import { fork } from 'redux-saga/effects';
import watchUserAuthentication from 'sagas/watchers';
import { 
    watchGetMe, 
    watchGetChildren, 
    watchGetCoParents, 
    watchGetCoParent, 
    watchLoader,
    watchQBStatus, 
    watchGetHouseHold ,
    watchGetThirdParty,
    watchCheckList,
    watchSessionStatus,
    watchLoginStatus,
    watchNotification
} from 'sagas/watchers';



export default function* startForman() {
    yield fork(watchUserAuthentication);
    yield fork(watchGetMe);
    yield fork(watchGetChildren);
    yield fork(watchGetCoParents);
    yield fork(watchGetCoParent);
    yield fork(watchQBStatus);
    yield fork(watchLoader);
    yield fork(watchGetHouseHold);
    yield fork(watchGetThirdParty);
    yield fork(watchCheckList);
    yield fork(watchSessionStatus);
    yield fork(watchLoginStatus);
    yield fork(watchNotification);
}
