import React, { Component, useState } from 'react';
import 'pages/CalenderInner/CalenderList/CalenderList.scss';
import { Card } from 'antd';
import Calendar from 'pages/CalenderInner/react-calendar/src';
import {connect} from 'react-redux';
import {getDots, getSwaps} from 'services/calendarService';
import LoadingOverlay from 'react-loading-overlay';
import { addLoading, removeLoading } from 'actions/loaderActions';

class CalenderList extends Component {
    constructor(props){
        super(props);
        this.state = {
            dates : {},
            swaps: [],
            loading: false,
            currentDate: new Date(),
            isThirdParty : localStorage.getItem("third_party"),
        };
        this.getCalendarDots = this.getCalendarDots.bind(this);
        this.getCalendarSwaps = this.getCalendarSwaps.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){ 
        const { isThirdParty } = this.state;
        this.getCalendarDots(this.props.coparent);
        this.getCalendarSwaps(this.props.coparent);
    }

    componentDidUpdate(prevProps){
        if(this.props.coparent !== prevProps.coparent){
            this.getCalendarDots(this.props.coparent);
            this.getCalendarSwaps(this.props.coparent);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.loadings === true) {
            this.props.dispatch(removeLoading());
            this.getCalendarDots(nextProps.coparent);
            this.getCalendarSwaps(nextProps.coparent);
        }
    }


    getCalendarDots = function (coparent) {
        const { isThirdParty } = this.state;
        this.setState({loading: true});
        const sParams = {
            coparent_id: (coparent && coparent.id) || null,
        };
        if(isThirdParty == 'true') {
            sParams .coparent_id = localStorage.getItem("coparent_id");
            sParams.member_id = localStorage.getItem("member_id");
            sParams.third_party = true;
        }

        getDots(sParams).then( async (resp) => {
            if (resp && resp.data) {
                await this.setState({ dates: resp.data, loading: false });
            }else {
                await this.setState({loading: false});
            }
        }).catch(err => {
            this.setState({ loading: false});
        })
    };

    getCalendarSwaps = function (coparent) {
        const params = {
            coparent_id : (coparent && coparent.id) || null,
        };

        getSwaps(params)
        .then(resp => {
            if(resp && resp.rows){
                this.setState({swaps: resp.rows});
            }
        }).catch(err => {});
    }

    handleChange = function(e){
        this.setState({currentDate: e});
    }

    render() {
        const {dates, loading, currentDate, swaps,isThirdParty } = this.state;
        const {children, coparent, user ,thirdPartyData} = this.props;
        return (
            <Card bordered={false} className="calenderlist">
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap cust-loader': ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Calendar
                swaps={swaps}
                selected={currentDate}
                onChange={(e) => this.handleChange(e)} 
                markedDates={dates} 
                children={isThirdParty == 'true' ?  (thirdPartyData && thirdPartyData.kid) : children}
                thirdParty= {isThirdParty == 'true' ?  true : false}
                user={user}
                />
            </Card>
        );
    }
};

const mapStateToProps = ({ serviceReducer, houseHoldReducer }) => {
    const { user, error, children, loadings, coparent } = serviceReducer;
    const { thirdPartyData } = houseHoldReducer;
    return { user, error, children, loadings, coparent,thirdPartyData };
};
export default connect(mapStateToProps)(CalenderList);
