import React, { Component } from 'react';
import {
    Card,
    Button,
    Modal,
    Checkbox,
    Form,
    Input,
    Select,
    Empty,
    Tabs,
    Radio
} from 'antd';
import 'pages/SubscriptionNew/EditSubscription.scss';

class ChooseUserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: '1'
        };
        this.handleChange = this.handleChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.openModal) {
            this.setState({
                selected: '1'
            });

        }
    }

    handleChange = async function (e) {
        this.setState({ selected: e.target.value });
    }

    handleConfirm = () => {
        const { selected } = this.state;
        if (selected === "1") {
            this.props.closeModal();
            this.props.selectedUser('1');
        } else if(selected === "2"){
            this.props.closeModal();
            this.props.selectedUser('2');
        }
    }

    render() {
        const { selected } = this.state;

        return (
            <Modal
            title="Choose Account"
            centered
            maskClosable={false}
            visible={this.props.openModal}
            className="choose-user-modal"
            onOk={this.props.closeModal}
            onCancel={this.props.closeModal}
            >

                <Radio.Group value={selected}>
                    <Radio onClick={this.handleChange} value={'1'}>
                        {'Normal User'}
                    </Radio>
                    <Radio onClick={this.handleChange} value={'2'}>
                       {'Third Party User'}
                    </Radio>
                </Radio.Group>

                <Button
                    type="primary"
                    onClick={this.handleConfirm}
                    className="btn fp-button-primary"
                >
                    Confirm
                </Button>
            </Modal>
        )
    }
}


export default ChooseUserModal;