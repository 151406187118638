import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactAudioPlayer from 'react-audio-player';

class AudioPlayer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { currentMessage } = this.props;
        return (
            <ReactAudioPlayer
                className="audio-player"
                ref={(element) => { this.rap = element; }}
                value={currentMessage}
                src={currentMessage.audio}
                controls
            />
        )
    }
}


export default AudioPlayer;