import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import 'pages/Payment/Register/Register.scss';
import { Card, Button, Modal, Select, Input, Checkbox, DatePicker } from 'antd';
import FloatLabel from 'Components/FloatLabel/FloatLabel';
import left from 'Assets/left-arrow.svg';
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { getHouseHoldAction, saveHouseHoldAction } from 'actions/houseHoldAction.js';
import { getHouseHoldService } from '../../../services/houseHoldService';
import { checkCustomer} from '../../../services/paymentService';
import { US_states } from 'pages/Household/Household/StateData.js';
import AccountSetup from 'pages/Payment/Register/AccountSetup';

const validator = require('email-validator');
let addressArray = [];
let countries = [
    {
        addressFields: ['address', 'city', 'zip', 'state', 'licence'],
        label: '+1 United States',
        value: 'usa',
    },
    {
        addressFields: ['street', 'city', 'provinceOrTerritory', 'postalCode', 'licence'],
        label: '+1 Canada',
        value: 'canada',
    },
];

var d = new Date();
var utc = d.getTime();
class Setup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ACHSetupVisible : false,
            setupVisible : false,
            LoadingIs: false,
            stepOneStatus : '',
            step: 1,
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            dob: new Date(utc),
            address: '',
            city: '',
            zip: '',
            state : '',
            country: '',
            street : '',
            postalCode: '',
            provinceOrTerritory: '',
            licence: '',
            addressOrThoroughsfare: '',
            postcode: '',
            postTown: '',
            locality: '',
            ssn: '',
            nameOnAccount: '',
            recuringNumber: '',
            accountNumber: '',
            errorIs: {
                name: '',
                firstName: '',
                lastName: '',
                email: '',
                dob: '',
                phoneNumber: '',
                licence: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                street: '',
                provinceOrTerritory: '',
                postalCode: '',
                ssn: '',
            },
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleState = this.handleState.bind(this);
        this.getStateName = this.getStateName.bind(this);
        this.getCountryName = this.getCountryName.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        this.setAccSetupVisible = this.setAccSetupVisible.bind(this);
        const { Option } = Select;
    }

    setACHSetupVisible(modalVisible) {
        this.setState({ ACHSetupVisible : modalVisible });
    }
    setModalClose(modalVisible) {
        this.setState({ ACHSetupVisible : false });
        this.props.handleClose(false);
    }
    setAccSetupVisible(modalVisible, modal2Visible) {
        this.setState({ setupVisible : modalVisible, ACHSetupVisible : modal2Visible, step : 1 });
    }
    handleClose (val) {
        this.setState({setupVisible : val})
        this.props.handleClose(false);
    }
    clearValidationMsg () {
        const { errorIs } = this.state;
        errorIs.address = '';
        errorIs.city = '';
        errorIs.state = '';
        errorIs.zip = '';
        errorIs.street = '';
        errorIs.provinceOrTerritory = '';
        errorIs.postalCode = '';
    }
  
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        this.validateForm(name, value);
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        
        if (nextProps && nextProps.setupVisible) {
            await this.clearData();
            this.setState({step : 1,  setupVisible : nextProps.setupVisible, stepOneStatus: ''})
            this.clearValidationMsg()
            this.setState({LoadingIs : true})
            await this.getUserData(nextProps);
            await this.getHouseholdDataById()
            var cust_exist = await checkCustomer();
            if(typeof cust_exist != undefined  ){
                this.handleCustomerCheck(cust_exist)
            } else {
                this.setState({LoadingIs : false})
            }
        }
           
    };

    getUserData(nextProps) {
        const props = nextProps || this.props;
        const user = props.user;
        if (user) {
          this.setState({
            email: user.email || "",
            firstName: user.first_name || "",
            lastName: user.last_name || "",
            phoneNumber: user.phone_number || "",
            dob: user.dob || new Date(utc),
          });
        }
    }

    clearData () {
        this.setState({
            email:  "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            dob: "",
            ssn: "",
            address: "",
            zip: "",
            city:  "",
            state:  "",
            country:  "",
            licence: "",
            street:  "",
            postalCode:  "",
            provinceOrTerritory:  "",
          });
        this.setState ({
            errorIs: {
                name: '',
                firstName: '',
                lastName: '',
                email: '',
                dob: '',
                phoneNumber: '',
                licence: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                street: '',
                provinceOrTerritory: '',
                postalCode: '',
                ssn: '',
            }
        })
    }

    handleCustomerCheck(resp) {
        if (resp.isExist) {
          this.setState({ step: 2, stepOneStatus: 'completed' });
          this.setACHSetupVisible(true)
          let data = resp.data;
          this.setState({
            email: data.email || "",
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            phoneNumber: data.phone || "",
            dob: data.dob || "",
            ssn: data.ssn || "",
            address: data.address1 || "",
            zip: data.zip || "",
            city: data.city || "",
            state: data.state || "",
            country: data.country_code || "",
            licence: data.licence || "",
            street: data.street || "",
            postalCode: data.postalCode || "",
            provinceOrTerritory: data.provinceOrTerritory || "",
          });
        }
         this.setState({LoadingIs : false})
    }

   async getHouseholdDataById() {
      const props = this.props;
      await getHouseHoldService().then(async (resp) => {
      //const country = resp.country_code || props.countries && props.countries[0].value;
      const country = resp.country_code || props.countries && props.countries[0].value || 'usa';
      if (country == "canada" || country == "CANADA") {
        this.setState({ country: props.countries[1].value });
      } else if (country == "uk" || country == "UK") {
        this.setState({ country: props.countries[2].value });
      }
      if (country == "usa" || country == "USA") {
        if (resp) {
          this.setState({
            address: resp.address || "",
            zip: resp.zip || "",
            city: resp.city || "",
            state: resp.state || "",
            country: resp.country_code || "usa",
            licence: resp.licence || "",
            street: resp.street || "",
            postalCode: resp.postalCode || "",
            provinceOrTerritory: resp.provinceOrTerritory || "",
          });
        }
      } else if (country == "canada" || country == "CANADA") {
        if (resp) {
          this.setState({
            street: resp.street || "",
            postalCode: resp.postalCode || "",
            city: resp.city || "",
            provinceOrTerritory: resp.provinceOrTerritory || "",
            country: resp.country_code || "canada",
            licence: resp.licence || "",
            address: resp.address || "",
            zip: resp.zip || "",
            city: resp.city || "",
            state: resp.state || "",
          });
        }
      } else if (country == "uk" || country == "UK") {
        if (resp) {
          this.setState({
            addressOrThoroughsfare: resp.addressOrThoroughsfare || "",
            postcode: resp.postcode || "",
            postTown: resp.postTown || "",
            locality: resp.locality || "",
            country: resp.country_code || "uk",
            licence: resp.licence || "",
          });
        }
      }
     //  this.getAddressFieldByCountryCode(this.state.country);
      });
    }

    formatDate = (dateObj) => {
        let date = new Date(dateObj);
        let month = date.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = date.getFullYear();
        let output = parseInt(year.toString()) + '-' + month + '-' + day;
        return output;
    };

    handleDOB = (e) => {
        if (e && e._d) {
            this.setState({ dob: this.formatDate(e._d) });
        }
    };

    getStateName = function (val) {
        let s = '';
        US_states.forEach((item) => {
            if (item.value === val) {
                s = item.label;
            }
        });
        return s;
    };

    handleState = async function (val) {
        await this.setState({state: val });
    };

    handleCountry = async function (value) {
        const { errorIs } = this.state;
        errorIs.address = '';
        errorIs.city = '';
        errorIs.state = '';
        errorIs.zip = '';
        errorIs.street = '';
        errorIs.provinceOrTerritory = '';
        errorIs.postalCode = '';
        await this.setState({ country_code: value, country: value});
    };

    getCountryName = function (val) {
        let c = '';
        const { errorIs } = this.state;

        addressArray = [];
        countries.forEach((item) => {
            if (item.value === val) {
                c = item.label;
            }
        });
        if (c == '') {
            c = '+1 United States';
        }
        let showAddressFields = [];
        countries.map((item) => {
            if (item.value === val) {
                showAddressFields = item.addressFields;
            }
        });

        addressArray.push(showAddressFields);
        return c;
    };
    async createCustomer() {
        const { errorIs } = this.state;
        if (this.validation()) {
            let customer = await this.getCustomerData(this.state.country);
            this.setState({ step: 2, stepOneStatus: 'completed' });
            this.setACHSetupVisible(true)
            this.props.handleClose(false);
        } else {
           Object.entries(errorIs).map((type , key) => {
               if(type && type.length > 1 && type[1] != '') {
                 this.focusNode(type[0])
               }
            })
        }

    }

     focusNode(field) {
        let node = ReactDOM.findDOMNode(this.refs[field]);
        if(node != null)
          node.scrollIntoView({block: 'start', behavior: 'smooth'});
       
    }

    getCustomerData(country) {
        let data = {
          FirstName: this.state.firstName,
          LastName: this.state.lastName,
          Email: this.state.email,
          Phone: this.state.phone,
          Address1: "",
          Address2: "",
          City: "",
          StateRegion: "",
          PostalCode: "",
          Country: "",
          licence: this.state.licence,
        };
        switch (country) {
          case "uk":
            data.Address1 = this.state.addressOrThoroughsfare;
            data.City = this.state.locality;
            data.StateRegion = this.state.postTown;
            data.PostalCode = this.state.postcode;
            data.Country = "UK";
            break;
          case "usa":
            data.Address1 = this.state.address;
            data.City = this.state.city;
            data.StateRegion = this.state.state;
            data.PostalCode = this.state.zip;
            data.Country = "USA";
            break;
          case "canada":
            data.Address1 = this.state.street;
            data.City = this.state.city;
            data.StateRegion = this.state.provinceOrTerritory;
            data.PostalCode = this.state.postalCode;
            data.Country = "CANADA";
            break;
        }
        return data;
    }

    validation() {
        const zipRegx = /^[0-9\b]+$/;
        const { errorIs, name, firstName, lastName, email, dob, phoneNumber, address,
                city, state, zip, postalCode, street, provinceOrTerritory, country_code,
                licence, ssn, } = this.state;
        const phoneRegx = /^[0-9\b]+$/;
        errorIs.firstName = !firstName.length
            ? 'First Name is required!'
            : !(firstName.length > 1)
            ? 'First Name is Too short (Must be at Least 2 character)'
            : firstName.length > 50
            ? 'First Name is Too Long! (Max 50 symbols)'
            : '';
        errorIs.lastName = !lastName.length
            ? 'Last Name is required!'
            : !(lastName.length > 1)
            ? 'Last Name Is Too short (Must be at Least 2 characters)'
            : lastName.length > 50
            ? 'Last Name Is Too Long (Must be 50 characters'
            : '';
        errorIs.ssn = !ssn.length
            ? 'SSN Is required!'
            : !(ssn.length > 8)
            ? 'SSN Is Too short (Must be at Least 9 characters)'
            : ssn.length > 9
            ? 'SSN Is Too Long (Must be 9 characters)'
            : '';
        errorIs.email = !email.length ? 'Email is required!' : !validator.validate(email) ? 'Invalid email' : '';
        errorIs.dob = !dob ? 'DOB is required!' : '';
        errorIs.phoneNumber = !phoneNumber.length
            ? 'Phone Number is required!'
            : !phoneRegx.test(phoneNumber)
            ? 'Only Number Characters Allowed!'
            : phoneNumber.length > 10 || phoneNumber.length < 10
            ? 'Phone Number should be exactly 10 characters '
            : '';
        errorIs.licence =
            licence.length > 0 && !(licence.length > 4)
                ? 'License is Too short (Must be at Least 5 characters)'
                : licence.length > 0 && licence.length > 16
                ? 'License is Too Long! (Max 16 characters)'
                : '';
        errorIs.address =
            country_code == 'usa' && !address.length
                ? 'Address is required!'
                : country_code == 'usa' && address.length > 50
                ? 'Address is Too Long! (Max 50 symbols)'
                : '';

        if (country_code == 'canada') {
            errorIs.street = !street.length
                ? 'Sreet Is required!'
                : !(street.length > 1)
                ? 'Street Is Too short (Must be at Least 2 characters)'
                : street.length > 50
                ? 'Street Is Too Long (Must be 50 characters)'
                : '';
        }

        if (country_code == 'canada') {
            errorIs.provinceOrTerritory = !provinceOrTerritory.length
                ? 'Province/Territory is required!'
                : !(provinceOrTerritory.length > 1)
                ? 'Province/Territory Is Too short (Must be at Least 2 characters)'
                : provinceOrTerritory.length > 50
                ? 'Province/Territory is Too Long (Must be 50 characters)'
                : '';
        }

        errorIs.city = !city.length
            ? 'City Is required!'
            : !(city.length > 1)
            ? 'City Is Too short (Must be at Least 2 characters)'
            : city.length > 25
            ? 'City Is Too Long (Must be 25 characters)'
            : '';
        errorIs.state = country_code == 'usa' && !state.length ? 'State is required!' : '';
        if (country_code == 'usa') {
            errorIs.zip = !zip.length
                ? 'Zip is required!'
                : !zipRegx.test(zip)
                ? 'Only Number Characters Allowed!'
                : !(zip.length > 4)
                ? 'Zip is Too short (Must be 5 characters)'
                : !(zip.length === 5)
                ? 'Zip is Too Long (Must be 5 characters)'
                : '';
        }
        if (country_code == 'canada') {
            errorIs.postalCode =
                country_code == 'canada' && !postalCode.length
                    ? 'Postal Code is required!'
                    : !zipRegx.test(postalCode)
                    ? 'Only Number Characters Allowed!'
                    : !(postalCode.length > 1)
                    ? 'Postal Code is Too short (Must be at Least 2 characters)'
                    : '';
        }

        this.setState({
            errorIs,
        });
        return (
            errorIs &&
            !errorIs.lastName &&
            !errorIs.firstName &&
            !errorIs.zip &&
            !errorIs.licence &&
            !errorIs.phoneNumber &&
            !errorIs.address &&
            !errorIs.street &&
            !errorIs.email &&
            !errorIs.ssn &&
            !errorIs.provinceOrTerritory &&
            !errorIs.postalCode &&
            !errorIs.dob &&
            !errorIs.licence &&
            !errorIs.city &&
            !errorIs.state
        );
    }

    validateForm = (name, value) => {
        const { errorIs, country } = this.state;
        const phoneRegx = /^[0-9\b]+$/;
        const zipRegx = /^[0-9\b]+$/;
        switch (name) {
            case 'name':
                errorIs.name = !value.length
                    ? 'Name is required!'
                    : !(value.length > 1)
                    ? 'Household Name is Too short (Must be at Least 2 character)'
                    : value.length > 50
                    ? 'Household Name is Too Long! (Max 50 symbols)'
                    : '';
                break;
            case 'firstName':
                errorIs.firstName = !value.length
                    ? 'Firstname is required!'
                    : !(value.length > 1)
                    ? 'First Name is Too short (Must be at Least 2 character)'
                    : value.length > 50
                    ? 'First Name is Too Long! (Max 50 symbols)'
                    : '';
                break;
            case 'lastName':
                errorIs.lastName = !value.length
                    ? 'Lastname is required!'
                    : !(value.length > 1)
                    ? 'Last Name Is Too short (Must be at Least 2 characters)'
                    : value.length > 50
                    ? 'Last Name Is Too Long (Must be 50 characters)'
                    : '';
                break;
            case 'email':
                errorIs.email = !value.length
                    ? 'Email is required!'
                    : !validator.validate(value)
                    ? 'Invalid email'
                    : '';
                break;
            case 'ssn':
                errorIs.ssn = !value.length
                    ? 'SSN is required!'
                    : !(value.length > 8)
                    ? 'SSN Is Too short (Must be at Least 9 characters)'
                    : value.length > 9
                    ? 'SSN Is Too Long (Must be 9 characters)'
                    : '';
                break;
            case 'dob':
                errorIs.dob = !value.length ? 'DOB is required!' : '';
                break;
            case 'phoneNumber':
                errorIs.phoneNumber = !value.length
                    ? 'Phone is required!'
                    : value.length > 0 && !phoneRegx.test(value)
                    ? 'Only Number Characters Allowed!'
                    : value.length > 10 || value.length < 10
                    ? 'Phone number should be exactly 10 characters'
                    : '';
                break;
            case 'licence':
                errorIs.licence =
                    value.length > 0 && !(value.length > 4)
                        ? 'License is Too short (Must be at Least 5 characters)'
                        : value.length > 0 && value.length > 16
                        ? 'License is Too Long! (Max 16 characters)'
                        : '';
                break;
            // case "password":
            //   errorIs.password = !value.length ? "Password is required!" : "";
            // break;
            case 'address':
                errorIs.address =
                    country == 'usa' && !value.length
                        ? 'Address is required!'
                        : country == 'usa' && value.length > 50
                        ? 'Address is Too Long! (Max 50 symbols)'
                        : '';
                break;
            case 'street':
                errorIs.street =
                    country == 'canada' && !value.length
                        ? 'Street is required!'
                        : country == 'canada' && !(value.length > 1)
                        ? 'Street is Too short (Must be at Least 2 characters)'
                        : country == 'canada' && value.length > 50
                        ? 'Street is Too Long (Must be 50 characters)'
                        : '';
                break;
            case 'provinceOrTerritory':
                errorIs.provinceOrTerritory =
                    country == 'canada' && !value.length
                        ? 'Province/Territory is required!'
                        : country == 'canada' && !(value.length > 1)
                        ? 'Province/Territory is Too short (Must be at Least 2 characters)'
                        : country == 'canada' && value.length > 50
                        ? 'Province/Territory is Too Long (Must be 50 characters)'
                        : '';
                break;
            case 'city':
                errorIs.city = !value.length
                    ? 'City is required!'
                    : !(value.length > 1)
                    ? 'City Is Too short (Must be at Least 2 characters)'
                    : value.length > 25
                    ? 'City is Too Long (Must be 25 characters)'
                    : '';
                break;
            case 'state':
                errorIs.state = country == 'usa' && !value.length ? 'State is required!' : '';
                break;
            case 'zip':
                errorIs.zip =
                    country == 'usa' && !value.length
                        ? 'Zip is required!'
                        : country == 'usa' && !zipRegx.test(value)
                        ? 'Only Number Characters Allowed!'
                        : country == 'usa' && !(value.length > 4)
                        ? 'Zip is Too short (Must be 5 characters)'
                        : country == 'usa' && !(value.length === 5)
                        ? 'Zip is Too Long (Must be 5 characters)'
                        : '';
                break;
            case 'postalCode':
                errorIs.postalCode =
                    country == 'canada' && !value.length
                        ? 'PostalCode is required!'
                        : country == 'canada' && !zipRegx.test(value)
                        ? 'Only Number Characters Allowed!'
                        : country == 'canada' && !(value.length > 1)
                        ? 'PostalCode is Too short (Must be at Least 2 characters)'
                        : '';
                break;
            default:
                break;
        }
        return errorIs;
    };


    render() {
        const { errorIs, country, setupVisible, LoadingIs} = this.state;
        addressArray = [];

        let showAddressFields = [];
        countries.map((item) => {
            if (item.value === country) {
                showAddressFields = item.addressFields;
            }
        });

        addressArray.push(showAddressFields);
    switch (this.state.step) {
      case 1:
        return (
                
            <Modal
                title="Account Setup"
                centered
                visible={setupVisible}
                onOk={() => this.setModalVisible(false)}
                onCancel={() => this.handleClose(false)}
                className="account-modal"
            >
             <LoadingOverlay
                        active={LoadingIs}
                        className="d-block pop-loader"
                        spinner
                        styles={{
                            spinner: (base) => ({
                                ...base,
                                width: '100px',
                                '& svg circle': {
                                    stroke: 'rgb(56, 160, 235)',
                                },
                            }),
                        }}
            >
            </LoadingOverlay>
                    {/* <Formik> */}
                <form>
                    <div className="content-wrap">
                        <div className="float-wrap" ref="firstName">
                            <FloatLabel label="First Name" value={this.state.firstName}>
                                <Input
                                    name="firstName"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.firstName}
                                />
                            </FloatLabel>
                             {errorIs.firstName ? (
                                                    <div className="err-msg">{errorIs.firstName}</div>
                                                ) : null}
                            {/*<div className="err-msg">this is a error message</div> */}
                        </div>
                        <div className="float-wrap" ref="lastName">
                            <FloatLabel label="Last Name" value={this.state.lastName}>
                                <Input
                                    name="lastName"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.lastName}
                                />
                            </FloatLabel>
                            {errorIs.lastName ? (
                                 <div className="err-msg">{errorIs.lastName}</div>
                            ) : null}
                        </div>
                        <div className="float-wrap" ref="phoneNumber">
                            <FloatLabel label="Phone Number" value={this.state.phoneNumber}>
                                <Input
                                    name="phoneNumber"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.phoneNumber}
                                />
                            </FloatLabel>
                        {errorIs.phoneNumber ? <div className="err-msg">{errorIs.phoneNumber}</div> : null}
                        </div>
                         <div className="float-wrap"  ref="ssn">
                            <FloatLabel label="SSN" value={this.state.ssn}>
                                <Input
                                    name="ssn"
                                    type = "password"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.ssn}
                                />
                            </FloatLabel>
                            {errorIs.ssn ? <div className="err-msg">{errorIs.ssn}</div> : null}
                        </div>
                        <div className="float-wrap" ref="email">
                            <FloatLabel label="Email" value={this.state.email}>
                                <Input
                                    name="email"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.email}
                                />
                            </FloatLabel>
                             {errorIs.email ? <div className="err-msg">{errorIs.email}</div> : null}
                        </div>
                        <div className="float-wrap cust-mb" ref="dob">
                            <FloatLabel label="Date of Birth" value={this.state.dob === '' ? this.state.dob : this.formatDate(this.state.dob)}>
                                <DatePicker
                                    className="cust-dp"
                                    inputReadOnly="true"
                                    value={Moment(this.state.dob)}
                                    placeholder=""
                                    size="large"
                                    onChange={this.handleDOB}
                                />
                            </FloatLabel>
                            {errorIs.dob ? <div className="err-msg">{errorIs.dob}</div> : null}
                        </div>
                         <div className="float-wrap" ref="country">
                            <Select value={this.getCountryName(this.state.country)}
                                onChange={this.handleCountry} className="cust-selct">
                               {countries.map((country, i) => (
                                    <Option
                                        className={
                                            this.state.country === country.value ? 'text-color' : ''
                                        }
                                        value={country.value}
                                        key={i}
                                    >
                                        {country.label}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        {addressArray[0].includes('address') && (
                            <div className="float-wrap" ref="address">
                                <FloatLabel label="Address" value={this.state.address}>
                                    <Input
                                        name="address"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                        }}
                                        autoComplete="nope"
                                        value={this.state.address}
                                    />
                                </FloatLabel>
                            {errorIs.address ? (
                                <div className="err-msg">{errorIs.address}</div>
                            ) : null}
                            </div>
                        )}
                        {addressArray[0].includes('street') && (
                            <div className="float-wrap" ref="street">
                                <FloatLabel label="Street" value={this.state.street}>
                                    <Input
                                        name="street"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                        }}
                                        autoComplete="nope"
                                        value={this.state.street}
                                    />
                                </FloatLabel>
                            {errorIs.street ? (
                                <div className="err-msg">{errorIs.street}</div>
                            ) : null}
                            </div>
                        )}
                        {addressArray[0].includes('city') && (
                            <div className="float-wrap" ref="city">
                                <FloatLabel label="City" value={this.state.city}>
                                    <Input
                                        name="city"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                        }}
                                        autoComplete="nope"
                                        value={this.state.city}
                                    />
                                </FloatLabel>
                            {errorIs.city ? (
                                <div className="err-msg">{errorIs.city}</div>
                            ) : null}
                            </div>
                        )}
                        {addressArray[0].includes('provinceOrTerritory') && (
                            <div className="float-wrap" ref="provinceOrTerritory">
                                <FloatLabel label="Province/Territory" value={this.state.provinceOrTerritory}>
                                    <Input
                                        name="provinceOrTerritory"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                        }}
                                        autoComplete="nope"
                                        value={this.state.provinceOrTerritory}
                                    />
                                </FloatLabel>
                            {errorIs.provinceOrTerritory ? (
                                <div className="err-msg">{errorIs.provinceOrTerritory}</div>
                            ) : null}
                            </div>
                        )}
                        {addressArray[0].includes('zip') && (
                            <div className="float-wrap" ref="zip">
                                <FloatLabel label="Zip" value={this.state.zip}>
                                    <Input
                                        name="zip"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                        }}
                                        autoComplete="nope"
                                        value={this.state.zip}
                                    />
                                </FloatLabel>
                            {errorIs.zip ? <div className="err-msg">{errorIs.zip}</div> : null}
                            </div>
                         )}
                        {addressArray[0].includes('postalCode') && (
                            <div className="float-wrap" ref="postalCode">
                                <FloatLabel label="PostalCode" value={this.state.postalCode}>
                                    <Input
                                        name="postalCode"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                        }}
                                        autoComplete="nope"
                                        value={this.state.postalCode}
                                    />
                                </FloatLabel>
                            {errorIs.postalCode ? <div className="err-msg">{errorIs.postalCode}</div> : null}
                            </div>
                         )}
                        {addressArray[0].includes('state') && (
                            <div className="float-wrap" ref="state">
                                <Select value={this.getStateName(this.state.state)}
                                    onChange={this.handleState} className="cust-selct">
                                   {US_states.map((item, i) => (
                                        <Option
                                            className={this.state.state === item.value ? 'text-color' : ''}
                                            value={item.value}
                                            key={i}
                                        >
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            {errorIs.state ? (
                                <div className="err-msg">{errorIs.state}</div>
                            ) : null}
                            </div>
                        )}
                        <div className="float-wrap" ref="licence">
                            <FloatLabel label="Drivers licence" value={this.state.licence}>
                                <Input
                                    name="licence"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.licence}
                                />
                            </FloatLabel>
                        {errorIs.licence ? (
                             <div className="err-msg">{errorIs.licence}</div>
                        ) : null}
                        </div>
                    </div>
                    <div className="btn-wrap">
                        <Button
                            type="primary"
                            className="fp-button-secondary btn-add-req"
                            onClick={() => this.createCustomer()}
                        >
                            <span>Continue</span>
                        </Button>
                    </div>
                    <div className="bottom-info">
                        <span>Receive and Send Funds Electronically.</span>
                        <span>STEP 1 0f 2</span>
                    </div>
                </form>
                    {/* </Formik> */}
                </Modal>
            );
           case 2 : 
            return (
                <AccountSetup 
                    achVisible={this.state.ACHSetupVisible}
                    formValues={this.state}
                    handleAccSetupForm = {this.setAccSetupVisible}
                    achclose={() => this.setModalClose(false)}
                    stepOneStatusProps = {this.state.stepOneStatus}
               /> 
            );   
        
    }
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparents, coparent } = serviceReducer;
    return { user, error, children, coparents, coparent };
};

export default connect(mapStateToProps)(Setup);

