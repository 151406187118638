import {
    makeRequest
} from './authenticationService';

export const getSubscriptionPlan = () => {
    let url = '/api/subscription/plan';

    let data = {
        "oauthtoken": localStorage.billingAccessToken
    }

    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const getBillingAuthToken = () => {
    let url = '/api/subscription/token';
    let data = {
         "oauthtoken": localStorage.billingAccessToken,
    }
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const getSubscriptionCheck = () => {
    let url = '/api/subscription/check';
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const getCouponCodes = () => {
    let url = '/api/subscription/coupon';
    let data = {
         "oauthtoken": localStorage.billingAccessToken,
    }
    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const createNewSubscription = (datafield) => {
    let url = '/api/subscription/create';
    let data = {
         "oauthtoken": localStorage.billingAccessToken,
        data: datafield
    }
    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const saveSubscription = (datafield) => {
    let url = '/api/subscription/add';
    let data = datafield;
    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const getUserSubscription = () => {
    let url = '/api/subscription/get';
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}


export const subscription = (request) => {

    let url = 'https://subscriptions.zoho.com/api/v1/subscriptions';


    //  return makeRequest('Put', url,request)
}

export const cancelSubscription = () => {
    let url = '/api/subscription/cancel';
    let data = {
         "oauthtoken": localStorage.billingAccessToken,
    }
    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const activateSubscription = () => {
    let url = '/api/subscription/activate';
    let data = {
         "oauthtoken": localStorage.billingAccessToken,
    }
    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const getActiveCards = () => {
    let url = '/api/subscription/getActiveCards';
    let data = {
         "oauthtoken": localStorage.billingAccessToken,
    }
    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}


export const notifySubscription = (data) => {

    let url = '/api/subscription/notify';

    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const updateFreeTrial = (data) => {

    let url = '/api/subscription/updateFreeTrial';

    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const deleteCard = (card_id) => {

    let oauthtoken = localStorage.billingAccessToken;
    
    let url = `/api/subscription/deleteCard?id=${card_id}&oauthtoken=${oauthtoken}`;

    return makeRequest('DELETE', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const updateCard = (card) => {

    let url = '/api/subscription/updateCard';
    let data = {
        "oauthtoken": localStorage.billingAccessToken,
        card: card
    };
    
    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const getCardDetails = (card_id) => {

    let oauthtoken = localStorage.billingAccessToken;
    
    let url = `/api/subscription/getCard?id=${card_id}&oauthtoken=${oauthtoken}`;

    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}


export const updateSubscription = (card) => {

    let url = '/api/subscription/updateSubscription';
    let data = {
        "oauthtoken": localStorage.billingAccessToken,
        card: card
    };
    
    return makeRequest('PUT', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const fetchSubscription = () => {

    let url = '/api/subscription/fetchSubscription';
    let data = {
        "oauthtoken": localStorage.billingAccessToken
    };
    
    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const getTransactions = () => {

    let url = '/api/subscription/getTransactions';

    let data = {
        "oauthtoken": localStorage.billingAccessToken,
    }

    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const getPayment = (id) => {

    let url = '/api/subscription/getPayment';

    let data = {
        "oauthtoken": localStorage.billingAccessToken,
        payment_id: id
    }

    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const getInvoice = (id) => {

    let url = '/api/subscription/getInvoice';

    let data = {
        "oauthtoken": localStorage.billingAccessToken,
        invoice_id: id
    }

    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}


export const addCard = (card) => {

    let options = {
        data: card,
        "oauthtoken": localStorage.billingAccessToken
    };

    let url = '/api/subscription/addCard';

    return makeRequest('POST', url, options)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
}

export const checkEmail = (email) => {
    let options = {
        email : email
    }
    let url = '/api/subscription/checkEmail';
    return makeRequest('POST', url,options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const createInvitation = (email) => {
    let options = {
        email : email
    }
    let url = '/api/subscription/createInvitation';
    return makeRequest('POST', url,options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}