import React, { Component } from 'react';
import 'pages/Landing/RecentTransactions/RecentTransactions.scss';
import { Card, Empty } from 'antd';
import TransactionCard from 'pages/Landing/RecentTransactions/RecentTransactionsCard';
import ScrollArea from 'react-scrollbar';
import { getPaymentTransactions } from 'services/paymentService';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import ACHModal from 'pages/Payment/Payments/ACHModal';
import RecentModal from 'pages/Payment/Payments/RecentModal';
import {removeLoading} from 'actions/loaderActions';
import { getUserById } from 'services/userService';

class RecentTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactions: [],
            offset: 0,
            coparent: null,
            loading: false,
            currentPayment: null,
            modal1Visible: false,
            modal2Visible: false,
            coParent: null
        };
        this.getRecentTransactions = this.getRecentTransactions.bind(this);
        this.getFormattedDate = this.getFormattedDate.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
    }

    componentDidMount() {
        let offset = 0;
        if (localStorage.third_party && localStorage.third_party === 'true') {
            getUserById(localStorage.coparent_id)
                    .then(resp => {
                        if (resp) {
                            this.setState({ coParent: resp });
                    }
            })
            this.getRecentTransactions(offset, localStorage.coparent_id, false);
        } else {
            this.getRecentTransactions(offset, this.props.coparent, false);
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.coparent !== prevProps.coparent) {
            let offset = 0;
            let arr = [];
            this.setState({ transactions: arr });
            if (localStorage.third_party && localStorage.third_party === 'true') {
                this.getRecentTransactions(offset, localStorage.coparent_id, true);
            } else {
                this.getRecentTransactions(offset, this.props.coparent, true);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps && nextProps.error !== null) {
           this.setState({loading: false});
        }
        if(nextProps && nextProps.err !== null) {
            this.setState({loading: false});
        }
        if(nextProps && nextProps.loadings === true) {
            this.props.dispatch(removeLoading());
            let offset = 0;
            let arr = [];
            this.setState({ transactions: arr });
            if (localStorage.third_party && localStorage.third_party === 'true') {
                this.getRecentTransactions(offset, localStorage.coparent_id, true);
            } else {
                this.getRecentTransactions(offset, this.props.coparent, true);
            }
        }
    }
    
    refreshPage = function() {
        let offset = 0;
        if (localStorage.third_party && localStorage.third_party === 'true') {
            this.getRecentTransactions(offset, localStorage.coparent_id, true);
        } else {
            this.getRecentTransactions(offset, this.props.coparent, true);
        }
    }

    getRecentTransactions = function (offset, coparent, flag) {
        const { transactions } = this.state;
        if(this.props.err === null) {
           this.setState({ loading: true });
        }

        let sParams = {};

        if (localStorage.third_party && localStorage.third_party === 'true') {
            sParams = {
                offset: offset,
                limit: 15,
                orderBy: '-updated_at',
                coparent_id: coparent,
                thirdParty: true
            };

        } else {
            sParams = {
                offset: offset,
                limit: 15,
                orderBy: '-updated_at',
                coparent_id: (coparent && coparent.id) || null,
            };
        }

        this.setState({ offset: offset, coparent: coparent || null });
        getPaymentTransactions(sParams).then((resp) => {
            if (resp) {
                if (resp.index !== undefined) {
                    this.setState({ offset: resp.index + 1, coparent: { id: resp.coparent } });
                } else {
                    resp.offset += 15;
                    this.setState({ offset: resp.offset, coparent: { id: resp.coparent } });
                }

                if (flag) {
                    let arr = [];
                    arr = [...arr, ...resp.transactions];
                    this.setState({ transactions: arr, loading: false });
                } else {
                    let arr = [];
                    arr = [...transactions, ...resp.transactions];
                    this.setState({ transactions: arr, loading: false });
                }
            } else {
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({ loading: false });
        })
    };

    handleClick = function(payment) {
        if(payment.status === 'inProgress') {
            this.setModal1Visible(true, payment);
        } else {
            this.setModal2Visible(true, payment);
        }

    }

    setModal1Visible = async function(modalVisible, payment) {
        await this.setState({modal1Visible: modalVisible, currentPayment: payment});
    }
    setModal2Visible = async function(modalVisible, payment) {
        await this.setState({modal2Visible: modalVisible, currentPayment: payment});
    }


    getFormattedDate = function (date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    };

    render() {
        const { user } = this.props;
        const { transactions, offset, coparent, currentPayment, loading } = this.state;

        return (
            <Card bordered={false} className="trans-card card-rec-trans">
                <LoadingOverlay
                    active={loading ? true : false}
                    className="d-block cust-loader"
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <div className="card-head req-head">
                    <span>Recent Transactions</span>
                </div>
                <ScrollArea
                    speed={0.8}
                    className="trans-card-wrap"
                    onScroll={(value) => {
                        if (value.topPosition === value.realHeight - value.containerHeight && value.topPosition !== 0) {
                            if (localStorage.third_party && localStorage.third_party === 'true') {
                                this.getRecentTransactions(offset, localStorage.coparent_id, false);
                            } else {
                                this.getRecentTransactions(offset, coparent, false);
                            }
                        }
                    }}
                    contentClassName="content"
                    horizontal={false}
                >
                        {user && transactions && Array.isArray(transactions) && transactions.length !== 0 ? (
                            transactions.map((payment, i) => {
                                return (
                                    (user.id === payment.PaymentCategory.coparent_user_id || user.id === payment.PaymentCategory.coparent_standlalone_id) && payment.PaymentCategory.category_status === 1 ? null : (   
                                    <TransactionCard
                                        coParent={this.state.coParent}
                                        handleInput={() => this.handleClick(payment)}
                                        user={user}
                                        payment={payment}
                                        status={payment.status}
                                        payer_id={payment.payer_id}
                                        receiver_id={payment.receiver_id}
                                        receiver_username={payment.receiver_username}
                                        payer_username={payment.payer_username}
                                        amount={payment.payer_amount}
                                        date={this.getFormattedDate(new Date(payment.payment_date))}
                                        key={i}
                                    />
                                    )
                                );
                                
                            })
                        ) : (
                            <div>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        )}
         
                </ScrollArea>
                <ACHModal  refresh={() => this.refreshPage()} currentPayment={currentPayment} handleOpen={this.state.modal1Visible} handleClose={() => this.setModal1Visible(false)} />
                <RecentModal refresh={() => this.refreshPage()} currentPayment={currentPayment} handleOpen={this.state.modal2Visible} handleClose={() => this.setModal2Visible(false)} />
            </Card>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, coparent, loadings, error } = serviceReducer;
    return { user, coparent, loadings, error };
};
export default connect(mapStateToProps)(RecentTransactions);
