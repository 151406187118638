import { makeRequest } from './authenticationService';

export const GetActivePaymentReport = (
  archived,
  pffset,
  plimit,
  callback
) => {
  const offset = pffset || 0;
  const limit = plimit || 10;
   let url = `/api/reports/payment/list?archived=${archived}&offset=${offset}&limit=${limit}`;
   return makeRequest('GET', url)
        .then((resp) => {
           if (callback) {
            callback(resp);
           }
        },
       true
    )
        
};

export const CreateRecurringReport = (data) => {
  return makeRequest("POST", `/api/reports/recurringPayment`, data);
};

export const SaveRecurringPaymentReport = (data) => {
  return makeRequest("POST", `/api/reports/recurringPayment/save`, data);
};

export const GetRecurringReporDetail = (recurring_report_id, callback) => {
  let url = `/api/reports/recurringPayment/${recurring_report_id}`;
  return makeRequest('GET', url)
        .then((resp) => {
           if (callback) {
            callback(resp);
           }
        },
       true
    )
};

export const UpdateRecurringPaymentReport = (data) => {
  return makeRequest("PUT", `/api/reports/recurringPayment/update`, data);
};



