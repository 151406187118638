import React, { Component } from 'react';
import { Card } from 'antd';

const payment_status = {
    'inProgress' : 'InProgress',
    'inQueue'    : 'In Queue',
    'verifying'  : 'Verifying',
    'processing' : 'Processing',
    'paid'       : 'Paid to',
    'disputed'   : 'Disputed',
    'declined'   : 'Declined',
    'Cancelled'  : 'Cancelled'
};

class TransactionCard extends Component {
    
    render() {
    const { user, status, payment, coParent, payer_id, receiver_id, payer_username, receiver_username, amount, date} = this.props;
        let normalUser = localStorage.third_party !== 'true';
    let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';
        
    return (
        <Card onClick={this.props.handleInput} bordered={false} className="list-card">
            <div className="req-inner">
                <div className="req-details">
                    <span>
                        {
                            payment && payment.status === 'inProgress'
                            ? (payment.sale_status !== 'paid' ? payment_status[payment.sale_status] : payment_status['processing'])
                            :payment && (payment_status[payment.status] || payment.status)
                        }
                    </span>
                    <span>
                        {normalUser && user ? (payment.status === 'paid' ? receiver_username : payer_username): null}
                        {thirdPartyUser && coParent ? (payment.status === 'paid' ? receiver_username : payer_username): null}
                    </span>
                </div>
                <div className="req-amount">
                    <span>{`$${amount}`}</span>
                    <span>{date}</span>
                </div>
            </div>
        </Card>
    );
    }
};
export default TransactionCard;
