import React, { Component } from 'react';
import 'pages/CalenderInner/Events/Events.scss';
import { Button, Modal, Form, Input, Checkbox, TimePicker,Switch, Select } from 'antd';
import { connect } from 'react-redux';
import Calendar from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import {
    editEvents,
    createEvent,
    userApproval,
    getScheduleById
} from 'services/calendarService';
import { deleteEvents } from 'services/calendarService';
import moment from 'moment';
import DeleteEventModal from 'pages/CalenderInner/Events/DeleteEventModal';
import LoadingOverlay from 'react-loading-overlay';
import DisplayErrorModal from 'pages/CalenderInner/Events/DisplayErrorModal';
import { CalendarOutlined } from '@ant-design/icons';

const { Option } = Select;
function onChange(time, timeString) {}

var c_d = new Date();
var c_year = c_d.getFullYear();
var c_month = c_d.getMonth();
var c_day = c_d.getDate();

let repeats = [
    {
        label: 'Daily',
        value: 'daily',
    },
    {
        label: 'Weekly',
        value: 'weekly',
    },
    {
        label: 'Monthly',
        value: 'monthly',
    },
    {
        label: 'Yearly',
        value: 'yearly',
    }
];

let repeatsLabel = [
    {
        label: 'Weeks',
        value: 'weekly',
    },
    {
        label: 'Month',
        value: 'monthly',
    },
    {
        label: 'Day',
        value: 'daily',
    },
    {
        label: 'Yearly',
        value: 'yearly',
    },
];

let weekDays = [
    {
        label: 'SUN',
        value: 'sunday',
        active: false,
    },
    {
        label: 'MON',
        value: 'monday',
        active: false,
    },
    {
        label: 'TUE',
        value: 'tuesday',
        active: false,
    },
    {
        label: 'WED',
        value: 'wednesday',
        active: false,
    },
    {
        label: 'THU',
        value: 'thursday',
        active: false,
    },
    {
        label: 'FRI',
        value: 'friday',
        active: false,
    },
    {
        label: 'SAT',
        value: 'saturday',
        active: false,
    },
];

class UpdateEventModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            loading: false,
            child: null,
            title: '',
            start_date: new Date(),
            actual_start_date : new Date(),
            end_date: new Date(c_year + 1, c_month, c_day),
            start_time: '',
            end_time: '',
            share: true,
            err: false,
            showDays : false,
            modalVisible: false,
            modal2Visible: false,
            modal3Visible: false,
            modal4Visible: false,
            modal5Visible: false,
            modal6Visible: false,
            modal7Visible: false,
            currentDate: new Date(),
            id: '',
            recurring_start_date: '',
            selectedOption : '',
            exception_days : '',
            event_dd : [],
            errors: {
                title: '',
                date :'',
            },
            old_data : '',
            last_reccuring_date : new Date(),
            isThirdParty : localStorage.getItem("third_party"),
            user_approved : 0,
            created_by_third_party: false,
            third_party_id : '',
            coparent_id : '',
            created_by_me : false,
            action_permission : false,
            show_approve : false,
            confirmModal : false,
            approved : false,

        };

        this.handleRepeats = this.handleRepeats.bind(this);
        this.handleEvery = this.handleEvery.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTitle = this.handleTitle.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.setModal3Visible = this.setModal3Visible.bind(this);
        this.setModal6Visible = this.setModal6Visible.bind(this);
        this.setModal7Visible = this.setModal7Visible.bind(this);
        this.confirmDate = this.confirmDate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleStartTime = this.handleStartTime.bind(this);
        this.handleEndTime = this.handleEndTime.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.validate = this.validate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);
        this.confirmEndDate = this.confirmEndDate.bind(this);
        this.onRecurringChange = this.onRecurringChange.bind(this);
        this.handleDaysVisible = this.handleDaysVisible.bind(this);
        this.approveReject = this.approveReject.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    componentDidMount() {}

    UNSAFE_componentWillReceiveProps = async function(nextProps) {
        const { user } = this.props;
         this.setState({show_approve : false,action_permission : true})
        if (nextProps && nextProps.handleOpen && nextProps.currentEvent && nextProps.currentEvent.Members) {
            
            await this.setState({ loading: true });

            getScheduleById(nextProps.currentEvent.id)
                .then(async event => {
                    await this.setState({ loading: false });
                    if (event) {
                        await this.setState({
                            title: event.title,
                            start_date: moment.parseZone(event.start_date),
                            end_date: moment.parseZone(event.end_date),
                            start_time: event.start_time,
                            end_time: event.end_time,
                            share: event.share,
                            child: event.Members[0] || null,
                            id: event.id,
                            recurring: event.recurring_flag,
                            frequency: event.recurring_frequency,
                            every: event.noOf_frequency,
                            activeButton: event.template,
                            //showDays : event.recurring_frequency == 'daily' && event.noOf_frequency == 1 ? true : false,
                            selectedOption: nextProps.selectedEditOption && nextProps.selectedEditOption, // 0 - Not a recurring event, 1 - selected 1st option(this event), 2 - 2nd option (this and following event)
                            // recurring_start_date: event.recurring_flag ? event.recurring_start_date : '',
                            recurring_start_date : nextProps.currentEvent.recurring_flag ? moment.parseZone(nextProps.currentEvent.recurring_start_date) : '',
                            exception_days: event.recurring_flag ? event.exception_days : '',
                            user_approved: event.user_approved ? event.user_approved : 0,
                            created_by_third_party: event.created_by_third_party ? event.created_by_third_party : false,
                            third_party_id: event.third_party_id ? event.third_party_id : '',
                            created_by_user: event.created_by_user ? event.created_by_user : '',
                            coparent_id: event.coparent_id ? event.coparent_id : '',
                        });

                        if(event.created_by_third_party) {
                            let action_permission = false;
                            if(user && user.id == event.created_by_user && event.user_approved == 1) {
                                action_permission = true;
                            } else if(user && user.id == event.third_party_id && event.user_approved == 0){
                                action_permission = true;
                            }
            
                            if(user && user.id == event.created_by_user && event.user_approved == 0) {
                                this.setState({show_approve : true, share : true})
                            }
                            this.setState({action_permission : action_permission})
                        } else {
                             this.setState({show_approve : false,action_permission : true})
                        }
                        if(event.recurring_flag && nextProps.selectedEditOption == 2){
                            this.handleDaysVisible(event.recurring_frequency)
                        }
            
                        this.setState({old_data : event})
                        if(event.recurring_flag && nextProps.selectedEditOption && nextProps.selectedEditOption != 0) {
                            this.setState({start_date : moment.parseZone(nextProps.currentEvent.recurring_start_date),
                                            actual_start_date : moment.parseZone(nextProps.currentEvent.start_date)})
                        } 
                        if(event.recurring_flag &&  nextProps.lastRecDate) {
                            this.setState({last_reccuring_date : moment.parseZone(nextProps.lastRecDate)})
                        }
                        let temp =  []
                        let tempOptions = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday']
                        if(this.state.activeButton) {
                             this.state.activeButton.map((i,index)=> {
                            if(i == 1) {
                                temp.push(tempOptions[index])
                            }
                        })
                        }
                        this.setState({list : temp})
                       
                        let errors = {
                            title: '',
                            date: '',
                        };
                        this.setState({ errors: errors });
                    }
            })

        } 
    }

    handleDaysVisible = function (value) {
        if((value == 'daily' && this.state.every == 1) || (value == 'weekly')) 
            this.setState({showDays : true})
        else if(value == 'daily' && this.state.every != 1)
            this.setState({showDays : false})
        else
             this.setState({showDays : false})
    };

    handleTitle = function (e) {
        this.setState({ title: e.target.value });
        if (e.target.value === '') {
            this.setState({ title: e.target.value, errors: { ...this.state.errors, title: 'Field is required!' } });
        } else if (e.target.value.length > 50) {
            this.setState({
                title: e.target.value,
                errors: { ...this.state.errors, title: 'Name length value exceeded! (50 symbols)' },
            });
        } else if (!e.target.value.replace(/\s/g, '').length) {
            this.setState({
                title: e.target.value,
                errors: { ...this.state.errors, title: 'Invalid name!' },
            });
        }else if (e.target.value[0] === ' ') {
            this.setState({
                title: e.target.value,
                errors: { ...this.state.errors, title: 'Event name should not start with whitespace character' },
            });
        } else {
            this.setState({ title: e.target.value, errors: { ...this.state.errors, title: '' } });
        }
    };

    formatDate = function (date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return month + '/' + day + '/' + year;
    };

    setModalVisible = function (visible) {
        this.setState({ modalVisible: visible });
    };

    setModal2Visible = function (visible) {
        this.setState({ modal2Visible: visible });
    };

    setModal3Visible = function (visible) {
        this.setState({ modal3Visible: visible });
    };

    setModal4Visible = function (visible) {
        this.setState({ modal4Visible: visible });
    };

    setModal5Visible = function (visible) {
        this.setState({ modal5Visible: visible });
    };

    setModal6Visible = function (visible) {
        this.setState({ modal6Visible: visible });
    };

    setModal7Visible = function (visible) {
        this.setState({ modal7Visible: visible });
    };

    setEndDateModal = function (visible) {
        this.setState({ endDateModal: visible });
    };

    setConfirmModal = function (visible) {
        this.setState({ confirmModal: visible });
    };

    handleChange = function () {};

    handleDate = function (e) {
        if (moment(e).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY')) {
            this.setState({ start_date: e, errors: { ...this.state.errors, date: '' } });
        } else if (moment(e) > moment(new Date())) {
            this.setState({ start_date: e, errors: { ...this.state.errors, date: '' } });
        }
    };

    handleEndDate = function (e) {
        if (moment(e).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY')) {
            this.setState({ end_date: e, errors: { ...this.state.errors, date: '' } });
        } else if (moment(e) > moment(new Date())) {
            this.setState({ end_date: e, errors: { ...this.state.errors, date: '' } });
        }
    }

    handleStartTime = async function (e) {
        const currentTime = (e && e._d) || null;
        if (currentTime !== null) {
            const newTime = moment(currentTime).format('hh:mm a');
            let n = moment(newTime, 'hh:mm a');
            if (currentTime === '') {
                await this.setState({
                    start_time: n,
                    errors: { ...this.state.errors, start_time: 'Field is required' },
                });
            } else {
                await this.setState({ start_time: n, errors: { ...this.state.errors, start_time: '' } });
            }
        }
    };

    handleEndTime = async function (e) {
        const currentTime = (e && e._d) || null;
        if (currentTime !== null) {
            const newTime = moment(currentTime).format('hh:mm a');
            let n = moment(newTime, 'hh:mm a');
            if (currentTime === '') {
                await this.setState({
                    end_time: n,
                    errors: { ...this.state.errors, end_time: 'Field is required' },
                });
            } else {
                await this.setState({ end_time: n, errors: { ...this.state.errors, end_time: '' } });
            }
        }
    };


    handleCheckbox = function (e) {
        let error = '';
        this.setState({ share: !this.state.share });
    };

    confirmDate = async function () {
        const { start_date } = this.state;
        let m = new Date(this.formatDate(start_date));
        let n = moment( m.setTime(moment(m).set({ 'h': 12, 'm': 0, 's': 0 }).valueOf()).valueOf());
        await this.setState({ start_date: n._d.toISOString(), modalVisible: false });
    };

    confirmEndDate = async function () {
        const { end_date } = this.state;
        let m = new Date(this.formatDate(end_date));
        let n = moment( m.setTime(moment(m).set({ 'h': 12, 'm': 0, 's': 0 }).valueOf()).valueOf());
        await this.setState({ end_date:  n._d.toISOString(), endDateModal: false });
    }

    validate = function () {
        const { title, child, start_time, end_time, errors,recurring, selectedOption, end_date, start_date } = this.state;
        let err = {};
        if (title === '') {
            err.title = 'Field is required!';
        } else if (title.length > 50) {
            err.title = 'Name length value exceeded! (50 symbols)';
        }else if (!title.replace(/\s/g, '').length) {
            err.title = 'Invalid name!';
        } else if (title[0] === ' ') {
            err.title = 'Event name should not start with whitespace character';
        } else {
            err.title = '';
        }

        if (child.id === '') {
            err.kids = 'You have to select at least one item';
        } else {
            err.kids = '';
        }
        if(recurring && selectedOption == 2) {
            if(moment(end_date).format('YYYY-MM-DD') <= moment(start_date).format('YYYY-MM-DD')) {
               err.date = 'End date must be more than start date!';
            } else if(moment(end_date).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD')) {
                 err.date = 'End date must be more than start date!';
            } else {
                err.date = '';
            }
        } else {
             err.date = '';
        }

        if (err.title === '' && err.kids === '' && err.date === '' ) {
           this.setState({
                errors: { ...this.state.errors, date: '' },
            });
            let et = typeof end_time === 'string' ? moment(end_time, 'hh:mm a') : end_time;
            let st = typeof start_time === 'string' ? moment(start_time, 'hh:mm a') : start_time;
            if (et <= st ) {
                this.setModal7Visible(true);
            } else {
                this.handleSubmit();
            }
        } else {
            this.setState({ errors: err });
        }
    };

    handleSubmit = async function () {
        this.setState({ loading: true });
        const child_id = [];
        const {clickedDate,reccuringStartDate} = this.props;
        const { title, child, start_date, start_time, end_time, share, errors, id, 
                selectedOption ,old_data, actual_start_date, last_reccuring_date, isThirdParty} = this.state;
        child_id.push(child.id);
        let selectedDate = moment(clickedDate).format('DD-MM-YYYY');
        //let startDate = moment(actual_start_date).format('DD-MM-YYYY');
        let startDate = moment.parseZone(reccuringStartDate).format('DD-MM-YYYY');
        let flag = 0;

        if((selectedDate == startDate && selectedOption == 2) || selectedOption == 0) {
            flag = 1;
        }

        const data = {
            title: title,
            child_ids: child_id,
            picture: null,
            start_date: moment.parseZone(start_date),
            type: 'event',
            start_time: typeof start_time === 'object' ? moment(start_time).format('hh:mm a') : start_time,
            end_time: typeof end_time === 'object' ? moment(end_time).format('hh:mm a') : end_time,
            share: share,
            err: false,
            recurring_flag : selectedOption == 2 ? this.state.recurring  :  false,
            end_date : this.state.end_date,
            recurring_frequency : this.state.frequency,
            noOf_frequency: this.state.every,
            template : this.state.activeButton,
        };
        // if(selectedOption == 2 ) {
        //      data.recurring_flag = this.state.recurring;
        // } else if(selectedDate == startDate && selectedOption == 2) {
        //      data.recurring_flag = this.state.recurring;
        // }
        //  else {
        //     data.recurring_flag = false;
        // }
       
        
        const oldData = {
            title:  old_data.title,
            child_ids:  old_data.child_ids,
            picture: null,
            start_date:  old_data.start_date,
            type: 'event',
            start_time:  old_data.start_time,
            end_time:  old_data.end_time,
            share:  old_data.share,
            err: false,
            recurring_flag :  old_data.recurring_flag,
            end_date :  old_data.end_date,
            recurring_frequency :  old_data.recurring_frequency,
            noOf_frequency:  old_data.noOf_frequency,
            template :  old_data.template,
           
        }

        if(selectedOption == 1 && flag == 0) {
            let ex_date = this.state.recurring_start_date;    
            let drr1 = this.state.exception_days != null ? this.state.exception_days : [];
            var joined = [];
            drr1.push(ex_date)
            await this.setState({ event_dd: drr1 })
            const new_non_rec = {
                    title: title,
                    child_ids: child_id,
                    picture: null,
                    start_date: start_date,
                    type: 'event',
                    start_time: typeof start_time === 'object' ? moment(start_time).format('hh:mm a') : start_time,
                    end_time: typeof end_time === 'object' ? moment(end_time).format('hh:mm a') : end_time,
                    share: share,
                    err: false,
                    recurring_flag :  false,
                    end_date : null,
                    recurring_frequency : null,
                    noOf_frequency: null,
                    template : null,
            }
            if(isThirdParty == 'true') {
                new_non_rec.created_by_third_party = true;
                new_non_rec.third_party_id = this.state.third_party_id;
                new_non_rec.user_approved = 0;
                new_non_rec.created_by_user = this.state.created_by_user;
                new_non_rec.coparent_id = this.state.created_by_user;

                oldData.created_by_third_party = true;
                oldData.third_party_id = this.state.third_party_id;
                oldData.user_approved = 0;
                oldData.created_by_user = this.state.created_by_user;
                oldData.coparent_id = this.state.coparent_id;
            }
            createEvent(new_non_rec)
            .then((resp) => {
                oldData.exception_days = this.state.event_dd;
                oldData.recurring_flag = true;
                
                 editEvents(id, oldData)
                    .then((updateResp) => {
                        if (resp) {
                            this.setState({ loading: false });
                            this.props.handleClose();
                            this.props.refresh();
                        }
                    })
                    .catch((err) => {
                        this.state.err = true;
                        this.setModal3Visible(true);
                    });
            })
        } else if(selectedOption == 2 && flag == 0) {

            const new_rec = {
                title: title,
                child_ids: child_id,
                picture: null,
                start_date: start_date,
                type: 'event',
                start_time: typeof start_time === 'object' ? moment(start_time).format('hh:mm a') : start_time,
                end_time: typeof end_time === 'object' ? moment(end_time).format('hh:mm a') : end_time,
                share: share,
                err: false,
                recurring_flag : this.state.recurring,
                end_date : this.state.end_date,
                recurring_frequency : this.state.frequency,
                noOf_frequency: this.state.every,
                template : this.state.activeButton,
            }
            if(isThirdParty == 'true') {
                new_rec.created_by_third_party = true;
                new_rec.third_party_id = this.state.third_party_id;
                new_rec.user_approved = 0;
                new_rec.created_by_user = this.state.created_by_user;
                new_rec.coparent_id = this.state.created_by_user;


                old_data.created_by_third_party = true;
                old_data.third_party_id = this.state.third_party_id;
                old_data.user_approved = 0;
                old_data.created_by_user = this.state.created_by_user;
                old_data.coparent_id = this.state.coparent_id;
            }
            createEvent(new_rec)
            .then((resp) => {
                old_data.end_date = this.state.recurring_start_date;
                old_data.end_date = last_reccuring_date;
               
                 editEvents(id, old_data)
                    .then((updateResp) => {
                        if (resp) {
                            this.setState({ loading: false });
                            this.props.handleClose();
                            this.props.refresh();
                        }
                    })
                    .catch((err) => {
                        this.state.err = true;
                        this.setModal3Visible(true);
                    });
            })

        } else if (flag == 1) {
            editEvents(id, data)
            .then((resp) => {
                if (resp) {
                    this.setState({ loading: false });
                    this.props.handleClose();
                    this.props.refresh();
                }
            })
            .catch((err) => {
                this.state.err = true;
                this.setModal3Visible(true);
            });
        }
        
    };

    approveReject = function () {
        const {  id, approved,share } = this.state;
        if(!this.state.approved){
            this.setState({share : false})
        } 
        const data = {
            user_approved : approved ? 1 : 2,
            share : share,
        };
        this.setState({loading : true , confirmModal : false})
        userApproval(id, data)
            .then((resp) => {
                if (resp) {
                    this.setState({ loading: false });
                    this.props.handleClose();
                    this.props.refresh();
                }
            })
            .catch((err) => {
                this.state.err = true;
                this.setModal3Visible(true);
            });
    }

    handleDelete = function () {
        const { id } = this.state;
        this.setState({ loading: true });
        if (id) {
            deleteEvents(id)
                .then((resp) => {
                    if (resp) {
                        this.setState({ loading: false });
                        this.props.handleClose();
                        this.props.refresh();
                    } else {
                        this.setState({ loading: false });
                    }
                })
                .catch((err) => {
                    this.state.err = true;
                    this.setModal3Visible(true);
                });
            this.setModal5Visible(false);
        }
    };

    onRecurringChange = function(checked) {
        this.setState({
            recurring: checked,
            errors: { ...this.state.errors, date: '' }
        });
    }

    handleRepeats = async function (value) {
        let val = [1,1,1,1,1,1,1]
        let val2 = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        let val5 = [val2[new Date().getDay()]];
        let val6 = [0, 0, 0, 0, 0, 0, 0];
        val6[new Date().getDay()] = 1;

        this.setState({frequency : value})
        if(value == 'monthly' || value == 'yearly') this.setState({every : 1})

        if(value == 'daily' && this.state.every == 1) 
            this.setState({showDays : true,activeButton: val,
                             list : val2})
        else if(value == 'daily' && this.state.every != 1)
            this.setState({showDays : false,activeButton: [0,0,0,0,0,0,0],
                             list : []})
        else if(value == 'weekly')
            await this.setState({showDays : true,activeButton:  val6,
                             list : val5 });
        else
             this.setState({showDays : false,activeButton : [0,0,0,0,0,0,0], list : []})
    };

    handleEvery = async function (value) {
        let val = [1,1,1,1,1,1,1]
        let val2 = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        let val5 = [val2[new Date().getDay()]];
        let val6 = [0, 0, 0, 0, 0, 0, 0];
        val6[new Date().getDay()] = 1;

        await this.setState({ every: value });
        if (value == 1 && this.state.frequency == 'daily') {
            this.setState({
                showDays: true,
                activeButton: val,
                list: val2
            })
        }
        else if (this.state.frequency == 'daily' && value != 1) {
            this.setState({
                showDays: false,
                activeButton: [0, 0, 0, 0, 0, 0, 0],
                list: []
            })
        }
        else if (this.state.frequency == 'weekly') {
            await this.setState({
                showDays: true,
                activeButton: val6,
                list: val5
            })
        }
        else {
            this.setState({
                showDays: false,
                activeButton: [0, 0, 0, 0, 0, 0, 0],
                list: []
            });
        }
    }

    async handleCheckboxChange(checked, index) {
        if(!this.state.list.includes(checked)) {
              this.setState(prevState => ({
                list: [...prevState.list, checked]
            }))
              let activeButton = [...this.state.activeButton];
              activeButton[index] = 1;
              this.setState({activeButton});

        } else {
            let flag = 0
            let len 
            if(this.state.frequency == 'daily' && this.state.every == 1) {  
                flag = 1 //cant deselect week days
            }
            if(this.state.frequency == 'weekly') {
                flag = 2 //atleast one week day should be selected
            }
        
            if(flag == 0) {
                let remove = this.state.list.indexOf(checked);
                this.setState({
                    list: this.state.list.filter((_, i) => i !== remove)
                 })
                let activeButton = [...this.state.activeButton];
                activeButton[index] = 0;
                this.setState({activeButton});
            } else if(flag == 2) {
                let remove = this.state.list.indexOf(checked);
                if(this.state.list && this.state.list.length > 1) { //atleast one week day should be selected
                    this.setState({
                        list: this.state.list.filter((_, i) => i !== remove)
                    })
                    let activeButton = [...this.state.activeButton];
                    activeButton[index] = 0;
                    this.setState({activeButton});
                }
                
            }
            
        }
      
    }

    closePopup = async function () {
        this.props.handleClose();
        await this.setState({
            loading: false,
            child: null,
            title: '',
            start_date: new Date(),
            actual_start_date: new Date(),
            end_date: new Date(c_year + 1, c_month, c_day),
            start_time: '',
            end_time: '',
            share: true,
            err: false,
            showDays: false,
            modalVisible: false,
            modal2Visible: false,
            modal3Visible: false,
            modal4Visible: false,
            modal5Visible: false,
            modal6Visible: false,
            modal7Visible: false,
            currentDate: new Date(),
            id: '',
            recurring_start_date: '',
            selectedOption: '',
            exception_days: '',
            event_dd: [],
            errors: {
                title: '',
                date: '',
            },
            old_data: '',
            last_reccuring_date: new Date(),
            isThirdParty: localStorage.getItem("third_party"),
            user_approved: 0,
            created_by_third_party: false,
            third_party_id: '',
            coparent_id: '',
            created_by_me: false,
            action_permission: false,
            // show_approve : false,
            confirmModal: false,
            approved: false,
        });
    }

    render() {
        const {
            title,
            child,
            start_date,
            start_time,
            end_time,
            share,
            currentDate,
            errors,
            err,
            loading,
            recurring,
            end_date,
            frequency,
            every,
            activeButton,
            showDays,
            list,
            endDate,
            selectedOption,
            old_data,
            user_approved ,
            created_by_third_party,
            third_party_id,
            created_by_me,
            action_permission,
            created_by_user,
            show_approve,
            isThirdParty,
        } = this.state;  
        const { user } = this.props;


        if (!this.props.handleOpen) {
            return null;
        } else {
            return (
                <Modal
                    title={(this.props.hideSubmit && show_approve == false || (created_by_third_party === true && action_permission == false && show_approve == false)) ? "Setup Event" : show_approve == true ? 'Approve/Reject' : "Edit Event"}
                    className="cal-modal"
                    centered
                    maskClosable={false}
                    visible={this.props.handleOpen}
                    onOk={this.props.handleClose}
                    onCancel={() => this.closePopup()}
                >
                    <LoadingOverlay
                        active={loading ? true : false}
                        className={loading ? 'loader-wrap pop-loader' : ''}
                        spinner
                        styles={{
                            spinner: (base) => ({
                                ...base,
                                width: '100px',
                                '& svg circle': {
                                    stroke: 'rgb(56, 160, 235)',
                                },
                            }),
                        }}
                    ></LoadingOverlay>
                    <Form>
                        <div className="scroll-wrap event-height">
                            <Form.Item className="input-sm">
                                <Input placeholder="Enter event title" value={title} readOnly={this.props.hideSubmit || show_approve || (created_by_third_party === true && action_permission == false)} onChange={this.handleTitle} />
                                {errors.title !== '' ? <div style={{ color: 'red' }}>{errors.title}</div> : null}
                            </Form.Item>
                            <Form.Item className="child-name">
                                <Input className="inputName p-0" value={child && child.name} readOnly />
                            </Form.Item>
                            <Form.Item className="input-col icon-input" label="Start Date:">
                                <Input
                                    readOnly={this.props.hideSubmit || show_approve}
                                    value={moment.parseZone(start_date).format('MM/DD/YYYY')}
                                    onClick={() => this.props.hideSubmit || show_approve || (created_by_third_party === true && action_permission == false) ? null : this.setModalVisible(true)}
                                    suffix={<CalendarOutlined onClick={() => this.props.hideSubmit || show_approve || (created_by_third_party === true && action_permission == false) ? null : this.setModalVisible(true)} />}
                                />
                            </Form.Item>
                        
                            <Form.Item className="input-col time-picker" label="Start Time:">
                                <TimePicker
                                    suffixIcon
                                    allowClear={false}
                                    use12Hours
                                    value={moment(start_time, 'hh:mm A')}
                                    format="h:mm A"
                                    disabled={this.props.hideSubmit || show_approve || (created_by_third_party === true && action_permission == false)}
                                    popupClassName="cust-timepicker"
                                    onChange={(e) => this.handleStartTime(e)}
                                />
                            </Form.Item>
                            <Form.Item className="input-col time-picker" label="End Time:">
                                <TimePicker
                                    suffixIcon
                                    allowClear={false}
                                    use12Hours
                                    value={moment(end_time, 'hh:mm A')}
                                    format="h:mm A"
                                    disabled={this.props.hideSubmit || show_approve || (created_by_third_party === true && action_permission == false)}
                                    popupClassName="cust-timepicker"
                                    onOk={() => this.setModal4Visible(false)}
                                    onChange={(e) => this.handleEndTime(e)}
                                />
                            </Form.Item>
                            {selectedOption == 2 && (
                                <Form.Item className="input-col " label="Recurring">
                                    <Switch onChange={this.onRecurringChange} disabled={this.props.hideSubmit} checked={recurring} />
                                </Form.Item>
                            )}
                            {this.state.recurring && selectedOption == 2 && (
                                <Form.Item className="input-col icon-input" label="End Date:">
                                    <Input
                                        readOnly={this.props.hideSubmit}
                                        value={this.formatDate(end_date)}
                                        onClick={() => this.props.hideSubmit ? null : this.setEndDateModal(true)}
                                        suffix={<CalendarOutlined onClick={() => this.props.hideSubmit ? null : this.setEndDateModal(true)} />}
                                    />
                                </Form.Item>
                            )}
                            {errors.date !== '' && recurring ? <div style={{ color: 'red', fontSize: '12px', textAlign: 'right' }}>{errors.date}</div> : null}
                            {this.state.recurring && selectedOption == 2 && (
                                <div>
                                    <Form.Item className="input-col select-sm " label="Repeats">
                                        <Select disabled={this.props.hideSubmit} placeholder="Weekly" value={frequency ? frequency : 'weekly'} onChange={this.handleRepeats}>
                                            {Array.isArray(repeats) &&
                                                repeats.map((kid, i) => (
                                                    <Option key={i} className={''} value={kid.value}>
                                                        {kid.label}
                                                    </Option>
                                                ))}
                                        </Select>
                                        {/*{errors.kids !== '' ? <div style={{color: 'red', fontSize: '12px'   }}>{errors.kids}</div> : null}*/}
                                    </Form.Item>
                                    <Form.Item className="input-col select-sm freq-select" label="Every">
                                        <Select disabled={this.props.hideSubmit} placeholder="Select" value={every} onChange={this.handleEvery}>
                                            {Array.from(Array(this.state.frequency == 'daily' ? 7 : this.state.frequency == 'weekly' ? 4 : 1), (e, i) => {
                                                return <Option className={''} value={i + 1} key={i}>
                                                    {i + 1}
                                                </Option>
                                            })}
                                        </Select>

                                        <Select placeholder="Select" value={frequency} disabled>
                                            {Array.isArray(repeatsLabel) &&
                                                repeatsLabel.map((kid, i) => (
                                                    <Option key={i} className={''} value={kid.value}>
                                                        {
                                                            frequency === 'daily' ?
                                                                (every > 1 ? 'Days' : kid.label)
                                                                : frequency === 'weekly' ?
                                                                    (every === 1 ? 'Week' : kid.label)
                                                                    : kid.label
                                                        }
                                                    </Option>
                                                ))}
                                        </Select>

                                        {/*{errors.kids !== '' ? <div style={{color: 'red', fontSize: '12px'   }}>{errors.kids}</div> : null}*/}
                                    </Form.Item>
                                    {showDays && selectedOption == 2 && (
                                        <Checkbox.Group>
                                            <span className="marked-list-label">Select the day of events:</span>
                                            <ul className="ul-custom marked-list">
                                                {weekDays.map((day, i) => (
                                                    <li key={i}>
                                                        <Button type="checkbox" disabled={this.props.hideSubmit} value={day.value} onClick={value => this.handleCheckboxChange(day.value, i)} className={this.state.list && this.state.list.includes(day.value) ? 'click-color' : ''} >{day.label}</Button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Checkbox.Group>
                                    )}
                                </div>
                            )}
                            {((this.props.hideSubmit || isThirdParty == 'true') && !show_approve) ? null : (
                                <Form.Item className="cb-form">
                                    <Checkbox disabled={this.props.hideSubmit && !show_approve} checked={share} onChange={this.handleCheckbox}>
                                        <span className="cb-text">Share with Co-parent</span>
                                    </Checkbox>
                                </Form.Item>
                            )}
                        </div>
                        <div className="btn-scroll-wrap">
                            {(this.props.hideSubmit === true || (created_by_third_party === true && action_permission == false)) ? null : (
                                <div>
                                    <Button onClick={this.validate} className="full-btn submit-btn" type="primary">
                                        Submit
                                    </Button>
                                </div>
                            )}
                            {(!old_data.recurring_flag && (action_permission == true)) && (
                                <Form.Item>
                                    <Button
                                        onClick={() => this.setModal6Visible(true)}
                                        className="full-btn remove-btn"
                                        type="primary"
                                    >
                                        Remove
                                    </Button>
                                </Form.Item>
                            )}

                            {(created_by_third_party === true && show_approve) && (
                                <div>
                                    <Button onClick={() => {
                                        this.setState({ approved: true }, () => {
                                            this.setConfirmModal(true);
                                        });
                                    }} className="full-btn submit-btn" type="primary">
                                        Approve
                                    </Button>
                                    <Button onClick={() => {
                                        this.setState({ approved: false }, () => {
                                            this.setConfirmModal(true);
                                        });
                                    }} className="full-btn  remove-btn" type="primary">
                                        Reject
                                    </Button>
                                </div>
                            )}

                            {err ? (
                                <Modal
                                    title="Connection error - please try again, or check your internet connection."
                                    visible={this.state.modal3Visible}
                                    className="error-popup"
                                    onOk={() => this.setModal3Visible(false)}
                                    onCancel={() => this.setModal3Visible(false)}
                                    maskClosable={false}
                                >
                                    <Button className="btn" onClick={this.props.handleClose}>
                                        OK
                                    </Button>
                                </Modal>
                            ) : null}
                        </div>
                    </Form>
                    <Modal
                        title="Calendar"
                        className="cal-modal swap-modal sc-modal"
                        centered
                        visible={this.state.modalVisible}
                        onOk={() => this.setModalVisible(false)}
                        onCancel={() => this.setModalVisible(false)}
                    >
                        <div className="edit-wrap cal-custom">
                            <div className="scroll-wrap">
                                <div className="cal-wrap">
                                    <Calendar
                                        isEvent={true}
                                        selected={start_date}
                                        onChange={(e) => this.handleDate(e)}
                                    />
                                </div>
                            </div>
                            <div className="btn-scroll-wrap">
                                <Form.Item className="btn-top">
                                    <Button onClick={this.confirmDate} className="fp-button-primary" type="primary">
                                        Confirm
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        title="Calendar"
                        className="cal-modal swap-modal sc-modal"
                        centered
                        visible={this.state.endDateModal}
                        onOk={() => this.setEndDateModal(false)}
                        onCancel={() => this.setEndDateModal(false)}
                    >
                        <div className="edit-wrap cal-custom">
                            <div className="scroll-wrap">
                                <div className="cal-wrap">
                                    <Calendar
                                        isEvent={true}
                                        selected={end_date}
                                        onChange={(e) => this.handleEndDate(e)}
                                    />
                                </div>
                            </div>
                            <div className="btn-scroll-wrap">
                                <Form.Item className="btn-top">
                                    <Button onClick={this.confirmEndDate} className="fp-button-primary" type="primary">
                                        Confirm
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Modal>
                    <DeleteEventModal
                        delete={() => this.handleDelete()}
                        handleOpen={this.state.modal6Visible}
                        handleClose={() => this.setModal6Visible(false)}
                    />
                    <DisplayErrorModal
                        openModal={this.state.modal7Visible}
                        closeModal={() => this.setModal7Visible(false)}
                    />

                    <Modal
                        title={recurring ? "This action will affect all events in the series" : "Confirm"}
                        centered
                        maskClosable={false}
                        visible={this.state.confirmModal}
                        className="payments-popup custom-popup del-popup"
                        onOk={() => this.setConfirmModal(false)}
                        onCancel={() => this.setConfirmModal(false)}
                    >
                      
                        <Button type="primary" onClick={this.approveReject} className="btn fp-button-primary">
                            Confirm
                        </Button>

                        <Button type="primary" onClick={() => this.setConfirmModal(false)} className="btn fp-button-secondary">
                            Cancel
                        </Button>
              
                    </Modal>
                </Modal>
            );
        }
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, loadings, error, coparent } = serviceReducer;
    return { children, user, loadings, error, coparent };
};

export default connect(mapStateToProps)(UpdateEventModal);
