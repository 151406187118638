import React, { Component } from 'react';
import 'pages/Household/Children/Children.scss';
import { Button, Modal, Form, Input, Avatar, Col, DatePicker, Select } from 'antd';
//import Calendar from 'react-calendar';
import Moment from 'moment';
import { getHouseHoldAction, getThirdPartyAction } from 'actions/houseHoldAction.js';
import userImg from 'Assets/defaultAvatar.png';
import add from 'Assets/add.svg';
import { getMeUserAction, getChildrenUserAction ,getCheckListAction} from 'actions/userActions';
import { addChildren, editChildren, deleteChildren } from '../../../services/houseHoldService';
import { connect } from 'react-redux';
import { checkCookie } from 'utils/cookies';
import LoadingOverlay from 'react-loading-overlay';
import Calendar from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import 'react-calendar/dist/Calendar.css';
import { CalendarOutlined } from '@ant-design/icons';
import AddCoparent from './CoparentModal';
import { getCoParentsUserAction } from 'actions/userActions';
import { getSetupProgress} from 'services/userService';

let colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    gradient1: 'Gradient1',
    gradient2: 'Gradient2',
};

class ViewChildren extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coparentTp : '',
            isCreator : true,
        };
    }
 
 
    async componentDidMount() {
        const { response ,data} = this.props;
        let coparentTp ;

        if(localStorage.getItem("third_party") == 'true') {
           coparentTp = response.houseHoldReducer && response.houseHoldReducer.thirdPartyData&& response.houseHoldReducer.thirdPartyData.coparent[0]
            this.setState({coparentTp})
            
        }
    }

    componentWillReceiveProps(props) {
        const { response, data } = this.props;
        let coparentTp ;
       if(localStorage.getItem("third_party") == 'true') {
           coparentTp = response.houseHoldReducer && response.houseHoldReducer.thirdPartyData&& response.houseHoldReducer.thirdPartyData.coparent[0]
            this.setState({coparentTp})
            
        }
    }

    render() {
        const colorArr = [
            { colorName: 'Pink', colorCodeIs: '#c910e8' },
            { colorName: 'Blue', colorCodeIs: '#2196f3' },
            { colorName: 'Green', colorCodeIs: '#4caf50' },
            { colorName: 'Red', colorCodeIs: '#ff0000' },
            { colorName: 'Purple', colorCodeIs: '#673ab7' },
            { colorName: 'Orange', colorCodeIs: '#ffc107' },
            { colorName: 'Cyan', colorCodeIs: '#00bcd4' },
            { colorName: 'Black', colorCodeIs: '#000000' },
            { colorName: 'Brown', colorCodeIs: '#795548' },
            { colorName: 'White', colorCodeIs: '#ffffff' },
            { colorName: 'Grey', colorCodeIs: '#999999' },
            { colorName: 'Charcoal', colorCodeIs: '#333333' },
            { colorName: 'Gradient 1', colorCodeIs: 'gradient1' },
            { colorName: 'Gradient 2', colorCodeIs: 'gradient2' },
        ];

        const genderArr = [{ gender: 'Male',value: 'male' }, { gender: 'Female',value: 'female' }, { gender: 'Non Binary',value : 'nonbinary' }];

        const {  coparentTp} = this.state;
        const { data } = this.props;
        let isCreator = (data.created_by_user === (localStorage.getItem("coparent_id")));
        let colorCode = data.color ? data.color : '';
        let coparentColorCode = data.coparent_color ? data.coparent_color : '';

        return (
            <React.Fragment>
                <Modal
                    centered
                    visible={this.props.viewVisible}
                    backdrop="static"
                    maskClosable={false}
                    className={'modal-container add-child-popup'}
                    onCancel={() => this.props.hadleClose(false,null)}
                >
                    {this.state.LoadingIs && (
                        <LoadingOverlay
                            active={true}
                            className="d-block pop-loader"
                            spinner
                            styles={{
                                spinner: (base) => ({
                                    ...base,
                                    width: '100px',
                                    '& svg circle': {
                                        stroke: 'rgb(56, 160, 235)',
                                    },
                                }),
                            }}
                        ></LoadingOverlay>
                    )}
                    <div className="modal-head">
                        <h5 className="del-head">{`${
                            `View Children`
                        }`}</h5>
                    </div>
                 
                        <Form >
                            <div className="head-avatar">
                                <div className="img-upload position-r wid-box">
                                    <Avatar
                                        src={data.file_url ? data.file_url : userImg}
                                        alt="user-avatar"
                                    />
                                   
                                </div>
                            </div>
                            <Col className="col-wrap cust-selct">
                                <div className={`input-full`}>
                                    <Input
                                        placeholder="Name/Nickname"
                                        name="name"
                                        value={ data.name}
                                        autoComplete="off"
                                    />
                                </div>
                            </Col>
                            <Col className="col-wrap cust-selct input-dob">
                                <div className='input-full'>
                                   
                                    <DatePicker
                                        className="custom-dp"
                                        inputReadOnly="true"
                                        value={data.dob ? Moment(data.dob) : ''}
                                        placeholder="Date of Birth"
                                        size="large"
                                    />
                                     
                                </div>
                            </Col>
                            <div className="select-wrap">
                                <Col span={12} className="col-wrap cust-selct">
                                    <div className={`box-slt-box pos-relative select-dot-kid`}>
                                        <Select
                                            name="colorCode"
                                            value={isCreator? colorCode: coparentColorCode}
                                            onChange={this.handleColor}
                                            className={`slt-2-box`}
                                            
                                        >
                                            <option value="">Dot Color</option>
                                            {colorArr.map((obj, index) => (
                                               
                                                <option
                                                    style={{
                                                        display: 'inline-block',
                                                        color: isCreator? (obj.colorCodeIs === colorCode ? '#2dadcc' : '#333') : (obj.colorCodeIs === coparentColorCode ? '#2dadcc' : '#333'),
                                                    }}
                                                    selected={isCreator? colorCode === obj.colorCodeIs : coparentColorCode === obj.colorCodeIs}
                                                    value={obj.colorCodeIs}
                                                    key={index}
                                                >
                                                    <span className={'dot ' + colors[obj.colorCodeIs]}></span>
                                                    {obj.colorName}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                </Col>
                                <Col span={12} className="col-wrap cust-selct ">
                                    <div className="box-slt-box pos-relative">
                                        <Select
                                            name="gender"
                                            value={data.gender}
                                            onChange={this.handleGender}
                                            className="slt-2-box"
                                        >
                                            <option value="">Gender</option>
                                            {genderArr.map((obj, index) => (
                                                <option
                                                    style={{ color:  '#333' }}
                                                    value={obj.value}
                                                    key={index}
                                                >
                                                    {obj.gender}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                </Col>
                            </div>
                            <div className="input-full">
                                <Col className="col-wrap cust-selct select-icon">
                                    <div className="box-slt-box pos-relative">
                                        <Select
                                            name="coParent"
                                            value={coparentTp && coparentTp[0].first_name}
                                            className="slt-2-box"
                                        >
                                            <option value="">Co-parent</option>
                                            
                                        </Select>
                                        
                                    </div>
                                     
                                </Col>
                            </div>
                        </Form>
                    
                </Modal>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (response) => ({ response });
export default connect(mapStateToProps)(ViewChildren);
