import * as QB from "quickblox/quickblox";

export const GetAllDialogs = async () => {

    var filters = { limit: 150 };

    return new Promise(resolve => {
        QB.chat.dialog.list(filters, function (error, dialogs) {
            resolve(dialogs);
        });
    })
}


export const CreateGroupDialog = async (occupantIds, groupName) => {

    var params = {
        type: 2,
        occupants_ids: occupantIds,
        name: groupName
    };

    return new Promise(resolve => {
          
        QB.chat.dialog.create(params, function(error, conversation) {
            resolve(conversation);
        });    
    });
  };