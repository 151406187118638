import React, { Component } from 'react';
import 'pages/CalenderInner/Custody/Custody.scss';
import { Card, Button, Modal, Form, Input, Select, Collapse, Empty } from 'antd';
import more from 'Assets/more.svg';
import { CalendarOutlined } from '@ant-design/icons';
import Calendar from 'pages/CalenderInner/Custody/react-calendar/src';
import { connect } from 'react-redux';
import { getCustody } from 'services/calendarService'; 
import CreateCustodyModal from 'pages/CalenderInner/Custody/CreateCustodyModal';
import CustodyTemplateModal from 'pages/CalenderInner/Custody/CustodyTemplateModal';
import UpdateCustodyModal from 'pages/CalenderInner/Custody/UpdateCustodyModal';
import EditCustodyModal from 'pages/CalenderInner/Custody/EditCustodyModal';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';

const { Panel } = Collapse;

const custody_status = {
    'pending': 'Pending',
    'declined': 'Declined',
    'approved': 'Approved',
};
const colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    'gradient1': 'Gradient1',
    'gradient2': 'Gradient2',
};
const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'Dec',
];

class Custody extends Component {
    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            modal2Visible: false,
            modal3Visible: false,
            modal4Visible: false,
            offset: 0,
            coparent: null,
            custody: [],
            loading: false,
            currentCustody: null,
        };
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.setModal3Visible = this.setModal3Visible.bind(this);
        this.setModal4Visible = this.setModal4Visible.bind(this);
        this.getCalendarCustody = this.getCalendarCustody.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.formateDate = this.formateDate.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    
    componentDidMount() {
        let offset = 0;
        this.getCalendarCustody(offset, this.props.coparent, true);
    }


    componentDidUpdate(prevProps) {
        if(this.props.coparent !== prevProps.coparent) {
            let offset = 0;
            let arr = [];
            this.setState({ custody: arr });
            this.getCalendarCustody(offset, this.props.coparent, true);
        }
    }

    handleScroll = function(e) {
        const { offset, coparent } = this.state;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if(bottom) {
            this.getCalendarCustody(offset, coparent, false);
        }
    }

    refreshPage = function(){
        let offset = 0;
        this.getCalendarCustody(offset, this.props.coparent, true);
    }

    getCalendarCustody = function(offset, coparent, flag) {
        const { custody } = this.state;
        this.setState({loading: true});

        const params = {
            offset: offset,
            limit: 10,
            coparent_id: (coparent && coparent.id) || null,
        };

        this.setState({offset: offset, coparent: coparent || null});
        getCustody(params)
        .then(resp => {
            if(resp) {
                resp.offset += 10;
                this.setState({offset: resp.offset, coparent: { id: resp.coparent } });

                if (flag) {
                    let arr = [];
                    arr = [...arr, ...resp.custody];
                    this.setState({ custody: arr, loading: false});
                } else {
                    let arr = [];
                    arr = [...custody, ...resp.custody];
                    this.setState({ custody: arr, loading: false });
                }
            } else {
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({ loading: false });
        })
    }

    handleClick = function(custody) {
        const { user } = this.props;
        if(user.id === custody.created_by_user) {
            this.setModal2Visible(true, custody);
        } else if(user.id === custody.coparent_id) {
            this.setModal3Visible(true, custody);
        }
    }

    formateDate = function(d){
       let date = new Date(d);
       let month = monthNames[date.getMonth()];
       let day = date.getDate();
       let year = date.getFullYear();
       
       return month + ' ' + day + ', ' + year;
    }
    
    setModalVisible = function(visible) {
        this.setState({ modalVisible: visible });
    }
    setModal2Visible = function(visible, item) {
        this.setState({ modal2Visible: visible, currentCustody: item});
    }
    setModal3Visible = function(visible, item) {
        this.setState({ modal3Visible: visible, currentCustody: item});
    }
    
    setModal4Visible = function(visible) {
        this.setState({ modal4Visible: visible});
    }

    render() {
        const { custody, loading, currentCustody } = this.state;
        const { user } = this.props;
        
        function getColor(item){
            return (item.created_by_user === (user && user.id))?item.color: item.coparent_color;
        }


        return (
            <Card bordered={false} title="Custody Schedule" className="custody">
                 <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap cust-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Button
                    type="primary"
                    onClick={() => this.setModalVisible(true)}
                    className="fp-button-secondary events-create"
                >
                    Create
                </Button>
                
                <div onScroll={this.handleScroll} className="custody-wrap">
                    {Array.isArray(custody) && custody.length !== 0 ? custody.map((item, i) => (
                    <Card key={i} bordered={false} onClick={() => this.handleClick(item)}>
                        <div className="card-left">
                            <div className="title">
                                <span>{item.title}</span>
                            </div>
                            <div className="date">
                                <span>Starts: </span>
                                <span>{moment.parseZone(item.start_date).format('MMM DD, YYYY')}</span>
                            </div>
                            <div className="repeat">
                            <span>{item.type == 'irr'&& (
                                'Repeats Irregulary'
                            )}
                            </span>
                            <span>{item.type !== 'irr' && item.duration !== 1  && (
                                     `Repeats Every: ${item.duration} day(s)`
                                    )} 
                            </span>
                            <span>{item.duration === 1  && (
                                    'Full time Custody'
                                    )}
                            </span>

                               {/* <span>{item.duration == 1 ? 'Full time Custody' : item.irregular_dates.length !== 0 ? 'Repeats Irregularly' : 'Repeats Every: '}</span>
                                <span>{item.duration == 1 ? null : item.irregular_dates.length !== 0 ? null : `${item.duration} days`} </span> */}
                            </div>
                        </div>
                        <div className="card-right">
                            <div className="name kid-color-card">
                                <span className={"list-dot " + colors[getColor(item.Members[0])]}></span>
                                <span>{item.Members[0] && item.Members[0].name}</span>
                                
                            </div>
                            <div className="icon">
                            </div>
                            <div className="status">
                                <span>{custody_status[item.status]}</span>
                            </div>
                        </div>
                    </Card>
                     )) : (
                        <div>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                    )}
                </div>
                <CreateCustodyModal
                  refresh={() => this.refreshPage()}
                  handleOpen={this.state.modalVisible}
                  handleClose={() => this.setModalVisible(false)}
                />
                <UpdateCustodyModal
                  refresh={() => this.refreshPage()}
                  currentCustody={currentCustody}
                  handleOpen={this.state.modal3Visible}
                  handleClose={() => this.setModal3Visible(false)}
                />
                <EditCustodyModal
                  refresh={() => this.refreshPage()}
                  currentCustody={currentCustody}
                  handleOpen={this.state.modal2Visible}
                  handleClose={() => this.setModal2Visible(false)}
                />
            </Card>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, loadings, error, coparent } = serviceReducer;
    return { children, user, loadings, error, coparent };
};

export default connect(mapStateToProps)(Custody);
