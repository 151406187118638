export const ROUTES = {
    LANDING_PAGE: '/',
    HOME: '/home',
    HOUSEHOLD: '/household',
    PAYMENT: '/payment',
    SUBSCRIPTION: '/subscription',
    CALENDERINNER: '/calendar',
    SUBSCRIPTIONDETAILS: '/subscriptionDetails',
    FORGOTPASSWORD: '/forgotpassword',
    RESETPASSWORD: '/resetPassword',
    SIGNUP: '/signup',
    SIGNUPTRIAL: '/signuptrial',
    ERRORPAGE: '/errorpage',
    CHAT: '/chat',
    CHAT_ID: '/chat/:id',
    CHAT_NEW: '/chat/new',
    CHAT_TOPIC: '/chat/topic',
    CHAT_ARCHIVED: '/chat/archived',
    CHAT_ARCHIVED_ID: '/chat/archived/:id',
    REPORTS: '/reports',
    NOTIFICATIONS: '/notifications'
};

export const MAIN_MENU = [
    {
        label: 'Setup Household',
        iconsrc: 'home',
        path: ROUTES.HOME,
        hideMenuForMobileMvp: false,
        children: [],
        disabled: false,
    },
    {
        label: 'Communication',
        iconsrc: 'safety',
        path: ROUTES.HOME,
        hideMenuForMobileMvp: false,
        children: [],
        disabled: false,
    },
    {
        label: 'My Profile',
        iconsrc: 'dollar',
        path: ROUTES.HOME,
        hideMenuForMobileMvp: false,
        children: [],
        disabled: false,
    },
];
