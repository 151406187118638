import { Modal, Button } from 'antd';
import React, {Component} from 'react';
import 'Components/Modal/ModalStyles.scss';
import 'antd/dist/antd.css';
import { BorderTopOutlined } from '@ant-design/icons';

class ConnectionModal extends Component {
  

  render() {
    return (
        <Modal
          title="Connection error - please try again, or check your internet connection."
          visible={this.props.handleOpen}
          maskClosable={false}
          className="error-popup"
          onOk={this.props.handleClose}
          onCancel={this.props.handleClose}
        >
          <Button className="btn" onClick={this.props.handleClose}>OK</Button>
        </Modal>
      
    );
  }
}

export default ConnectionModal;