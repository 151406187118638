import React, { Component } from 'react';
import 'pages/Payment/Register/Register.scss';
import { Card, Button, Modal, Select, Input, Checkbox } from 'antd';


class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);

    }


    render() {
        return (
            <Modal
                centered
                title="Kincrew Privacy Policy"
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
                className="privacy-policy-popup"
            >
                <div className="privacy-wrap">
                    <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
                        <strong>
                            THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION AND WAIVER OF
                            JURY TRIAL. PLEASE READ IT CAREFULLY BEFORE AGREEING.
                        </strong>
                    </div>

                    <div className="privacy-rights-text">Your Privacy Rights</div>

                    <p style={{marginTop: '20px'}}>Effective Date: October 2020</p>

                    <p>OUR PRIVACY COMMITMENT TO YOU IS SIMPLE:</p>
                    <ol className="policy-list" type = "1">
                        <li>We are not in business to sell your personal information.</li>
                        <li>We built our company and technology to make your life easier, not more complicated. So, we only use your personal information for the defined, appropriate purpose, of delivering our Services in a manner that meets - and hopefully exceeds - your expectations.</li>
                        <li>We adhere to the highest legal standards where we operate, and have committed to continuously improve what we do as privacy laws and regulations change.</li>
                        <li>We work with ISO (International Organization for Standardization), American, Canadian, European, and other borderless best practices guidance to make sure that your privacy rights aren’t lost in the shuffle of competing interests.</li>
                        <li>We care about your privacy and want to hear from you if you have questions, comments, or concerns. Contact us at <a href="mailto:privacy@kincrew.io">privacy@kincrew.io</a></li>
                        <li>X2X, Inc. supports the principles and values of Privacy by Design. Privacy by Design provides guidance regarding the protection of consumer privacy. For more information about Privacy by Design, please visit https://iapp.org/media/pdf/resource_center/pbd_implement_7found_principles.pdf</li>
                    </ol>
                    <p>
                        This Privacy Policy, together with any additional notices of privacy practices posted separately (“Privacy Policy” or “Policy”) describes the types of information gathered by X2X, Inc., doing business as Kincrew App, or Kincrew, (“X2X”, “us” or “we”) in the process of providing this website, 
                        mobile application, services, information, tools, functionality, updates and similar materials (collectively, the “Service(s)”), how we use it, with whom the information may be shared, what choices are available to you regarding collection, and how use and distribution of information and our
                        efforts to protect the information you provide to us through the Services works.
                    </p>
                    <p>
                        By using the Services, you hereby consent to allow us to process information in accordance with this Policy.
                    </p>

                    <p>
                        This Policy is subject to updates and improvements, including updates made as a result of your feedback.
                        If our information retention or usage practices change, we will let you know by posting the Privacy Policy 
                        changes on the Services and/or otherwise making you aware of the changes.Please refer to the “Effective Date” 
                        above to see when this Policy was last updated.
                    </p>

                    <p>
                        In particular, we recognize the sensitive and confidential nature of the information which may be shared between users of our Service.
                        We are making every reasonable effort to secure your data and have engaged an Advisory Committee so that we follow and maintain the best practices in the industry.
                    </p>

                    <div className="privacy-head">BACKGROUND</div>

                    <p>
                        Our Services include tools designed to help you and your co-parent, guardian, and / or other legally-recognized co-care-taker(s)(also a “Partner”) manage communication, parenting plans and scheduling,
                        shared expenses, contacts and other critical information, and send and receive payments to one another. In order for you to use the Services effectively, you will need to provide us with information 
                        about your family, your schedules and your parenting responsibilities,as well as any shared expenses, contacts or other information that you would like to manage via the Services. You will also have the option to upload,
                        store and search your relevant documents (such as documents related to scheduling, court orders, and agreements),as well as related information, that is legally required or practically-needed to be shared between Partners 
                        with shared custody and / or supervision responsibilities, which we will store on our server.
                    </p>

                    <p>
                        Your account and that of your Partner will be connected so that you both will have access to the Services in order to manage and facilitate your interactions. You will control what information is shared with your Partner.
                        To this end, you are enouraged to post and share responsibly. Also, you will be provided with means to edit your sharing preferences for certain documents, uploads, or lists.
                        Similarly, you will have the ability to share certain information with others, such as other family members, caretakers, Family,
                        marriage therapists, other medical personnel, attorneys and financial professionals such as accountants.
                    </p>

                    <div className="privacy-head">Territoriality</div>

                    <p>
                        If you sign up for our Services in the USA or are given access to our services through an American affiliate, then regardless of where you or our servers are located, your personal data may be processed by us in the United States.
                        BY VISITING THE WEBSITE AND USING THE SERVICES, YOU UNEQUIVOCALLY AND UNAMBIGUOUSLY CONSENT TO THE COLLECTION AND PROCESSING IN THE UNITED STATES OF ANY INFORMATION COLLECTED OR OBTAINED BY US THROUGH VOLUNTARY SUBMISSIONS,
                        AND THAT U.S. LAW GOVERNS ANY SUCH COLLECTION AND PROCESSING.
                    </p>

                    <p>
                        If you sign up for our Services in Europe or are given access to our services through an EU affiliate, then your data will be stored and processed on servers located within the EU that may only transfer data to
                        jurisdictions outside the EU that abide by similar protections to those that exist in the EU. Data transfers that do take place must be subject to contracts and protections that are reflective of best practices in data transfers and data privacy,
                        and ensure that protections available to EU citizens are available as required.
                    </p>

                    <p>
                        If you sign up for or use our Services elsewhere in the world, you are consenting to our US-based data collection, storage, and transfer practices.
                    </p>

                    <div className="privacy-head">Information Collected</div>
                    <p>
                        We collect two types of information about and from persons who use the Services: Personal
                        Information and Anonymous Information.
                    </p>
                    <p>
                        “Personal Information” is information that is personally identifiable to you, such as your name,
                        address, email address or phone number, as well as other non-public information that is
                        associated with the foregoing. Anything you publicly post or that is available publicly will not
                        be considered Personal Information, and will be outside the restrictions of this policy.
                    </p>
                    <p>
                        “Anonymous Information” means information that is not associated with or linked to your Personal
                        Information. We may create Anonymous Information from (i) automatically gathered information or
                        (ii) Personal Information by excluding information (such as name) that reasonably makes the
                        original information personally identifiable to you.
                    </p>
                    <div className="privacy-head">Personal Information Collected</div>
                    <p>
                        We collect certain Personal Information about you or about those you identify, which may be
                        supplied when you interact with the Services, enter documents or information into the Service,
                        complete a survey or respond to requests for information, make a Transaction, make a payment for
                        Service, schedule an event, share information, when you request services or initiate a
                        transaction with us, or otherwise when you submit such information. The information collected
                        may include one or more of the following:
                    </p>
                    <ul>
                        <li>Name</li>
                        <li>Address</li>
                        <li>Phone Number(s)</li>
                        <li>E-mail address</li>
                        <li>Credit card and / or related billing information</li>
                        <li>Social security number</li>
                        <li>Bank account information</li>
                        <li>Date of birth</li>
                        <li>Your schedule and the schedule(s) of your children or dependent adults</li>
                        <li>Employment information</li>
                        <li>Other family information</li>
                        <li>Physical Address</li>
                        <li>IP Address</li>
                        <li>Information from your activities on the Services</li>
                        <li>
                            Other financial information (e.g. child care expenses, and other expense items entered in
                            the Services)
                        </li>
                        <li>Information you enter into the Services including messages, attachments, and documents</li>
                    </ul>

                    <div className="privacy-head">Location Information</div>
                    <p>
                        We may receive information from you, your Partner, or trusted sources such as your ISP’s
                        provision of your device’s IP address regarding your location. Your location, to the extent
                        available to us, is primarily used to validate your identity. We do not use or sell your
                        location for advertising purposes. Location features may not be available at all times, or on
                        all types of devices or platforms, or in certain locations. If location features are available,
                        your device settings will provide you with the option to enable or disable location features.{' '}
                    </p>
                    <div className="privacy-head">Anonymous Information</div>
                    <p>
                        Anonymous Information is collected about you when you use the Services, including but not
                        limited to enrollment history, the type of device you used and its operating system, the pages
                        accessed most frequently, how pages are used, search terms entered, and similar data. Anonymous
                        Information may also be created from any of the Content we receive. This Anonymous Information
                        is used to help us improve our Services, improve the delivery of these Services to you, and
                        improve our understanding of how aggregated groups of consumers interact with our Services.
                        Anonymous Information, including securely anonymized formerly Personal Information, will not
                        identify you or your specific usage of our Services. Such Anonymous Information can be used by
                        us to maintain and improve our Services available to all Users and Partners. We do not sell our
                        Anonymous Information to third parties for advertising purposes.
                    </p>
                    <div className="privacy-head">Sensitive Information</div>
                    <p>
                        “Sensitive Information” is information that you upload, share with your Partner through our
                        Service, or share with us, that pertains to practically sensitive matters, such as a person’s
                        health or finances. Sensitive Information may be eligible for heightened security and / or
                        privacy safeguards. Sensitive Information is sometimes also (or includes) Personal Information.
                        Sensitive Information that is also Personal Information must be de-identified to become
                        Anonymous Information
                    </p>

                    <div className="privacy-head">PAYMENT INFORMATION</div>

                    <p>
                        The Service charges a fee, facilitates payments between you and ther users, and collects payment information which may include credit card, ACH, debit card,or bank 
                        account information. All payments between you and us, and between and among you and other users (a “Transaction”) will be accomplished through a third-party payment processor, and is subject to the policies of that processor.
                    </p>

                    <p>
                        Additional financial services are provided by our third party partners. When completing your requested transaction, your information will be subject to the terms and policies of
                        hose third parties, and we encourage you to review their policies accordingly. Banking services are provided in part by <a href="http://www.mstreetbank.com/"> MainStreet Bank,</a> member FDIC, Equal Housing Lender, NMLS# 416495.
                    </p>

                    <div className="privacy-head">SOCIAL MEDIA</div>

                    <p>
                        The Service does not permit you to log in through, or directly share any information with, any third party social media platform (such as Facebook, Twitter, Instagram, etc.).
                        We are not a social media platform. We encourage you to post responsibly.
                    </p>

                    <p>
                        If you choose to follow us or otherwise interact with us on any social media platform, the nature of our relationship and communication will be governed by that platform’s terms of service and use.
                    </p>

                    <div className="privacy-head">Cookies</div>

                    <p>
                        Although it may be possible to turn off the collection of most or all cookies through your device or browser,
                        that may interfere with your use of the Services. Please see our cookie dashboard and Cookie Policy (US) for additional information and to make choices regarding cookies.
                        Site experience cookies are those that remember your user selection options. Therefore, if you decline all cookies,
                        our technology will not store or recall your options, including the option to decline all cookies. You are welcome to repeatedly decline cookies per your preferences.
                        We do not use cookies to collect and sell data for advertising.
                    </p>

                    <div className="privacy-head">Do Not Track</div>
                        <p>At present, the Services do not specifically respond to browser do-not-track signals.</p>
                    <div className="privacy-head">Other Analytics</div>
                    <p>
                        Our Services may utilize other common forms of website and / or app usage analytics, such as
                        those associated with Amazon, Google, or other commercially-available cloud hosting or browser
                        analytics systems.
                    </p>
                    <div className="privacy-head">Push Notifications</div>
                    <p>
                        We may offer automatic, also known as push notifications. We will provide push notifications
                        only if you opt-in to receive them. You may use your device or browser settings to turn on or
                        disable these push notifications.
                    </p>

                    <div className="privacy-head">Use of Information</div>

                    <div className="privacy-head">Internal Use of Information by Us</div>
                    <p>
                        We accept and gather Personal Information and Anonymous Information in an effort to provide the
                        Services to you. We may also use this information to help us develop and improve our Services,
                        fulfill your requests, execute Transactions, send materials to you, inform you about our offers
                        and those of others, tailor our Services to meet your interests and needs, and for other
                        purposes permitted or required by law, such as internal compliance and training.
                    </p>
                    <p>
                        We may use Personal Information or Anonymous Information for any business purpose including, but
                        not limited to, analyzing traffic and usage patterns, diagnosing problems, administering the
                        Service, improving the Service, executing requests you make through the Service, tracking how
                        users are using the Service, and improving reporting, marketing, or communication systems.
                    </p>
                    <div className="privacy-head">Sharing Collected Information </div>
                    <p>
                        We may share Personal Information and Anonymous Information with our business associates,
                        consultants, service providers, advisors, and affiliates in order for them to provide services
                        to us, to you, and to enable us to provide the Services. For example, our payment processor
                        requires us to pass Personal Information and Transaction information to it in order to verify
                        your credentials or identity and allow you to make or receive payments.
                    </p>
                    <p>
                        We may ask you to share e-mail addresses or other information in your address book in order to
                        invite other users (i.e. your Partner or other authorized third parties) to our Services. In
                        some cases, this will be required for use of the Service. If you wish to invite your Partner or
                        someone else to use the Services, or you wish to recommend the Services to other friends, we
                        will automatically send an email, text message, or another form of message (as selected by you)
                        to each person that you select to receive your recommendation. We store the contact information
                        you provide so that we can (a) send an initial message to your contact, and (b) monitor the
                        effectiveness of our invitation services. For contacts that do not respond, or that do respond
                        but do not elect to give us their contact information for subsequent information and updates, we
                        will delete that information when we periodically purge our files.
                    </p>
                    <p>
                        We may share Personal Information and Anonymous Information with other users of the Service that
                        you interact with or that you select or indicate for information sharing, or if required by an
                        applicable legal obligation such as a court order.
                    </p>
                    <p>
                        We may share Personal Information and Anonymous Information with third parties that are
                        necessary for the completion of our Services, or legal compliance of our Services. For example,
                        when you initiate a payment transaction, our Services must be able to have a connection to your
                        bank account to complete a payment transfer, and our Services must be able to verify standard
                        anti-fraud information about you and your account such as confirming that your name within our
                        Services matches the name on your linked bank account
                    </p>
                    <p>
                        We do not and will not share Personal Information with any third parties for their marketing
                        purposes.{' '}
                    </p>
                    <p>
                        We may share Anonymous Information with service providers for the purposes of analytics and for
                        our own marketing of Services, and for other purposes permitted by law. The use of such
                        Anonymous Information by third-parties will be governed by their own policies, and may be
                        broader than the uses outlined in this Policy.
                    </p>
                    <p>
                        Further, we may disclose collected information, including Personal Information and Anonymous
                        Information, to the extent we believe it necessary to comply with the law, such as in response
                        to a subpoena or court order, to defend a legal claim, to fulfill a legal compliance
                        requirement, or establish or protect our legal rights or otherwise as permitted by applicable
                        law (a “Compelled Disclosure”). You agree to such Compelled Disclosures, whether or not you are
                        ever made aware of such disclosures, and will indemnify, defend, and hold X2X harmless for any
                        information related to your account that X2X shares pursuant to a Compelled Disclosure. Please
                        be aware that some government agencies may require that we not inform you that such Compelled
                        Disclosure has been required or made.{' '}
                    </p>
                    <p>
                        We may also disclose any information in our possession in the event that we believe it necessary
                        or appropriate to prevent criminal activity, personal injury, property damage, or bodily harm.{' '}
                    </p>
                    <p>
                        Additionally, we may transfer your information to a successor in interest, which may include but
                        may not be limited to a third-party in the event of an acquisition, sale, asset sale, merger or
                        bankruptcy. The policies applicable to your information thereafter may be determined by the
                        transferee, except that we will require the transferee to prohibit use of Personal Information
                        for marketing purposes.
                    </p>  

                    <div className="privacy-head">Retention of Information</div>
                    <p>
                        All information you generate or submit through use of the Service will be retained while you
                        have an active account, regardless of whether you deleted it locally from your computer or
                        mobile device. That information includes, but is not limited to, Personal Information such as
                        posted messages, photos, requests, calendar entries, financial entries, all whether received or
                        sent within each six month period ("Account Activity") and for a period of time after
                        termination of your account, as determined by X2X (“Retention Term”). If there is no Account
                        Activity for six months or longer, you agree that X2X has no obligation to contact you or advise
                        you that your account (along with its associated data) has terminated or is scheduled to be
                        terminated due to inactivity, and that in such event we have the right to terminate your account
                        without liability to you, and may permanently delete and / or de-identify all Personal
                        Information associated with your account. We do not have the ability to delete Anonymous
                        Information. For the Retention Term, you unconditionally and irrevocably grant to X2X the
                        authority to retain any data or records created by you, including but not limited to, text
                        messages, financial transactions, photos, calendar events, and all Personal Information created
                        or shared by you.{' '}
                    </p>
                    <p>
                        During the Retention Term, your Personal Information may be retrieved by you, other persons you
                        have previously authorized us to provide that Personal Information, or your child’s (or
                        children’s or ward’s) other parent or legal guardian (or in some cases by another court
                        appointed professional, such as a guardian ad litem). A person requesting such information must
                        have an active account at the time of the request.
                    </p>
                    <div className="privacy-head">Links</div>
                    <p>
                        The Services may contain links to other websites. We are not responsible for the privacy
                        practices of such other sites. When you leave our Service to visit another website or
                        application, please read the privacy statements of those websites or applications. This Privacy
                        Policy applies solely to information collected by us through the Service.{' '}
                    </p>
                    <div className="privacy-head">Security </div>
                    <p>
                        We employ procedural and technical safeguards to secure your personal information against loss,
                        theft, alteration, and unauthorized access, use, and disclosure. We also employ security
                        procedures to protect your information from unauthorized access by users inside and outside the
                        company
                    </p>
                    <p>
                        Regardless of the precautions we take, no transmission of data over the internet is guaranteed
                        to be completely secure. It may be possible for third parties not under our control to intercept
                        or access transmissions or private communications unlawfully. While we strive to protect
                        personal information, we cannot ensure or warrant the security of any information you transmit
                        to us or enter into the Service.{' '}
                    </p>
                    <div className="privacy-head">E-Mail and Electronic Newsletters </div>
                    <p>
                        We may offer electronic newsletters and e-mails concerning existing and new products and
                        services, or may provide other marketing or informational materials as a service to our users.
                        Users may receive newsletters and e-mails concerning promotions and marketing of ours or of our
                        business partners, after registering with the Services or after engaging with us in person or
                        through our agents.{' '}
                    </p>
                    <p>
                        If you have received a message from us, and you no longer wish to receive such materials, you
                        may opt-out by following the unsubscribe instructions included in each electronic newsletter and
                        e-mail.{' '}
                    </p>
                    <p>
                        Please note that you may be required to separately opt out of any e-mails sent by our business
                        associates. You may not be able to opt out of messages related to your account, the availability
                        of Services, or other technical, security, or privacy-related messages.{' '}
                    </p>
                    <div className="privacy-head">Children and Privacy</div>
                    <p>
                        We do not knowingly permit users to use our Services if they are under 13 years old, and
                        therefore do not request personally identifiable information from anyone under the age of 13.{' '}
                    </p>
                    <p>
                        If we become aware that a user or customer is under the age of 13 and has registered without
                        verifiable parental consent, we will remove his or her personally identifiable registration
                        information from our files and will disable the account. If you are the parent or guardian of a
                        person under the age of 13 who has provided personally identifiable information to us without
                        your approval, please inform us by contacting us at the e-mail address below and we will remove
                        such information from our database.{' '}
                    </p>
                    <div className="privacy-head">California Privacy Rights </div>
                    <p>
                        California law allows California residents to request information regarding our disclosures in
                        the prior calendar year, if any, of their personally identifiable information to third parties.
                        To make such a request, please contact us at <a href="#">privacy@kincrew.io</a> with
                        "Request for Privacy Information" in the subject line. Please include enough detail for us to
                        locate your file; at a minimum, your name, email, and address. We will attempt to provide you
                        with the requested information within thirty (30) days of receipt. We reserve our right not to
                        respond to requests sent more than once in a calendar year, or requests submitted in a form
                        other than as prescribed in this notice. Please note that this law does not cover all
                        information sharing. Our disclosure only includes information covered by the law.
                    </p>
                    <div className="privacy-head">
                        General Data Protection Regulation and Other Extra-Jurisdictional Requirements
                    </div>
                    <p>
                        X2X is based in the United States and operates based upon United States law. However, we aspire
                        to the best borderless practices in data privacy, data security, quality control, product
                        management, and product safety. As a result, we may voluntarily seek to comply with laws or
                        requirements that do not actually apply to X2X. We are not responsible for any failure to meet
                        compliance with any laws or requirements that we voluntarily commit to following.
                    </p>
                    <div className="privacy-head">Sensitive Records Laws</div>
                    <p>
                        Federal, State, and Local laws may contain requirements that apply to Content or information
                        shared through our Service. To the extent those laws may apply to you or us, you provide your
                        consent to our use of your Content consistent with this Privacy Policy and our Terms of Service
                        when you upload, transmit, post, or otherwise use our Service. We treat data with a high level
                        of sensitivity by default, however, we are not responsible for errors, omissions, security
                        breaches, unauthorized access, or unauthorized use of Content.{' '}
                    </p>
                    <div className="privacy-head">Contact Information</div>
                    <p>
                        If you have any questions or suggestions regarding our Privacy Policy, please contact us via
                        e-mail at <a href="#">privacy@kincrew.io</a>
                    </p>
                </div> 

                <div className="cb-wrap">
                    <Button
                        type="primary"
                        className="fp-button-secondary btn-add-req"
                        
                    >
                        <span>Print</span>
                    </Button>
                </div>

            </Modal>
        )
    }
}


export default PrivacyPolicy;