import React, { Component } from 'react';
import 'pages/CalenderInner/Swap/Swap.scss';
import { Card, Button, Modal, Form, Input, Select, Avatar, Empty } from 'antd';
import right from 'Assets/right-arrow.png';
import TextArea from 'antd/lib/input/TextArea';
import user from 'Assets/defaultAvatar.png';
import { connect } from 'react-redux';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { getSwap, updateSwap, getDots } from 'services/calendarService';
import Calendar from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import { checkCookie } from 'utils/cookies';
import { getCoParentsUserAction, getChildrenUserAction } from 'actions/userActions';
import { addLoading, removeLoading } from 'actions/loaderActions';

const colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    gradient1: 'Gradient1',
    gradient2: 'Gradient2',
};
class EditSwap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modalVisible: false,
            currentDate: new Date(),
            title: '',
            child_ids: [],
            expire: '',
            dates: [],
            tradeDates: [],
            temp1: [],
            temp2: [],
            flag: false,
            picture: null,
            memo: '',
            response_memo: '',
            swap: null,
            dots: {},
            errors: {
                kids: '',
                forfeit: '',
            },
        };
        this.setModalVisible = this.setModalVisible.bind(this);
        this.getExpireDuration = this.getExpireDuration.bind(this);
        this.sortedDates = this.sortedDates.bind(this);
        this.handleChildren = this.handleChildren.bind(this);
        this.getChildrenNames = this.getChildrenNames.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.handleForfeit = this.handleForfeit.bind(this);
        this.handleTrade = this.handleTrade.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDates = this.handleDates.bind(this);
        this.confirmDates = this.confirmDates.bind(this);
        this.formattedDates = this.formattedDates.bind(this);
        this.getCalendarDots = this.getCalendarDots.bind(this);
        this.handleChilds = this.handleChilds.bind(this);
        this.dateFormate = this.dateFormate.bind(this);
    }

    componentDidMount() {
        this.getCalendarDots(this.props.coparent);
        if (checkCookie() !== null) {
            this.props.dispatch(getChildrenUserAction());
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.coparent !== prevProps.coparent) {
            this.getCalendarDots(this.props.coparent);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.handleOpen && nextProps.currentSwap) {
            let err = {
                kids: '',
                forfeit: '',
            };

            this.setState({ loading: true });
            getSwap(nextProps.currentSwap.id).then((resp) => {
                this.setState({
                    currentDate: new Date(),
                    title: resp.title,
                    child_ids: resp.child_ids,
                    expire: resp.exp_date,
                    dates: this.sortedDates(resp.dates),
                    tradeDates: this.sortedDates(resp.trade_dates),
                    temp1: this.sortedDates(resp.dates),
                    temp2: this.sortedDates(resp.trade_dates),
                    flag: false,
                    memo: resp.memo,
                    response_memo: resp.response_memo,
                    picture: resp.Members && resp.Members[0].picture,
                    errors: err,
                    loading: false,
                    swap: resp,
                });
            });

            this.getCalendarDots(nextProps.coparent);
        }
    }

    getCalendarDots = function (coparent) {
        const sParams = {
            coparent_id: (coparent && coparent.id) || null,
        };

        getDots(sParams).then(async (resp) => {
            if (resp && resp.data) {
                await this.setState({ dots: resp.data });
            }
        });
    };

    setModalVisible = function (visible, flag) {
        this.setState({ modalVisible: visible, flag: flag });
    };

    handleChilds = async function (value) {
        if (value.length === 0) {
            await this.setState({
                child_ids: value,
                errors: { ...this.state.errors, kids: 'You have to select at least one item' },
            });
        } else {
            await this.setState({ child_ids: value, errors: { ...this.state.errors, kids: '' } });
        }
    };

    handleChildren = async function (value) {
        const { child_ids } = this.state;
        let arr = [...child_ids];
        if (arr.includes(value)) {
            let index = arr.indexOf(value);
            arr.splice(index, 1);
            if (arr.length === 0) {
                await this.setState({
                    child_ids: arr,
                    errors: { ...this.state.errors, kids: 'You have to select at least one item' },
                });
            } else {
                await this.setState({ child_ids: arr, errors: { ...this.state.errors, kids: '' } });
            }
        } else {
            await this.setState({
                child_ids: [...this.state.child_ids, value],
                errors: { ...this.state.errors, kids: '' },
            });
        }
    };

    confirmDates = async function () {
        const { flag, temp1, dates, temp2 } = this.state;
        if (!flag) {
            let err = '';
            if (temp1.length > 0) {
                err = '';
            } else if (temp1.length === 0 && dates.length === 1) {
                err = 'You have to select at least one item';
            }

            this.setState({ dates: temp1, modalVisible: false, errors: { ...this.state.errors, forfeit: err } });
        } else {
            this.setState({ tradeDates: temp2, modalVisible: false });
        }
    };

    formattedDates = function (dates) {
        let arr = [];

        arr = dates.map((date) => {
            var d = new Date(date);
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();
            var year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return year + '-' + month + '-' + day;
        });

        return arr;
    };

    dateFormate = function (date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return year + '-' + month + '-' + day;
    };

    verifyCustodyOnDate = (d, child_ids) => {
        let res = false;
        const { children } = this.props;
        let arr = [];
        children.forEach((child) => {
            if (child_ids.includes(child.id)) {
                arr.push(child);
            }
        });

        if (arr.length === d.dots.length) {
            arr.forEach((item) => {
                if (d.dots.includes(item.color)) {
                    res = true;
                } else {
                    res = false;
                    return res;
                }
            });
        } else {
            res = false;
        }

        return res;
    };

    handleDates = async function (e) {
        let date = this.formatDate(e);
        const { flag, temp1, temp2, dates, tradeDates, dots, child_ids } = this.state;
        let d = dots[this.dateFormate(date)];
        if (!flag) {
            if (d && d.dots && d.dots.length > 0) {
                if (child_ids.length > 0) {
                    if (!tradeDates.includes(date)) {
                        if (this.verifyCustodyOnDate(d, child_ids)) {
                            let arr = [];
                            temp1.forEach((item) => {
                                arr.push(item);
                            });
                            if (arr.includes(date)) {
                                let index = arr.indexOf(date);
                                arr.splice(index, 1);
                                await this.setState({ temp1: arr });
                            } else {
                                await this.setState({ temp1: [...temp1, date] });
                            }
                        }
                    }
                }
            } else {
            }
        } else {
            if (d && d.dots && d.dots.length > 0) {
            } else {
                if (child_ids.length > 0) {
                    if (!dates.includes(date)) {
                        let arr = [];
                        temp2.forEach((item) => {
                            arr.push(item);
                        });
                        if (arr.includes(date)) {
                            let index = arr.indexOf(date);
                            arr.splice(index, 1);
                            await this.setState({ temp2: arr });
                        } else {
                            await this.setState({ temp2: [...temp2, date] });
                        }
                    }
                }
            }
        }
    };

    getChildrenNames = function (child_ids) {
        let names = '';
        const { children } = this.props;
        child_ids.forEach((child) => {
            for (let i = 0; i < children.length; i++) {
                let c = children[i];
                if (child == c.id) {
                    names = names + c.name;
                    names = names + ', ';
                }
            }
        });
        names = names.trim();
        names = names.slice(0, names.length - 1);
        return names;
    };

    sortedDates = function (dates) {
        let arr = [];

        arr = dates.map((date) => {
            var d = moment(moment.parseZone(date).format('YYYY-MM-DD')).toDate();
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();
            var year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return month + '/' + day + '/' + year;
        });

        return arr;
    };

    getExpireDuration = (expire) => {
        let expires = '';
        const expireDate = moment(new Date(expire)).zone(0).format('YYYY-MM-DD');
        const currentDate = moment(new Date()).format('YYYY-MM-DD');

        if (!expire || moment(currentDate).isAfter(expireDate)) {
            expires = 'Expired';
        } else {
            expires = moment
                .duration(moment(expireDate).valueOf() - moment(currentDate).valueOf(), 'milliseconds')
                .humanize(true);
        }
        return expires;
    };

    handleForfeit = async function () {
        const { dates, tradeDates } = this.state;
        await this.setState({ temp1: dates, temp2: tradeDates });
        this.setModalVisible(true, false);
    };

    handleTrade = async function () {
        const { dates, tradeDates } = this.state;
        await this.setState({ temp2: tradeDates, temp1: dates });
        this.setModalVisible(true, true);
    };

    formatDate = function (date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return month + '/' + day + '/' + year;
    };

    validate = function () {
        const { title, child_ids, dates } = this.state;
        let err = {};

        if (child_ids.length === 0) {
            err.kids = 'You have to select at least one item';
        } else {
            err.kids = '';
        }

        if (dates.length === 0) {
            err.forfeit = 'You have to select at least one item';
        } else {
            err.forfeit = '';
        }

        if (err.kids === '' && err.forfeit === '') {
            this.handleSubmit();
        } else {
            this.setState({ errors: err });
        }
    };

    handleSubmit = function () {
        const { title, child_ids, expire, dates, picture, tradeDates, memo, response_memo, swap } = this.state;
        this.setState({ loading: true });

        let data = {
            title: title,
            child_ids: child_ids,
            picture: picture,
            start_date: null,
            exp_date: expire,
            dates: this.formattedDates(dates),
            trade_dates: this.formattedDates(tradeDates),
            memo: memo,
            response_memo: response_memo,
        };

        updateSwap(swap.id, data).then((resp) => {
            this.setState({ loading: false });
            this.props.handleClose();
            this.props.refresh();
            this.props.dispatch(addLoading());
        });
    };

    render() {
        const { Option } = Select;

        const {
            loading,
            dots,
            title,
            child_ids,
            expire,
            dates,
            tradeDates,
            temp1,
            temp2,
            currentDate,
            memo,
            response_memo,
            picture,
            errors,
        } = this.state;
        const { children } = this.props;
        return (
            <Modal
                title="Swap Invite"
                className="cal-modal swap-modal"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Form>
                    <div className="scroll-wrap enhance-height">
                        <div className="invite-top">
                            <span>{title}</span>
                            <div className="avatar-wrap">
                                <Avatar size={60} src={picture ? picture.url : user} alt="avatar-image" />
                            </div>
                        </div>
                        <Form.Item className="select-sm multi-select">
                            <Select
                                placeholder="Choose kid(s)"
                                value={child_ids}
                                mode="multiple"
                                onChange={this.handleChilds}
                            >
                                {Array.isArray(children) &&
                                    children.map((kid, i) => (
                                        <Option
                                            key={i}
                                            className={child_ids.includes(kid.id) ? 'text-color' : ''}
                                            value={kid.id}
                                        >
                                            <span className={'dot ' + colors[kid.color]}></span>
                                            {kid.name}
                                        </Option>
                                    ))}
                            </Select>
                            {errors.kids !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.kids}</div>
                            ) : null}
                        </Form.Item>
                        <Form.Item className="input-col select-sm" label="Offer Expires: " labelCol={{ span: 24 }}>
                            <Input value={this.getExpireDuration(expire)} readOnly />
                        </Form.Item>
                        <Form.Item className="calendar-btn">
                            <Button
                                onClick={this.handleForfeit}
                                className="fp-button-secondary full-btn"
                                type="primary"
                            >
                                <span>Select swap or forfeit day(s)</span>
                                <span>
                                    <img src={right} alt="right-arrow" />
                                </span>
                            </Button>
                            {errors.forfeit !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.forfeit}</div>
                            ) : null}
                            <div className="forfeit-list">
                                {Array.isArray(dates) && dates.length !== 0
                                    ? dates.map((date, i) => <div key={i}>{date}</div>)
                                    : null}
                            </div>
                        </Form.Item>
                        <div style={{ marginBottom: '20px' }}></div>
                        <Form.Item className="calendar-btn" label="Optional" labelCol={{ span: 24 }}>
                            <Button onClick={this.handleTrade} className="fp-button-secondary full-btn" type="primary">
                                <span>Select suggested trade day(s)</span>
                                <span>
                                    <img src={right} alt="right-arrow" />
                                </span>
                            </Button>
                            <div className="trade-list">
                                {Array.isArray(tradeDates) && tradeDates.length !== 0
                                    ? tradeDates.map((date, i) => <div key={i}>{date}</div>)
                                    : null}
                            </div>
                        </Form.Item>
                        <Form.Item className="sm-textarea" label="Swap memo" labelCol={{ span: 24 }}>
                            <TextArea value={memo} readOnly />
                        </Form.Item>
                        <Form.Item className="sm-textarea" label="Responses memo:" labelCol={{ span: 24 }}>
                            <TextArea value={response_memo} readOnly />
                        </Form.Item>
                    </div>
                    <div className="btn-scroll-wrap">
                        <Form.Item>
                            <Button onClick={this.validate} className="fp-button-primary full-btn" type="primary">
                                Save
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
                <Modal
                    title="Calendar"
                    className="cal-modal swap-modal sc-modal"
                    centered
                    maskClosable={false}
                    visible={this.state.modalVisible}
                    onOk={() => this.setModalVisible(false)}
                    onCancel={() => this.setModalVisible(false)}
                >
                    <div className="edit-wrap cal-custom">
                        <div className="scroll-wrap scroll-cust">
                            <div className="cal-wrap cal-icon cal-with-dots">
                                <Calendar
                                    forfeit_dates={temp1}
                                    trade_dates={temp2}
                                    selected={currentDate}
                                    markedDates={dots}
                                    onChange={(e) => this.handleDates(e)}
                                />
                            </div>
                        </div>
                        <div className="btn-scroll-wrap">
                            <Form.Item className="btn-top">
                                <Button onClick={this.confirmDates} className="fp-button-primary" type="primary">
                                    Done
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Modal>
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparent } = serviceReducer;
    return { user, error, children, coparent };
};

export default connect(mapStateToProps)(EditSwap);
