import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, InputNumber, Radio, DatePicker, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import {} from 'services/categoryService';
import moment from 'moment';
import {setupSpecialCategory} from 'services/categoryService';
import TermsModal from 'pages/Payment/Categories/Terms';

const { Option } = Select;
const { TextArea } = Input;

class YouModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            termsVisible: false,
            percent_verified: false,
            category_name: '',
            category: null,
            errors: {
                percent_verified: ''
            }
        };
        this.handleCheck = this.handleCheck.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps && nextProps.handleOpen && nextProps.currentCategory && nextProps.categoryName) {
            let errors = {
                percent_verified: '',
            };

            this.setState({category_name: nextProps.categoryName, category: nextProps.currentCategory, percent_verified: false, errors: errors});
        } else if(nextProps.categoryName) {
            let errors = {
                percent_verified: '',
            };

            this.setState({category_name: nextProps.categoryName, percent_verified: false, errors: errors});
        }
    }

    updateTermsState = async val => {
        await this.setState({percent_verified: val,termsVisible : false})
        let error = '';
        if(val === false) {
           error = 'Please Accept the terms and conditions';
        }
        await this.setState({errors: {...this.state.errors, percent_verified: error}})
    }

    setTermsVisible (val){
        this.setState({termsVisible : val})
    }

    handleCheck = async function(){
        let error = '';
        if(!this.state.percent_verified === false) {
           error = 'Please Accept the terms and conditionsss';
        }
        //this.setState({percent_verified: !this.state.percent_verified, errors: {...this.state.errors, percent_verified: error}})
        await this.setState({termsVisible : true});
    }

    handleSubmit = async function() {
        const {percent_verified, category_name} = this.state;
        const {currentCategory, coparent, coparents, categoryName } = this.props;
        
        if(percent_verified === false) {
            await this.setState({errors: {...this.state.errors, percent_verified: 'Please Accept the terms and conditions'}});
        } else {
            this.setState({loading: true});
            let options = {};
            if(currentCategory === null) {
                options = {
                    name: category_name,
                    creator_amount_percent: 100,
                    percent_verified: percent_verified,
                    default_cat_id: categoryName === 'Child Support' ? 'df06' : 'df07',
                    is_na: 0,
                    category_status: 1,
                    catIds: 0,
                   // coparent_user_id: coparent.id,
                };
            } else {
                options = {
                    name: category_name,
                    creator_amount_percent: 100,
                    percent_verified: percent_verified,
                    default_cat_id: categoryName === 'Child Support' ? 'df06' : 'df07',
                    is_na: 0,
                    category_status: 1,
                    catIds: currentCategory.id,
                    //coparent_user_id: coparent.id,
                };
            }

            if (coparent && coparent.is_registered) {
                 options.coparent_user_id = coparent.id;
            } else {
                options.coparent_standalone_id = coparent.id;
            }

            setupSpecialCategory(options)
            .then(resp => {
                this.setState({loading: false});
                this.props.handleClose();
                this.props.refresh();
            }).catch(err => {
                this.setState({loading: false});
            })
        }
    }
    render() {
        const {percent_verified, loading, category_name, errors} = this.state;
        return (
            <Modal
            title="Edit Category"
            centered
            maskClosable={false}
            visible={this.props.handleOpen}
            className="payments-popup edit-payment"
            onOk={this.props.handleClose}
            onCancel={this.props.handleClose}
            >
            <LoadingOverlay
                    active={loading ? true : false}
                    className={'loader-wrap pop-loader ' + (loading ? 'd-block' : '')}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
            ></LoadingOverlay>
            <Form>
                <Form.Item className="input-sm">
                    <Input value={category_name} readOnly />
                </Form.Item>
                <div className="input-number">
                    <Form.Item label="You" labelCol={{ span: 24 }}>
                    <InputNumber value={100} readOnly />
                    </Form.Item>
                    <Form.Item label="Co-parent" labelCol={{ span: 24 }}>
                    <InputNumber value={0} readOnly />
                    </Form.Item>
                </div>
                <Form.Item className="cb-form">
                    <Checkbox checked={percent_verified} onChange={this.handleCheck}>
                        <span className="cb-text"> Accept the Terms and Conditions</span>
                    </Checkbox>
                    {errors.percent_verified !== '' ? <div style={{color: 'red', fontSize: '12px'}}>{errors.percent_verified}</div> : null}
                </Form.Item>
                <Form.Item className="btn-full">
                    <Button onClick={this.handleSubmit} className="fp-button-primary" type="primary">
                        <span>Create</span>
                    </Button>
                </Form.Item>
            </Form>

            <TermsModal 
                    termsVisible={this.state.termsVisible}
                    termsStatus = {this.state.percent_verified}
                    updateTermsAgree={this.updateTermsState}
                    onClose={() => this.setTermsVisible(false)}
               /> 

           </Modal>
        );
    }
}


const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparent, coparents } = serviceReducer;
    return { user, error, children, coparent, coparents };
};
export default connect(mapStateToProps)(YouModal);