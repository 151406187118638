import React, { Component } from 'react';
import 'pages/Reports/CustodySwaps/CustodySwaps.scss';
import { Card, Button, Tabs, Modal, Select, Empty } from 'antd';
import CreateSwapReportModal from 'pages/Reports/CustodySwaps/CreateSwapReportModal';
import SaveSwapReportModal from 'pages/Reports/CustodySwaps/SaveSwapReportModal';
import { GetActiveSwapReport } from 'services/reportsService';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';

const { TabPane } = Tabs;
const { Option } = Select;

function callback(key) {

}

class CustodySwaps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            swapReport: '',
            detailReportVisible: false,
            modalVisible: false,
            reports: [],
            flag: false,
            offset: 0,
            archived: false,
            selectedTab: "1"
        };
        this.setModalVisible = this.setModalVisible.bind(this);
        this.getCustodySwaps = this.getCustodySwaps.bind(this);
        this.getCoparentName = this.getCoparentName.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.GetChilrenName = this.GetChilrenName.bind(this);
        this.GetSwapchildName = this.GetSwapchildName.bind(this);
        this.GetExceptionchildName = this.GetExceptionchildName.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        let offset = 0;
        let archived = false;
        this.getCustodySwaps(offset, archived, true);
    }

    getCoparentName = function (cp_id) {
        const { coparents } = this.props;
        let cp_name;
        coparents.forEach((cp) => {
            if (cp.id === cp_id) {
                cp_name = cp.name;
            }
        });

        return cp_name;
    };

    formatDate = function (date) {
        var newdate = moment(date, 'DD-MM-YYYY');
        return moment(newdate).format('MM/DD/YY');
    };

    getCustodySwaps = async function (offset, archived, flag) {
        const { reports } = this.state;

        await this.setState({ loading: true });
        let options = {
            archived: archived,
            offset: offset,
            limit: 10,
        };

        await this.setState({ archived: archived, offset: offset });

        GetActiveSwapReport(options).then(async (resp) => {
            if (resp && resp.data.length > 0) {
                let newOffset = this.state.offset + 10;
                this.setState({ reports: resp.data, offset: newOffset });

                if (flag) {
                    let arr = [];
                    arr = [...arr, ...resp.data];
                    await this.setState({ reports: arr, loading: false });
                } else {
                    let arr = [];
                    arr = [...reports, ...resp.data];
                    await this.setState({ reports: arr, loading: false });
                }
            } else {
                await this.setState({ loading: false });
                if(flag){
                    this.setState({ reports: [] });
                }
            }
        });
    };

    GetSwapchildName = function (data) {
        if (data) {
            let swaplist = [];
            let allkeys = Object.keys(data);

            for (const key of allkeys) {
                swaplist.push(data[key]);
            }

            let childrens = swaplist.map((item) => item.kidName);
            return childrens;
        } else {
            return [];
        }
    };

    GetExceptionchildName = function (datatoshow) {
        if (datatoshow) {
            let exceptionlist = [];
            let allkeys = Object.keys(datatoshow);
            for (const key of allkeys) {
                exceptionlist = exceptionlist.concat(datatoshow[key]);
            }
            let childrens = exceptionlist.map((item) => item.kidName);
            return childrens;
        } else {
            return [];
        }
    };

    GetChilrenName = function (data) {
        let childname = [];
        let swap_approved = data.swapData.approved;
        let forfeit_approved = data.forfeitData.approved;
        let exception_approved = data.exceptionData.approved;

        let swap_declined = data.swapData.declined;
        let forfeit_declined = data.forfeitData.declined;
        let exception_declined = data.exceptionData.declined;

        childname = childname.concat(this.GetSwapchildName(swap_approved));
        childname = childname.concat(this.GetSwapchildName(forfeit_approved));
        childname = childname.concat(this.GetSwapchildName(swap_declined));
        childname = childname.concat(this.GetSwapchildName(forfeit_declined));
        childname = childname.concat(this.GetExceptionchildName(exception_approved));
        childname = childname.concat(this.GetExceptionchildName(exception_declined));

        childname = childname.filter(function (value, index, array) {
            return array.indexOf(value) == index;
        });

        return childname.map((item) => item).join(', ');
    };

    setModalVisible = async function (visible) {
        await this.setState({ modalVisible: visible });
    };
    setDetailsModalVisible = async function (visible) {
        await this.setState({ detailReportVisible: visible });
    };

    handleClick = async function (key) {
        let archived;
        if (key === '1') {
            archived = false;
        } else if (key === '2') {
            archived = true;
        }

        let offset = 0;
        let arr = [];
        await this.setState({ selectedTab: key, reports: arr, offset: 0 });
        this.getCustodySwaps(offset, archived, true);
    };

    handleScroll = function (e) {
        const { archived, offset } = this.state;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this.getCustodySwaps(offset, archived, false);
        }
    };

    refresh = async function() {
        await this.setState({ selectedTab: "1"});
        this.getCustodySwaps(0, false, true);
    };

    async showDetails(data) {
        let info = {
            data: data.id,
            type: this.state.archived ? 'archive' : 'active',
        };
        await this.setState({ swapReport: info });
        this.setState({ detailReportVisible: true });
    }

    render() {
        const { reports, loading, selectedTab } = this.state;
        return (
            <div className="report-page-wrap">
                <LoadingOverlay
                    active={loading ? true : false}
                    // className={'loader-wrap ' + (loading ? 'd-block' : '')}
                    className={loading ? 'd-block pop-loader' : 'd-none pop-loader'}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Card bordered={false} className="custody-swap-card">
                    <div className="card-head">
                        <span>Custody Swaps</span>
                        <Button className="fp-button-secondary add-btn" onClick={() => this.setModalVisible(true)}>
                            +
                        </Button>
                    </div>
                    <div className="card-body">
                        <Tabs activeKey={selectedTab} onChange={this.handleClick}>
                            <TabPane tab="Active Reports" key="1">
                                <div onScroll={this.handleScroll} className="tab-content-wrap">
                                    {Array.isArray(reports) && reports.length > 0 ? (
                                        reports.map((report, i) => (
                                            <Card
                                                key={i}
                                                bordered={false}
                                                className="card-inner"
                                                onClick={() => this.showDetails(report)}
                                            >
                                                <div className="card-top">
                                                    <span>{this.getCoparentName(report.coparent_id)}</span>
                                                    <span>
                                                        {this.formatDate(report.startDate)} -{' '}
                                                        {this.formatDate(report.endDate)}
                                                    </span>
                                                </div>
                                                <div className="card-bottom">
                                                    <span>{this.GetChilrenName(report)}</span>
                                                </div>
                                            </Card>
                                        ))
                                    ) : (
                                        <div>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Archived Reports" key="2">
                                <div onScroll={this.handleScroll} className="tab-content-wrap">
                                    {Array.isArray(reports) && reports.length > 0 ? (
                                        reports.map((report, i) => (
                                            <Card
                                                key={i}
                                                bordered={false}
                                                className="card-inner"
                                                onClick={() => this.showDetails(report)}
                                            >
                                                <div className="card-top">
                                                    <span>{this.getCoparentName(report.coparent_id)}</span>
                                                    <span>
                                                        {this.formatDate(report.startDate)} -{' '}
                                                        {this.formatDate(report.endDate)}
                                                    </span>
                                                </div>
                                                <div className="card-bottom">
                                                    <span>{this.GetChilrenName(report)}</span>
                                                </div>
                                            </Card>
                                        ))
                                    ) : (
                                        <div>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                    <CreateSwapReportModal
                        handleOpen={this.state.modalVisible}
                        refreshSwapReports={() => this.refresh()}
                        handleClose={() => this.setModalVisible(false)}
                    />

                    <SaveSwapReportModal
                        openModal={this.state.detailReportVisible}
                        swapReport={this.state.swapReport}
                        refreshSwapReports={() => this.refresh()}
                        closeModal={() => this.setDetailsModalVisible(false)}
                    />
                </Card>
            </div>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, loadings, error, coparent, coparents } = serviceReducer;
    return { children, user, loadings, error, coparent, coparents };
};

export default connect(mapStateToProps)(CustodySwaps);
