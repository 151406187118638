import React, { Component } from 'react';
import 'pages/Household/Household.scss';
import { Row, Col } from 'antd';
import Household from 'pages/Household/Household/Household';
import Children from 'pages/Household/Children/Children';
import CoParents from 'pages/Household/CoParents/CoParents';
import ThirdParty from 'pages/Household/ThirdParty/ThirdParty';
import { connect } from 'react-redux';
import Modal from 'Components/Modal/ConnectionModal';

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible : true,
            isThirdParty : localStorage.getItem("third_party")
        };
        this.setModalVisible = this.setModalVisible.bind(this);
    }

    componentDidMount() {
        
    }

    setModalVisible = async function(visible){
        await this.setState({modalVisible: visible});
    }

    render(){
        const {error} = this.props;
        return (
            <div>
            {error ? <Modal handleOpen={this.state.modalVisible} maskClosable={false} handleClose={() => this.setModalVisible(false)}></Modal> : null}
            <Row className="row-container" gutter={'10'}>
                <Col xs={24} lg={10} className="calender-wrap">
                    <Household />
                </Col>
                <Col xs={24} lg={7} className="todo-wrap">
                    <Children />
                </Col>
                <Col xs={24} lg={7} className="col-wrap">
                    <CoParents />
                    {this.state.isThirdParty == 'false' && (
                        <ThirdParty />
                    )}
                    
                </Col>
            </Row>
            </div>
        );
    }
};

const mapStateToProps = ({houseHoldReducer}) => {
    const {error} = houseHoldReducer;
    return {error};
}

export default connect(mapStateToProps)(Landing);
