import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mergeClassNames from 'merge-class-names';

import { tileProps } from './shared/propTypes';

function getValue(nextProps, prop) {
    const { activeStartDate, date, view } = nextProps;

    return typeof prop === 'function' ? prop({ activeStartDate, date, view }) : prop;
}

let colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    gradient1: 'Gradient1',
    gradient2: 'Gradient2',
};

const formatDate = (date) => {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

export default class Tile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            custodyDots: [],
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { tileClassName, tileContent } = nextProps;

        const nextState = {};

        if (tileClassName !== prevState.tileClassNameProps) {
            nextState.tileClassName = getValue(nextProps, tileClassName);
            nextState.tileClassNameProps = tileClassName;
        }

        if (tileContent !== prevState.tileContentProps) {
            nextState.tileContent = getValue(nextProps, tileContent);
            nextState.tileContentProps = tileContent;
        }

        if (nextProps && nextProps.date && nextProps.markedDates) {
            let custodyDates;
            let custodyDots = [];
            if (nextProps.markedDates) {
                custodyDates = nextProps.markedDates[formatDate(nextProps.date)];
                if (custodyDates) {
                    custodyDates.dots.forEach((color) => {
                        custodyDots.push(colors[color]);
                    });
                }
            }
            nextState.custodyDots = custodyDots;
        }

        return nextState;
    }

    render() {
        const {
            activeStartDate,
            children,
            classes,
            date,
            formatAbbr,
            locale,
            maxDate,
            maxDateTransform,
            minDate,
            minDateTransform,
            onClick,
            onMouseOver,
            style,
            tileDisabled,
            view,
            markedDates,
        } = this.props;
        const { tileClassName, tileContent, custodyDots } = this.state;
        return (
            <button
                className={mergeClassNames(classes, tileClassName)}
                disabled={
                    (minDate && minDateTransform(minDate) > date) ||
                    (maxDate && maxDateTransform(maxDate) < date) ||
                    (tileDisabled && tileDisabled({ activeStartDate, date, view }))
                }
                onClick={onClick && ((event) => onClick(date, event))}
                onFocus={onMouseOver && (() => onMouseOver(date))}
                onMouseOver={onMouseOver && (() => onMouseOver(date))}
                style={style}
                type="button"
            >
                {formatAbbr ? <div aria-label={formatAbbr(locale, date)}>{children}</div> : children}
                {tileContent}
                <div className="dot-wrap">
                    <div className="dot-inside">
                        {custodyDots && custodyDots.map((color, i) => <span key={i} className={color}></span>)}
                    </div>
                </div>
            </button>
        );
    }
}

Tile.propTypes = {
    ...tileProps,
    children: PropTypes.node.isRequired,
    formatAbbr: PropTypes.func,
    maxDateTransform: PropTypes.func.isRequired,
    minDateTransform: PropTypes.func.isRequired,
};
