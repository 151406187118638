import React, { Component } from 'react';
import 'pages/CalenderInner/Swap/Swap.scss';
import { Card, Button, Modal, Form, Input, Select, Avatar, Empty } from 'antd';
import right from 'Assets/right-arrow.png';
import TextArea from 'antd/lib/input/TextArea';
import { connect } from 'react-redux';
import moment from 'moment';
import Calendar from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import { setupSwap, getDots } from 'services/calendarService';
import LoadingOverlay from 'react-loading-overlay';
import SwapErrorModal from 'pages/CalenderInner/Swap/SwapErrorModal';
import { checkCookie } from 'utils/cookies';
import { getCoParentsUserAction, getChildrenUserAction } from 'actions/userActions';
import { addLoading, removeLoading } from 'actions/loaderActions';

const getTomorowStart = () => {
    let tomorrowDay = moment().add(1, 'day');
    tomorrowDay = moment(tomorrowDay).startOf('day');
    return tomorrowDay;
};

const expireSelectData = [
    {
        value: moment(getTomorowStart()).add(1, 'day').valueOf(),
        label: '1 Day',
    },
    {
        value: moment(getTomorowStart()).add(3, 'days').valueOf(),
        label: '3 Days',
    },
    {
        value: moment(getTomorowStart()).add(5, 'days').valueOf(),
        label: '5 Days',
    },
    {
        value: moment(getTomorowStart()).add(10, 'days').valueOf(),
        label: '10 Days',
    },
];
const colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    gradient1: 'Gradient1',
    gradient2: 'Gradient2',
};

class CreateSwap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modalVisible: false,
            modal1Visible: false,
            currentDate: new Date(),
            title: '',
            child_ids: [],
            expire: expireSelectData[0].value,
            dates: [],
            tradeDates: [],
            temp1: [],
            temp2: [],
            flag: false,
            memo: '',
            response_memo: '',
            msg: '',
            dots: {},
            errors: {
                title: '',
                kids: '',
                forfeit: '',
            },
        };
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.handleTitle = this.handleTitle.bind(this);
        this.handleChildren = this.handleChildren.bind(this);
        this.getChildrenNames = this.getChildrenNames.bind(this);
        this.handleExpire = this.handleExpire.bind(this);
        this.handleDates = this.handleDates.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.handleForfeit = this.handleForfeit.bind(this);
        this.handleTrade = this.handleTrade.bind(this);
        this.confirmDates = this.confirmDates.bind(this);
        this.handleMemo = this.handleMemo.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.formattedDates = this.formattedDates.bind(this);
        this.getCalendarDots = this.getCalendarDots.bind(this);
        this.handleChilds = this.handleChilds.bind(this);
        this.dateFormate = this.dateFormate.bind(this);
    }

    componentDidMount() {
        this.getCalendarDots(this.props.coparent);
        if (checkCookie() !== null) {
            this.props.dispatch(getChildrenUserAction());
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.coparent !== prevProps.coparent) {
            this.getCalendarDots(this.props.coparent);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.handleOpen) {
            let err = {
                title: '',
                kids: '',
                forfeit: '',
            };

            this.setState({
                currentDate: new Date(),
                title: '',
                child_ids: [],
                expire: expireSelectData[0].value,
                dates: [],
                tradeDates: [],
                temp1: [],
                temp2: [],
                flag: false,
                memo: '',
                response_memo: '',
                errors: err,
            });

            this.getCalendarDots(nextProps.coparent);
        }
    }

    getCalendarDots = function (coparent) {
        const sParams = {
            coparent_id: (coparent && coparent.id) || null,
        };

        getDots(sParams).then(async (resp) => {
            if (resp && resp.data) {
                await this.setState({ dots: resp.data });
            }
        });
    };

    handleTitle = function (e) {
        if (e.target.value === '') {
            this.setState({ title: e.target.value, errors: { ...this.state.errors, title: 'Field is required!' } });
        } else if (e.target.value.length > 50) {
            this.setState({
                title: e.target.value,
                errors: { ...this.state.errors, title: 'Name length values exceeded!(Max 50 symbols)' },
            });
        } else {
            this.setState({ title: e.target.value, errors: { ...this.state.errors, title: '' } });
        }
    };

    handleChilds = async function (value) {
        if (value.length === 0) {
            await this.setState({
                child_ids: value,
                errors: { ...this.state.errors, kids: 'You have to select at least one item' },
            });
        } else {
            await this.setState({ child_ids: value, errors: { ...this.state.errors, kids: '' } });
        }
    };

    handleChildren = async function (value) {
        const { child_ids } = this.state;
        let arr = [...child_ids];
        if (arr.includes(value)) {
            let index = arr.indexOf(value);
            arr.splice(index, 1);
            if (arr.length === 0) {
                await this.setState({
                    child_ids: arr,
                    errors: { ...this.state.errors, kids: 'You have to select at least one item' },
                });
            } else {
                await this.setState({ child_ids: arr, errors: { ...this.state.errors, kids: '' } });
            }
        } else {
            await this.setState({
                child_ids: [...this.state.child_ids, value],
                errors: { ...this.state.errors, kids: '' },
            });
        }
    };

    handleForfeit = async function () {
        const { dates, tradeDates } = this.state;
        await this.setState({ temp1: dates, temp2: tradeDates });
        this.setModalVisible(true, false);
    };

    handleTrade = async function () {
        const { tradeDates, dates } = this.state;
        await this.setState({ temp2: tradeDates, temp1: dates });
        this.setModalVisible(true, true);
    };

    formatDate = function (date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return month + '/' + day + '/' + year;
    };

    dateFormate = function (date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return year + '-' + month + '-' + day;
    };

    handleMemo = function (e) {
        this.setState({ memo: e.target.value });
    };

    getChildrenNames = function (child_ids) {
        let names = '';
        const { children } = this.props;
        child_ids.forEach((child) => {
            for (let i = 0; i < children.length; i++) {
                let c = children[i];
                if (child == c.id) {
                    names = names + c.name;
                    names = names + ', ';
                }
            }
        });
        names = names.trim();
        names = names.slice(0, names.length - 1);
        return names;
    };

    setModalVisible = function (visible, flag) {
        this.setState({ modalVisible: visible, flag: flag });
    };

    setModal1Visible = function (visible, msg) {
        this.setState({ modal1Visible: visible, msg: msg });
    };

    formattedDates = function (dates) {
        let arr = [];

        arr = dates.map((date) => {
            var d = new Date(date);
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();
            var year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return year + '-' + month + '-' + day;
        });

        return arr;
    };

    confirmDates = async function () {
        const { flag, temp1, dates, tradeDates, temp2 } = this.state;
        if (!flag) {
            let err = '';
            if (temp1.length > 0) {
                err = '';
            } else if (temp1.length === 0 && dates.length === 1) {
                err = 'You have to select at least one item';
            }
            this.setState({ dates: temp1, modalVisible: false, errors: { ...this.state.errors, forfeit: err } });
        } else {
            this.setState({ tradeDates: temp2, modalVisible: false });
        }
    };

    handleExpire = function (value) {
        this.setState({ expire: value });
    };

    verifyCustodyOnDate = (d, child_ids) => {
        let res = false;
        const { children, user } = this.props;
        let arr = [];
        children.forEach((child) => {
            if (child_ids.includes(child.id)) {
                arr.push(child);
            }
        });

        if (arr.length <= d.dots.length) {
            arr.forEach((item) => {
                const dot = (item.created_by_user === (user && user.id))?item.color: item.coparent_color;
                if (d.dots.includes(dot)) {
                    res = true;
                } else {
                    res = false;
                    return res;
                }
            });
        } else {
            res = false;
        }

        return res;
    };

    verifyTradeOnDate = (d, child_ids) => {
        let res = false;
        const { children, user } = this.props;
        let arr = [];
        children.forEach((child) => {
            if (child_ids.includes(child.id)) {
                arr.push(child);
            }
        });

        for (let i = 0; i < arr.length; i++) {
            let item = arr[i];
            const dot = (item.created_by_user === (user && user.id))?item.color: item.coparent_color;
            if (d.dots.includes(dot)) {
                res = false;
                break;
            } else {
                res = true;
            }
        }

        return res;
    };

    handleDates = async function (e) {
        let date = this.formatDate(e);
        const { flag, temp1, temp2, dates, tradeDates, dots, child_ids } = this.state;
        let d = dots[this.dateFormate(date)];
        if (!flag) {
            // Forfeit days
            if (d && d.dots && d.dots.length > 0) {
                if (child_ids.length > 0) {
                    if (!tradeDates.includes(date)) {
                        if (this.verifyCustodyOnDate(d, child_ids)) {
                            let arr = [];
                            temp1.forEach((item) => {
                                arr.push(item);
                            });
                            if (arr.includes(date)) {
                                let index = arr.indexOf(date);
                                arr.splice(index, 1);
                                await this.setState({ temp1: arr });
                            } else {
                                await this.setState({ temp1: [...temp1, date] });
                            }
                        }
                    }
                }
            } else {
            }
        } else {
            if (child_ids.length > 0) {
                if (this.verifyTradeOnDate(d, child_ids)) {
                    let arr = [];
                    temp2.forEach((item) => {
                        arr.push(item);
                    });
                    if (arr.includes(date)) {
                        let index = arr.indexOf(date);
                        arr.splice(index, 1);
                        await this.setState({ temp2: arr });
                    } else {
                        await this.setState({ temp2: [...temp2, date] });
                    }
                }
            }
        }
    };

    validate = function () {
        const { title, child_ids, dates } = this.state;
        let err = {};
        if (title === '') {
            err.title = 'Field is required!';
        } else if (title.length > 50) {
            err.title = 'Name length values exceeded!(Max 50 symbols)';
        } else {
            err.title = '';
        }

        if (child_ids.length === 0) {
            err.kids = 'You have to select at least one item';
        } else {
            err.kids = '';
        }

        if (dates.length === 0) {
            err.forfeit = 'You have to select at least one item';
        } else {
            err.forfeit = '';
        }

        if (err.title === '' && err.kids === '' && err.forfeit === '') {
            this.handleSubmit();
        } else {
            this.setState({ errors: err });
        }
    };

    handleSubmit = function () {
        const { title, child_ids, expire, dates, tradeDates, memo, response_memo } = this.state;
        this.setState({ loading: true });

        let data = {
            title: title,
            child_ids: child_ids,
            picture: null,
            start_date: null,
            exp_date: new Date(expire).toISOString(),
            dates: this.formattedDates(dates),
            trade_dates: this.formattedDates(tradeDates),
            memo: memo,
            response_memo: response_memo,
        };

        setupSwap(data).then((resp) => {
            this.setState({ loading: false });
            if (resp[0].message && resp[0].message !== '') {
                this.setModal1Visible(true, resp[0].message);
            } else {
                this.props.handleClose();
                this.props.refresh();
                this.props.dispatch(addLoading());
            }
        });
    };

    render() {
        const { Option } = Select;

        const {
            loading,
            title,
            child_ids,
            expire,
            dates,
            tradeDates,
            temp1,
            temp2,
            currentDate,
            memo,
            errors,
            dots,
        } = this.state;
        const { children, user } = this.props;

        function getColor(item){
            return (item.created_by_user === (user && user.id))?item.color: item.coparent_color;
        }


        return (
            <Modal
                title="Create Swap"
                className="cal-modal swap-modal"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Form>
                    <div className="scroll-wrap enhance-height">
                        <Form.Item className="input-sm">
                            <Input value={title} placeholder="Enter Swap Title" onChange={this.handleTitle} />
                            {errors.title !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.title}</div>
                            ) : null}
                        </Form.Item>
                        <Form.Item className="select-sm multi-select">
                            <Select
                                placeholder="Choose kid(s)"
                                value={child_ids}
                                mode="multiple"
                                onChange={this.handleChilds}
                            >
                                {Array.isArray(children) &&
                                    children.map((kid, i) => (
                                        <Option
                                            key={i}
                                            className={child_ids.includes(kid.id) ? 'text-color' : ''}
                                            value={kid.id}
                                        >
                                            <span className={'dot ' + colors[getColor(kid)]}></span>
                                            {kid.name}
                                        </Option>
                                    ))}
                            </Select>
                            {errors.kids !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.kids}</div>
                            ) : null}
                        </Form.Item>
                        <Form.Item className="input-col select-sm" label="Offer Expires In" labelCol={{ span: 24 }}>
                            <Select placeholder="Days" value={expire} onChange={this.handleExpire}>
                                {Array.isArray(expireSelectData) &&
                                    expireSelectData.map((item, i) => (
                                        <Option key={i} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <Form.Item className="calendar-btn">
                            <Button
                                onClick={this.handleForfeit}
                                className="fp-button-secondary full-btn"
                                type="primary"
                            >
                                <span>Select swap or forfeit day(s)</span>
                                <span>
                                    <img src={right} alt="right-arrow" />
                                </span>
                            </Button>
                            {errors.forfeit !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.forfeit}</div>
                            ) : null}
                            <div className="forfeit-list">
                                {Array.isArray(dates) && dates.length !== 0
                                    ? dates.map((date, i) => <div key={i}>{date}</div>)
                                    : null}
                            </div>
                        </Form.Item>

                        <Form.Item className="calendar-btn" label="Optional" labelCol={{ span: 24 }}>
                            <Button onClick={this.handleTrade} className="fp-button-secondary full-btn" type="primary">
                                <span>Select suggested trade day(s)</span>
                                <span>
                                    <img src={right} alt="right-arrow" />
                                </span>
                            </Button>
                            <div className="trade-list">
                                {Array.isArray(tradeDates) && tradeDates.length !== 0
                                    ? tradeDates.map((date, i) => <div key={i}>{date}</div>)
                                    : null}
                            </div>
                        </Form.Item>
                        <Form.Item className="sm-textarea" label="Swap memo: Optional" labelCol={{ span: 24 }}>
                            <TextArea value={memo} onChange={this.handleMemo} />
                        </Form.Item>
                    </div>
                    <div className="btn-scroll-wrap">
                        <Form.Item>
                            <Button onClick={this.validate} className="fp-button-primary full-btn" type="primary">
                                Submit proposal
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
                <SwapErrorModal
                    msg={this.state.msg}
                    openModal={this.state.modal1Visible}
                    closeModal={() => this.setModal1Visible(false, '')}
                />
                <Modal
                    title="Calendar"
                    className="cal-modal swap-modal sc-modal date-cal-modal"
                    centered
                    maskClosable={false}
                    visible={this.state.modalVisible}
                    onOk={() => this.setModalVisible(false)}
                    onCancel={() => this.setModalVisible(false)}
                >
                    <div className="edit-wrap cal-custom">
                        <div className="scroll-wrap scroll-cust">
                            <div className="cal-wrap cal-icon cal-with-dots">
                                <Calendar
                                    forfeit_dates={temp1}
                                    trade_dates={temp2}
                                    selected={currentDate}
                                    markedDates={dots}
                                    onChange={(e) => this.handleDates(e)}
                                />
                            </div>
                        </div>
                        <div className="btn-scroll-wrap cust-mt">
                            <Form.Item className="btn-top">
                                <Button onClick={this.confirmDates} className="fp-button-primary" type="primary">
                                    Done
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Modal>
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparent } = serviceReducer;
    return { user, error, children, coparent };
};

export default connect(mapStateToProps)(CreateSwap);
