import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from "@react-pdf/renderer";
import moment from "moment";

const POSTER_PATH = "https://image.tmdb.org/t/p/w154";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff"
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  movieContainer: {
    backgroundColor: "#f6f6f5",
    display: "flex",
    flexDirection: "row",
    padding: 5
  },
  movieDetails: {
    display: "flex",
    marginLeft: 5
  },
  movieTitle: {
    fontSize: 15,
    marginBottom: 10
  },
  movieOverview: {
    fontSize: 10
  },

  image: {
    height: 200,
    width: 150
  },
  subtitle: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: 150,
    alignItems: "center",
    marginBottom: 12
  },
  vote: {
    display: "flex",
    flexDirection: "row"
  },
  rating: {
    height: 10,
    width: 10
  },
  vote_text: {
    fontSize: 10
  },
  vote_pop: {
    fontSize: 10,
    padding: 2,
    backgroundColor: "#61C74F",
    color: "#fff"
  },
  vote_pop_text: {
    fontSize: 10,
    marginLeft: 4
  },
  overviewContainer: {
    minHeight: 110
  },
  detailsFooter: {
    display: "flex",
    flexDirection: "row"
  },
  lang: {
    fontSize: 8,
    fontWeight: 700
  },
  vote_average: {
    fontSize: 8,
    marginLeft: 4,
    fontWeight: "bold"
  }
});

export function PdfDocument(props) {
  return (
    <Document>
      <Page >
         {props.data
          ? props.data.reverse().map((a, index) => {
              return (
                <View key={index} style={styles.movieContainer}>
                
                  <View style={styles.movieDetails}>
                  <Text style={styles.vote_average}>{a.user.first_name} {a.user.last_name != '' ? ` ${a.user.last_name}` : ''} {", "}
                        {moment(a.createdAt, "YYYY-MM-DD hh:mm A").format(
                          " MMMM D Y hh:mm A"
                        )}</Text>

                  {a.image_id && a.image_id !== null ? (
                   <Image
                    style={styles.image}
                    source={
                       a.image_id && a.image_id !== null
                        ? a.image
                        : "null"
                    }
                   />
                  ) : (
                     <Text style={styles.vote_average}>
                        {a.text}
                      </Text>
                  )
                }
                     
                  </View>
                </View>
              );
            })
          : ""}
              
      </Page>
    </Document>
  );
}
