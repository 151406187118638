import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';

const { Option } = Select;
const { TextArea } = Input;

class DeleteAttachmentModal extends Component {
    constructor(props){
        super(props);
        this.confirmDelete = this.confirmDelete.bind(this);
    }
    confirmDelete = function(){
        this.props.closeModal();
        this.props.delete();
    }

    render() {
        return (
            <Modal
            title="Do you want to delete this attachment?"
            centered
            maskClosable={false}
            visible={this.props.openModal}
            className="payments-popup custom-popup del-popup"
            onOk={this.props.closeModal}
            onCancel={this.props.closeModal}
            >
              
                <Button type="primary"  onClick={() => this.confirmDelete()} className="btn fp-button-primary">
                    Confirm
                </Button>

                <Button type="primary" onClick={this.props.closeModal} className="btn fp-button-secondary">
                    Cancel
                </Button>
              
            </Modal>
        );
    }
}

export default DeleteAttachmentModal;