import React, { Component } from 'react';
import 'pages/SubscriptionNew/EditSubscription.scss';
import {
    Card,
    Button,
    Modal,
    Checkbox,
    Form,
    Input,
    Select,
    Empty,
    Tabs,
    Radio
} from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { relativeTimeThreshold } from 'moment';

import LoadingOverlay from 'react-loading-overlay';

const { Option } = Select;
const { TextArea } = Input;


class ChoosePlanModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: '1',
            plan_code: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.openModal) {
        
            let _selected = '1';
            if (nextProps.plan_code == '007') {
                _selected = '2';
            } else if (
                nextProps.plan_code == '0013' ||
                nextProps.plan_code == '0014' ||
                nextProps.plan_code == '0015' ||
                nextProps.plan_code == '0011'
            ) {
                _selected = '1';
            }

            this.setState({
                selected: _selected,
                plan_code: nextProps.plan_code
            });
        }
    }


    handleChange = async function (e) {
        this.setState({ selected: e.target.value });
    }

    handleConfirm = async function () {
        const { plan, selected } = this.state;
        if (selected === '1') {
            let plan = {
                plan_code: '007',
                name: 'Monthly Test Plan',
                price: 9.99,
                quantity: '1',
            };
            this.props.confirm(plan);
        } else if (selected === '2') {
            let plan = {
                plan_code: '0013',
                name: 'Three Months Test Plan',
                price: 29.99,
                quantity: '1',
            };
            this.props.confirm(plan);
        } else if (selected === '3') {
            let plan = {
                plan_code: '0014',
                name: 'Six Months Test Plan',
                price: 49.99,
                quantity: '1',
            };
            this.props.confirm(plan);
        } else if (selected === '4') {
            let plan = {
                plan_code: '0015',
                name: 'Nine Months Test Plan',
                price: 79.99,
                quantity: '1',
            };
            this.props.confirm(plan);
        } else if (selected === '5') {
            let plan = {
                plan_code: '0011',
                name: 'Yearly Test Plan',
                price: 99.99,
                quantity: '1',
            };
            this.props.confirm(plan);
        }

        this.props.closeModal();

    }

    getPlan = () => {
        let res = '';
        const { plan_code } = this.state;
        if (plan_code === '007') {
            res = '$9.99/month';
        } else if (plan_code === '0011') {
            res = '$99.99/year';
        } else if (plan_code === '0013') {
            res =  '$29.99/3 months';
        } else if (plan_code === '0014') {
            res =  '$49.99/6 months';
        } else if (plan_code === '0015') {
            res = '$79.99/9 months';
        }

        return res;
    }

    render() {
        const {
            selected,
            plan_code
        } = this.state;
        
        return (
            <Modal
                title="Choose Subscription Plan"
                centered
                maskClosable={false}
                visible={this.props.openModal}
                className="choose-plan-modal"
                onOk={this.props.closeModal}
                onCancel={this.props.closeModal}
            >

                <h3 className="current-plan">Current Plan - {this.getPlan()}</h3>
                <Radio.Group value={selected}>
                    {plan_code === '007' ? null : (
                        <Radio onClick={this.handleChange} value={'1'}>
                            {'$9.99/month'}
                        </Radio>
                    )}
                    {plan_code === '0013' ? null : (
                        <Radio onClick={this.handleChange} value={'2'}>
                            {'$29.99/3 months'}
                        </Radio>
                    )}
                    {plan_code === '0014' ? null : (
                        <Radio onClick={this.handleChange} value={'3'}>
                            {'$49.99/6 months'}
                        </Radio>
                    )}
                    {plan_code === '0015' ? null : (
                        <Radio onClick={this.handleChange} value={'4'}>
                            {'$79.99/9 months'}
                        </Radio>
                    )}
                    {plan_code === '0011' ? null : (
                        <Radio onClick={this.handleChange} value={'5'}>
                            {'$99.99/year'}
                        </Radio>
                    )}
                </Radio.Group>
                
                <Button type="primary" onClick={this.handleConfirm} className="btn fp-button-primary">
                    Confirm
                </Button>
    

            </Modal>
        );
    }
}


export default ChoosePlanModal;