import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'pages/Payment/Register/Register.scss';
import { Card, Button, Modal, Select, Input, Checkbox } from 'antd';
import LoadingOverlay from 'react-loading-overlay';
import FloatLabel from 'Components/FloatLabel/FloatLabel';
import left from 'Assets/left-arrow.svg';
import SetUp from 'pages/Payment/Register/Setup';
import UpdateSetup from 'pages/Payment/Register/UpdateSetup';
import TermsOfUse from 'pages/Payment/Register/TermsOfUse';
import PrivacyPolicy from 'pages/Payment/Register/PrivacyPolicy';
import { checkCustomer, getCoParentsCustomerDetails, getUserCustomerDetails, updateAccountStatus} from '../../../services/paymentService';

class PaymentSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            accSetupVisible: false,
            paymentSetupVisible : props.paymentSetupVisible,
            accUpdateSetupVisible: false,
            sendingForm: false,
            isExist: false,
            coParentsCustomerInfo: [],
            msg: '',
            resMsgModal: false,
            actionConfirm: false,
            userAccInfo: [],
            LoadingIs: false,
            showTermsAgree : false,
            modal2Visible: false,
        };
        
        this.setSetUpModalVisible = this.setSetUpModalVisible.bind(this);
        this.setACCModalVisible = this.setACCModalVisible.bind(this);
        this.coParentStatue = this.coParentStatue.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        const { Option } = Select;
    }

    setACCModalVisible(modalVisible) {
        this.setState({ [modalVisible] : true });
       
    }
    setSetUpModalVisible(modalVisible) {
        this.setState({[modalVisible]  : false });
        this.getUserDetails();
    }

    handleMsgClose() {
      this.setState({ msg: '',resMsgModal: false });
      if(this.state.actionConfirm)
        this.updateBusiness(this.state.actionType);
    }

    setTermsUseVisible (val){
        this.setState({showTermsAgree : val})
    }

    setModal2Visible = function(visible) {
        this.setState({ modal2Visible: visible });
    }

    coParentStatue(id) {
      let item = [];
     const {coParentsCustomerInfo} = this.state;
      if (coParentsCustomerInfo) {
        item = coParentsCustomerInfo.filter(
          (coParent, i) =>
            coParent.coparent_id == id && coParent.coparent_account
        );
      }
      
      if (item.length > 0) {
        if (
          item[0].coparent_account &&
          item[0].coparent_account.account_status == 1
        ) {
          return "Approved";
        } else {return "Disabled"};
      } else {
        var emptyString = "";
        return emptyString;
      }
      //return item.length > 0 && item.status == 1 ? true : false;
    }


     UNSAFE_componentWillReceiveProps = async function (nextProps) {
 
      if(nextProps.paymentSetupVisible){

        this.setState({paymentSetupVisible : true})
        this.getUserDetails();
      }
         
    };
    handleClose () {
      this.setState({paymentSetupVisible : false} , () => {
        this.props.handleClose()
      })
     
    }
    getUserDetails = async() => {
     this.setState({LoadingIs : true})
      await getUserCustomerDetails().then((resp) => {
        this.setState({LoadingIs : false})
        this.setState({userAccInfo : resp})
      })
      .catch((err) => {
       this.setState({LoadingIs : false})
      });
      var coParentsCustomerInfo = await getCoParentsCustomerDetails();
      var cust_exist = await checkCustomer();
      if(typeof cust_exist != undefined  ){
          this.setState({isExist : cust_exist.isExist, coParentsCustomerInfo})
      } 
    }

    updateBusiness = async (flag) => {
      this.setState({LoadingIs : true})
      updateAccountStatus({ flag })
        .then(async (resp) => {
          this.setState({LoadingIs : false})
          if (resp.updated) {
            this.getUserDetails();
            this.setState({msg: resp.message, resMsgModal: true, actionConfirm: false})
          } else {
            this.setState({msg: resp.error, resMsgModal: true, actionConfirm: false})
          }
        })
        .catch((err) => {
         this.setState({LoadingIs : false})
        //  handleCommonError("message", err);
        });
    };

    deleteItem(type, confirmCallback) {
      let msg = ""
      if (type == 1) {
        msg = "Do you really want to enable this account?";
      } else {
         msg =
        "You can retrieve the same account by clicking on the enable option once disabled. Do you really want to disable this account?";
      }
      this.setState({msg, resMsgModal: true, actionConfirm :true , actionType: type})
    }
    openTermsCondition () {
      this.setState({showTermsAgree : true})
    }


    render() {
        const { coparents, user } = this.props;
        const { isExist, resMsgModal, msg, userAccInfo, LoadingIs, paymentSetupVisible} = this.state;
        let updatedCoParents = coparents.filter((coParent, i) => {
          if(coParent != null && coParent.hasOwnProperty('email')){
            if(coParent.name != 'Household' &&  coParent.email && user.email )
                return user.email != coParent.email;  
          }
        });
      

        return (
          <React.Fragment>
            { resMsgModal  && (
                    <Modal
                        centered
                        visible={resMsgModal}
                        backdrop="static"
                        height={200}
                        className="modal-container req-card"
                        maskClosable={false}
                        onCancel={() => this.handleMsgClose()}
                    >
                        <div className="modal-head">
                            <div style={{textAlign: 'center', width: '80%', margin: 'auto', fontWeight: '700', marginBottom: '15px'}}>
                                {msg}
                            </div>
                            <Button type="primary" style={{width: '80%', marginTop: '10px', border: 'none', borderRadius: '4px' }} onClick={() =>  this.handleMsgClose()} className="btn fp-button-primary">
                               Ok
                            </Button>
                        </div>
                    </Modal>
            )}
                <Modal
                    title="Payments Setup"
                    centered
                    visible={paymentSetupVisible}
                    //onOk={() => this.setModal3Visible(false)}
                    onCancel={() => this.handleClose()}
                    className="account-modal pay-setup-modal"
                >
                    <LoadingOverlay
                            active={LoadingIs}
                            className="d-block pop-loader"
                            spinner
                            styles={{
                                spinner: (base) => ({
                                    ...base,
                                    width: '100px',
                                    '& svg circle': {
                                        stroke: 'rgb(56, 160, 235)',
                                    },
                                }),
                            }}
                >
                </LoadingOverlay>

                    <div className="recieve-head">Send/Receive Funds Electronically</div>
                      <div className="content-wrap payment-setup-wrap">
                    <Card bordered={false} className="recieve-card">
                        <div className="card-wrap">
                            <div className="card-data">
                                <span>ACH</span>
                            </div>
                            <div className="card-btn-wrap">
                            {userAccInfo.account == null  && (
                                <Button type="primary" className="btn-edit" onClick={() => this.setACCModalVisible('accSetupVisible')}>
                                    <span>Register</span>
                                </Button>
                            )}
                            {userAccInfo.account != null &&
                              userAccInfo.account.account_status == 1 && (    
                                <Button type="primary" className="btn-edit" onClick={() => this.setACCModalVisible('accUpdateSetupVisible')}>
                                    <span>Edit</span>
                                </Button>
                            )}
                            {userAccInfo.account != null &&
                              userAccInfo.account.account_status == 0 && (    
                                <Button type="primary" className="btn-enable" onClick={() => {
                                                                this.deleteItem(1);
                                                               } }>
                                    <span>Enable</span>
                                </Button>
                            )}
                            {userAccInfo.account != null &&
                              userAccInfo.account.account_status == 1 && (    
                                <Button type="primary" className="btn-disable" onClick={() => {
                                                                this.deleteItem(0);
                                                               } }>
                                    <span>Disable</span>
                                </Button>
                            )}
                            </div>
                        </div>
                    </Card>
                    <div className="review-head">Electronic Payment Checklist</div>
                    <Card bordered={false} className="review-card" onClick = {()=> this.setModal2Visible(true)} hoverable>
                        <div className="card-wrap">
                            <div className="card-data">
                                <span>Kincrew Privacy Policy</span>
                            </div>
                            <div className="card-btn-wrap">
                                <span className="succes">{userAccInfo.account && userAccInfo.account.id ? "Accepted" : ""}</span>
                            </div>
                        </div>
                    </Card>
                    <Card bordered={false} className="review-card">
                        <div className="card-wrap">
                            <div className="card-data">
                                <span>ID Verification Status</span>
                            </div>
                            <div className="card-btn-wrap">
                                <span className="succes">{isExist ? 'Approved' : 'Pending'}</span>
                            </div>
                        </div>
                    </Card>
                     <Card bordered={false} className="review-card">
                        <div className="card-wrap">
                            <div className="card-data">
                                <span>Bank Verification Status</span>
                            </div>
                            <div className="card-btn-wrap">
                                <span className="succes">{userAccInfo.account && userAccInfo.account.account_status == 1 ? 'Approved' : 'Pending' }</span>
                            </div>
                        </div>
                    </Card>
                    {userAccInfo.account && (
                      <Card bordered={false} className="review-card">
                          <div className="card-wrap">
                              <div className="card-data">
                                  <span>Your Registration Status</span>
                              </div>
                              <div className="card-btn-wrap">
                                  <span className="succes">{userAccInfo.account &&
                        userAccInfo.account.account_status == 1 &&
                        isExist
                          ? "Approved"
                          : "Pending"}</span>
                              </div>
                          </div>
                      </Card>
                    )}


                {updatedCoParents.map( (data, i) => {
                  var check =    this.coParentStatue(data.id);
                    return (
                      <div>
                        { check != '' && (
                          <Card bordered={false} key={i} className="review-card">
                                    <div className="card-wrap" >
                                        <div className="card-data">
                                            <span>{data.name} Account Status</span>
                                        </div>
                                        <div className="card-btn-wrap">
                                            <span className="succes">{check}</span>
                                        </div>
                                    </div>
                          </Card> 
                        ) }
                      </div>
                    ) 
                })}
                 </div>
                  <SetUp 
                    setupVisible={this.state.accSetupVisible}
                    setupModalVisible={this.setACCModalVisible}
                    handleClose={() => this.setSetUpModalVisible('accSetupVisible')} 
                  /> 
                   
                    
                    {this.state.accUpdateSetupVisible && (
                      <UpdateSetup 
                         UpdateSetupVisible={this.state.accUpdateSetupVisible}
                          handleClose={() => this.setSetUpModalVisible('accUpdateSetupVisible')}
                          userCustomerInfo = {userAccInfo.account} 
                     />
                    )}
                     
                  <TermsOfUse 
                    termsVisible={this.state.showTermsAgree}
                    viewOnly = {Boolean(true)}
                    onClose={() => this.setTermsUseVisible(false)}
                  /> 
                  <PrivacyPolicy
                    handleOpen={this.state.modal2Visible}
                    handleClose={() => this.setModal2Visible(false)}
                  />
                </Modal>
          </React.Fragment>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparents, coparent } = serviceReducer;
    return { user, error, children, coparents, coparent };
};

export default connect(mapStateToProps)(PaymentSetup);
