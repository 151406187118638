import React, { Component } from 'react';
import PreviewModal from 'pages/Payment/Payments/PreviewModal';

class ImageUploader extends Component {
    constructor(props){
      super(props)
      this.state = {
        fileList: [],
        file: null,
        modalVisible: false,
        flag: false,
      };    
      this.handleChange = this.handleChange.bind(this);
      this.handleDelete = this.handleDelete.bind(this);
      this.setModalVisible = this.setModalVisible.bind(this);
    } 
    
    UNSAFE_componentWillReceiveProps = async function(nextProps) {
        if(nextProps && nextProps.num) {
            let arr = [];
            await this.setState({ fileList: arr, flag: false, modalVisible: false});
        }
    }

    handleChange = async function(event) {

      let flag = false;
      if(this.state.fileList.length === 2){
         flag = true;
      }
      this.setState({
        fileList: [...this.state.fileList,URL.createObjectURL(event.target.files[0])],
        flag: flag
      });
      
      let file = Object.assign(event.target.files[0], {url:URL.createObjectURL(event.target.files[0]) }, {path: document.getElementById('img').value});
      
        this.props.handleImages(file);
      

    }  
    
    handleDelete = async function(file){
       const {fileList} = this.state;
       let arr = [...fileList];
       let index = arr.indexOf(file);
       arr.splice(index, 1);
       await this.setState({fileList: arr})
    }

    setModalVisible = function(modalVisible,file) {
       this.setState({modalVisible: modalVisible, file: file});
    }

    render() {
        const { fileList, flag } = this.state;
      return (
        <div>  
        <div>
          <input id="img" type={flag === false ? "file" : "button"}  multiple={true} onChange={this.handleChange}/>
          {Array.isArray(fileList) && fileList.map((file,i) => (
          <div key={i}> 
            <a  onClick={() => this.setModalVisible(true, file)}>Link</a>
            <span style={{color:'red', cursor: 'pointer'}}onClick={() => this.handleDelete(file)}>Delete</span>
          </div> 
          ))}
        </div>
        <PreviewModal  file={this.state.file} handleOpen={this.state.modalVisible} handleClose={() => this.setModalVisible(false)} />
        </div>
      );
    }
  }
  
  export default ImageUploader;