import * as types from './index';

export function addLoading() {
    return {
      type: types.ADD_LOADING,
    };
}

export function removeLoading() {
    return {
      type: types.REMOVE_LOADING,
    };
}

export function addReloading() {
  return {
    type: types.ADD_RELOADING,
  };
}

export function removeReloading() {
  return {
    type: types.REMOVE_RELOADING,
  };
}

export function addPermission() {
  return {
    type: types.ADD_PERMISSION,
  };
}


export function removePermission() {
  return {
    type: types.REMOVE_PERMISSION,
  };
}