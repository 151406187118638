import React, { Component } from 'react';
import 'pages/CalenderInner/Events/Events.scss';
import { Button, Modal, Form, Input, Select, Checkbox, TimePicker, Switch, Card } from 'antd';
import { connect } from 'react-redux';
import Calendar from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import { CalendarOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { createEvent } from 'services/calendarService';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import DisplayErrorModal from 'pages/CalenderInner/Events/DisplayErrorModal';
import { checkCookie } from 'utils/cookies';
import { getCoParentsUserAction, getChildrenUserAction , getCheckListAction} from 'actions/userActions';
import { getSetupProgress} from 'services/userService';
import { Link } from 'react-router-dom';
import checkListAdd from 'Assets/check-list-add.svg';
import checkListHome from 'Assets/check-list-home.svg';
import checkListCalender from 'Assets/check-list-calender.svg';
import checkListPayment from 'Assets/check-list-payment.svg';
import checkListDone from 'Assets/check-list-done.svg';
import HomeIcon from 'Assets/checklist-svg/home-icon';
import CalenderIcon from 'Assets/checklist-svg/calender-icon';
import PaymentIcon from 'Assets/checklist-svg/payment-icon';
import DoneIcon from 'Assets/checklist-svg/done-icon';


const { Option } = Select;
function onChange(time, timeString) {}


class SetupCheckListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            setup_points: this.getSetupPoints(),
            respp : JSON.parse(localStorage.getItem('checkListResp')),
            calendarEnabled : localStorage.getItem('calendarEnabled'),
            paymentEnabled: localStorage.getItem('paymentEnabled'),
            chatEnabled: localStorage.getItem('chatEnabled'),
            reportsEnabled: localStorage.getItem('reportsEnabled'),
            baseListEnabled:localStorage.getItem('baseListEnabled')
            
        };
        
    }
    getSetupPoints = () => {
         const {checkList} = this.props
        return [
          {
            id: "profile",
            title: "Setup My Profile",
            icon: HomeIcon,
            iconCheckList : checkListAdd,
            iconCompleted: checkListDone,
            navigateTo: "household",
            enabled: true,
            
          },
          {
            id: "coParent",
            title: "Invite Co-Parent",
            icon: checkListHome,
            iconCheckList : checkListAdd,
            iconCompleted: checkListDone,
            navigateTo: "household",
            enabled: true,
            
          },
          {
            id: "children",
            title: "Add Children To Households",
            icon: checkListHome,
            iconCheckList : checkListAdd,
            iconCompleted: checkListDone,
            navigateTo: "household",
            enabled: true,
            
          },
          {
            id: "custody",
            title: "Setup Custody Schedule",
            icon: checkListCalender,
            iconCheckList : checkListAdd,
            iconCompleted: checkListDone,
            navigateTo: "calendar",
            enabled: false,
           
          },
          {
            id: "specialDates",
            title: "Add Special Dates ,Birthdays, Holidays, Annual Events",
            icon: checkListCalender,
            iconCheckList : checkListAdd,
            iconCompleted: checkListDone,
            navigateTo: "calendar",
             enabled: false,
           
          },
          {
            id: "expenseCategories",
            title: "Setup Child Expense Categories",
            icon: checkListPayment,
            iconCheckList : checkListAdd,
            iconCompleted: checkListDone,
            navigateTo: "payment",
            enabled: false,
          
          },
          {
            id: "recurring",
            title: "Setup Recurring Expenses",
            icon: checkListPayment,
            iconCheckList : checkListAdd,
            iconCompleted: checkListDone,
            navigateTo: "payment",
            enabled: false,
            
          },
        ];
    };


    componentDidMount() {
        if (localStorage.third_party !== 'true') {
            this.props.dispatch(getCheckListAction());
        }

    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        if(!this.state.loading) {
             this.setState({loading : true})
        }
         setTimeout(() => {this.setState({loading : false})}, 5000);
    };


    setModalVisible = function (visible) {
        this.setState({ modalVisible: visible });
    };


    hideModal (){
        const {checkList,baseListStatus} = this.props
        this.props.handleClose()
    }

    handleClick(e) {
     e.preventDefault();
     }


    render() {
        const {
           loading,setup_points,respp,baseListEnabled
        } = this.state;
        const {checkList,baseListStatus,checkListStatus} = this.props
        var baseCheckListEnabled = false
         if(checkListStatus != undefined) {
             const calendarEnabledc =
                            !!checkListStatus["profile"] &&
                            !!checkListStatus["coParent"] &&
                            !!checkListStatus["children"];
            baseCheckListEnabled = calendarEnabledc;
         }
        return (
            <Modal
                title="Setup Checklist"
                className="cal-modal checklist-modal"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={'loader-wrap pop-loader ' + (loading ? 'd-block' : 'checklist-loader-d-none')}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '80px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Form>
                <ul className="checklist-wrap">
                    {setup_points.map((item, index) => {
                        var completed = 0
                        const {id} = item
                        if(checkListStatus!=null && checkListStatus[id] == 1)
                        {
                            completed = 1
                        }
                        return (
                            <li className = {completed ? 'checklist-active' : ''} key= {index}>
                            {item.enabled || baseCheckListEnabled == true ? (
                                <Link to={'/'+item.navigateTo} onClick= {() =>this.hideModal()}>
                                <div className="checklist-list-item">
                                   <div className = "checklist-list-item-inner">
                                        <span className = {completed ? 'svg-color-color-white' : 'svg-fill-color-grey'}>
                                           {(item.id == "profile" || item.id == "coParent" || item.id == 'children') && (
                                           <HomeIcon/>)}
                                            {(item.id == "custody" || item.id == "specialDates") && (
                                           <CalenderIcon/>)}
                                           {(item.id == "expenseCategories" || item.id == "recurring") && (
                                           <PaymentIcon/>)}
                                        </span>
                                        <span>{item.title}</span>
                                    </div>
                                    <span>
                                    {completed ? (
                                        <DoneIcon/>
                                    ) : (
                                    <img src={completed ? item.iconCompleted : item.iconCheckList} alt="add-icon"/>
                                    )}
                                       
                                    </span>
                                </div>
                             </Link>
                            ):(
                                <Link disabled>
                                <div className="checklist-list-item">
                                   <div className = "checklist-list-item-inner">
                                        <span className = "svg-fill-color">
                                           {(item.id == "profile" || item.id == "coParent" || item.id == 'children') && (
                                           <HomeIcon/>)}
                                            {(item.id == "custody" || item.id == "specialDates") && (
                                           <CalenderIcon/>)}
                                           {(item.id == "expenseCategories" || item.id == "recurring") && (
                                           <PaymentIcon/>)}
                                        </span>
                                        <span>{item.title}</span>
                                    </div>
                                    <span>
                                        {completed ? (
                                        <DoneIcon/>
                                    ) : (
                                        <img src={completed ? item.iconCompleted : item.iconCheckList} alt="add-icon"/>
                                    )}
                                    </span>
                                </div>
                             </Link>
                            )}
                             
                            </li>
                        )
                    })}
                </ul>
                {/*  <div className="checklist-bottom">
                        <span>You Can still Access Checklist in Menu</span>
                   </div>*/}
                </Form>        
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, loadings, error, coparent, checkListStatus } = serviceReducer;
    return { children, user, loadings, error, coparent, checkListStatus };
};

export default connect(mapStateToProps)(SetupCheckListModal);
