import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, InputNumber, Radio, DatePicker, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import {} from 'services/categoryService';
import moment from 'moment';
import {updateSpecialCategory} from 'services/categoryService';

const { Option } = Select;
const { TextArea } = Input;

class UpdateModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            percent_verified: false,
            category_name: '',
            category: null,
            you: 0,
            _coparent: 0,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDispute = this.handleDispute.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps && nextProps.handleOpen && nextProps.currentCategory && nextProps.categoryName) {
            this.setState({you: nextProps.currentCategory.coparent_amount_percent, _coparent: nextProps.currentCategory.creator_amount_percent, category_name: nextProps.categoryName, category: nextProps.currentCategory, percent_verified: false});
        } else if(nextProps.currentCategory && nextProps.categoryName) {
            this.setState({you: nextProps.currentCategory.coparent_amount_percent, _coparent: nextProps.currentCategory.creator_amount_percent, category_name: nextProps.categoryName, percent_verified: false});
        }
    }

    handleSubmit = async function () {
        const {percent_verified, category_name} = this.state;
        const {currentCategory, coparent, coparents} = this.props;
        await this.setState({ loading: true });
        let options = {
            id: currentCategory.id,
            default_id: currentCategory.default_id,
            status: 1,
            creator_percent: currentCategory.creator_amount_percent,
            coparent_percent: currentCategory.coparent_amount_percent,
        };
            
        let cp_id = currentCategory.coparent_user_id || currentCategory.coparent_standalone_id;

        updateSpecialCategory(options,cp_id)
            .then(resp => {
            this.setState({loading: false});
            this.props.handleClose();
            this.props.refresh();
        }).catch(err => {
            this.setState({loading: false});
        })  
            
        
    }

    handleDispute = async function() {
        const {percent_verified, category_name} = this.state;
        const {currentCategory, coparent, coparents} = this.props;
        this.setState({loading: true});
        let options = {
            id: currentCategory.id,
            default_id: currentCategory.default_cat_id,
            status: 2,
            creator_percent: currentCategory.creator_amount_percent,
            coparent_percent: currentCategory.coparent_amount_percent,
        };
            
        let cp_id = currentCategory.coparent_user_id || currentCategory.coparent_standalone_id;

        updateSpecialCategory(options,cp_id)
        .then(resp => {
            this.setState({loading: false});
            this.props.handleClose();
            this.props.refresh();
        }).catch(err => {
            this.setState({loading: false});
        }) 
            
        
    }
    render() {
        const {percent_verified, loading, category_name, errors, you, _coparent} = this.state;

        return (
            <Modal
            title="Edit Category"
            centered
            maskClosable={false}
            visible={this.props.handleOpen}
            className="payments-popup edit-payment"
            onOk={this.props.handleClose}
            onCancel={this.props.handleClose}
            >
            <LoadingOverlay
                    active={loading}
                    className={'loader-wrap pop-loader'}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
            ></LoadingOverlay>
            <Form>
                <Form.Item className="input-sm">
                    <Input value={category_name} readOnly />
                </Form.Item>
                <div className="input-number">
                    <Form.Item label="You" labelCol={{ span: 24 }}>
                    <InputNumber value={you} readOnly />
                    </Form.Item>
                    <Form.Item label="Co-parent" labelCol={{ span: 24 }}>
                    <InputNumber value={_coparent} readOnly />
                    </Form.Item>
                </div>
                <Form.Item className="cb-form">
                    <Checkbox checked>
                        <span className="cb-text"> I verified % Agreed upon with Co-parent</span>
                    </Checkbox>
                </Form.Item>
                <Form.Item className="btn-full">
                    <Button onClick={this.handleSubmit} className="fp-button-primary" type="primary">
                        <span>Accept</span>
                    </Button>
                </Form.Item>
                <Form.Item className="btn-full">
                    <Button onClick={this.handleDispute} className="fp-button-secondary" type="primary">
                        <span>Dispute</span>
                    </Button>
                </Form.Item>
            </Form>
           </Modal>
        );
    }
}


const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparent, coparents } = serviceReducer;
    return { user, error, children, coparent, coparents };
};
export default connect(mapStateToProps)(UpdateModal);