import React from 'react';
import PropTypes from 'prop-types';

import Days from './MonthView/Days';
import Weekdays from './MonthView/Weekdays';
import WeekNumbers from './MonthView/WeekNumbers';

import { CALENDAR_TYPES, CALENDAR_TYPE_LOCALES } from './shared/const';
import { isCalendarType } from './shared/propTypes';
import { ConsoleSqlOutlined } from '@ant-design/icons';

function getCalendarTypeFromLocale(locale) {
    return (
        Object.keys(CALENDAR_TYPE_LOCALES).find((calendarType) =>
            CALENDAR_TYPE_LOCALES[calendarType].includes(locale),
        ) || CALENDAR_TYPES.US
    );
}

export default function MonthView(props) {
    const {
        activeStartDate,
        locale,
        onMouseLeave,
        showFixedNumberOfWeeks,
        markedDates,
        children,
        selected,
        swaps,
        user,
        thirdParty
    } = props;
    const {
        calendarType = getCalendarTypeFromLocale(locale),
        formatShortWeekday,
        onClickWeekNumber,
        showWeekNumbers,
        ...childProps
    } = props;

    function formatDate(date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    function getDateArray(start, end) {
        var arr = [];
        var dt = new Date(start);
        while (dt <= end) {
            arr.push(new Date(dt));
            dt.setDate(dt.getDate() + 1);
        }
        return arr;
    }

    function getChildren() {
        let arr = [];
        let startDate = activeStartDate;
        let endDate = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth() + 1, 0);
        let dates = getDateArray(startDate, endDate);
        let formattedDates = [];
        dates.forEach((date) => {
            formattedDates.push(formatDate(date));
        });
        let dots = [];
        for (let i = 0; i < formattedDates.length; i++) {
            let date = formattedDates[i];
            if(markedDates){
                let custodyDate = markedDates[date];
                if (custodyDate) {
                    custodyDate.dots.forEach((color) => {
                        if (dots.includes(color)) {
                        } else {
                            dots.push(color);
                        }
                    });
                }
            }
        }

        if (Array.isArray(children)) {
            children.forEach((child) => {
            let dot ; 

            if(thirdParty) {
                 dot = (child.created_by_user === (localStorage.coparent_id))? child.color: child.coparent_color
            } else {
                 dot = (child.created_by_user === (user && user.id))? child.color: child.coparent_color
            }
            
                if (dots.includes(dot)) {
                    arr.push(child);
                }
            });
        }

        return arr;
    }

    function renderWeekdays() {
        return (
            <Weekdays
                calendarType={calendarType}
                formatShortWeekday={formatShortWeekday}
                locale={locale}
                onMouseLeave={onMouseLeave}
            />
        );
    }

    function renderWeekNumbers() {
        if (!showWeekNumbers) {
            return null;
        }

        return (
            <WeekNumbers
                activeStartDate={activeStartDate}
                calendarType={calendarType}
                onClickWeekNumber={onClickWeekNumber}
                onMouseLeave={onMouseLeave}
                showFixedNumberOfWeeks={showFixedNumberOfWeeks}
            />
        );
    }

    function renderDays() {
        return (
            <Days
                swaps={swaps}
                markedDates={markedDates}
                selected={selected}
                calendarType={calendarType}
                {...childProps}
            />
        );
    }

    function getColor(item){
            if(thirdParty) {
                return (item.created_by_user === (localStorage.coparent_id))?item.color: item.coparent_color;

            } else {
                return (item.created_by_user === (user && user.id))?item.color: item.coparent_color;
            }
            
    }

    let colors = {
        '#c910e8': 'Pink',
        '#2196f3': 'Blue',
        '#673ab7': 'Purple',
        '#ff0000': 'Red',
        '#4caf50': 'Green',
        '#ffc107': 'Orange',
        '#00bcd4': 'Cyan',
        '#795548': 'Brown',
        '#ffffff': 'White',
        '#999999': 'Grey',
        '#333333': 'Charcoal',
        '#000000': 'Black',
        gradient1: 'Gradient1',
        gradient2: 'Gradient2',
    };

    const className = 'react-calendar__month-view';
    const child_arr = getChildren();
    
    return (
        <div className={[className, showWeekNumbers ? `${className}--weekNumbers` : ''].join(' ')}>
            <div className="child-name">
                {Array.isArray(child_arr) &&
                    child_arr.map((child, i) => (
                        <span key={i} className={colors[getColor(child)]}>
                            {child.name}
                        </span>
                    ))}
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                }}
            >
                {renderWeekNumbers()}
                <div
                    style={{
                        flexGrow: 1,
                        width: '100%',
                    }}
                >
                    {renderWeekdays()}
                    {renderDays()}
                </div>
            </div>
        </div>
    );
}

MonthView.propTypes = {
    activeStartDate: PropTypes.instanceOf(Date).isRequired,
    calendarType: isCalendarType,
    formatShortWeekday: PropTypes.func,
    locale: PropTypes.string,
    onClickWeekNumber: PropTypes.func,
    onMouseLeave: PropTypes.func,
    showFixedNumberOfWeeks: PropTypes.bool,
    showWeekNumbers: PropTypes.bool,
};
