import React, { Component } from 'react';
import { Card, Button, Modal, Form, Radio, Select, Avatar, Input, Empty, Switch } from 'antd';
import { connect } from 'react-redux';
import { getHouseHoldAction } from 'actions/houseHoldAction.js';
import { addChildren, editChildren, deleteChildren } from '../../../services/houseHoldService';
import LoadingOverlay from 'react-loading-overlay';
import userImg from 'Assets/defaultAvatar.png';
const validator = require('email-validator');
const { Option } = Select;

class AddThirdParty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            pic: '',
            picture: '',
            imgRef: '',
            gender: 'Select Gender',
            relation: 'Select Relation',
            LoadingIs: false,
            child_ids: [],
            errorIs: { name: '', email: '', gender: '', kid: '', relation: '' },
            permission: { payment: true, calendar: true, chat: true },
            paymentPermission: true,
            calendarPermission: true,
            chatPermission: true,
            isThirdParty: localStorage.getItem("third_party"),
            resMsg: ''
        };
        this.handleChilds = this.handleChilds.bind(this);
    }

    handleOnChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
        this.validation(name, value);
    };

    handleChange = (value) => {
        // this.setState({ gender: value });
        const { errorIs } = this.state;
        errorIs.gender = value === 'Select Gender' ? 'Gender is required!' : '';
        this.setState({ gender: value, errorIs });
    };
    handleRelationChange = (value) => {
        const { errorIs } = this.state;
        errorIs.relation = value === 'Select Relation' ? 'Relation is required!' : '';
        this.setState({ relation: value, errorIs });
    }

    handleModal = (modalVisible) => {
        this.props.handleFunc({ modalVisible: modalVisible, editIs: false });
        this.resetField();
    };

    resetField = () => {
        this.setState({
            name: '',
            email: '',
            gender: 'Select Gender',
            relation: 'Select Relation',
            resMsg: '',
            errorIs: { name: '', email: '', gender: '', kid: '', relation: '' },
            pic: '',
            picture: '',
            imgRef: '',
        });
    };

    validation(name, value) {
        const { errorIs } = this.state;
        switch (name) {
            case 'name':
                errorIs.name = !value.length ? 'Name is required!' : value.length > 50 ? 'Name is Too Long (Must be 50 characters)' : '';
                break;
            case 'email':
                errorIs.email = value.length > 0 && !validator.validate(value) ? 'Invalid Email' : '';
                break;
            case 'gender':
                errorIs.gender = value == 'Select Gender' ? 'Gender is required!' : '';
            case 'relation':
                errorIs.relation = value == 'Select Relation' ? 'Relation is required!' : '';

            default:
                break;
        }
        return errorIs;
    }

    validateForm() {
        const { errorIs, name, email, gender, child_ids, relation } = this.state;
        errorIs.name = !name.length ? 'Name is required!' : name.length > 50 ? 'Name is Too Long (Must be 50 characters)' : '';
        errorIs.email = !email.length ? 'Email is required!' : email.length > 0 && !validator.validate(email) ? 'Invalid Email' : '';
        errorIs.gender = gender == 'Select Gender' ? 'Gender is required!' : '';
        errorIs.relation = relation == 'Select Relation' ? 'Relation is required!' : '';
        errorIs.kid = child_ids.length == 0 ? 'You have to select at least one kid!' : '';
        this.setState({ errorIs });
        return !errorIs.name && !errorIs.email && !errorIs.gender && !errorIs.kid && !errorIs.relation;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { response, editIs, data } = this.props;
        const { name, email, gender, child_ids, permission, picture, relation } = this.state;
        const houseHoldData = response.houseHoldReducer.houseHold;
        var householdId = typeof houseHoldData === 'object' ? houseHoldData.id : '';
        const coParentData = houseHoldData.Coparents.length > 0 ? houseHoldData.Coparents : '';
        const coParentId = coParentData && coParentData.map((item) => item.id);
        const user = response.serviceReducer.user;
        if (user.email == email.trim()) {
            this.setState({ resMsg: 'You cant add yourself as a Third Party' })
        } else {
            if (this.validateForm() && householdId) {
                this.setState({ LoadingIs: true });
                if (!editIs) {
                    const currentData = {
                        household_id: householdId,
                        type: 'member',
                        name: name,
                        gender: gender,
                        relation: relation,
                        email: email,
                        children: child_ids,
                        permission: permission,
                        picture: picture ? picture : null,
                    };
                    addChildren(currentData).then((res) => {
                        this.setState({ LoadingIs: false });
                        if (res.code) {
                            this.setState({ resMsg: res.message });
                        } else {
                            this.props.handleFunc({ modalVisible: false });
                            if (this.state.isThirdParty == 'false') {
                                this.props.dispatch(getHouseHoldAction());
                            }
                            this.resetField();
                            this.props.refresh();
                        }
                    });
                } else {
                    const _id = data.id ? data.id : '';
                    const currentData = {
                        household_id: householdId,
                        type: 'member',
                        name: name,
                        gender: gender,
                        relation: relation,
                        email: email,
                        id: _id,
                        children: child_ids,
                        permission: permission,
                        picture: picture ? picture : null,
                    };
                    editChildren(_id, currentData).then((res) => {
                        if (res) {
                            this.setState({ LoadingIs: false });
                            this.props.handleFunc({ modalVisible: false, editIs: false, deleteIs: false });
                            if (this.state.isThirdParty == 'false') {
                                this.props.dispatch(getHouseHoldAction());
                            }

                            this.resetField();
                            this.props.refresh();
                        }
                    });
                }
            }
        }

    };

    handleDeleteThirdParty = () => {
        const { data } = this.props;
        let _id = data.id ? data.id : '';
        this.setState({ LoadingIs: true });
        deleteChildren(_id).then((res) => {
            if (res) {
                this.setState({ LoadingIs: false });
                if (this.state.isThirdParty == 'false') {
                    this.props.dispatch(getHouseHoldAction());
                }

                this.props.handleFunc({ modalVisible: false, editIs: false });
            }
        });
    };

    handleChilds = async function (value) {
        const { errorIs } = this.state;
        if (value.length === 0) {

            await this.setState({
                child_ids: value,
                errorIs: { ...this.state.errorIs, kid: 'You have to select at least one kid!' }

            });
        } else {
            await this.setState({ child_ids: value, errorIs: { ...this.state.errorIs, kid: '' } });
        }
    };

    componentWillReceiveProps(props) {
        const { data, editIs, deleteIs } = props;

        if (data && editIs && !deleteIs) {
            this.setState({
                name: data.name ? data.name : '',
                email: data.email ? data.email : '',
                gender: data.gender ? data.gender : '',
                relation: data.relation ? data.relation : 'Select Relation',
                child_ids: data.children ? data.children : '',
                permission: data.permission ? data.permission : { payment: true, calendar: true, chat: true },
                paymentPermission: data.permission && data.permission.payment != null ? data.permission.payment : true,
                calendarPermission: data.permission && data.permission.calendar != null ? data.permission.calendar : true,
                chatPermission: data.permission && data.permission.chat != null ? data.permission.chat : true,
                pic: data.file_url ? data.file_url : '',
                picture: data.picture ? data.picture : '',
                imgRef: data.file_url ? data.file_url : '',
            });
        }
    }


    handleClick(val, key) {
        const {
            paymentPermission,
            calendarPermission,
            chatPermission,
            permission,
        } = this.state;
        const data = {};
        if (key == 'calendar') {
            this.setState({ calendarPermission: val })
            permission.calendar = val;

        } else if (key == 'payment') {
            this.setState({ paymentPermission: val })
            permission.payment = val;

        } else if (key == 'chat') {
            this.setState({ chatPermission: val })
            permission.chat = val;
        }

    }
    handleErrorModal = () => {
        this.setState({ resMsg: '' });
    };

    handleUploadImage = (e) => {
        var self = this;
        var reader = new FileReader();
        if (e.target.files[0]) {
            var file = e.target.files[0];
            var name = e.target.files[0].name;
            self.setState({
                imgRef: URL.createObjectURL(file),
            });
            reader.onload = function (upload) {
                self.setState({
                    picture: { base64: upload.target.result, name: name, uri: '' },
                });
            };
            reader.readAsDataURL(file);
        }
    };

    render() {
        const { name, email, gender, LoadingIs, errorIs, child_ids, paymentPermission, calendarPermission, chatPermission, resMsg, relation } = this.state;
        const { editIs, deleteIs, response } = this.props;
        const children = response && response.serviceReducer && response.serviceReducer.children;
        return (
            <React.Fragment>

                {resMsg && (
                    <Modal
                        centered
                        visible={true}
                        backdrop="static"
                        height={200}
                        className="modal-container req-card"
                        maskClosable={false}
                        onCancel={() => this.handleErrorModal(false)}
                    >
                        <div className="modal-head">
                            <div style={{ textAlign: 'center', width: '80%', margin: 'auto', fontWeight: '700', marginBottom: '15px' }}>
                                {resMsg}
                            </div>
                            <Button type="primary" style={{ width: '80%', marginTop: '10px', border: 'none', borderRadius: '4px' }} onClick={() => this.handleErrorModal(false)} className="btn fp-button-primary">
                                Ok
                            </Button>
                        </div>
                    </Modal>
                )}

                <Modal
                    centered
                    visible={this.props.modalVisible}
                    backdrop="static"
                    maskClosable={false}
                    className={deleteIs ? 'add-child-popup del-pop' : 'modal-container add-child-popup member-popup'}
                    onCancel={() => this.handleModal(false)}
                >
                    {LoadingIs && (
                        <LoadingOverlay
                            active={true}
                            className="d-block pop-loader"
                            spinner
                            styles={{
                                spinner: (base) => ({
                                    ...base,
                                    width: '100px',
                                    '& svg circle': {
                                        stroke: 'rgb(56, 160, 235)',
                                    },
                                }),
                            }}
                        ></LoadingOverlay>
                    )}
                    <div className="modal-head">
                        <h5>{`${
                            editIs
                                ? `Edit Third Party`
                                : deleteIs
                                    ? `Do you want to delete this third party ?`
                                    : `Add Third party`
                            }`}</h5>
                    </div>
                    {!deleteIs ? (
                        <Form className="tp-form" onSubmit={(e) => this.handleSubmit(e)}>
                            <div className="member-form">
                                <div className="head-avatar">
                                    <div className="img-upload position-r wid-box c-pointer">
                                        <Avatar
                                            src={
                                                this.state.imgRef
                                                    ? this.state.imgRef
                                                    : this.state.pic
                                                        ? this.state.pic
                                                        : userImg
                                            }
                                            className={this.state.imgRef ? "img-exist" : this.state.pic ? "img-exist" : "no-img"}
                                            alt="user-avatar"
                                        />
                                        <input
                                            type="file"
                                            name="picture"
                                            className="position-ab c-pointer"
                                            encType="multipart/form-data"
                                            onChange={this.handleUploadImage}
                                        />
                                        <div className="edit-icon">
                                            <svg version="1.1" id="Capa_1" viewBox="0 0 512 512">
                                                <polygon points="51.2,353.28 0,512 158.72,460.8" />
                                                <rect
                                                    x="89.73"
                                                    y="169.097"
                                                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -95.8575 260.3719)"
                                                    width="353.277"
                                                    height="153.599"
                                                />
                                                <path
                                                    d="M504.32,79.36L432.64,7.68c-10.24-10.24-25.6-10.24-35.84,0l-23.04,23.04l107.52,107.52l23.04-23.04
                                C514.56,104.96,514.56,89.6,504.32,79.36z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`input-full ${errorIs.name ? `err-msg` : ``}`}>
                                    <Input
                                        placeholder="Enter Name"
                                        name="name"
                                        value={name}
                                        onChange={this.handleOnChange}
                                    />
                                    {errorIs.name ? <div className="form-err remo_position">{errorIs.name}</div> : null}
                                </div>
                                <div className={`input-full ${errorIs.email ? `err-msg` : ``}`}>
                                    <Input
                                        placeholder="Email address"
                                        name="email"
                                        value={email}
                                        onChange={this.handleOnChange}
                                    />
                                    {errorIs.email ? <div className="form-err remo_position ">{errorIs.email}</div> : null}
                                </div>
                                <div className={` input-full ${errorIs.gender ? `err-msg` : ``}`}>
                                    <Select
                                        name="gender"
                                        className="cust-select"
                                        value={gender}
                                        onChange={this.handleChange}
                                    >
                                        <Option value="male">Male</Option>
                                        <Option value="female">Female</Option>
                                        <Option value="nonbinary">Non Binary</Option>
                                    </Select>
                                    {errorIs.gender ? (
                                        <div className="form-err remo_position ">{errorIs.gender}</div>
                                    ) : null}
                                </div>
                                <div className={` input-full ${errorIs.relation ? `err-msg` : ``}`}>
                                    <Select
                                        name="relation"
                                        className="cust-select"
                                        value={relation}
                                        onChange={this.handleRelationChange}
                                    >
                                        <Option value="0">Extended Family</Option>
                                        <Option value="1">Child Care</Option>
                                        <Option value="2">Other</Option>
                                    </Select>
                                    {errorIs.relation ? (
                                        <div className="form-err remo_position ">{errorIs.relation}</div>
                                    ) : null}
                                </div>

                                <div className={` input-full ${errorIs.kid ? `err-msg` : ``}`}>
                                    <Select
                                        placeholder="Choose Kid(s)"
                                        value={child_ids}
                                        className="cust-select multi-custom-select"
                                        mode="multiple"
                                        onChange={this.handleChilds}
                                    >
                                        {Array.isArray(children) &&
                                            children.map((kid, i) => (
                                                <Option
                                                    key={i}

                                                    value={kid.id}
                                                >
                                                    {' '}

                                                    {kid.name}
                                                </Option>
                                            ))}
                                    </Select>
                                    {errorIs.kid ? (
                                        <div className="form-err remo_position ">{errorIs.kid}</div>
                                    ) : null}
                                </div>

                                <div className='permission-switch-head'>
                                    <span>Permissions</span>
                                </div>
                                <div className='permission-switch-wrap'>

                                    <div className='permission-switch-inner'>
                                        <span>Calender</span>
                                        <Switch
                                            checked={calendarPermission}
                                            trackColor={{ true: "#4CD964", false: "grey" }}
                                            thumbColor="#ffffff"
                                            onClick={(value) => {
                                                this.setState({ calendarPermission: value }, () => {
                                                    this.handleClick(value, "calendar");
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className='permission-switch-inner'>
                                        <span>Request Payments</span>
                                        <Switch
                                            checked={paymentPermission}
                                            trackColor={{ true: "#4CD964", false: "grey" }}
                                            thumbColor="#ffffff"
                                            onClick={(value) => {
                                                this.setState({ paymentPermission: value }, () => {
                                                    this.handleClick(value, "payment");
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className='permission-switch-inner'>
                                        <span>Chat</span>
                                        <Switch
                                            checked={chatPermission}
                                            trackColor={{ true: "#4CD964", false: "grey" }}
                                            thumbColor="#ffffff"
                                            onClick={(value) => {
                                                this.setState({ chatPermission: value }, () => {
                                                    this.handleClick(value, "chat");
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: '20px' }} className="full-btn btn-confirm">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="btn-modal"
                                    onClick={(e) => this.handleSubmit(e)}
                                >
                                    {editIs ? 'Update' : 'Add Third party'}
                                </Button>
                            </div>
                        </Form>
                    ) : (
                            <Form>
                                <div className="modal-bottom">
                                    <Button type="warning" className="ant-btn" onClick={() => this.handleModal(false)}>
                                        CANCEL
                                </Button>
                                    <Button
                                        type="primary"
                                        className="btn-add"
                                        onClick={() => this.handleDeleteThirdParty()}
                                    >
                                        OK
                                </Button>
                                </div>
                            </Form>
                        )}
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (response) => ({ response });

export default connect(mapStateToProps)(AddThirdParty);
