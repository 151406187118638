import React, { Component } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import { connect } from 'react-redux';
import { getHouseHoldAction } from 'actions/houseHoldAction.js';
import { ChangePwd } from '../../../services/houseHoldService';
import LoadingOverlay from 'react-loading-overlay';

class ChangePassword extends Component {
    state = {
        currentPwd: '',
        newPwd: '',
        confirmNewPwd: '',
        errorIs: { currentPwd: '', newPwd: '', confirmNewPwd: '' },
        LoadingIs: false,
        resMsg: '',
        isThirdParty : localStorage.getItem("third_party"),
    };

    handleModal = (modalVisible) => {
        this.props.handleFunc({ modalVisible: modalVisible });
        this.resetField();
    };

    handleOnChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
        this.validation(name, value);
    };

    validation(name, value) {
        const { errorIs, newPwd } = this.state;
        switch (name) {
            case 'currentPwd':
                errorIs.currentPwd = !value.length ? 'Current password is required' : '';
                break;
            case 'newPwd':
                errorIs.newPwd = !value.length ? 'New Password is required' : !(value.length > 7) ? "Password is too short (Must be at least 8 Character)" : '';
                break;
            case 'confirmNewPwd':
                errorIs.confirmNewPwd = !value.length
                    ? 'Confirm password is required'
                    : value !== newPwd
                    ? 'Password Not Matched'
                    : '';
                break;
            default:
                break;
        }
        return errorIs;
    }

    validateform() {
        const { errorIs, currentPwd, newPwd, confirmNewPwd } = this.state;
        errorIs.currentPwd = !currentPwd.length ? 'Current password is required' : '';
        errorIs.newPwd = !newPwd.length ? 'New Password is required' : !(newPwd.length > 7) ? "Password is too short (Must be at least 8 Character)" : '';
        errorIs.confirmNewPwd = !confirmNewPwd.length
            ? 'Confirm password is required'
            : confirmNewPwd !== newPwd
            ? 'Password Not Matched'
            : '';

        this.setState({ errorIs });

        return !errorIs.currentPwd && !errorIs.newPwd && !errorIs.confirmNewPwd;
    }

    resetField = () => {
        this.setState({
            currentPwd: '',
            newPwd: '',
            confirmNewPwd: '',
            errorIs: { currentPwd: '', newPwd: '', confirmNewPwd: '' },
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { currentPwd, newPwd } = this.state;
        const currentData = {
            oldPassword: currentPwd,
            newPassword: newPwd,
            socialLogin: false,
        };
        const { response } = this.props;
        const houseHoldId = response.houseHoldReducer.houseHold.id;
        if (this.validateform()) {
            this.setState({ LoadingIs: true });
            if (houseHoldId) {
                ChangePwd(houseHoldId, currentData).then((res) => {
                    if (res) {
                        this.setState({ LoadingIs: false, 
                            resMsg: res.message === "Old password is wrong." ? "Current password is wrong." : res.message
                        });
                        this.props.handleFunc({ modalVisible: false });
                        this.resetField();
                    }
                });
            }
        }
    };

    handleErrorModal = () => {
        this.setState({ resMsg: '' });
    };

    async componentDidMount() {
        if(this.state.isThirdParty == 'false') {
            await this.props.dispatch(getHouseHoldAction());
        }
    }

    render() {
        const { modalVisible } = this.props;
        const { currentPwd, newPwd, confirmNewPwd, errorIs, LoadingIs, resMsg } = this.state;

        const FieldArr = [
            {
                name: 'currentPwd',
                value: currentPwd,
                placeholder: 'Current Password',
            },
            {
                name: 'newPwd',
                value: newPwd,
                placeholder: 'New Passwod',
            },
            {
                name: 'confirmNewPwd',
                value: confirmNewPwd,
                placeholder: 'Confirm New Passwod',
            },
        ];

        return (
            <React.Fragment>
                {resMsg && (
                    <Modal
                        centered
                        visible={true}
                        backdrop="static"
                        maskClosable={false}
                        className="modal-container req-card "
                        onCancel={() => this.handleErrorModal(false)}
                    >
                        <div className="modal-body">
                            <h5>{`${resMsg}`}</h5>
                        </div>
                    </Modal>
                )}
                <Modal
                    centered
                    visible={modalVisible}
                    backdrop="static"
                    maskClosable={false}
                    className="modal-container req-card cp-popup"
                    onCancel={() => this.handleModal(false)}
                >
                    {LoadingIs && (
                        <LoadingOverlay
                            active={true}
                            className="d-block"
                            spinner
                            styles={{
                                spinner: (base) => ({
                                    ...base,
                                    width: '100px',
                                    '& svg circle': {
                                        stroke: 'rgb(56, 160, 235)',
                                    },
                                }),
                            }}
                        ></LoadingOverlay>
                    )}
                    <div className="modal-head">
                        <span>Change Password</span>
                    </div>
                    <Form onSubmit={(e) => this.handleSubmit(e)}>
                        {FieldArr.map((obj, index) => (
                            <div
                                className={`input-full ${
                                    (obj.name === 'currentPwd' && errorIs.currentPwd) ||
                                    (obj.name === 'newPwd' && errorIs.newPwd) ||
                                    (obj.name === 'confirmNewPwd' && errorIs.confirmNewPwd)
                                        ? 'err-msg'
                                        : ''
                                }`}
                                key={index}
                            >
                                <Input
                                    type="password"
                                    placeholder={obj.placeholder}
                                    name={obj.name}
                                    value={obj.value}
                                    onChange={this.handleOnChange}
                                />
                                {(obj.name === 'currentPwd' && errorIs.currentPwd) ||
                                (obj.name === 'newPwd' && errorIs.newPwd) ||
                                (obj.name === 'confirmNewPwd' && errorIs.confirmNewPwd) ? (
                                    <div className="form-err remo_position">
                                        {(obj.name === 'currentPwd' && errorIs.currentPwd) ||
                                            (obj.name === 'newPwd' && errorIs.newPwd) ||
                                            (obj.name === 'confirmNewPwd' && errorIs.confirmNewPwd)}
                                    </div>
                                ) : null}
                            </div>
                        ))}

                        <div className="full-btn">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="btn-modal"
                                onClick={(e) => this.handleSubmit(e)}
                            >
                                Update
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (response) => ({ response });
export default connect(mapStateToProps)(ChangePassword);
