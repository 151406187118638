import * as types from './index';

export const getHouseHoldAction = () => {
   
    return {
        type: types.GET_HOUSEHOLD,
    }
};

export const getThirdPartyAction = (id) => {
    return {
        type: types.GET_THIRD_PARTY,
        id,
    }
};

export const saveHouseHoldAction = (data) => {
    return {
        type: types.SAVE_HOUSEHOLD,
        data,
    };
};


