import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { connect } from 'react-redux';
import {
    updatePayment,
    deletePayment,
    updatePaymentStatus
} from 'services/paymentService';
import DeleteModal from 'pages/Payment/Payments/DeletePaymentModal';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import { addLoading, removeLoading } from 'actions/loaderActions';
import PreviewModal from 'pages/Payment/Payments/PreviewModal';
import { scroller, animateScroll } from 'react-scroll';
import ReactAutoScroll from 'react-to-target-auto-scroll';
import DeleteAttachmentModal from 'pages/Payment/Payments/DeleteAttachmentModal';
import { getThirdPartyAction } from 'actions/houseHoldAction';
import { getUserById } from 'services/userService';

const { Option } = Select;
const { TextArea } = Input;

const pay_methods = {
    Epayment: 'ACH',
    credit_card: 'Credit Card',
    cash: 'Cash',
    check: 'Check',
};

class SentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMethod: '',
            error: false,
            modalVisible: false,
            modal1Visible: false,
            modal2Visible: false,
            notes: '',
            loading: false,
            oneDayAgo: '',
            flag1: false,
            fileList: [],
            files: [],
            file: null,
            index: '',
            isSpecial: false,
            _payment: null,
            isThirdParty: false,
            coParent: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.getFormattedDate = this.getFormattedDate.bind(this);
        this.getChildrenNames = this.getChildrenNames.bind(this);
        this.handlePaymentReceived = this.handlePaymentReceived.bind(this);
        this.handlePaymentRejected = this.handlePaymentRejected.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleText = this.handleText.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.getBase64 = this.getBase64.bind(this);
        this.handleImageDelete = this.handleImageDelete.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.handleAccept = this.handleAccept.bind(this);
    }

    componentDidMount() {
        if (ReactDOM.findDOMNode(this.refs.myInput)) {
            ReactDOM.findDOMNode(this.refs.myInput).focus();
        }

        if (localStorage.third_party && localStorage.third_party === 'true') {
            this.props.dispatch(getThirdPartyAction({ id : localStorage.member_id }));
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.handleOpen !== prevProps.handleOpen) {
            this.setState({ paymentMethod: '', error: false });
        }
    }

    UNSAFE_componentWillReceiveProps = async function(nextProps) {
        if (nextProps && nextProps.currentPayment && nextProps.user) {
            let hours = moment().diff(moment(nextProps.currentPayment.created_at), 'hours');
            let result = hours >= 24 ? true : false;
            let files = [];
            if (nextProps.currentPayment.attachment && nextProps.currentPayment.attachment.length !== 0) {
                nextProps.currentPayment.attachment.forEach((item) => {
                    files.push(item.url);
                });
            }

            let normalUser = localStorage.third_party !== 'true';
            let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';
            
            let isSpecial = false;
            
            if (normalUser) {
                if (nextProps.currentPayment.status === 'on-hold') {
                    if (nextProps.user.id === nextProps.currentPayment.created_by) {
                        isSpecial = true;
                    }
                }
                await this.setState({ coParent: null });
            }
            
            if (thirdPartyUser) {
                getUserById(localStorage.coparent_id)
                    .then(async resp => {
                        if (resp) {
                            await this.setState({ coParent: resp });
                    }
                })    
            }

            this.setState({
                notes: nextProps.currentPayment.recurring ? nextProps.currentPayment.RecurringPaymentSchedule.notes : nextProps.currentPayment.notes,
                oneDayAgo: result,
                flag1: nextProps.currentPayment.attachment && nextProps.currentPayment.attachment.length === 3 ? true : false,
                fileList: nextProps.currentPayment.attachment || [],
                files: files,
                isSpecial,
                isThirdParty: thirdPartyUser
            });

            if (ReactDOM.findDOMNode(this.refs.myInput)) {
                ReactDOM.findDOMNode(this.refs.myInput).focus();
            }
        }
    }

    getFormattedDate = function (dateObj) {
        let month = dateObj.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = dateObj.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = dateObj.getFullYear();
        let output = month + '/' + day + '/' + parseInt(year.toString());
        return output;
    };

    getChildrenNames = function (child_ids) {
        let names = '';
        let normalUser = localStorage.third_party !== 'true';
        let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';
        const { children, thirdPartyData } = this.props;
        if (normalUser) {
            child_ids.forEach((child) => {
                for (let i = 0; i < children.length; i++) {
                    let c = children[i];
                    if (child == c.id) {
                        names = names + c.name;
                        names = names + ', ';
                    }
                }
            });
        } else {
            if (thirdPartyData) {
                thirdPartyData.kid.forEach(child => {
                    if (child_ids.includes(child.id)) {
                        names += child.name;
                        names += ', ';
                    }
                })
            }
        }
        names = names.trim();
        names = names.slice(0, names.length - 1);
        return names;
    };

    handleText = async function (e) {
        await this.setState({ notes: e.target.value });
    };

    handleChange = async function (value) {
        await this.setState({ paymentMethod: value, error: false });
    };

    handlePaymentReceived = function () {
        const { paymentMethod, notes, fileList } = this.state;
        const { currentPayment } = this.props;
        if (paymentMethod.length === 0) {
            this.setState({ error: true });
            this.focusNode('paymentMethod');
        } else {
            this.setState({ loading: true });
            let options = {};
            if (currentPayment) {
                options = {
                    notes: notes,
                    reply_note: currentPayment.reply_note,
                    full_amount: currentPayment.full_amount,
                    confirm_code: currentPayment.confirm_code,
                    status: 'paid',
                    payment_method: paymentMethod,
                    approved_by: currentPayment.approved_by,
                    approved_date: currentPayment.approved_date,
                    attachment: fileList,
                };
            }

            updatePayment(currentPayment.id, options).then((resp) => {
                this.setState({ loading: false });
                this.props.dispatch(addLoading());
                this.props.handleClose();
                this.props.refresh();
            });
        }
    };

    handlePaymentRejected = function () {
        this.setState({ loading: true });
        const { currentPayment } = this.props;
        const { notes, fileList } = this.state;
        const options = {
            notes: notes,
            reply_note: currentPayment.reply_note,
            full_amount: currentPayment.full_amount,
            confirm_code: currentPayment.confirm_code,
            status: 'declined',
            attachment: fileList,
        };

        updatePayment(currentPayment.id, options).then((resp) => {
            if (resp) {
                this.setState({ loading: false });
                this.props.dispatch(addLoading());
                this.props.handleClose();
                this.props.refresh();
            }
        });
    };

    handleDelete = function () {
        this.setState({ loading: true });
        const { currentPayment } = this.props;
        if (currentPayment) {
            deletePayment(currentPayment.id).then((resp) => {
                if (resp) {
                    this.setState({ modalVisible: false, loading: false });
                    this.props.dispatch(addLoading());
                    this.props.handleClose();
                    this.props.refresh();
                }
            });
        }
    };

    focusNode = (field) => {
        let node = ReactDOM.findDOMNode(this.refs[field]);
        if (node) {
            node.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    }

    scrollToBottom = function () {};

    setModalVisible = async function (modalVisible) {
        await this.setState({ modalVisible: modalVisible });
    };

    setModal1Visible = function (modalVisible, file) {
        this.setState({ modal1Visible: modalVisible, file: file });
    };

    setModal2Visible = function (modalVisible,index) {
        this.setState({ modal2Visible: modalVisible, index:index});
    };

    handleImageDelete = async function (index) {
        this.setState({index:index})
        const { files, fileList } = this.state;
        let arr = [...files];
        let farr = [...fileList];

        arr.splice(this.state.index, 1);
        farr.splice(this.state.index, 1);

        await this.setState({ files: arr, fileList: farr, flag1: false });
    };

    getBase64 = function (file) {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (upload) {
                resolve(upload.target.result);
            };
            reader.onerror = function (error) {
                
            };
        });
    };

    handleUpload = async function (event) {
        let flag = false;
        if (this.state.files.length === 2) {
            flag = true;
        }
        this.setState({
            files: [...this.state.files, URL.createObjectURL(event.target.files[0])],
            flag1: flag,
        });

        var reader = new FileReader();
        var file = event.target.files[0];
        var name = event.target.files[0].name;
        let url = URL.createObjectURL(file);
        let base64;

        base64 = await this.getBase64(file);

        let upload = {
            uri: url,
            name: name,
            base64: base64,
        };

        this.setState({ fileList: [...this.state.fileList, upload] });
    };

    handleAccept = function(){
        const { user, currentPayment } = this.props;
        this.setState({ loading: true });
        let data = {
            id: currentPayment.id,
            status: 'pending'
        };

        updatePaymentStatus(data)
            .then(resp => {
                this.setState({ loading: false });
                if (resp.success) {
                    this.props.handleClose();
                    this.props.refresh();
                }
        })
    }

    handleReject = () => {
        const { user, currentPayment } = this.props;
        const { _payment } = this.state;
        this.setState({ loading: true });
        let data = {
            id: currentPayment.id,
            status: 'Failed'
        };

        updatePaymentStatus(data)
            .then(resp => {
                this.setState({ loading: false });
                if (resp.success) {
                    this.props.handleClose();
                    this.props.refresh();
                }
        })
    }

    render() {
        const { user, currentPayment, thirdPartyData } = this.props;
        const {
            paymentMethod,
            loading,
            oneDayAgo,
            notes,
            flag1,
            fileList,
            files,
            isSpecial,
            isThirdParty,
            coParent
        } = this.state;

        return (
            <Modal
                title="Payments Request"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                className="payments-popup custom-popup pr-popup"
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <ScrollArea speed={0.8} horizontal={false}>
                    <Form>
                        <div className="form-scroll">
                            <div className="modal-top">
                                <Form.Item label="Co-parent" labelCol={{ span: 24 }}>
                                    <Input
                                        readOnly
                                        value={
                                            !isThirdParty? (
                                            currentPayment
                                                ? user && user.id === currentPayment.payer_id
                                                    ? currentPayment.receiver_username
                                                    : user && user.id === currentPayment.receiver_id
                                                    ? currentPayment.payer_username
                                                    : null
                                                    : null
                                            ) : (
                                                // currentPayment
                                                // ? coParent && coParent.id === currentPayment.payer_id
                                                //     ? currentPayment.receiver_username
                                                //     : coParent && coParent.id === currentPayment.receiver_id
                                                //     ? currentPayment.payer_username
                                                //     : null
                                                //     : null  
                                                localStorage.getItem('coparent_name')    
                                            )
                                        }
                                    />
                                </Form.Item>
                                <Form.Item label="Kids" labelCol={{ span: 24 }}>
                                    <Input
                                        readOnly
                                        value={currentPayment ? this.getChildrenNames(currentPayment.child_ids) : null}
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item className="pr-input" label="Category" labelCol={{ span: 24 }}>
                                <Input readOnly value={currentPayment ? currentPayment.PaymentCategory.name : null} />
                            </Form.Item>
                            {(isThirdParty) ? null : (
                            <Form.Item ref="paymentMethod">
                                    <Select
                                        value={paymentMethod.length === 0 ? 'Payment Method' : pay_methods[paymentMethod]}
                                        onChange={this.handleChange}
                                    >
                                        <Option value="Epayment">ACH</Option>
                                        <Option value="credit_card">Credit Card</Option>
                                        <Option value="cash">Cash</Option>
                                        <Option value="check">Check</Option>
                                    </Select>
                                {this.state.error ? <div className="field-err">This field is required</div> : null}
                            </Form.Item>
                            )}
                            <Form.Item label="Notes:" labelCol={{ span: 24 }}>
                                <TextArea onChange={this.handleText} value={notes} readOnly={isThirdParty ? true : false}/>
                            </Form.Item>
                            <Form.Item label="Message" labelCol={{ span: 24 }}>
                                <TextArea readOnly />
                            </Form.Item>
                            <Form.Item label="Amount" className="input-sm" labelCol={{ span: 24 }}>
                                <Input value={currentPayment ? currentPayment.payer_amount : null} />
                            </Form.Item>
                            <Form.Item className="input-sm input-code">
                                <Input placeholder="Confirmation Code/Check#" readOnly={isThirdParty ? true : false} />
                            </Form.Item>

                            {fileList.length !== 0 && (
                                <div style={{ marginBottom: flag1 ? '0px' : '20px' }} className="upload-file">
                                    <div className="modal-bottom">
                                        <span>Attachments</span>
                                        <div className="upload-wrapper">
                                            <input
                                                id="img"
                                                type={flag1 === false ? 'file' : 'button'}
                                                multiple={true}
                                                encType="multipart/form-data"
                                                onChange={this.handleUpload}
                                            />
                                            <Button type="primary" className="add-btn fp-button-primary">
                                                Add
                                            </Button>
                                        </div>
                                    </div>
                                    {Array.isArray(files) &&
                                        files.map((file, i) => (
                                            <div className="file-track" key={i}>
                                                <a onClick={() => this.setModal1Visible(true, file)}>View</a>
                                                <span  onClick={() =>this.setModal2Visible(true,i)}>Delete</span>
                                            </div>
                                        ))}
                                </div>
                            )}
                            {Array.isArray(files) && files.length === 3 ? (
                                <span className="file-err">You can only select 3 attachments</span>
                            ) : null}

                            {paymentMethod === 'Epayment' ? (
                                <div style={{ fontSize: '10px', marginBottom: '10px' }}>
                                    Payments made after 8:00PM Central Time will be processed the next business day.
                                </div>
                            ) : null}
                        </div>
                        <div className="btn-wrap">
                            {!isSpecial && !isThirdParty && (
                                <Form.Item className="btn-form">
                                    <Button
                                        type="primary"
                                        ref="myInput"
                                        id="special"
                                        onClick={this.handlePaymentReceived}
                                        className="btn fp-button-primary"
                                    >
                                        Payment Received
                                    </Button>
                                </Form.Item>
                            )}
                            {!isSpecial && !isThirdParty && (
                                <Form.Item className="btn-form">
                                    <Button
                                        type="primary"
                                        onClick={this.handlePaymentRejected}
                                        className="btn fp-button-secondary"
                                    >
                                        Payment Rejected
                                    </Button>
                                </Form.Item>
                            )}
                            {isSpecial && (
                                <Form.Item className="btn-form">
                                <Button
                                    type="primary"
                                    ref="myInput"
                                    id="special"
                                    onClick={this.handleAccept}
                                    className="btn fp-button-primary"
                                >
                                    Accept
                                </Button>
                                </Form.Item>
                            )}
                             {isSpecial && (
                                <Form.Item className="btn-form">
                                    <Button
                                        type="primary"
                                        onClick={this.handleReject}
                                        className="btn fp-button-secondary"
                                    >
                                        Reject
                                    </Button>
                                </Form.Item>
                            )}
                            {!oneDayAgo && currentPayment && user && user.id === currentPayment.created_by && !isSpecial && !isThirdParty ? (
                                <Form.Item className="btn-form">
                                    <Button
                                        type="primary"
                                        onClick={() => this.setModalVisible(true)}
                                        className="btn fp-button-red"
                                    >
                                        Cancel Request
                                    </Button>
                                </Form.Item>
                            ) : null}
                        </div>
                    </Form>
                </ScrollArea>
                <DeleteModal
                    openModal={this.state.modalVisible}
                    closeModal={() => this.setModalVisible(false)}
                    delete={() => this.handleDelete()}
                />
                <PreviewModal
                    file={this.state.file}
                    handleOpen={this.state.modal1Visible}
                    handleClose={() => this.setModal1Visible(false)}
                />
                <DeleteAttachmentModal
                    index={this.state.index}
                    openModal={this.state.modal2Visible}
                    closeModal={() => this.setModal2Visible(false)}
                    delete={() => this.handleImageDelete()}
                />
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer, houseHoldReducer }) => {
    const { user, error, children, coparent } = serviceReducer;
    const { thirdPartyData } = houseHoldReducer;
    return { user, error, children, coparent, thirdPartyData };
};

export default connect(mapStateToProps)(SentModal);
