import React, { Component } from 'react';
import 'pages/Reports/Reports.scss';
import { Row, Col } from 'antd';
import CustodySwaps from 'pages/Reports/CustodySwaps/CustodySwaps';
import PaymentTransaction from 'pages/Reports/PaymentTransaction/PaymentTransaction';
import ReconcileRecurring from 'pages/Reports/ReconcileRecurring/ReconcileRecurring';
import TimeRatio from 'pages/Reports/TimeRatio/TimeRatio';

class Reports extends Component {
    componentDidMount() {
        if (localStorage.third_party === 'true') {
           window.location.href = '/home';
        }    
    }

    render() {
        if (localStorage.third_party === 'true') {
            return null;
        } else {
            return (
                <Row className="row-container" gutter={'10'}>
                    <Col xs={24} lg={7} className="calender-wrap">
                        <CustodySwaps />
                    </Col>
                    <Col xs={24} lg={10} className="todo-wrap">
                        <PaymentTransaction />
                    </Col>
                    <Col xs={24} lg={7} className="col-wrap">
                        <ReconcileRecurring />
                        <TimeRatio />
                    </Col>
                </Row>
            );
        }
    }
}

export default Reports;
