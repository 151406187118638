import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, InputNumber, Radio, DatePicker, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { updateCategory } from 'services/categoryService';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

class SaveCategoryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            coParent: null,
            you: 50,
            _coparent: 50,
            category_name: '',
            percent_verified: true,
            errors: {
                category_name: '',
                percent_verified: '',
            },
            default_cat_id: '',
        };
        this.handleName = this.handleName.bind(this);
        this.handleCoparent = this.handleCoparent.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.handleOpen && nextProps.currentCategory && nextProps.coparents) {
            for (let i = 0; i < nextProps.coparents.length; i++) {
                let cpr = nextProps.coparents[i];
                if (
                    nextProps.currentCategory.coparent_user_id === cpr.id ||
                    nextProps.currentCategory.coparent_standalone_id === cpr.id
                ) {
                    this.setState({ coParent: cpr });
                }
            }

            let errors = {
                category_name: '',
                percent_verified: '',
            };

            let x = nextProps.currentCategory.You.slice(0, nextProps.currentCategory.You.length - 1);
            let y = nextProps.currentCategory.coParent.slice(0, nextProps.currentCategory.coParent.length - 1);

            this.setState({
                percent_verified: true,
                you: x,
                _coparent: y,
                category_name: nextProps.currentCategory.Category,
                errors: errors,
                default_cat_id: nextProps.currentCategory ? nextProps.currentCategory.default_cat_id : '',
            });
        }
    }

    handleCoparent = async function (value) {
        const { coparents } = this.props;
        let cp;
        coparents.forEach((coparent) => {
            if (coparent.id === value) {
                cp = coparent;
            }
        });

        await this.setState({ coParent: cp });
    };

    handleName = function (e) {
        if (e.target.value === '') {
            this.setState({
                category_name: e.target.value,
                errors: { ...this.state.errors, category_name: 'Field is required!' },
            });
        } else if(e.target.value.length > 50){
            this.setState({
                category_name: e.target.value,
                errors: { ...this.state.errors, category_name: 'Max length value exceeded(50 symbols)' },
            });
        } else {
            this.setState({ category_name: e.target.value, errors: { ...this.state.errors, category_name: '' } });
        }
    };

    handleCheck = async function () {
        let error = '';
        if (!this.state.percent_verified === false) {
            error = 'Please verify the % Agreed upon with Co-parent';
        }
        await this.setState({
            percent_verified: !this.state.percent_verified,
            errors: { ...this.state.errors, percent_verified: error },
        });
    };

    handleSubmit = async function () {
        const { coParent, category_name, default_cat_id, you, _coparent, percent_verified, errors } = this.state;
        const { currentCategory } = this.props;

        if (category_name === '') {
            await this.setState({ errors: { ...this.state.errors, category_name: 'Field is required!' } });
        } else if (percent_verified === false) {
            await this.setState({
                errors: { ...this.state.errors, percent_verified: 'Please verify the % Agreed upon with Co-parent' },
            });
        } else if(category_name.length > 50) {
            await this.setState({ errors: { ...this.state.errors, category_name: 'Max length value exceeded(50 symbols)' } });
        }  else if(!category_name.replace(/\s/g, '').length){
            await this.setState({ errors: { ...this.state.errors, category_name: 'Category name should not contain only white spaces' } });
        } else {
            this.setState({ loading: true });
            let options = {
                name: category_name,
                creator_amount_percent: you,
                coparent_amount_percent: _coparent,
                percent_verified: percent_verified,
                default_cat_id: default_cat_id,
                status: 0,
                category_status: 1,
                id: currentCategory.categoryId,
            };

            if(coParent.is_registered){
                options.coparent_user_id = coParent.id;
            } else {
                options.coparent_standalone_id = coParent.id;
            }

            updateCategory(options, currentCategory.categoryId).then((resp) => {
                this.setState({ loading: false });
                this.props.handleClose();
                this.props.refresh();
            }).catch(err => {
                this.setState({loading: false});
            })
        }
    };

    render() {
        const { coParent, loading, you, _coparent, category_name, percent_verified, errors } = this.state;
        const { coparents, coparent, currentCategory } = this.props;

        return (
            <Modal
                title="Edit Category"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                className="payments-popup edit-payment"
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={'loader-wrap pop-loader ' + (loading ? 'd-block' : '')}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Form>
                    <div className="edit-type">
                        <span>Co-parent</span>
                    </div>
                    <Form.Item className="cust-input">
                        <Input readOnly value={coParent && 'Shared with '+ coParent.name +' \'s household'} />
                    </Form.Item>
                    <Form.Item label="Category Name" className="input-sm" labelCol={{ span: 24 }}>
                        <Input value={category_name} onChange={this.handleName} />
                        {errors.category_name !== '' ? <div className="file-err">{errors.category_name}</div> : null}
                    </Form.Item>

                    <div className="input-number">
                        <Form.Item label="You" labelCol={{ span: 24 }}>
                            <InputNumber
                                value={you}
                                min={0}
                                max={100}
                                onChange={(val) => {
                                    if (val === '' || val === null) {
                                        val = 0;
                                        this.setState({ you: val, _coparent: 100 - val });
                                    }
                                    if (
                                        (val.toString().charCodeAt(0) >= 32 && val.toString().charCodeAt(0) <= 47) ||
                                        (val.toString().charCodeAt(0) >= 58 && val.toString().charCodeAt(0) <= 64) ||
                                        (val.toString().charCodeAt(0) >= 91 && val.toString().charCodeAt(0) <= 96) ||
                                        (val.toString().charCodeAt(0) >= 123 && val.toString().charCodeAt(0) <= 126)
                                    ) {
                                        val = 0;
                                        this.setState({ you: val, _coparent: 100 - val });
                                    } else if ((val - Math.floor(val)) !== 0){
                                        val = parseInt(val);
                                        this.setState({ you: val, _coparent: 100 - val }); 
                                    } else {
                                        this.setState({ you: val, _coparent: 100 - val });
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Co-parent" labelCol={{ span: 24 }}>
                            <InputNumber
                                value={_coparent}
                                min={0}
                                max={100}
                                onChange={(val) => {
                                    if (val === '' || val === null) {
                                        val = 0;
                                        this.setState({ _coparent: val, you: 100 - val });
                                    }
                                    if (
                                        (val.toString().charCodeAt(0) >= 32 && val.toString().charCodeAt(0) <= 47) ||
                                        (val.toString().charCodeAt(0) >= 58 && val.toString().charCodeAt(0) <= 64) ||
                                        (val.toString().charCodeAt(0) >= 91 && val.toString().charCodeAt(0) <= 96) ||
                                        (val.toString().charCodeAt(0) >= 123 && val.toString().charCodeAt(0) <= 126)
                                    ) {
                                        val = 0;
                                        this.setState({ _coparent: val, you: 100 - val });
                                    } else if ((val - Math.floor(val)) !== 0){
                                        val = parseInt(val);
                                        this.setState({ _coparent: val, you: 100 - val }); 
                                    } else {
                                        this.setState({ _coparent: val, you: 100 - val });
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>
                    <Form.Item className="cb-form">
                        <Checkbox checked={percent_verified} onChange={this.handleCheck}>
                            <span className="cb-text"> I verified % Agreed upon with Co-parent</span>
                        </Checkbox>
                        {errors.percent_verified !== '' ? (
                        <div style={{ color: 'red', fontSize: '12px' }}>{errors.percent_verified}</div>
                        ) : null}
                    </Form.Item>
                    <Form.Item className="btn-full">
                        <Button onClick={this.handleSubmit} className="fp-button-primary" type="primary">
                            <span>Save</span>
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparent, coparents } = serviceReducer;
    return { user, error, children, coparent, coparents };
};
export default connect(mapStateToProps)(SaveCategoryModal);
