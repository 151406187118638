import React, { Component } from 'react';
import 'pages/Household/Children/Children.scss';
import { Button, Modal, Form, Input, Avatar, Col, DatePicker, Select } from 'antd';
//import Calendar from 'react-calendar';
import Moment from 'moment';
import { getHouseHoldAction } from 'actions/houseHoldAction.js';
import userImg from 'Assets/defaultAvatar.png';
import add from 'Assets/add.svg';
import { getMeUserAction, getChildrenUserAction, getCheckListAction } from 'actions/userActions';
import { addChildren, editChildren, deleteChildren } from '../../../services/houseHoldService';
import { connect } from 'react-redux';
import { checkCookie } from 'utils/cookies';
import LoadingOverlay from 'react-loading-overlay';
import Calendar from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import 'react-calendar/dist/Calendar.css';
import { CalendarOutlined } from '@ant-design/icons';
import AddCoparent from './CoparentModal';
import { getCoParentsUserAction } from 'actions/userActions';
import { getSetupProgress } from 'services/userService';

let colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    gradient1: 'Gradient1',
    gradient2: 'Gradient2',
};

class AddChildren extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            dob: null,
            colorCode: '',
            coParent: '',
            gender: '',
            pic: '',
            picture: '',
            coid: '',
            cpname: '',
            is_registered: 0,
            relation: '',
            houseHold_id: '',
            coparentList: [],
            ownerNmae: '',
            LoadingIs: false,
            errorIs: { name: '', dob: '', colorCode: '', coParent: '', coparentColorCode: '' },
            responseError: '',
            start_date: new Date(),
            hod_id: '',
            coparentStandaloneId: '',
            showCoParentSelect: true,
            modalVisible: false,
            currentDate: new Date(),
            modalVisible: false,
            addCoparent: false,
            addedCoparent: false,
            newRegStatus: '',
            newId: '',
            isThirdParty: localStorage.getItem("third_party"),
            isCreator: true,
            coparentColorCode: ''
        };
        this.showAddCoparent = this.showAddCoparent.bind(this);
        this.setCoparent = this.setCoparent.bind(this);
        this.closeAddCoparent = this.closeAddCoparent.bind(this);
        this.handleModal = this.handleModal.bind(this);
    }

    handleOnChange = (e) => {
        const { name, value } = e.target;
        this.setState(
            {
                [name]: value,
            },
            () => {
            },
        );
        this.validation(name, value);

        if (name === 'coParent') {
            var index = e.target.selectedIndex;
            var optionElement = e.target.childNodes[index];
            var option = optionElement.getAttribute('data-id');
            var is_registered = optionElement.getAttribute('data-is_registered');
            var relation = optionElement.getAttribute('data-relation');
            var houseHold_id = optionElement.getAttribute('data-houseHold_id');
            this.setState({
                coid: option,
                is_registered: is_registered,
                relation: relation,
                houseHold_id: houseHold_id,
            });
            this.setState({ coparentStandaloneId: option });
        }
    };

    handleName = (e) => {
        if (e.target.value === '') {
            this.setState({
                name: e.target.value,
                errorIs: { ...this.state.errorIs, name: 'Name is required!' },
            });
        } else if (e.target.value.charAt(0) === ' ') {
            if (!e.target.value.replace(/\s/g, '').length) {
                this.setState({
                    name: e.target.value,
                    errorIs: { ...this.state.errorIs, name: 'Child name should not contain only white spaces' },
                });
            } else {
                this.setState({
                    name: e.target.value,
                    errorIs: { ...this.state.errorIs, name: 'Name should not start with white space character!' },
                });
            }
        } else if (e.target.value.length > 50) {
            this.setState({
                name: e.target.value,
                errorIs: { ...this.state.errorIs, name: 'Name length value exceeded! (Max 50 symbols)' },
            });
        }
        else {
            this.setState({ name: e.target.value, errorIs: { ...this.state.errorIs, name: '' } });
        }
    };

    handleCoparent = async (value) => {
        const { coparentList, hod_id } = this.state;
        this.setState({ coParent: value, addCoparent: false, LoadingIs: true });
        this.validation('coParentId', value);
        let obj = {};
        coparentList.forEach((cp) => {
            if (cp.id === value) {
                obj = cp;
            }
        });
        this.setState({
            coid: obj.id,
            cpname: obj.name,
            is_registered: obj.is_registered,
            relation: obj.relation,
            houseHold_id: hod_id,
        });
        if (obj.id == undefined) {
            this.setState({ coparentStandaloneId: value, coid: value });
        } else {
            this.setState({ coparentStandaloneId: obj.id });
        }
        this.setState({ LoadingIs: false })

    };

    handleColor = (value) => {
        const { isCreator } = this.state;
        if (!isCreator) {
            this.setState({ coparentColorCode: value });
            this.validation('coparentColorCode', value);
        } else {
            this.setState({ colorCode: value });
            this.validation('colorCode', value);
        }

    };

    handleGender = (value) => {
        this.setState({ gender: value });
        this.validation('Gender', value);
    };
    showAddCoparent() {
        this.setState({
            addCoparent: true
        });
    }

    closeAddCoparent() {
        this.setState({
            addCoparent: false
        });
    }

    async setCoparent(id, newRegStatus) {
        const { dataIs, editIs, deleteIs, response } = this.props;
        const { showCoParentSelect } = this.state;
        this.setState({ LoadingIs: true, addedCoparent: true, newRegStatus: newRegStatus, newId: id })
        //await this.props.dispatch(getCoParentsUserAction());
        const user = response.serviceReducer.user;
        if (id) {
            this.setState({ coparentStandaloneId: id })
        }



        if (!showCoParentSelect) {
            this.setState({ showCoParentSelect: true });
        }

        const houseHoldData = response.houseHoldReducer ? response.houseHoldReducer.houseHold : null;
        const userData = response.serviceReducer ? response.serviceReducer.user : null;
        const householdCoParents = response.serviceReducer ? response.serviceReducer.coparents : [];
        if (typeof houseHoldData === 'object' && !editIs && !deleteIs) {
            let results = [];
            let co_parents = response.serviceReducer.coparents;
            co_parents.forEach((cp) => {
                if (cp.is_registered === false) {
                    cp.isInvite = false;
                    results.push(cp);
                } else {
                    if (user && user.id === cp.sender_id) {
                        cp.isInvite = false;
                        results.push(cp);
                    } else if (user && user.id === cp.receiver_id) {
                        let validCoparent = this.isValidCoparent(cp);
                        if (validCoparent) {
                            cp.isInvite = false;
                            results.push(cp);
                        }
                    }
                }
            });
            await this.setState({
                coparentList: results,
                addCoparent: false,
                // LoadingIs: false,
            });
            if (id)
                this.handleCoparent(id);
        }

        if (dataIs && editIs && !deleteIs) {

            let showCoParentSelect = true;

            if (userData && dataIs.created_by_user !== userData.id) {
                showCoParentSelect = false;
            }

            let results = [];
            let co_parents = response.serviceReducer.coparents;
            await co_parents.forEach((cp) => {
                if (cp.is_registered === false) {
                    cp.isInvite = false;
                    results.push(cp);
                } else {
                    if (user && user.id === cp.sender_id) {
                        cp.isInvite = false;
                        results.push(cp);
                    } else if (user && user.id === cp.receiver_id) {
                        let validCoparent = this.isValidCoparent(cp);
                        if (validCoparent) {
                            cp.isInvite = false;
                            results.push(cp);
                        }
                    }
                }
            });
            await this.setState({
                coparentList: results,
                addCoparent: false,
            }, () => this.handleCoparent(id));
            // if (id)
            //  this.handleCoparent(id);
        }
    }

    setIndexState = (stateObj) => {
        // this.setState({stateObj});
    };

    refreshPage = () => {
        this.setCoparent()
    }

    DateOnChange = (date) => {
        if (date !== null) {
            let dateIs = Moment(date).format('YYYY-MM-DD');
            this.setState({
                dob: dateIs,
            });
        }
    };

    resetField = () => {
        this.setState({
            name: '',
            dob: '',
            colorCode: '',
            coParent: '',
            gender: '',
            pic: '',
            picture: '',
            imgRef: '',
            coid: '',
            cpname: '',
            addCoparent: false,
            addedCoparent: false,
            newRegStatus: '',
            newId: '',
            errorIs: { name: '', dob: '', colorCode: '', coParent: '', coparentColorCode: '' },
            isCreator: true,
            coparentColorCode: ''
        });
    };

    handleModal = async function (modalVisible) {
        this.props.handleFunc({ modalVisible: modalVisible, editIs: false, deleteIs: false });
        this.resetField();
    };

    formatDate = function (dateObj) {
        let date = new Date(dateObj);
        let month = date.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = date.getFullYear();
        let output = parseInt(year.toString()) + '-' + month + '-' + day;
        return output + 'T12:00:00';
    };

    handleUploadImage = (e) => {
        var self = this;
        var reader = new FileReader();
        var file = e.target.files[0];
        var name = e.target.files[0].name;
        self.setState({
            imgRef: URL.createObjectURL(file),
        });
        reader.onload = function (upload) {
            self.setState({
                picture: { base64: upload.target.result, name: name, uri: '' },
            });
        };
        reader.readAsDataURL(file);
    };

    getCoparentName = () => {
        const { coParent, coparentList } = this.state;
        let val = '';
        coparentList.forEach((cp) => {
            if (coParent === cp.id) {
                val = cp.name;
            }
        });
        return val;
    };

    setModal2Visible = async (visible) => {
        await this.setState({ modalVisible: visible });
    };

    validation = (name, value) => {
        const { errorIs } = this.state;
        switch (name) {
            case 'name':
                errorIs.name = !value.length
                    ? 'Name is required!'
                    : value.length > 50
                        ? 'Name length value exceeded! (Max 50 symbols)'
                        : '';
                break;
            case 'colorCode':
                errorIs.colorCode = !value.length ? 'Color is required!' : '';
                break;
            case 'coparentColorCode':
                errorIs.coparentColorCode = !value.length ? 'Color is required!' : '';
                break;
            case 'coParentId':
                errorIs.coParent = !value.length ? 'Coparent is required!' : '';
                break;
            case 'DOB':
                errorIs.dob = value == null || value == '' ? 'DOB is required!' : '';
                break;
            default:
                break;
        }

        return errorIs;
    };

    validateForm() {
        const { name, dob, colorCode, coParent, gender, errorIs, isCreator, coparentColorCode } = this.state;

        errorIs.name = !name.length
            ? 'Name is required!'
            : name.length > 50
                ? 'Name length value exceeded! (Max 50 symbols)'
                : '';
        if (name.charAt(0) === ' ') {
            errorIs.name = 'Name should not start with white space character!';
        }
        if (!name.replace(/\s/g, '').length) {
            errorIs.name = 'Child name should not contain only white spaces';
        }
        errorIs.colorCode = !colorCode.length ? 'Color is required!' : '';
        errorIs.coparentColorCode = !coparentColorCode.length ? 'Color is required!' : '';
        errorIs.coParent = !coParent.length ? 'Coparent is required!' : '';
        if (errorIs.dob) {
            errorIs.dob = errorIs.dob;
        } else {
            errorIs.dob = dob == null || dob == '' ? 'DOB is required!' : '';
        }
        this.setState({ errorIs });
        let flag = !errorIs.name && !errorIs.dob && !errorIs.colorCode && !errorIs.coParent && !errorIs.gender;
        if (!isCreator) {
            flag = !errorIs.name && !errorIs.dob && !errorIs.coparentColorCode && !errorIs.coParent && !errorIs.gender;
        }
        return flag;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {
            name,
            dob,
            colorCode,
            coparentColorCode,
            coParent,
            gender,
            start_date,
            picture,
            coid,
            relation,
            is_registered,
            houseHold_id,
            addedCoparent,
            newRegStatus,
        } = this.state;
        const { response } = this.props;
        const houseHoldData = response.houseHoldReducer.houseHold;
        if (this.validateForm()) {
            if (typeof houseHoldData === 'object') {
                // var householdId = houseHoldData.id;
                var userId = houseHoldData.user_id;
                const data = {
                    household_id: response && response.houseHoldReducer.houseHold.id,
                    type: 'child',
                    name: name,
                    color: colorCode,
                    gender: gender,
                    dob: dob === '' ? Moment.parseZone(start_date).format('YYYY-MM-DD') : Moment.parseZone(dob).format('YYYY-MM-DD'),
                    picture: picture ? picture : null,
                };


                if (is_registered == 1) {
                    data.coparent_name = this.getCoparentName();
                } else {
                    data.coparent_name = this.getCoparentName();
                }
                if (!addedCoparent) {
                    if (is_registered == 1) {
                        data.coparent_user_id = coid;
                    } else {
                        data.coparent_standalone_id = coid;
                    }
                }

                if (addedCoparent && newRegStatus) { // coparent is added from add children popup page
                    data.coparent_user_id = coid;
                } else if (addedCoparent && !newRegStatus) {
                    data.coparent_standalone_id = coid;
                }

                this.setState({ LoadingIs: true });
                addChildren(data).then((res) => {
                    if (res) {
                        this.setState({ LoadingIs: false, responseError: res.error });
                        this.props.handleFunc(!res.error ? { modalVisible: false } : { modalVisible: true });
                        let access_token = '';
                        if (checkCookie() !== null) {
                            access_token = checkCookie();
                        }
                        if (!res.error) {
                            this.props.dispatch(getMeUserAction({ token: access_token }));
                            this.props.dispatch(getHouseHoldAction());
                            if (localStorage.third_party !== 'true') {
                                this.props.dispatch(getCheckListAction());
                            }
                            this.props.dispatch(getChildrenUserAction());
                            this.resetField();
                        }
                    }
                });
            }
        }
    };

    handleUpdate = (e) => {
        e.preventDefault();
        const {
            name,
            dob,
            colorCode,
            coParent,
            gender,
            picture,
            coparentStandaloneId,
            showCoParentSelect,
            coid,
            addedCoparent,
            newRegStatus,
            isCreator,
            coparentColorCode
        } = this.state;
        const { dataIs, response } = this.props;

        const householdCoParents = response.serviceReducer ? response.serviceReducer.coparents : [];

        if (this.validateForm()) {
            if (dataIs) {
                const _id = dataIs.id ? dataIs.id : '';
                const data = {
                    household_id: dataIs.household_id ? dataIs.household_id : '',
                    type: 'child',
                    name: name,
                    color: colorCode,
                    gender: gender,
                    dob: Moment.parseZone(dob).format('YYYY-MM-DD'),
                    picture: picture ? picture : null,
                    //coparent_user_id: coparentStandaloneId ? coparentStandaloneId : null,
                    //coparent_name: coParent ? coParent : '',
                    id: _id,
                    created_by_user: dataIs.created_by_user ? dataIs.created_by_user : ''
                };

                if (!isCreator) {
                    data.coparent_color = coparentColorCode
                }

                if (coid !== '' && showCoParentSelect) {
                    const _coParent = householdCoParents.find((item) => item.id === coid);
                    if (_coParent && !addedCoparent) {
                        if (_coParent.is_registered) {
                            data.coparent_user_id = _coParent.id;
                        } else {
                            data.coparent_standalone_id = _coParent.id;
                        }
                        data.coparent_relation = _coParent.relation;
                        data.coparent_name = _coParent.name;
                    }
                    if (_coParent && addedCoparent) {
                        if (newRegStatus) { // coparent is added from add children popup page
                            data.coparent_user_id = coid;
                        } else if (!newRegStatus) {
                            data.coparent_standalone_id = coid;
                        }
                        data.coparent_relation = _coParent.relation;
                        data.coparent_name = _coParent.name;
                    }

                } else if (coid) {
                    const _coParent = householdCoParents.find((item) => item.id === coid);
                    if (_coParent) {
                        data.coparent_id = _coParent.id;
                    }
                }


                this.setState({ LoadingIs: true });

                editChildren(_id, data).then((res) => {
                    if (res) {
                        this.setState({ LoadingIs: false, responseError: res.error });
                        this.props.handleFunc(!res.error ? { modalVisible: false } : { modalVisible: true });
                        this.props.handleFunc(!res.error ? { editIs: false } : { editIs: true });
                        if (!res.error) {
                            this.props.dispatch(getHouseHoldAction());
                            this.props.dispatch(getChildrenUserAction());
                            this.resetField();
                        }
                    }
                });
            }
        }
    };

    handleDeleteChild = () => {
        const { dataIs } = this.props;
        let _id = dataIs.id ? dataIs.id : '';
        this.setState({ LoadingIs: true });
        deleteChildren(_id).then((res) => {
            if (res) {
                this.setState({ LoadingIs: false });
                this.props.dispatch(getHouseHoldAction());
                this.props.handleFunc({ modalVisible: false, editIs: false, deleteIs: false });
                if (localStorage.third_party !== 'true') {
                    this.props.dispatch(getCheckListAction());
                }
                // update checklist status
            }
        });
    };

    async componentDidMount() {
        if (this.state.isThirdParty == 'false')
            await this.props.dispatch(getHouseHoldAction());
    }

    componentWillReceiveProps(props) {
        const { dataIs, editIs, deleteIs, response } = props;
        const { showCoParentSelect, newId } = this.state;
        const user = response.serviceReducer.user;

        if (!showCoParentSelect) {
            this.setState({ showCoParentSelect: true });
        }

        const houseHoldData = response.houseHoldReducer ? response.houseHoldReducer.houseHold : null;
        const userData = response.serviceReducer ? response.serviceReducer.user : null;
        const householdCoParents = response.serviceReducer ? response.serviceReducer.coparents : [];
        if (typeof houseHoldData === 'object' && !editIs && !deleteIs) {
            let results = [];
            let co_parents = response.serviceReducer.coparents;
            co_parents.forEach((cp) => {
                if (cp.is_registered === false) {
                    cp.isInvite = false;
                    results.push(cp);
                } else {
                    if (user && user.id === cp.sender_id) {
                        cp.isInvite = false;
                        results.push(cp);
                    } else if (user && user.id === cp.receiver_id) {
                        let validCoparent = this.isValidCoparent(cp);
                        if (validCoparent) {
                            cp.isInvite = false;
                            results.push(cp);
                        }
                    }
                }
            });
            this.setState({
                coparentList: results,
                cpname: '',
                ownerNmae: houseHoldData.length > 0 ? houseHoldData.owner.first_name : '',
                hod_id: houseHoldData.length > 0 ? houseHoldData.id : '',
                currentDate: new Date(),
                start_date: new Date(),
                // dob: null,
            });
        }


        if (dataIs && editIs && !deleteIs) {
            let dobIs = dataIs.dob ? dataIs.dob.split('T') : new Date();
            let coparent = dataIs.Coparents.length > 0 ? dataIs.Coparents.map((item) => item.receiver_name) : '';

            const coParentId =
                userData.id === dataIs.created_by_user
                    ? dataIs.coparent_user_id || dataIs.coparent_standalone_id
                    : dataIs.created_by_user;
            let coParentData = '';

            householdCoParents.map((item) => {
                if (
                    item.id === coParentId ||
                    item.stand_alone_id === coParentId ||
                    (item.invitationsIds && item.invitationsIds.indexOf(coParentId) !== -1) ||
                    item.id === dataIs.created_by_user
                ) {
                    coParentData = item;
                }
            });

            let showCoParentSelect = true;

            if (userData && dataIs.created_by_user !== userData.id) {
                showCoParentSelect = false;
            }

            let results = [];
            let co_parents = response.serviceReducer.coparents;
            co_parents.forEach((cp) => {
                if (cp.is_registered === false) {
                    cp.isInvite = false;
                    results.push(cp);
                } else {
                    if (user && user.id === cp.sender_id) {
                        cp.isInvite = false;
                        results.push(cp);
                    } else if (user && user.id === cp.receiver_id) {
                        let validCoparent = this.isValidCoparent(cp);
                        if (validCoparent) {
                            cp.isInvite = false;
                            results.push(cp);
                        }
                    }
                }
            });


            this.setState({
                name: dataIs.name ? dataIs.name : '',
                dob: dobIs ? dobIs[0] : '',
                colorCode: dataIs.color ? dataIs.color : '',
                gender: dataIs.gender ? dataIs.gender : '',
                start_date: new Date(),
                currentDate: dobIs ? dobIs[0] : '',
                coparentList: results,
                coParent: coParentData ? coParentData.name : '',
                cpname: coParentData ? coParentData.name : '',
                pic: dataIs.file_url ? dataIs.file_url : '',
                picture: dataIs.picture ? dataIs.picture : '',
                imgRef: dataIs.file_url ? dataIs.file_url : '',
                coparentStandaloneId:
                    dataIs.coparent_user_id === null
                        ? dataIs.coparent_standalone_id
                        : dataIs.coparent_user_id == houseHoldData.user_id
                            ? dataIs.created_by_user
                            : dataIs.coparent_user_id,
                showCoParentSelect,
                coid: coParentData.id,
                isCreator: (dataIs.created_by_user === (user && user.id)),
                coparentColorCode: dataIs.coparent_color ? dataIs.coparent_color : '',
            });
            if (this.state.addedCoparent)
                this.handleCoparent(newId)

        }
    }

    handleErrorModal = () => {
        this.setState({ responseError: '' });
    };

    handleDate = (e) => {
        this.setState({ currentDate: e });
    };

    confirmDate = () => {
        const { currentDate } = this.state;
        this.setState({ dob: currentDate, modalVisible: false });
    };

    isValidCoparent = (cp) => {
        const { response } = this.props;
        const user = response.serviceReducer.user;
        const children = response.serviceReducer.children;
        let res = false;

        for (let i = 0; i < children.length; i++) {
            let child = children[i];
            if ((user && user.id === child.coparent_user_id) || (user && user.id === child.coparent_standalone_id)) {
                res = true;
            }
        }

        return res;
    };

    handleDOB = (e) => {
        if (e && e._d) {
            let err = '';
            if (Moment(new Date()) < Moment(e._d)) {
                err = 'Date can\'t be future date.';
            } else {
                err = '';
            }
            this.setState({ dob: Moment.parseZone(e._d).format('YYYY-MM-DD'), errorIs: { ...this.state.errorIs, dob: err }, });
        }
    }

    retrieveCoparentName = (cp_id) => {
        const { coparentList } = this.state;
        let name = '';
        coparentList.forEach(cp => {
            if (cp.id === cp_id) {
                name = cp.name;
            }
        })
        return name;
    }

    render() {
        const colorArr = [
            { colorName: 'Pink', colorCodeIs: '#c910e8' },
            { colorName: 'Blue', colorCodeIs: '#2196f3' },
            { colorName: 'Green', colorCodeIs: '#4caf50' },
            { colorName: 'Red', colorCodeIs: '#ff0000' },
            { colorName: 'Purple', colorCodeIs: '#673ab7' },
            { colorName: 'Orange', colorCodeIs: '#ffc107' },
            { colorName: 'Cyan', colorCodeIs: '#00bcd4' },
            { colorName: 'Black', colorCodeIs: '#000000' },
            { colorName: 'Brown', colorCodeIs: '#795548' },
            { colorName: 'White', colorCodeIs: '#ffffff' },
            { colorName: 'Grey', colorCodeIs: '#999999' },
            { colorName: 'Charcoal', colorCodeIs: '#333333' },
            { colorName: 'Gradient 1', colorCodeIs: 'gradient1' },
            { colorName: 'Gradient 2', colorCodeIs: 'gradient2' },
        ];

        const genderArr = [{ gender: 'Male', value: 'male' }, { gender: 'Female', value: 'female' }, { gender: 'Non Binary', value: 'nonbinary' }];

        const {
            name,
            dob,
            colorCode,
            coParent,
            cpname,
            gender,
            errorIs,
            coparentList,
            ownerNmae,
            responseError,
            start_date,
            hod_id,
            relation,
            showCoParentSelect,
            currentDate,
            coid,
            picture,
            isCreator,
            coparentColorCode
        } = this.state;

        let cp_name = this.retrieveCoparentName(coid);

        const { editIs, deleteIs, modalVisible } = this.props;

        const housedata = this.props.response.houseHoldReducer.houseHold;

        const { serviceReducer } = this.props.response;
        const { coparents } = serviceReducer;
        const { user } = serviceReducer;

        function getColor(item) {
            return (item.created_by_user === (user && user.id)) ? item.color : item.coparent_color;
        }

        return (
            <React.Fragment>
                {this.state.responseError && (
                    <Modal
                        centered
                        visible={responseError}
                        backdrop="static"
                        className="modal-container req-card"
                        onCancel={() => this.handleErrorModal(false)}
                    >
                        <div className="modal-body">
                            <h5>{`${responseError}`}</h5>
                        </div>
                    </Modal>
                )}
                <Modal
                    centered
                    visible={this.props.modalVisible}
                    backdrop="static"
                    maskClosable={false}
                    className={deleteIs ? 'add-child-popup del-pop' : modalVisible ? 'modal-container add-child-popup' : 'add-child-popup del-pop'}
                    onCancel={() => this.handleModal(false)}
                // onCancel={this.props.handleClose}
                >
                    {this.state.LoadingIs && (
                        <LoadingOverlay
                            active={true}
                            className="d-block pop-loader"
                            spinner
                            styles={{
                                spinner: (base) => ({
                                    ...base,
                                    width: '100px',
                                    '& svg circle': {
                                        stroke: 'rgb(56, 160, 235)',
                                    },
                                }),
                            }}
                        ></LoadingOverlay>
                    )}
                    <div className="modal-head">
                        <h5 className="del-head">{`${
                            editIs
                                ? `Edit Children`
                                : deleteIs
                                    ? `Do you want to delete this kid ? `
                                    : `Add Children`
                            }`}</h5>
                    </div>
                    {!deleteIs ? (
                        <Form onSubmit={editIs ? (e) => this.handleUpdate(e) : (e) => this.handleSubmit(e)}>
                            <div className="head-avatar">
                                <div className="img-upload position-r wid-box c-pointer">
                                    <Avatar
                                        src={
                                            this.state.imgRef
                                                ? this.state.imgRef
                                                : this.state.pic
                                                    ? this.state.pic
                                                    : userImg
                                        }
                                        className={this.state.imgRef ? "img-exist" : this.state.pic ? "img-exist" : "no-img"}
                                        alt="user-avatar"
                                    />
                                    <input
                                        type="file"
                                        name="picture"
                                        className="position-ab c-pointer"
                                        encType="multipart/form-data"
                                        onChange={this.handleUploadImage}
                                    />
                                    <div className="edit-icon">
                                        <svg version="1.1" id="Capa_1" viewBox="0 0 512 512">
                                            <polygon points="51.2,353.28 0,512 158.72,460.8" />
                                            <rect
                                                x="89.73"
                                                y="169.097"
                                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -95.8575 260.3719)"
                                                width="353.277"
                                                height="153.599"
                                            />
                                            <path
                                                d="M504.32,79.36L432.64,7.68c-10.24-10.24-25.6-10.24-35.84,0l-23.04,23.04l107.52,107.52l23.04-23.04
                                C514.56,104.96,514.56,89.6,504.32,79.36z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <Col className="col-wrap cust-selct">
                                <div className={`input-full ${errorIs.name ? 'err-msg' : ''}`}>
                                    <Input
                                        placeholder="Name/Nickname"
                                        name="name"
                                        value={name}
                                        onChange={this.handleName}
                                        autoComplete="off"
                                    />
                                    {errorIs.name ? <div className="form-err-child">{errorIs.name}</div> : null}
                                </div>
                            </Col>
                            <Col className="col-wrap cust-selct input-dob">
                                <div className={`input-full`}>
                                    {/* <Input
                                    onClick={() => this.setModal2Visible(true)}
                                    value={dob === '' ? this.formatDate(start_date) : this.formatDate(new Date(dob))}
                                    suffix={<CalendarOutlined onClick={() => this.setModal2Visible(true)} />}
                                    /> */}
                                    <DatePicker
                                        className="custom-dp"
                                        inputReadOnly="true"
                                        value={dob ? Moment(dob) : ''}
                                        placeholder="Date of Birth"
                                        size="large"
                                        onChange={this.handleDOB}
                                    />
                                    {errorIs.dob ? <div className="form-err-child">{errorIs.dob}</div> : null}
                                </div>
                            </Col>
                            <Modal
                                title="Calendar"
                                centered
                                className="cal-modal hh-dob-modal"
                                visible={this.state.modalVisible}
                                onOk={() => this.setModal2Visible(false)}
                                onCancel={() => this.setModal2Visible(false)}
                            >
                                <div className="dob-cal-wrap">
                                    <Calendar selected={currentDate} onChange={(e) => this.handleDate(e)} />
                                </div>
                                <div className="btn-wrap">
                                    <Button
                                        onClick={() => this.confirmDate()}
                                        className="fp-button-primary full-btn"
                                        type="primary"
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            </Modal>
                            <div className="select-wrap">
                                <Col span={12} className="col-wrap cust-selct">
                                    <div className={`box-slt-box pos-relative select-dot-kid`}>
                                        <Select
                                            name={isCreator ? colorCode : coparentColorCode}
                                            value={isCreator ? colorCode : coparentColorCode}
                                            onChange={this.handleColor}
                                            className={`slt-2-box`}
                                        >
                                            <option value="">Dot Color</option>
                                            {colorArr.map((obj, index) => (
                                                <option
                                                    style={{
                                                        display: 'inline-block',
                                                        color: isCreator ? (obj.colorCodeIs === colorCode ? '#2dadcc' : '#333') : (obj.colorCodeIs === coparentColorCode ? '#2dadcc' : '#333'),
                                                    }}
                                                    selected={isCreator ? colorCode === obj.colorCodeIs : coparentColorCode === obj.colorCodeIs}
                                                    value={obj.colorCodeIs}
                                                    key={index}
                                                >
                                                    <span className={'dot ' + colors[obj.colorCodeIs]}></span>
                                                    {obj.colorName}
                                                </option>
                                            ))}
                                        </Select>
                                        {errorIs.colorCode ? (
                                            <div className="form-err-child">{errorIs.colorCode}</div>
                                        ) : null}
                                        {!isCreator && errorIs.coparentColorCode ? (
                                            <div className="form-err-child">{errorIs.coparentColorCode}</div>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col span={12} className="col-wrap cust-selct ">
                                    <div className="box-slt-box pos-relative">
                                        <Select
                                            name="gender"
                                            value={gender}
                                            onChange={this.handleGender}
                                            className="slt-2-box"
                                        >
                                            <option value="">Gender</option>
                                            {genderArr.map((obj, index) => (
                                                <option
                                                    style={{ color: obj.gender === gender ? '#2dadcc' : '#333' }}
                                                    value={obj.value}
                                                    key={index}
                                                >
                                                    {obj.gender}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                </Col>
                            </div>
                            <div className="input-full">
                                <Col className="col-wrap cust-selct select-icon">
                                    <div className="box-slt-box pos-relative">
                                        <Select
                                            name="coParent"
                                            value={coParent === '' ? 'Co-parent' : cp_name}
                                            onChange={this.handleCoparent}
                                            className="slt-2-box"
                                            disabled={!showCoParentSelect}
                                        >
                                            <option value="">Co-parent</option>
                                            {coparentList.length > 0 &&
                                                coparentList.map((obj, index) => (
                                                    obj.name !== 'Household' && (
                                                        <option
                                                            className={
                                                                coid && coid === obj.id ? 'text-color' : ''
                                                            }
                                                            selected={this.state.coparentStandaloneId === obj.id}
                                                            value={
                                                                typeof obj.Users !== 'undefined' && obj.Users.length > 0
                                                                    ? obj.Users[0].id
                                                                    : obj.id
                                                            }
                                                            data-id={
                                                                typeof obj.Users !== 'undefined' && obj.Users.length > 0
                                                                    ? obj.Users[0].id
                                                                    : obj.id
                                                            }
                                                            data-is_registered={obj.is_registered === true ? 1 : 0}
                                                            data-relation={obj.relation}
                                                            data-houseHold_id={
                                                                housedata.id !== undefined ? housedata.id : ''
                                                            }
                                                            key={index}
                                                        >
                                                            {obj.name}
                                                        </option>
                                                    )
                                                ))}
                                        </Select>
                                        {errorIs.coParent ? (
                                            <div className="form-err-child">{errorIs.coParent}</div>
                                        ) : null}
                                    </div>
                                    {Boolean(showCoParentSelect) && (<div className="add-icon">
                                        <img src={add} alt="add-icon" onClick={this.showAddCoparent} />
                                    </div>)}
                                </Col>
                            </div>
                            <div className="full-btn">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="btn-modal"
                                    onClick={editIs ? (e) => this.handleUpdate(e) : (e) => this.handleSubmit(e)}
                                >
                                    {editIs ? `Update` : `Add Child to Household`}
                                </Button>
                            </div>
                        </Form>
                    ) : (
                            <Form>
                                <div className="modal-bottom">
                                    <Button type="warning" className="ant-btn" onClick={() => this.handleModal(false)}>
                                        Cancel
                                </Button>
                                    <Button type="primary" className="btn-add" onClick={() => this.handleDeleteChild()}>
                                        OK
                                </Button>
                                </div>
                            </Form>
                        )}

                    <AddCoparent  {...this.state} handleFunc={this.setIndexState} refreshCoparent={this.setCoparent} handleSimpleClose={this.closeAddCoparent} />
                </Modal>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (response) => ({ response });
export default connect(mapStateToProps)(AddChildren);
