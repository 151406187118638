import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import 'antd/dist/antd.css';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { 
    cancelTransaction, 
    getTransactionStatus,
    getPaymentById 
} from 'services/paymentService';
import { addLoading, removeLoading } from 'actions/loaderActions';
import PreviewModal from 'pages/Payment/Payments/PreviewModal';
import DeleteAttachmentModal from 'pages/Payment/Payments/DeleteAttachmentModal';
import SwapErrorModal from 'pages/CalenderInner/Swap/SwapErrorModal';
import { getThirdPartyAction } from 'actions/houseHoldAction';
import { getUserById } from 'services/userService';

const { Option } = Select;
const { TextArea } = Input;

class ACHModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fileList: [],
            files: [],
            file: null,
            flag1: false,
            modalVisible: false,
            reply_note: '',
            modal1Visible: false,
            modal2Visible: false,
            index:'',
            paymentStatus: '',
            msg: '',
            bundle_flag: false,
            currentPayment: null,
            isThirdParty: false,
            coParent: null
        };
        this.getFormattedDate = this.getFormattedDate.bind(this);
        this.getChildrenNames = this.getChildrenNames.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.getBase64 = this.getBase64.bind(this);
        this.handleImageDelete = this.handleImageDelete.bind(this);
        this.getFinalAmount = this.getFinalAmount.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.getStatus = this.getStatus.bind(this);
    }

    componentDidMount() {
        if (localStorage.third_party && localStorage.third_party === 'true') {
            this.props.dispatch(getThirdPartyAction({ id : localStorage.member_id }));
        }
    }

    UNSAFE_componentWillReceiveProps = async function(nextProps) {
        if (nextProps && nextProps.handleOpen && nextProps.currentPayment) {
            let files = [];
    
            this.setState({ loading: true });
            getPaymentById(nextProps.currentPayment.id).then(async resp => {
                if (resp.attachment && resp.attachment.length !== 0) {
                    resp.attachment.forEach((item) => {
                        files.push(item.url);
                    });
                }
                this.getStatus(resp.id);
    
                let normalUser = localStorage.third_party !== 'true';
                let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';
    
                if (normalUser) {
                    await this.setState({ coParent: null });
                }
    
                if (thirdPartyUser) {
                    getUserById(localStorage.coparent_id)
                        .then(async resp => {
                            if (resp) {
                                await this.setState({ coParent: resp });
                        }
                    })    
                }

                this.setState({
                    files: files,
                    reply_note: resp.reply_note,
                    fileList: resp.attachment || [],
                    flag1: false,
                    index: '',
                    loading: false,
                    currentPayment: resp,
                    bundle_flag: resp.bundle_payment_flag,
                    isThirdParty: thirdPartyUser
                });
            })

           
        
        }
    }


    getStatus = async function(paymentId){
        getTransactionStatus({paymentId}).then(async (statusResponse) => {
            if (statusResponse && statusResponse.JhData) {
              let update = {
                jhData: statusResponse.JhData,
              };
              let debit = statusResponse.JhData.filter(
                (item) => item.transaction_type == 'debit',
              );
        
              if (
                debit &&
                debit.length > 0 &&
                debit[0].transaction_status == 'Approved'
              ) {
                update.paymentStatus = 'Pending';
              }
              await this.setState({paymentStatus: update.paymentStatus});
            }
          });
    }

    getFormattedDate = function (dateObj) {
        let month = dateObj.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = dateObj.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = dateObj.getFullYear();
        let output = month + '/' + day + '/' + parseInt(year.toString());
        return output;
    };

    getChildrenNames = function (child_ids) {
        let names = '';
        let normalUser = localStorage.third_party !== 'true';
        let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';
        const { children, thirdPartyData } = this.props;
        if (normalUser) {
            child_ids.forEach((child) => {
                for (let i = 0; i < children.length; i++) {
                    let c = children[i];
                    if (child == c.id) {
                        names = names + c.name;
                        names = names + ', ';
                    }
                }
            });
        } else {
            thirdPartyData.kid.forEach(child => {
                if (child_ids.includes(child.id)) {
                    names += child.name;
                    names += ', ';
                }
            })
        }
        names = names.trim();
        names = names.slice(0, names.length - 1);
        return names;
    };

    handleCancel = function () {
        this.setState({ loading: true });
        const { currentPayment } = this.state;
        if (currentPayment) {
            let options = {
                paymentId: currentPayment.id,
            };
            
            cancelTransaction(options).then((resp) => {
                this.setState({ loading: false });
                if(resp.error){
                    this.setState({ modal2Visible: true, msg: resp.error});
                }else {
                    this.props.handleClose();
                    this.props.refresh();
                    this.props.dispatch(addLoading());
                }
            });
        }
    };

    setModalVisible = function (modalVisible, file) {
        this.setState({ modalVisible: modalVisible, file: file });
    };

    setModal1Visible = function (modalVisible,index) {
        this.setState({ modal1Visible: modalVisible, index:index});
    };

    handleImageDelete = async function (index) {

        await this.setState({index:index})
        const { files, fileList } = this.state;
        let arr = [...files];
        let farr = [...fileList];
 
        arr.splice(this.state.index, 1);
        farr.splice(this.state.index, 1);
        await this.setState({ files: arr, fileList: farr });
    };

    getBase64 = function (file) {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (upload) {
                resolve(upload.target.result);
            };
            reader.onerror = function (error) {
            };
        });
    };

    handleUpload = async function (event) {
        let flag = false;
        if (this.state.files.length === 2) {
            flag = true;
        }
        this.setState({
            files: [...this.state.files, URL.createObjectURL(event.target.files[0])],
            flag1: flag,
        });

        var reader = new FileReader();
        var file = event.target.files[0];
        var name = event.target.files[0].name;
        let url = URL.createObjectURL(file);
        let base64;

        base64 = await this.getBase64(file);

        let upload = {
            uri: url,
            name: name,
            base64: base64,
        };

        this.setState({ fileList: [...this.state.fileList, upload] });
    };

    getFinalAmount = function(payment){
        const {user} = this.props;
        let isMePayer = payment && user ? user.id === payment.payer_id : false;
        if (payment && payment.recurring == false) {
            if (payment.asking_reimbursing == false && isMePayer == true) {
                isMePayer = false;
            } else if (payment.asking_reimbursing == false && isMePayer == false) {
                isMePayer = true;
            }
        }
        let amount =
        payment.payer_amount == '0.00'
        ? parseFloat(payment.full_amount || 0)
        : parseFloat(payment.payer_amount || 0);

        if (!payment.recurring && payment.asking_reimbursing == false) {
            amount =
              payment.sender_amount == '0.00'
                ? parseFloat(payment.full_amount || 0)
                : parseFloat(payment.payer_amount || 0);
        }

        return amount;

    }

    setModal2Visible = async function (visible, msg) {
        await this.setState({ modal2Visible: visible, msg: msg });
        this.props.handleClose();
        this.props.refresh();
        this.props.dispatch(addLoading());
    };

    render() {
        const { user } = this.props;
        const { 
            loading, 
            fileList, 
            files, 
            flag1,
            reply_note,
            paymentStatus,
            currentPayment,
            isThirdParty,
            coParent
        } = this.state;

        let transferAmount = '0.00';
        if (currentPayment) {
          transferAmount = this.getFinalAmount(currentPayment);
          transferAmount = transferAmount.toFixed(2);
        }
        const isPending = currentPayment ? currentPayment.status === 'pending' : true;
        let isMePayer = currentPayment && user ? user.id === currentPayment.payer_id : false;
        let isMeReceiver = currentPayment && user ? user.id === currentPayment.receiver_id : false;
        let Notetitle,ReplyTitle,noteEditable,msgEditable,NoteValue,ReplyValue;
        let notes = currentPayment && currentPayment.notes;
        let replyNote = currentPayment && currentPayment.reply_note;
        if (isMePayer == false) {
            Notetitle = 'Reply';
            NoteValue = replyNote
            ReplyValue = notes
            ReplyTitle = 'Note'

        } else if (isMePayer == true) {
            Notetitle = 'Note';
            NoteValue = notes
            ReplyValue = replyNote
            ReplyTitle = 'Reply'
        } 
        
        if (isMePayer == true && currentPayment && currentPayment.asking_reimbursing == false) {
            noteEditable = true
            msgEditable = false
            Notetitle = 'Note';
            ReplyTitle = 'Message'
        } else if (isMePayer == true && currentPayment && currentPayment.asking_reimbursing == true) {
            noteEditable = false
            msgEditable = true
            Notetitle = 'Message';
            ReplyTitle = 'Note'
        } else if (isMePayer == false && currentPayment && currentPayment.asking_reimbursing == true) {
            noteEditable = true
            msgEditable = false
            Notetitle = 'Note';
            ReplyTitle = 'Message'
        } else if (isMePayer == false && currentPayment && currentPayment.asking_reimbursing == false) {
            noteEditable = false
            msgEditable = true
            Notetitle = 'Message';
            ReplyTitle = 'Note'
        }

        let normalUser = localStorage.third_party !== 'true';
        let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';

        return (
            <Modal
                title="Payments Request"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                className="payments-popup custom-popup cancel-popup"
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Form>
                    <div className="form-scroll">
                        <div className="modal-top">
                            <Form.Item label="Co-parent" labelCol={{ span: 24 }}>
                                <Input
                                    readOnly
                                    value={
                                        normalUser ? (
                                        currentPayment
                                            ? user && user.id === currentPayment.payer_id
                                                ? currentPayment.receiver_username
                                                : user && user.id === currentPayment.receiver_id
                                                ? currentPayment.payer_username
                                                : null
                                                : null
                                        ) : (
                                            // currentPayment
                                            // ? coParent && coParent.id === currentPayment.payer_id
                                            //     ? currentPayment.receiver_username
                                            //     : coParent && coParent.id === currentPayment.receiver_id
                                            //     ? currentPayment.payer_username
                                            //     : null
                                            //     : null  
                                            localStorage.getItem('coparent_name')    
                                        )
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Kids" labelCol={{ span: 24 }}>
                                <Input
                                    readOnly
                                    value={currentPayment ? this.getChildrenNames(currentPayment.child_ids) : null}
                                />
                            </Form.Item>
                        </div>
                        <div className="modal-top">
                            <Form.Item label="Category" labelCol={{ span: 24 }}>
                                <Input readOnly value={currentPayment ? currentPayment.PaymentCategory && currentPayment.PaymentCategory.name : null} />
                            </Form.Item>
                            <Form.Item label="Payment Method" labelCol={{ span: 24 }}>
                                <Input readOnly value={currentPayment ? 'ACH' : null} />
                            </Form.Item>
                        </div>
                        <Form.Item label={Notetitle} labelCol={{ span: 24 }}>
                            <TextArea readOnly value={currentPayment ? currentPayment.notes : null} />
                        </Form.Item>
                        <Form.Item label={ReplyTitle} labelCol={{ span: 24 }}>
                            <TextArea readOnly value={currentPayment ? currentPayment.reply_note : null} />
                        </Form.Item>

                        {fileList.length !== 0 && (
                            <div style={{ marginBottom: flag1 ? '0px' : '20px' }} className="upload-file">
                                <div className="modal-bottom">
                                    <span>Attachments</span>
                                    <div className="upload-wrapper">
                                        <input
                                            id="img"
                                            type={flag1 === false ? 'file' : 'button'}
                                            multiple={true}
                                            encType="multipart/form-data"
                                            onChange={this.handleUpload}
                                        />
                                    </div>
                                </div>
                                {Array.isArray(files) &&
                                    files.map((file, i) => (
                                        <div className="file-track" key={i}>
                                            <a onClick={() => this.setModalVisible(true, file)}>View</a>
                                            <span onClick={() =>this.setModal1Visible(true,i)}>Delete</span>
                                        </div>
                                    ))}
                            </div>
                        )}
                        {/* {Array.isArray(files) && files.length === 3 ? (
                            <span className={files.length === 3 ? 'file-err' : ''}>
                                You can only select 3 attachments
                            </span>
                        ) : null} */}

                        <div className="trans-details">
                            <div className="send">

                                {currentPayment && currentPayment.payment_method == 'Epayment' && currentPayment.status !== 'pending' && (
                                <div className="send-name">
                                    <span>Sender: {currentPayment ? currentPayment.payer_username : null}</span>
                                </div>
                                )}

                                <div>
                                {(Boolean(isMePayer) || Boolean(isMeReceiver)) && currentPayment.status != 'disputed' && currentPayment.status != 'declined' && (
                                    <div className="send-amount">
                                        <span>Transfer Amount</span>
                                        <span>{currentPayment ? '$' + currentPayment.payer_amount : null}</span>
                                    </div>
                                )}    
                                </div>    

                                <div>
                               {(Boolean(isMePayer) || Boolean(isMeReceiver)) && currentPayment.status != 'disputed' && currentPayment.status != 'declined' && (
                                <div style={{ paddingBottom: '15px', borderBottom: '1px solid #dddddd' }} className="send-fee">
                                    <span>{this.state.bundle_flag ? 'Bundle' : 'Transfer'} Fees</span>
                                    <span>
                                        {currentPayment
                                            ? currentPayment.fee
                                                ? '$' + currentPayment.fee
                                                : '$0.00'
                                            : null}
                                    </span>
                                </div>
                                )}
                                </div>

                            </div>
                            <div className="reciepient">

                               {currentPayment && currentPayment.payment_method == 'Epayment' && currentPayment.status !== 'pending' && (
                                <div className="reciepient-name">
                                    <span>Recipient: {currentPayment ? currentPayment.receiver_username : null}</span>
                                </div>
                               )}

                               {currentPayment && currentPayment.payment_method == 'Epayment' && currentPayment.status !== 'pending' && (
                               <div style={{marginTop: '20px'}} className="reciepient-date">
                                    <span>Payment Sent</span>
                                    <span>
                                        {currentPayment
                                            ? this.getFormattedDate(new Date(currentPayment.approved_date))
                                            : null}
                                    </span>
                                </div>
                                )}
                            </div>
                            {currentPayment && currentPayment.payment_method == 'Epayment' && currentPayment.status !== 'pending' && (
                            <div className="total">
                                <span>Total to Recipient:</span>
                                <span>{'$ ' + transferAmount}</span>
                            </div>
                            )}

                        </div>
                        <div>
                        {currentPayment && Boolean(!isMePayer) &&
                        !isPending &&
                        currentPayment.payment_method == 'Epayment' &&
                        currentPayment.status === 'inProgress' && (
                            <div className="trans-notes">
                            <span>Request made after 5:00pm will be sent the next business day</span>
                            </div>
                        )}

                        {currentPayment && Boolean(!isMePayer) &&
                        !isPending &&
                        currentPayment.payment_method == 'Epayment' &&
                        currentPayment.status === 'inProgress' && (
                            <div className="trans-notes">
                            <span>Transactions can take up to 5 business days to process.</span>
                            </div>
                        )}

                        {currentPayment && Boolean(isMePayer) &&
                        currentPayment.payment_method == 'Epayment' &&
                        currentPayment.status === 'inProgress' &&
                        currentPayment.sale_status == 'inProgress' && (
                            <div className="trans-notes">
                            <span>
                                Requests made after 5:00pm will be sent the next business
                                day.
                            </span>
                            </div>
                        )}   

                        {currentPayment && Boolean(isMePayer) &&
                        currentPayment.payment_method == 'Epayment' &&
                        currentPayment.status === 'inProgress' &&
                        currentPayment.sale_status == 'inProgress' && (
                            <div className="trans-notes">
                            <span>
                            Transactions can take up to 5 business days to process.
                            </span>
                            </div>
                        )}

                        {currentPayment && user && !isPending &&
                        paymentStatus === 'Pending' &&
                        currentPayment.approved_by == user.id && (
                            <div className="trans-notes">
                            <span>
                            You will have upto 12:00 AM EST to cancel the transaction.
                            </span>
                            </div>
                        )}
                        </div>
                    </div>
                    <div className="btn-wrap">
                        
                        {currentPayment && user && !isPending &&
                        paymentStatus === 'Pending' &&
                        currentPayment.approved_by == user.id && !isThirdParty && (
                            <Form.Item className="btn-form">
                                <Button type="primary" onClick={this.handleCancel} className="btn btn-red">
                                    Cancel Payment
                                </Button>
                            </Form.Item>
                        )}
                    </div>
                </Form>
                <PreviewModal
                    file={this.state.file}
                    handleOpen={this.state.modalVisible}
                    handleClose={() => this.setModalVisible(false)}
                />
                <DeleteAttachmentModal
                    index={this.state.index}
                    openModal={this.state.modal1Visible}
                    closeModal={() => this.setModal1Visible(false)}
                    delete={() => this.handleImageDelete()}
                />
                <SwapErrorModal
                    msg={this.state.msg}
                    openModal={this.state.modal2Visible}
                    closeModal={() => this.setModal2Visible(false, '')}
                />
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer, houseHoldReducer }) => {
    const { user, error, children, coparent } = serviceReducer;
    const { thirdPartyData } = houseHoldReducer;
    return { user, error, children, coparent, thirdPartyData };
};

export default connect(mapStateToProps)(ACHModal);
