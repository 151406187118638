import React, { Component } from 'react';
import 'pages/CalenderInner/Swap/Swap.scss';
import { Card, Button, Modal, Form, Input, Select, Avatar, Empty } from 'antd';
import right from 'Assets/right-arrow.png';
import TextArea from 'antd/lib/input/TextArea';
import { connect } from 'react-redux';
import { CalendarOutlined } from '@ant-design/icons';
import Calendar from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import { setupScheduleException } from 'services/calendarService';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import { checkCookie } from 'utils/cookies';
import { getCoParentsUserAction, getChildrenUserAction } from 'actions/userActions';
import { addLoading, removeLoading } from 'actions/loaderActions';

let frequencies = [
    {
        label: 'Yearly',
        value: 'yearly',
    },
    {
        label: 'Weekly',
        value: 'weekly',
    },

    {
        label: 'Monthly',
        value: 'monthly',
    },
    {
        label: '6 Months',
        value: 'six_months',
    },
    {
        label: 'One Time',
        value: 'onetime',
    },
];

let exceptionTypes = [
    {
        label: 'Alternate custody, I get first custody',
        value: 1,
    },
    {
        label: 'Alternate custody, co-parent gets first custody',
        value: 2,
    },
    {
        label: 'I will have custody on this date',
        value: 3,
    },
    {
        label: "I won't have custody on this date",
        value: 4,
    },
];

let specialTypes = [
    {
        label: 'I will have custody on this date',
        value: 3,
    },
    {
        label: "I won't have custody on this date",
        value: 4,
    },
];

const colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    'gradient1': 'Gradient1',
    'gradient2': 'Gradient2',
};

class CreateException extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            currentDate: new Date(),
            start_date: new Date(),
            title: '',
            child_ids: [],
            frequency: '',
            custody: '',
            errors: {
                title: '',
                kids: '',
                frequency: '',
                custody: '',
            },
            loading: false,
        };
        this.handleTitle = this.handleTitle.bind(this);
        this.handleChildren = this.handleChildren.bind(this);
        this.handleFrequency = this.handleFrequency.bind(this);
        this.handleCustody = this.handleCustody.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.getChildrenNames = this.getChildrenNames.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.confirmDate = this.confirmDate.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChilds = this.handleChilds.bind(this);
    }

    componentDidMount() {
        if(checkCookie() !== null){
            this.props.dispatch(getChildrenUserAction()); 
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.handleOpen) {
            let err = {
                title: '',
                kids: '',
                frequency: '',
                custody: '',
            };

            this.setState({
                currentDate: new Date(),
                start_date: new Date(),
                title: '',
                child_ids: [],
                frequency: '',
                custody: '',
                errors: err,
            });
        }
    }

    getChildrenNames = function (child_ids) {
        let names = '';
        const { children } = this.props;
        child_ids.forEach((child) => {
            for (let i = 0; i < children.length; i++) {
                let c = children[i];
                if (child == c.id) {
                    names = names + c.name;
                    names = names + ', ';
                }
            }
        });
        names = names.trim();
        names = names.slice(0, names.length - 1);
        return names;
    };

    handleTitle = function (e) {
        if (e.target.value === '') {
            this.setState({ title: e.target.value, errors: { ...this.state.errors, title: 'Field is required!' } });
        } else {
            this.setState({ title: e.target.value, errors: { ...this.state.errors, title: '' } });
        }
    };

    handleFrequency = async function (value) {
        if(value === 'onetime' && (this.state.custody !== 3 && this.state.custody !== 4)){
            await this.setState({ custody: ''});
        }
        await this.setState({ frequency: value, errors: { ...this.state.errors, frequency: '' } });
    };

    handleCustody = async function (value) {
        await this.setState({ custody: value, errors: { ...this.state.errors, custody: '' } });
    };

    setModalVisible = function (modalVisible) {
        this.setState({ modalVisible });
    };
    setModal2Visible = function (modal2Visible) {
        this.setState({ modal2Visible });
    };

    formatDate = function (date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return month + '/' + day + '/' + year;
    };

    handleChilds = async function(value){
        if(value.length === 0){
            await this.setState({ child_ids: value, errors: {...this.state.errors, kids: 'You have to select at least one item'}});
        } else {
            await this.setState({ child_ids: value, errors: {...this.state.errors, kids: ''}});
        }
    }

    handleChildren = async function (value) {
        const { child_ids } = this.state;
        let arr = [...child_ids];
        if (arr.includes(value)) {
            let index = arr.indexOf(value);
            arr.splice(index, 1);
            if (arr.length === 0) {
                await this.setState({
                    child_ids: arr,
                    errors: { ...this.state.errors, kids: 'You have to select at least one item' },
                });
            } else {
                await this.setState({ child_ids: arr, errors: { ...this.state.errors, kids: '' } });
            }
        } else {
            await this.setState({
                child_ids: [...this.state.child_ids, value],
                errors: { ...this.state.errors, kids: '' },
            });
        }
    };

    handleDate = function (e) {
        this.setState({ currentDate: e });
    };

    confirmDate = async function () {
        const { currentDate } = this.state;
        await this.setState({ start_date: currentDate, modalVisible: false });
    };

    validate = function () {
        const { title, child_ids, start_date, frequency, custody, errors } = this.state;
        let err = {};
        if (title === '') {
            err.title = 'Field is required!';
        } else {
            err.title = '';
        }

        if (child_ids.length === 0) {
            err.kids = 'You have to select at least one item';
        } else {
            err.kids = '';
        }

        if (frequency === '') {
            err.frequency = 'Field is required!';
        } else {
            err.frequency = '';
        }

        if (custody === '') {
            err.custody = 'Field is required!';
        } else {
            err.custody = '';
        }

        if (err.title === '' && err.kids === '' && err.frequency === '' && err.custody === '') {
            this.handleSubmit();
        } else {
            this.setState({ errors: err });
        }
    };

    handleSubmit = async function () {
        const { title, child_ids, start_date, frequency, custody, errors } = this.state;
        this.setState({ loading: true });
        
        let m = new Date(this.formatDate(start_date));
        let n = moment( m.setTime(moment(m).set({ 'h': 12, 'm': 0, 's': 0 }).valueOf()).valueOf());
        
        let data = {
            title: title,
            child_ids: child_ids,
            picture: null,
            start_date: moment.parseZone(n._d).format('YYYY-MM-DD'),
            type: 'special_date',
            frequency: frequency,
            is_alternate_new: custody,
        };
        
        setupScheduleException(data).then((resp) => {
            this.setState({ loading: false });
            this.props.handleClose();
            this.props.refresh();
            this.props.dispatch(addLoading());
        });
    };

    render() {
        const { Option } = Select;

        const { currentDate, start_date, child_ids, title, errors, frequency, custody, loading } = this.state;
        const { children, user } = this.props;
    
        let today = new Date();

        let correctDate = moment(this.formatDate(start_date)) >= moment(this.formatDate(today));

        function getColor(item){
            return (item.created_by_user === (user && user.id))?item.color: item.coparent_color;
        }

        return (
            <Modal
                title="Schedule Exception"
                className="cal-modal swap-modal exception-modal"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Form className="schedule-form">
                    <div className="scroll-wrap enhance-height">
                        <Form.Item className="input-sm">
                            <Input value={title} placeholder="Enter Exception Title" onChange={this.handleTitle} />
                            {errors.title !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.title}</div>
                            ) : null}
                        </Form.Item>
                        <Form.Item className="select-sm multi-select">
                            <Select
                                placeholder="Choose Kid(s)"
                                value={child_ids}
                                mode="multiple"
                                onChange={this.handleChilds}
                            >
                                {Array.isArray(children) &&
                                    children.map((kid, i) => (
                                        <Option
                                            key={i}
                                            className={child_ids.includes(kid.id) ? 'text-color' : ''}
                                            value={kid.id}
                                        >
                                        <span className={"dot " + colors[getColor(kid)]}></span>
                                            {kid.name}
                                        </Option>
                                    ))}
                            </Select>
                            {errors.kids !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.kids}</div>
                            ) : null}
                        </Form.Item>
                        {/*<Form.Item className="input-col select-sm" label="Offer Expires In">
                          <Select placeholder="Days" onChange={handleChange}>
                              <Option value="jack">Jack</Option>
                              <Option value="lucy">Lucy</Option>
                              <Option value="Yiminghe1">yiminghe1</Option>
                              <Option value="Yiminghe2">yiminghe2</Option>
                          </Select>
                      </Form.Item>*/}
                        <Form.Item
                            className="input-sm icon-input"
                            label="Select Exception Date"
                            labelCol={{ span: 24 }}
                        >
                            <Input
                                value={this.formatDate(start_date)}
                                placeholder="Select Exception Date"
                                onClick={() => this.setModalVisible(true)}
                                suffix={<CalendarOutlined onClick={() => this.setModalVisible(true)} />}
                            />
                        </Form.Item>

                        {/* <Button className="fp-button-secondary full-btn" type="primary">
                                <span>Select Frequency</span>
                                <span className="img-wrap">
                                    <img src={right} alt="right-arrow" />
                                </span>
                            </Button> */}
                        <Form.Item className="select-sm">
                            <div className="heading-style">What is the frequency of custody exception?</div>
                            <Select
                                value={frequency === '' ? 'Select Frequency' : frequency}
                                onChange={this.handleFrequency}
                            >
                                {Array.isArray(frequencies) &&
                                    frequencies.map((item, i) => (
                                        <Option
                                            key={i}
                                            style={{ color: frequency === item.value ? '#479ED7' : '#333333' }}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Option>
                                    ))}
                            </Select>
                            {errors && errors.frequency !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.frequency}</div>
                            ) : null}
                        </Form.Item>
                        <Form.Item className="select-sm">
                            <div className="heading-style">Who will have custody on exception date?</div>
                            <Select value={custody === '' ? 'Select Custody' : custody} onChange={this.handleCustody}>
                                {frequency !== 'onetime' && Array.isArray(exceptionTypes) &&
                                    exceptionTypes.map((item, i) => (
                                        <Option
                                            key={i}
                                            style={{ color: custody === item.value ? '#479ED7' : '#333333' }}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Option>
                                    ))}
                                    {frequency === 'onetime' && Array.isArray(specialTypes) &&
                                    specialTypes.map((item, i) => (
                                        <Option
                                            key={i}
                                            style={{ color: custody === item.value ? '#479ED7' : '#333333' }}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Option>
                                    ))}
                            </Select>
                            {errors && errors.custody !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.custody}</div>
                            ) : null}
                        </Form.Item>
                    </div>
                    <div className="btn-scroll-wrap">
                        <Form.Item>
                            <Button
                                className={correctDate ? "fp-button-primary full-btn" : "fp-button-primary grey-btn"}
                                onClick={() => correctDate ? this.validate() : null}
                                type="primary"
                            >
                                Create Custody Exception
                            </Button>
                        </Form.Item>
                    </div>
                </Form>

                <Modal
                    title="Calendar"
                    className="cal-modal swap-modal sc-modal"
                    centered
                    visible={this.state.modalVisible}
                    onOk={() => this.setModalVisible(false)}
                    onCancel={() => this.setModalVisible(false)}
                >
                    <div className="edit-wrap cal-custom">
                        <div className="scroll-wrap">
                            <div className="cal-wrap">
                                <Calendar selected={currentDate} onChange={(e) => this.handleDate(e)} />
                            </div>
                        </div>
                        <div className="btn-scroll-wrap">
                            <Form.Item className="btn-top">
                                <Button onClick={this.confirmDate} className="fp-button-primary" type="primary">
                                    Confirm
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title="Schedule Exception"
                    className="cal-modal swap-modal exception"
                    centered
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                >
                    <div className="edit-wrap cal-custom">
                        <div className="scroll-wrap">
                            <div className="detail-wrap">
                                <div className="detail-item">
                                    <span>Custody:</span>
                                    <span>Privetgey</span>
                                </div>
                                <div className="detail-item">
                                    <span>Child:</span>
                                    <span>Samson</span>
                                </div>
                                <div className="detail-item">
                                    <span>Start Date:</span>
                                    <span>Apr 25, 2019</span>
                                </div>
                                <div className="detail-item">
                                    <span>Pickup Time</span>
                                    <span>12:00 PM</span>
                                </div>
                                <div className="detail-item">
                                    <span>Drop off Time</span>
                                    <span>12:00 PM</span>
                                </div>
                            </div>
                        </div>
                        <div className="btn-scroll-wrap">
                            <Form.Item className="btn-top">
                                <Button className="fp-button-primary" type="primary">
                                    Edit Custody Exception
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Modal>
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparent } = serviceReducer;
    return { user, error, children, coparent };
};

export default connect(mapStateToProps)(CreateException);
