import React, { Component } from 'react';
import 'pages/Signin/Signin.scss';
import cover from 'Assets/cover.png';
import logo from 'Assets/kincrew-logo.svg';
import arrowWhite from 'Assets/arrow-right.svg';
import loginGoogle from 'Assets/google.png';
import arrowDark from 'Assets/arrow-right-dark.png';
import { Form, Input, Button, Checkbox } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import history from 'utils/history';
import FloatLabel from 'Components/FloatLabel/FloatLabel';
import { connect } from 'react-redux';
import { ROUTES } from 'constants/routes';
import { getMeUserAction, updateLoginFlag } from 'actions/userActions';
import { getThirdPartyAction } from 'actions/houseHoldAction';
import { loginUserAction } from 'actions/authenticationActions';
import { setCookie, checkCookie } from 'utils/cookies';
import { Formik } from 'formik';
import LoadingOverlay from 'react-loading-overlay';
import { getUserSubscription } from '../../services/subscriptionService';
import GoogleSignin from './GoogleSignin';
import ConnectionErrorModal from 'Components/Modal/ConnectionModal';
import { getMeUser } from 'services/userService';
import { DisConnect } from 'pages/QuickBlox/qbutility';
import { QuickBlockConfig } from 'pages/QuickBlox/QBConfig';
import ChooseUserModal from 'pages/Signin/ChooseUserModal';
import ChooseHouseholdModal from 'pages/Signin/ChooseHouseholdModal';
import {
    getPermission
} from 'services/memberService';
import { getUserById } from 'services/userService';

const validator = require('email-validator');

const initialValues = {
    email: '',
    password: '',
};

class Signin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: '',
            modalVisible: false,
            data: null,
            selected: false,
            modal2Visible: false,
        };
        this.validate = this.validate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleSignup = this.handleSignup.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.handleThirdParty = this.handleThirdParty.bind(this);
    }

    componentDidMount() {
        let res = localStorage.getItem('notFirst');
        if(res) {
            QuickBlockConfig();
            setTimeout(() => {
                DisConnect();
            },2000);
            // Logout();  
        }
        let arr = [
            'access_token',
            'user',
            'paypal_auth',
            'last_login_email',
            'qd_userid',
            'qb_username',
            'qb_login',
            'first_name',
            'last_name',
            'notFirst', 
        ]

        arr.forEach(item => {
            localStorage.removeItem(item);
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (
            nextProps &&
            nextProps.hasOwnProperty('response') &&
            nextProps.response !== undefined &&
            nextProps.response !== null
        ) {
            if (
                nextProps.response.hasOwnProperty('login') &&
                nextProps.response.login !== undefined &&
                nextProps.response.login !== null &&
                nextProps.response.login.hasOwnProperty('response') &&
                nextProps.response.login.response !== undefined &&
                nextProps.response.login.response !== null
            ) {
                if (
                    nextProps.response.login.response.hasOwnProperty('token') &&
                    nextProps.response.login.response.token !== undefined &&
                    nextProps.response.login.response.token !== null
                ) {
                    let isSuccess = nextProps.response.login.response.token;
                    let data = nextProps.response.login.response;
                    if(data.multiuser && !this.state.selected) { //third party
                        this.setState({loading: false,data : nextProps.response.login.response})
                        this.setModal1Visible(true);

                    }else if (data.multiuser === false && !this.state.selected && data.thirdPartyStatus === true) {
                        if (data.thirdPartyStatus === true) {
                            this.setState({loading: false,data : nextProps.response.login.response})
                            this.setModal2Visible(true);
                        }
                    }else if(!this.state.selected) {
                        if (isSuccess) {

                            this.setState({ loading: false });

                            setCookie('token', nextProps.response.login.response.token, 4);
                            this.props.dispatch(getMeUserAction({ token: nextProps.response.login.response.token }));
                            localStorage.setItem('socialLogin', false);
                            // alert('www');

                            let promises = [];
                            promises.push(
                                getMeUser(isSuccess)
                                    .then(async (data) => {
                                        await localStorage.setItem('third_party', JSON.stringify(false));
                                        await localStorage.setItem('last_login_email', data.email);
                                        await localStorage.setItem('user_id', data.id);
                                        await localStorage.setItem('first_name', data.first_name);
                                        await localStorage.setItem('last_name', data.last_name);
                                        if (data.qb_username && data.qd_userid) {
                                            await localStorage.setItem('qb_username', data.qb_username);
                                            await localStorage.setItem('qd_userid', data.qd_userid);
                                            await localStorage.setItem('qb_login', data.qb_login == null ? data.qb_username : data.qb_login);
                                        } else {
                                            const login = data.email.replace(/[\W_]+/g,"") + this.generateUID();
                                            await localStorage.setItem('qb_login', login);
                                            await localStorage.setItem('qb_username', data.email);
                                            await localStorage.setItem('new_user', JSON.stringify(true));
                                        }
                                    })
                            );

                            Promise.all(promises)
                                .then(() => {
                                    getUserSubscription().then((resp) => {
                                        this.setState({ loading: false });
        
                                        if (resp && resp.data) {
                                            localStorage.setItem('resp', JSON.stringify(resp.data));
                                        }
        
                                        if (resp.stats === true && resp.data) {
                                            if (
                                                resp.data.status === 'trial' ||
                                                resp.data.status === 'live' ||
                                                resp.data.status === 'future' ||
                                                resp.data.inTrial
                                            ) {
                                                return this.props.history.push(ROUTES.HOME);
                                            } else {
                                                return this.props.history.push(ROUTES.SUBSCRIPTION);
                                            }
                                        } else {
                                            return this.props.history.push(ROUTES.SUBSCRIPTION);
                                        }
                                    });
                                });
                        }
                    }
                   
                    
                }

                // "SequelizeConnectionRefusedError"
                if (
                    nextProps.response.login.response.name &&
                    nextProps.response.login.response.name === 'SequelizeConnectionRefusedError'
                ) {
                    this.setState({ loading: false });
                    if (nextProps.response.login.response && nextProps.response.login.response.token) {
                    } else {
                        this.setModalVisible(true);
                    }
                }

                if (nextProps.response.login.response.hasOwnProperty('message')) {
                    this.setState({ loading: false, error: nextProps.response.login.response.message });
                }
            }
            if (
                nextProps.response.hasOwnProperty('login') &&
                nextProps.response.login &&
                nextProps.response.login.hasOwnProperty('error') &&
                nextProps.response.login.error
            ) {
                this.setState({ loading: false });
                if (nextProps.response.login.response && nextProps.response.login.response.token) {} 
                else {
                    this.setModalVisible(true);
                }
            }
        }

        if (nextProps.response && nextProps.response.login && nextProps.response.login.response) {
            this.setState({ errorMessage: nextProps.response.login.response.message });
        }
    }

    generateUID = () => {

      // I generate the UID from two parts here

      // to ensure the random number provide enough bits.

      var firstPart = (Math.random() * 46656) | 0;

      var secondPart = (Math.random() * 46656) | 0;

      firstPart = ('000' + firstPart.toString(36)).slice(-3);

      secondPart = ('000' + secondPart.toString(36)).slice(-3);

     return firstPart + secondPart;

    };

    // handleMultiUser() {
    //     this.setModal1Visible(true);

    // }
    setModal1Visible = async function(visible) {
        await this.setState({ modal1Visible: visible });
    }
    setModal2Visible = async function (visible) {
        await this.setState({ modal2Visible: visible });
    }

     handleUser = async function(type) {
        const { data } = this.state;
        let TokenIs;
        if (type === '1') {
            this.setState({ selected: true });
            TokenIs = data.token;
        } else if (type === '2') {
            this.setModal2Visible(true);
        } 

        if(TokenIs){
            setCookie('token', TokenIs, 4);
            this.props.dispatch(getMeUserAction({ token: TokenIs }));
            localStorage.setItem('socialLogin', false);
                            // alert('www');
            
            let promises = [];
            promises.push(
                getMeUser(TokenIs)
                    .then(async (data) => {
                        await localStorage.setItem('third_party', JSON.stringify(false));
                        await localStorage.setItem('last_login_email', data.email);
                        await localStorage.setItem('user_id', data.id);
                        await localStorage.setItem('first_name', data.first_name);
                        await localStorage.setItem('last_name', data.last_name);
                        if (data.qb_username && data.qd_userid) {
                            await localStorage.setItem('qb_username', data.qb_username);
                            await localStorage.setItem('qd_userid', data.qd_userid);
                            await localStorage.setItem('qb_login', data.qb_login == null ? data.qb_username : data.qb_login);
                        } else {
                            const login = data.email.replace(/[\W_]+/g,"") + this.generateUID();
                            await localStorage.setItem('qb_login', login);
                            await localStorage.setItem('qb_username', data.email);
                            await localStorage.setItem('new_user', JSON.stringify(true));
                        }
                    })
            );

            Promise.all(promises)
                .then(() => {
                    getUserSubscription().then((resp) => {
                        this.setState({ loading: false });
        
                        if (resp && resp.data) {
                            localStorage.setItem('resp', JSON.stringify(resp.data));
                        }
        
                        if (resp.stats === true && resp.data) {
                            if (
                                resp.data.status === 'trial' ||
                                resp.data.status === 'live' ||
                                resp.data.status === 'future' ||
                                resp.data.inTrial  
                            ) {
                                    return this.props.history.push(ROUTES.HOME);
                            } else {
                                    return this.props.history.push(ROUTES.SUBSCRIPTION);
                            }
                        } else {
                                    return this.props.history.push(ROUTES.SUBSCRIPTION);
                                }
                    });   
            })
        }
    }

    handleThirdParty = async function(obj){
        const {data} = this.state;
        this.setState({ selected: true });
        this.setState({ loading: true });
        let TokenIs;
        if(data.multiuser === false && data.thirdPartyStatus === true) {
            this.setState({ modal2Visible: false })
            TokenIs = data.token;
        } else {
            TokenIs = obj.token;
            localStorage.setItem('third_party', JSON.stringify(true));
        }
       
       
        if(TokenIs){
            setCookie('token', TokenIs, 4);
            this.props.dispatch(getMeUserAction({ token: TokenIs }));
            this.props.dispatch(getThirdPartyAction({ id : obj.member_id }));
            localStorage.setItem('socialLogin', false);
                            // alert('www');
            let promises = [];
            promises.push(
                getMeUser(TokenIs)
                    .then(async (data) => {
                        await localStorage.setItem('last_login_email', 'thirdParty_' + data.email);
                        await localStorage.setItem('user_id', data.id);
                        await localStorage.setItem('first_name', data.first_name);
                        await localStorage.setItem('last_name', data.last_name);
                        await localStorage.setItem('third_party', JSON.stringify(true));
                        await localStorage.setItem('household_id', obj.household_id);
                        await localStorage.setItem('coparent_id', obj.coparent_id);
                        await localStorage.setItem('member_id', obj.member_id);
                        if (data.qb_username && data.qd_userid) {
                            await localStorage.setItem('qb_username', data.qb_username);
                            await localStorage.setItem('qd_userid', data.qd_userid);
                            await localStorage.setItem('qb_login', data.qb_login == null ? data.qb_username : data.qb_login);
                        } else {
                            const login = 'thirdParty_' + data.email.replace(/[\W_]+/g,"") + this.generateUID();
                            await localStorage.setItem('qb_login', login);
                            await localStorage.setItem('qb_username', 'thirdParty_' + data.email);
                            await localStorage.setItem('new_user', JSON.stringify(true));
                        }
                    })
            );

            
            promises.push(
                getUserById(obj.coparent_id)
                    .then(resp => {
                        if (resp) {
                            localStorage.setItem('coparent_name', resp.first_name);
                        }
                    })
            );

            Promise.all(promises)
                .then(() => {
                    let _data = {
                        user_id: localStorage.user_id,
                        household_id: obj.household_id
                    };

                    let arr = [];

                    arr.push(
                        getPermission(_data)
                            .then(resp => {
                                if (resp && resp.success && resp.permission) {
                                    localStorage.setItem('third_party_permission', JSON.stringify(resp.permission));
                                    localStorage.setItem('calendar_permission', resp.permission.calendar);
                                    localStorage.setItem('payment_permission', resp.permission.payment);
                                    localStorage.setItem('chat_permission', resp.permission.chat);
                                }
                            })
                    );

                Promise.all(arr)
                .then(() => {
                    getUserSubscription().then((resp) => {
                        this.setState({ loading: false });
                        if (resp && resp.data) {
                            localStorage.setItem('resp', JSON.stringify(resp.data));
                        }
        
                        if (resp.stats === true && resp.data) {
                            if (
                                resp.data.status === 'trial' ||
                                resp.data.status === 'live' ||
                                resp.data.status === 'future' ||
                                resp.data.inTrial
                            ) {
                                    return this.props.history.push(ROUTES.HOME);
                            } else {
                                    return this.props.history.push(ROUTES.SUBSCRIPTION);
                                }
                            } else {
                                    return this.props.history.push(ROUTES.SUBSCRIPTION);
                            }
                    });   
                }) 
            })
        }

    }

    setModalVisible = async function(visible){
        await this.setState({ modalVisible: visible });
    }

    handleSignup = async function () {
        await this.setState({ error: '' });
    };

    validate = (values) => {
        let errors = {};
        if (!values.email) {
            errors.email = 'Email is required!';
        } else if (!validator.validate(values.email.trim())) {
            errors.email = 'Invalid email';
        }

        if (!values.password) {
            errors.password = 'Password is required!';
        }
        if (errors.email !== '' || errors.password !== '') {
            this.setState({ error: '' });
        }
        return errors;
    };

    onSubmit = (values) => {
        this.setState({ loading: true });
        const data = {
            email: values.email.trim(),
            password: values.password,
        };
        this.props.dispatch(loginUserAction(data));
    };

    render() {
        const { response } = this.props;
        const { loading, error } = this.state;

        return (
            <LoadingOverlay
                active={loading ? true : false}
                className={'loader-wrap ' + (loading ? 'd-block' : '')}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '100px',
                        '& svg circle': {
                            stroke: 'rgb(56, 160, 235)',
                        },
                    }),
                }}
            >
                <div className="signin-wrapper">
                    <div className="signin-cover">{/* <img src={cover} alt="kincrew-cover-image" /> */}</div>
                    <div className="signin-details">
                        <div className="details-inner">
                            <div className="details-head">
                                <div className="logo-wrap">
                                    <img src={logo} alt="kincrew-logo" />
                                    <span>kincrew</span>
                                </div>
                                <div className="btn-wrap">
                                    <Link to="/signup">
                                        <Button onClick={this.handleSignup} type="primary" className="btn-sign-in">
                                            Sign Up
                                            <span className="arrow-wrap">
                                                <img src={arrowDark} alt="arrow-right" />
                                            </span>
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                            <div className="data-wrap">
                                {error !== '' ? <div className="sign-err-top">{error}</div> : null}
                                <div className="sign-head">
                                    <span>Welcome!</span>
                                </div>

                                <div className="signin-form-wrap">
                                    <Formik
                                        initialValues={initialValues}
                                        validate={this.validate}
                                        onSubmit={this.onSubmit}
                                    >
                                        {({
                                            touched,
                                            values,
                                            handleChange,
                                            handleSubmit,
                                            handleBlur,
                                            errors,
                                            isSubmitting,
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <Form.Item label="Email" labelCol={{ span: 24 }}>
                                                    <Input
                                                        placeholder="mom.parent@gmail.com"
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        autoCapitalize ="none"
                                                    />
                                                    {touched.email && errors.email ? (
                                                        <div className="sign-err">{errors.email}</div>
                                                    ) : null}
                                                </Form.Item>
                                                <Form.Item label="Password" labelCol={{ span: 24 }}>
                                                    <Input
                                                        name="password"
                                                        value={values.password}
                                                        type="password"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {touched.password && errors.password ? (
                                                        <div className="sign-err">{errors.password}</div>
                                                    ) : null}
                                                </Form.Item>
                                                <div className="fgt-pwd">
                                                    <Link to="/forgotpassword">Forgot Password?</Link>
                                                </div>
                                                <div className="checkbox-wrap"></div>
                                                <div className="btn-wrap">
                                                    <Button type="primary" htmlType="submit" className="btn-full">
                                                        <span>Login</span>
                                                        <span>
                                                            <img src={arrowWhite} alt="arrow-right" />
                                                        </span>
                                                    </Button>
                                                    {/* <Button type="primary" htmlType="submit" className="btn-full btn-google ">
                                                        <span>Sign in with Google</span>
                                                        <span>
                                                            <img src={loginGoogle} alt="google-logo" />
                                                        </span>
                                                    </Button>*/}
                                                    {/*------------- Google Login ------------------------*/}

                                                    <GoogleSignin location={this.props.location}/>

                                                    {/*------------- Google Login ------------------------*/}
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ConnectionErrorModal
                    handleOpen={this.state.modalVisible}
                    handleClose={() => this.setModalVisible(false)}
                />
                <ChooseUserModal
                    openModal={this.state.modal1Visible}
                    closeModal={() => this.setModal1Visible(false)}
                    selectedUser={(type) => this.handleUser(type)}
                />
                <ChooseHouseholdModal
                    openModal={this.state.modal2Visible}
                    closeModal={() => this.setModal2Visible(false)}
                    data={this.state.data}
                    selected={(obj) => this.handleThirdParty(obj)}
                />
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = (response) => ({ response });

export default connect(mapStateToProps)(Signin);
