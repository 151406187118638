import React, { Component } from "react";
import "Components/Header/Header.scss";
import { Row, Col, Select, Popover, Modal, Button, Badge, List } from "antd";
import logo from "Assets/kincrew-white.png";
import settings from "Assets/settings.svg";
import help from "Assets/help.svg";
import user from "Assets/user.svg";
import calendar from "Assets/calendar.svg";
import report from "Assets/reports.svg";
import helpCenter from "Assets/service-call.svg";
import card from "Assets/credit-card.svg";
import notification from "Assets/notification.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { connect } from "react-redux";
import {
  getCoParentUserAction,
  getCheckListAction,
  getNotificationAction,
} from "actions/userActions";
import SetupCheckListModal from "pages/Landing/SetUpCheckList/SetupCheckListModal";
import history from "utils/history";
import { createBrowserHistory } from "history";
import { getSetupProgress, updateChecklistResp } from "services/userService";
import { getUserById } from "services/userService";
import close from "Assets/close.svg";
import { getPermission } from "services/memberService";
import { updateNotificationRead } from "services/notificationService";
import { addPermission, removePermission } from "actions/loaderActions";
import moment from "moment";
import { WindowsFilled } from "@ant-design/icons";

const { Option } = Select;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coparent: "",
      err: false,
      clicked: false,
      is_clicked: false,
      hovered: false,
      navigationHelpModal: false,
      baseListStatus: false,
      updated: false,
      checklistStatus1: {
        checklistResp: [],
        calendarEnabled: false,
        paymentEnabled: false,
        chatEnabled: false,
        reportsEnabled: false,
      },
      respp: JSON.parse(localStorage.getItem("checkListResp")),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.hide = this.hide.bind(this);
    this.handleClickChange = this.handleClickChange.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.setNavigationHelpModal = this.setNavigationHelpModal.bind(this);
    this.renderNotification = this.renderNotification.bind(this);
    this.handlePopover = this.handlePopover.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
  }

  hide = () => {
    this.setState({
      clicked: false,
    });
  };

  handleClickChange = (visible) => {
    this.setState({
      clicked: visible,
    });
  };

  handlePopover = (visible) => {
    this.setState({
      is_clicked: visible,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({
      clicked: visible,
    });
  };

  componentDidMount() {
    if (localStorage.third_party !== "true") {
      this.props.dispatch(getCheckListAction());
      this.props.dispatch(getNotificationAction());
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.permission) {
      this.props.dispatch(removePermission());
      this.setState({ updated: !this.state.updated });
    }
  }

  handleChange = function (value) {
    if (localStorage.third_party !== "true") {
      this.props.coparents.forEach((coparent) => {
        if (coparent.id === value) {
          if (coparent.id === 222) {
            this.props.dispatch(getCoParentUserAction(null));
          } else {
            this.props.dispatch(getCoParentUserAction(coparent));
          }
        }
      });
    } else if (localStorage.third_party === "true") {
    }
  };

  handleLogout = function () {
    document.cookie =
      "token= ; path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    window.location.replace(ROUTES.LANDING_PAGE);

    let arr = [
      "socialLogin",
      "third_party",
      "household_id",
      "coparent_id",
      "third_party_permission",
      "calendar_permission",
      "payment_permission",
      "chat_permission",
      "member_id",
      "coparent_name",
      "new_user",
    ];

    arr.forEach((item) => {
      localStorage.removeItem(item);
    });
  };

  showNavigationHelp() {
    this.setState({ navigationHelpModal: true });
  }

  setNavigationHelpModal = async function (modalVisible) {
    await this.setState({ navigationHelpModal: modalVisible });
  };

  handleNavigation = async function (item) {
    await this.setState({ is_clicked: !this.state.is_clicked });
    if (!item.is_read) {
      updateNotificationRead(item.id).then((resp) => {
        if (resp.updated) {
          this.props.dispatch(getNotificationAction());
        }
      });
    }
  };

  getRoute = (item) => {
    let res = "/home";
    if (item.action == 1 || item.action == 2) {
      res = "/household";
    } else if (item.action == 3 || item.action == 4 || item.action == 5) {
      res = "/calendar";
    } else if (item.action == 6 || item.action == 7 || item.action == 8) {
      res = "/payment";
    } else if (item.action == 9) {
      res = "/chat";
    }

    return res;
  };

  closePopover = () => {
    this.setState({ is_clicked: false });
  };

  renderNotification = function () {
    const { notifications } = this.props;

    let arr = [];
    if (notifications.length <= 4) {
      arr = [...notifications];
    } else {
      arr = notifications.slice(0, 4);
    }

    return (
      <div className="notification-content-wrap">
        <div className="head">
          <span>Notifications</span>
          <span onClick={() => this.closePopover()}>
            <img src={close} alt="close-icon" />
          </span>
        </div>
        <div className="list-wrap-notif">
          <List itemLayout="horizontal">
            {arr.map((item, i) => (
              <Link to={this.getRoute(item)}>
                <List.Item
                  onClick={() => this.handleNavigation(item)}
                  style={{ cursor: "pointer" }}
                  key={i}
                  className={item.is_read ? "blue-list" : ""}
                >
                  <div className="list-top">
                    <span>{item.title}</span>
                    <span>{moment(item.created_at).from(moment())}</span>
                  </div>
                  <div className="list-bottom">{item.details}</div>
                </List.Item>
              </Link>
            ))}
          </List>
        </div>
        <div
          className="notification-bottom"
          onClick={() => this.closePopover()}
        >
          <Link to="/notifications">
            <span>View all notifications</span>
          </Link>
        </div>
      </div>
    );
  };

  render() {
    const history = createBrowserHistory();
    const { checklistStatus1, updated } = this.state;
    const { coparents, checkListStatus } = this.props;
    var baseListEnabled = true;
    if (checkListStatus != undefined && typeof resp === "object") {
      if (
        checkListStatus["profile"] == 0 ||
        checkListStatus["coParent"] == 0 ||
        checkListStatus["children"] == 0
      ) {
        baseListEnabled = false;
      }
    }

    let showCalendar =
      localStorage.third_party !== "true" ||
      (localStorage.third_party === "true" &&
        localStorage.calendar_permission === "true");
    let showPayment =
      localStorage.third_party !== "true" ||
      (localStorage.third_party === "true" &&
        localStorage.payment_permission === "true");
    let showChat =
      localStorage.third_party !== "true" ||
      (localStorage.third_party === "true" &&
        localStorage.chat_permission === "true");

    const content = (
      <div className="content-wrap">
        <span onClick={this.hide}>
          <Link to="/household">
            <p>Profile</p>
          </Link>
        </span>
        {localStorage.third_party !== "true" && (
          <span onClick={this.hide}>
            <Link to="/subscription">
              <p>Subscription</p>
            </Link>
          </span>
        )}
        <span className="logout" onClick={this.handleLogout}>
          <p>Logout</p>
        </span>
      </div>
    );

    if (localStorage.third_party === "true") {
      if (history.location.pathname === "/reports") {
        return null;
      }
      if (history.location.pathname === "/notifications") {
        return null;
      }
      if (history.location.pathname === "/subscription") {
        return null;
      }
      if (history.location.pathname === "/subscriptionDetails") {
        return null;
      }
    }

    if (
      history.location.pathname === "/subscription" ||
      history.location.pathname === "/"
    ) {
      return null;
    } else if (history.location.pathname === "/subscriptionDetails") {
      return null;
    } else if (
      history.location.pathname === "/signup" ||
      history.location.pathname === "/signup/" ||
      history.location.pathname === "/resetpassword" ||
      history.location.pathname === "/forgotpassword" ||
      history.location.pathname === "/forgot" ||
      history.location.pathname === "/reset"
    ) {
      return null;
    } else if (
      history.location.pathname === "/forgotpassword" ||
      history.location.pathname === "/forgot" ||
      history.location.pathname === "/reset" ||
      history.location.pathname === "/resetpassword" ||
      history.location.pathname === "/errorPage" ||
      history.location.pathname === "/signup"
    ) {
      return null;
    } else {
      return (
        <div className="header-wrap">
          <Row className="head-row">
            <Col span={8}>
              <div className="logo-wrap">
                <Link to="/home">
                  <div className="logo-inner">
                    <img src={logo} alt="sop-logo" />
                    <span className="logo-item">kincrew</span>
                  </div>
                </Link>
              </div>
            </Col>
            <Col span={8} className="drop-wrap">
              <div className="drop-down">
                <Select defaultValue="Household" onChange={this.handleChange}>
                  {coparents.map((coparent, i) => (
                    <Option key={i} value={coparent.id}>
                      {coparent.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col span={8} className="icon-wrap">
              <div className="icon-inner">
              {localStorage.third_party !== "true" && (
                <span className="notification-wrap">
                  <Popover
                    // content={<a onClick={this.hide}>Close</a>}
                    content={this.renderNotification()}
                    trigger="click"
                    overlayClassName="notification-popover"
                    placement="bottomRight"
                    visible={this.state.is_clicked}
                    onVisibleChange={this.handlePopover}
                  >
                    <Badge
                      count={this.props.notificationCount}
                      overflowCount={10}
                    >
                      <Link>
                        <img
                          src={notification}
                          alt="Notifications"
                          title="Notifications"
                        />
                      </Link>
                    </Badge>
                  </Popover>
                </span>
                )}
                {showCalendar && (
                  <span>
                    {baseListEnabled === false ? (
                      <Link onClick={() => this.showNavigationHelp()}>
                        <img src={calendar} alt="calendar" title="Calendars" />
                      </Link>
                    ) : (
                      <Link
                        to={
                          localStorage.third_party
                            ? localStorage.third_party == "true"
                              ? localStorage.calendar_permission == "true"
                                ? "/calendar"
                                : "/home"
                              : "/calendar"
                            : "/calendar"
                        }
                      >
                        <img src={calendar} alt="calendar" title="Calendars" />
                      </Link>
                    )}
                  </span>
                )}
                {localStorage.third_party !== "true" && (
                  <span>
                    {baseListEnabled === false ? (
                      <Link onClick={() => this.showNavigationHelp()}>
                        <img src={report} alt="reports" title="Reports" />
                      </Link>
                    ) : (
                      <Link to="/reports">
                        <img src={report} alt="reports" title="Reports" />
                      </Link>
                    )}
                  </span>
                )}

                {showChat && (
                  <span>
                    {baseListEnabled === false ? (
                      <Link onClick={() => this.showNavigationHelp()}>
                        <img
                          src={help}
                          alt="chat-icon"
                          title="Communications"
                        />
                      </Link>
                    ) : (
                      <Link
                        to={
                          localStorage.third_party
                            ? localStorage.third_party == "true"
                              ? localStorage.chat_permission == "true"
                                ? "/chat"
                                : "/home"
                              : "/chat"
                            : "/chat"
                        }
                      >
                        <img
                          src={help}
                          alt="chat-icon"
                          title="Communications"
                        />
                      </Link>
                    )}
                  </span>
                )}

                {showPayment && (
                  <span>
                    {baseListEnabled === false ? (
                      <Link onClick={() => this.showNavigationHelp()}>
                        <img src={card} alt="card-icon" title="Payments" />
                      </Link>
                    ) : (
                      <Link
                        to={
                          localStorage.third_party
                            ? localStorage.third_party == "true"
                              ? localStorage.payment_permission == "true"
                                ? "/payment"
                                : "/home"
                              : "/payment"
                            : "/payment"
                        }
                      >
                        <img src={card} alt="card-icon" title="Payments" />
                      </Link>
                    )}
                  </span>
                )}
                    <span>
                      <a target="_blank" href="https://kincrew.io/help-center/">
                         <img src={helpCenter} alt="help-icon" title="Help Center" />
                      </a>
                  </span>
                <span>
                  <Popover
                    placement="bottomRight"
                    content={content}
                    trigger="click"
                    visible={this.state.clicked}
                    onVisibleChange={this.handleClickChange}
                  >
                    <img src={user} alt="user" title="Household" />
                  </Popover>
                </span>
              </div>
            </Col>
          </Row>
          <SetupCheckListModal
            handleOpen={this.state.navigationHelpModal}
            handleClose={() => this.setNavigationHelpModal(false)}
            checkList={checklistStatus1.checklistResp}
            baseListStatus={this.state.baseListStatus}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = ({ serviceReducer }) => {
  const {
    coparents,
    checkListStatus,
    permission,
    notificationCount,
    notifications,
  } = serviceReducer;
  const household = {
    id: 222,
    name: "Household",
  };
  let flag = false;
  coparents.forEach((cp) => {
    if (cp.id === household.id) {
      flag = true;
    }
  });
  if (!flag) {
    coparents.unshift(household);
  }
  return {
    coparents,
    checkListStatus,
    permission,
    notificationCount,
    notifications,
  };
};
export default connect(mapStateToProps)(Header);
