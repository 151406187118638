import React, { Component, useState } from 'react';
import 'pages/Reports/PaymentTransaction/PaymentTransaction.scss';
import { Card, Button, Tabs, Modal, Select, DatePicker, Empty } from 'antd';
import { connect } from 'react-redux';
import moment, { duration } from 'moment';
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import {
    SaveRecurringPaymentReport,
    GetRecurringReporDetail,
    UpdateRecurringPaymentReport,
} from 'services/recurringReportsService';

class SaveReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recurringData: this.props.responseInfo,
            type: '',
            LoadingIs: false,
        };
    }
    setModalVisible(modalVisible) {
        this.setState({ modalVisible });
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        
        const { responseInfo } = this.props;
        if (nextProps.handleOpen && responseInfo) {
            if (responseInfo.type == 'create') {
                this.setState({ recurringData: responseInfo.data, type: responseInfo.type });
            } else {
                this.setState({ type: responseInfo.type });
                this.getActiveReports(responseInfo.data);
            }
        }
    };

    getActiveReports(id) {
        this.setState({ LoadingIs: true, recurringData:[]  });
        GetRecurringReporDetail(id, (data) => {
            this.setState({ LoadingIs: false });
            this.setState({ recurringData: data.data });
        });
    }

    formartData(date) {
        var newdate = moment(date, 'DD-MM-YYYY');
        return moment(newdate).format('MM/DD/YY');
    }

    SaveReport = async () => {
        const { type, recurringData } = this.state;
        this.setState({ LoadingIs: true });
        if (type == 'create') {
            let data = {
                coparent_id: recurringData.coparent_id,
                startDate: recurringData.startDate,
                endDate: recurringData.endDate,
                child_ids: recurringData.child_ids,
                periodDuration: recurringData.periodDuration,
                recurringData: recurringData.paymentsByCategory,
            };

            let response = await SaveRecurringPaymentReport(data);
            this.setState({ LoadingIs: false });
            if (response) {
                this.props.handleAllClose();
            }
        } else {
            let data = {};
            data.id = recurringData.id;
            data.archived = true;

            this.setState({ LoadingIs: true });
            let response = await UpdateRecurringPaymentReport(data);
            this.setState({ LoadingIs: false });
            if (response) {
                this.props.handleRefreshClose();
            }
        }
    };

    render() {
        const { recurringData, LoadingIs, type } = this.state;
        let page = recurringData && recurringData.paymentsByCategory ? 'list' : 'details';
        let dataLength = 0;
        if (recurringData && recurringData != null &&  page == 'list') {
            if(Object.keys(recurringData) && Object.keys(recurringData).length > 0 && recurringData.paymentsByCategory &&  Object.keys(recurringData.paymentsByCategory)){
                dataLength = Object.keys(recurringData.paymentsByCategory).length;
            }
        } else if (recurringData && recurringData != null  && page == 'details') {
            if(Object.keys(recurringData) && Object.keys(recurringData).length > 0  && recurringData.recurringData && Object.keys(recurringData.recurringData)){
                dataLength = Object.keys(recurringData.recurringData).length;
            }
        }

        return (
            <div>
                <Modal
                    title="Reconcile Recurring"
                    centered
                    className="report-data-modal pay-data"
                    maskClosable={false}
                    visible={this.props.handleOpen}
                    onCancel={this.props.handleClose}
                >
                    <LoadingOverlay
                        active={LoadingIs}
                        className={LoadingIs ? 'd-block pop-loader' : 'd-none'}
                        spinner
                        styles={{
                            spinner: (base) => ({
                                ...base,
                                width: '100px',
                                '& svg circle': {
                                    stroke: 'rgb(56, 160, 235)',
                                },
                            }),
                        }}
                    ></LoadingOverlay>
                    {dataLength > 0 && recurringData && (
                        <div className="modal-top">
                            <span>
                                {'' +
                                    this.formartData(recurringData.startDate) +
                                    ' - ' +
                                    this.formartData(recurringData.endDate)}
                            </span>
                            <span> {recurringData.periodDuration + ' Days'}</span>
                        </div>
                    )}
                    <div className="modal-body">
                        <div className="recocile-wrap">
                            <div className="recocile-item">
                                <div className="recocile-head">
                                    <span>Recurring</span>
                                    <span>Scheduled</span>
                                    <span>Sent</span>
                                    <span>Outstanding</span>
                                </div>
                                {dataLength > 0 && recurringData &&
                                    page == 'list' &&
                                    Object.values(recurringData.paymentsByCategory).map((type, i) => (
                                        <div key={i}>
                                            <div className="item-head">
                                                <span>{type.name}</span>
                                                <span>{'$' + type.totalScheduleAmount.toFixed(2)}</span>
                                                <span>{'$' + type.totalSentAmount.toFixed(2)}</span>
                                                <span
                                                    className={
                                                        Number(type.totalScheduleAmount - type.totalSentAmount) > 0
                                                            ? 'red'
                                                            : ''
                                                    }
                                                >
                                                    {'$' +
                                                        Number(type.totalScheduleAmount - type.totalSentAmount).toFixed(
                                                            2,
                                                        )}
                                                </span>
                                            </div>

                                            {type.payments.map((val, id) => (
                                                <div className="item-inner" key={id}>
                                                    <span className="double-cell">
                                                        <span></span>
                                                        <span>{moment(val.sentDate).format('MM-DD-YYYY')}</span>
                                                    </span>
                                                    <span>{'$' + val.scheduleAmount}</span>
                                                    <span>{val.sentAmount == 0 ? '$--,--' : '$' + val.sentAmount}</span>
                                                    <span
                                                        className={
                                                            Number(val.scheduleAmount - val.sentAmount) > 0 ? 'red' : ''
                                                        }
                                                    >
                                                        {'$' + Number(val.scheduleAmount - val.sentAmount).toFixed(2)}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    ))}

                                {dataLength > 0 && recurringData &&
                                    page == 'details' &&
                                    Object.values(recurringData.recurringData).map((type, i) => (
                                        <div key={i}>
                                            <div className="item-head">
                                                <span>{type.name}</span>
                                                <span>{'$' + type.totalScheduleAmount.toFixed(2)}</span>
                                                <span>{'$' + type.totalSentAmount.toFixed(2)}</span>
                                                <span
                                                    className={
                                                        Number(type.totalScheduleAmount - type.totalSentAmount) > 0
                                                            ? 'red'
                                                            : ''
                                                    }
                                                >
                                                    {'$' +
                                                        Number(type.totalScheduleAmount - type.totalSentAmount).toFixed(
                                                            2,
                                                        )}
                                                </span>
                                            </div>

                                            {type.payments.map((val, id) => (
                                                <div className="item-inner" key={id}>
                                                    <span className="double-cell">
                                                        <span></span>
                                                        <span>{moment(val.sentDate).format('MM-DD-YYYY')}</span>
                                                    </span>
                                                    <span>{'$' + val.scheduleAmount}</span>
                                                    <span>{val.sentAmount == 0 ? '$--,--' : '$' + val.sentAmount}</span>
                                                    <span
                                                        className={
                                                            Number(val.scheduleAmount - val.sentAmount) > 0 ? 'red' : ''
                                                        }
                                                    >
                                                        {'$' + Number(val.scheduleAmount - val.sentAmount).toFixed(2)}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    ))}

                                {recurringData && dataLength == 0 && (
                                    <div>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {type != 'archive' && dataLength > 0 && (
                        <div className="btn-save-wrap">
                            <Button className="fp-button-secondary create-btn" onClick={() => this.SaveReport()}>
                                {type == 'create' ? 'Save Report' : 'Archive Report'}
                            </Button>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparents, coparent } = serviceReducer;
    return { user, error, children, coparents, coparent };
};
export default connect(mapStateToProps)(SaveReport);
