import React,{ Component } from 'react';
import 'pages/Signin/Signin.scss';
import { Button } from 'antd';
import img from 'Assets/google.png';
import { GoogleLogin } from 'react-google-login';
import { setCookie } from 'utils/cookies';
import { connect } from 'react-redux';
import { Baseurl,CLIENTID } from '../../services/authenticationService';
import { getMeUserAction } from 'actions/userActions';
import { getUserSubscription } from '../../services/subscriptionService';
import { ROUTES } from 'constants/routes';
import history from 'utils/history';
import ConnectionErrorModal from 'Components/Modal/ConnectionModal';
import ChooseUserModal from 'pages/Signin/ChooseUserModal';
import ChooseHouseholdModal from 'pages/Signin/ChooseHouseholdModal';
import SwapErrorModal from 'pages/CalenderInner/Swap/SwapErrorModal';
import ThirdParty from 'pages/Household/ThirdParty/ThirdParty';
import { getMeUser } from 'services/userService';
import { getThirdPartyAction } from 'actions/houseHoldAction';
import {
    getPermission
} from 'services/memberService';
import { getUserById } from 'services/userService';

class GoogleSignin extends Component{
    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            modal1Visible: false,
            modal2Visible: false,
            modal3Visible: false,
            data: null,
            msg: ''
        };
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.setModal3Visible = this.setModal3Visible.bind(this);
    }


responseGoogle = (response) => {
     if(response.tokenId){ 
         const type ="social"; 
         
         const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " }, 
            body: JSON.stringify({ token : response.tokenId, platform: "web" })
         };
         
         let url = `${Baseurl}/auth/${type}`;
         if (this.props.signup) {
             if (this.props.location && this.props.location.search) {
                 if (this.props.location.search.includes('isThirdParty=true')) {
                    url += '?isThirdPartySignup=true';
                }
            }
         }
         

         if(requestOptions){
            fetch(url ,requestOptions).then(async res => { 
            const data = await res.json();

            if (data.message) {
                this.setModal3Visible(true, data.message);
            } else {
                this.setState({ data });
                const TokenIs = data.token;
                if (data.multiuser) {
                    if (data.thirdParty && data.thirdParty.length === 0) {
                        this.handleUser('1');
                    } else {
                        this.setModal1Visible(true);
                    }
                } else if (data.multiuser === false) {
                    if (data.thirdPartyStatus === false) {
                    this.handleUser('1');
                } else if (data.thirdPartyStatus === true) {
                    this.setModal2Visible(true);
                }
            }
            }
            }).catch((err)=>{
                this.setModalVisible(true);
            })
        } 
     }
 }

    setModalVisible = async function(visible){
        await this.setState({ modalVisible: visible });
    }

    setModal1Visible = async function(visible) {
        await this.setState({ modal1Visible: visible });
    }

    setModal2Visible = async function (visible) {
        await this.setState({ modal2Visible: visible });
    }

    setModal3Visible = async function (visible, msg) {
        await this.setState({ modal3Visible: visible, msg });
    }

    handleUser = (type) => {
        const { data } = this.state;
        let TokenIs;
        if (type === '1') {
            TokenIs = data.token;
        }
        else if (type === '2') {
            this.setModal2Visible(true);
        } 

        if(TokenIs){
            setCookie('token', TokenIs, 4);
            let resp = this.props.dispatch(getMeUserAction({ token: TokenIs }));
            localStorage.setItem("socialLogin", true);
            let promises = [];

            promises.push(
                getMeUser(TokenIs)
                    .then(async (userData) => {
                        await localStorage.setItem('third_party', JSON.stringify(false));
                        await localStorage.setItem('last_login_email', userData.email);
                        await localStorage.setItem('user_id', userData.id);
                        await localStorage.setItem('first_name', userData.first_name);
                        await localStorage.setItem('last_name', userData.last_name);
                        if (userData.qb_username && userData.qd_userid) {
                            await localStorage.setItem('qb_username', userData.qb_username);
                            await localStorage.setItem('qd_userid', userData.qd_userid);
                            await localStorage.setItem('qb_login', userData.qb_login == null ? userData.qb_username : userData.qb_login);
                        } else {
                            const login = userData.email.replace(/[\W_]+/g,"") + this.generateUID();
                            await localStorage.setItem('qb_login', login);
                            await localStorage.setItem('qb_username', userData.email);
                            await localStorage.setItem('new_user', JSON.stringify(true));
                        }
                    })
            );

            Promise.all(promises)
                .then(() => {
                    if (this.props.signup) { // google signup
                        this.setState({ loading: false });
                        return window.location.replace(ROUTES.SUBSCRIPTION);
                    } else {  // google signin
                        getUserSubscription().then((resp) => {
                            this.setState({ loading: false });
                            localStorage.setItem('resp', JSON.stringify(resp.data));
        
                            if (resp.stats === true && resp.data) {
                                if (
                                    resp.data.status === 'trial' ||
                                    resp.data.status === 'live' ||
                                    resp.data.status === 'future' ||
                                    resp.data.inTrial
                                ) {
                                    return window.location.replace(ROUTES.HOME);
                                } else {
                                    return window.location.replace(ROUTES.SUBSCRIPTION);
                                }
                            } else {
                                return window.location.replace(ROUTES.SUBSCRIPTION);
                            }
                        });
                    }
            })   
        }
    }

    generateUID = () => {

      // I generate the UID from two parts here

      // to ensure the random number provide enough bits.

      var firstPart = (Math.random() * 46656) | 0;

      var secondPart = (Math.random() * 46656) | 0;

      firstPart = ('000' + firstPart.toString(36)).slice(-3);

      secondPart = ('000' + secondPart.toString(36)).slice(-3);

     return firstPart + secondPart;

    };

 handleThirdParty = (obj) => {
     let TokenIs = obj.token;
     if(TokenIs){
        setCookie('token', TokenIs, 4);
        let resp = this.props.dispatch(getMeUserAction({ token: TokenIs }));
        this.props.dispatch(getThirdPartyAction({ id : obj.member_id }));
        localStorage.setItem("socialLogin", true);
     
        let promises = [];
        promises.push(
            getMeUser(TokenIs)
            .then(async (userData) => {
                await localStorage.setItem('third_party', JSON.stringify(true));
                await localStorage.setItem('household_id', obj.household_id);
                await localStorage.setItem('coparent_id', obj.coparent_id);
                await localStorage.setItem('member_id', obj.member_id);
                await localStorage.setItem('last_login_email', 'thirdParty_' + userData.email);
                await localStorage.setItem('user_id', userData.id);
                await localStorage.setItem('first_name', userData.first_name);
                await localStorage.setItem('last_name', userData.last_name);
            if (userData.qb_username && userData.qd_userid) {
                await localStorage.setItem('qb_username', userData.qb_username);
                await localStorage.setItem('qd_userid', userData.qd_userid);
                await localStorage.setItem('qb_login', userData.qb_login == null ? userData.qb_username : userData.qb_login);
            } else {
                const login = 'thirdParty_' + userData.email.replace(/[\W_]+/g,"") + this.generateUID();
                await localStorage.setItem('qb_login', login);
                await localStorage.setItem('qb_username', 'thirdParty_' + userData.email);
                await localStorage.setItem('new_user', JSON.stringify(true));
            }
            
            })
        );
         
        promises.push(
            getUserById(obj.coparent_id)
            .then(resp => {
                 if (resp) {
                    localStorage.setItem('coparent_name', resp.first_name);
                 }
            })
        );
         
        Promise.all(promises)
            .then(() => {

                let _data = {
                    user_id: localStorage.user_id,
                    household_id: obj.household_id
                };
                
                let arr = [];

                arr.push(
                    getPermission(_data)
                        .then(resp => {
                            if (resp && resp.success && resp.permission) {
                                localStorage.setItem('third_party_permission', JSON.stringify(resp.permission));
                                localStorage.setItem('calendar_permission', resp.permission.calendar);
                                localStorage.setItem('payment_permission', resp.permission.payment);
                                localStorage.setItem('chat_permission', resp.permission.chat);
                            }
                        })
                );

                Promise.all(arr)
                    .then(() => {
                        getUserSubscription().then((resp) => {
                            this.setState({ loading: false });
                            localStorage.setItem('resp', JSON.stringify(resp.data));
                        
                            if (resp.stats === true && resp.data) {
                               if (
                                   resp.data.status === 'trial' ||
                                   resp.data.status === 'live' ||
                                   resp.data.status === 'future' ||
                                   resp.data.inTrial
                               ) {
                                   return window.location.replace(ROUTES.HOME);
                               } else {
                                   return window.location.replace(ROUTES.SUBSCRIPTION);
                               }
                               } else {
                                   return window.location.replace(ROUTES.SUBSCRIPTION);
                               }
                            });  
                })
        });
     }
 
 }

    render(){
        return(
            <React.Fragment>
                <GoogleLogin
                    clientId={CLIENTID}                      
                    render={renderProps => (                       
                    <Button className="g-btn-wrap ant-btn ant-btn-primary btn-full btn-google "
                    onClick={renderProps.onClick} disabled={renderProps.disabled}
                    >
                    <span>Sign in with Google</span>
                    <img className="g-image" src={img} alt="google-logo" />
                    </Button>
                    )}
                    buttonText="Login"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    responseType='code,token'
                />
                <ConnectionErrorModal
                    handleOpen={this.state.modalVisible}
                    handleClose={() => this.setModalVisible(false)}
                />
                <ChooseUserModal
                    openModal={this.state.modal1Visible}
                    closeModal={() => this.setModal1Visible(false)}
                    selectedUser={(type) => this.handleUser(type)}
                />
                <ChooseHouseholdModal
                    openModal={this.state.modal2Visible}
                    closeModal={() => this.setModal2Visible(false)}
                    data={this.state.data}
                    selected={(obj) => this.handleThirdParty(obj)}
                />
                <SwapErrorModal
                    openModal={this.state.modal3Visible}
                    closeModal={() => this.setModal3Visible(false, '')}
                    msg={this.state.msg}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (response) => ({ response });

export default connect(mapStateToProps)(GoogleSignin);