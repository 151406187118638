import { makeRequest } from './authenticationService';

const passwordToken  = localStorage.getItem("forgotPasswordToken")
export const ResetWebUrl = `/resetpassword?token=${passwordToken}`;
export const ResetIosUrl = `familyplanapp://forgot.com/?token=${passwordToken}`;
export const ResetAndroidUrl = `intent://forgot.com/?token=${passwordToken}#Intent;scheme=familyplan;package=com.familyplanapp.familyplan;end`;

export const sendPasswordOnEmail = (request) => {
    let url = '/api/users/forgot';
    let body = { email: request.email }
    return makeRequest('POST', url, body)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const resetPassword = (request) => {
    let url = '/api/users/resetpassword'

    let body = {
        data:{
                password: request.password,
                token: localStorage.forgotPasswordToken
                }
            }

    return makeRequest('POST', url, body)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const verifiedToken = () => {
    const passwordToken  = localStorage.getItem("forgotPasswordToken")
    let url = '/api/users/verifytoken'
	
   let body = {
    token: passwordToken
    }

    return makeRequest('POST', url, body)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
}