import React, { Component } from 'react';
import 'pages/Payment/RecurringPayments/RecurringPayments.scss';
import { Card, Button, Modal, Form, Radio, Select, Input, Empty } from 'antd';
import Icon from '@ant-design/icons';
import img from 'Assets/next.png';
// import cycle from 'Assets/cycle.png';
import { CaretDownOutlined } from '@ant-design/icons';
import ScrollArea from 'react-scrollbar';
import { connect } from 'react-redux';
import { getRecurringPayments } from 'services/paymentService';
import LoadingOverlay from 'react-loading-overlay';
import RecurringModal from 'pages/Payment/RecurringPayments/RecurringModal';
import EditModal from 'pages/Payment/RecurringPayments/EditModal';
import { removeReloading } from 'actions/loaderActions';
import { getUserById } from 'services/userService';
import moment from 'moment';

const { Option } = Select;
const refreshSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 13.237 17.024">
        <path
            d="M177.543,26.108a6.5,6.5,0,0,1,6.373-7.246V17.115a.125.125,0,0,1,.2-.09l3.594,2.641a.11.11,0,0,1,0,.177l-3.59,2.641a.125.125,0,0,1-.2-.09V20.65a4.7,4.7,0,0,0-4.255,2.883,4.633,4.633,0,0,0-.364,2.058,4.686,4.686,0,0,0,1.026,2.679.9.9,0,1,1-1.41,1.109A6.41,6.41,0,0,1,177.543,26.108Zm10.376-3.355a4.645,4.645,0,0,1,.661,4.737,4.7,4.7,0,0,1-4.255,2.883V28.631a.125.125,0,0,0-.2-.09l-3.594,2.641a.11.11,0,0,0,0,.177L184.121,34a.125.125,0,0,0,.2-.09V32.162a6.5,6.5,0,0,0,6.373-7.246,6.376,6.376,0,0,0-1.369-3.271.9.9,0,1,0-1.407,1.109Z"
            transform="translate(-177.5 -17)"
        />
    </svg>
);
const RefreshIcon = (props) => <Icon component={refreshSvg} {...props} />;
function handleChange(value) {
    
}

const frequencies = {
    weekly: 'Weekly',
    'bi-weekly': 'Bi-Weekly',
    monthly: 'Monthly',
    '2-month': '2 Months',
    '3-month': '3 Months',
    '6-month': '6 Months',
    yearly: 'Yearly',
};

class RecurringPayments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            editVisible: false,
            payments: [],
            offset: 0,
            currentPayment:null,
            coparent: null,
            loading: false,
            coParent: null
        };
        this.getRecurring = this.getRecurring.bind(this);
        this.getFormattedDate = this.getFormattedDate.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        let offset = 0;
        if (localStorage.third_party && localStorage.third_party === 'true') {
            getUserById(localStorage.coparent_id)
                    .then(resp => {
                        if (resp) {
                            this.setState({ coParent: resp });
                    }
            })
            this.getRecurring(offset, localStorage.coparent_id, false);
        } else {
            this.getRecurring(offset, this.props.coparent, false);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.coparent !== prevProps.coparent) {
            let offset = 0;
            let arr = [];
            this.setState({ payments: arr });
            if (localStorage.third_party && localStorage.third_party === 'true') {
                this.getRecurring(offset, localStorage.coparent_id, true);
            } else {
                this.getRecurring(offset, this.props.coparent, true);
            }
        }
    }
 
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps && nextProps.err !== null) {
            this.setState({loading: false});
        }
        if(nextProps && nextProps.error !== null) {
            this.setState({loading: false});
        }
        if(nextProps && nextProps.reload === true){
            this.props.dispatch(removeReloading());
            let offset = 0;
            let arr = [];
            this.setState({ payments: arr });
            if (localStorage.third_party && localStorage.third_party === 'true') {
                this.getRecurring(offset, localStorage.coparent_id, true);
            } else {
                this.getRecurring(offset, this.props.coparent, true);
            }
        }
    }
    
    getRecurring = function (offset, coparent, flag) {
        const { payments } = this.state;
        if(this.props.err === null) {
           this.setState({ loading: true });
        }

        let sParams = {};

        if (localStorage.third_party && localStorage.third_party === 'true') {
            sParams = {
                offset: offset,
                limit: 15,
                orderBy: '-updated_at',
                coparent_id: coparent,
                thirdParty: true
            };
        } else {
            sParams = {
                offset: offset,
                limit: 15,
                orderBy: '-updated_at',
                coparent_id: (coparent && coparent.id) || null,
            };
        }

        this.setState({ offset: offset, coparent: coparent || null });
        getRecurringPayments(sParams).then((resp) => {
            if (resp) {
                if (resp.index !== undefined) {
                    this.setState({ offset: resp.index + 1, coparent: { id: resp.coparent } });
                } else {
                    resp.offset += 15;
                    this.setState({ offset: resp.offset, coparent: { id: resp.coparent } });
                }

                if (flag) {
                    let arr = [];
                    arr = [...arr, ...resp.payments];
                    this.setState({ payments: arr, loading: false });
                } else {
                    let arr = [];
                    arr = [...payments, ...resp.payments];
                    this.setState({ payments: arr, loading: false });
                }
            } else {
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({loading: false});
        })
    };

    refreshPage = async function () {
        let offset = 0;
        let arr = [];
        await this.setState({ payments: arr });
        if (localStorage.third_party && localStorage.third_party === 'true') {
            this.getRecurring(offset, localStorage.coparent_id, true);
        } else {
            this.getRecurring(offset, this.props.coparent, true);
        }
    };

    getFormattedDate = function (dateObj) {
        let month = dateObj.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = dateObj.getDate().toString();
        day = day.toString().length > 1 ? day : '0' + day;
        let year = dateObj.getFullYear();
        let output = month + '/' + day + '/' + year;
        return output;
    };

    setModalVisible = function(visible) {
        this.setState({ modalVisible: visible });
    }

    setModal1Visible = function(modalVisible, payment) {
        if(modalVisible == false) {
            console.log('innnn')
            this.setState({ editVisible: modalVisible, currentPayment: null });
        } else {
            this.setState({ editVisible: modalVisible, currentPayment: payment });
        }
        
    }

    handleClick = function(payment) {
        this.setModal1Visible(true, payment);
    }

    render() {
        const { payments, offset, coparent, coParent, currentPayment, loading } = this.state;
        const { user } = this.props;

        let normalUser = localStorage.third_party !== 'true';
        let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';


        return (
            <Card bordered={false} className="rp-card">
                <LoadingOverlay
                    active={loading ? true : false}
                    className={'loader-wrap cust-loader ' + (loading ? 'd-block' : '')}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <div className="rp-card-head">
                    <h1>Recurring Payments</h1>
                    <Button type="primary" onClick={() => this.setModalVisible(true)} className="btn-add-rec">
                        Add recurring request
                    </Button>
                    
                
                </div>
                <ScrollArea
                    speed={0.8}
                    className="rp-card-body"
                    onScroll={(value) => {
                        if (value.topPosition === value.realHeight - value.containerHeight && value.topPosition !== 0) {
                            if (localStorage.third_party && localStorage.third_party === 'true') {
                                this.getRecurring(offset, localStorage.coparent_id, false);
                            } else {
                                this.getRecurring(offset, coparent, false);
                            }
                        }
                    }}
                    contentClassName="content"
                    horizontal={false}
                >
                    {normalUser && user && payments && Array.isArray(payments) && payments.length !== 0 ? (
                        payments.map((payment, i) => (
                            (user.id === payment.PaymentCategory.coparent_user_id || user.id === payment.PaymentCategory.coparent_standlalone_id) && payment.PaymentCategory.category_status === 1 ? null : (
                            <Card onClick={() => this.handleClick(payment)} key={i} bordered={false} className="list-card">
                                <div className="req-inner">
                                    <div className="req-details">
                                        <div className="detail-wrap">
                                            <span>{payment.payer_username}</span>
                                            <span>
                                                <img src={img} alt="arrow-icon" />
                                            </span>
                                            <span>{payment.receiver_username}</span>
                                        </div>
                                        <span className="type">
                                            {payment.PaymentCategory && payment.PaymentCategory.name}
                                        </span>
                                    </div>
                                    <div className="req-action">
                                        <span className="img-wrap">
                                            <RefreshIcon />
                                        </span>
                                        <span>{frequencies[payment.frequency]}</span>
                                    </div>
                                    <div className="req-amount">
                                        <span>{`$${payment.payer_amount}`}</span>
                                        <span>{moment.parseZone(payment.start_date).format('MM/DD/YYYY')}</span>
                                    </div>
                                </div>
                            </Card>
                            )
                        ))
                    ) : (
                        normalUser ? (    
                        <div>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                        ): null
                    )}

                    
                    {thirdPartyUser && coParent && payments && Array.isArray(payments) && payments.length !== 0 ? (
                        payments.map((payment, i) => (
                            <Card onClick={() => this.handleClick(payment)} key={i} bordered={false} className="list-card">
                                <div className="req-inner">
                                    <div className="req-details">
                                        <div className="detail-wrap">
                                            <span>{payment.payer_username}</span>
                                            <span>
                                                <img src={img} alt="arrow-icon" />
                                            </span>
                                            <span>{payment.receiver_username}</span>
                                        </div>
                                        <span className="type">
                                            {payment.PaymentCategory && payment.PaymentCategory.name}
                                        </span>
                                    </div>
                                    <div className="req-action">
                                        <span className="img-wrap">
                                            <RefreshIcon />
                                        </span>
                                        <span>{frequencies[payment.frequency]}</span>
                                    </div>
                                    <div className="req-amount">
                                        <span>{`$${payment.payer_amount}`}</span>
                                        <span>{moment.parseZone(payment.start_date).format('MM/DD/YYYY')}</span>
                                    </div>
                                </div>
                            </Card>
                        ))
                    ) : (
                        thirdPartyUser ? (    
                        <div>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                        ): null
                    )}

                </ScrollArea>
                <RecurringModal 
                refresh={() => this.refreshPage()} 
                handleOpen={this.state.modalVisible}
                handleClose={() => this.setModalVisible(false)} 
                /> 
                <EditModal  
                currentPayment={currentPayment} 
                refresh={() => this.refreshPage()} 
                handleOpen={this.state.editVisible} 
                handleClose={() => this.setModal1Visible(false)}
                />
            </Card>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, coparent, reload } = serviceReducer;
    return { user, error, coparent, reload };
};

export default connect(mapStateToProps)(RecurringPayments);
