import React, { Component } from 'react';
import 'pages/Reports/CustodySwaps/CustodySwaps.scss';
import { Card, Button, Tabs, Modal, Select, List, Typography, Progress, Empty } from 'antd';
import { connect } from 'react-redux';
import { UpdateCustodyReport, SaveCustodyReport, GetCustodyDetail } from 'services/reportsService';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';

const { TabPane } = Tabs;
const { Option } = Select;

class SaveReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LoadingIs: false,
            custodyTimeData: null,
            type: '',
        };
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        const { responseInfo, type } = this.props;
        await this.setState({ custodyTimeData: null, LoadingIs: false });
        if (nextProps.handleOpen && responseInfo) {
            if (responseInfo.type == 'create') {
                this.setState({ custodyTimeData: responseInfo.data, type: responseInfo.type });
            } else {
                this.setState({ type: responseInfo.type });
                this.getActiveReports(responseInfo.data);
            }
        }
    };

    getActiveReports(id) {
        this.setState({ LoadingIs: true });
        GetCustodyDetail(id, (data) => {
            this.setState({ LoadingIs: false });
            this.setState({ custodyTimeData: data.data });
        });
    }

    formartData(date) {
        var newdate = moment(date, 'DD-MM-YYYY');
        return moment(newdate).format('MM/DD/YY');
    }

    GetCoparentDays(childId) {
        const { custodyTimeData } = this.state;
        let custodyByCoparent = custodyTimeData.custodyByCoparent;

        for (const child of custodyByCoparent) {
            if (child.id == childId) {
                return child.adjusted;
            }
        }
    }

    UserHeaderComponent = (name, data) => {
        if (!data) {
            return;
        }
        let totalSchedule = 0;
        let totaladjusted = 0;

        for (const row of data) {
            totalSchedule += row.scheduled;
            totaladjusted += row.adjusted;
        }

        return (
            <div className="parent-wrap">
                <div className="parent-row">
                    <span>{name}</span>
                    <span>{totalSchedule + ' Days'}</span>
                    <span> {totaladjusted + ' Days'}</span>
                    <span></span>
                </div>
            </div>
        );
    };

    ListItem = (data) => {
        const { user } = this.props;
        return data.map((row, i) => {
            const color = row.created_by_user? (row.created_by_user === (user && user.id))?row.color: row.coparent_color : row.color;
            return (
                <div className="row-item" key={i}>
                    <span>
                        <span></span>
                        <span style={{ color: color == '#ffffff' ? '#333333' : color }}>{row.name}</span>
                    </span>
                    <span>{row.scheduled + ' Days'}</span>
                    <span>{row.adjusted + ' Days'}</span>
                    <span>{row.scheduled - row.adjusted == 0 ? '' : row.adjusted - row.scheduled}</span>
                </div>
            );
        });
    };

    PercentageView = () => {
        const { custodyTimeData } = this.state;
        let custodyByUser = custodyTimeData.custodyByUser;

        return custodyByUser.map((child, i) => {
            let total = child.adjusted + this.GetCoparentDays(child.id);
            let progress = 0;
            if (total > 0) {
                progress = Math.round((child.adjusted / total) * 100);
            }
            return (
                <div key={i}>
                    <div className="child-name">{child.name}</div>
                    {progress > 0 && (
                        <div className="progress-wrap orange-bar">
                            <Progress percent={progress} status="active" showInfo={false} />
                            <span
                                style={{ left: `${progress + (100 - progress) / 2}%` }}
                                className="ant-progress-text-inactive"
                            >
                                {progress >= 99 ? null : 100 - progress + '%'}
                            </span>
                            <span style={{ left: `${progress / 2 - 1}%` }} className="ant-progress-text-active">
                                {progress}%
                            </span>
                        </div>
                    )}
                </div>
            );
        });
    };

    SaveReport = async () => {
        const { type, custodyTimeData } = this.state;
        if (type == 'create') {
            let coparent_id = custodyTimeData.user ? custodyTimeData.user.coparent_id : '';
            let child_ids = custodyTimeData.custodyByUser.map((child) => child.id);
            custodyTimeData.coparent_id = coparent_id;
            custodyTimeData.child_ids = child_ids;
            this.setState({ LoadingIs: true });
            let response = await SaveCustodyReport(custodyTimeData);
            if (response) {
                this.props.handleAllClose();
            }
        } else {
            let data = {};
            data.id = custodyTimeData.id;
            data.archived = true;
            this.setState({ LoadingIs: true });
            let response = await UpdateCustodyReport(data);
            this.setState({ LoadingIs: false });
            if (response) {
                this.props.handleRefreshClose();
            }
        }
    };

    render() {
        const { custodyTimeData, type, LoadingIs } = this.state;
        let Userkeys = [];
        let Coparentkeys = [];
        let noData = true;

        if (custodyTimeData && custodyTimeData.custodyByUser) {
            Userkeys = Object.keys(custodyTimeData.custodyByUser);
        }
        if (custodyTimeData && custodyTimeData.custodyByCoparent) {
            Coparentkeys = Object.keys(custodyTimeData.custodyByCoparent);
        }
        if (Userkeys.length == 0 && Coparentkeys.length == 0) {
            noData = true;
        } else {
            noData = false;
        }

        return (
            <div>
                <Modal
                    title="Custody Time Ratio"
                    centered
                    className="report-data-modal"
                    maskClosable={false}
                    visible={this.props.handleOpen}
                    onCancel={this.props.handleClose}
                >
                    <LoadingOverlay
                        active={LoadingIs}
                        className={LoadingIs ? 'd-block pop-loader' : 'd-none'}
                        spinner
                        styles={{
                            spinner: (base) => ({
                                ...base,
                                width: '100px',
                                '& svg circle': {
                                    stroke: 'rgb(56, 160, 235)',
                                },
                            }),
                        }}
                    ></LoadingOverlay>
                    {custodyTimeData && (
                        <div>
                            <div className="modal-top">
                                <span>
                                    {'' +
                                        this.formartData(custodyTimeData.startDate) +
                                        ' - ' +
                                        this.formartData(custodyTimeData.endDate)}
                                </span>
                                <span>{custodyTimeData.periodDuration + ' Days'}</span>
                            </div>
                            <div className="modal-body">
                                <div className="ratio-wrap">
                                    {noData ? (
                                        <div>
                                            {' '}
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="ratio-head">
                                                <span></span>
                                                <span>Scheduled</span>
                                                <span>Adjusted</span>
                                                <span>+/-</span>
                                            </div>
                                            {custodyTimeData.user &&
                                                this.UserHeaderComponent(
                                                    custodyTimeData.user.name,
                                                    custodyTimeData.custodyByUser,
                                                )}
                                            {this.ListItem(custodyTimeData.custodyByUser)}
                                            {custodyTimeData.user &&
                                                this.UserHeaderComponent(
                                                    custodyTimeData.user.coparentName,
                                                    custodyTimeData.custodyByCoparent,
                                                )}
                                            {this.ListItem(custodyTimeData.custodyByCoparent)}
                                        </div>
                                    )}
                                </div>
                                {!noData && (
                                    <div className="stat-wrap">
                                        <div bordered={'false'} className="stat-card">
                                            <div className="title">
                                                <span>{custodyTimeData.user ? custodyTimeData.user.name : ''}</span>
                                            </div>
                                            <div className="stat-card-body">{this.PercentageView()}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {type != 'archive' && !noData && (
                                <div className="btn-save-wrap">
                                    <Button
                                        className="fp-button-secondary create-btn"
                                        onClick={() => this.SaveReport()}
                                    >
                                        {type == 'create' ? 'Save Report' : 'Archive Report'}
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, error, coparent, coparents } = serviceReducer;
    return { children, user, error, coparent, coparents };
};

export default connect(mapStateToProps)(SaveReport);
