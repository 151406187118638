import React, { Component } from 'react';
import { Button, Modal, Form, Select, Input, Col, Checkbox, label } from 'antd';
import { addCoparent, editCoparent } from '../../../services/houseHoldService';
import { getHouseHoldAction } from 'actions/houseHoldAction.js';
import { getMeUserAction, getChildrenUserAction ,getCoParentsUserAction} from 'actions/userActions';
import { checkEmail, createInvitation } from '../../../services/subscriptionService';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { checkCookie } from 'utils/cookies';
import { ROUTES } from 'constants/routes';

const { Option } = Select;
const validator = require('email-validator');
 
class AddCoparent extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: '',
            email: '',
            gender: 'Select Gender',
            LoadingIs: false,
            errorIs: { name: '', email: '', gender: '' },
            resMsg: '',
            checked: true,
            disabled: false,
            chekbox: true,
            userName: '',
            addCoparent: props.addCoparent,
            isThirdParty : localStorage.getItem("third_party"),
            responseErr: false,
        };
        this.handleCheckBox = this.handleCheckBox.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.validation = this.validation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.resetField = this.resetField.bind(this);
        this.isCoparentInvited = this.isCoparentInvited.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleErrorModal = this.handleErrorModal.bind(this);
    }


    handleCheckBox = () => {
        const { chekbox } = this.state;
        this.setState({
            chekbox: !chekbox,
        });
    };

    handleModal = (modalVisible) => {
        //this.setState({addCoparent: false})
        this.setState({
            name: '',
            email: '',
            gender: 'Select Gender',
            addCoparent : false,
            errorIs: { name: '', email: '', gender: '' },
        });
        this.props.handleSimpleClose();
        //this.resetField();
    };

    handleShareCode = () => {
        const { shareCode } = this.state;
        this.setState({
            shareCode: !shareCode,
        });
    };


    handleOnChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
        this.setState({
            checked: event.target.checked,
        });
        this.validation(name, value);
    };

    validation = (name, value) => {
        const { errorIs } = this.state;
        switch (name) {
            case 'name':
                errorIs.name = !value.length ? 'Name is required!' 
                               : !(value.length > 1) 
                               ? 'Name is Too short (Must be at Least 2 character)' 
                               : (value.length > 50) 
                               ? 'Name is Too Long (Must be 50 characters)'
                               : '';
                break;
            case 'email':
                if (!value.length) {
                    errorIs.email = 'Email is required!';
                } else if (!validator.validate(value)) {
                    errorIs.email = 'Invalid Email';
                } else {
                    errorIs.email = '';
                }
                break;
            case 'gender':
                errorIs.gender = value === 'Select Gender' ? 'Gender is required!' : '';
                break;
            default:
                break;
        }

        return errorIs;
    };

    handleChange = (value) => {
        const { errorIs } = this.state;
        errorIs.gender = value === 'Select Gender' ? 'Gender is required!' : '';
        this.setState({ gender: value, errorIs });
    };

    validateForm = () => {
        const { name, email, gender, errorIs } = this.state;
        errorIs.name = !name.length ? 'Name is required!': !(name.length > 1) ? 'Name is Too short (Must be at Least 2 character)' :(name.length > 50) ? 'Name is Too Long (Must be 50 characters)'  : '';
        errorIs.email = !email.length ? 'Email is required!' : !validator.validate(email) ? 'Invalid Email' : '';
        errorIs.gender = gender === 'Select Gender' ? 'Gender is required!' : '';
        this.setState({ errorIs });
        return !errorIs.name && !errorIs.email && !errorIs.gender;
    }

    resetField = () => {
        this.setState({
            name: '',
            email: '',
            gender: 'Select Gender',
            addCoparent : false,
            registeredCoparent : true,
            responseErr: false,
            errorIs: { name: '', email: '', gender: '' },
        });
    };

    checkRegUser = () => {
        const { email, errorIs } = this.state;
        const { response } = this.props;
        const user = response.serviceReducer.user;
        if(email != '' && errorIs.email == '') {
            this.setState({ LoadingIs: true });
            checkEmail(email).then((res) => {
                if (res) {
                    if(res.status)
                        this.setState({registeredCoparent : false ,LoadingIs: false,shareCode : true})
                    else
                         this.setState({registeredCoparent : true,LoadingIs: false})
                } else {
                    this.setState({registeredCoparent : true,LoadingIs: false})
                }
            }); 
        }
       
    }

    componentDidMount = async function() {
         if(this.state.isThirdParty == 'false') {
            await this.props.dispatch(getHouseHoldAction());
         }
        
        let user = this.props.response.serviceReducer.user;
        await this.setState({ userName: user !== null ? user.first_name : '' });
    }
    componentWillMount() {
    }
    componentDidUpdate() {
    }

    isCoparentInvited = async(email, name) => {

        return new Promise(resolve => {
            let res = false;
            const { response } = this.props;
            const coparents = response.serviceReducer.coparents;
            
            for(let i = 0;i < coparents.length; i++) {
                let cp = coparents[i];
                if(cp.email === email && cp.name !== name){
                    res = true;
                    break;
                }
            }

            resolve(res);
        })
        
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, gender, chekbox, userName ,childPage,shareCode} = this.state;
        const { response } = this.props;
        const user = response.serviceReducer.user;
        const houseHoldData = response.houseHoldReducer.houseHold;
        if (this.validateForm()) {
            this.setState({ LoadingIs: true });
            if (typeof houseHoldData === 'object') {
                let householdId = houseHoldData.id;
                let sender_id = houseHoldData.user_id;
                const data = {
                    household_id: householdId,
                    sender_id: sender_id,
                    receiver_email: email,
                    gender: gender,
                    receiver_name: name,
                    email_text: `You are invited by ${this.state.userName} to join Kincrew. Use the link below to get started.`,
                    notify: chekbox,
                    userName: this.state.userName
                };

                if(chekbox && shareCode) {
                    this.createInvitation(data);
                } else {
                    this.saveCoparent(data);
                }

                // addCoparent(data).then((res) => {
                //     if (res) {
                //         if(res.msg || res.err){
                //             this.setState({ LoadingIs: false, resMsg: res.msg || res.err });
                //         } else {
                //             this.setState({ LoadingIs: false, resMsg: 'Co-parent added!' || res.err});
                //             if(!res.err && res.is_registered && res.hasOwnProperty('receiver_id')) {
                //                 this.setState({id : res.receiver_id , reg : res.is_registered})
                //             } else if(!res.err && res.hasOwnProperty('id')) {
                //                 this.setState({id : res.id, reg : res.is_registered })
                //             }
                            
                //         }
                //     } else {
                //         this.setState({ LoadingIs: false});
                //     } 
                // });
            }
        }
    };

    createInvitation = (data) => {
        const { name, email, gender, chekbox, userName, shareCode, flag } = this.state;
         this.setState({responseErr : false})
         createInvitation(this.state.email).then((res) => {
            if (res && res.data) {
                            
            data.email_text = `You are invited by ${this.state.userName} to join Kincrew. Use the link below to get started. You will need to enter the coupon code <b>${res.data.coupon_code}</b> in the Subscription Order Summary when signing up to access your 1-year prepaid account and to be a coparent linked to ${this.state.userName}'s household.`;
                                
            this.saveCoparent(data);
                } else {
                    this.setState({LoadingIs: false ,resMsg:res.error ,responseErr : true})
                }
            });
    }

    saveCoparent = (data) => {
        this.setState({responseErr : false})
       addCoparent(data).then( (res) => {
                if (res) {
                        if(res.msg || res.err){
                            this.setState({ LoadingIs: false, resMsg: res.msg || res.err });
                        } else {
                            this.setState({ LoadingIs: false, resMsg: 'Co-parent added!' || res.err});
                            if(!res.err && res.is_registered && res.hasOwnProperty('receiver_id')) {
                                this.setState({id : res.receiver_id , reg : res.is_registered})
                            } else if(!res.err && res.hasOwnProperty('id')) {
                                this.setState({id : res.id, reg : res.is_registered })
                            }
                            
                        }
                    } else {
                        this.setState({ LoadingIs: false});
                    }
        }); 
                
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        const { data, response } = nextProps;
        const user = response.serviceReducer.user;
            this.setState({
                name: '',
                email: '',
                gender: 'Select Gender',
                LoadingIs: false,
                errorIs: { name: '', email: '', gender: '' },
                resMsg: '',
                checked: true,
                disabled: false,
                chekbox: true,
                userName: user && user.first_name,
                addCoparent: nextProps.addCoparent,
                registeredCoparent : true,
                id : '',
                reg : '',
                responseErr: false,
            })
       
    }

    handleErrorModal = async () => {
        this.setState({ resMsg: '' });
        if(!this.state.responseErr) {
            this.setState({addCoparent: false})
            this.props.dispatch(getHouseHoldAction());
            //this.setState({addCoparent: false})
            await this.props.dispatch(getCoParentsUserAction());
            //this.props.handleFunc({ addCoparent: false});
            if(this.state.id != '')
            this.props.refreshCoparent(this.state.id,this.state.reg); 
        }
        
    };

    render() {
        const { name, email, gender, errorIs, LoadingIs, resMsg, chekbox, addCoparent,registeredCoparent } = this.state;
        const { modalVisible, editIs, response } = this.props;
        const user1 = response.serviceReducer.user;
        return (
            <React.Fragment>
                {resMsg && (
                    <Modal
                        centered
                        visible={true}
                        backdrop="static"
                        height={200}
                        className="modal-container req-card"
                        maskClosable={false}
                        onCancel={() => this.handleErrorModal(false)}
                    >
                        <div className="modal-head">
                            <div style={{textAlign: 'center', width: '80%', margin: 'auto', fontWeight: '700', marginBottom: '15px'}}>
                                {resMsg}
                            </div>
                            <Button type="primary" style={{width: '80%', marginTop: '10px', border: 'none', borderRadius: '4px' }} onClick={() => this.handleErrorModal(false)} className="btn fp-button-primary">
                               Ok
                            </Button>
                        </div>
                    </Modal>
                )}
                <Modal
                    centered
                    visible={addCoparent}
                    backdrop="static"
                    maskClosable={false}
                    className="modal-container req-card add-coparent"
                    onCancel={() => this.handleModal(false)}
                >
                    <LoadingOverlay
                        active={LoadingIs}
                        className="d-block pop-loader"
                        spinner
                        styles={{
                            spinner: (base) => ({
                                ...base,
                                width: '100px',
                                '& svg circle': {
                                    stroke: 'rgb(56, 160, 235)',
                                },
                            }),
                        }}
                    ></LoadingOverlay>
                    <div className="modal-head">
                        <span>{editIs ? 'Edit Co-parent' : 'Add Co-parent'}</span>
                    </div>
                    <Form onSubmit={editIs ? (e) => this.handleEditCoparent(e) : (e) => this.handleSubmit(e)}>
                        <Col className="col-wrap">
                            <div className={`input-full ${errorIs.name ? 'err-msg' : ''}`}>
                                <Input
                                    placeholder="Enter Name"
                                    name="name"
                                    value={name}
                                    onChange={this.handleOnChange}
                                    autoComplete="nope"
                                    disabled={editIs ? 'disabled' : ''}
                                />
                                {errorIs.name ? <div className="form-err-coparent">{errorIs.name}</div> : null}
                            </div>
                        </Col>
                        <Col className="col-wrap">
                            <div className={`input-full ${errorIs.email ? 'err-msg' : ''}`}>
                                <Input
                                    placeholder="Email address"
                                    name="email"
                                    value={email}
                                    onChange={this.handleOnChange}
                                    onBlur={!editIs && user1 && user1 !== null && user1.is_contracted ? this.checkRegUser : null}
                                    autoComplete="nope"
                                />
                                {errorIs.email ? <div className="form-err-coparent">{errorIs.email}</div> : null}
                            </div>
                        </Col>
                        <Col className="col-wrap">
                            <div className="select-wrap input-full">
                                <Select
                                    name="gender"
                                    value={gender}
                                    onChange={this.handleChange}
                                    disabled={editIs ? 'disabled' : ''}
                                >
                                    <Option value="male">Male</Option>
                                    <Option value="female">Female</Option>
                                    <Option value="nonbinary">Non Binary</Option>
                                </Select>
                                {errorIs.gender ? <div className="form-err-coparent">{errorIs.gender}</div> : null}
                            </div>
                        </Col>
                        <Col>Invite memo:
                            <div className="cust-textbox">
                                You are invited by {user1 !== null ? user1.first_name : ''} to join Kincrew. Use the
                                link below to get started.
                            </div>
                        </Col>
                        <Col>
                            <Checkbox
                                style={{ marginTop: '5px' }}
                                checked={this.state.chekbox}
                                onChange={this.onChange}
                                name="chekbox"
                                className="cust-cb"
                                onClick={this.handleCheckBox}
                            >
                                Notify the Co-parent
                            </Checkbox>
                        </Col>
                         {!registeredCoparent && chekbox && !editIs && (
                            <Col>
                            <Checkbox
                                style={{ marginTop: '5px' }}
                                checked={this.state.shareCode}
                                onChange={this.onChange}
                                name="shareCode"
                                className="cust-cb"
                                onClick={this.handleShareCode}
                            >
                                Share your coupon with this Co-parent 
                            </Checkbox>
                            </Col>
                        )}
                        <div className="full-btn">
                            <Button
                                type="primary"
                                className="btn-modal"
                                htmlType="submit"
                                onClick={editIs ? (e) => this.handleEditCoparent(e) : (e) => this.handleSubmit(e)}
                            >
                                {editIs ? 'Update' : 'Save Co-parent'}
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (response) => ({ response });
export default connect(mapStateToProps)(AddCoparent);
