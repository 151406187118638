import { makeRequest } from './authenticationService';


export const setupCategory = (options) => {
    
    let url = '/api/payment/category';
    
    
    return makeRequest('POST', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const getUpdatedActionDispute = (userId, categoryId) => {
    
    let url = '/api/payment/category/getDefault/' + userId + '?categoryId=' + categoryId;


    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const setupPaymentAddDispute = (params, userId) => {
    
    let url = '/api/payment/category/addDefault/' + userId;

    return makeRequest('PUT', url, params)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const updateCategory = (options, categoryId) => {
    
    let url = '/api/payment/category/' + categoryId;
    
    return makeRequest('PUT', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const setupSpecialCategory = (options) => {
    
    let url = '/api/payment/category';
    
    return makeRequest('POST', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const updateSpecialCategory = (options, coparentId) => {
    
    let url = '/api/payment/category/addDefault/' + coparentId;
    
    return makeRequest('PUT', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}