import React, { Component } from 'react';
import 'pages/Reports/CustodySwaps/CustodySwaps.scss';
import { Card, Button, Tabs, Modal, Select, List, Typography, DatePicker } from 'antd';
import { connect } from 'react-redux';
import { CreateCustodyReport } from 'services/reportsService';
import moment, { duration } from 'moment';
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import SaveReport from 'pages/Reports/TimeRatio/SaveReport';

const { TabPane } = Tabs;
const { Option } = Select;
const dateDropwnData = [
    { id: 1, name: 'Last 7 days' },
    { id: 2, name: 'Last 14 days' },
    { id: 3, name: 'Last 3 months' },
    { id: 4, name: 'Last 6 months' },
    { id: 5, name: 'Last 12 months' },
    { id: 6, name: 'Custom' },
];
const colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    gradient1: 'Gradient1',
    gradient2: 'Gradient2',
};

function callback(key) {
    
}
var d = new Date();
var utc = d.getTime();
class CreateCustodyRatioModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal1Visible: false,
            saveReportVisible: false,
            loading: false,
            coParent: null,
            child_ids: [],
            kids: [],
            selectedDate: null,
            startdate: new Date(utc),
            enddate: new Date(utc),
            flag: false,
            saveData: '',
            errors: {
                coparent: '',
                kids: '',
                selectedDate: '',
                startdate: '',
                enddate: '',
            },
        };
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.handleCoparent = this.handleCoparent.bind(this);
        this.getChildren = this.getChildren.bind(this);
        this.handleChilds = this.handleChilds.bind(this);
        this.getName = this.getName.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.handleDate = this.handleDate.bind(this);
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        if (nextProps.handleOpen) {
            let errors = {
                coparent: '',
                kids: '',
                selectedDate: '',
                startdate: '',
                enddate: '',
            };

            this.setState({
                coParent: null,
                child_ids: [],
                kids: [],
                selectedDate: null,
                startdate: new Date(utc),
                enddate: new Date(utc),
                flag: false,
                errors: errors,
            });
        }
    };

    handleEndField = async (e) => {
        if (e && e._d) {
            await this.setState({ enddate: this.formatDate(e._d) });
            this.endDateValidation();
        }
    };

    handleStartField = async (e) => {
        if (e && e._d) {
            await this.setState({ startdate: this.formatDate(e._d) });
            this.endDateValidation();
        }
    };

    async endDateValidation() {
        const { selectedDate, startdate, enddate } = this.state;
         let Dateerrors = '';
        if (startdate !== null && enddate !== null) {
            if (this.formatDate(startdate) === this.formatDate(enddate)) {
                Dateerrors = 'End date must be more than start date!';
            } else if (this.formatDate(enddate) < this.formatDate(startdate)) {
                Dateerrors = 'End date must be more than start date!';
            }
        }
        await this.setState({
            errors: { ...this.state.errors, enddate: Dateerrors }
        });
    }

    disabledDate = (current) => {
        return current && current.valueOf() > Date.now();
    };

    formatDate = (dateObj) => {
        let date = new Date(dateObj);
        let month = date.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = date.getFullYear();
        let output = parseInt(year.toString()) + '-' + month + '-' + day;
        return output;
    };

    validate = async function () {
        const { flag, coParent, child_ids, kids, selectedDate, startdate, enddate } = this.state;

        let errors = {};

        if (coParent === null) {
            errors.coParent = 'Field is required!';
        } else {
            errors.coParent = '';
        }

        // if (kids.length === 0) {
        //     if (this.state.errors.kids.length !== 0) {
        //         errors.kids = this.state.errors.kids;
        //     } else {
        //         errors.kids = 'Field is required!';
        //     }
        // } else {
        //     if (this.state.errors.kids) {
        //         errors.kids = this.state.errors.kids;
        //     } else {
        //         errors.kids = '';
        //     }
        // }

        // if (this.state.child_ids.length === 0) {
        //     errors.kids = 'Field is required!';
        // } else {
        //     errors.kids = '';
        // }

        if (selectedDate === null) {
            errors.selectedDate = 'Field is required!';
        } else {
            errors.selectedDate = '';
            if (selectedDate === 6) {
                if (startdate === null) {
                    errors.startdate = 'Field is required!';
                } else {
                    errors.startdate = '';
                }
                if (enddate === null) {
                    errors.enddate = 'Field is required!';
                } else {
                    errors.enddate = '';
                }

                if (startdate !== null && enddate !== null) {
                    if (this.formatDate(startdate) === this.formatDate(enddate)) {
                        errors.enddate = 'End date must be more than start date!';
                    } else if (this.formatDate(enddate) < this.formatDate(startdate)) {
                        errors.enddate = 'End date must be more than start date!';
                    }
                }
            }
        }

        if (selectedDate !== 6) {
            if (errors.coParent === '' && errors.selectedDate === '') {
                this.handleSubmit();
            } else {
                await this.setState({ errors: errors });
            }
        } else {
            if (
                errors.coParent === '' &&
                errors.selectedDate === '' &&
                errors.startdate === '' &&
                errors.enddate === ''
            ) {
                this.handleSubmit();
            } else {
                await this.setState({ errors: errors });
            }
        }
    };

    getName = function (val) {
        let res = '';
        dateDropwnData.forEach((item) => {
            if (item.id === val) {
                res = item.name;
            }
        });
        return res;
    };

    handleChilds = async function (value) {
        if (value.length === 0) {
            await this.setState({
                child_ids: value,
               // errors: { ...this.state.errors, kids: 'You have to select atleast one item' },
            });
        } else {
            await this.setState({ child_ids: value });
           // await this.setState({ child_ids: value, errors: { ...this.state.errors, kids: '' } });
        }
    };

    getChildren = async function (coparent) {
        const { user, children, coparents } = this.props;
        const { errors } = this.state;
        let arr = [];
        children.forEach((child) => {
            if (child.coparent_user_id) {
                if (
                    (child.created_by_user === user.id && child.coparent_user_id === coparent.id) ||
                    (child.created_by_user === coparent.id && child.coparent_user_id === user.id)
                ) {
                    arr.push(child);
                }
            } else {
                if (child.coparent_standalone_id) {
                    coparents.forEach(cp => {
                        if(cp.name !== 'Household'){
                            if(
                                cp.id === coparent.id && (
                                child.coparent_standalone_id === cp.id ||
                                cp.invitationsIds && cp.invitationsIds.includes(child.coparent_standalone_id))
                            ){
                                arr.push(child);
                            }
                        }
                    })
                }
            }
        });

        // let error = '';
        // if (errors.kids.length !== 0 && arr.length !== 0) {
        //     error = 'Field is required!';
        // } else if (arr.length === 0) {
        //     error = 'No Children In List! (Add Child First)';
        // }
        //await this.setState({ kids: arr, errors: { ...this.state.errors, kids: error } });
        await this.setState({ kids: arr });
    };

    handleCoparent = async function (value) {
        const { coparents } = this.props;
        let coparent = {};
        coparents.forEach((c) => {
            if (c.id === value) {
                coparent = c;
            }
        });
        await this.setState({
            coParent: coparent,
            child_ids: [],
            category: null,
            errors: { ...this.state.errors, coParent: '' },
        });

        this.getChildren(coparent);
    };

    setModal1Visible = function (visible) {
        this.setState({ modal1Visible: visible });
    };

    setSaveModalVisible(modalVisible) {
        this.setState({ saveReportVisible: modalVisible });
    }

    handleAllClose() {
        this.setState({ saveReportVisible: false });
        this.props.handleRefreshClose();
    }

    handleDate = async function (value) {
        let start = null;
        let end = null;

        if(value != '') {
            await this.setState({
                 errors: { ...this.state.errors, selectedDate: '' },
            });
        }

        if (value == 1) {
            start = new Date(moment().subtract(7, 'days').add(1, 'days'));
            end = new Date(new Date());
        } else if (value == 2) {
            start = new Date(moment().subtract(14, 'days').add(1, 'days'));
            end = new Date(new Date());
        } else if (value == 3) {
            start = new Date(moment().subtract(3, 'month').add(1, 'days'));
            end = new Date(new Date());
        } else if (value == 4) {
            start = new Date(moment().subtract(6, 'month').add(1, 'days'));
            end = new Date(new Date());
        } else if (value == 5) {
            start = new Date(moment().subtract(12, 'month').add(1, 'days'));
            end = new Date(new Date());
        } else if (value == 6) {
            start = new Date(new Date());
            end = new Date(new Date());
        }

        this.setState({
            selectedDate: value,
            startdate: start,
            enddate: end,
        });
    };

    handleSubmit = async function () {
        await this.setState({ loading: true });
        const { coParent, child_ids, startdate, enddate } = this.state;

        let options = {
            coparent_id: coParent.id,
            startDate: Moment(startdate).format('DD-MM-YYYY'),
            endDate: Moment(enddate).format('DD-MM-YYYY'),
            child_ids: child_ids,
        };
        CreateCustodyReport(options).then((resp) => {
            resp.data.startDate = moment(startdate).format('DD-MM-YYYY');
            resp.data.endDate = moment(enddate).format('DD-MM-YYYY');
            this.setState({ loading: false });
            let responseData = {
                data: resp.data,
                type: 'create',
            };
            this.setState({ saveData: responseData }, () => this.setSaveModalVisible(true));
        });
    };

    render() {
        const { coParent, child_ids, kids, selectedDate, startdate, enddate, errors, loading } = this.state;
        const { coparents, children, user } = this.props;
        
        function getColor(item){
            return (item.created_by_user === (user && user.id))?item.color: item.coparent_color;
        }
        return (
            <div>
                <Modal
                    title="Custody Time Ratio"
                    centered
                    className="report-modal"
                    maskClosable={false}
                    visible={this.props.handleOpen}
                    onOk={this.props.handleClose}
                    onCancel={this.props.handleClose}
                >
                    <LoadingOverlay
                        active={loading}
                        className={loading ? 'd-block pop-loader' : 'd-none pop-loader'}
                        spinner
                        styles={{
                            spinner: (base) => ({
                                ...base,
                                width: '100px',
                                '& svg circle': {
                                    stroke: 'rgb(56, 160, 235)',
                                },
                            }),
                        }}
                    ></LoadingOverlay>
                    <div className="modal-body">
                        <form>
                            {/* Select coparent */}
                            <Select
                                value={coParent === null ? 'Select Co-parent' : coParent.name}
                                onChange={this.handleCoparent}
                                className="full-select"
                            >
                                {Array.isArray(coparents) &&
                                    coparents.map(
                                        (coparent, i) =>
                                            coparent.name !== 'Household' && (
                                                <Option
                                                    key={i}
                                                    className={
                                                        coParent && coParent.id === coparent.id ? 'text-color' : ''
                                                    }
                                                    value={coparent.id}
                                                >
                                                    {coparent.name}
                                                </Option>
                                            ),
                                    )}
                            </Select>
                            {errors.coParent !== '' ? <div className="form-err">{errors.coParent}</div> : null}

                            {/* Select child */}
                            {kids.length === 0 ? (
                                <Select value="Select child" className="full-select">
                                    <Option>No options</Option>
                                </Select>
                            ) : (
                                <Select
                                    placeholder="Select child"
                                    value={child_ids}
                                    className="full-select"
                                    mode="multiple"
                                    onChange={this.handleChilds}
                                >
                                    {Array.isArray(kids) &&
                                        kids.map((kid, i) => (
                                            <Option
                                                key={i}
                                                className={child_ids.includes(kid.id) ? 'text-color' : ''}
                                                value={kid.id}
                                            >
                                                <span className={'dot ' + colors[getColor(kid)]}></span>
                                                {kid.name}
                                            </Option>
                                        ))}
                                </Select>
                            )}

                           {/*{errors.kids !== '' ? <div className="form-err">{errors.kids}</div> : null} */}

                            {/* Select Date */}
                            <Select
                                value={selectedDate === null ? 'Select Date' : this.getName(selectedDate)}
                                className="full-select"
                                onChange={(value) => {
                                    this.handleDate(value);
                                }}
                            >
                                {Array.isArray(dateDropwnData) &&
                                    dateDropwnData.map((item, i) => (
                                        <Option
                                            key={i}
                                            className={item.id === selectedDate ? 'text-color' : ''}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                            {errors.selectedDate !== '' ? <div className="form-err">{errors.selectedDate}</div> : null}
                            {selectedDate && selectedDate == 6 && (
                                <div className="date-picker-wrapper">
                                    <div className="date-picker-inner">
                                        <span className="dp-label">Start Date</span>
                                        <DatePicker
                                            className="cust-dp"
                                            inputReadOnly={true}
                                            value={Moment(this.state.startdate)}
                                            placeholder="Start Date"
                                            size="large"
                                            onChange={this.handleStartField}
                                        />
                                        {errors.startdate !== '' ? (
                                            <div className="form-err">{errors.startdate}</div>
                                        ) : null}
                                    </div>
                                    <div className="date-picker-inner">
                                        <span className="dp-label">End Date</span>
                                        <DatePicker
                                            className="cust-dp"
                                            inputReadOnly={true}
                                            disabledDate={this.disabledDate}
                                            value={Moment(this.state.enddate)}
                                            placeholder="End Date"
                                            size="large"
                                            onChange={this.handleEndField}
                                        />
                                        {errors.enddate !== '' ? (
                                            <div className="form-err">{errors.enddate}</div>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                            <Button className="fp-button-secondary create-btn" onClick={this.validate}>
                                Create Report
                            </Button>
                        </form>
                    </div>
                </Modal>
                <SaveReport
                    handleOpen={this.state.saveReportVisible}
                    handleClose={() => this.setSaveModalVisible(false)}
                    handleAllClose={() => this.handleAllClose()}
                    responseInfo={this.state.saveData}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, loadings, error, coparent, coparents } = serviceReducer;
    return { children, user, loadings, error, coparent, coparents };
};

export default connect(mapStateToProps)(CreateCustodyRatioModal);
