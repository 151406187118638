import React, { Component } from 'react';
import 'pages/CalenderInner/Events/Events.scss';
import { Card, Button, Empty, Popover, Modal } from 'antd';
import { getAllEvents, deleteEvents, editEvents } from 'services/calendarService';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import EventDetailsModal from 'pages/CalenderInner/Events/EventDetails';
import UpdateEventModal from 'pages/CalenderInner/Events/UpdateEventModal';
import CreateEventModal from 'pages/CalenderInner/Events/CreateEventModal';
import DeleteEventModal from 'pages/CalenderInner/Events/DeleteEventModal';
import moment from 'moment';

const colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    'gradient1': 'Gradient1',
    'gradient2': 'Gradient2',
};

const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

class Events extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            modal2Visible: false,
            modal3Visible: false,
            modal4Visible: false,
            modal5Visible: false,
            deleteModalVisible: false,
            errorModalVisible: false,
            deletErr: false,
            offset: 0,
            coparent: null,
            events: [],
            dates: [],
            loading: false,
            currentEvent: null,
            kids: [],
            selectedEditOption: '',
            last_rec_date: new Date(),
            isVisible: false,
            selected: 0,
            hide: false,
            isThirdParty: localStorage.getItem("third_party"),
            today: new Date(),
            remainingDates: [],
            allDates: [],
            index: 0,
            clickedDate: null,
            reccStartDate: null,
            msg: '',
            showMsg: false
        };
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.setModal3Visible = this.setModal3Visible.bind(this);
        this.getCalendarEvents = this.getCalendarEvents.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.getDate = this.getDate.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.setDeleteModalVisible = this.setDeleteModalVisible.bind(this);
        this.setErrorModalVisible = this.setErrorModalVisible.bind(this);
        this.handlePop = this.handlePop.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.closeUpdate = this.closeUpdate.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount() {
        let offset = 0;
        this.getCalendarEvents(offset, this.props.coparent, true);
    }

    componentDidUpdate(prevProps) {
        if (this.props.coparent !== prevProps.coparent) {
            let offset = 0;
            let arr = [];
            let darr = [];
            this.setState({ events: arr, dates: darr, selectedEditOption: '', last_rec_date: new Date(), selected: 0, index: 0, allDates: [] });
            this.getCalendarEvents(offset, this.props.coparent, true);
        }
    }

    getCalendarEvents = function (offset, coparent, flag) {
        const { events, dates, isThirdParty, remainingDates } = this.state;
        this.setState({ loading: true });
        let today = moment.parseZone(new Date()).format('YYYY-MM-DD');
        const params = {
            type: 'event',
            offset: offset,
            limit: 15,
            coparent_id: (coparent && coparent.id) || null,
            upper_cut_date: today,
        };
        if (isThirdParty == 'true') {
            params.coparent_id = localStorage.getItem("coparent_id");
            params.third_party = true;
        }

        this.setState({ offset: offset, coparent: coparent || null });
        getAllEvents(params).then(async (resp) => {
            if (resp) {
                resp.offset += 15;
                this.setState({ offset: resp.offset, coparent: { id: resp.coparent } });
                let currentDate = new Date()

                if (flag) {
                    let arr = [];
                    let darr = [];
                    arr = [...arr, ...resp.events];
                    for (let i = 0; i < arr.length; i++) {
                        let event = arr[i];
                        if (darr.includes(moment.parseZone(event.recurring_flag ? event.recurring_start_date : event.start_date).format('YYYY-MM-DD'))) { }
                        else {
                            let eventDate = event.recurring_flag ? event.recurring_start_date : event.start_date;
                            if (moment.parseZone(eventDate).format('YYYY-MM-DD') >= moment.parseZone(currentDate).format('YYYY-MM-DD'))
                                darr.push(moment.parseZone(eventDate).format('YYYY-MM-DD'));
                        }
                    }
                    let sortedListDates = darr.sort((a, b) => moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD'))
                    //this.setState({ events: arr, dates: darr, loading: false,allDates : darr});
                    this.setState({ events: arr, loading: false, allDates: darr });
                    let filteredrecord = await this.getSevenDaysRecord();


                } else {
                    let arr = [];
                    let darr = [];
                    arr = [...events, ...resp.events];
                    darr = [...darr, ...dates];
                    for (let i = 0; i < arr.length; i++) {
                        let event = arr[i];
                        if (darr.includes(moment.parseZone(event.recurring_flag ? event.recurring_start_date : event.start_date).format('YYYY-MM-DD'))) { }
                        else {
                            let eventDate = event.recurring_flag ? event.recurring_start_date : event.start_date;
                            if (moment.parseZone(eventDate).format('YYYY-MM-DD') >= moment.parseZone(currentDate).format('YYYY-MM-DD'))
                                darr.push(moment.parseZone(eventDate).format('YYYY-MM-DD'));
                        }
                    }

                    let sortedListDates = darr.sort((a, b) => moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD'))
                    //this.setState({ events: arr, dates: darr, loading: false,allDates : darr});
                    this.setState({ events: arr, loading: false, allDates: darr });
                    let filteredrecord = await this.getSevenDaysRecord();

                }

            } else {
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({ loading: false });
        })
    };

    refreshPage = function () {
        let offset = 0;
        let arr = [];
        let darr = [];
        this.setState({ events: arr, dates: darr, index: 0, allDates: [], msg: '', showMsg: false })
        this.getCalendarEvents(offset, this.props.coparent, true);
    };


    getSevenDaysRecord = async function () {
        let seventDays
        const { dates, allDates, index } = this.state;
        seventDays = allDates;

        await this.setState((prevState) => {
            if (prevState.dates.length > 0) {
                if (seventDays.length < 7) {
                    this.setState({ dates: [...prevState.dates, ...seventDays], allDates: [] }) //reached end of the record
                } else {
                    this.setState({ dates: [...prevState.dates, ...seventDays.splice(index, 7)] })
                }

            } else {
                this.setState({ dates: seventDays.splice(index, 7) })
            }
        });

        // await this.setState(prevState => ({
        //         index:  prevState.index + 7
        // }))
    }

    formatDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };

    handleScroll = function (e) {
        const { offset, coparent, remainingDates } = this.state;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        // if(bottom) {
        //     this.getCalendarEvents(offset, coparent, false);

        // }
    }
    loadMore = function () {
        this.getSevenDaysRecord();
    }

    getDate = function (date) {
        let d = new Date(date);
        let day = days[d.getDay()];
        let month = monthNames[d.getMonth()];
        return day + ', ' + month;
    }

    setModalVisible = async function (modalVisible) {
        await this.setState({ modalVisible: modalVisible });
    };

    setModal2Visible = async function (modal2Visible, item) {
        await this.setState({ modal2Visible: modal2Visible, currentEvent: item });
    };

    setModal3Visible = async function (modal3Visible, item, date = null, recc_start_date) {
        await this.setState({ modal3Visible: modal3Visible, currentEvent: item, clickedDate: date, reccStartDate: recc_start_date });
    }
    setDeleteModalVisible = function (visible, item, date = null, recc_start_date) {
        this.setState({ deleteModalVisible: visible, currentEvent: item, clickedDate: date, reccStartDate: recc_start_date });
    };

    setErrorModalVisible = function (visible) {
        this.setState({ errorModalVisible: visible });
    };
    setStatusPopup = function (visible) {
        this.setState({ showMsg: visible, msg: '' });
        if (visible == false) {
            this.refreshPage();
        }
    }

    handleClick = async function (event, type, date = null, recc_start_date = null) {
        const { user } = this.props;
        const { events } = this.state;
        await this.setState({ selectedEditOption: type, isVisible: false }); // 0 - Not a recurring event, 1 - selected 1st option(this event), 2 - 2nd option (this and following event)
        await this.setState({ isVisible: false });
        let id;
        let recc_dates = [];
        if (event.id) {
            id = event.id;
        }

        if (type != 0) {
            if (Array.isArray(events) && events.length > 0) {
                events.forEach((eve) => {
                    if (eve.id == id) {
                        recc_dates.push(moment(eve.recurring_start_date).format('YYYY-MM-DD'))
                    }
                })
            }
            const now = moment(event.recurring_start_date).format('YYYY-MM-DD');
            const sortedDates = recc_dates.sort((a, b) => moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD'))
            var index = sortedDates.indexOf(now);
            if (index != -1 && index != 0) {
                await this.setState({ last_rec_date: sortedDates[index - 1] })
            } else if (index == 0) {
                await this.setState({ last_rec_date: sortedDates[index] })
            }
            else {
                await this.setState({ last_rec_date: event.recurring_start_date - 1 })
            }
        }

        if (type == 3 || type == 4) {
            this.setDeleteModalVisible(true, event, date, recc_start_date)
        } else if (user.id === event.coparent_id) {
            this.setModal2Visible(true, event, date, recc_start_date);
        } else if (user.id === event.created_by_user || (event.created_by_third_party == true && (user.id == event.third_party_id))) {
            this.setModal3Visible(true, event, date, recc_start_date);
        }
    }

    handleDelete = function () {
        const { currentEvent, selectedEditOption, last_rec_date, clickedDate } = this.state;
        let id = currentEvent && currentEvent.id;
        const data = {
            title: currentEvent.title,
            child_ids: currentEvent.child_ids,
            picture: null,
            start_date: currentEvent.start_date,
            type: 'event',
            start_time: currentEvent.start_time,
            end_time: currentEvent.end_time,
            share: currentEvent.share,
            err: false,
            recurring_flag: currentEvent.recurring_flag,
            end_date: currentEvent.end_date,
            recurring_frequency: currentEvent.recurring_frequency,
            noOf_frequency: currentEvent.noOf_frequency,
            template: currentEvent.template,

        }
        if (id) {
            this.setState({ loading: true });
            let selectedDate = moment(clickedDate).format('DD-MM-YYYY');
            let startDate = moment(currentEvent.start_date).format('DD-MM-YYYY');
            let rec_date = currentEvent.recurring_start_date;
            let exceptionDays = currentEvent.exception_days != null ? currentEvent.exception_days : [];
            exceptionDays.push(rec_date)
            if (selectedDate == startDate && selectedEditOption == 4) {
                deleteEvents(id)
                    .then((resp) => {
                        if (resp) {
                            if (resp.hasOwnProperty('status') && !resp.status) {
                                this.setState({ msg: resp.message, showMsg: true })
                            } else {
                                this.setState({ loading: false });
                                this.setDeleteModalVisible();
                                this.refreshPage();
                            }

                        } else {
                            this.setState({ loading: false });
                        }
                    })
                    .catch((err) => {
                        this.state.deletErr = true;
                        this.setErrorModalVisible(true);
                    });
            } else if (selectedEditOption == 3) {
                data.exception_days = exceptionDays;
                data.recurring_flag = true;
                editEvents(id, data)
                    .then((updateResp) => {
                        if (updateResp) {
                            if (updateResp.hasOwnProperty('status') && !updateResp.status) {
                                this.setState({ msg: updateResp.message, showMsg: true })
                            } else {
                                this.setState({ loading: false });
                                //this.props.handleClose();
                                this.setDeleteModalVisible()
                                this.refreshPage();
                            }

                        } else {
                            this.setState({ loading: false });
                        }
                    })
                    .catch((err) => {
                        this.state.deletErr = true;
                        this.setErrorModalVisible(true);
                    });
            } else if (selectedEditOption == 4) {
                // data.end_date = currentEvent.recurring_start_date;
                data.end_date = last_rec_date + 'T06:30:00.000Z';
                data.recurring_flag = true;
                editEvents(id, data)
                    .then((updateResp) => {
                        if (updateResp) {
                            if (updateResp.hasOwnProperty('status') && !updateResp.status) {
                                this.setState({ msg: updateResp.message, showMsg: true })
                            } else {
                                this.setState({ loading: false });
                                this.setDeleteModalVisible()
                                this.refreshPage();
                            }

                        } else {
                            this.setState({ loading: false });
                        }
                    })
                    .catch((err) => {
                        this.state.deletErr = true;
                        this.setErrorModalVisible(true);
                    });
            }
        }
    };

    handlePop = (index) => {
        this.setState({ isVisible: true, selected: index });
    }

    handleUpdate = async function (item) {
        const { user } = this.props;
        const { events } = this.state;
        if (item.created_by_third_party && item.third_party_id == user.id && item.user_approved == 0) {
            await this.setState({ hide: false, selectedEditOption: 2 });
        } else {
            await this.setState({ hide: true, selectedEditOption: 2 });
        }

        let id;
        let recc_dates = [];
        if (item.id) {
            id = item.id;
        }

        if (Array.isArray(events) && events.length > 0) {
            events.forEach((eve) => {
                if (eve.id == id) {
                    recc_dates.push(moment(eve.recurring_start_date).format('YYYY-MM-DD'))
                }
            })
        }
        const now = moment(item.recurring_start_date).format('YYYY-MM-DD');
        const sortedDates = recc_dates.sort((a, b) => moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD'))
        var index = sortedDates.indexOf(now);
        if (index != -1 && index != 0) {
            await this.setState({ last_rec_date: sortedDates[index - 1] })
        } else if (index == 0) {
            await this.setState({ last_rec_date: sortedDates[index] })
        }
        else {
            await this.setState({ last_rec_date: item.recurring_start_date - 1 })
        }

        this.setModal3Visible(true, item);
    }

    closeUpdate = async function () {
        await this.setState({
            modal3Visible: false,
            currentEvent: null,
            clickedDate: null,
            reccStartDate: null
        });
        await this.setState({ hide: false });
    }


    render() {
        const {
            events,
            dates,
            loading,
            currentEvent,
            kids,
            isVisible,
            selected,
            selectedEditOption,
            deletErr,
            hide,
            isThirdParty,
            allDates,
            msg,
            showMsg
        } = this.state;

        const { user } = this.props;
        function getColor(item) {
            if (isThirdParty == 'true') {
                return (item.created_by_user === (localStorage.coparent_id)) ? item.color : item.coparent_color;

            } else {
                return (item.created_by_user === (user && user.id)) ? item.color : item.coparent_color;
            }

        }

        return (
            <Card bordered={false} title="Events" className="events">
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Button
                    type="primary"
                    onClick={() => this.setModalVisible(true)}
                    className="fp-button-secondary events-create"
                >
                    Create Events
                </Button>
                <div onScroll={this.handleScroll} className="events-card-wrap">
                    {Array.isArray(dates) && dates.length !== 0 ? dates.map((date, i) => (
                        <Card key={i} bordered={false} className="list-card">
                            <div className="list-inner">
                                <div className="list-date">
                                    <span>{moment.parseZone(date).format('DD')}</span>
                                    <span>{moment.parseZone(date).format('ddd, MMM')}</span>
                                </div>
                                <div className="detail-wrapper">
                                    {Array.isArray(events) && events.length !== 0 ? events.map((item, i) => (
                                        date === moment.parseZone(item.recurring_flag ? item.recurring_start_date : item.start_date).format('YYYY-MM-DD') && item.Members[0] && (
                                            !item.recurring_flag ? (
                                                <div key={i} onClick={() => this.handleClick(item, 0)} className="list-time">
                                                    <div className="time-inner">
                                                        <span>{item.start_time + ' - '}</span>
                                                        <span className={colors[getColor(item.Members[0])]}>{item.title}</span>
                                                    </div>
                                                    <div className="address">
                                                        <span className="data">
                                                            <span className={"dot " + colors[getColor(item.Members[0])]}></span>
                                                        </span>
                                                        {/* <span>128 Main St</span> */}
                                                    </div>
                                                </div>
                                            ) : (

                                                    <div key={i} onClick={() => (user && user.id === item.coparent_id) || ((item.created_by_third_party && user && user.id == item.created_by_user && item.user_approved == 0) || (item.created_by_third_party && user && user.id == item.third_party_id && item.user_approved != 0))
                                                        ? this.handleUpdate(item) : null} className="list-time pop-over-edit">
                                                        <Popover
                                                            //visible={selected === i && isVisible}               
                                                            placement="top" r
                                                            content={(user && user.id === item.coparent_id) || ((item.created_by_third_party && user && user.id == item.created_by_user && item.user_approved == 0) || (item.created_by_third_party && user && user.id == item.third_party_id && item.user_approved != 0))
                                                                ? null : <div className='pop-content-wrap'>
                                                                    <span onClick={() => this.handleClick(item, 1, date, item.start_date)}>Edit this event</span>
                                                                    <span onClick={() => this.handleClick(item, 2, date, item.start_date)}>Edit this and following events</span>
                                                                    <span onClick={() => this.handleClick(item, 3, date, item.start_date)}>Delete this event</span>
                                                                    <span onClick={() => this.handleClick(item, 4, date, item.start_date)}>Delete this and following events</span>
                                                                </div>} trigger="click" overlayClassName={this.state.modal3Visible || this.state.deleteModalVisible ? 'pop-over-event-custom ant-popover-hidden' : 'pop-over-event-custom'}>
                                                            <div className="time-inner">
                                                                <span>{item.start_time + ' - '}</span>
                                                                <span className={colors[getColor(item.Members[0])]}>{item.title}</span>
                                                            </div>
                                                            <div className="address">
                                                                <span className="data">
                                                                    <span className={"dot " + colors[getColor(item.Members[0])]}></span>
                                                                </span>
                                                                {/* <span>128 Main St</span> */}
                                                            </div>
                                                        </Popover>
                                                    </div>
                                                )


                                        ))) : null}

                                    {deletErr ? (
                                        <Modal
                                            title="Connection error - please try again, or check your internet connection."
                                            visible={this.state.errorModalVisible}
                                            className="error-popup"
                                            onOk={() => this.setErrorModalVisible(false)}
                                            onCancel={() => this.setErrorModalVisible(false)}
                                            maskClosable={false}
                                        >
                                            <Button className="btn" onClick={this.props.handleClose}>
                                                OK
                                    </Button>
                                        </Modal>
                                    ) : null}
                                </div>
                            </div>
                        </Card>
                    )) : (
                            <div>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        )}
                    {dates.length >= 7 && allDates.length > 0 && (
                        <div className="load-more-btn-wrap">
                            <Button onClick={this.loadMore} className="fp-button-primary load-more-btn" type="primary">
                                Load More
                            </Button>
                        </div>
                    )}
                    {showMsg && (
                        <Modal
                            title={this.state.msg}
                            visible={this.state.showMsg}
                            className="error-popup event-st-msg"
                            onOk={() => this.setStatusPopup(false)}
                            onCancel={() => this.setStatusPopup(false)}
                            maskClosable={false}
                        >
                            <Button className="btn" onClick={() => this.setStatusPopup(false)}>
                                OK
                            </Button>
                        </Modal>
                    )}


                </div>
                <CreateEventModal
                    defaultValue={moment(12).format('hh:mm a')}
                    refresh={() => this.refreshPage()}
                    handleOpen={this.state.modalVisible}
                    handleClose={() => this.setModalVisible(false)}
                />
                <UpdateEventModal
                    refresh={() => this.refreshPage()}
                    currentEvent={currentEvent}
                    clickedDate={this.state.clickedDate}
                    reccuringStartDate={this.state.reccStartDate}
                    selectedEditOption={selectedEditOption}
                    lastRecDate={this.state.last_rec_date}
                    hideSubmit={hide}
                    handleOpen={this.state.modal3Visible}
                    handleClose={() => this.closeUpdate()}
                />
                <EventDetailsModal
                    currentEvent={currentEvent}
                    handleOpen={this.state.modal2Visible}
                    handleClose={() => this.setModal2Visible(false)}
                />
                <DeleteEventModal
                    delete={() => this.handleDelete()}
                    handleOpen={this.state.deleteModalVisible}
                    lastRecDate={this.state.last_rec_date}
                    handleClose={() => this.setDeleteModalVisible(false)}
                />
            </Card>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, loadings, error, coparent } = serviceReducer;
    return { children, user, loadings, error, coparent };
};

export default connect(mapStateToProps)(Events);