import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import 'pages/SubscriptionNew/EditSubscription.scss';
import { Card, Button, Modal, Checkbox, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { relativeTimeThreshold } from 'moment';
import { addLoading, removeLoading } from 'actions/loaderActions';
import {
    deleteCard
} from 'services/subscriptionService';
import LoadingOverlay from 'react-loading-overlay';

const { Option } = Select;
const { TextArea } = Input;

class DeleteCardModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            card: null,
            loading: false
        }
        this.handleDelete = this.handleDelete.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.openModal) {
            this.setState({
                card: nextProps.card
            });
        }
    }

    handleDelete = function(){
        const { card } = this.state;
        this.setState({ loading: true });
        deleteCard(card.card_id)
            .then(resp => {
                this.setState({ loading: false });
                this.props.closeModal();
                this.props.refresh();
        })
    }

    render() {
        const {
            loading
        } = this.state;

        return (
            <Modal
                title="Delete payment method"
                centered
                maskClosable={false}
                visible={this.props.openModal}
                className="card-popup"
                onOk={this.props.closeModal}
                onCancel={this.props.closeModal}
            >
            <LoadingOverlay
                active={loading ? true : false}
                className={'delete-loader-wrap'}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '100px',
                        '& svg circle': {
                            stroke: 'rgb(56, 160, 235)',
                        },
                    }),
                }}
            ></LoadingOverlay>
              
                <div className="card__text">
                    Are you sure you want to delete this payment method? Click confirm button to remove it.
                </div>
                <Button type="primary" onClick={this.handleDelete} className="btn fp-button-primary">
                    Confirm
                </Button>

                <Button type="primary" onClick={this.props.closeModal} className="btn fp-button-secondary">
                    Cancel
                </Button>
              
            </Modal>
        );
    }
}

export default DeleteCardModal;