import { makeRequest } from './authenticationService';

// Get QB Credentials
export const getQBCredentials = () => {

    let url = '/api/chat/credential?requestFrom=web';

    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

//Update QB Data
export const updateQBUserData = (options) => {
 

    let url = '/api/chat/updateUser';

    return makeRequest('PUT', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}


//Get Chat List
export const GetChatList = (type, offset, limit,cp_id =null,third_party=false) => {

    let _offset = offset || 0;
    let _limit = limit || 10;
    
    let url = `/api/chat/listChat?type=${type}&offset=${_offset}&limit=${_limit}`;
    if(third_party == true) {
        url += '&thirdParty=true';
        url += '&coparent_id=' + cp_id;
    }
    return makeRequest('GET', url)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
};

// Get Chat List for third party
export const GetThirdPartyChatList = (type, offset, limit, cp_id) => {

    let _offset = offset || 0;
    let _limit = limit || 10;
    
    let url = `/api/chat/listChat?type=${type}&offset=${_offset}&limit=${_limit}`;

    url += '&thirdParty=true';
    url += '&coparent_id=' + cp_id;

    return makeRequest('GET', url)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
};

//Get Co-Parent List
export const GetCoParentList = (cp_id =null,third_party=null) => {

    let url = '/api/chat/getCoparents';
    if(third_party == true) {
        url += '?thirdParty=true&coparent_id=' + cp_id;
    }

    return makeRequest('GET', url)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });

  };

  //Get Co-Parent List for third party
export const GetCoParentListTP = (c_id) => {
    let url = `/api/chat/getCoparentsTp?cp_id=${c_id}`;

    return makeRequest('GET', url)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });

  };


  //Create Chat
export const CreateChat = (data) => {

    let url = '/api/chat/createChat';

    return makeRequest('POST', url, data)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
};


//Get Chat Detail
export const GetChatDetail = (ID) => {

    let url = '/api/chat/getChat/' + ID;

    return makeRequest('GET', url)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
};


//Remove Members
export const RemoveMembers = (item) => {

    let url = '/api/chat/removeMember';

    return makeRequest('PUT', url, item)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });

};
  
//Get Archive List
export const GetArchiveList = (offset, limit, cp_id =null,third_party=false ) => {

    let url = `/api/chat/listArchived?offset=${offset}&limit=${limit}`;
    
    if(third_party == true) {
        url += '&thirdParty=true';
        url += '&coparent_id=' + cp_id;
    }

    return makeRequest('GET', url)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
};

export const GetThirdPartyArchiveList = (cp_id) => {

    let url = '/api/chat/listArchived';

    url += '?thirdParty=true&coparent_id=' + cp_id;

    return makeRequest('GET', url)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
};

//Update Chat
export const UpdateChatData = (item) => {

    let url = '/api/chat/updateChat';

    return makeRequest('PUT', url, item)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });

};