import React, { Component } from 'react';
import 'pages/Chat/ChatContainer/ChatContainer.scss';
import { Card, Avatar, Button, Modal, Select, Input, Empty } from 'antd';
import Icon from '@ant-design/icons';
import { GetArchiveList, GetThirdPartyArchiveList } from 'services/chatService';
import { GetAllDialogs } from 'pages/QuickBlox/qbDialogs';
import { v4 as uuid } from 'uuid';
import LoadingOverlay from 'react-loading-overlay';
import user from 'Assets/default.jpg';
import moment from 'moment';
import { connect } from 'react-redux';
import * as QB from 'quickblox/quickblox';
import { QuickBlockConfig } from 'pages/QuickBlox/QBConfig';
import {
    getQBCredentials,
    updateQBUserData,
    GetChatDetail
} from 'services/chatService';
import { updat } from 'actions/userActions';
import {
    CreateUser, 
    LoginUser,
    ConnectChatServer,
    IsConnected,
    GetSession,
    CreateSession,
    CreateAppSession,
    GetUser
} from 'pages/QuickBlox/qbutility';
import {
    ChatHistory
} from 'pages/QuickBlox/qbChat';

const editSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <defs>
            <clipPath id="a">
                <rect className="a" width="24" height="24" transform="translate(968 114)" />
            </clipPath>
        </defs>
        <g className="b" transform="translate(-968 -114)">
            <g transform="translate(970.275 116.275)">
                <path
                    className="c"
                    d="M0,16.2v4.052H4.052L16.01,8.3,11.958,4.244Z"
                    transform="translate(0 -0.805)"
                />
                <path
                    className="c"
                    d="M22.2,2.839,19.676.316a1.083,1.083,0,0,0-1.529,0L16.169,2.294l4.052,4.052L22.2,4.369A1.083,1.083,0,0,0,22.2,2.839Z"
                    transform="translate(-3.066)"
                />
            </g>
        </g>
    </svg>
);
const EditIcon = () => <Icon component={editSvg} />;

class ChatArchives extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            archivedList: [],
            offset: 0,
            limit: 10,
            flag1: false
        };
        this.getArchiveList = this.getArchiveList.bind(this);
        this.getUsersName = this.getUsersName.bind(this);
        this.getPicture = this.getPicture.bind(this);
        this.parseListData = this.parseListData.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }
    
    componentDidMount() {
        // if (this.props.value === "4") {
        //     this.setState({ loading: true, offset: 0 });
        //     if(QB.chat && QB.chat.isConnected){
        //         this.getArchiveList(0, true);
        //     } 
        // }
        
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        if (nextProps && nextProps.value === "4") {
            await this.setState({ loading: true, offset: 0 });
            if (QB.chat && QB.chat.isConnected) {
                this.getArchiveList(0, true);   
            } 
        }
    }

    getArchiveList = async function (offset, flag) {
        const { limit, archivedList } = this.state;
        let cp_id;
        let third_party = false;
        let list = await GetAllDialogs();
        if (!list) {
            this.setState({ loading: false });
        }
        if (list && list.items && list.items.length === 0) {
            this.setState({ loading: false });
        }
        if(localStorage.third_party == 'true') {
            cp_id = localStorage.coparent_id;
            third_party = true;
        }
        if (list && Array.isArray(list.items) && list.items.length > 0) {
            if (localStorage.third_party !== 'true') {
                GetArchiveList(offset, limit, cp_id,third_party).then(async resp => {
                    if (resp && resp.data.length > 0) {
                        // await this.setState({ offset: this.state.offset + 10 });
                        let chats = await this.parseListData(resp.data, list.items);

                        if (flag) {
                            let arr = [];
                            arr = [...arr, ...chats];
                            this.setState({ archivedList: arr, loading: false, offset: offset + 10 });
                        } else {
                            let arr = [];
                            arr = [...archivedList, ...chats];
                            this.setState({ archivedList: arr, loading: false, offset: offset + 10  });
                        }
                    } else {
                        this.setState({ loading: false });
                    }
                });
            } else if (localStorage.third_party === 'true') {
                GetArchiveList(offset, limit, cp_id,third_party ).then(async resp => {
                    if (resp && resp.data.length > 0) {
                        // await this.setState({ offset: this.state.offset + 10 });
                        let arr = resp.data;
                        /*resp.data.forEach(el => {
                            if (el.Dialogues[0] && el.Dialogues[0].create_by === localStorage.coparent_id) {
                                arr.push(el);
                            }
                        })*/

                        let chats = await this.parseListData(arr, list.items);

                        if (flag) {
                            let arr = [];
                            arr = [...arr, ...chats];
                            this.setState({ archivedList: arr, loading: false, offset: offset + 10 });
                        } else {
                            let arr = [];
                            arr = [...archivedList, ...chats];
                            this.setState({ archivedList: arr, loading: false, offset: offset + 10 });
                        }
                    } else {
                        this.setState({ loading: false });
                    }
                });
            }
        }
    
    }

    parseListData = async (fpList, qbList) => {
        let array = [];
        for (const dialoglist of fpList) {
            for (const data of qbList) {
                if (data._id == dialoglist.qb_dialogue_id) {
                    if (localStorage.third_party !== 'true') {
                        let obj = {};
                        obj.type = dialoglist.Dialogues[0].type;
                        obj.id = data._id;
                        obj.fp_dialogid = dialoglist.dialogue_id;

                        obj.unread_count = data.unreadMessagesCount;
                        obj.qb_dialogue_id = dialoglist.qb_dialogue_id;

                        let messageObj = {};

                        if (dialoglist.is_archived) {
                            let archived_at = await this.CallChatDetailApi(dialoglist.qb_dialogue_id);

                            let filter = {
                                field: 'date_sent',
                                operator: 'lte',
                                value: '' + archived_at,
                            };

                            let chathistory = await ChatHistory(dialoglist.qb_dialogue_id, 20, 0, filter, dialoglist.is_archived);
                            let last_message = {};
                            if (chathistory && chathistory.items && chathistory.items[0]) {
                                last_message.text = chathistory.items[0].message;
                                last_message.created_at = chathistory.items[0].created_at;
                            }
                            
                            let messageDate = Math.round(data.last_message_date_sent / 1000);
                            var archived = dialoglist.archived_at;
                            let archivedTimestamp = Math.round(new Date(archived).getTime() / 1000);
                            if (messageDate <= archivedTimestamp) {
                                messageObj.id = uuid();
                                messageObj.author = data.last_message_user_id;
                                messageObj.text = data.last_message;
                                messageObj.created_at = data.updated_at;
                                messageObj.unread = true;
                                obj.last_message = last_message;
                            }
                        } else {
                            messageObj.id = uuid();
                            messageObj.author = data.last_message_user_id;
                            messageObj.text = data.last_message;
                            messageObj.created_at = data.updated_at;
                            messageObj.unread = true;
                            obj.last_message = messageObj;
                        }

                        obj.title = dialoglist.Dialogues[0].name;

                        obj.is_archived = dialoglist.is_archived;
                        obj.archived_at = dialoglist.archived_at;
                        obj.createdBy = dialoglist.Dialogues[0].create_by;
                        let members = dialoglist.Dialogues[0].DialogueMembers;

                        let users = await members.map((mem) => {
                            // let user = this.state.coparents.filter((x) => {
                            //   return x.id == mem.user_id;
                            // });

                            // mem.first_name = user[0] ? user[0].first_name : "";
                            mem.first_name = mem.userInfo.first_name;
                            mem.last_name = '';
                            mem.picture = mem.userInfo.file_url;
                            return mem;
                        });
                        obj.users = users;
                        array.push(obj);

                        break;
                    } else if (localStorage.third_party === 'true') {
                        //if (dialoglist.Dialogues[0] && dialoglist.Dialogues[0].create_by === localStorage.coparent_id) {
                            let obj = {};
                            obj.type = dialoglist.Dialogues[0].type;
                            obj.id = data._id;
                            obj.fp_dialogid = dialoglist.dialogue_id;

                            obj.unread_count = data.unreadMessagesCount;
                            obj.qb_dialogue_id = dialoglist.qb_dialogue_id;

                            let messageObj = {};

                            if (dialoglist.is_archived) {
                                
                                let archived_at = await this.CallChatDetailApi(dialoglist.qb_dialogue_id);

                                let filter = {
                                    field: 'date_sent',
                                    operator: 'lte',
                                    value: '' + archived_at,
                                };

                                let chathistory = await ChatHistory(dialoglist.qb_dialogue_id, 20, 0, filter, dialoglist.is_archived);
                                let last_message = {};
                                if (chathistory && chathistory.items && chathistory.items[0]) {
                                    last_message.text = chathistory.items[0].message;
                                    last_message.created_at = chathistory.items[0].created_at;
                                }

                                let messageDate = Math.round(data.last_message_date_sent / 1000);
                                var archived = dialoglist.archived_at;
                                let archivedTimestamp = Math.round(new Date(archived).getTime() / 1000);
                                if (messageDate <= archivedTimestamp) {
                                    messageObj.id = uuid();
                                    messageObj.author = data.last_message_user_id;
                                    messageObj.text = data.last_message;
                                    messageObj.created_at = data.updated_at;
                                    messageObj.unread = true;
                                    obj.last_message = last_message;
                                }
                            } else {
                                messageObj.id = uuid();
                                messageObj.author = data.last_message_user_id;
                                messageObj.text = data.last_message;
                                messageObj.created_at = data.updated_at;
                                messageObj.unread = true;
                                obj.last_message = messageObj;
                            }

                            obj.title = dialoglist.Dialogues[0].name;

                            obj.is_archived = dialoglist.is_archived;
                            obj.archived_at = dialoglist.archived_at;
                            obj.createdBy = dialoglist.Dialogues[0].create_by;
                            let members = dialoglist.Dialogues[0].DialogueMembers;

                            let users = await members.map((mem) => {
                                // let user = this.state.coparents.filter((x) => {
                                //   return x.id == mem.user_id;
                                // });

                                // mem.first_name = user[0] ? user[0].first_name : "";
                                mem.first_name = mem.userInfo.first_name;
                                mem.last_name = '';
                                mem.picture = mem.userInfo.file_url;
                                return mem;
                            });
                            obj.users = users;
                            array.push(obj);

                            break;
                        //}
                    }
                }
            }
        }

        return array;
    };

    CallChatDetailApi = async (dialogId) => {
        return new Promise(resolve => {
            GetChatDetail(dialogId).then(async (resp) => {
                if (resp && resp.data) {
                    let dialogdata = resp.data.Dialogues;
                    var archivedtime = resp.data.archived_at;
                    let archivedTimestamp = Math.round(new Date(archivedtime).getTime() / 1000);
                    let archived = resp.data.is_archived;
                    let archived_at = archivedTimestamp;
                    resolve(archived_at);
                }

            });
        });
    };

    getUsersName = function (users) {
        let res = '';
        let arr = [];
        if (Array.isArray(users)) {
            users.forEach((user) => {
                if(!arr.includes(user.first_name)){
                    arr.push(user.first_name);
                }
            });
        }

        res = arr.join(', ');
        return res;
    };

    getPicture = function (chat) {
        let res = [];
        let users = chat.users.slice(0, 2);
        users.forEach((user) => {
            res.push(user.picture);
        });

        return res;
    };

    handleClick = (chat) => {
        this.props.archive(chat.qb_dialogue_id);
    }

    handleScroll = function(e) {
        const { offset } = this.state;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this.setState({ loading: true });
            this.getArchiveList(offset, false);
        }
    }

    render() {
        const { loading, archivedList, offset } = this.state;
        console.log('offset**', offset);
        console.log('arvhivedList', archivedList);

        return (
            <LoadingOverlay
                active={loading ? true : false}
                className={'loader-wrap chat-loader-overlay ' + (loading ? 'd-block' : '')}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '100px',
                        '& svg circle': {
                            stroke: 'rgb(56, 160, 235)',
                        },
                    }),
                }}
            >
            <div className="chat-archive-wrap">
                <div className="archive-head">
                    <span>Archives</span>
                    <span className="icon-wrap">
                        {/* <EditIcon /> */}
                    </span>
                </div>
                <div className="archive-content" onScroll={this.handleScroll} >
                {Array.isArray(archivedList) && archivedList.length > 0 ? (
                    archivedList.map((chat, i) => (
                    <Card key={i} bordered={false} onClick={() => this.handleClick(chat)} className="archive-content-item">
                        <div className="card-left">
                            <div className="avatar-wrap">
                            <Avatar
                                size={30}
                                src={this.getPicture(chat)[1] ? this.getPicture(chat)[1] : user}
                            />
                            <Avatar
                                size={25}
                                src={this.getPicture(chat)[0] ? this.getPicture(chat)[0] : user}
                            />
                            </div>
                        </div>
                        <div className="card-right">
                            <div className="person-data">
                                <span>{chat.title === 'Chat Group'?this.getUsersName(chat.users) : chat.title}</span>
                                <span>{chat.last_message ? moment(chat.last_message.created_at).fromNow() : null}</span>
                            </div>
                            <div className="msg-body">
                                <span>{chat.last_message ? chat.last_message.text : null}</span>
                            </div>
                        </div>
                    </Card>
                    ))
                    ) : (
                        <div>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                    )}

                </div>
            </div>
            </LoadingOverlay>
        );
    }
}


export default ChatArchives;

