import React, { Component } from 'react';
import 'pages/Reports/CustodySwaps/CustodySwaps.scss';
import { Card, Button, Tabs, Modal, Select, List, Typography , Empty} from 'antd';
import { connect } from 'react-redux';
import { SaveSwapReport, GetSwapDetail, UpdateSwapReport } from 'services/reportsService';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { swap } from 'formik';

const { TabPane } = Tabs;
const { Option } = Select;

class SaveSwapReportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            type: '',
            swapReport: null,
            startDate: new Date(),
            endDate: new Date(),
            duration: null,
            user: null,
            coparentName: '',
            selectedTab: '1',
            swapList: [],
            exceptionList: [],
            forfeitList: [],
        };
        this.changeTab = this.changeTab.bind(this);
        this.getSwapList = this.getSwapList.bind(this);
        this.getExceptionList = this.getExceptionList.bind(this);
        this.getForfeitList = this.getForfeitList.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
         this.setState({swapList: [], exceptionList: [], forfeitList: [], swapReport:null})
        if (nextProps && nextProps.openModal && nextProps.swapReport) {
            if (nextProps.swapReport.type == 'create') {
                await this.setState({
                    swapList: [],
                    exceptionList: [],
                    forfeitList: [],
                    selectedTab: '1',
                    swapReport: nextProps.swapReport,
                    startDate: nextProps.swapReport.startDate,
                    endDate: nextProps.swapReport.endDate,
                    duration: nextProps.swapReport.periodDuration,
                    user: nextProps.swapReport.user,
                    type: nextProps.swapReport.type,
                    coparentName: nextProps.swapReport.user && nextProps.swapReport.user.coparentName,
                });
            } else {
                this.setState({ type: nextProps.swapReport.type });
                this.getActiveReports(nextProps.swapReport.data);
            }
            this.getSwapList();
            this.getExceptionList();
            this.getForfeitList();
        }
    };

    async getActiveReports(id) {
        this.setState({ loading: true,swapList: [], exceptionList: [], forfeitList: [],swapReport:null });
        GetSwapDetail(id, async (data) => {
            this.setState({ loading: false });
            this.setState({ swapReport: data.data });
            await this.setState({
                swapList: [],
                exceptionList: [],
                forfeitList: [],
                selectedTab: '1',
                swapReport: data.data,
                startDate: data.data.startDate,
                endDate: data.data.endDate,
                duration: data.data.periodDuration,
                user: data.data.user,
                coparentName: data.data.user && data.data.user.coparentName,
            });

            this.getSwapList();
            this.getExceptionList();
            this.getForfeitList();
        });
    }

    handleClose () {
           this.setState({swapList: [], exceptionList: [], forfeitList: [],swapReport:null}, 
             ()=>this.props.closeModal())
        
    }
    formartData(date) {
        var newdate = moment(date, 'DD-MM-YYYY');
        return moment(newdate).format('MM/DD/YY');
    }

    getSwapList = () => {
        const { selectedTab, swapReport } = this.state;
        let swapList = [];

        let datatoshow = null;

        if (selectedTab == '1') {
            if (swapReport && swapReport.swapData) {
                datatoshow = swapReport.swapData.approved;
            }
        } else {
            if (swapReport && swapReport.swapData) {
                datatoshow = swapReport.swapData.declined;
            }
        }

        if (datatoshow) {
            let allkeys = Object.keys(datatoshow);

            for (const key of allkeys) {
                swapList.push(datatoshow[key]);
            }

            this.setState({ swapList: swapList });
        } else {
            this.setState({ swapList: [] });
        }
    };

    getExceptionList = () => {
        const { selectedTab, swapReport } = this.state;
        let exceptionList = [];

        let datatoshow = null;

        if (selectedTab == '1') {
            if (swapReport && swapReport.exceptionData) {
                datatoshow = swapReport.exceptionData.approved;
            }
        } else {
            if (swapReport && swapReport.exceptionData) {
                datatoshow = swapReport.exceptionData.declined;
            }
        }

        if (datatoshow) {
            let allkeys = Object.keys(datatoshow);

            for (const key of allkeys) {
                exceptionList.push(datatoshow[key]);
            }

            this.setState({ exceptionList: exceptionList });
        } else {
            this.setState({ exceptionList: [] });
        }
    };

    getForfeitList = () => {
        const { selectedTab, swapReport } = this.state;
        let forfeitList = [];

        let datatoshow = null;

        if (selectedTab == '1') {
            if (swapReport && swapReport.forfeitData) {
                datatoshow = swapReport.forfeitData.approved;
            }
        } else {
            if (swapReport && swapReport.forfeitData) {
                datatoshow = swapReport.forfeitData.declined;
            }
        }

        if (datatoshow) {
            let allkeys = Object.keys(datatoshow);

            for (const key of allkeys) {
                forfeitList.push(datatoshow[key]);
            }

            forfeitList.forEach((item) => {
                if (item.isUser) {
                    item.leftArrowShow = true;
                    item.rightArrowShow = false;
                } else {
                    item.leftArrowShow = false;
                    item.rightArrowShow = true;
                }
            });

            this.setState({ forfeitList: forfeitList });
        } else {
            this.setState({ forfeitList: [] });
        }
    };

    changeTab = async (key) => {
        await this.setState({ selectedTab: key });
        this.getSwapList();
        this.getExceptionList();
        this.getForfeitList();
    };

    handleSave = async () => {
        const { type, swapReport } = this.state;
        await this.setState({ loading: true });
        if (type == 'create') {
            const { swapReport } = this.state;

            let coparent_id = swapReport.user ? swapReport.user.coparent_id : '';
            swapReport.coparent_id = coparent_id;
            swapReport.child_ids = [];
            SaveSwapReport(swapReport).then(async (resp) => {
                await this.setState({ loading: false });
                this.props.closeModal();
                this.props.refresh();
            });
        } else {
            let data = {};
            data.id = swapReport.id;
            data.archived = true;
            let response = await UpdateSwapReport(data);
            await this.setState({ loading: false });
            if (response) {
                this.props.closeModal();
                this.props.refreshSwapReports();
            }
        }
    };

    render() {
        const {
            swapReport,
            startDate,
            endDate,
            duration,
            user,
            coparentName,
            selectedTab,
            swapList,
            exceptionList,
            forfeitList,
            loading,
            type,
        } = this.state;

        return (
            <div>
                <Modal
                    title="Swap Report"
                    centered
                    className="report-data-modal save-swap"
                    maskClosable={false}
                    visible={this.props.openModal}
                    onOk={this.props.closeModal}
                    onCancel={()=>this.handleClose()}
                >
                    <LoadingOverlay
                        active={loading}
                        className={loading ? 'd-block pop-loader' : 'd-none'}
                        spinner
                        styles={{
                            spinner: (base) => ({
                                ...base,
                                width: '100px',
                                '& svg circle': {
                                    stroke: 'rgb(56, 160, 235)',
                                },
                            }),
                        }}
                    ></LoadingOverlay>
                    <div className="modal-top">
                        <span>{'' + this.formartData(startDate) + ' - ' + this.formartData(endDate)}</span>
                        <span>{duration} Days</span>
                    </div>
                    <div className="modal-body">
                        <Tabs activeKey={selectedTab} onChange={this.changeTab}>
                            <TabPane tab="Approved" key="1">
                                <div className="tab-content-wrap">
                                    <div className="content-inner">
                                        <div className="t-row">
                                            <div className="row-item row-head">
                                                <span>{user && user.name}</span>
                                                <span>Swap Days</span>
                                                <span>{coparentName}</span>
                                            </div>
                                            {swapList.map((swap, i) => (
                                                <div key={i} className="row-item ">
                                                    <span className="multi-date-left">
                                                        {swap.userCustodyDate.map((date, j) => (
                                                            <div key={j}>
                                                                {moment(new Date(date)).format('MM/DD/YY')}
                                                            </div>
                                                        ))}
                                                    </span>
                                                    <span className="arrow-both">{swap.kidName}</span>
                                                    <span className="multi-date">
                                                        {swap.coparentCustodyDate.map((cp_date, k) => (
                                                            <div key={k}>
                                                                {moment(new Date(cp_date)).format('MM/DD/YY')}
                                                            </div>
                                                        ))}
                                                    </span>
                                                </div>
                                            ))}
                                            {swapList && swapList.length == 0 && (
                                                <div>
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                </div>
                                            )}
                                        </div>
                                        <div className="t-row">
                                            <div className="row-item row-head">
                                                <span></span>
                                                <span>Exception Days</span>
                                                <span>Custody</span>
                                            </div>
                                            {exceptionList.map((item, i) =>
                                                item.map((listItem, j) => (
                                                    <div key={j} className="row-item ">
                                                        <span>
                                                            {moment(new Date(listItem.custodyDate)).format('MM/DD/YY')}
                                                        </span>
                                                        <span className="arrow-right">{listItem.kidName}</span>
                                                        <span>
                                                            {listItem.isUser ? user && user.name : coparentName}
                                                        </span>
                                                    </div>
                                                )),
                                            )}
                                            {exceptionList && exceptionList.length == 0 && (
                                                <div>
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                </div>
                                            )}
                                        </div>
                                        <div className="t-row">
                                            <div className="row-item row-head">
                                                <span>{user && user.name}</span>
                                                <span>Forfeit Days</span>
                                                <span>{coparentName}</span>
                                            </div>
                                            {forfeitList.map((item, i) => (
                                                <div key={i} className="row-item">
                                                    <span className="multi-date-left">
                                                        {item.isUser &&
                                                            item.custodyDate.map((date, j) => (
                                                                <div key={j}>
                                                                    {moment(new Date(date)).format('MM/DD/YY')}
                                                                </div>
                                                            ))}
                                                    </span>
                                                    <span className={item.leftArrowShow ? 'arrow-left' : 'arrow-right'}>
                                                        {item.kidName}
                                                    </span>
                                                    <span className="multi-date">
                                                        {item.isUser === false &&
                                                            item.custodyDate.map((cp_date, k) => (
                                                                <div key={k}>
                                                                    {moment(new Date(cp_date)).format('MM/DD/YY')}
                                                                </div>
                                                            ))}
                                                    </span>
                                                </div>
                                            ))}
                                           {forfeitList && forfeitList.length == 0 && (
                                                <div>
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* <div className="btn-save-wrap">
                                        <Button
                                            className="fp-button-secondary create-btn"
                                            onClick={this.handleSave}
                                        >
                                            Save Report
                                        </Button>
                                    </div>*/}
                                </div>
                            </TabPane>
                            <TabPane tab="Declined" key="2">
                                <div className="tab-content-wrap">
                                    <div className="content-inner">
                                        <div className="t-row">
                                            <div className="row-item row-head">
                                                <span>{user && user.name}</span>
                                                <span>Swap Days</span>
                                                <span>{coparentName}</span>
                                            </div>
                                            {swapList.map((swap, i) => (
                                                <div key={i} className="row-item ">
                                                    <span className="multi-date-left">
                                                        {swap.userCustodyDate.map((date, j) => (
                                                            <div key={j}>
                                                                {moment(new Date(date)).format('MM/DD/YY')}
                                                            </div>
                                                        ))}
                                                    </span>
                                                    <span className="arrow-both">{swap.kidName}</span>
                                                    <span className="multi-date">
                                                        {swap.coparentCustodyDate.map((cp_date, k) => (
                                                            <div key={k}>
                                                                {moment(new Date(cp_date)).format('MM/DD/YY')}
                                                            </div>
                                                        ))}
                                                    </span>
                                                </div>
                                            ))}
                                            {swapList && swapList.length == 0 && (
                                                <div>
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                </div>
                                            )}
                                        </div>
                                        <div className="t-row">
                                            <div className="row-item row-head">
                                                <span></span>
                                                <span>Exception Days</span>
                                                <span>Custody</span>
                                            </div>
                                            {exceptionList.map((item, i) =>
                                                item.map((listItem, j) => (
                                                    <div key={j} className="row-item ">
                                                        <span>
                                                            {moment(new Date(listItem.custodyDate)).format('MM/DD/YY')}
                                                        </span>
                                                        <span className="arrow-right">{listItem.kidName}</span>
                                                        <span>
                                                            {listItem.isUser ? user && user.name : coparentName}
                                                        </span>
                                                    </div>
                                                )),
                                            )}
                                            {exceptionList && exceptionList.length == 0 && (
                                                <div>
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                </div>
                                            )}
                                        </div>
                                        <div className="t-row">
                                            <div className="row-item row-head">
                                                <span>{user && user.name}</span>
                                                <span>Forfeit Days</span>
                                                <span>{coparentName}</span>
                                            </div>
                                            {forfeitList.map((item, i) => (
                                                <div key={i} className="row-item">
                                                    <span className="multi-date-left">
                                                        {item.isUser &&
                                                            item.custodyDate.map((date, j) => (
                                                                <div key={j}>
                                                                    {moment(new Date(date)).format('MM/DD/YY')}
                                                                </div>
                                                            ))}
                                                    </span>
                                                    <span className={item.leftArrowShow ? 'arrow-left' : 'arrow-right'}>
                                                        {item.kidName}
                                                    </span>
                                                    <span className="multi-date">
                                                        {item.isUser === false &&
                                                            item.custodyDate.map((cp_date, k) => (
                                                                <div key={k}>
                                                                    {moment(new Date(cp_date)).format('MM/DD/YY')}
                                                                </div>
                                                            ))}
                                                    </span>
                                                </div>
                                            ))}
                                            {forfeitList && forfeitList.length == 0 && (
                                                <div>
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                    {type != 'archive' && (
                        <div className="btn-save-wrap">
                            <Button className="fp-button-secondary create-btn" onClick={this.handleSave}>
                                {type == 'create' ? 'Save Report' : 'Archive Report'}
                            </Button>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, loadings, error, coparent, coparents } = serviceReducer;
    return { children, user, loadings, error, coparent, coparents };
};

export default connect(mapStateToProps)(SaveSwapReportModal);
