import { makeRequest } from './authenticationService';

export const getHouseHoldService = (request) => {

    let url = '/api/households/my';

    return makeRequest('GET', url)
        .then((resp) => {         
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const getThirdPartyData = (request) => {
    let url = '/api/households/thirdparty?id='+request.id.id;

    return makeRequest('GET', url)
        .then((resp) => {         
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const saveHouseHoldService = (request) => {

    let url = '/api/households/'+request.data.houseHoldId;

    return makeRequest('PUT', url,request.data)
        .then((resp) => {         
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const saveHouseHold = (data) => {

    let url = '/api/households/'+data.houseHoldId;

    return makeRequest('PUT', url,data)
        .then((resp) => {         
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const resendInvitation = (data) => {
 
    let url = '/api/coparents/resendInvite';

    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

// cliffex------

export const addChildren = (request) => {    
    let url = '/api/members';
    return makeRequest('Post', url,request)        
}

export const editChildren = (id,request) => {
    let url = `/api/members/${id}`;
     return makeRequest('Put', url,request)
}

export const deleteChildren = (id) => {
    let url = `/api/members/${id}`;
    return makeRequest('delete', url)
}

export const addCoparent = (request) => {    
    let url = '/api/coparents';
    return makeRequest('Post', url,request)        
}

export const resendInvite = (id) => {    
    let url = `/api/coparents/resend/${id}`;
    return makeRequest('Post', url)        
}

export const editCoparent = (id ,request) => {
    let url = `/api/coparents/${id}`;
    return makeRequest('Put', url,request) 
}

export const ChangePwd = (id, request) =>{
    let url = `/api/users/${id}/password`;
    return makeRequest('Put', url,request)
}


export const getMembers = (data) => {

    let url = `/api/households/getMembers`;

    if (data.isThirdParty) {
        url += `?isThirdParty=${true}`;
        if (data.coparent_id) {
            url += `&coparent_id=${data.coparent_id}`;
        }
    }

    return makeRequest('GET', url)
        .then((resp) => {         
            return resp;
        })
        .then((json) => {
            return json;
        });

}