import React, { Component } from 'react';
import 'pages/CalenderInner/Swap/Swap.scss';
import { Card, Button, Modal, Form, Input, Select, Avatar, Empty } from 'antd';
import right from 'Assets/right-arrow.png';
import TextArea from 'antd/lib/input/TextArea';
import user from 'Assets/defaultAvatar.png';
import { connect } from 'react-redux';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { getSwap, updateSwap } from 'services/calendarService';
import Calendar from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import { addLoading, removeLoading } from 'actions/loaderActions';

const swap_status = {
    pending: 'Pending',
    active: 'Approved',
    declined: 'Declined',
};

class SwapInvite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modalVisible: false,
            currentDate: new Date(),
            title: '',
            child_ids: [],
            child: null,
            expire: '',
            dates: [],
            tradeDates: [],
            temp1: [],
            temp2: [],
            flag: false,
            status: '',
            picture: null,
            memo: '',
            response_memo: '',
            swap: null,
            decline_reason: '',
            flag1: false,
            error: '',
        };
        this.setModalVisible = this.setModalVisible.bind(this);
        this.getExpireDuration = this.getExpireDuration.bind(this);
        this.sortedDates = this.sortedDates.bind(this);
        this.handleChildren = this.handleChildren.bind(this);
        this.getChildrenNames = this.getChildrenNames.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.handleForfeit = this.handleForfeit.bind(this);
        this.handleTrade = this.handleTrade.bind(this);
        this.handleDates = this.handleDates.bind(this);
        this.confirmDates = this.confirmDates.bind(this);
        this.formattedDates = this.formattedDates.bind(this);
        this.handleDecline = this.handleDecline.bind(this);
        this.handleText = this.handleText.bind(this);
        this.handleAccept = this.handleAccept.bind(this);
        this.handleResponseMemo = this.handleResponseMemo.bind(this);
    }

    componentDidMount() {}

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.handleOpen && nextProps.currentSwap) {
            this.setState({ loading: true });
            getSwap(nextProps.currentSwap.id).then((resp) => {
                this.setState({
                    currentDate: new Date(),
                    title: resp.title,
                    child_ids: resp.child_ids,
                    expire: resp.exp_date,
                    dates: this.sortedDates(resp.dates),
                    tradeDates: this.sortedDates(resp.trade_dates),
                    temp1: this.sortedDates(resp.dates),
                    temp2: this.sortedDates(resp.trade_dates),
                    flag: false,
                    memo: resp.memo,
                    response_memo: resp.response_memo,
                    child: (resp.Members && resp.Members[0]) || null,
                    picture: resp.Members && resp.Members[0].picture,
                    loading: false,
                    swap: resp,
                    status: resp.status,
                    decline_reason: '',
                    flag1: false,
                    error: '',
                });
            });
        }
    }

    setModalVisible = function (visible, flag) {
        this.setState({ modalVisible: visible, flag: flag });
    };

    handleChildren = async function (value) {
        const { child_ids } = this.state;
        let arr = [...child_ids];
        if (arr.includes(value)) {
            let index = arr.indexOf(value);
            arr.splice(index, 1);
            if (arr.length === 0) {
                await this.setState({
                    child_ids: arr,
                    errors: { ...this.state.errors, kids: 'You have to select at least one item' },
                });
            } else {
                await this.setState({ child_ids: arr, errors: { ...this.state.errors, kids: '' } });
            }
        } else {
            await this.setState({
                child_ids: [...this.state.child_ids, value],
                errors: { ...this.state.errors, kids: '' },
            });
        }
    };

    handleText = async function (e) {
        if (e.target.value === '') {
            await this.setState({ decline_reason: e.target.value, error: 'You have to provide a decline reason!' });
        } else {
            await this.setState({ decline_reason: e.target.value, error: '' });
        }
    };

    confirmDates = async function () {
        const { flag, temp1, dates, temp2 } = this.state;
        if (!flag) {
            let err = '';
            if (temp1.length > 0) {
                err = '';
            } else if (temp1.length === 0 && dates.length === 1) {
                err = 'You have to select at least one item';
            }

            this.setState({ dates: temp1, modalVisible: false, errors: { ...this.state.errors, forfeit: err } });
        } else {
            this.setState({ tradeDates: temp2, modalVisible: false });
        }
    };

    formattedDates = function (dates) {
        let arr = [];

        arr = dates.map((date) => {
            var d = new Date(date);
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();
            var year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return year + '-' + month + '-' + day;
        });

        return arr;
    };

    handleDates = async function (e) {
        let date = this.formatDate(e);
        const { flag, temp1, temp2, dates, tradeDates } = this.state;
        if (!flag) {
            let arr = [];
            temp1.forEach((item) => {
                arr.push(item);
            });
            if (arr.includes(date)) {
                let index = arr.indexOf(date);
                arr.splice(index, 1);
                await this.setState({ temp1: arr });
            } else {
                await this.setState({ temp1: [...temp1, date] });
            }
        } else {
            if (!dates.includes(date)) {
                let arr = [];
                temp2.forEach((item) => {
                    arr.push(item);
                });
                if (arr.includes(date)) {
                    let index = arr.indexOf(date);
                    arr.splice(index, 1);
                    await this.setState({ temp2: arr });
                } else {
                    await this.setState({ temp2: [...temp2, date] });
                }
            }
        }
    };

    getChildrenNames = function (child_ids) {
        let names = '';
        const { children } = this.props;
        child_ids.forEach((child) => {
            for (let i = 0; i < children.length; i++) {
                let c = children[i];
                if (child == c.id) {
                    names = names + c.name;
                    names = names + ', ';
                }
            }
        });
        names = names.trim();
        names = names.slice(0, names.length - 1);
        return names;
    };

    sortedDates = function (dates) {
        let arr = [];

        arr = dates.map((date) => {
            var d = moment(moment.parseZone(date).format('YYYY-MM-DD')).toDate();
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();
            var year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return month + '/' + day + '/' + year;
        });

        return arr;
    };

    getExpireDuration = (expire) => {
        let expires = '';
        const expireDate = moment(new Date(expire)).zone(0).format('YYYY-MM-DD');
        const currentDate = moment(new Date()).format('YYYY-MM-DD');

        if (!expire || moment(currentDate).isAfter(expireDate)) {
            expires = 'Expired';
        } else {
            expires = moment
                .duration(moment(expireDate).valueOf() - moment(currentDate).valueOf(), 'milliseconds')
                .humanize(true);
        }
        return expires;
    };

    handleForfeit = async function () {
        const { dates } = this.state;
        await this.setState({ temp1: dates });
        this.setModalVisible(true, false);
    };

    handleTrade = async function () {
        const { tradeDates } = this.state;
        await this.setState({ temp2: tradeDates });
        this.setModalVisible(true, true);
    };

    formatDate = function (date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return month + '/' + day + '/' + year;
    };

    handleResponseMemo = function (e) {
        this.setState({ response_memo: e.target.value });
    };

    handleAccept = function () {
        const { swap, child, response_memo } = this.state;
        this.setState({ loading: true });
        let options = {
            status: 'active',
            kid: child.id,
            response_memo: response_memo,
        };

        updateSwap(swap.id, options).then((resp) => {
            this.setState({ loading: false });
            this.props.handleClose();
            this.props.refresh();
            this.props.dispatch(addLoading());
        });
    };

    handleDecline = function () {
        const { decline_reason, swap, child, response_memo } = this.state;
        if (decline_reason.length === 0) {
            this.setState({ flag1: true, error: 'You have to provide a decline reason!' });
        } else {
            this.setState({ loading: true });
            let options = {
                status: 'declined',
                kid: child.id,
                response_memo: response_memo,
                decline_reason: decline_reason,
            };

            updateSwap(swap.id, options).then((resp) => {
                this.setState({ loading: false });
                this.props.handleClose();
                this.props.refresh();
                this.props.dispatch(addLoading());
            });
        }
    };

    render() {
        const { Option } = Select;

        const {
            flag1,
            loading,
            title,
            swap,
            child_ids,
            decline_reason,
            child,
            status,
            expire,
            dates,
            tradeDates,
            temp1,
            temp2,
            currentDate,
            memo,
            response_memo,
            picture,
            error,
        } = this.state;
        const { children } = this.props;

        return (
            <Modal
                title="Swap Invite"
                className="cal-modal swap-modal exception-modal"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Form>
                    <div className="scroll-wrap enhance-height">
                        <div className="invite-top">
                            <span>{title}</span>
                            <div className="avatar-wrap">
                                <Avatar size={60} src={picture ? picture.url : user} alt="avatar-image" />
                            </div>
                        </div>
                        <div className="swap-details">
                            <span style={{ color: '#999999', fontSize: '12px' }}>Kid(s):</span>
                            <span style={{ fontSize: '14px' }}>{child && child.name}</span>
                        </div>
                        <Form.Item className="input-col select-sm" label="Offer Expires: " labelCol={{ span: 24 }}>
                            <Input value={this.getExpireDuration(expire)} readOnly />
                        </Form.Item>
                        <Form.Item className="calendar-btn">
                            <Button
                                style={{ backgroundColor: '#999999', color: '#fff' }}
                                className="full-btn color-grey"
                                type="primary"
                            >
                                <span>Select swap or forfeit Day(s)</span>
                                <span>
                                    <img src={right} alt="right-arrow" />
                                </span>
                            </Button>
                            <div className="forfeit-list">
                                {Array.isArray(dates) && dates.length !== 0
                                    ? dates.map((date, i) => <div key={i}>{date}</div>)
                                    : null}
                            </div>
                        </Form.Item>
                        <div style={{ marginBottom: '20px' }}></div>
                        <Form.Item className="calendar-btn" label="Optional" labelCol={{ span: 24 }}>
                            <Button
                                style={{ backgroundColor: '#999999', color: '#fff' }}
                                className="full-btn color-grey"
                                type="primary"
                            >
                                <span>Select Suggested Trade Day(s)</span>
                                <span>
                                    <img src={right} alt="right-arrow" />
                                </span>
                            </Button>
                            <div className="trade-list">
                                {Array.isArray(tradeDates) && tradeDates.length !== 0
                                    ? tradeDates.map((date, i) => <div key={i}>{date}</div>)
                                    : null}
                            </div>
                        </Form.Item>
                        <Form.Item className="sm-textarea" label="Swap memo" labelCol={{ span: 24 }}>
                            <TextArea value={memo} readOnly />
                        </Form.Item>
                        <Form.Item className="sm-textarea" label="Responses memo:" labelCol={{ span: 24 }}>
                            <TextArea value={response_memo} onChange={this.handleResponseMemo} />
                        </Form.Item>
                        {flag1 === true && (
                            <Form.Item className="sm-textarea" label="Decline Reason" labelCol={{ span: 24 }}>
                                <TextArea value={decline_reason} onChange={this.handleText} />
                                {flag1 === true && error !== '' ? (
                                    <div style={{ color: 'red', fontSize: '12px' }}>{error}</div>
                                ) : null}
                            </Form.Item>
                        )}
                    </div>
                    <div className="btn-scroll-wrap">
                        {flag1 === false && (
                            <Form.Item>
                                <Button onClick={this.handleAccept} className="save-btn" type="primary">
                                    Accept
                                </Button>
                            </Form.Item>
                        )}
                        <Form.Item>
                            <Button onClick={this.handleDecline} className="red-btn" type="primary">
                                Decline
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparent } = serviceReducer;
    return { user, error, children, coparent };
};

export default connect(mapStateToProps)(SwapInvite);
