import React, { Component } from 'react';
import 'pages/SubscriptionNew/EditSubscription.scss';
import {
    Card,
    Button,
    Modal,
    Checkbox,
    Form,
    Input,
    Select,
    Empty,
    Tabs,
    Radio
} from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { relativeTimeThreshold } from 'moment';

import LoadingOverlay from 'react-loading-overlay';
import { US_states } from 'pages/Household/Household/StateData.js';
import {
    stripeCardNumberValidation,
    stripeCardExpirValidation,
    textWithSpacesOnly,
    minLength,
} from 'pages/SubscriptionNew/validations';
import {
    addCard
} from 'services/subscriptionService';
import SwapErrorModal from 'pages/CalenderInner/Swap/SwapErrorModal';

const { Option } = Select;
const { TextArea } = Input;

const dropDownArr = [
    { addressFields: ['address', 'city', 'zip', 'state', 'licence'], name: '+1 United States', value: 'US' },
    {
        addressFields: ['street', 'city', 'provinceOrTerritory', 'postalCode', 'licence'],
        name: '+1 Canada',
        value: 'CA',
    },
];
const years = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039];
const findDebitCardType = (cardNumber) => {
    const regexPattern = {
        MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
        VISA: /^4[0-9]{2,}$/,
        AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
        DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
        DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
        JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
    };
    for (const card in regexPattern) {
        if (cardNumber.replace(/[^\d]/g, '').match(regexPattern[card])) return card;
    }
    return '';
};


class AddCardModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            country_code: 'US',
            state: 'AL',
            city: '',
            cardError: '',
            expiryError: '',
            securityCodeError: '',
            expiryMonthError: '',
            expiryYearError: '',
            expiryYearCodeError: '',
            card: '',
            expiry: '',
            securityCode: '',
            error: '',
            cardType: '',
            exp_month: '',
            exp_year: '',
            card_type: '',
            total: '',
            discountCode: '',
            cardNumber: '',
            cvc: '',
            zip: '',
            expiryDate: '',
            loading: false,
            modalVisible: false,
        };
        this.expiryMonthYearChangeHandler = this.expiryMonthYearChangeHandler.bind(this);
        this.handleCardNumber = this.handleCardNumber.bind(this);
        this.handleSecurityCode = this.handleSecurityCode.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
    }

    componentDidMount() { }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.openModal) {
            this.setState({
                first_name: '',
                last_name: '',
                country_code: 'US',
                state: 'AL',
                city: '',
                cardError: '',
                expiryError: '',
                securityCodeError: '',
                expiryMonthError: '',
                expiryYearError: '',
                expiryYearCodeError: '',
                card: '',
                expiry: '',
                securityCode: '',
                error: '',
                cardType: '',
                exp_month: '',
                exp_year: '',
                card_type: '',
                total: '',
                discountCode: '',
                cardNumber: '',
                cvc: '',
                zip: '',
                expiryDate: '',
                loading: false,
                modalVisible: false
            })
        }
    }

    setModalVisible = function (visible) {
        this.setState({ modalVisible: visible });
    }

    handleCardNumber = async function (e) {
        const { card } = this.state;
        if (e.target.value === '') {
            await this.setState({ card: e.target.value, card_type: '', cardError: '' });
        }
        else {
            let _card = e.target.value;
            let cardTypeText = findDebitCardType(_card);
            this.setState({ card_type: cardTypeText });
            let errorText = stripeCardNumberValidation(_card);
            if (_card.length === 16 && _card.trim() === '') {
                errorText = 'Enter 16 digit valid Card number';
            }
            this.setState({ cardError: errorText, card: _card });
            
        }
    }

    expiryMonthYearChangeHandler = (event, key) => {
        let { expiryYearCodeError, expiryMonthError } = this.state;
        if (key == 'exp_year') {
            expiryYearCodeError = '';
        } else if (key == 'exp_month') {
            expiryMonthError = '';
        }
        this.setState({ [key]: event, expiryYearCodeError, expiryMonthError }, () => {
            if (this.state.exp_month !== '' && this.state.exp_year !== '') {
                let errorText = stripeCardExpirValidation(`${this.state.exp_month}/${this.state.exp_year}`);
                this.setState({ expiryMonthError: errorText });
            }
        });
    };

    handleSecurityCode = function (e) {
        const { securityCode } = this.state;
        if (e.target.value === '') {
            this.setState({ securityCode: e.target.value, securityCodeError: '' });
        } else {
            let _cvv = e.target.value;
            let errorText = _cvv === '' ? 'Required Field' : minLength(3)(_cvv);
            if (!errorText) {
                let isnum = /^\d+$/.test(_cvv);
                if (!isnum) {
                    errorText = 'Invalid input value for cvv number';
                }
            }
            
            this.setState({ securityCode: _cvv, securityCodeError: errorText });
        }
    }

    validate = function() {
        const {
            cardError,
            card,
            exp_month,
            exp_year,
            securityCode,
            securityCodeError,
            expiryMonthError,
            expiryYearError,
            expiryYearCodeError
        } = this.state;

        let _cardError = '';
        let _expiryMonthError = '';
        let _expiryYearCodeError = '';
        let _securityCodeError = '';

        if (card === '') {
            _cardError = 'Required Field';
        } else if (card.length !== 16) {
            _cardError = "Enter 16 digit valid Card number";
        } else if (!card.replace(/\s/g, '').length) {
            _cardError = "Card number should not contain only white spaces";
        }

        if (exp_month === '') {
            _expiryMonthError = 'Required Field';
        }
        if (exp_year === '') {
            _expiryYearCodeError = 'Required Field';
        }

        if (securityCode === '') {
            _securityCodeError = 'Required Field';
        } else {
            let isnum = /^\d+$/.test(securityCode);
            if (!isnum) {
                _securityCodeError = 'Invalid input value for cvv number';
            }
        }


        if (exp_month !== '' || exp_year !== '') {
            let errorText = stripeCardExpirValidation(`${this.state.exp_month}/${this.state.exp_year}`);
            _expiryMonthError = errorText;
        }

        if (_cardError !== '' || _expiryMonthError !== '' || _expiryYearCodeError !== '' || _securityCodeError !== '') {
            this.setState({
                cardError: _cardError,
                expiryMonthError: _expiryMonthError,
                expiryYearCodeError: _expiryYearCodeError,
                securityCodeError: _securityCodeError
            });
        } else {
            if (_cardError || _expiryMonthError || _expiryYearCodeError || _securityCodeError) {
                
            } else {
                this.handleSubmit();
            }
        }
    }

    handleSubmit = function() {
        const {
            card,
            exp_month,
            exp_year,
            securityCode
        } = this.state;
        this.setState({ loading: true });

        let userInfo = JSON.parse(localStorage.getItem('resp'));
        let data = userInfo.data;
        let subscription = data.subscription;
        let customer = subscription.customer;

        let _card = {
            card_number: card,
            city: customer.billing_address.city,
            country: customer.billing_address.country,
            cvv_number: securityCode,
            expiry_month: exp_month,
            expiry_year: exp_year,
            first_name: customer.first_name,
            last_name: customer.last_name,
            payment_gateway: "authorize_net",
            state: customer.billing_address.state,
            street: customer.billing_address.street,
            zip: customer.billing_address.zip
        };

        addCard(_card)
            .then(resp => {
                this.setState({ loading: false });
                if (resp.data && resp.data.code == 0) {
                    this.props.closeModal();
                    this.props.refresh();
                } else {
                    this.setModalVisible(true);
                }
        })
    }

    render() {
        const {
            first_name,
            last_name,
            country_code,
            state,
            city,
            card,
            securityCode,
            cardError,
            exp_month,
            exp_year,
            loading
        } = this.state;

       

        return (
            <Modal
                title="Add Card"
                centered
                maskClosable={false}
                visible={this.props.openModal}
                className="add-card-modal"
                onOk={this.props.closeModal}
                onCancel={this.props.closeModal}
            >
             <LoadingOverlay
                active={loading ? true : false}
                className={'loader-wrap'}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '100px',
                        '& svg circle': {
                            stroke: 'rgb(56, 160, 235)',
                        },
                    }),
                }}
                ></LoadingOverlay>
            <div>
                <div className="input-sm">
                    <Input
                        placeholder="Card Number"
                        name="card"
                        value={card}
                        onChange={this.handleCardNumber}
                        autoComplete="off"
                        required
                    />
                    {this.state.card_type}
                    {this.state.cardError ? (
                        <div className="sub-form-err">{this.state.cardError}</div>
                    ) : null}
                </div>

                <div className="input-group-sm input-sm">
                    <React.Fragment>
                        <div className="mr-5 bottom-input">
                            <Select
                                className="slt-2-box"
                                placeholder="Expiry Month"
                                name="exp_month"
                                value={exp_month === '' ? 'Expiry Month' : exp_month}    
                                onChange={(e) => this.expiryMonthYearChangeHandler(e, 'exp_month')}
                            >
                                <option selected> Expiry Month </option>
                                <option value="01"> 01 </option>
                                <option value="02"> 02 </option>
                                <option value="03"> 03 </option>
                                <option value="04"> 04 </option>
                                <option value="05"> 05 </option>
                                <option value="06"> 06 </option>
                                <option value="07"> 07 </option>
                                <option value="08"> 08 </option>
                                <option value="09"> 09 </option>
                                <option value="10"> 10 </option>
                                <option value="11"> 11 </option>
                                <option value="12"> 12 </option>
                            </Select>
                            {this.state.expiryMonthError ? (
                                <div className="sub-form-err">{this.state.expiryMonthError}</div>
                            ) : null}
                        </div>
                        <div className="mr-5 bottom-input">
                            <Select
                                className="slt-2-box"
                                placeholder="Expiry Year"
                                name="exp_year"
                                value={exp_year === '' ? 'Expiry Year' : exp_year}      
                                onChange={(e) => this.expiryMonthYearChangeHandler(e, 'exp_year')}
                            >
                                {years.map((year, index) => {
                                    return (
                                        !(year < new Date().getFullYear()) && (
                                            <option key={`year${index}`} value={year}>
                                            {year}
                                            </option>
                                        )
                                    );
                                    
                                })}
                            </Select>
                            {this.state.expiryYearCodeError ? (
                                <div className="sub-form-err">{this.state.expiryYearCodeError}</div>
                            ) : null}
                        </div>
                    </React.Fragment>
                    <div className="bottom-input">
                        <Input
                            name="securityCode"
                            type="text"
                            pattern="\d{3,4}"
                            placeholder="CVC"
                            value={securityCode}
                            minLength="3"
                            maxLength="3"
                            autoComplete="off"
                            onChange={this.handleSecurityCode}
                            format={this.formatCVC}
                        />
                        {this.state.securityCodeError ? (
                            <div className="sub-form-err">{this.state.securityCodeError}</div>
                        ) : null}
                    </div>
                </div>
                 
                <Button type="primary" onClick={this.validate} className="btn fp-button-primary">
                    Create
                </Button>

            </div>
                <SwapErrorModal
                    openModal={this.state.modalVisible}
                    closeModal={() => this.setModalVisible(false)}
                    msg={"Invalid input for the card number."}
                /> 
            </Modal>
        )
    }
}


export default AddCardModal;