import React, { Component } from 'react';
import 'pages/CalenderInner/Custody/Custody.scss';
import { Card, Button, Modal, Form, Input, Select, Collapse } from 'antd';
import more from 'Assets/more.svg';
import { CalendarOutlined } from '@ant-design/icons';
import Calendar from 'pages/CalenderInner/Custody/react-calendar/src';
import { connect } from 'react-redux';
import SaveCustodyModal from 'pages/CalenderInner/Custody/SaveCustodyModal';
import LoadingOverlay from 'react-loading-overlay';
import { updateCustodyStatus, updateCustody } from 'services/calendarService';
import { addLoading, removeLoading } from 'actions/loaderActions';

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const status = {
    'approved' : 'Approved',
    'declined' : 'Declined'
};

class UpdateCustodyModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            title: '',
            child: '',
            start_date: new Date(),
            dates: [],
            markedDates: [],
            currentCustody: null,
            modalVisible: false,
            modal1Visible: false,
            loading: false,
            start_time: '',
            end_time: '',
            decline_reason: '',
            error: '',
            flag: false,
        };
        this.formateDate = this.formateDate.bind(this);
        this.getDates = this.getDates.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.handleDecline = this.handleDecline.bind(this);
        this.handleText = this.handleText.bind(this);
        this.handleAccept = this.handleAccept.bind(this);
    }

    componentDidMount() {
        
    }

    
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps && nextProps.handleOpen && nextProps.currentCustody) {
            
            let obj = this.getDates(nextProps.currentCustody.start_date, nextProps.currentCustody.pattern, nextProps.currentCustody.type, nextProps.currentCustody.duration);
            this.setState({
                title: nextProps.currentCustody.title,
                child: nextProps.currentCustody.Members[0].name,
                start_date: nextProps.currentCustody.start_date,
                dates: obj ? obj.markedDates : [],
                markedDates: obj ? obj.dates : [],
                currentCustody: nextProps.currentCustody,
                start_time: nextProps.currentCustody.start_time,
                end_time: nextProps.currentCustody.end_time,
                decline_reason: '',
                error: '',
                flag: false
            });
        }
    }

    getDates = function(date, pattern, type, duration) {
        let sd = new Date(date);
        let endDate = new Date(sd.getFullYear() + 1, 12, 0);
        let obj = {};
        let arr = [];
        let darr = [];
        let items = [];

        if(type === 'irr') {
            while(sd <= endDate){
                arr.push(new Date(sd));
                sd.setDate(sd.getDate() + 1);
            }
            obj.dates = arr;
            obj.markedDates = darr;
            return obj;
        } else {
            if(pattern.toString() === '1'){
                while(sd <= endDate){
                    darr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                obj.dates = arr;
                obj.markedDates = darr;
                return obj;
            } else {
                let ar = pattern;
                for(let i = 0, num = 0;i < ar.length;){
                    if(ar[i] === '1'){
                        while(i < ar.length && ar[i] === '1'){
                            num++;
                            i++;
                            if(i >= ar.length)
                            break;
                        }
                        items.push(num+'-On');
                        num = 0;
                    } else if(ar[i] === '0'){
                        while(i < ar.length && ar[i] === '0'){
                            num++;
                            i++;
                            if(i >= ar.length)
                            break;
                        }
                        items.push(num+'-Off');
                        num = 0;
                    }  
                }
                
                while(sd <= endDate){
                    for(let i = 0;i < ar.length;i++){
                        if(ar[i] === '0'){
                            arr.push(new Date(sd));
                            sd.setDate(sd.getDate() + 1);
                        } else if(ar[i] === '1'){
                            darr.push(new Date(sd));
                            sd.setDate(sd.getDate() + 1);
                        }
                    }
                    if(type === 'alt') {
                        let pat = [];
                        for(let i = 0;i < pattern.length;i++){
                            if(pattern[i] === '0'){
                                pat.push('1');
                            }else if(pattern[i] === '1'){
                                pat.push('0');
                            }
                        }

                        for(let i = 0;i < pat.length;i++){
                            if(pat[i] === '0'){
                                arr.push(new Date(sd));
                                sd.setDate(sd.getDate() + 1);
                            } else if(pat[i] === '1'){
                                darr.push(new Date(sd));
                                sd.setDate(sd.getDate() + 1);
                            }
                        }
                    }
                }
                obj.dates = arr;
                obj.markedDates = darr;
                return obj;
            }
        }

    }

    setModalVisible = async function(visible){
        await this.setState({modalVisible: visible});
    }

    setModal1Visible = async function(visible){
        await this.setState({modal1Visible: visible});
    }

    refreshPage = function() {
        this.props.handleClose();
        this.props.refresh();
    }

    formateDate = function(d){
        let date = new Date(d);
        let month = monthNames[date.getMonth()];
        let day = date.getDate();
        let year = date.getFullYear();
        
        return month + ' ' + day + ', ' + year;
    }

    handleText = async function (e) {
        if(e.target.value === ''){
            await this.setState({ decline_reason: e.target.value, error: 'You have to provide a decline reason!' });
        } else {
            await this.setState({ decline_reason: e.target.value, error: '' });
        }
    };

    handleAccept = function(){
        const { currentCustody } = this.state;
        this.setState({loading: true});
        let options = {
            status: 'approved'
        };

        updateCustodyStatus(currentCustody.id, options)
        .then(resp => {
            this.setState({ loading: false });
            this.props.dispatch(addLoading());
            this.refreshPage();
        })
    }

    handleDecline = function(){
        const {decline_reason, currentCustody } = this.state;
        if(decline_reason.length === 0){
            this.setState({ flag: true,  error: 'You have to provide a decline reason!'});
        }else {
            this.setState({loading: true});
            let options = {
                status: 'declined',
                decline_reason: decline_reason
            };
             
            updateCustodyStatus(currentCustody.id, options)
            .then(resp => {
                this.setState({ loading: false });
                this.props.dispatch(addLoading());
                this.refreshPage();
            })
        }
    }

    render() {
        const { 
            title, 
            child, 
            start_date, 
            dates,
            start_time,
            end_time, 
            loading, 
            markedDates, 
            currentCustody,
            error,
            flag,
            decline_reason
        } = this.state;
        return (
            <Modal
                title="Normal Schedule Setup"
                className="cal-modal swap-modal sc-modal"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <div className="edit-wrap">
                    <div className="scroll-wrap enhance-height">
                        <div className="cal-wrap cust-cal-wrap">
                            <Calendar
                            markedDates={markedDates} 
                            highlightDates={dates} 
                            />
                        </div>
                        <div className="schedule-wrap">
                            {currentCustody && currentCustody.status !== 'pending' && (
                                <div className="schedule-inner">
                                    <span>Status:</span>
                                    <span style={{color: currentCustody.status === 'approved' ? 'green' : 'red'}}>{status[currentCustody.status]}</span>
                                </div>
                            )}
                            <div className="schedule-inner">
                                <span>Custody:</span>
                                <span>{title}</span>
                            </div>
                            <div className="schedule-inner">
                                <span>Child:</span>
                                <span>{child}</span>
                            </div>
                            <div className="schedule-inner">
                                <span>Start Date:</span>
                                <span>{this.formateDate(start_date)}</span>
                            </div>
                           {/* <div className="schedule-inner">
                                <span>Pickup Time</span>
                                <span>{start_time}</span>
                            </div>
                            <div className="schedule-inner">
                                <span>Drop off Time</span>
                                <span>{end_time}</span>
                            </div>*/}
                        </div>
                        {currentCustody && currentCustody.status === 'pending' && flag === false && (
                        <span style={{marginTop: '5px', marginBottom: '5px', fontSize: '12px', color: '#999999'}}>Accepting will update all calendars</span>
                        )}
                    </div>
                    <div className="btn-scroll-wrap">
                        {currentCustody && currentCustody.status === 'pending' && flag === false && (
                        <Form.Item className="btn-top">
                            <Button onClick={this.handleAccept} className="fp-button-primary full-btn" type="primary">
                                Accept
                            </Button>
                        </Form.Item>
                        )}

                        {flag === true && (
                            <Form.Item className="dispute-txt">
                                <TextArea value={decline_reason} onChange={this.handleText} />
                                {flag === true && error !== '' ? <div style={{color: 'red', fontSize: '12px'}}>{error}</div> : null}
                            </Form.Item>
                        )}
                        {currentCustody && currentCustody.status === 'pending' && (
                        <Form.Item className="btn-btm">
                            <Button onClick={this.handleDecline} className="fp-button-secondary full-btn" type="primary">
                                Decline
                            </Button>
                        </Form.Item>
                        )}

                        {currentCustody && currentCustody.status === 'approved' && (
                        <Form.Item className="btn-btm">
                            <Button onClick={() => this.setModalVisible(true)} className="fp-button-secondary full-btn" type="primary">
                                Edit Custody Schedule
                            </Button>
                        </Form.Item>
                        )}
                    </div>
                </div>
                <SaveCustodyModal
                  dates={markedDates}
                  markedDates={dates}
                  currentCustody={currentCustody}
                  refresh={() => this.refreshPage()}
                  handleOpen={this.state.modalVisible}
                  handleClose={() => this.setModalVisible(false)}
                />
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, loadings, error, coparent } = serviceReducer;
    return { children, user, loadings, error, coparent };
};

export default connect(mapStateToProps)(UpdateCustodyModal);