import React, { Component } from 'react';
import 'pages/Payment/RecurringPayments/RecurringPayments.scss';
import {
    Card,
    Button,
    Modal,
    Upload,
    Checkbox,
    InputNumber,
    DatePicker,
    Radio,
    Form,
    Input,
    Select,
    Empty,
    Tabs,
} from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { connect } from 'react-redux';
import { CalendarOutlined } from '@ant-design/icons';
import {
    updateRecurringPayment,
    setupRecurringPayment,
    modifyRecurring,
    deleteRecurringPayment
} from 'services/paymentService';
import LoadingOverlay from 'react-loading-overlay';
import { getPaymentCategories } from 'services/paymentService';
import PreviewModal from 'pages/Payment/Payments/PreviewModal';
import moment from 'moment';
import CategoryErrorModal from 'pages/Payment/Payments/CategoryErrorModal';
import ErrorModal from 'pages/Payment/Payments/ErrorModal';
import DeleteAttachmentModal from 'pages/Payment/Payments/DeleteAttachmentModal';
import CalendarDate from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import 'pages/CalenderInner/Custody/Custody.scss';
import { getThirdPartyAction } from 'actions/houseHoldAction';
import { getUserById } from 'services/userService';
import SwapErrorModal from 'pages/CalenderInner/Swap/SwapErrorModal';

const { Option } = Select;
const { TextArea } = Input;
const colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    gradient1: 'Gradient1',
    gradient2: 'Gradient2',
};

const frequencies = {
    Weekly: 'weekly',
    'Bi-Weekly (2 weeks)': 'bi-weekly',
    Monthly: 'monthly',
    '2 Months': '2-month',
    '3 Months': '3-month',
    '6 Months': '6-month',
    Yearly: 'yearly',
};

const freq = ['Weekly', 'Bi-Weekly (2 weeks)', 'Monthly', '2 Months', '3 Months', '6 Months', 'Yearly'];

class EditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isReimbursing: true,
            coParent: null,
            kids: [],
            child_ids: [],
            categories: [],
            category: null,
            amount: '',
            you: 0,
            _coparent: 0,
            user_percent: 50,
            coparent_percent: 50,
            notes: '',
            fileList: [],
            flag: false,
            file: null,
            modalVisible: false,
            modal1Visible: false,
            modal2Visible: false,
            modal3Visible: false,
            modal4Visible: false,
            flag1: false,
            files: [],
            frequency: null,
            startDate: new Date(),
            currentDate: new Date(),
            index: '',
            errors: {
                coParent: '',
                kids: '',
                categories: '',
                amount: '',
                frequency: '',
            },
            isSpecial: false,
            isThirdParty: false,
            msg: ''
        };
        this.getFormattedDate = this.getFormattedDate.bind(this);
        this.getChildrenNames = this.getChildrenNames.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.validate = this.validate.bind(this);
        this.handleCoparent = this.handleCoparent.bind(this);
        this.getChildren = this.getChildren.bind(this);
        this.handleChildren = this.handleChildren.bind(this);
        this.handleCategory = this.handleCategory.bind(this);
        this.handleAmount = this.handleAmount.bind(this);
        this.handleNotes = this.handleNotes.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.getBase64 = this.getBase64.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.handleFrequency = this.handleFrequency.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.setModal3Visible = this.setModal3Visible.bind(this);
        this.setModal4Visible = this.setModal4Visible.bind(this);
        this.setModal5Visible = this.setModal5Visible.bind(this);
        this.handleChilds = this.handleChilds.bind(this);
        this.confirmDate = this.confirmDate.bind(this);
        this.handleAccept = this.handleAccept.bind(this);
        this.refreshData = this.refreshData.bind(this);
    }

    componentDidMount() {
        if (localStorage.third_party && localStorage.third_party === 'true') {
            this.props.dispatch(getThirdPartyAction({ id: localStorage.member_id }));
        }
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        if (localStorage.third_party !== 'true' && nextProps && nextProps.handleOpen && nextProps.currentPayment && nextProps.coparents && nextProps.user) {
            let cp;

            let errors = {
                coParent: '',
                kids: '',
                categories: '',
                amount: '',
                frequency: '',
            };

            await this.setState({ errors: errors, loading: true });

            if (nextProps.user.id === nextProps.currentPayment.payer_id) {
                nextProps.coparents.forEach((c) => {
                    if (c.id === nextProps.currentPayment.receiver_id) {
                        cp = c;
                        this.getCategories(
                            cp,
                            nextProps.currentPayment.payment_category_id,
                            nextProps.currentPayment.full_amount,
                        );
                        this.getChildren(cp);
                    }
                });
            } else {
                nextProps.coparents.forEach((c) => {
                    if (c.is_registered) {
                        if (c.id === nextProps.currentPayment.payer_id) {
                            cp = c;
                            this.getCategories(
                                cp,
                                nextProps.currentPayment.payment_category_id,
                                nextProps.currentPayment.full_amount,
                            );
                            this.getChildren(cp);
                        }
                    } else if (c.is_registered === false) {
                        c.invitationsIds.forEach(cp_id => {
                            if (cp_id === nextProps.currentPayment.payer_id) {
                                cp = c;
                                this.getCategories(
                                    cp,
                                    nextProps.currentPayment.payment_category_id,
                                    nextProps.currentPayment.full_amount,
                                );
                                this.getChildren(cp);
                            }
                        })
                    }
                });
            }

            let files = [];
            if (nextProps.currentPayment.attachment && nextProps.currentPayment.attachment.length !== 0) {
                nextProps.currentPayment.attachment.forEach((item) => {
                    files.push(item.url);
                });
            }

            let isSpecial = false;
            if (nextProps.user.id === nextProps.currentPayment.created_by && nextProps.currentPayment.user_approved === false) {
                isSpecial = true;
            }

            await this.setState({
                isReimbursing: nextProps.currentPayment.asking_reimbursing,
                startDate: moment(moment.parseZone(nextProps.currentPayment.start_date).format('YYYY-MM-DD')).toDate(),
                currentDate: moment(moment.parseZone(nextProps.currentPayment.start_date).format('YYYY-MM-DD')).toDate(),
                frequency: nextProps.currentPayment.frequency,
                coParent: cp,
                flag: false,
                flag1:
                    nextProps.currentPayment.attachment && nextProps.currentPayment.attachment.length === 3
                        ? true
                        : false,
                files: files,
                child_ids: nextProps.currentPayment.child_ids,
                notes: nextProps.currentPayment.notes,
                fileList: nextProps.currentPayment.attachment || [],
                isSpecial,
                isThirdParty: false,
                loading: false
            });
        } else if(localStorage.third_party === 'true' && nextProps && nextProps.currentPayment){
            let errors = {
                coParent: '',
                kids: '',
                categories: '',
                amount: '',
                frequency: '',
            };

            await this.setState({ errors: errors, loading: true });
            getUserById(localStorage.coparent_id)
                .then(resp => {
                    if (resp) {
                        this.setState({ coParent: resp });
                        this.getCategories(resp,nextProps.currentPayment.payment_category_id,
                            nextProps.currentPayment.full_amount);
                }
            })
        
            let files = [];
            if (nextProps.currentPayment.attachment && nextProps.currentPayment.attachment.length !== 0) {
                nextProps.currentPayment.attachment.forEach((item) => {
                    files.push(item.url);
                });
            }

            let isSpecial = false;
            
            if (nextProps.currentPayment.user_approved === false) {
                isSpecial = false;
            } else if(nextProps.currentPayment.user_approved === true) {
                isSpecial = true;
            }

            await this.setState({
                isReimbursing: nextProps.currentPayment.asking_reimbursing,
                startDate: moment(moment.parseZone(nextProps.currentPayment.start_date).format('YYYY-MM-DD')).toDate(),
                currentDate: moment(moment.parseZone(nextProps.currentPayment.start_date).format('YYYY-MM-DD')).toDate(),
                frequency: nextProps.currentPayment.frequency,
                flag: false,
                flag1:
                    nextProps.currentPayment.attachment && nextProps.currentPayment.attachment.length === 3
                        ? true
                        : false,
                files: files,
                child_ids: nextProps.currentPayment.child_ids,
                notes: nextProps.currentPayment.notes,
                fileList: nextProps.currentPayment.attachment || [],
                isSpecial,
                isThirdParty: true,
                loading: false
            })

        }

        if (nextProps && nextProps.thirdPartyData) {
            this.setState({ kids: nextProps.thirdPartyData.kid });
        }
    };

    getFormattedDate = function (dateObj) {
        let month = dateObj.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = dateObj.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = dateObj.getFullYear();
        let output = month + '/' + day + '/' + parseInt(year.toString());
        return output;
    };

    formatDate = function (dateObj) {
        let month = dateObj.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = dateObj.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = dateObj.getFullYear();
        let output = parseInt(year.toString()) + '/' + month + '/' + day;
        return output;
    };

    getChildrenNames = function (child_ids) {
        let names = '';
        let normalUser = localStorage.third_party !== 'true';
        let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';
        const { children, thirdPartyData } = this.props;
        if (normalUser) {
            child_ids.forEach((child) => {
                for (let i = 0; i < children.length; i++) {
                    let c = children[i];
                    if (child == c.id) {
                        names = names + c.name;
                        names = names + ', ';
                    }
                }
            });
        } else {
            if (thirdPartyData) {
                thirdPartyData.kid.forEach(child => {
                    if (child_ids.includes(child.id)) {
                        names += child.name;
                        names += ', ';
                    }
                })
            }
        }
        names = names.trim();
        names = names.slice(0, names.length - 1);
        return names;
    };

    getCategories = function (coparent, cg_id, amount) {
        const { user } = this.props;
        const { errors, coParent } = this.state;
        let arr = [];

        let x_percent = 50,
            y_percent = 50;
        let c;
        let x = 0,
            y = 0;

        let sParams = {
            orderBy: '-updated_at',
            coparent_id: (coparent && coparent.id) || null,
        };

        if (localStorage.third_party && localStorage.third_party === 'true') {
            sParams.third_party = true;
        }

        getPaymentCategories(sParams).then((resp) => {
            if (resp) {
                let error = '';

                if (Array.isArray(resp)) {
                    resp.forEach((cg) => {
                        if (localStorage.third_party !== 'true') {
                            if (
                                (user.id === cg.coparent_user_id || user.id === cg.coparent_standalone_id) &&
                                cg.category_status === 1
                            ) {
                            } else {
                                if (
                                    (cg.name === 'Spousal Support' && cg.category_status === 3) ||
                                    (cg.name === 'Child Support' && cg.category_status === 3)
                                ) { }
                                else {
                                    arr.push(cg);
                                }
                            }
                        } else if (localStorage.third_party === 'true') {
                            if (
                                (coParent.id === cg.coparent_user_id || coParent.id === cg.coparent_standalone_id) &&
                                cg.category_status === 1
                            ) {
                            } else {
                                if (
                                    (cg.name === 'Spousal Support' && cg.category_status === 3) ||
                                    (cg.name === 'Child Support' && cg.category_status === 3)
                                ) { }
                                else {
                                    arr.push(cg);
                                }
                            }
                        }
                    });
                }

                if (errors.categories.length !== 0 && arr.length !== 0) {
                    error = 'Field is required!';
                } else if (arr.length === 0) {
                    error = 'No Categories In List! (Add Category First)';
                }

                arr.forEach((cg) => {
                    if (cg.id === cg_id) {
                        c = cg;
                    }
                });

                if (localStorage.third_party !== 'true') {
                    if (user && c) {
                        if (user.id === c.created_by_user) {
                            x_percent = c.creator_amount_percent;
                            y_percent = c.coparent_amount_percent;

                            if (amount) {
                                x = ((x_percent * parseFloat(amount)) / 100).toFixed(2);
                                y = ((y_percent * parseFloat(amount)) / 100).toFixed(2);
                            }
                        } else {
                            x_percent = c.coparent_amount_percent;
                            y_percent = c.creator_amount_percent;

                            if (amount) {
                                x = ((x_percent * parseFloat(amount)) / 100).toFixed(2);
                                y = ((y_percent * parseFloat(amount)) / 100).toFixed(2);
                            }
                        }
                    }
                } else if (localStorage.third_party === 'true') {
                    if (coParent && c) {
                        if (coParent.id === c.created_by_user) {
                            x_percent = c.creator_amount_percent;
                            y_percent = c.coparent_amount_percent;

                            if (amount) {
                                x = ((x_percent * parseFloat(amount)) / 100).toFixed(2);
                                y = ((y_percent * parseFloat(amount)) / 100).toFixed(2);
                            }
                        } else {
                            x_percent = c.coparent_amount_percent;
                            y_percent = c.creator_amount_percent;

                            if (amount) {
                                x = ((x_percent * parseFloat(amount)) / 100).toFixed(2);
                                y = ((y_percent * parseFloat(amount)) / 100).toFixed(2);
                            }
                        }
                    }
                }

                this.setState({
                    categories: arr,
                    amount: amount,
                    user_percent: x_percent,
                    coparent_percent: y_percent,
                    you: x,
                    _coparent: y,
                    category: c,
                    errors: { ...this.state.errors, categories: error },
                });
            } else {
            }
        });
    };

    validate = async function () {
        const {
            coParent,
            kids,
            categories,
            amount,
            frequency,
            isReimbursing,
            user_percent,
            coparent_percent,
        } = this.state;
        let errors = {};
        if (coParent === null) {
            errors.coParent = 'Field is required!';
        } else {
            errors.coParent = '';
        }

        if (kids.length === 0) {
            if (this.state.errors.kids) {
                errors.kids = this.state.errors.kids;
            } else {
                errors.kids = 'Field is required!';
            }
        } else {
            if (this.state.errors.kids) {
                errors.kids = this.state.errors.kids;
            } else {
                errors.kids = '';
            }
        }

        if (this.state.child_ids.length === 0) {
            errors.kids = 'Field is required!';
        } else {
            errors.kids = '';
        }

        if (categories.length === 0) {
            if (this.state.errors.categories) {
                errors.categories = this.state.errors.categories;
            } else {
                errors.categories = 'Field is required!';
            }
        } else {
            if (this.state.errors.categories) {
                errors.categories = this.state.errors.categories;
            } else {
                errors.categories = '';
            }
        }

        if (this.state.category === null) {
            errors.categories = 'Field is required!';
        } else {
            errors.categories = '';
        }

        if (amount === '' || amount === null || amount === undefined) {
            if (this.state.errors.amount) {
                errors.amount = this.state.errors.amount;
            } else {
                errors.amount = 'Field is required!';
            }
        } else {
            if (this.state.errors.amount) {
                errors.amount = this.state.errors.amount;
            } else {
                errors.amount = '';
            }
        }

        if (frequency === null) {
            errors.frequency = 'Field is required!';
        } else {
            errors.frequency = '';
        }

        let flag = false;
        if (
            errors.coParent === '' &&
            errors.kids === '' &&
            errors.categories === '' &&
            errors.amount === '' &&
            errors.frequency === ''
        ) {
            flag = true;
        }

        if (flag) {
            if (isReimbursing === false && user_percent === 0) {
                this.setModal1Visible(true);
            } else if (isReimbursing === true && coparent_percent === 0) {
                this.setModal2Visible(true);
            } else {
                this.handleSubmit();
            }
        } else {
            if (isReimbursing === false && user_percent === 0) {
                this.setModal1Visible(true);
            }
            if (isReimbursing === true && coparent_percent === 0) {
                this.setModal2Visible(true);
            }
            await this.setState({ errors: errors, flag: flag });
        }
    };

    getChildren = async function (coparent) {
        const { user, children, coparents } = this.props;
        const { errors } = this.state;
        let arr = [];
        children.forEach((child) => {
            if (child.coparent_user_id) {
                if (
                    (child.created_by_user === user.id && child.coparent_user_id === coparent.id) ||
                    (child.created_by_user === coparent.id && child.coparent_user_id === user.id)
                ) {
                    arr.push(child);
                }
            } else {
                if (child.coparent_standalone_id) {
                    coparents.forEach(cp => {
                        if(cp.name !== 'Household'){
                            if(
                                cp.id === coparent.id && (
                                child.coparent_standalone_id === cp.id ||
                                cp.invitationsIds && cp.invitationsIds.includes(child.coparent_standalone_id))
                            ){
                                arr.push(child);
                            }
                        }
                    })
                }
            }
        });

        let error = '';
        if ((errors.kids.length !== 0 || errors.categories.length !== 0) && arr.length !== 0) {
            error = 'Field is required!';
        } else if (arr.length === 0) {
            error = 'No Children In List! (Add Child First)';
        }

        await this.setState({ kids: arr, errors: { ...this.state.errors, kids: error } });
    };

    handleCoparent = async function (value) {
        if (localStorage.third_party && localStorage.third_party === 'true') { }
        else {
            const { coparents } = this.props;
            let coparent = {};
            coparents.forEach((c) => {
                if (c.id === value) {
                    coparent = c;
                }
            });
            await this.setState({
                coParent: coparent,
                child_ids: [],
                category: null,
                errors: { ...this.state.errors, coParent: '' },
            });

            this.getCategories(coparent);
            this.getChildren(coparent);
        }
    };

    handleChilds = async function (value) {
        if (value.length === 0) {
            await this.setState({
                child_ids: value,
                errors: { ...this.state.errors, kids: 'You have to select atleast one item' },
            });
        } else {
            await this.setState({ child_ids: value, errors: { ...this.state.errors, kids: '' } });
        }
    };

    handleChildren = async function (value) {
        const { child_ids, errors } = this.state;
        let arr = [...child_ids];
        if (arr.includes(value)) {
            let index = arr.indexOf(value);
            arr.splice(index, 1);
            let err = '';
            if (errors.kids.length !== 0) {
                err = '';
            } else if (arr.length == 0) {
                err = 'Field is required!';
            }
            await this.setState({ child_ids: arr, errors: { ...this.state.errors, kids: err } });
        } else {
            let err = '';
            if (errors.kids.length !== 0) {
                err = '';
            }
            await this.setState({
                child_ids: [...this.state.child_ids, value],
                errors: { ...this.state.errors, kids: err },
            });
        }
    };

    handleCategory = async function (value) {
        const { categories, amount, coParent, errors } = this.state;
        const { user } = this.props;
        let x_percent = 50,
            y_percent = 50;
        let c;
        let x = 0,
            y = 0;
        categories.forEach((category) => {
            if (category.id === value) {
                c = category;
            }
        });

        if (localStorage.third_party !== 'true') {
            if (user.id === c.created_by_user) {
                x_percent = c.creator_amount_percent;
                y_percent = c.coparent_amount_percent;

                if (amount) {
                    x = ((x_percent * parseFloat(amount)) / 100).toFixed(2);
                    y = ((y_percent * parseFloat(amount)) / 100).toFixed(2);
                }
            } else {
                x_percent = c.coparent_amount_percent;
                y_percent = c.creator_amount_percent;

                if (amount) {
                    x = ((x_percent * parseFloat(amount)) / 100).toFixed(2);
                    y = ((y_percent * parseFloat(amount)) / 100).toFixed(2);
                }
            }
        } else if (localStorage.third_party === 'true') {
            if (coParent.id === c.created_by_user) {
                x_percent = c.creator_amount_percent;
                y_percent = c.coparent_amount_percent;

                if (amount) {
                    x = ((x_percent * parseFloat(amount)) / 100).toFixed(2);
                    y = ((y_percent * parseFloat(amount)) / 100).toFixed(2);
                }
            } else {
                x_percent = c.coparent_amount_percent;
                y_percent = c.creator_amount_percent;

                if (amount) {
                    x = ((x_percent * parseFloat(amount)) / 100).toFixed(2);
                    y = ((y_percent * parseFloat(amount)) / 100).toFixed(2);
                }
            }
        }


        let err = '';
        if (errors.categories.length !== 0) {
            err = '';
        }

        await this.setState({
            category: c,
            you: x,
            errors: { ...this.state.errors, categories: err },
            _coparent: y,
            user_percent: x_percent,
            coparent_percent: y_percent,
        });
    };

    handleAmount = async function (e) {
        const { amount, coParent, category, you, _coparent } = this.state;
        const { user } = this.props;

        let amt = amount;
        let x, y;
        let error = '';
        if (category === null) {
            if (e.target.value !== '') {
                if (isNaN(e.target.value)) {
                    x = 0.0;
                    y = 0.0;
                    error = 'Amount Not Valid!';
                }
                else if (e.target.value == 0) {
                    x = 0.0;
                    y = 0.0;
                    error = 'Amount Not Valid!';                    
                }
                else if (e.target.value < 0) {
                    x = ((50 * parseFloat(e.target.value)) / 100).toFixed(2);
                    y = ((50 * parseFloat(e.target.value)) / 100).toFixed(2);
                    error = 'Amount Not Valid!';
                }
                else {
                    x = ((50 * parseFloat(e.target.value)) / 100).toFixed(2);
                    y = ((50 * parseFloat(e.target.value)) / 100).toFixed(2);

                    if (parseFloat(e.target.value) > 2000) {
                        error = 'Please fill an amount less than or equal to 2000';
                    }
                }
            } else {
                x = 0.0;
                y = 0.0;
                error = 'Field Is Required!';
            }
            this.setState({
                amount: e.target.value,
                you: x,
                _coparent: y,
                user_percent: 50,
                coparent_percent: 50,
                errors: { ...this.state.errors, amount: error },
            });
        } else {
            if (e.target.value !== '') {
                if (isNaN(e.target.value)) {
                    x = 0.0;
                    y = 0.0;
                    error = 'Amount Not Valid!';
                }
                else if (e.target.value == 0) {
                    x = 0.0;
                    y = 0.0;
                    error = 'Amount Not Valid!';                    
                }
                else if (e.target.value < 0) {
                    if (localStorage.third_party !== 'true') {
                        if (category.created_by_user === user.id) {
                            x = ((category.creator_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                            y = ((category.coparent_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                        } else if (category.created_by_user === coParent.id) {
                            x = ((category.coparent_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                            y = ((category.creator_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                        }
                    } else if (localStorage.third_party === 'true') {
                        if (category.created_by_user === coParent.id) {
                            x = ((category.creator_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                            y = ((category.coparent_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                        } else {
                            x = ((category.coparent_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                            y = ((category.creator_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                        }
                    }

                    error = 'Amount Not Valid!';
                }
                else {
                    if (localStorage.third_party !== 'true') {
                        if (category.created_by_user === user.id) {
                            x = ((category.creator_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                            y = ((category.coparent_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                        } else if (category.created_by_user === coParent.id) {
                            x = ((category.coparent_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                            y = ((category.creator_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                        }
                    } else if (localStorage.third_party === 'true') {
                        if (category.created_by_user === coParent.id) {
                            x = ((category.creator_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                            y = ((category.coparent_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                        } else {
                            x = ((category.coparent_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                            y = ((category.creator_amount_percent * parseFloat(e.target.value)) / 100).toFixed(2);
                        }
                    }


                    if (parseFloat(e.target.value) > 2000) {
                        error = 'Please fill an amount less than or equal to 2000';
                    }
                }
            } else {
                x = 0.0;
                y = 0.0;
                error = 'Field Is Required!';
            }
            this.setState({
                amount: e.target.value,
                you: x,
                _coparent: y,
                errors: { ...this.state.errors, amount: error },
            });
        }

        if (e.target.value !== '' && isNaN(e.target.value) === false && e.target.value.includes('.')) {
            let err = '';
            if (e.target.value - Math.floor(e.target.value) !== 0) {
                if (e.target.value < 0) {
                    await this.setState({ errors: { ...this.state.errors, amount: 'Amount Not Valid!' } });
                } else {
                    let str = e.target.value.split('.')[1];
                    if (str !== undefined) {
                        if (str.length > 2) {
                            await this.setState({ errors: { ...this.state.errors, amount: 'Amount Not Valid!' } });
                        } else {
                            await this.setState({ errors: { ...this.state.errors, amount: '' } });
                        }
                    }
                }
            } else {
                let str = e.target.value.split('.')[1];
                if (str === '') {
                    err = 'Amount Not Valid!';
                } else {
                    err = '';
                }
                await this.setState({ errors: { ...this.state.errors, amount: err } });
            }
        }
    };

    handleNotes = function (e) {
        this.setState({ notes: e.target.value });
    };

    handleChange = function (e) {
        if (e.target.value === '1') {
            this.setState({ isReimbursing: true });
        } else if (e.target.value === '2') {
            this.setState({ isReimbursing: false });
        }
    };

    handleFrequency = async function (value) {
        await this.setState({ frequency: value, errors: { ...this.state.errors, frequency: '' } });
    };

    getBase64 = function (file) {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (upload) {
                resolve(upload.target.result);
            };
            reader.onerror = function (error) {
               
            };
        });
    };

    handleUpload = async function (event) {
        let flag = false;
        if (this.state.files.length === 2) {
            flag = true;
        }
        this.setState({
            files: [...this.state.files, URL.createObjectURL(event.target.files[0])],
            flag1: flag,
        });

        var reader = new FileReader();
        var file = event.target.files[0];
        var name = event.target.files[0].name;
        let url = URL.createObjectURL(file);
        let base64;

        base64 = await this.getBase64(file);

        let upload = {
            uri: url,
            name: name,
            base64: base64,
        };

        this.setState({ fileList: [...this.state.fileList, upload] });
    };

    handleSubmit = function () {
        this.setState({ loading: true });
        const {
            coParent,
            isReimbursing,
            frequency,
            startDate,
            amount,
            user_percent,
            coparent_percent,
            child_ids,
            notes,
            fileList,
            category,
        } = this.state;
        const { user, currentPayment } = this.props;
        let sender_amt, payer_amt;
        if (isReimbursing === false) {
            sender_amt = (amount - user_percent * 0.01 * amount).toFixed(2);
            payer_amt = (user_percent * 0.01 * amount).toFixed(2);
        } else {
            sender_amt = (user_percent * 0.01 * amount).toFixed(2);
            payer_amt = amount - (user_percent * 0.01 * amount).toFixed(2);
        }

        var d = new Date();
        d.setDate(d.getDate() - 1);

        const options = {
            notes: notes,
            full_amount: amount,
            sender_amount: sender_amt,
            payer_amount: payer_amt,
            payment_category_id: category.id,
            receipt_available: true,
            payment_method: 'paypal',
            coparent_id: coParent.id,
            child_ids: child_ids,
            start_date: startDate.toISOString(),
            frequency: frequency,
            asking_reimbursing: isReimbursing,
            attachment: fileList,
            id: currentPayment.id,
        };

        if (localStorage.third_party && localStorage.third_party === 'true') {
            options.thirdParty = true;
        }


        updateRecurringPayment(currentPayment.id, options).then(async (resp) => {
            await this.setState({ loading: false });
            if (resp.code) {
                this.setModal5Visible(true, resp.message);
            } else {
                this.props.handleClose();
                this.props.refresh();
            }
        });
    };

    handleDelete = async function (index) {
        this.setState({ index: index });
        const { files, fileList } = this.state;
        let arr = [...files];
        let farr = [...fileList];

        arr.splice(this.state.index, 1);
        farr.splice(this.state.index, 1);

        await this.setState({ files: arr, fileList: farr, flag1: false });
    };

    setModalVisible = function (modalVisible, file) {
        this.setState({ modalVisible: modalVisible, file: file });
    };

    setModal1Visible = function (modalVisible) {
        this.setState({ modal1Visible: modalVisible });
    };

    setModal2Visible = function (modalVisible) {
        this.setState({ modal2Visible: modalVisible });
    };

    setModal3Visible = function (modalVisible, index) {
        this.setState({ modal3Visible: modalVisible, index: index });
    };

    setModal4Visible = function (visible) {
        this.setState({ modal4Visible: visible });
    };

    setModal5Visible = function (visible, msg) {
        this.setState({ modal5Visible: visible, msg });
    }

    handleDate = async function (value) {
        if (this.formatDate(value) === this.formatDate(new Date())) {
            await this.setState({ currentDate: value });
        } else if (value < new Date()) {
        } else {
            await this.setState({ currentDate: value });
        }
    };

    confirmDate = async function () {
        const { currentDate } = this.state;
        await this.setState({ startDate: currentDate, modal4Visible: false });
    };

    handleAccept = function () {
        const { currentPayment } = this.props;
        this.setState({ loading: true });
        let data = {
            id: currentPayment.id,
            user_approved: true
        };

        modifyRecurring(data)
            .then(resp => {
                this.setState({ loading: false });
                if (resp.success) {
                    this.props.handleClose();
                    this.props.refresh();
                }
        })
    }

    handleReject = () => {
        const { currentPayment } = this.props;
        this.setState({ loading: true });

        deleteRecurringPayment(currentPayment.id)
        .then(resp => {
            this.setState({ loading: false });
            if (resp.success) {
                this.props.handleClose();
                this.props.refresh();
            }
        })
    }
    refreshData () {
         this.setState({
            loading: false,
            isReimbursing: true,
            coParent: null,
            kids: [],
            child_ids: [],
            categories: [],
            category: null,
            amount: '',
            you: 0,
            _coparent: 0,
            user_percent: 50,
            coparent_percent: 50,
            notes: '',
            fileList: [],
            flag: false,
            file: null,
            modalVisible: false,
            modal1Visible: false,
            modal2Visible: false,
            modal3Visible: false,
            modal4Visible: false,
            flag1: false,
            files: [],
            frequency: null,
            startDate: new Date(),
            currentDate: new Date(),
            index: '',
            errors: {
                coParent: '',
                kids: '',
                categories: '',
                amount: '',
                frequency: '',
            },
            isSpecial: false,
            isThirdParty: false,
            msg: ''
        });
        this.props.handleClose();
    }

    render() {
        const { user, coparents, children, currentPayment } = this.props;
        const {
            isReimbursing,
            fileList,
            startDate,
            frequency,
            files,
            flag1,
            loading,
            flag,
            coParent,
            kids,
            categories,
            category,
            child_ids,
            amount,
            errors,
            you,
            _coparent,
            user_percent,
            coparent_percent,
            notes,
            currentDate,
            isSpecial,
            isThirdParty
        } = this.state;
        const dateFormat = 'YYYY/MM/DD';

        let normalUser = localStorage.third_party !== 'true';
        let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';
      
        return (
            <Modal
                centered
                visible={this.props.handleOpen}
                backdrop="static"
                maskClosable={false}
                className="modal-container create-pay-modal"
                onOk={this.refreshData}
                onCancel={this.refreshData}
                title="Edit Recurring Payment"
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Form>
                    <div className="form-scroll">
                        <Radio.Group disabled={(normalUser && currentPayment && currentPayment.user_approved === false) || (thirdPartyUser && currentPayment && currentPayment.user_approved === true) ? true : false} name="radiogroup" value={isReimbursing ? 1 : 2}>
                            <Radio onClick={this.handleChange} value={1}>
                                <div className="option-inner">
                                    <span>Request Payment - (Send Requests)</span>
                                    <span>Setup recurring payment requests from Co-parents</span>
                                </div>
                            </Radio>
                            <Radio onClick={this.handleChange} value={2}>
                                <div className="option-inner">
                                    <span>Reimburse Co-parent - (Pay Co-parent)</span>
                                    <span>
                                        Setup recurring payments and send money to <br />
                                        Co-Parent
                                    </span>
                                </div>
                            </Radio>
                        </Radio.Group>
                        <div className="select-wrap multi-select">
                            {normalUser && user && currentPayment && (user.id === currentPayment.created_by && currentPayment.user_approved === true) && (
                                <Select
                                    value={coParent === null || coParent === undefined ? 'Select Co-parent' : normalUser ? coParent.name : coParent.first_name}
                                    onChange={this.handleCoparent}
                                    className="single-sel"
                                >
                                    
                                    {Array.isArray(coparents) &&
                                        coparents.map(
                                            (coparent, i) =>
                                                coparent.name !== 'Household' && (
                                                    <Option
                                                        key={i}
                                                        className={
                                                            coParent && coParent.id === coparent.id ? 'text-color' : ''
                                                        }
                                                        value={coparent.id}
                                                    >
                                                        {coparent.name}
                                                    </Option>
                                                ),
                                        )}
                                </Select>
                            )}

                            {thirdPartyUser && coParent && currentPayment && currentPayment.user_approved === false && (
                                <Select
                                value={coParent === null || coParent === undefined ? 'Select Co-parent' : normalUser ? coParent.name : coParent.first_name}
                                onChange={this.handleCoparent}
                                className="single-sel"
                                >
                                    <Option
                                            className={'text-color'}
                                            value={coParent.id}
                                        >
                                            {coParent.first_name}
                                    </Option>
                                </Select>

                            )}
                            {normalUser && user && currentPayment && ((user.id !== currentPayment.created_by) || (user.id === currentPayment.created_by && currentPayment.user_approved === false)) && (
                                <Input className="coparent-input" value={coParent && coParent.name}  readOnly/>
                            )}
                            {thirdPartyUser && currentPayment && currentPayment.user_approved === true && (
                                 <Input className="coparent-input" value={localStorage.getItem('coparent_name')}  readOnly/>
                            )}
                            {errors.coParent !== '' ? (
                                <div style={{ fontSize: '12px', color: 'red' }}>{errors.coParent}</div>
                            ) : null}

                            {(normalUser && currentPayment && currentPayment.user_approved === false) || (thirdPartyUser && currentPayment && currentPayment.user_approved === true) ? (
                                  <Input className="child-input" value={this.getChildrenNames(currentPayment.child_ids)}  readOnly/>
                            ) : (
                            <Select
                                placeholder="Select kid(s)"
                                value={child_ids}
                                mode="multiple"
                                onChange={this.handleChilds}
                            >
                                {Array.isArray(kids) &&
                                    kids.map((kid, i) => (
                                        <Option
                                            key={i}
                                            className={child_ids.includes(kid.id) ? 'text-color' : ''}
                                            value={kid.id}
                                        >
                                            <span className={'dot ' + colors[kid.color]}></span>
                                            {kid.name}
                                        </Option>
                                    ))}
                            </Select>
                            )}
                            {errors.kids !== '' ? <div className="err-msg edit-err">{errors.kids}</div> : null}
                        </div>

                        <div className="select-wrap input-full">
                            {categories.length === 0 ? (
                                <Select value="Select Category">
                                    <Option>No options</Option>
                                </Select>
                            ) : (
                                (normalUser && currentPayment &&  currentPayment.user_approved === false) || (thirdPartyUser && currentPayment && currentPayment.user_approved === true) ? (
                                    <Input className="category-input" value={category && category.name}  readOnly/>      
                                ): (
                                <Select
                                    value={
                                        categories.length === 0
                                            ? 'Select Category'
                                            : category === null || category === undefined
                                            ? 'Select Category'
                                            : category.name
                                    }
                                    onChange={this.handleCategory}
                                >
                                    {Array.isArray(categories) &&
                                        categories.map((c, i) => (
                                            <Option
                                                key={i}
                                                className={category && category.id === c.id ? 'text-color' : ''}
                                                value={c.id}
                                            >
                                                {c.name}
                                            </Option>
                                        ))}
                                </Select>
                                )
                            )}
                            {errors.categories !== '' ? <div className="err-msg">{errors.categories}</div> : null}
                        </div>
                        <div className="input-full input-sm">
                            <Input
                                placeholder="Enter Full Amount"
                                value={amount}
                                onChange={this.handleAmount}
                                prefix="$"
                                readOnly={(normalUser && currentPayment && currentPayment.user_approved === false) || (thirdPartyUser && currentPayment && currentPayment.user_approved === true) ? true : false}
                            />
                            {errors.amount && errors.amount !== '' ? (
                                <div className="display-form-err">
                                    {errors.amount.length !== 0 ? errors.amount : null}
                                </div>
                            ) : null}
                        </div>
                        <div className="transfer-details">
                            <span>You</span>
                            <span>{user_percent}%</span>
                            <span>${you === 0 ? '0.00' : you}</span>
                        </div>
                        <div className="transfer-details item">
                            <span>Co - Parent</span>
                            <span>{coparent_percent}%</span>
                            <span>${_coparent === 0 ? '0.00' : _coparent}</span>
                        </div>
                        <Form.Item className="text-area-sm">
                            <TextArea
                                placeholder="Notes"
                                value={notes}
                                onChange={this.handleNotes}
                                readOnly={(normalUser && currentPayment && currentPayment.user_approved === false) || (thirdPartyUser && currentPayment && currentPayment.user_approved === true) ? true : false}
                            />
                        </Form.Item>

                        <div className="freq-wrap">
                            <Form.Item>
                                <div className="select-wrap input-full">
                                    {(normalUser && currentPayment && currentPayment.user_approved === false) || (thirdPartyUser && currentPayment && currentPayment.user_approved === true) ? (
                                       <Input className="category-input" value={frequency}  readOnly/>
                                    ): (
                                    <Select
                                        value={frequency === null ? 'Select Frequency' : frequency}
                                        onChange={this.handleFrequency}
                                    >
                                        {Array.isArray(freq) &&
                                            freq.map((item, i) => (
                                                <Option key={i} value={frequencies[item]}>
                                                    {item}
                                                </Option>
                                            ))}
                                    </Select>
                                    )}
                                    {errors.frequency !== '' ? (
                                        <div style={{ color: 'red' }}>{errors.frequency}</div>
                                    ) : null}
                                </div>
                            </Form.Item>
                            <Form.Item className="dt-picker input-dob">
                                <Form.Item label="Start Date" labelCol={{ span: 24 }}>
                                    <Input
                                        readOnly
                                        onClick={() => (normalUser && currentPayment && currentPayment.user_approved === false) || (thirdPartyUser && currentPayment && currentPayment.user_approved === true) ? null : this.setModal4Visible(true)}
                                        value={this.formatDate(startDate)}
                                        suffix={<CalendarOutlined onClick={() => (normalUser && currentPayment && currentPayment.user_approved === false) || (thirdPartyUser && currentPayment && currentPayment.user_approved === true) ? null : this.setModal4Visible(true)} />}
                                    />
                                </Form.Item>
                            </Form.Item>
                        </div>
                        <div className="upload-file">
                            {(normalUser && currentPayment && currentPayment.user_approved === false) || (thirdPartyUser && currentPayment && currentPayment.user_approved === true) ? null : (
                                <div className="modal-bottom">
                                    <span>Attachments</span>
                                    <div className="upload-wrapper">
                                        <input
                                            id="img"
                                            type={flag1 === false ? 'file' : 'button'}
                                            multiple={true}
                                            onChange={this.handleUpload}
                                        />
                                        <Button
                                            type="primary"
                                            className="add-btn fp-button-primary"
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {Array.isArray(files) &&
                                files.map((file, i) => (
                                    <div className="file-track" key={i}>
                                        <a onClick={() => this.setModalVisible(true, file)}>View</a>
                                        <span onClick={() => this.setModal3Visible(true, i)}>Delete</span>
                                    </div>
                                ))}
                        </div>
                        {Array.isArray(files) && files.length === 3 ? (
                            <span className="file-err">You can only select 3 attachments</span>
                        ) : null}
                    </div>
                    <div className="btn-wrap">
                        {!isSpecial && (
                            <div className="full-btn">
                                <Button type="primary" onClick={this.validate} className="modal-btn fp-button-primary">
                                    Save
                                </Button>
                            </div>
                        )}
                        {isSpecial && !isThirdParty && (
                            <div className="full-btn">
                                <Button type="primary" onClick={this.handleAccept} className="modal-btn fp-button-primary">
                                    Accept
                                </Button>
                            </div>
                        )}
                        {isSpecial && !isThirdParty && (
                            <div className="full-btn">
                                <Button type="primary" onClick={this.handleReject} className="modal-btn fp-button-secondary">
                                    Reject
                                </Button>
                            </div>
                        )}
                    </div>
                </Form>
                <Modal
                    title="Calendar"
                    className="cal-modal swap-modal sc-modal"
                    centered
                    visible={this.state.modal4Visible}
                    onOk={() => this.setModal4Visible(false)}
                    onCancel={() => this.setModal4Visible(false)}
                >
                    <div className="edit-wrap cal-custom">
                        <div className="scroll-wrap">
                            <div className="cal-wrap">
                                <CalendarDate selected={currentDate} onChange={(e) => this.handleDate(e)} />
                            </div>
                        </div>
                        <div className="btn-scroll-wrap">
                            <Form.Item className="btn-top">
                                <Button onClick={this.confirmDate} className="fp-button-primary" type="primary">
                                    Confirm
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Modal>
                <PreviewModal
                    file={this.state.file}
                    handleOpen={this.state.modalVisible}
                    handleClose={() => this.setModalVisible(false)}
                />
                <CategoryErrorModal
                    openModal={this.state.modal1Visible}
                    closeModal={() => this.setModal1Visible(false)}
                />
                <ErrorModal openModal={this.state.modal2Visible} closeModal={() => this.setModal2Visible(false)} />
                <DeleteAttachmentModal
                    index={this.state.index}
                    openModal={this.state.modal3Visible}
                    closeModal={() => this.setModal3Visible(false)}
                    delete={() => this.handleDelete()}
                />
                 <SwapErrorModal
                    openModal={this.state.modal5Visible}
                    closeModal={() => this.setModal5Visible(false, '')}
                    msg={this.state.msg}
                />
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer, houseHoldReducer }) => {
    const { user, error, children, coparents, coparent } = serviceReducer;
    const { thirdPartyData } = houseHoldReducer;
    return { user, error, children, coparents, coparent, thirdPartyData };
};

export default connect(mapStateToProps)(EditModal);
