import React, { Component } from 'react';
import 'pages/Signin/ResetPassword.scss';
import cover from 'Assets/cover.png';
import logo from 'Assets/kincrew-logo.svg';
import arrowWhite from 'Assets/arrow-right.svg';
import { Form, Input, Button, Checkbox, Modal } from 'antd';
import { resetPassword, verifiedToken } from '../../services/forgotPasswordService';
import ConnectionErrorModal from 'Components/Modal/ConnectionModal';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            confirmPassword: '',
            resPonseMsg: '',
            modalVisible: false,
            LoadingIs: false,
            tokenVerificationMsg: '',
            tokenInvalidIs: false,
            mobileIsDetect: false,
            errorsNewPass: '',
            errorsConfirmPass: '',
            modal1Visible: false,
            modal2Visible: false,
            // plans: props.location.dataProps,
        };
        this.onChangePasswordHandler = this.onChangePasswordHandler.bind(this);
    }
    
    componentDidMount() {
        const ParamsToken = this.props.location.search.split('?token=').slice(0)[1];
        if (ParamsToken) {
            localStorage.setItem('forgotPasswordToken', ParamsToken);
        }
        this.handleTokenVerification();

        let canRedirect = this.props.history.location && this.props.history.location.state ? true : false;
        if (this.state.mobileIsDetect && !canRedirect) {
            this.props.history.push({
                pathname: '/forgotpassword',
            });
        }
    }

    onChangePasswordHandler = async function (event, key) {
        let s = event.target.value;
        if (key === 'newPassword') {
            await this.setState({ newPassword: s });
            this.validate(s);
            if(this.state.confirmPassword !== '') {
                this.passwordMatch(this.state.confirmPassword);
            }
        }
        if (key === 'confirmPassword') {
            await this.setState({ confirmPassword: s });
            this.passwordMatch(s);
        }
    };

    handleResponseModal = (modalVisible) => {
        this.setState({ modalVisible: modalVisible }, () => this.props.history.push('/'));
    };

    setModal2Visible = (visible) => {
        this.setState({ modal2Visible: visible });
    }

    isDisbaleChangePasswordButton = () => {
        return this.state.newPassword !== this.state.confirmPassword ? true : false;
    };

    changePasswordButtonHandler = () => {
        this.setState({ LoadingIs: true });
        resetPassword({ password: this.state.newPassword })
            .then((response) => {
                if (response) {
                    this.setState({ resPonseMsg: response.message, modalVisible: true, LoadingIs: false });
                }
            })
            .catch((err) => {
                this.setModal1Visible(true);
            });
    };

    checkPassword = async () => {
        const { errorsNewPass, errorsConfirmPass } = this.state;
        if(errorsNewPass) {
            await this.setState({ resPonseMsg: errorsNewPass });
            this.setModal2Visible(true);
        } else if (errorsConfirmPass) {
            await this.setState({ resPonseMsg: errorsNewPass });
            this.setModal2Visible(true);
        } else {
            this.changePasswordButtonHandler();
        }
    }

    validate = (values) => {
        if (!values) {
            this.setState({ errorsNewPass: 'Password is required!' });
        } else if (!values.replace(/\s/g, '').length) {
            this.setState({ errorsNewPass: 'Invalid password!' });
        } else if (values.length < 8) {
            this.setState({ errorsNewPass: 'Password is too short! (Must be atleast 8 characters)' });
        } else if (values.length > 50) {
            this.setState({ errorsNewPass: 'Password is too long! (Must be max 50 characters)' });
        } else {
            this.setState({ errorsNewPass: '' });
        }
    };

    passwordMatch = (values) => {
        if (values !== this.state.newPassword) {
            this.setState({ errorsConfirmPass: 'Password Do Not Match!' });
        } else {
            this.setState({ errorsConfirmPass: '' });
        }
    };

    checkValidation = () => {
        if (this.state.newPassword === '') {
            this.setState({ errorsNewPass: 'Password is required!' });
        }
        if (this.state.confirmPassword === '') {
            this.setState({ errorsConfirmPass: 'Confirm password is required!' });
        }
    };

    handleTokenVerification = () => {
        verifiedToken()
            .then((res) => {
                if (res.success === false) {
                    this.setState({
                        tokenVerificationMsg: res.message,
                        tokenInvalidIs: true,
                        modalVisible: true,
                    });
                    setTimeout(() => {
                        this.setState({ tokenInvalidIs: false });
                        this.props.history.push('/');
                    }, 4000);
                }
            })
            .catch((err) => {
                this.setModal1Visible(true);
            });
    };

    setModal1Visible = (visible) => {
        this.setState({ modal1Visible: visible });
    };

    render() {
        const {
            disabledIs,
            newPassword,
            confirmPassword,
            resPonseMsg,
            modalVisible,
            modal2Visible,
            LoadingIs,
            tokenInvalidIs,
            tokenVerificationMsg,
            errorsNewPass, errorsConfirmPass
        } = this.state;


        return (
            <div className="signin-wrapper">
                <div className="signin-cover">{/* <img src={cover} alt="kincrew-cover-image" /> */}</div>
                <div className="signin-details">
                    <Modal
                        centered
                        visible={modalVisible}
                        backdrop="static"
                        maskClosable={false}
                        className="modal-container req-card"
                        onCancel={() => this.handleResponseModal(false)}
                    >
                        <div className="modal-body">
                            <h5>{`${resPonseMsg ? resPonseMsg : tokenVerificationMsg}`}</h5>
                        </div>
                    </Modal>

                    <Modal
                        centered
                        visible={modal2Visible}
                        backdrop="static"
                        maskClosable={false}
                        className="modal-container req-card"
                        onCancel={() => this.setModal2Visible(false)}
                    >
                        <div className="modal-body">
                            <h5>{`${resPonseMsg ? resPonseMsg : tokenVerificationMsg}`}</h5>
                        </div>
                    </Modal>

                    <ConnectionErrorModal
                        handleOpen={this.state.modal1Visible}
                        handleClose={() => this.setModal1Visible(false)}
                    />

                    <div className="details-inner">
                        <div className="details-head pswrd-head">
                            <div className="logo-wrap">
                                <img src={logo} alt="kincrew-logo" />
                                <span>kincrew</span>
                            </div>
                        </div>
                        <div className="data-wrap pwd-wrap">
                            <div className="sign-head">
                                <span>Reset Password?</span>
                            </div>
                            <div className="signin-form-wrap pwd-form">
                                <Form>
                                    <Form.Item
                                        label="New Password"
                                        id="newPassword"
                                        onChange={(e) => this.onChangePasswordHandler(e, 'newPassword')}
                                        labelCol={{ span: 24 }}
                                    >
                                        <Input.Password />
                                        {this.state.errorsNewPass && (
                                            <div className="form-err forgotErrorMessage">
                                                {this.state.errorsNewPass}
                                            </div>
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Confirm Password"
                                        labelCol={{ span: 24 }}
                                        id="confirmPassword"
                                        onChange={(e) => this.onChangePasswordHandler(e, 'confirmPassword')}
                                    >
                                        <Input.Password />
                                        {this.state.errorsConfirmPass && (
                                            <div className="form-err forgotErrorMessage">
                                                {this.state.errorsConfirmPass}
                                            </div>
                                        )}
                                    </Form.Item>
                                    <div className="btn-wrap btn-reset">
                                        <Button
                                            type="primary"
                                            className="btn-full"
                                            onClick={
                                                this.state.newPassword !== '' && confirmPassword === newPassword
                                                    ? () => this.checkPassword()
                                                    : () => this.checkValidation()
                                            }
                                        >
                                            <span>Next</span>
                                            <span>
                                                <img src={arrowWhite} alt="arrow-right" />
                                            </span>
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResetPassword;
