import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Button, Modal,Input, Select} from 'antd';
// import ScrollArea from 'react-scrollbar';
// import Icon from '@ant-design/icons';
// import lock from 'Assets/lock.png';
// import check from 'Assets/simple-check.png';
// import { relativeTimeThreshold } from 'moment';

// const { Option } = Select;
// const { TextArea } = Input;

class DeleteEventModal extends Component {
    constructor(props){
        super(props);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleDelete = function(){
        this.props.handleClose();
        this.props.delete();
    }

    render() {
        return (
            <Modal
            title="Delete item?"
            centered
            maskClosable={false}
            visible={this.props.handleOpen}
            className="payments-popup custom-popup del-popup"
            onOk={this.props.handleClose}
            onCancel={this.props.handleClose}
            >
              
                <Button type="primary" onClick={this.handleDelete} className="btn fp-button-primary">
                    Confirm
                </Button>

                <Button type="primary" onClick={this.props.handleClose} className="btn fp-button-secondary">
                    Cancel
                </Button>
              
            </Modal>
        );
    }
}

export default DeleteEventModal;