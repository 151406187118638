import React, { Component } from 'react';
import 'pages/CalenderInner/Events/Events.scss';
import { Modal, Form, Input, Select} from 'antd';
import { connect } from 'react-redux';

class EventDetailsModal extends Component {
    constructor(props){
        super(props);
        this.state = {
           child: null,
           title: '',
           start_date: '',
           start_time: '',
           end_time: '',
           share: true,
        };
    }

    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps && nextProps.handleOpen && nextProps.currentEvent && nextProps.currentEvent.Members) {
            this.setState({
                title: nextProps.currentEvent.title,
                start_date: nextProps.currentEvent.start_date,
                start_time: nextProps.currentEvent.start_time,
                end_time: nextProps.currentEvent.end_time,
                share: nextProps.currentEvent.share,
                child: nextProps.currentEvent.Members[0] || null,
            });
        }
    }

    formatDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [month, day, year].join('/');
    };
    
    render() {
        const { title, child, start_date, start_time, end_time, share } = this.state;
        return (
            <Modal
                title="Setup Event"
                className="cal-modal"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
                >
                <Form>
                    <div className="scroll-wrap">
                        <Form.Item className="input-sm">
                            <Input placeholder="Enter event title" value={title} />
                        </Form.Item>
                        <Form.Item className="child-name">
                            <Input className="inputName" value={child && child.name} readOnly />
                        </Form.Item>
                        <Form.Item className="input-col" label="Date:">
                            <Input placeholder="11/10/2020" value={this.formatDate(start_date)} />
                        </Form.Item>
                        <Form.Item className="input-col" label="Start Time:">
                            <Input placeholder="9:00 AM" value={start_time} />
                        </Form.Item>
                        <Form.Item className="input-col" label="End Time:">
                            <Input placeholder="5:00 PM" value={end_time} />
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, loadings, error, coparent } = serviceReducer;
    return { children, user, loadings, error, coparent };
};

export default connect(mapStateToProps)(EventDetailsModal);