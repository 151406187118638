import React, { Component, useState } from 'react';
import 'pages/Reports/ReconcileRecurring/ReconcileRecurring.scss';
import CreateReport from 'pages/Reports/ReconcileRecurring/CreateReport';
import { Card, Button, Tabs, Empty } from 'antd';
import moment, { duration } from 'moment';
import { GetActiveRecurringReport } from 'services/paymentReportsService';
import ScrollArea from 'react-scrollbar';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import SaveReport from 'pages/Reports/ReconcileRecurring/SaveReport';

const { TabPane } = Tabs;

function callback(key) {
   
}
class ReconcileRecurring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createReportVisible: false,
            detailReportVisible: false,
            refreshing: false,
            coParnetData: [],
            coParentId: null,
            validForm: false,
            selectedTab: props.selectedTab || 0,
            reportsList: [],
            limit: 10,
            offset: 0,
            archived: false,
            selectedDate: null,
            showMore: true,
            statusSel: [],
            activeTab: 1,
            LoadingIs: false,
            detailsData: null,
            selectedTab: "1"
        };
        this.setModalVisible = this.setModalVisible.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.getActiveReports = this.getActiveReports.bind(this);
        this.handleAllClose = this.handleAllClose.bind(this);
    }

    setModalVisible = async function (visible) {
        await this.setState({ createReportVisible: visible });
    };

    componentDidMount() {
        this.startPage();
    }

    setSaveModalVisible(val) {
        this.setState({ detailReportVisible: val });
    }

    startPage() {
        let offset = 0;
        let archived = false;
        this.setState({ offset, archived, showMore: true });
        this.getActiveReports(true);
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {};

    handleAllClose = async function(modal) {
        await this.setState({ [modal]: false, detailsData: [], selectedTab: "1" });
        this.startPage();
    }

    getActiveReports = async () => {
        const { showMore, offset, limit, archived } = this.state;

        // let activeTab = await this.state.activeTab;
        // let archived = activeTab == 1 ? false : true;
        if (showMore) {
            this.setState({ LoadingIs: true });
            GetActiveRecurringReport(archived, offset, limit, (data) => {
                this.setState({ LoadingIs: false });
               
                if (data && data.data.length > 0) {
                    let data1 = this.state.reportsList;
                    let newData = data.data;

                    if (offset == 0) {
                        // data1 = [...data1, ...newData];
                        this.setState({ reportsList: newData });
                    } else {
                        data1 = [...data1, ...newData];
                        this.setState({ reportsList: data1 });
                    }

                    if (newData.length < limit) {
                        this.setState({ showMore: false });
                    } else {
                        let newoffset = offset + limit;
                        this.setState({ showMore: true, offset: newoffset });
                    }
                } else {
                    this.setState({ showMore: false });
                    if (offset == 0) {
                       this.setState({ reportsList: [] }); 
                    }
                }
            });
        }
    };

    handleScroll = function (e) {
        const { archived, offset } = this.state;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this.getActiveReports();
        }
    };

    formartData(date) {
        var newdate = moment(date, 'DD-MM-YYYY');
        return moment(newdate).format('MM/DD/YY');
    }

    changeTab = (activeKey) => {
        let archived;
        if (activeKey === '1') {
            archived = false;
        } else if (activeKey === '2') {
            archived = true;
        }

        this.setState(
            {
                reportsList: [],
                offset: 0,
                showMore: true,
                archived,
                selectedTab: activeKey
            },
            () => this.getActiveReports(true),
        );
    };

    async showDetails(data) {
        let info = {
            data: data.id,
            type: this.state.archived ? 'archive' : 'active',
        };
        await this.setState({ detailsData: info });
        this.setState({ detailReportVisible: true });
    }

    render() {
        const { reportsList, LoadingIs, archived, selectedTab } = this.state;

        return (
            <div className="report-reconcile-wrap">
                <LoadingOverlay
                    active={LoadingIs}
                    className={LoadingIs ? 'd-block pop-loader' : 'd-none pop-loader'}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Card bordered={false} className="reconcile-card">
                    <div className="card-head">
                        <span>Reconcile Recurring</span>
                        <Button className="fp-button-secondary add-btn" onClick={() => this.setModalVisible(true)}>
                            +
                        </Button>
                    </div>
                    <div className="card-body">
                        <Tabs activeKey={selectedTab} onChange={this.changeTab}>
                            <TabPane tab="Active Reports" key="1">
                                <div className="tab-content-wrap" onScroll={this.handleScroll}>
                                    {reportsList && Array.isArray(reportsList) && reportsList.length !== 0 ? (
                                        reportsList.map((report, i) => (
                                            <Card
                                                bordered={false}
                                                className="card-inner"
                                                key={i}
                                                onClick={() => this.showDetails(report)}
                                            >
                                                {Object.values(report.recurringData).map((val, k) => (
                                                    <div className="card-top" key={k}>
                                                        <span>{val.name}</span>
                                                        {k == 0 && (
                                                            <span>
                                                                {'' +
                                                                    this.formartData(report.startDate) +
                                                                    ' - ' +
                                                                    this.formartData(report.endDate)}
                                                            </span>
                                                        )}
                                                    </div>
                                                ))}
                                            </Card>
                                        ))
                                    ) : (
                                        <div>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                    )}
                                </div>
                            </TabPane>

                            <TabPane tab="Archived Reports" key="2">
                                <div className="tab-content-wrap" onScroll={this.handleScroll}>
                                    {reportsList && Array.isArray(reportsList) && reportsList.length !== 0 ? (
                                        reportsList.map((report, i) => (
                                            <Card
                                                bordered={false}
                                                className="card-inner"
                                                key={i}
                                                onClick={() => this.showDetails(report)}
                                            >
                                                {Object.values(report.recurringData).map((val, k) => (
                                                    <div className="card-top" key={k}>
                                                        <span>{val.name}</span>
                                                        {k == 0 && (
                                                            <span>
                                                                {'' +
                                                                    this.formartData(report.startDate) +
                                                                    ' - ' +
                                                                    this.formartData(report.endDate)}
                                                            </span>
                                                        )}
                                                    </div>
                                                ))}
                                            </Card>
                                        ))
                                    ) : (
                                        <div>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                    <CreateReport
                        handleOpen={this.state.createReportVisible}
                        handleClose={() => this.setModalVisible(false)}
                        handleRefreshClose={() => this.handleAllClose('createReportVisible')}
                    />
                    <SaveReport
                        handleOpen={this.state.detailReportVisible}
                        handleClose={() => this.setSaveModalVisible(false)}
                        handleRefreshClose={() => this.handleAllClose('detailReportVisible')}
                        responseInfo={this.state.detailsData}
                    />
                </Card>
            </div>
        );
    }
}
const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparents, coparent } = serviceReducer;
    return { user, error, children, coparents, coparent };
};

export default connect(mapStateToProps)(ReconcileRecurring);
