import React, { Component } from 'react';
import 'pages/CalenderInner/Events/Events.scss';
import { Button, Modal, Form, Input, Select, Checkbox, TimePicker, Switch } from 'antd';
import { connect } from 'react-redux';
import Calendar from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import { CalendarOutlined } from '@ant-design/icons';
import { createEvent } from 'services/calendarService';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import DisplayErrorModal from 'pages/CalenderInner/Events/DisplayErrorModal';
import { checkCookie } from 'utils/cookies';
import { getCoParentsUserAction, getChildrenUserAction } from 'actions/userActions';
import { getUserById } from 'services/userService';
import { getThirdPartyAction } from 'actions/houseHoldAction';

const { Option } = Select;
function onChange(time, timeString) {}

var c_d = new Date();
var c_year = c_d.getFullYear();
var c_month = c_d.getMonth();
var c_day = c_d.getDate();
const colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    gradient1: 'Gradient1',
    gradient2: 'Gradient2',
};

let repeats = [
    {
        label: 'Daily',
        value: 'daily',
    },
    {
        label: 'Weekly',
        value: 'weekly',
    },
    {
        label: 'Monthly',
        value: 'monthly',
    },
    {
        label: 'Yearly',
        value: 'yearly',
    }
];

let repeatsLabel = [
    {
        label: 'Weeks',
        value: 'weekly',
    },
    {
        label: 'Month',
        value: 'monthly',
    },
    {
        label: 'Day',
        value: 'daily',
    },
    {
        label: 'Yearly',
        value: 'yearly',
    },
];

let weekDays = [
    {
        label: 'SUN',
        value: 'sunday',
        active: false,
    },
    {
        label: 'MON',
        value: 'monday',
        active: false,
    },
    {
        label: 'TUE',
        value: 'tuesday',
        active: false,
    },
    {
        label: 'WED',
        value: 'wednesday',
        active: false,
    },
    {
        label: 'THU',
        value: 'thursday',
        active: false,
    },
    {
        label: 'FRI',
        value: 'friday',
        active: false,
    },
    {
        label: 'SAT',
        value: 'saturday',
        active: false,
    },
];

class CreateEventModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            child: null,
            title: '',
            child_ids: [],
            start_date: new Date(),
            end_date: new Date(c_year, c_month + 5, c_day),
            frequency: 'daily',
            start_time: moment('12:00 am', 'hh:mm a'),
            end_time: moment('12:00', 'hh:mm a'),
            share: true,
            modalVisible: false,
            modal3Visible: false,
            modal4Visible: false,
            currentDate: new Date(),
            endDate : new Date(c_year, c_month + 5, c_day),
            every: 1,
            kids: [],
            childrenThirdParty : [],
            err: false,
            recurring: false,
            showDays: true,
            clicked: false,
            activeButton: [1,1,1,1,1,1,1],
            list : ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'],
            errors: {
                title: '',
                kids: '',
                start_time: '',
                end_time: '',
                date: '',
            },
            isThirdParty : localStorage.getItem("third_party"),
        };
        this.handleRepeats = this.handleRepeats.bind(this);
        this.handleEvery = this.handleEvery.bind(this);
        this.handleTitle = this.handleTitle.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setEndDateModal = this.setEndDateModal.bind(this);
        this.setModal3Visible = this.setModal3Visible.bind(this);
        this.setModal4Visible = this.setModal4Visible.bind(this);
        this.confirmDate = this.confirmDate.bind(this);
        this.confirmEndDate = this.confirmEndDate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleStartTime = this.handleStartTime.bind(this);
        this.handleEndTime = this.handleEndTime.bind(this);
        this.handleChildren = this.handleChildren.bind(this);
        this.getChildrenNames = this.getChildrenNames.bind(this);
        this.validate = this.validate.bind(this);
        this.handleChilds = this.handleChilds.bind(this);
        this.onRecurringChange = this.onRecurringChange.bind(this);
    }

    componentDidMount() {
        if (checkCookie() !== null) {
            this.props.dispatch(getChildrenUserAction());
        }
        if (localStorage.third_party && localStorage.third_party === 'true') {
            this.props.dispatch(getThirdPartyAction({ id : localStorage.member_id }));
        }
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        if (nextProps && nextProps.handleOpen) {
            let errors = {
                title: '',
                kids: '',
                start_time: '',
                end_time: '',
                date : '',
            };
            await this.setState({
                title: '',
                child_ids: [],
                currentDate: new Date(),
                endDate : new Date(c_year, c_month + 5, c_day),
                start_date: new Date(),
                end_date: new Date(c_year, c_month + 5, c_day),
                start_time: moment('12:00 am', 'hh:mm a'),
                end_time: moment('12:00', 'hh:mm a'),
                share: true,
                every: 1,
                recurring: false,
                frequency: 'daily',
                showDays: true,
                clicked: false,
                activeButton: [1,1,1,1,1,1,1],
                list : ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'],
                errors: errors,
            });

            // if (localStorage.third_party && localStorage.third_party === 'true') {
            //     getUserById(localStorage.coparent_id)
            //         .then(resp => {
            //             if (resp) {
            //                 this.setState({ coParent: resp });
            //         }
            //     })
            // }
            if (localStorage.third_party === 'true' && nextProps && nextProps.thirdPartyData) {
                this.setState({ childrenThirdParty: nextProps.thirdPartyData.kid });
            }
        }
    };
    formatDate = function (date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return month + '/' + day + '/' + year;
    };

    setModalVisible = function (visible) {
        this.setState({ modalVisible: visible });
    };

    setEndDateModal = function (visible) {
        this.setState({ endDateModal: visible });
    };

    setModal3Visible = function (visible) {
        this.setState({ modal3Visible: visible });
    };

    setModal4Visible = function (visible) {
        this.setState({ modal4Visible: visible });
    };

    handleRepeats = async function (value) {
        let val = [1,1,1,1,1,1,1]
        let val2 = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
        let val5 = [val2[new Date().getDay()]];
        let val6 = [0, 0, 0, 0, 0, 0, 0];
        val6[new Date().getDay()] = 1;

        this.setState({frequency : value})
        if(value == 'monthly' || value == 'yearly') this.setState({every : 1})

        if(value == 'daily' && this.state.every == 1) 
            this.setState({showDays : true,activeButton: val,
                             list : val2})
        else if(value == 'daily' && this.state.every != 1)
            this.setState({showDays : false,activeButton: [0,0,0,0,0,0,0],
                             list : []})
        else if(value == 'weekly')
            await this.setState({showDays : true,activeButton: val6,
                             list : val5 })
        else
             this.setState({showDays : false,activeButton : [0,0,0,0,0,0,0], list : []})
    };

    handleEvery = async function (value) {
        let val = [1,1,1,1,1,1,1]
        let val2 = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        let val5 = [val2[new Date().getDay()]];
        let val6 = [0, 0, 0, 0, 0, 0, 0];
        val6[new Date().getDay()] = 1;

        await this.setState({ every: value });

        if (value == 1 && this.state.frequency == 'daily') {
            this.setState({
                showDays: true,
                activeButton: val,
                list: val2
            });
        }
        else if (this.state.frequency == 'daily' && value != 1) {
            this.setState({
                showDays: false,
                activeButton: [0, 0, 0, 0, 0, 0, 0],
                list: []
            });
        }
        else if (this.state.frequency == 'weekly') {
            await this.setState({
                showDays: true,
                activeButton: val6,
                list: val5
            });
        }
        else {
            this.setState({
                showDays: false,
                activeButton: [0, 0, 0, 0, 0, 0, 0],
                list: []
            });
        }
    }

    handleTitle = function (e) {
        if (e.target.value === '') {
            this.setState({ title: e.target.value, errors: { ...this.state.errors, title: 'Field is required!' } });
        } else if (e.target.value.length > 50) {
            this.setState({
                title: e.target.value,
                errors: { ...this.state.errors, title: 'Name length value exceeded! (50 symbols)' },
            });
        }else if (!e.target.value.replace(/\s/g, '').length) {
            this.setState({
                title: e.target.value,
                errors: { ...this.state.errors, title: 'Invalid name!' },
            });
    
        } else if (e.target.value[0] === ' ') {
            this.setState({
                title: e.target.value,
                errors: { ...this.state.errors, title: 'Event name should not start with whitespace character' },
            });
        } else {
            this.setState({ title: e.target.value, errors: { ...this.state.errors, title: '' } });
        }
    };

    getChildrenNames = function (child_ids) {
        let names = '';
        const { children } = this.props;
        if (Array.isArray(child_ids)) {
            child_ids.forEach((child) => {
                for (let i = 0; i < children.length; i++) {
                    let c = children[i];
                    if (child == c.id) {
                        names = names + c.name;
                        names = names + ', ';
                    }
                }
            });
        }
        names = names.trim();
        names = names.slice(0, names.length - 1);
        return names;
    };

    handleChilds = async function (value) {
        if (value.length === 0) {
            await this.setState({
                child_ids: value,
                errors: { ...this.state.errors, kids: 'You have to select at least one item' },
            });
        } else {
            await this.setState({ child_ids: value, errors: { ...this.state.errors, kids: '' } });
        }
    };

    handleChildren = async function (value) {
        const { child_ids } = this.state;
        let arr = [...child_ids];
        if (arr.includes(value)) {
            let index = arr.indexOf(value);
            arr.splice(index, 1);
            if (arr.length === 0) {
                await this.setState({
                    child_ids: arr,
                    errors: { ...this.state.errors, kids: 'You have to select at least one item' },
                });
            } else {
                await this.setState({ child_ids: arr, errors: { ...this.state.errors, kids: '' } });
            }
        } else {
            await this.setState({
                child_ids: [...this.state.child_ids, value],
                errors: { ...this.state.errors, kids: '' },
            });
        }
    };

    handleDate = function (e) {
        if (moment(e).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY')) {
            this.setState({ currentDate: e, start_date: e, errors: { ...this.state.errors, date: '' } });
        } else if (moment(e) > moment(new Date())) {
            this.setState({ currentDate: e, start_date: e, errors: { ...this.state.errors, date: '' } });
        }
    };
        

    handleEndDate = function (e) {
        if (moment(e).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY')) {
            this.setState({ endDate: e, end_date: e, errors: { ...this.state.errors, date: '' } });
        } else if (moment(e) > moment(new Date())) {
            this.setState({ endDate: e, end_date: e, errors: { ...this.state.errors, date: '' } });
        }
    }

    confirmDate = async function () {
        const { start_date } = this.state;
        let m = new Date(this.formatDate(start_date));
        let n = moment( m.setTime(moment(m).set({ 'h': 12, 'm': 0, 's': 0 }).valueOf()).valueOf());
        await this.setState({ start_date: n._d.toISOString(), modalVisible: false });
    };

    confirmEndDate = async function () {
        const { end_date } = this.state;
        let m = new Date(this.formatDate(end_date));
        let n = moment( m.setTime(moment(m).set({ 'h': 12, 'm': 0, 's': 0 }).valueOf()).valueOf());
        await this.setState({ end_date:  n._d.toISOString(), endDateModal: false });
    }

    handleStartTime = function (e) {
        const currentTime = (e && e._d) || null;
        if (currentTime !== null) {
            const newTime = moment(currentTime).format('hh:mm a');
            let n = moment(newTime, 'hh:mm a');
            this.setState({ start_time: n });
        }
    };

    handleEndTime = function (e) {
        const currentTime = (e && e._d) || null;
        if (currentTime !== null) {
            const newTime = moment(currentTime).format('hh:mm a');
            let n = moment(newTime, 'hh:mm a');
            this.setState({ end_time: n });
        }
    };

    handleCheckbox = function (e) {
        let error = '';
        this.setState({ share: !this.state.share });
    };

    validate = function () {
        const { title, child_ids, start_time, end_time, errors, recurring, selectedOption, start_date, end_date} = this.state;
        let err = {};
        if (title === '') {
            err.title = 'Field is required!';
        } else if (title.length > 50) {
            err.title = 'Name length value exceeded! (50 symbols)';
        }else if (!title.replace(/\s/g, '').length) {
            err.title = 'Invalid name!';
        } else if (title[0] === ' ') {
            err.title = 'Event name should not start with whitespace character';
        } else {
            err.title = '';
        }

        if (child_ids.length === 0) {
            err.kids = 'You have to select at least one item';
        } else {
            err.kids = '';
        }

        if(recurring) {
            if(moment(end_date).format('YYYY-MM-DD') <= moment(start_date).format('YYYY-MM-DD')) {
               err.date = 'End date must be more than start date!';
            } else if(moment(end_date).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD')) {
                 err.date = 'End date must be more than start date!';
            } else {
                err.date = '';
            }
        } else {
             err.date = '';
        }
        if (err.title === '' && err.kids === ''  && err.date === '' ) {
            this.setState({
                errors: { ...this.state.errors, date: '' },
            });
            if (end_time <= start_time) {
                this.setModal4Visible(true);
            } else {
                this.handleSubmit();
            }
        } else {
            this.setState({ errors: err });
        }
    };

    handleSubmit = async function () {
        this.setState({ loading: true });
        const { title, child_ids, start_date, start_time, end_time, share, end_date, isThirdParty } = this.state;
        if(this.state.recurring) {
            let m = new Date(this.formatDate(end_date));
            let n = moment( m.setTime(moment(m).set({ 'h': 12, 'm': 0, 's': 0 }).valueOf()).valueOf());
            await this.setState({ end_date:  n._d.toISOString()});
        }
        
        const data = {
            title: title,
            child_ids: child_ids,
            picture: null,
            start_date: start_date,
            type: 'event',
            start_time: moment(start_time).format('hh:mm a'),
            end_time: moment(end_time).format('hh:mm a'),
            share: share,
            err: false,
            recurring_flag :this.state.recurring,
            end_date : this.state.end_date,
            recurring_frequency : this.state.frequency,
            noOf_frequency: this.state.every,
            template : this.state.activeButton,
        };
        if(isThirdParty == 'true') {
            data.created_by_third_party = true;
            data.coparent_id = localStorage.getItem("coparent_id");
            data.share = false;
        }
    
        createEvent(data)
            .then((resp) => {
                if (resp) {
                    this.setState({ loading: false });
                    this.props.handleClose();
                    this.props.refresh();
                }
            })
            .catch((err) => {
                this.state.err = true;
                this.setModal3Visible(true);
            });
    };

    onRecurringChange = async function(checked) {
        await this.setState({
            recurring: checked,
            end_date: new Date(c_year, c_month + 5, c_day),
            endDate: new Date(c_year, c_month + 5, c_day),
            errors: {...this.state.errors, date: ''}
        });
    }

     handleCheckboxChange(checked, index) {
        if(!this.state.list.includes(checked)) {
            this.setState(prevState => ({
                list: [...prevState.list, checked]
            }))
            let activeButton = [...this.state.activeButton];
            activeButton[index] = 1;
            this.setState({activeButton});

        } else {
            let flag = 0
            let len 
            if(this.state.frequency == 'daily' && this.state.every == 1) {  
                flag = 1 //cant deselect week days
            }
            if(this.state.frequency == 'weekly') {
                flag = 2 //atleast one week day should be selected
            }
        
            if(flag == 0) {
                let remove = this.state.list.indexOf(checked);
                this.setState({
                    list: this.state.list.filter((_, i) => i !== remove)
                 })
                let activeButton = [...this.state.activeButton];
                activeButton[index] = 0;
                this.setState({activeButton});
            } else if(flag == 2) {
                let remove = this.state.list.indexOf(checked);
                if(this.state.list && this.state.list.length > 1) { //atleast one week day should be selected
                    this.setState({
                        list: this.state.list.filter((_, i) => i !== remove)
                    })
                    let activeButton = [...this.state.activeButton];
                    activeButton[index] = 0;
                    this.setState({activeButton});
                }
                
            }
            
        }
      
    }

    onGroupChange = (checkedList) => {
    this.setState({
      checkedList,
      checkAll: checkedList.length === weekDays.length,
    });
  }

    render() {
        const {
            title,
            start_date,
            end_date,
            share,
            start_time,
            end_time,
            currentDate,
            endDate,
            errors,
            child_ids,
            err,
            loading,
            frequency,
            every,
            recurring,
            showDays,
            clicked,
            isThirdParty,
            childrenThirdParty
        } = this.state;
        const { children, user } = this.props;

        function getColor(item){
            if(isThirdParty == 'true') {
                return (item.created_by_user === (localStorage.coparent_id))?item.color: item.coparent_color;
            } else {
                return (item.created_by_user === (user && user.id))?item.color: item.coparent_color;
            }
            
        }

        return (
            <Modal
                title="Create Event"
                className="cal-modal "
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Form>
                    <div className="scroll-wrap event-height">
                        <Form.Item className="input-sm">
                            <Input placeholder="Enter event title" value={title} onChange={this.handleTitle} />
                            {errors.title !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.title}</div>
                            ) : null}
                        </Form.Item>
                        <Form.Item className="select-sm kids-select multi-select">
                            <Select
                                placeholder="Choose kid(s)"
                                value={child_ids}
                                mode="multiple"
                                onChange={this.handleChilds}
                            >
                            {localStorage.third_party === 'true' ? (
                                Array.isArray(childrenThirdParty) &&
                                    childrenThirdParty.map((kid, i) => (
                                        <Option
                                            key={i}
                                            className={child_ids.includes(kid.id) ? 'text-color' : ''}
                                            value={kid.id}
                                        >
                                            {' '}
                                            <span className={'dot ' + colors[getColor(kid)]}></span>
                                            {kid.name}
                                        </Option>
                                    ))

                            ) : (
                                Array.isArray(children) &&
                                    children.map((kid, i) => (
                                        <Option
                                            key={i}
                                            className={child_ids.includes(kid.id) ? 'text-color' : ''}
                                            value={kid.id}
                                        >
                                            {' '}
                                            <span className={'dot ' + colors[getColor(kid)]}></span>
                                            {kid.name}
                                        </Option>
                                    ))
                            )}
                                
                            
                            </Select>
                            {errors.kids !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.kids}</div>
                            ) : null}
                        </Form.Item>
                        <Form.Item className="input-col icon-input" label={recurring ? "Start Date" : "Select Event Date:"}>
                            <Input
                                value={this.formatDate(start_date)}
                                onClick={() => this.setModalVisible(true)}
                                suffix={<CalendarOutlined onClick={() => this.setModalVisible(true)} />}
                            />
                        </Form.Item>
                        
                        <Form.Item className="input-col time-picker" label="Start Time:">
                            <TimePicker
                                suffixIcon
                                allowClear={false}
                                use12Hours
                                format="hh:mm A"
                                value={start_time}
                                onChange={(e) => this.handleStartTime(e)}
                                popupClassName="cust-timepicker"
                            />
                            {errors.start_time !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.start_time}</div>
                            ) : null}
                        </Form.Item>
                        <Form.Item className="input-col time-picker" label="End Time:">
                            <TimePicker
                                suffixIcon
                                allowClear={false}
                                use12Hours
                                format="hh:mm A"
                                value={end_time}
                                onChange={(e) => this.handleEndTime(e)}
                                popupClassName="cust-timepicker"
                            />
                            {errors.end_time !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.end_time}</div>
                            ) : null}
                        </Form.Item>
                        <Form.Item className="input-col " label="Recurring">
                            <Switch onChange={this.onRecurringChange} checked = {recurring}/>
                        </Form.Item>
                        {this.state.recurring && (
                            <Form.Item className="input-col icon-input" label="End Date:">
                                <Input
                                    value={this.formatDate(end_date)}
                                    onClick={() => this.setEndDateModal(true)}
                                    suffix={<CalendarOutlined onClick={() => this.setEndDateModal(true)} />}
                                />
                                
                            </Form.Item>
                        )}
                        {errors.date !== '' && recurring ? (
                                <div style={{ color: 'red', fontSize: '12px',textAlign: 'right' }}>{errors.date}</div>
                            ) : null}
                        {this.state.recurring && (
                            <div>
                            <Form.Item className="input-col select-sm " label="Repeats">
                                <Select placeholder="Weekly" value={frequency ? frequency : 'weekly'} onChange={this.handleRepeats}>
                                    {Array.isArray(repeats) &&
                                        repeats.map((kid, i) => (
                                            <Option key={i} className={''} value={kid.value}>
                                                {kid.label}
                                            </Option>
                                        ))}
                                </Select>
                                {/*{errors.kids !== '' ? <div style={{color: 'red', fontSize: '12px'   }}>{errors.kids}</div> : null}*/}
                            </Form.Item>
                            
                            <Form.Item className="input-col select-sm freq-select" label="Every">
                                <Select placeholder="Select" value={every} onChange={this.handleEvery}>
                                    {Array.from(Array(this.state.frequency == 'daily' ? 7 : this.state.frequency == 'weekly' ? 4 : 1), (e, i) => {
                                   return  <Option className={''} value={i+1} key={i}>
                                        {i+1}
                                    </Option>
                                    })}
                                </Select>

                                <Select placeholder="Select" value={frequency} disabled>
                                    {Array.isArray(repeatsLabel) &&
                                        repeatsLabel.map((kid, i) => (
                                            <Option key={i} className={''} value={kid.value}>
                                                {
                                                    frequency === 'daily' ?
                                                    (every > 1 ? 'Days' : kid.label)
                                                    : frequency === 'weekly' ?
                                                    (every === 1 ? 'Week' : kid.label)
                                                    : kid.label   
                                                }
                                            </Option>
                                        ))}
                                </Select>

                                {/*{errors.kids !== '' ? <div style={{color: 'red', fontSize: '12px'   }}>{errors.kids}</div> : null}*/}
                            </Form.Item>
                           {/* <Checkbox.Group type='button' options={weekDays} onChange={this.onGroupChange} value={this.state.checkedList} style={{ width: '100%', marginLeft: '5%', fontWeight: 'lighter' }}>
                            </Checkbox.Group>*/}
                            {showDays && (
                                <Checkbox.Group>
                                    <span className="marked-list-label">Select the day of events:</span>
                                    <ul className="ul-custom marked-list">
                                        {weekDays.map((day,i) => (
                                            <li key={i}>
                                                <Button style={{ color: '#000' }} type="checkbox" value={day.value} onClick={value => this.handleCheckboxChange(day.value,i)} className = {this.state.list.includes(day.value) ? 'click-color' : ''}>{day.label}</Button>
                                            </li> 
                                        ))}
                                    </ul>
                                </Checkbox.Group>
                            )}
                              </div>
                            )}
                            {isThirdParty != 'true' && (
                                <Form.Item className="cb-form">
                                    <Checkbox checked={share} onChange={this.handleCheckbox}>
                                        <span className="cb-text">Share with Co-parent</span>
                                    </Checkbox>
                                </Form.Item>
                            )}
                        
                    </div>
                    <div className="btn-scroll-wrap mb-0">
                        <Form.Item>
                            <Button onClick={this.validate} className="fp-button-primary full-btn" type="primary">
                                Submit
                            </Button>
                        </Form.Item>
                        {err ? (
                            <Modal
                                title="Connection error - please try again, or check your internet connection."
                                visible={this.state.modal3Visible}
                                className="error-popup"
                                onOk={() => this.setModal3Visible(false)}
                                onCancel={() => this.setModal3Visible(false)}
                                maskClosable={false}
                            >
                                <Button className="btn" onClick={this.props.handleClose}>
                                    OK
                                </Button>
                            </Modal>
                        ) : null}
                    </div>
                </Form>
                <Modal
                    title="Calendar"
                    className="cal-modal swap-modal sc-modal"
                    centered
                    visible={this.state.modalVisible}
                    onOk={() => this.setModalVisible(false)}
                    onCancel={() => this.setModalVisible(false)}
                >
                    <div className="edit-wrap cal-custom">
                        <div className="scroll-wrap">
                            <div className="cal-wrap">
                                <Calendar
                                    isEvent={true}
                                    selected={currentDate}
                                    onChange={(e) => this.handleDate(e)}
                                />
                            </div>
                        </div>
                        <div className="btn-scroll-wrap">
                            <Form.Item className="btn-top">
                                <Button onClick={this.confirmDate} className="fp-button-primary" type="primary">
                                    Confirm
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title="Calendar"
                    className="cal-modal swap-modal sc-modal"
                    centered
                    visible={this.state.endDateModal}
                    onOk={() => this.setEndDateModal(false)}
                    onCancel={() => this.setEndDateModal(false)}
                >
                    <div className="edit-wrap cal-custom">
                        <div className="scroll-wrap">
                            <div className="cal-wrap">
                                <Calendar
                                    isEvent={true}
                                    selected={endDate}
                                    onChange={(e) => this.handleEndDate(e)}
                                />
                            </div>
                        </div>
                        <div className="btn-scroll-wrap">
                            <Form.Item className="btn-top">
                                <Button onClick={this.confirmEndDate} className="fp-button-primary" type="primary">
                                    Confirm
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Modal>
                <DisplayErrorModal
                    openModal={this.state.modal4Visible}
                    closeModal={() => this.setModal4Visible(false)}
                />
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer, houseHoldReducer }) => {
    const { children, user, loadings, error, coparent } = serviceReducer;
    const { thirdPartyData } = houseHoldReducer;
    return { children, user, loadings, error, coparent, thirdPartyData };
};

export default connect(mapStateToProps)(CreateEventModal);
