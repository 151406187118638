import React, { Component } from 'react';
import 'pages/Signin/Signup.scss';
import cover from 'Assets/cover.png';
import logo from 'Assets/kincrew-logo.svg';
import arrowWhite from 'Assets/arrow-right.svg';
import flag from 'Assets/flag.png';
import loginGoogle from 'Assets/google.png';
import arrowDark from 'Assets/arrow-right-dark.png';
import { Form, Input, Button, Checkbox, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROUTES } from 'constants/routes';
import { getMeUserAction, updateLoginFlag } from 'actions/userActions';
import { registerUserAction } from 'actions/authenticationActions';
import { setCookie, checkCookie } from 'utils/cookies';
import { Formik } from 'formik';
import LoadingOverlay from 'react-loading-overlay';
import GoogleSignin from './GoogleSignin';
import ConnectionErrorModal from 'Components/Modal/ConnectionModal';
import { sendOTPToEmail, verifyOTP} from '../../services/emailVerificationService';
import OtpInput from 'react-otp-input';
import { getMeUser, setupThirdParty } from 'services/userService';
import Terms from 'pages/Payment/Register/TermsOfUse';
import ChooseHouseholdModal from 'pages/Signin/ChooseHouseholdModal';
import { getThirdPartyAction } from 'actions/houseHoldAction';
import {
    getPermission
} from 'services/memberService';
import { getUserById } from 'services/userService';
import SwapErrorModal from 'pages/CalenderInner/Swap/SwapErrorModal';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import SignupTerms from 'pages/Signin/SignupTerms';

const validator = require('email-validator');

const initialValues = {
    first_name: '',
    last_name: '',
    signup_email: '',
    signup_password: '',
    confirm_password: '',
    password_type: false,
    terms_condition: false,
    news_updates: false,
    otp: '',
};

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: '',
            sinupActive: true,
            modalVisible: false,
            modal2Visible: false,
            modal3Visible: false,
            resMsgModal: false,
            showCodeInput: false,
            email : '',
            f_name: '',
            l_name: '',
            password : '',
            terms_val: false,
            password_type: false,
            news_updt : '',
            otpVal : '',
            counter: 60,
            resMsg: '',
            codeErr: false,
            codeSuccess : false,
            showCodeMsg : false,
            showTimer: false,
            codeMsg: '',
            time: 60,
            terms_error: '',
            data: null,
            msg: '',
            inputType: 'password'
        };
        this.timerId = null;
        this.validate = this.validate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.confirmCode = this.confirmCode.bind(this);
        this.handleTimer = this.handleTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.reSendOTP = this.reSendOTP.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.setModal3Visible = this.setModal3Visible.bind(this);
        this.setModal4Visible = this.setModal4Visible.bind(this);
        this.updateTerms = this.updateTerms.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
    }

    componentDidMount() {
        let isTrialStatus = this.props.location.search;
        if (isTrialStatus.includes('isTrial=true')) {
            this.setState({ isSendingTrialParms: false });
        } else {
            this.setState({ isSendingTrialParms: true });
        }
        
    }

    handleTimer() {
        this.timerId = setInterval(() => {
            this.setState((prevState) => { 
                if(prevState.time > 0)
                    return { time: prevState.time - 1 }
                else
                    this.stopTimer();
            })
        }, 1000);
    }

    stopTimer(){
        clearInterval(this.timerId);
        this.setState({time : 60,showTimer : false})
    }

    handleMsgClose(){
        this.setState({resMsgModal : false, resMsg : ''})
    }

    handleOTP = otpVal => this.setState({ otpVal });

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps &&
            nextProps.hasOwnProperty('response') &&
            nextProps.response !== undefined &&
            nextProps.response !== null
        ) {
            if (
                nextProps.response.hasOwnProperty('register') &&
                nextProps.response.register !== undefined &&
                nextProps.response.register !== null &&
                nextProps.response.register.hasOwnProperty('response') &&
                nextProps.response.register.response !== undefined &&
                nextProps.response.register.response !== null
            ) {
                if (
                    nextProps.response.register.response.hasOwnProperty('token') &&
                    nextProps.response.register.response.token !== undefined &&
                    nextProps.response.register.response.token !== null
                ) {
                    let isSuccess = nextProps.response.register.response.token;
                    if (isSuccess) {
                        this.setState({ loading: false });
                        setCookie('token', nextProps.response.register.response.token, 4);
                        this.props.dispatch(getMeUserAction({ token: nextProps.response.register.response.token }));
                        localStorage.setItem('socialLogin', false);
                        this.setState({ sinupActive: true });

                        let promises = [];

                        promises.push(
                            getMeUser(isSuccess)
                                .then(async (data) => {
                                    await localStorage.setItem('third_party', JSON.stringify(false));
                                    await localStorage.setItem('last_login_email', data.email);
                                    await localStorage.setItem('user_id', data.id);
                                    await localStorage.setItem('first_name', data.first_name);
                                    await localStorage.setItem('last_name', data.last_name);
                                    if (data.qb_username && data.qd_userid) {
                                        localStorage.setItem('qb_username', data.qb_username);
                                        localStorage.setItem('qd_userid', data.qd_userid);
                                        localStorage.setItem('qb_login', data.qb_login == null ? data.qb_username : data.qb_login);
                                    } else {
                                        const login = data.email.replace(/[\W_]+/g,"") + this.generateUID();
                                        await localStorage.setItem('qb_login', login);
                                        await localStorage.setItem('qb_username', data.email);
                                        await localStorage.setItem('new_user', JSON.stringify(true));
                                    }
                                    this.props.dispatch(updateLoginFlag(true))
                                })
                        );

                        Promise.all(promises)
                            .then(() => {
                                this.props.history.push('/subscription'); 
                        })
                    }
                }

                // "SequelizeConnectionRefusedError"
                if (
                    nextProps.response.register.response.name &&
                    nextProps.response.register.response.name === 'SequelizeConnectionRefusedError'
                ) {
                    this.setState({ loading: false });
                    if (nextProps.response.register.response && nextProps.response.register.response.token) {
                    } else {
                        this.setModalVisible(true);
                    }
                }

                if (nextProps.response.register.response.hasOwnProperty('message')) {
                    this.setState({ loading: false, error: nextProps.response.register.response.message });
                }
            }
        }
        if (
            nextProps.response.hasOwnProperty('register') &&
            nextProps.response.register &&
            nextProps.response.register.hasOwnProperty('error') &&
            nextProps.response.register.error
        ) {
            this.setState({ loading: false });
            if (nextProps.response.register.response && nextProps.response.register.response.token) {
            } else {
                this.setModalVisible(true);
            }
        }
    }

    generateUID = () => {

      // I generate the UID from two parts here

      // to ensure the random number provide enough bits.

      var firstPart = (Math.random() * 46656) | 0;

      var secondPart = (Math.random() * 46656) | 0;

      firstPart = ('000' + firstPart.toString(36)).slice(-3);

      secondPart = ('000' + secondPart.toString(36)).slice(-3);

     return firstPart + secondPart;

    };

    setModalVisible = async function (visible) {
        await this.setState({ modalVisible: visible });
    };

    setModal2Visible = async function (visible) {
        await this.setState({ modal2Visible: visible });
    }

    setModal3Visible = async function (visible) {
        await this.setState({ modal3Visible: visible });
    }

    handleLogin = async function () {
        await this.setState({ error: '' });
    };


    sendVerificationCode = async function () {
        const data =  {
            email : this.state.email,
            firstName : this.state.f_name
        }

        if (this.props.location.search.includes('isThirdParty=true')) {
            data.isThirdParty = true;
        }

       
        sendOTPToEmail(data).then((response) => {

            if (response && response.code) {
                this.setState({ loading: false });
                this.setModal4Visible(true, response.message);
            }
            else if (response && response.status) {
                if(response.email_exist) {
                    this.setState({loading : false,resMsgModal: true, resMsg : response.message })
                }
                else{
                    this.setState({showCodeInput : true , loading : false})
                    if(this.state.showTimer){
                        this.setState({showCodeMsg : true, codeMsg: 'We have sent the OTP code in your Registered email Address!'})
                    }
                }

            }
            else {
                this.setState({loading : false , resMsgModal: true, resMsg : 'Something went wrong . Could not send verification code'})
            }
            if(this.state.showTimer) {this.stopTimer();}
         })
    }

    reSendOTP(){
        this.handleTimer();
        this.setState({showTimer : true, codeErr: false, codeSuccess : false,showCodeMsg : false, codeMsg: ''})
        this.sendVerificationCode();
    }

    validate = (values) => {
        let errors = {};


        if (!values.first_name) {
            errors.first_name = 'First name is required!';
        } else if (!values.first_name.replace(/\s/g, '').length) {
            errors.first_name = 'Invalid name!';
        } else if (values.first_name[0] === ' ') {
            errors.first_name = 'First name should not start with whitespace character';
        }

        if (!values.last_name) {
            errors.last_name = 'Last name is required!';
        } else if (!values.last_name.replace(/\s/g, '').length) {
            errors.last_name = 'Invalid name!';
        } else if (values.last_name[0] === ' ') {
            errors.last_name = 'Last name should not start with whitespace character';
        }


        if (!values.signup_email) {
            errors.signup_email = 'Email is required!';
        } else if (!validator.validate(values.signup_email)) {
            errors.signup_email = 'Invalid email';
        }

        if (!values.signup_password) {
            errors.signup_password = 'Password is required!';
        } else if (!values.signup_password.replace(/\s/g, '').length) {
            errors.signup_password = 'Invalid password!';
        } else if (values.signup_password.length < 8) {
            errors.signup_password = 'Password is too short! (Must be atleast 8 characters that include atleast 1 number)';
        } else if (values.signup_password.length >= 8 && values.signup_password.length <= 50) {
            let res = false;
            for(let i = 0; i< values.signup_password.length;i++){
                let ch = values.signup_password[i];
                if(ch.charCodeAt() >= 48 && ch.charCodeAt() <= 57){
                    res = true;
                }
            }
            if(res === false) {
                errors.signup_password = 'Password must has atleast 1 number';
            }
        } else if (values.signup_password.length > 50) {
            errors.signup_password = 'Password is too long! (Must be max 50 characters)';
        }

        if(!values.confirm_password) {
            errors.confirm_password = 'Confirm Password is required!';
        } else if(values.confirm_password !== values.signup_password){
            errors.confirm_password = 'Password Do Not Match!';
        }


        // if (this.state.terms_val === false) {
        //     errors.terms_condition = 'Please agree to our terms & conditions';
        //     this.setState({ terms_error: 'Please agree to our terms & conditions' });
        // }

        if (
            errors.first_name !== '' ||
            errors.last_name !== '' ||
            errors.signup_email !== '' ||
            errors.signup_password !== '' ||
            errors.confirm_password !== '' 
        ) {
            this.setState({ error: '' });
        }
        return errors;
    };

    onSubmit = (values) => {
        this.setState({
                       email : values.signup_email, f_name : values.first_name, l_name: values.last_name,password : values.signup_password,
                       terms_val: this.state.terms_val, news_updt : values.news_updates,
                       showCodeMsg : true, codeMsg :'We have sent the OTP code in your Registered email Address!'})
        this.setState({loading : true});

        this.sendVerificationCode();
    };

    confirmCode () {
         const otpdata =  {
            email : this.state.email,
            code : this.state.otpVal

        }
        this.setState({ loading: true, sinupActive: false})
        verifyOTP(otpdata).then((resp) => {
            if(resp && resp.status) {
                this.setState({codeErr : false ,codeSuccess : true,showCodeMsg : true, codeMsg :'Congratulations your verification is done successfully'})
                const data = {
                    first_name: this.state.f_name,
                    last_name: this.state.l_name,
                    signup_email: this.state.email,
                    signup_password: this.state.password,
                    terms_condition: this.state.terms_val,
                    news_updates: this.state.news_updt,
                };

                if (this.state.isSendingTrialParms) {
                    data.isTrial = false;
                } else {
                    data.isTrial = true;
                }

                if (this.props.location.search.includes('isThirdParty=true')) {
                    const credentials = {
                        first_name: data.first_name,
                        last_name: data.last_name,
                        email: data.signup_email,
                        pwd: data.signup_password,
                        provider: "local",
                        terms_condition: true, // terms and condition
                        news_updates: data.news_updates,
                        isTrial: true,
                    };

                    setupThirdParty(credentials)
                        .then(resp => {
                            this.setState({ loading: false });
                            if (resp) {
                                if (resp.code) {
                                    this.setModal4Visible(true, resp.message);
                                } else {
                                    this.setState({ data: resp });
                                    this.setModal3Visible(true);
                                }
                            }
                        })
                } else {
                    this.props.dispatch(registerUserAction(data));
                }
            } else {
                 this.setState({loading: false,codeErr : true ,codeSuccess : false,showCodeMsg : true, codeMsg :'You might be put wrong code please try again'})
            }
        })

    }

    handleThirdParty = (obj) => {
        const { data } = this.state;
        let TokenIs = data.token;
        if(TokenIs){
            setCookie('token', TokenIs, 8);
            let resp = this.props.dispatch(getMeUserAction({ token: TokenIs }));
            this.props.dispatch(getThirdPartyAction({ id : obj.member_id }));
            localStorage.setItem("socialLogin", false);
            this.setState({ sinupActive: true });

            let promises = [];

            promises.push(
                getMeUser(TokenIs)
                    .then(async (userData) => {
                        await localStorage.setItem('third_party', JSON.stringify(true));
                        await localStorage.setItem('household_id', obj.household_id);
                        await localStorage.setItem('coparent_id', obj.coparent_id);
                        await localStorage.setItem('member_id', obj.member_id);
                        await localStorage.setItem('last_login_email', 'thirdParty_' + userData.email);
                        await localStorage.setItem('user_id', userData.id);
                        await localStorage.setItem('first_name', userData.first_name);
                        await localStorage.setItem('last_name', userData.last_name);
                        if (userData.qb_username && userData.qd_userid) {
                            await localStorage.setItem('qb_username', userData.qb_username);
                            await localStorage.setItem('qd_userid', userData.qd_userid);
                            await localStorage.setItem('qb_login', userData.qb_login == null ? userData.qb_username : userData.qb_login);
                        } else {
                            const login = 'thirdParty_' + userData.email.replace(/[\W_]+/g,"") + this.generateUID();
                            await localStorage.setItem('qb_login', login);
                            await localStorage.setItem('qb_username', 'thirdParty_' + userData.email);
                            await localStorage.setItem('new_user', JSON.stringify(true));
                        }
                    })
            );

            promises.push(
                getUserById(obj.coparent_id)
                    .then(resp => {
                        if (resp) {
                            localStorage.setItem('coparent_name', resp.first_name);
                        }
                    })
            );

            
            Promise.all(promises)
                .then(() => {

                    let _data = {
                        user_id: localStorage.user_id,
                        household_id: obj.household_id
                    };

                    let arr = [];

                    arr.push(
                        getPermission(_data)
                            .then(resp => {
                                if (resp && resp.success && resp.permission) {
                                    localStorage.setItem('third_party_permission', JSON.stringify(resp.permission));
                                    localStorage.setItem('calendar_permission', resp.permission.calendar);
                                    localStorage.setItem('payment_permission', resp.permission.payment);
                                    localStorage.setItem('chat_permission', resp.permission.chat);
                                }
                            })
                    );

                    Promise.all(arr)
                        .then(() => {
                            this.props.history.push('/home');   
                    })  
            })   
        }

    }

    updateTerms = async function (val) {
        let err = '';
        if(val === true) {
            err = '';
        } else {
            err = 'Please agree to our terms & conditions';
        }
        this.setState({ modal2Visible: false, terms_val: val, terms_error: err });
    }

    handleCheck = async () => {
        let err = '';
        if(!this.state.terms_val === true) {
            err = '';
        } else {
            err = 'Please agree to our terms & conditions';
        }
        this.setState({ terms_val: !this.state.terms_val,  terms_error: err });
    }

    setModal4Visible = function (visible, msg) {
        this.setState({ modal4Visible: visible, msg });
    }

    handlePassword = function(){
        const { inputType } = this.state;
        let input = '';
        if (inputType === 'password') {
            input = 'text';
        } else if (inputType === 'text') {
            input = 'password';
        }

        this.setState({ inputType: input, password_type: !this.state.password_type });
    }

    render() {
        const { response } = this.props;
        const {
            loading,
            error,
            sinupActive,
            isSendingTrialParms,
            resMsgModal,
            resMsg,
            showCodeInput,
            counter,
            showCodeMsg,
            codeMsg,
            codeErr,
            codeSuccess,
            showTimer,
            inputType
        } = this.state;
      
        return (
            <LoadingOverlay
                active={loading ? true : false}
                className={'loader-wrap ' + (loading ? 'd-block' : '')}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '100px',
                        '& svg circle': {
                            stroke: 'rgb(56, 160, 235)',
                        },
                    }),
                }}
            >
                <div className="signin-wrapper">
                    {this.state.errorMessage && <div className="sign-err">{this.state.errorMessage}</div>}
                    <div className="signin-cover">{/* <img src={cover} alt="kincrew-cover-image" /> */}</div>
                    <div className="signin-details">
                        <div className="details-inner">
                            <div className="details-head">
                                <div className="logo-wrap">
                                    <img src={logo} alt="kincrew-logo" />
                                    <span>kincrew</span>
                                </div>
                                {!showCodeInput && (
                                    <div className="btn-wrap">
                                        <Link to="/">
                                            <Button onClick={this.handleLogin} type="primary" className="btn-sign-in">
                                                Login
                                                <span className="arrow-wrap">
                                                    <img src={arrowDark} alt="arrow-right" />
                                                </span>
                                            </Button>
                                        </Link>
                                    </div>
                                )}
                            </div>
                            <div className="data-wrap">
                                {error !== '' ? <div className="sign-err-top">{error}</div> : null}
                                {isSendingTrialParms ? (
                                    <div className="sign-head">
                                        <span>{showCodeInput ? 'OTP Verification' : 'Welcome!'}</span>
                                    </div>
                                ) : (
                                    <div className="sign-head">
                                        <span>Try kincrew for 14 days on us</span>
                                    </div>
                                )}

                                <div className="signin-form-wrap">
                                    <Formik
                                        initialValues={initialValues}
                                        validate={this.validate}
                                        onSubmit={this.onSubmit}
                                    >
                                        {({
                                            touched,
                                            values,
                                            handleChange,
                                            handleSubmit,
                                            handleBlur,
                                            errors,
                                            isSubmitting,
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <Modal
                                                    centered
                                                    visible={resMsgModal}
                                                    backdrop="static"
                                                    height={200}
                                                    className="modal-container req-card"
                                                    maskClosable={false}
                                                    onCancel={() => this.handleMsgClose()}
                                                >
                                                    <div className="modal-head">
                                                        <div style={{textAlign: 'center', width: '80%', margin: 'auto', fontWeight: '700', marginBottom: '15px'}}>
                                                            {resMsg}
                                                        </div>
                                                        <Button type="primary" style={{width: '80%', marginTop: '10px', border: 'none', borderRadius: '4px' }} onClick={() =>  this.handleMsgClose()} className="btn fp-button-primary">
                                                           Ok
                                                        </Button>
                                                    </div>
                                                </Modal>
                                                {!showCodeInput && (
                                                    <div>
                                                    <Form.Item label="First Name" labelCol={{ span: 24 }}>
                                                        <Input
                                                            name="first_name"
                                                            value={values.first_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoComplete="off"
                                                        />
                                                        {touched.first_name && errors.first_name ? (
                                                            <div className="sign-err">{errors.first_name}</div>
                                                        ) : null}
                                                    </Form.Item>
                                                    <Form.Item label="Last Name" labelCol={{ span: 24 }}>
                                                        <Input
                                                            name="last_name"
                                                            value={values.last_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoComplete="off"
                                                        />
                                                        {touched.last_name && errors.last_name ? (
                                                            <div className="sign-err">{errors.last_name}</div>
                                                        ) : null}
                                                    </Form.Item>
                                                    <Form.Item label="Email" labelCol={{ span: 24 }}>
                                                        <Input
                                                            placeholder="mom.parent@gmail.com"
                                                            name="signup_email"
                                                            value={values.signup_email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoCapitalize ="none"
                                                        />
                                                        {touched.signup_email && errors.signup_email ? (
                                                            <div className="sign-err">{errors.signup_email}</div>
                                                        ) : null}
                                                    </Form.Item>
                                                    <Form.Item label="Password" labelCol={{ span: 24 }}>
                                                        <Input
                                                            name="signup_password"
                                                            value={values.signup_password}
                                                            type={inputType}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {touched.signup_password && errors.signup_password ? (
                                                            <div className="sign-err">{errors.signup_password}</div>
                                                        ) : null}
                                                    </Form.Item>
                        
                                                    <Form.Item  className={touched.signup_password && errors.signup_password && errors.signup_password.length > 25 ? 'confirm_text' : null} label="Confirm Password" labelCol={{ span: 24 }}>
                                                        <Input
                                                            name="confirm_password"
                                                            value={values.confirm_password}
                                                            type={inputType}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {touched.confirm_password && errors.confirm_password ? (
                                                            <div className="sign-err">{errors.confirm_password}</div>
                                                        ) : null}
                                                        </Form.Item>
                                                    <div className="checkbox-wrap">
                                                        <div style={{ display: 'flex'}}>
                                                        <Checkbox
                                                            className="terms_box"
                                                            name="terms_condition"
                                                            value={values.password_type}
                                                            checked={this.state.password_type}
                                                            type="checkbox"
                                                            onClick={this.handlePassword}
                                                            onBlur={handleBlur}
                                                        >
                                                        </Checkbox>
                                                           <p className="password_text">Show password</p>
                                                        </div>
                                                        
                                                    </div>    
                                                    <div className="checkbox-wrap">
                                                        <div style={{ display: 'flex' }}>
                                                        {/* <Checkbox
                                                            className="terms_box"
                                                            name="terms_condition"
                                                            value={values.terms_condition}
                                                            //checked={values.terms_condition}
                                                            checked={this.state.terms_val}
                                                            type="checkbox"
                                                            onClick={this.handleCheck}
                                                            onBlur={handleBlur}
                                                        >
                                                        </Checkbox> */}
                      
                                                        <p style={{ marginLeft: '35px'}} className="terms_text">
                                                        By Signing in you will be agreed with our <a onClick={() => this.setModal2Visible(true)}>Terms &amp;
                                                        Conditions.</a></p>
                                                        </div>
                                                        {/* {touched.terms_condition && this.state.terms_error ? (
                                                            <div className="sign-err">{this.state.terms_error}</div>
                                                        ) : null} */}
                                                    </div>
                                                    <div className="checkbox-wrap">
                                                        <Checkbox
                                                            name="news_updates"
                                                            value={values.news_updates}
                                                            checked={values.news_updates}
                                                            type="checkbox"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        >
                                                            Keep me posted with latest news and updates.Checking this box to opt in to marketing emails does not impact our ability to contact you about your account, account activity, payments, or other matters related to your subscription.
                                                        </Checkbox>
                                                    </div>
                                                </div>
                                                )}

                                                 {showCodeInput && (
                                                    <div className={this.state.codeErr ? 'otp-box-wrap otp-error' : this.state.codeSuccess ? 'otp-box-wrap otp-success' :  'otp-box-wrap'}>
                                                        <Form.Item label="Enter the OTP Code" labelCol={{ span: 24 }}>
                                                            {/* <Input
                                                            name="otp"
                                                            value={values.otp}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {touched.name && errors.name ? (
                                                            <div className="sign-err">{errors.name}</div>
                                                        ) : null} */}
                                                            <div className="otp-input-wrap ">
                                                               <OtpInput
                                                                value={this.state.otpVal}
                                                                onChange={this.handleOTP}
                                                                numInputs={6}
                                                                isInputNum={true}
                                                                />
                                                            </div>
                                                        </Form.Item>
                                                        <div className="otp-label-wrap">
                                                            {showCodeMsg && !showTimer && (
                                                                <span>
                                                                  {codeMsg}
                                                                </span> 
                                                            )}
                                                             
                                                            {/* <span>You might be put wrong code please try again</span> */}
                                                            {/* <span>
                                                                Congratulations your verification is done successfully
                                                            </span> */}
                                                            {showTimer && (
                                                                <span className="otp-timer">
                                                                    You'll get the OTP within <span>{this.state.time}</span>
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="otp-resend">
                                                            <Link to="#" onClick={this.reSendOTP}>Resend OTP</Link>
                                                        </div>
                                                    </div>
                                                )}
                                               
                                                <div className="btn-wrap signup-btn">
                                                 {!showCodeInput && (
                                                    <Button type="primary" htmlType="submit" className="btn-full ">
                                                        <span>
                                                            {isSendingTrialParms ? 'Sign Me Up' : 'Start Your Trial'}
                                                        </span>
                                                        <span>
                                                            <img src={arrowWhite} alt="arrow-right" />
                                                        </span>
                                                    </Button>
                                                     )}
                                                    {showCodeInput && (
                                                        <Button type="primary"  className="btn-full " onClick = {this.confirmCode}>
                                                            <span>
                                                                {'Confirm' }
                                                            </span>
                                                            <span>
                                                                <img src={arrowWhite} alt="arrow-right" />
                                                            </span>
                                                        </Button>
                                                     )}
                                                     
                                                    {/*<Button type="primary" htmlType="submit" className="btn-full btn-google ">
                                                        <span>Sign in with Google</span>
                                                        <span>
                                                            <img src={loginGoogle} alt="google-logo" />
                                                        </span>
                                                    </Button>*/}
                                                    {/*------------- Google Login ------------------------*/}
                                                     {!showCodeInput && (
                                                        <GoogleSignin
                                                            signup={true}
                                                            location={this.props.location}
                                                        />
                                                    )}

                                                    {/*------------- Google Login ------------------------*/}
                                                </div>
                                               
                                                {!isSendingTrialParms && (
                                                    <div className="form-bottom">
                                                        <div>
                                                            By clicking on Start Your Trial, you agree to our{' '}
                                                            <Link to="#">Terms</Link> and you acknowledge having read
                                                            our <Link to="#">Privacy Policy</Link>.
                                                        </div>
                                                        <div>
                                                            *This includes periodic newsletters, emails about offers and
                                                            app usage tips, and other communications. You can opt out
                                                            anytime.
                                                        </div>
                                                    </div>
                                                )}
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ConnectionErrorModal
                    handleOpen={this.state.modalVisible}
                    handleClose={() => this.setModalVisible(false)}
                />
                <SignupTerms
                   termsVisible={this.state.modal2Visible}
                   termsStatus={this.state.terms_val}
                   onClose={() => this.setModal2Visible(false)}
                   updateTermsAgree={(val) => this.updateTerms(val)}
                />
                <ChooseHouseholdModal
                    openModal={this.state.modal3Visible}
                    closeModal={() => this.setModal3Visible(false)}
                    data={this.state.data}
                    selected={(obj) => this.handleThirdParty(obj)}
                />
                <SwapErrorModal
                    openModal={this.state.modal4Visible}
                    closeModal={() => this.setModal4Visible(false, '')}
                    msg={this.state.msg}
                />
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = (response) => ({ response });

export default connect(mapStateToProps)(Signup);
