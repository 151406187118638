import React from 'react';
import PropTypes from 'prop-types';

import Flex from './Flex';

import { getTileClasses } from './shared/utils';
import { tileGroupProps } from './shared/propTypes';

export default function TileGroup({
    className,
    count = 3,
    dateTransform,
    dateType,
    end,
    hover,
    markedDates,
    selected,
    swaps,
    offset,
    start,
    step = 1,
    tile: Tile,
    value,
    valueType,
    ...tileProps
}) {
    const formatDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    let colors = {
        "#c910e8": "Pink",
        "#2196f3": "Blue",
        "#673ab7": "Purple",
        "#ff0000": "Red",
        "#4caf50": "Green",
        "#ffc107": "Orange",
        "#00bcd4": "Cyan",
        "#795548": "Brown",
        "#ffffff": "White",
        "#999999": "Grey",
        "#333333": "Charcoal",
        "#000000": "Black",
        "gradient1": "Gradient1",
        "gradient2": "Gradient2",
    };

    const tiles = [];
    for (let point = start; point <= end; point += step) {
        const date = dateTransform(point);
        let flag = (formatDate(date) === formatDate(selected));
        let custodyDates;
        let custodyDots = [];
        if(markedDates){
            custodyDates = markedDates[formatDate(date)];
            if(custodyDates){
                custodyDates.dots.forEach(color => {
                    custodyDots.push(colors[color]);
                });
            }
        }

        tiles.push(
            <div key={formatDate(date)} className={flag ? "day selected" : "day"}>
                <Tile
                    key={date.getTime()}
                    classes={getTileClasses({
                        value,
                        valueType,
                        date,
                        dateType,
                        hover,
                    })}
                    swaps={swaps}
                    date={date}
                    point={point}
                    {...tileProps}
                />
                <div className="child-block">
                   { custodyDots && custodyDots.map((color,i) => <span key={i} className={color}></span>)}
                </div>
            </div>,
        );
    }

    return (
        <Flex className={className} swaps={swaps} count={count} offset={offset} wrap>
            {tiles}
        </Flex>
    );
}

TileGroup.propTypes = {
    ...tileGroupProps,
    activeStartDate: PropTypes.instanceOf(Date),
    count: PropTypes.number,
    dateTransform: PropTypes.func.isRequired,
    offset: PropTypes.number,
    step: PropTypes.number,
    tile: PropTypes.func.isRequired,
};



