import React, { Component } from 'react';
import {
    Card,
    Button,
    Modal,
    Checkbox,
    Form,
    Input,
    Select,
    Empty,
    Tabs,
    Radio
} from 'antd';
import 'pages/SubscriptionNew/EditSubscription.scss';

class ChooseHouseholdModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thirdParty: [],
            selected: '0'
        };
        this.handleChange = this.handleChange.bind(this);
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.data) {
            this.setState({
                thirdParty: nextProps.data.thirdParty,
                selected: '0'
            });
        }    
    }

    handleChange = async function (e) {
        this.setState({ selected: e.target.value });
    }

    handleConfirm = () => {
        const { thirdParty, selected } = this.state;
        let obj = thirdParty.find((el, i) => i == selected);
        if (obj) {
            this.props.closeModal();
            this.props.selected(obj);
        }
    }
    
    render() {
        const { thirdParty, selected } = this.state;

        return (
            <Modal
            title="Choose Household"
            centered
            maskClosable={false}
            visible={this.props.openModal}
            className="choose-household-modal"
            onOk={this.props.closeModal}
            onCancel={this.props.closeModal}
            >
            <div className="household-wrapper">
                <Radio.Group value={selected}>
                    {Array.isArray(thirdParty) && thirdParty.map((item, i) => (
                        <Radio key={i} onClick={this.handleChange} value={'' + i}>
                            {item.name}
                        </Radio>
                    ))}
                </Radio.Group>
            </div>
                
            <Button
                type="primary"
                onClick={this.handleConfirm}
                className="btn fp-button-primary"
            >
                Confirm
            </Button>
            </Modal>
        )
    }

}


export default ChooseHouseholdModal;