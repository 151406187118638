import { makeRequest } from './authenticationService';


export const getDots = (sParams) => {

    const sortParams = sParams || {};
    const coparent_id = sortParams.coparent_id || null;
   
    const third_party = sortParams.third_party;
    const member_id = sortParams.member_id;
    let url ;
    if(sortParams.third_party) {
        url = `/api/calendar/calendarDots/fetch?coparent_id=${coparent_id}&member_id=${member_id}`;
    } else {
        url = `/api/calendar/calendarDots/fetch?coparent_id=${coparent_id}`;
    }
    
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });   
}

export const getEventDots = (params=null) => {
    let url ;
    if(params != null && Object.entries(params).length != 0) {
        const member_id = params.member_id;
        const coparent_id = params.coparent_id;
        url = `/api/calendar/dots/event?coparent_id=${coparent_id}&member_id=${member_id}`;
    } else {
        url = '/api/calendar/dots/event';
    }
    //let url = '/api/calendar/dots/event';
    
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });   
}

export const getSchedule = (params) => {
    const {type, view, start_date, end_date, coparent_id, member_id, third_party } = params;

    // let url ;
    // if(third_party) {
    //     url = `/api/calendar/schedule/getEvents?member_id=${member_id}`;
    // } else {
    //     url = `/api/calendar/schedule/getEvents`;
    // }
    let url =`/api/calendar/schedule/getEvents`;
    if(params){
        url += '?';
        if(params.type) {
           url += `&type=${type}`;
        }
        if(params.view) {
            url += `&view=${view}`;
        }
        if(params.start_date && params.end_date){
            url += `&start_date=${start_date}&end_date=${end_date}`;
        }
        if(third_party) {
            url += `&member_id=${member_id}&third_party=${third_party}`;
        }
        url += `&coparent_id=${coparent_id}`;
    }
    
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}


export const getScheduleById = (id) => {

    let url = '/api/calendar/schedule/' + id;

    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}


export const getSwaps = (params) => {

    const {coparent_id} = params;

    let url = '/api/calendar/swap/fetch?offset=0&limit=100';

    if(params){
        if (params.view) {
            url += `&view=${params.view}`;
        }
        url += `&coparent_id=${coparent_id}`;  
    }


    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
};

export const getCustody = (params) => {
    const sortParams = params || {};
    const view = sortParams.view;
    const offset = sortParams.offset || 0;
    const limit = sortParams.limit || 10;
    const {coparent_id} = params;
    let url = `/api/calendar/custody/fetch?offset=${offset}&limit=${limit}&coparent_id=${coparent_id}`;

    if (view) {
        url += `&view=${view}`;
    }

    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
};

export const getAllEvents = (params) => {
    const sortParams = params || {};
    const view = sortParams.view;
    const type = sortParams.type;
    const offset = sortParams.offset || 0;
    const limit = sortParams.limit || 10;
    const third_party = sortParams.third_party;
    const upper_cut_date = sortParams.upper_cut_date ? sortParams.upper_cut_date : null;
    const {coparent_id} = params;
    let url ;
    if(third_party) {
        url = `/api/calendar/schedule/fetch?type=${type}&offset=${offset}&limit=${limit}&coparent_id=${coparent_id}&third_party=${third_party}`;
    } else {
        url = `/api/calendar/schedule/fetch?type=${type}&offset=${offset}&limit=${limit}&coparent_id=${coparent_id}`;
    }
   

    if (view) {
        url += `&view=${view}`;
    }
    if(upper_cut_date) {
          url += `&upper_cut_date=${upper_cut_date}`;
    }
    
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const createEvent = (request) => {
    let url = '/api/calendar/schedule';

    return makeRequest('POST', url, request)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}
export const editEvents = (id,request) => {
    let url = `/api/calendar/schedule/${id}`;
    return makeRequest('PUT', url,request)
     .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
}

export const userApproval = (id,request) => {
    let url = `/api/calendar/schedule/eventApproval/${id}`;
    return makeRequest('PUT', url,request)
     .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
}

export const deleteEvents = (id) => {
    let url = `/api/calendar/schedule/${id}`;
    return makeRequest('DELETE', url)
     .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
}

export const getAllSwaps = (params) => {
    const sortParams = params || {};
    const view = sortParams.view;
    const offset = sortParams.offset || 0;
    const limit = sortParams.limit || 10;
    const {coparent_id} = params;
    let url = `/api/calendar/swap/schedule/fetch?offset=${offset}&limit=${limit}&coparent_id=${coparent_id}`;
    
    if (view) {
        url += `&view=${view}`;
    }
    
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const setupCustody = (custody) => {

    let url = '/api/calendar/custody/setup';

    return makeRequest('POST', url, custody)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const updateCustody = (id, options) => {
    
    let url = '/api/calendar/custody/' + id;
    
    return makeRequest('PUT', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const getSwap = (id) => {
    
    let url = `/api/calendar/swap/${id}`;
    
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const deleteCustody = (id) => {

    let url = '/api/calendar/custody/' + id;

    return makeRequest('DELETE', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });  
}

export const updateCustodyStatus = (id, options) => {
    
    let url = '/api/calendar/custody/' + id;
    
    return makeRequest('PUT', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const setupScheduleException = (data) => {

    let url = '/api/calendar/schedule';

    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const getScheduleException = (id) => {
    
    let url = `/api/calendar/schedule/${id}`;
    
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const updateScheduleException = (id, options) => {
    
    let url = '/api/calendar/schedule/' + id;
    
    return makeRequest('PUT', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const deleteException = (id) => {

    let url = '/api/calendar/schedule/' + id;

    return makeRequest('DELETE', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });  
}

export const setupSwap = (data) => {

    let url = '/api/calendar/swap';

    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const updateSwap = (id, options) => {
    
    let url = '/api/calendar/swap/' + id;
    
    return makeRequest('PUT', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}
