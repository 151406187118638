import React, { Component } from 'react';
import 'pages/Landing/RecentRequest/RecentRequest.scss';
import { Card, Empty , Button} from 'antd';
import RequestCard from 'pages/Landing/RecentRequest/RecentRequestCard';
import ScrollArea from 'react-scrollbar';
import { getPaymentRequests } from 'services/paymentService';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import SentModal from 'pages/Payment/Payments/SentModal';
import ReviewModal from 'pages/Payment/Payments/ReviewModal';
import {addLoading} from 'actions/loaderActions';
import { getUserById } from 'services/userService';

class RecentRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payments: [],
            offset: 0,
            coparent: null,
            loading: false,
            status: ['pending'],
            currentPayment: null,
            sentModalVisible: false,
            reviewModalVisible: false,
            coParent: null
        };
        this.getRecentPayments = this.getRecentPayments.bind(this);
        this.getFormattedDate = this.getFormattedDate.bind(this);
        this.setSentModalVisible = this.setSentModalVisible.bind(this);
        this.setReviewModalVisible = this.setReviewModalVisible.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
    }

    componentDidMount() {
        let offset = 0;
        if (localStorage.third_party && localStorage.third_party === 'true') {
            getUserById(localStorage.coparent_id)
                    .then(resp => {
                        if (resp) {
                            this.setState({ coParent: resp });
                    }
            })
            this.getRecentPayments(offset, localStorage.coparent_id, false);
        } else {
            this.getRecentPayments(offset, this.props.coparent, false);
        }
    }

    componentDidUpdate(prevProps,prevState) {
        if(this.props.coparent !== prevProps.coparent) {
            let offset = 0;
            let arr = [];
            this.setState({ payments: arr });
            if (localStorage.third_party && localStorage.third_party === 'true') {
                this.getRecentPayments(offset, localStorage.coparent_id, true);
            } else {
                this.getRecentPayments(offset, this.props.coparent, true);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps && nextProps.error !== null) {
           this.setState({loading: false});
        }
        if(nextProps && nextProps.err !== null) {
            this.setState({loading: false});
        }
    }

    getRecentPayments = function (offset, coparent, flag) {
        const { payments, status } = this.state;
        if(this.props.err === null) {
           this.setState({ loading: true });
        }
        let sParams = {};

        if (localStorage.third_party && localStorage.third_party === 'true') {
            sParams = {
                offset: offset,
                limit: 15,
                status: status,
                orderBy: '-updated_at',
                coparent_id: coparent,
                thirdParty: true
            };
        } else {
            sParams = {
                offset: offset,
                limit: 15,
                status: status,
                orderBy: '-updated_at',
                coparent_id: (coparent && coparent.id) || null,
            };
        }
        
        this.setState({ offset: offset, coparent: coparent || null });
        getPaymentRequests(sParams).then((resp) => {
            if (resp) {
                if (resp.index !== undefined) {
                    this.setState({ offset: resp.index + 1, coparent: { id: resp.coparent } });
                } else {
                    resp.offset += 15;
                    this.setState({ offset: resp.offset, coparent: { id: resp.coparent } });
                }

                if (flag) {
                    let arr = [];
                    arr = [...arr, ...resp.payments];
                    this.setState({ payments: arr, loading: false });
                } else {
                    let arr = [];
                    arr = [...payments, ...resp.payments];
                    this.setState({ payments: arr, loading: false });
                }
            } else {
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({ loading: false });
        })
        
    };

    handleClick = function(payment) {
       const { user } = this.props;
       const { coParent } = this.state;

       let normalUser = localStorage.third_party !== 'true';
       let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';

        if (normalUser) {
            if (user && user.id === payment.receiver_id) {
                this.setSentModalVisible(true, payment);
            } else if (user && user.id === payment.payer_id) {
                this.setReviewModalVisible(true, payment);
            }
        } else {
            if (coParent && coParent.id === payment.receiver_id) {
                this.setSentModalVisible(true, payment);
            } else if (coParent && coParent.id === payment.payer_id) {
                this.setReviewModalVisible(true, payment);
            }
        }
    }

    setSentModalVisible = async function(modalVisible, payment) {
        await this.setState({sentModalVisible: modalVisible, currentPayment: payment});
    }
    setReviewModalVisible = async function(modalVisible, payment) {
        await this.setState({reviewModalVisible: modalVisible, currentPayment: payment});
    }

    refreshPage = function() {
        this.props.dispatch(addLoading());
        let offset = 0;
        if (localStorage.third_party && localStorage.third_party === 'true') {
            this.getRecentPayments(offset, localStorage.coparent_id, true);
        } else {
            this.getRecentPayments(offset, this.props.coparent, true);
        }
    }

    getFormattedDate = function (date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    };
    
    render() {
        const { user } = this.props;
        const { payments, offset, coparent, currentPayment,  loading } = this.state;
        return (
            <Card bordered={false} className="req-card card-rec-req">
                <LoadingOverlay
                    active={loading ? true : false}
                    className="d-block cust-loader"
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <div className="card-head req-head">
                    <span>Recent Request</span>
                </div>
                <ScrollArea
                    speed={0.8}
                    className="req-card-wrap"
                    onScroll={(value) => {
                        if (value.topPosition === value.realHeight - value.containerHeight && value.topPosition !== 0) {
                            if (localStorage.third_party && localStorage.third_party === 'true') {
                                this.getRecentPayments(offset, localStorage.coparent_id, false);
                            } else {
                                this.getRecentPayments(offset, coparent, false);
                            }
                        }
                    }}
                    contentClassName="content"
                    horizontal={false}
                >  
                        { user  && payments && Array.isArray(payments) && payments.length !== 0  ? payments.map((payment, i) => (
                            (user.id === payment.PaymentCategory.coparent_user_id || user.id === payment.PaymentCategory.coparent_standlalone_id) && payment.PaymentCategory.category_status === 1 ? null : (    
                                <RequestCard
                                    coParent={this.state.coParent}
                                    handleInput={() => this.handleClick(payment)}
                                    user={user}
                                    status={payment.status}
                                    payer_id={payment.payer_id}
                                    receiver_id={payment.receiver_id}
                                    receiver_username={payment.receiver_username}
                                    payer_username={payment.payer_username}
                                    amount={payment.payer_amount}
                                    date={this.getFormattedDate(new Date(payment.payment_date))}
                                    key={i}
                                />
                                ) 
                                )) : (
                            <div>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        )}
          
                </ScrollArea>

                <SentModal  refresh={() => this.refreshPage()} currentPayment={currentPayment} handleOpen={this.state.sentModalVisible} handleClose={() => this.setSentModalVisible(false)} />
                <ReviewModal refresh={() => this.refreshPage()} currentPayment={currentPayment} handleOpen={this.state.reviewModalVisible} handleClose={() => this.setReviewModalVisible(false)} />
            </Card>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, coparent, error } = serviceReducer;
    return { user, coparent, error };
};
export default connect(mapStateToProps)(RecentRequest);
