import React, { Component } from 'react';
import 'pages/SubscriptionNew/EditSubscription.scss';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { Card, Button, Typography, Input, Tabs, Select } from 'antd';
import { connect } from 'react-redux';
import logo from 'Assets/kincrew-white.png';
import UpgradeSubscription from 'pages/SubscriptionNew/UpgradeSubscription';
import PaymentMethod from 'pages/SubscriptionNew/PaymentMethod';
import PaymentHistory from 'pages/SubscriptionNew/PaymentHistory';

class EditSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    render() {
        return (
        <div className="edit-container">
            <div className="subscription__header">
            <div className="logo">
                <div className="logo-wrap">
                    <Link to="/home">
                        <img src={logo} alt="kincrew-logo" />
                        <span className="logo-item">kincrew</span>
                    </Link>
                </div>
            </div>      
            </div>
            <div className="container">
                <h3 className="heading">Subscriptions and Payment Info</h3>
                <UpgradeSubscription />
                <PaymentMethod />
                <PaymentHistory />
            </div>
        </div>        
        );
    }
}


export default EditSubscription;