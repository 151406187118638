import React, { Component } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router-dom';

// import PrivateRoute from './privateRoute';
//import LoginPage from '../components/loginPage';
//import RegisterPage from '../components/registerPage';
//import DashboardPage from '../components/dashboardPage';

import 'App.scss';
import 'antd/dist/antd.css';
// import Header from 'Components/Header/Header';
// import Landing from 'pages/Landing/Landing';
import Signin from 'pages/Signin/Signin';
import Signup from 'pages/Signin/Signup';
import Routes from 'routes';
import Subscription from 'pages/SubscriptionNew/Subscription';
import SignupTrail from 'pages/Signin/SignupTrial';
import ForgotPassword from 'pages/Signin/ForgotPassword';
import ResetPassword from 'pages/Signin/ResetPassword';
import LandingPage from 'pages/Landing/Landing';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import history from 'utils/history';
import { ROUTES } from 'constants/routes';
//import NotSubscribed from 'pages/SubscriptionNew/NotSubscribed';
import { getSubscriptionCheck } from 'services/subscriptionService';
import { Modal } from 'antd';
import { createBrowserHistory } from 'history';
import SubscriptionDetails from 'pages/SubscriptionNew/SubscriptionDetails';
import { Redirect } from 'react-router-dom';
import { checkCookie } from 'utils/cookies';
import { getUserSubscription } from 'services/subscriptionService';
import { getCheckListAction } from 'actions/userActions';
import { getSetupProgress } from 'services/userService';
import Chat from 'pages/Chat/Chat';
import SetupCheckListModal from 'pages/Landing/SetUpCheckList/SetupCheckListModal';

class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubscribed: true,
            baseChecklist: false,
            setupProgress: [],
            setUpModalVisible: false,
            msg: ''
        };
        this.handleCheckSubcription = this.handleCheckSubcription.bind(this);
    }

    handleCheckSubcription = () => {
        let arr = [
            '/home',
            '/household',
            '/payment',
            '/calendar',
            '/chat',
            '/chat/:id',
            '/chat/new',
            '/chat/topic',
            '/chat/archived',
            '/chat/archived/:id',
            '/reports'
        ];
        if (localStorage.resp) {
            let userInfo;
            if (typeof localStorage.resp !== 'undefined') {
                userInfo = typeof localStorage.resp === 'string' ? JSON.parse(localStorage.resp) : JSON.parse(JSON.stringify(localStorage.resp));
            }
            if (userInfo && userInfo.subscription_id === undefined) {
                getSubscriptionCheck().then((res) => {
                    if (!res.inTrial) {
                        if
                            ((
                                this.props.location.pathname !== '/subscriptionDetails' &&
                                this.props.location.pathname !== '/subscription' &&
                                this.props.location.pathname !== '/login' &&
                                this.props.location.pathname !== '/' &&
                                this.props.location.pathname !== '/subscription' &&
                                this.props.location.pathname !== '/' &&
                                this.props.location.pathname !== '/forgot' &&
                                this.props.location.pathname !== '/reset' &&
                                this.props.location.pathname !== '/forgotpassword' &&
                                this.props.location.pathname !== '/resetpassword' &&
                                this.props.location.pathname !== '/signup' &&
                                this.props.location.pathname !== '/signup/' &&
                                (arr.includes(this.props.location.pathname) || this.props.location.pathname.includes('/chat'))
                            )) {

                            let message = '';
                            if (res.newUser) {
                                message = 'You need to subscribe...';
                            } else {
                                message = 'Your trial period is expired please subscribe ...';
                            }

                            this.setState({ isSubscribed: false, msg: message }, () => {
                                setTimeout(() => { this.setState({ isSubscribed: true }, () => this.props.history.push('/subscription')) }, 5000);
                            })
                        }
                    } else {
                        this.setState({ isSubscribed: true }, () => this.handleCheckList());
                    }
                });
            } else {
                this.setState({ isSubscribed: true }, () => this.handleCheckList());
            }
        }
    };

    handleCheckList = () => {

        if (localStorage.third_party && localStorage.third_party == 'true') { }
        else {
            let arr = [
                '/payment',
                '/calendar',
                '/chat',
                '/chat/:id',
                '/chat/new',
                '/chat/topic',
                '/chat/archived',
                '/chat/archived/:id',
                '/reports'
            ];
            if (this.state.isSubscribed) {
                getSetupProgress().then(async (resp) => {
                    if (typeof resp === 'object') {
                        const calendarEnabled =
                            !!resp["profile"] &&
                            !!resp["coParent"] &&
                            !!resp["children"];
                        this.setState({ baseChecklist: calendarEnabled, setupProgress: resp })
                        if ((!this.state.baseChecklist &&
                            this.props.location.pathname !== '/subscriptionDetails' &&
                            this.props.location.pathname !== '/subscription' &&
                            this.props.location.pathname !== '/login' &&
                            this.props.location.pathname !== '/' &&
                            this.props.location.pathname !== '/subscription' &&
                            this.props.location.pathname !== '/' &&
                            this.props.location.pathname !== '/forgot' &&
                            this.props.location.pathname !== '/reset' &&
                            this.props.location.pathname !== '/forgotpassword' &&
                            this.props.location.pathname !== '/resetpassword' &&
                            this.props.location.pathname !== '/signup' &&
                            this.props.location.pathname !== '/signup/' &&
                            (arr.includes(this.props.location.pathname) || this.props.location.pathname.includes('/chat'))
                        )) {
                            setTimeout(() => { this.setState({ setUpModalVisible: true }, () => this.props.history.push('/home')) }, 5000);

                        }
                    }
                })
            }
        }

    }

    setModal2Visible = async function (modalVisible) {
        await this.setState({ setUpModalVisible: modalVisible });
    }

    componentDidMount() {
        this.handleCheckSubcription();
        if (this.props.location.pathname == '/' && checkCookie() !== null) {
            this.props.history.push('/home')
        }

    }

    UNSAFE_componentWillReceiveProps() {
        this.handleCheckSubcription();
        // this.handleCheckList();
    }

    render() {
        const history = createBrowserHistory();
        const arr = [
            '/',
            '/login',
            '/signin',
            '/signup',
            '/subscription',
            '/subscriptionDetails',
            '/signuptrial',
            '/forgotpassword',
            '/resetPassword',
            '/home',
            '/household',
            '/payment',
            '/calendar',
            '/chat',
            '/chat/:id',
            '/chat/new',
            '/chat/topic',
            '/chat/archived',
            '/chat/archived/:id',
            '/reports',
            '/notifications',
        ];


        return (
            <React.Fragment>
                {/* // <Router > */}
                <div>
                    <Switch history={history}>
                        <Route path="/" exact={true} component={Signin} />
                        <Route path="/login" component={Signin} />
                        <Route path="/signup" component={Signup} />
                        <Route path="/signuptrial" component={SignupTrail} />
                        <Route path="/forgotpassword" component={ForgotPassword} />
                        <Route path="/resetPassword" component={ResetPassword} />
                        {checkCookie() !== null ? <Route path="/subscription" component={Subscription} /> : null}
                        {checkCookie() !== null ? <Route path="/subscriptionDetails" component={SubscriptionDetails} /> : null}
                        {checkCookie() === null && history.location.pathname.includes('/chat') && <Redirect to="/" />}
                        {history.location.pathname.includes('/chat') ? null : (arr.includes(history.location.pathname) === false) ? <Route component={ErrorPage} /> : null}
                        <Routes history={history} />
                    </Switch>
                </div>
                {/* // </Router> */}
                {!this.state.isSubscribed && (
                    <React.Fragment>
                        <Modal
                            title={this.state.msg}
                            visible={!this.state.isSubscribed}
                            maskClosable={false}
                            className="error-popup cal-modal swap-modal sc-modal"
                            ariaHideApp={false}
                        ></Modal>
                    </React.Fragment>
                )}
                {this.state.isSubscribed && (
                    <SetupCheckListModal
                        handleOpen={this.state.setUpModalVisible}
                        handleClose={() => this.setModal2Visible(false)}
                        checkList={this.state.setupProgress}
                        baseListStatus={this.state.baseChecklist} />
                )}

            </React.Fragment>
        );
    }
}

export default withRouter(Container);
