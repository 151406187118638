import React, { Component, useState } from 'react';
import 'pages/Reports/ReconcileRecurring/ReconcileRecurring.scss';
import { Card, Button, Tabs, Modal, Select, DatePicker } from 'antd';
import { connect } from 'react-redux';
import moment, { duration } from 'moment';
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { CreateRecurringReport } from 'services/recurringReportsService';
import SaveReport from 'pages/Reports/ReconcileRecurring/SaveReport';

const { TabPane } = Tabs;
const { Option } = Select;

function callback(key) {
    
}
const colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    gradient1: 'Gradient1',
    gradient2: 'Gradient2',
};

const dateDropwnData = [
    { id: 1, name: 'Last 7 days' },
    { id: 2, name: 'Last 14 days' },
    { id: 3, name: 'Last 3 months' },
    { id: 4, name: 'Last 6 months' },
    { id: 5, name: 'Last 12 months' },
    { id: 6, name: 'Custom' },
];

const status = [
    { label: 'Paid', value: 'paid' },
    { label: 'Declined', value: 'declined' },
    { label: 'Disputed', value: 'disputed' },
    { label: 'Pending', value: 'pending' },
    { label: 'InProgress', value: 'inProgress' },
];
 var d = new Date();
 var utc = d.getTime();

class CreateReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveReportVisible: false,
            coParent: null,
            coParentId: '',
            child_ids: [],
            selectedDate: null,
            startdate: new Date(utc),
            enddate: new Date(utc),
            statusSel: [],
            LoadingIs: false,
            errors: {
                coParent: '',
                kids: '',
                selectedDate: '',
                startdate: '',
                enddate: '',
            },
        };
        this.handleCoparent = this.handleCoparent.bind(this);
        this.getChildren = this.getChildren.bind(this);
        this.handleChilds = this.handleChilds.bind(this);
        this.CreateReportBtnClkd = this.CreateReportBtnClkd.bind(this);
        this.validate = this.validate.bind(this);
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        if (nextProps && nextProps.handleOpen) {
            let errors = {
                coParent: '',
                kids: '',
                selectedDate: '',
                startdate: '',
                enddate: '',
            };

            await this.setState({
                coParent: null,
                kids: [],
                child_ids: [],
                errors: errors,
                LoadingIs: false,
                startdate: new Date(utc),
                enddate: new Date(utc),
            });
        }
    };

    setSaveModalVisible(modalVisible) {
        this.setState({ saveReportVisible: modalVisible });
    }

    handleAllClose() {
        this.setState({ saveReportVisible: false });
        this.props.handleRefreshClose();
    }

    handleEndField = async (e) => {
        if (e && e._d) {
            await this.setState({ enddate: this.formatDate(e._d) });
            this.endDateValidation();
        }
    };

    handleStartField = async (e) => {
        if (e && e._d) {
            await this.setState({ startdate: this.formatDate(e._d) });
            this.endDateValidation();
        }
    };

    async endDateValidation() {
        const { selectedDate, startdate, enddate } = this.state;
         let Dateerrors = '';
        if (startdate !== null && enddate !== null) {
            if (this.formatDate(startdate) === this.formatDate(enddate)) {
                Dateerrors = 'End date must be more than start date!';
            } else if (this.formatDate(enddate) < this.formatDate(startdate)) {
                Dateerrors = 'End date must be more than start date!';
            }
        }
        await this.setState({
            errors: { ...this.state.errors, enddate: Dateerrors }
        });
    }

    disabledDate = (current) => {
        return current && current.valueOf() > Date.now();
    };

    formatDate = (dateObj) => {
        let date = new Date(dateObj);
        let month = date.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = date.getFullYear();
        let output = parseInt(year.toString()) + '-' + month + '-' + day;
        return output;
    };

    handleChilds = async function (value) {
        if (value.length === 0) {
            await this.setState({
                child_ids: value,
               // errors: { ...this.state.errors, kids: 'You have to select atleast one item' },
            });
        } else {
            // await this.setState({ child_ids: value, errors: { ...this.state.errors, kids: '' } });
             await this.setState({ child_ids: value });
        }
    };

    getChildren = async function (coparent) {
        const { user, children, coparents } = this.props;
        const { errors } = this.state;
        let arr = [];
        children.forEach((child) => {
            if (child.coparent_user_id) {
                if (
                    (child.created_by_user === user.id && child.coparent_user_id === coparent.id) ||
                    (child.created_by_user === coparent.id && child.coparent_user_id === user.id)
                ) {
                    arr.push(child);
                }
            } else {
                if (child.coparent_standalone_id) {
                    coparents.forEach(cp => {
                        if(cp.name !== 'Household'){
                            if(
                                cp.id === coparent.id && (
                                child.coparent_standalone_id === cp.id ||
                                cp.invitationsIds && cp.invitationsIds.includes(child.coparent_standalone_id))
                            ){
                                arr.push(child);
                            }
                        }
                    })
                }
            }
        });

        // let error = '';
        // if (errors.kids.length !== 0 && arr.length !== 0) {
        //     error = 'Field is required!';
        // } else if (arr.length === 0) {
        //     error = 'No Children In List! (Add Child First)';
        // }
        // await this.setState({ kids: arr, errors: { ...this.state.errors, kids: error } });
        await this.setState({ kids: arr });
    };

    handleCoparent = async function (value) {
        const { coparents } = this.props;
        let coparent = {};
        coparents.forEach((c) => {
            if (c.id === value) {
                coparent = c;
            }
        });
        await this.setState({
            coParent: coparent,
            coParentId: coparent.id,
            child_ids: [],
            errors: { ...this.state.errors, coParent: '' },
        });

        this.getChildren(coparent);
    };

    async onDateChange(value) {
        // this.setState({ selectedDate: value, startdate: null, enddate: null });

        let start = null;
        let end = null;

        if(value != '') {
            await this.setState({
                 errors: { ...this.state.errors, selectedDate: '' },
            });
        }

        if (value == 1) {
            start = new Date(moment().subtract(7, 'days').add(1, 'days'));
            end = new Date(new Date());
        } else if (value == 2) {
            start = new Date(moment().subtract(14, 'days').add(1, 'days'));
            end = new Date(new Date());
        } else if (value == 3) {
            start = new Date(moment().subtract(3, 'month').add(1, 'days'));
            end = new Date(new Date());
        } else if (value == 4) {
            start = new Date(moment().subtract(6, 'month').add(1, 'days'));
            end = new Date(new Date());
        } else if (value == 5) {
            start = new Date(moment().subtract(12, 'month').add(1, 'days'));
            end = new Date(new Date());
        } else if (value == 6) {
            start = new Date(new Date());
            end = new Date(new Date());
        }

        this.setState({
            selectedDate: value,
            startdate: start,
            enddate: end,
        });
    }

    validate() {
        const { flag, coParent, coParentId, child_ids, kids, selectedDate, startdate, enddate } = this.state;

        let errors = {};
        if (coParentId === null || coParentId == '') {
            errors.coParent = 'Field is required!';
        } else {
            errors.coParent = '';
        }

        // if (kids.length === 0) {
        //     if (this.state.errors.kids.length !== 0) {
        //         errors.kids = this.state.errors.kids;
        //     } else {
        //         errors.kids = 'Field is required!';
        //     }
        // } else {
        //     if (this.state.errors.kids) {
        //         errors.kids = this.state.errors.kids;
        //     } else {
        //         errors.kids = '';
        //     }
        // }

        // if (this.state.child_ids.length === 0) {
        //     errors.kids = 'Field is required!';
        // } else {
        //     errors.kids = '';
        // }

        if (selectedDate === null) {
            errors.selectedDate = 'Field is required!';
        } else {
            errors.selectedDate = '';
            if (selectedDate === 6) {
                if (startdate === null) {
                    errors.startdate = 'Field is required!';
                } else {
                    errors.startdate = '';
                }
                if (enddate === null) {
                    errors.enddate = 'Field is required!';
                } else {
                    errors.enddate = '';
                }

                if (startdate !== null && enddate !== null) {
                    if (this.formatDate(startdate) === this.formatDate(enddate)) {
                        errors.enddate = 'End date must be more than start date!';
                    } else if (this.formatDate(enddate) < this.formatDate(startdate)) {
                        errors.enddate = 'End date must be more than start date!';
                    }
                }
            }
        }

        if (selectedDate !== 6) {
            if (errors.coParent === ''  && errors.selectedDate === '') {
                return true;
            } else {
                this.setState({ errors: errors });
                return false;
            }
        } else {
            if (
                errors.coParent === '' &&
                errors.selectedDate === '' &&
                errors.startdate === '' &&
                errors.enddate === ''
            ) {
                return true;
            } else {
                this.setState({ errors: errors });
                return false;
            }
        }
    }
    CreateReportBtnClkd = () => {
        const { coParent, coParentId, child_ids, startdate, enddate, statusSel } = this.state;
        let data = {
            coparent_id: coParentId,
            startDate: Moment(startdate).format('DD-MM-YYYY'),
            endDate: Moment(enddate).format('DD-MM-YYYY'),
            child_ids: child_ids,
            status: statusSel,
        };
        if (this.validate()) {
            this.setState({ LoadingIs: true });
            CreateRecurringReport(data).then((resp) => {
                this.setState({ LoadingIs: false });
                resp.data.startDate = moment(startdate).format('DD-MM-YYYY');
                resp.data.endDate = moment(enddate).format('DD-MM-YYYY');
                resp.data.coparent_id = coParentId;
                resp.data.child_ids = child_ids;
                let responseData = {
                    data: resp.data,
                    type: 'create',
                };
                this.setState({ saveData: responseData }, () => this.setSaveModalVisible(true));
            });
        }
    };

    render() {
        const { coparents, user } = this.props;
        const { coParent, kids, child_ids, errors, selectedDate, statusSel, LoadingIs } = this.state;

        function getColor(item){
            return (item.created_by_user === (user && user.id))?item.color: item.coparent_color;
        }
        return (
            <div>
                <Modal
                    title="Reconcile Recurring"
                    centered
                    className="report-modal"
                    maskClosable={false}
                    visible={this.props.handleOpen}
                    onOk={this.props.handleClose}
                    onCancel={this.props.handleClose}
                >
                    <LoadingOverlay
                        active={LoadingIs}
                        className={LoadingIs ? 'd-block pop-loader' : 'd-none pop-loader'}
                        spinner
                        styles={{
                            spinner: (base) => ({
                                ...base,
                                width: '100px',
                                '& svg circle': {
                                    stroke: 'rgb(56, 160, 235)',
                                },
                            }),
                        }}
                    ></LoadingOverlay>
                    <div className="modal-body">
                        <form>
                            <Select
                                placeholder="Select Co -Parent"
                                className="full-select"
                                value={coParent === null ? 'Select Co-parent' : coParent.name}
                                onChange={this.handleCoparent}
                            >
                                {Array.isArray(coparents) &&
                                    coparents.map(
                                        (coparent, i) =>
                                            coparent.name !== 'Household' && (
                                                <Option key={i} value={coparent.id}>
                                                    {coparent.name}
                                                </Option>
                                            ),
                                    )}
                            </Select>
                            {errors.coParent !== '' ? <div className="form-err">{errors.coParent}</div> : null}
                            <Select
                                placeholder="Select child"
                                className="full-select"
                                value={child_ids}
                                mode="multiple"
                                onChange={this.handleChilds}
                            >
                                {Array.isArray(kids) &&
                                    kids.map((kid, i) => (
                                        <Option
                                            key={i}
                                            className={child_ids.includes(kid.id) ? 'text-color' : ''}
                                            value={kid.id}
                                        >
                                            <span className={'dot ' + colors[getColor(kid)]}></span>
                                            {kid.name}
                                        </Option>
                                    ))}
                            </Select>
                           {/* {errors.kids !== '' ? <div className="form-err">{errors.kids}</div> : null}*/}
                            <Select
                                placeholder="Payment Status"
                                className="full-select"
                                value={statusSel}
                                mode="multiple"
                                onChange={(statusSel) => {
                                    this.setState({ statusSel });
                                }}
                            >
                                {Array.isArray(status) &&
                                    status.map((sel, i) => (
                                        <Option key={i} value={sel.value}>
                                            {sel.label}
                                        </Option>
                                    ))}
                            </Select>
                            <Select
                                defaultValue="Select Date"
                                className="full-select"
                                onChange={(value) => {
                                    this.onDateChange(value);
                                }}
                            >
                                {Array.isArray(dateDropwnData) &&
                                    dateDropwnData.map((val, i) => (
                                        <Option key={i} value={val.id}>
                                            {val.name}
                                        </Option>
                                    ))}
                            </Select>
                            {errors.selectedDate !== '' ? <div className="form-err">{errors.selectedDate}</div> : null}

                            {selectedDate && selectedDate == 6 && (
                                <div className="date-picker-wrapper">
                                    <div className="date-picker-inner">
                                        <span className="dp-label">Start Date</span>
                                        <DatePicker
                                            className="cust-dp"
                                            inputReadOnly={true}
                                            value={Moment(this.state.startdate)}
                                            placeholder="Start Date"
                                            size="large"
                                            onChange={this.handleStartField}
                                        />
                                        {errors.startdate !== '' ? (
                                            <div className="form-err">{errors.startdate}</div>
                                        ) : null}
                                    </div>
                                    <div className="date-picker-inner">
                                        <span className="dp-label">End Date</span>
                                        <DatePicker
                                            className="cust-dp"
                                            inputReadOnly={true}
                                            disabledDate={this.disabledDate}
                                            value={Moment(this.state.enddate)}
                                            placeholder="End Date"
                                            size="large"
                                            onChange={this.handleEndField}
                                        />
                                        {errors.enddate !== '' ? (
                                            <div className="form-err">{errors.enddate}</div>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                            <Button
                                className="fp-button-secondary create-btn"
                                onClick={() => this.CreateReportBtnClkd()}
                            >
                                Create Report
                            </Button>
                        </form>
                    </div>

                    <SaveReport
                        handleOpen={this.state.saveReportVisible}
                        handleClose={() => this.setSaveModalVisible(false)}
                        handleAllClose={() => this.handleAllClose()}
                        responseInfo={this.state.saveData}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparents, coparent } = serviceReducer;
    return { user, error, children, coparents, coparent };
};
export default connect(mapStateToProps)(CreateReport);
