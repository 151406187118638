import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';

const { Option } = Select;
const { TextArea } = Input;

class LogoutModal extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <Modal
            centered
            maskClosable={false}
            visible={this.props.openModal}
            className="payments-popup custom-popup ach-error-popup"
            onOk={this.props.closeModal}
            onCancel={this.props.closeModal}
            >
                 <div style={{textAlign: 'center', width: '75%', margin: 'auto', fontWeight: '700', marginBottom: '20px'}}>
                 {this.props.msg}
                </div>
                <Button style={{width: '75%', marginLeft: '12.5%'}} type="primary" onClick={this.props.closeModal} className="btn fp-button-primary">
                    Ok
                </Button>
              
            </Modal>
        );
    }
}

export default LogoutModal;