import React, { Component } from 'react';
import 'pages/Chat/ChatContainer/ChatContainer.scss';
import { Button, Modal, Input } from 'antd';
import Icon from '@ant-design/icons';
import { RemoveMembers } from 'services/chatService';
import LoadingOverlay from 'react-loading-overlay';

const { TextArea } = Input;


class ArchiveModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
           loading: false,
           dialogId: '',
           fpDialogID: '',
           userId: '',
        };
        this.handleArchive = this.handleArchive.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps && nextProps.handleOpen) {
            this.setState({
                dialogId: nextProps.dialogId,
                fpDialogID: nextProps.fpDialogID,
                userId: nextProps.userId,
            });
        }
    }

    handleArchive = () => {
        this.setState({ loading: true });
        let updateFP = {
            id: this.state.fpDialogID,
            qb_dialogue_id: this.state.dialogId,
            members: [this.state.userId],
        };
        
        RemoveMembers(updateFP)
        .then(resp => {
            this.setState({loading: false});
            if(resp){
                this.props.handleClose();
                this.props.refresh();
            }
        })
         
    }

    render() {
        const { loading } = this.state;

        return (
            <Modal
                title="Do you want to archive?"
                centered
                className="chat-modal"
                maskClosable={false}
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={'loader-wrap chat-modal-loader ' + (loading ? 'd-block' : '')}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <div className="new-topic-wrap">
                    <Button
                       onClick={this.handleArchive}
                       className="fp-button-secondary archive-btn"
                    >
                        Archive
                    </Button>
                    <Button
                       onClick={this.props.handleClose} 
                       className="fp-button-secondary archive-btn"
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
        )
    }
}

export default ArchiveModal;