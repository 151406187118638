import * as QB from "quickblox/quickblox";
import { UpOutlined } from "@ant-design/icons";
import moment from 'moment';

const PASSWORD = "12345678";

export const ChatHistory = async (dialogId, limit, skip, filter, archived, exportchat=null) => {
    

    return new Promise(resolve => {

        if(exportchat) {

            if(archived) {
                var params = {
                    chat_dialog_id: dialogId,
                    sort_desc: 'date_sent',
                    //limit: limit,
                    skip: skip,
                    markAsRead: true,
                };

                params[`date_sent[${filter[0].operator}]`] = filter[0].value;
                params[`date_sent[${filter[1].operator}]`] = filter[1].value;
                  
                QB.chat.message.list(params, function(error, messages) {
                    resolve(messages);               
                });

            } else {
               var params = {
                    chat_dialog_id: dialogId,
                    sort_desc: 'date_sent',
                    //limit: limit,
                    skip: skip,
                    markAsRead: true,
                };

                params[`date_sent[${filter[0].operator}]`] = filter[0].value;
                params[`date_sent[${filter[1].operator}]`] = filter[1].value;
                  
                QB.chat.message.list(params, function(error, messages) {
                    resolve(messages);               
                }); 
            }
             


        } else {
            if (archived) {
                var params = {
                    chat_dialog_id: dialogId,
                    sort_desc: 'date_sent',
                    limit: limit,
                    skip: skip,
                    markAsRead: true,
                };

                
                params[`date_sent[${filter.operator}]`] = filter.value;
                  
                QB.chat.message.list(params, function(error, messages) {
                    resolve(messages);               
                }); 

            } else {
                var params = {
                    chat_dialog_id: dialogId,
                    sort_desc: 'date_sent',
                    limit: limit,
                    skip: skip,
                    markAsRead: true,
                };
                  

                QB.chat.message.list(params, function (error, messages) {
                    resolve(messages);               
                }); 
            }
        }

        
    })
};


export const RetrieveUserChatHistory = async (dialogId, sender_id, archived, archived_at) => {
    
    return new Promise(resolve => {
        if (archived) {
            var params = {
                chat_dialog_id: dialogId,
                sort_desc: 'date_sent',
                //sender_id: sender_id,
                markAsRead: true,
            };

            params[`date_sent[lte]`] = "" + archived_at;

              
            QB.chat.message.list(params, function(error, messages) {
                resolve(messages);               
            }); 

        } else {
            var params = {
                chat_dialog_id: dialogId,
                sort_desc: 'date_sent',
                sender_id: sender_id,
                markAsRead: true,
            };
              
            
            QB.chat.message.list(params, function(error, messages) {
                resolve(messages);               
            }); 
        }
    })

}

export const JoinChatGroup = async (dialogId) => {
    let timerid;
    var dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialogId);


    return new Promise(resolve => {
        if(QB.chat.muc && QB.chat.muc.joinedRooms && 
            QB.chat.muc.joinedRooms && QB.chat.muc.joinedRooms[dialogJid] &&
            QB.chat.muc.joinedRooms[dialogJid] ===  true){
            return resolve({id:dialogId, connected: true});
        }
        try {
            QB.chat.muc.join(dialogJid, function (error, result) {
                if (timerid) {
                    clearTimeout(timerid);
                }
                resolve(result);
            });
        } catch (e) {
            if (e.name === 'ChatNotConnectedError') {
              // not connected to chat
            }
        }
        finally {
            timerid = setTimeout(() => {
                if(QB.chat.muc && QB.chat.muc.joinedRooms && 
                    QB.chat.muc.joinedRooms && QB.chat.muc.joinedRooms[dialogJid] &&
                    QB.chat.muc.joinedRooms[dialogJid] ===  true){
                    return resolve({id:dialogId, connected: true});
                }
            }, 4000);
        }
    });
}

export const LeaveChatGroup = async (dialogId) => {

    if (QB.chat) {
        var dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialogId);
    }
    
    try {
        QB.chat.muc.leave(dialogJid, function(error) {
          
        });
      } catch (e) {
        if (e.name === 'ChatNotConnectedError') {
          // not connected to chat
        }
    }
}

export const SendToGroup = async (dialogId, body) => {
    return new Promise(resolve => {
        var message = {
            type: "groupchat",
            body: body,
            extension: {
              save_to_history: 1,
              dialog_id: dialogId
            },
            markable: 1
        };
          
        var dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialogId);
          
        message.id = QB.chat.send(dialogJid, message);
        resolve(message.id);
 
    });
      
};

export const UploadAttachment = async (inputFile) => {

    var fileParams = {
        name: inputFile.name,
        file: inputFile,
        type: inputFile.type,
        size: inputFile.size,
        public: false
    };

    return new Promise(resolve => {
        QB.content.createAndUpload(fileParams, function(error, result) {
            if(result){
                resolve(result.uid);
            }
        });
    })

}

export const SendAttachment = (dialogId, UID) => {
    return new Promise(resolve => {
        var message = {
            type: "groupchat",
            body: "",
            extension: {
              save_to_history: 1,
              dialog_id: dialogId,
              attachments: [{ id: UID, type: "image" }]
            },
            markable: 1
        };

        var dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialogId);
          
        message.id = QB.chat.send(dialogJid, message);
        
        resolve(message.id);
    });

}

export const SendAudio = (dialogId, UID) => {
    return new Promise(resolve => {
        var message = {
            type: "groupchat",
            body: "",
            extension: {
              save_to_history: 1,
              dialog_id: dialogId,
              attachments: [{ id: UID, type: "audio" }]
            },
            markable: 1
        };

        var dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialogId);
          
        message.id = QB.chat.send(dialogJid, message);
        
        resolve(message.id);
    });

}

export const MarkMessageRead = async (message) => {
    
    var params = {
        messageId: message.id,
        userId: message.sender_id,
        dialogId: message.dialog_id
    };
      
    try {
        QB.chat.sendReadStatus(params);
    } catch (e) {
        if (e.name === "ChatNotConnectedError") {
          // not connected to chat
        }
    }
};

export const MarkRead = async (message) => {
    
    var params = {
        messageId: message.id,
        userId: message.user && message.user.qb_user_id,
        dialogId: message.user && message.user.qb_dialogue_id
    };
      
    QB.chat.sendReadStatus(params);
};

export const GetOnlineUsersList = (_dialogId) => {
     
    var dialogId = _dialogId;
    var dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialogId);
    
    return new Promise(resolve => {
        QB.chat.muc.listOnlineUsers(dialogJid, function(users) {
            resolve(users);
        });
    });
}


export const UnreadMessagesCount = (dialogId) => {
    var params = {
        chat_dialog_ids: [dialogId]
    };
      
    return new Promise(resolve => {
        QB.chat.message.unreadCount(params, function(error, result) {
            resolve(result);
        });
    });
}


export const GetContactList = () => {

    return new Promise(resolve => {
        try {
            QB.chat.roster.get(function (contactlist) {
                resolve(contactlist);
            });
        } catch (e) {
            if (e.name === 'ChatNotConnectedError') {
                // not connected to chat
            }
        }
    });
}

export const AddUser = (userId) => {

    return new Promise(resolve => {
        try {
            QB.chat.roster.add(userId, function () {
                resolve(true);
            });
          } catch (e) {
            if (e.name === 'ChatNotConnectedError') {
              // not connected to chat
            }
          }
    });
}

export const ConfirmUser = (userId) => {

    try {
        QB.chat.roster.confirm(userId, function() {
      
        });
    } catch (e) {
        if (e.name === 'ChatNotConnectedError') {
          // not connected to chat
        }
    }
    
}