import { makeRequest } from './authenticationService';


export const getCoParents = (request) => {
    
    let url = '/api/coparents/fetch';
    
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const acceptKid = (url, children) => {
	return makeRequest('POST', url, children)
        .then((response) => {
            return response;
        })
        .then((json) => {
            return json;
        });
}

export const declineKid = (url) => {
	return makeRequest('DELETE', url)
        .then((response) => {
            return response;
        })
        .then((json) => {
            return json;
        });
}


