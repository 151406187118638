import React, { Component } from 'react';
import 'pages/Chat/ChatContainer/ChatContainer.scss';
import * as QB from 'quickblox/quickblox';
import { Button, Modal, Input, Checkbox, Card, Avatar } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import user from 'Assets/default.jpg';
import { QuickBlockConfig } from 'pages/QuickBlox/QBConfig';
import {
    getQBCredentials,
    updateQBUserData
} from 'services/chatService';
import { updateQBStatus } from 'actions/userActions';
import {
    CreateUser, 
    LoginUser,
    ConnectChatServer,
    IsConnected,
    GetSession,
    CreateSession,
    CreateAppSession,
    GetUser
} from 'pages/QuickBlox/qbutility';
import {
    ChatHistory,
    JoinChatGroup,
    SendToGroup,
    MarkMessageRead,
    UploadAttachment,
    SendAttachment,
    SendAudio
} from 'pages/QuickBlox/qbChat';
import { GetChatList } from 'services/chatService';
import { GetAllDialogs } from 'pages/QuickBlox/qbDialogs';
import LoadingOverlay from 'react-loading-overlay';
import { createNewSubscription } from 'services/subscriptionService';
import Reports from 'pages/Reports/Reports';

class SelectTopicModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            topicList: [],
            userid: '',
            offset: 0,
            limit: 15,
            chatItem: null,
            copyMessages: [],
        };
        this.getDialogList = this.getDialogList.bind(this);
        this.getPicture = this.getPicture.bind(this);
        this.getUsersName = this.getUsersName.bind(this);
        this.handleTopicScroll = this.handleTopicScroll.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps = async function(nextProps) {
        if (nextProps && nextProps.handleOpen && nextProps.copyMessages && nextProps.messages) {
            let arr = [];
            for (let i = 0; i < nextProps.messages.length; i++){
                let msg = nextProps.messages[i];
                nextProps.copyMessages.forEach(item => {
                    if (msg.id === item.id) {
                        arr.push(msg);
                    }
                })
            }

            await this.setState({
                loading: true,
                topicList: [],
                userid: '',
                offset: 0,
                limit: 15,
                chatItem: null,
                copyMessages: arr || [],
            });

            this.getDialogList(0, true);
        }
    }

    getDialogList = async function (offset, flag) {
        const { limit, topicList } = this.state;
        // await this.setState({ loading: true });
        let userid = await localStorage.getItem('user_id');
        this.setState({ userid: userid });
        let list = await GetAllDialogs();
        if (!list) {
            this.setState({ loading: false });
        }

        if (list && Array.isArray(list.items) && list.items.length > 0) {
            GetChatList(1, offset, limit).then(async (resp) => {
                if (resp && resp.data.length > 0) {
                    await this.setState({ offset: this.state.offset + 15 });

                    let topics = await this.parseListData(resp.data, list.items);

                    if (flag) {
                        let arr = [];
                        arr = [...arr, ...topics];
                        this.setState({ topicList: arr, loading: false});
                    } else {
                        let arr = [];
                        arr = [...topicList, ...topics];
                        this.setState({ topicList: arr, loading: false });
                    }
                } else {
                    this.setState({ loading: false });
                }
            })

        } else {
            this.setState({ loading: false });
        }
    }

    parseListData = async (fpList, qbList) => {
        let array = [];
        for (const dialoglist of fpList) {
            for (const data of qbList) {
                if (data._id == dialoglist.qb_dialogue_id) {
                    if (localStorage.third_party !== 'true') {
                        let obj = {};
                        obj.type = dialoglist.Dialogues[0].type;
                        obj.id = data._id;
                        obj.fp_dialogid = dialoglist.dialogue_id;

                        obj.unread_count = data.unread_messages_count;
                        obj.qb_dialogue_id = dialoglist.qb_dialogue_id;

                        let messageObj = {};

                        if (dialoglist.is_archived) {
                            let messageDate = Math.round(new Date(data.updated_at).getTime() / 1000);
                            var archived = dialoglist.archived_at;
                            let archivedTimestamp = Math.round(new Date(archived).getTime() / 1000);
                            if (messageDate > archivedTimestamp) {
                                messageObj.id = uuid();
                                messageObj.author = data.last_message_user_id;
                                messageObj.text = data.last_message;
                                messageObj.created_at = data.updated_at;
                                messageObj.unread = true;
                                obj.last_message = messageObj;
                            }
                        } else {
                            messageObj.id = uuid();
                            messageObj.author = data.last_message_user_id;
                            messageObj.text = data.last_message;
                            messageObj.created_at = data.updated_at;
                            messageObj.unread = true;
                            obj.last_message = messageObj;
                        }

                        obj.title = dialoglist.Dialogues[0].name;

                        obj.is_archived = dialoglist.is_archived;
                        obj.archived_at = dialoglist.archived_at;
                        obj.createdBy = dialoglist.Dialogues[0].create_by;
                        let members = dialoglist.Dialogues[0].DialogueMembers;

                        let users = await members.map((mem) => {
                            // let user = this.state.coparents.filter((x) => {
                            //   return x.id == mem.user_id;
                            // });

                            // mem.first_name = user[0] ? user[0].first_name : "";
                            mem.first_name = mem.userInfo.first_name;
                            mem.last_name = '';
                            mem.picture = mem.userInfo.file_url;
                            return mem;
                        });
                        obj.users = users;
                        array.push(obj);

                        break;
                    } else if (localStorage.third_party === 'true') {
                        if (dialoglist.Dialogues[0] && dialoglist.Dialogues[0].create_by === localStorage.coparent_id) {
                            let obj = {};
                            obj.type = dialoglist.Dialogues[0].type;
                            obj.id = data._id;
                            obj.fp_dialogid = dialoglist.dialogue_id;

                            obj.unread_count = data.unread_messages_count;
                            obj.qb_dialogue_id = dialoglist.qb_dialogue_id;

                            let messageObj = {};

                            if (dialoglist.is_archived) {
                                let messageDate = Math.round(new Date(data.updated_at).getTime() / 1000);
                                var archived = dialoglist.archived_at;
                                let archivedTimestamp = Math.round(new Date(archived).getTime() / 1000);
                                if (messageDate > archivedTimestamp) {
                                    messageObj.id = uuid();
                                    messageObj.author = data.last_message_user_id;
                                    messageObj.text = data.last_message;
                                    messageObj.created_at = data.updated_at;
                                    messageObj.unread = true;
                                    obj.last_message = messageObj;
                                }
                            } else {
                                messageObj.id = uuid();
                                messageObj.author = data.last_message_user_id;
                                messageObj.text = data.last_message;
                                messageObj.created_at = data.updated_at;
                                messageObj.unread = true;
                                obj.last_message = messageObj;
                            }

                            obj.title = dialoglist.Dialogues[0].name;

                            obj.is_archived = dialoglist.is_archived;
                            obj.archived_at = dialoglist.archived_at;
                            obj.createdBy = dialoglist.Dialogues[0].create_by;
                            let members = dialoglist.Dialogues[0].DialogueMembers;

                            let users = await members.map((mem) => {
                                // let user = this.state.coparents.filter((x) => {
                                //   return x.id == mem.user_id;
                                // });

                                // mem.first_name = user[0] ? user[0].first_name : "";
                                mem.first_name = mem.userInfo.first_name;
                                mem.last_name = '';
                                mem.picture = mem.userInfo.file_url;
                                return mem;
                            });
                            obj.users = users;
                            array.push(obj);

                            break;
                        }
                    }
                }
            }
        }

        return array;
    };

    getUsersName = function (chat) {
        let res = '';
        let arr = [];
        if (Array.isArray(chat.users)) {
            chat.users.forEach((user) => {
                if(chat.createdBy === user.user_id){
                    if(!arr.includes(user.first_name)){
                        arr.unshift(user.first_name);
                    }
                } else {
                    if(!arr.includes(user.first_name)){
                        arr.push(user.first_name);
                    }
                }
            });
        }

        res = arr.join(', ');
        res = res.length >= 20 ? res.slice(0,20) + '...' : res;
        return res;
    };

    getPicture = function (chat) {
        let res = [];
        let users = chat.users.slice(0, 2);
        users.forEach((user) => {
            res.push(user.picture);
        });

        return res;
    };

    handleTopicScroll = (e) => {
        const { offset } = this.state;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if(bottom) {
            this.getDialogList(offset, false);
        }
    }

    handleClick = function (chat) {
        this.setState({ chatItem: chat });
    }

    handleSubmit = async function () {
        const {
            topicList,
            copyMessages,
            chatItem
        } = this.state;

        await this.setState({ loading: true });

        let result = await JoinChatGroup(chatItem.qb_dialogue_id);

        if (result) {
            for (let i = copyMessages.length - 1; i >= 0; i--) {
                let msg = copyMessages[i];
                if (msg.image) {
                    let response = await SendAttachment(chatItem.qb_dialogue_id, msg.image_id);
                } else if (msg.text) {
                    let response = await SendToGroup(chatItem.qb_dialogue_id, msg.text);
                } else if (msg.audio) {
                    let response = await SendAudio(chatItem.qb_dialogue_id, msg.audio_id);
                }
            }
        }

        this.setState({ loading: false });
        this.props.handleClose();
        this.props.changePage(chatItem.qb_dialogue_id);
    }

    render() {
        const {
            topicList,
            loading,
            chatItem,
            copyMessages
        } = this.state;


        return (
            <Modal
                title="Select Topic"
                centered
                maskClosable={false}
                className="chat-modal msg-action-modal"
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                 <LoadingOverlay
                    active={loading ? true : false}
                    className={'create-modal-loader'}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <div
                    className="content-wrap"
                    onScroll={this.handleTopicScroll}
                >
                {Array.isArray(topicList) && topicList.length > 0 ? (
                    topicList.map((chat, j) => (
                    <Card key={j} bordered={false} onClick={() => this.handleClick(chat)} className={ chatItem ? (chatItem.id === chat.id ? "chat-content-item selected-item" : "chat-content-item") : "chat-content-item"}>
                        <div className="card-left">
                            <div className="avatar-wrap">
                            <Avatar
                                size={30}
                                src={this.getPicture(chat)[1] ? this.getPicture(chat)[1] : user}
                            />
                            <Avatar
                                size={25}
                                src={this.getPicture(chat)[0] ? this.getPicture(chat)[0] : user}
                            />
                            </div>
                        </div>
                        <div className="card-right">
                            <div className="person-data">
                                <span>{chat.title}</span>
                                <span className="chat-time">{chat.last_message ? moment(new Date(chat.last_message.created_at)).fromNow() : null}</span>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between'}} className="msg-body">
                                    <span>{chat.last_message ? chat.last_message.text : null}</span>
                                <span className={chat.unread_count === 0 ? null : "chat-messages-count"}>
                                    {chat.unread_count === 0 ? null : chat.unread_count}
                                </span>
                            </div>
                        </div>
                    </Card>
                    ))
                    ) : (
                        <div className="create-new-chat-text">
                            <p>Create New Topic</p>
                        </div>
                    )}
                </div>
                <div className="msg-action-btn">
                    <Button
                        type="primary"
                        className="fp-button-primary send-btn"
                        onClick={() => chatItem ? this.handleSubmit() : null}
                    >
                        Send
                    </Button>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, loadings, coparent, status } = serviceReducer;
    return { user, error, children, loadings, coparent, status };
};

export default connect(mapStateToProps)(SelectTopicModal);