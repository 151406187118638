import React from 'react';
import PropTypes from 'prop-types';
import { getDate, getDayStart, getDayEnd } from '@wojtekmaj/date-utils';

import Tile from '../Tile';

import { isWeekend } from '../shared/dates';
import { formatLongDate as defaultFormatLongDate } from '../shared/dateFormatter';
import { tileProps } from '../shared/propTypes';

const className = 'react-calendar__month-view__days__day';

export default function Day({
  formatLongDate = defaultFormatLongDate,
  calendarType,
  classes,
  selected,
  forfeit_dates,
  trade_dates,
  currentMonthIndex,
  ...otherProps
}) {
  
  const formatDate = (date) => {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return month + '/' + day + '/' + year;  
  }


  const { date } = otherProps;

  let s = '';

  let className = 'react-calendar__month-view__days__day react-calendar__tile ';

  if(formatDate(selected) === formatDate(date)){
     s = 'set-date';
  }

  if(Array.isArray(forfeit_dates) && forfeit_dates.includes(formatDate(date))){
    s = 'tile-circle';
  }

  if(Array.isArray(trade_dates) && trade_dates.includes(formatDate(date))){
    s = 'trade-tile-circle';
  }

  className += s;

  return (
    <Tile
      {...otherProps}
      classes={[].concat(
        classes,
        className,
        isWeekend(date, calendarType) ? `${className}--weekend` : null,
        date.getMonth() !== currentMonthIndex ? `${className}--neighboringMonth` : null,
      )}
      formatAbbr={formatLongDate}
      maxDateTransform={getDayEnd}
      minDateTransform={getDayStart}
      view="month"
    >
      {getDate(date)}
    </Tile>
  );
}

Day.propTypes = {
  ...tileProps,
  currentMonthIndex: PropTypes.number.isRequired,
  formatLongDate: PropTypes.func,
};
