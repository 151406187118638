import { takeLatest } from 'redux-saga/effects';
import { registerSaga, loginSaga } from 'sagas/authenticationSaga';
import { 
    getMeSaga, 
    getChildrenSaga, 
    getCoParentsSaga, 
    getCoParentSaga,
    updateQBStatusSaga,
    getCheckList,
    updateSessionStatusSaga,
    updateLoginStatusSaga,
    getNotificationsSaga
} from 'sagas/userSaga';
import { getHouseHoldSaga,saveHouseHoldSaga, getThirdPartySaga } from 'sagas/houseHoldSaga';

import { loaderSaga } from 'sagas/loaderSaga';

import * as types from 'actions';
import { updateQBStatus } from 'actions/userActions';

export default function* watchUserAuthentication() {
    yield takeLatest(types.REGISTER_USER, registerSaga);
    yield takeLatest(types.LOGIN_USER, loginSaga);
}

export function* watchGetMe() {
    yield takeLatest(types.GET_USER, getMeSaga);
}

export function* watchCheckList() {
    yield takeLatest(types.GET_CHECK_LIST, getCheckList);
}

export function* watchLoader() {
    yield takeLatest(types.ADD_LOADING, loaderSaga);
    yield takeLatest(types.REMOVE_LOADING, loaderSaga);
    yield takeLatest(types.ADD_RELOADING, loaderSaga);
    yield takeLatest(types.REMOVE_RELOADING, loaderSaga);
    yield takeLatest(types.ADD_PERMISSION, loaderSaga);
    yield takeLatest(types.REMOVE_PERMISSION, loaderSaga);
}

export function* watchGetChildren() {
    yield takeLatest(types.GET_CHILDREN, getChildrenSaga);
}

export function* watchGetCoParents() {
    yield takeLatest(types.GET_COPARENTS, getCoParentsSaga);
}

export function* watchGetCoParent() {
    yield takeLatest(types.GET_COPARENT, getCoParentSaga);
}

export function* watchQBStatus() {
    yield takeLatest(types.QB_CONNECTION_STATUS, updateQBStatusSaga);
}

export function* watchGetHouseHold() {
    yield takeLatest(types.GET_HOUSEHOLD, getHouseHoldSaga);
    yield takeLatest(types.SAVE_HOUSEHOLD, saveHouseHoldSaga);
    
}

export function* watchGetThirdParty() {
    yield takeLatest(types.GET_THIRD_PARTY, getThirdPartySaga);
    
}

export function* watchSessionStatus() {
    yield takeLatest(types.SET_SESSION_STATUS, updateSessionStatusSaga);
}

export function* watchLoginStatus() {
    yield takeLatest(types.SET_LOGIN_STATUS, updateLoginStatusSaga);
}

export function* watchNotification() {
    yield takeLatest(types.GET_NOTIFICATION, getNotificationsSaga);
}