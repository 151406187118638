import React, { Component } from 'react';
import 'pages/Chat/ChatContainer/ChatContainer.scss';
import { GiftedChat } from 'react-web-gifted-chat';
import { Button, Modal, Input } from 'antd';
import Icon from '@ant-design/icons';

const { TextArea } = Input;

class ChatScreen extends Component {
    state = {
        messages: [],
    };
    state = {
        modal1Visible: false,
    };

    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }
    componentWillMount() {
        this.setState({
            messages: [
                {
                    id: 1,
                    text: 'Hi Sofia, How are you?',
                    createdAt: new Date(),
                    user: {
                        id: 2,
                        name: 'React',
                        avatar: 'https://source.unsplash.com/random',
                    },
                },
            ],
        });
    }

    onSend(messages = []) {
        this.setState((previousState) => ({
            messages: GiftedChat.append(previousState.messages, messages),
        }));
    }
    setModalVisible(modalVisible) {
        this.setState({ modalVisible });
    }
    renderToolbar = () => {
        return (
            <div className="toolbar-wrap">
                <TextArea rows={2} placeholder="Type a Message here..." />
                <span onClick={() => this.setModal1Visible(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18.002" viewBox="0 0 24 18.002">
                        <defs></defs>
                        <path
                            class="a"
                            d="M22.454,18H1.547A1.557,1.557,0,0,1,0,16.438V5.086A1.556,1.556,0,0,1,1.547,3.524h3.68a.868.868,0,0,0,.716-.379L7.654.618A1.779,1.779,0,0,1,8.9,0h6.2a1.774,1.774,0,0,1,1.245.618l1.7,2.512a.9.9,0,0,0,.738.393h3.665A1.556,1.556,0,0,1,24,5.086V16.438A1.557,1.557,0,0,1,22.454,18ZM12,5.088a5.479,5.479,0,1,0,5.419,5.478A5.455,5.455,0,0,0,12,5.088ZM18.582,4.7a.783.783,0,1,0,.774.783A.78.78,0,0,0,18.582,4.7ZM12,14.871a4.3,4.3,0,1,1,4.258-4.3A4.286,4.286,0,0,1,12,14.871ZM4.645,2.348h-3.1V1.566A.78.78,0,0,1,2.322.783h1.55a.779.779,0,0,1,.773.783v.782Z"
                            transform="translate(0 0)"
                        />
                    </svg>
                </span>
                <Button type="primary" className="fp-button-primary send-btn">
                    Send
                </Button>
            </div>
        );
    };
    render() {
        return (
            <div className="chat-screen-wrapper">
                <div className="chat-screen-head">
                    <span>Emma, Sofia</span>
                    {/* unarchived classname for grey icon */}
                    <span className="archived" onClick={() => this.setModalVisible(true)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <defs>
                                <clipPath id="a">
                                    <rect class="a" width="24" height="24" />
                                </clipPath>
                            </defs>
                            <g class="b">
                                <g transform="translate(0.999 1.733)">
                                    <path
                                        class="a"
                                        d="M21.117,1.2H.884A.885.885,0,0,0,0,2.084V6.334H22V2.084A.885.885,0,0,0,21.117,1.2Z"
                                        transform="translate(0 -1.2)"
                                    />
                                    <path
                                        class="c"
                                        d="M2.817,22.334a1.521,1.521,0,0,1-1.617-1.4V8.4H21V20.938a1.521,1.521,0,0,1-1.617,1.4ZM14.4,11.072H7.8v1.6a1.208,1.208,0,0,0,1.285,1.11h4.03a1.208,1.208,0,0,0,1.285-1.11Z"
                                        transform="translate(-0.1 -1.8)"
                                    />
                                </g>
                            </g>
                        </svg>
                    </span>
                </div>
                <div className="chat-wrap">
                    <GiftedChat
                        className="custom-chat"
                        messages={this.state.messages}
                        onSend={(messages) => this.onSend(messages)}
                        showUserAvatar={true}
                        showAvatarForEveryMessage={true}
                        alwaysShowSend={true}
                        send={true}
                        user={{
                            id: 1,
                        }}
                        renderInputToolbar={this.renderToolbar}
                    />
                </div>

                <Modal
                    title="Do you want to archive?"
                    centered
                    className="chat-modal"
                    visible={this.state.modalVisible}
                    onOk={() => this.setModalVisible(false)}
                    onCancel={() => this.setModalVisible(false)}
                >
                    <div className="new-topic-wrap">
                        <Button className="fp-button-secondary archive-btn">Archive</Button>
                        <Button className="fp-button-secondary archive-btn">Cancel</Button>
                    </div>
                </Modal>
                <Modal
                    title="Select file"
                    centered
                    className="chat-modal"
                    visible={this.state.modal1Visible}
                    onOk={() => this.setModal1Visible(false)}
                    onCancel={() => this.setModal1Visible(false)}
                >
                    <div className="new-topic-wrap">
                        <Button className="fp-button-secondary archive-btn">Open Camera</Button>

                        <div className="upload-wrapper">
                            <input id="img" type="file" multiple={true} encType="multipart/form-data" />
                            <Button className="fp-button-secondary archive-btn">Open Document</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default ChatScreen;
