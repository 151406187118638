import React, { Component } from 'react';
import 'pages/SubscriptionNew/Subscription.scss';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { browserHistory } from 'react-router';
import { Card, Button, Typography, Input, Tabs, Select } from 'antd';
import { connect } from 'react-redux';
import logo from 'Assets/kincrew-white.png';
import monthly from 'Assets/child-mother.svg';
import yearly from 'Assets/child-father.svg';
import check from 'Assets/check-round.svg';
import history from 'utils/history';
import { checkCookie } from 'utils/cookies';
import { getMeUserAction } from 'actions/userActions';
import {
    getSubscriptionPlan,
    getBillingAuthToken,
    cancelSubscription,
    getUserSubscription,
    activateSubscription,
    getActiveCards
} from '../../services/subscriptionService';
import LoadingOverlay from 'react-loading-overlay';
import SubscriptionErrorModal from 'pages/SubscriptionNew/SubscriptionError';
import { ADDONS } from 'pages/SubscriptionNew/constant';
import ConnectionErrorModal from 'Components/Modal/ConnectionModal';
import { ROUTES } from 'constants/routes';
import EditSubscription from 'pages/SubscriptionNew/EditSubscription';

const { Text } = Typography;
const { TabPane } = Tabs;

function callback(key) {}

const { Option } = Select;

let interval;

class Subscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LoadingIs: true,
            loading: false,
            plans: [],
            LoaderIs: true,
            isSubscribed: false,
            plan_code: '',
            prev_plan_code: '',
            modal1Visible: false,
            msg: '',
            status: '',
            modal2Visible: false,
            isCreated: false,
        };
        this.handleCancel = this.handleCancel.bind(this);
        this.handleActivate = this.handleActivate.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getSubscription = this.getSubscription.bind(this);
    }

    componentDidMount() {
        if (localStorage.third_party === 'true') {
            this.props.history.push('/home');
        }   
        if (window.navigator.onLine === false) {
            this.setState({ LoaderIs: false });
            this.setModal2Visible(true);
            window.stop();
        }
        this.getSubscription();
        history.listen((loc, action) => {
            if (action === 'POP') {
                // do stuff)
                this.props.history.push('/home');
            }
        });

        interval = setInterval(() => {
            this.getBillingDetails();
        }, 3000000);
    }

    
    getSubscription = () => {
        getUserSubscription()
            .then((resp) => {
                this.setState({ loading: false });
                localStorage.setItem('resp', JSON.stringify(resp.data));
                this.getBillingDetails();
            })
            .catch((err) => {
                this.setState({ loading: false, LoaderIs: false });
                this.setModal2Visible(true);
            });
    };

    handleCancel = () => {
        this.setState({ loading: true });
        cancelSubscription()
            .then((res) => {
                const msg = res.data ? res.data.message : 'Unble to cancel the subscription';
                this.setState({ loading: false, msg });
                this.setModal1Visible(true);
            })
            .catch((err) => {
                this.setState({ loading: false, LoaderIs: false });
                this.setModal2Visible(true);
            });
    };

    handleActivate = () => {
        this.setState({ loading: true });
        activateSubscription()
            .then((res) => {
                const msg = res.data ? res.data.message : 'Unble to reactivate the subscription';
                this.setState({ loading: false, msg });
                this.setModal1Visible(true);
            })
            .catch((err) => {
                this.setState({ loading: false, LoaderIs: false });
                this.setModal2Visible(true);
            });
    };

    handleClose = () => {
        this.setModal1Visible(false);
        this.props.history.push('/home');
    };

    setModal1Visible = (modal1Visible) => {
        this.setState({ modal1Visible });
    };

    setModal2Visible = (visible) => {
        this.setState({ modal2Visible: visible });
    };

    getBillingDetails = () => {
        if (localStorage.resp) {
            let userInfo = JSON.parse(localStorage.resp);
            
            if (userInfo && userInfo.subscription_id) {
                this.setState({ isCreated: true }); 
            } else {
                this.setState({ isCreated: false });
            }


            if (userInfo.status && userInfo.status != 'cancelled' && userInfo.subscription_id) {
                this.setState({ isSubscribed: true, plan_code: userInfo.plan_code, prev_plan_code: '' });
            } else if (userInfo.status) {
                this.setState({ status: userInfo.status, prev_plan_code: userInfo.plan_code });
            }
        }
        getBillingAuthToken()
            .then((res) => {
                localStorage.setItem('billingAccessToken', res.data.access_token);
                getSubscriptionPlan()
                    .then((res) => {
                        if (res.data.plans !== undefined) {
                            let defaultIndex = 1;
                            let plans = JSON.parse(JSON.stringify(this.state.plans));
                            plans = res.data.plans;

                            const { isSubscribed, plan_code, prev_plan_code } = this.state;
                            let temp_code = plan_code;
                            if (plan_code == '' && prev_plan_code != '') temp_code = prev_plan_code;

                            if ((plans && plans.length > 0 && isSubscribed) || (prev_plan_code != '' && temp_code)) {
                                defaultIndex = plans.findIndex((x) => x.plan_code == temp_code);
                                if (defaultIndex > -1) {
                                    plans = plans.map((item, index) => {
                                        const disabled = index !== defaultIndex;
                                        return { ...item, disabled };
                                    });
                                    defaultIndex = defaultIndex + 1;
                                } else {
                                    defaultIndex = 1;
                                }
                            }

                            this.setState({ plans, LoaderIs: false, defaultIndex: defaultIndex.toString() });
                        }
                    })
                    .catch((err) => {
                        this.setState({ LoaderIs: false });
                        this.setModal2Visible(true);
                    });
                
            })
            .catch((err) => {
                this.setState({ LoaderIs: false });
                this.setModal2Visible(true);
            });
    };

    checkPlan = (plan) => {
        const { plans } = this.state;
        let res = '';

        if(plan.interval == 1) {
            if(plan.interval_unit == 'months') {
                res = 'Monthly';
            } else if(plan.interval_unit == 'years') {
                res = 'Yearly'
            }
        } else if( plan.interval_unit == 'months') {
            res = plan.interval + ' Months'
        }
        
        // if (plan.recurring_price === 9.99) {
        //     res = 'Monthly';
        // } else if (plan.recurring_price === 29.99) {
        //     res = '3 Months';
        // } else if (plan.recurring_price === 49.99) {
        //     res = '6 Months'; 
        // } else if (plan.recurring_price === 79.99) {
        //     res = '9 Months';
        // } else if (plan.recurring_price === 99.99) {
        //     res = 'Yearly';
        // }

        return res;
    }


    render() {
        const {
            plans,
            LoaderIs,
            isSubscribed,
            loading,
            modal1Visible,
            msg,
            status,
            isCreated
        } = this.state;
        

        if (isCreated) {
            return (
                <EditSubscription />
            );
        } else {
            return (
                <div>
                    <div className="sub-wrapper">
                        <LoadingOverlay
                            active={loading || LoaderIs}
                            className={'loader-wrap subscription-loader' + (loading ? 'd-block' : '')}
                            spinner
                            styles={{
                                spinner: (base) => ({
                                    ...base,
                                    width: '100px',
                                    '& svg circle': {
                                        stroke: 'rgb(56, 160, 235)',
                                    },
                                }),
                            }}
                        ></LoadingOverlay>
                        <div className="logo">
                            <div className="logo-wrap">
                                <Link to="/home">
                                    <img src={logo} alt="kincrew-logo" />
                                    <span className="logo-item">kincrew</span>
                                </Link>
                            </div>
                        </div>
                        <Card bordered={false} className="sub-card req-card">
                            {LoaderIs ? null : (
                                <div className="sub-wrap">
                                    <React.Fragment>
                                        <div className="sub-card-head">
                                            <h1>Your Subscription</h1>
                                        </div>

                                        <Tabs defaultActiveKey={this.state.defaultIndex ? this.state.defaultIndex : '1'}>
                                            {undefined !== plans &&
                                                plans.length > 0 &&
                                                plans.map((plan, index) => (
                                                    <TabPane
                                                        tab={this.checkPlan(plan)}
                                                        key={index + 1}
                                                        disabled={plan.disabled}
                                                    >
                                                        <div className="tab-content">
                                                            <div className="img-wrap">
                                                                <img
                                                                    src={plan.interval_unit === 'months' ? monthly : yearly}
                                                                    alt="mom and girl"
                                                                />
                                                            </div>
                                                            <div className="amount">
                                                                <span>${plan.recurring_price}</span>
                                                                <span>{plan.name}</span>
                                                            </div>
                                                            <div className="contents">
                                                                <div className="contents-inner">
                                                                    {ADDONS.length > 0
                                                                        ? ADDONS.map((obj, index) => (
                                                                            <div className="item" key={index}>
                                                                                <span>{obj}</span>
                                                                                <span>
                                                                                    <img src={check} alt="check icon" />
                                                                                </span>
                                                                            </div>
                                                                        ))
                                                                        : ''}

                                                                    {!isSubscribed ? (
                                                                        <div className="btn-wrapper">
                                                                            {status == 'cancelled' ? (
                                                                                <Button
                                                                                    className="fp-button-primary "
                                                                                    onClick={this.handleActivate}
                                                                                >
                                                                                    Reactivate Subscription
                                                                                </Button>
                                                                            ) : (
                                                                                <Link
                                                                                    to={{
                                                                                        pathname: '/subscriptionDetails',
                                                                                        dataProps: {
                                                                                            plan_code: plan.plan_code,
                                                                                            plan_name: plan.name,
                                                                                            plan_description: plan.description,
                                                                                            quantity: '1',
                                                                                            price: plan.recurring_price,
                                                                                            billing_cycles: '-1',
                                                                                            trial_days: plan.trial_period,
                                                                                            setup_fee: plan.setup_fee,
                                                                                            tax_id: plan.tax_id,
                                                                                            setup_fee_tax_id: '',
                                                                                            interval_unit: plan.interval_unit,
                                                                                            interval : plan.interval,
                                                                                        },
                                                                                    }}
                                                                                >
                                                                                    <Button className="fp-button-primary">
                                                                                        Start Subscription
                                                                            </Button>
                                                                                </Link>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="btn-wrapper">
                                                                            <Button
                                                                                className="fp-button-primary "
                                                                                onClick={this.handleCancel}
                                                                            >
                                                                                Cancel Subscription
                                                                    </Button>
                                                                        </div>
                                                                    )}
                                                                    <Link to="/home">
                                                                        <div className="btn-wrapper">
                                                                            <Button className="fp-button-primary">
                                                                                Return To Home
                                                                    </Button>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TabPane>
                                                ))}

                                            {/* <TabPane tab="Yearly" key="2">
                                <div className="tab-content">
                                    <div className="img-wrap">
                                        <img src={yearly} alt="mom and girl" />
                                    </div>
                                    <div className="amount">
                                        <span>$99.99</span>
                                        <span>Billed monthly</span>
                                    </div>
                                    <div className="contents">
                                        <div className="contents-inner">
                                            <div className="item">
                                                <span>Shared Celendar</span>
                                                <span>
                                                    <img src={check} alt="check icon" />
                                                </span>
                                            </div>
                                            <div className="item">
                                                <span>Chat</span>
                                                <span>
                                                    <img src={check} alt="check icon" />
                                                </span>
                                            </div>
                                            <div className="item">
                                                <span>Payments</span>
                                                <span>
                                                    <img src={check} alt="check icon" />
                                                </span>
                                            </div>
                                            <div className="item">
                                                <span>Household</span>
                                                <span>
                                                    <img src={check} alt="check icon" />
                                                </span>
                                            </div>
                                            <div className="btn-wrapper">
                                                <Link to="/subscriptionDetails">
                                                    <Button className="fp-button-primary ">Start Subscription</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane> */}
                                        </Tabs>
                                    </React.Fragment>
                                    {/* )} */}
                                </div>
                            )}
                        </Card>
                    </div>
                    <SubscriptionErrorModal openModal={modal1Visible} closeModal={() => this.handleClose()} msg={msg} />
                    <ConnectionErrorModal
                        handleOpen={this.state.modal2Visible}
                        handleClose={() => this.setModal2Visible(false)}
                    />
                </div>
            );
        }
    }
}

const mapStateToProps = (response) => ({ response });

export default connect(mapStateToProps)(Subscription);
