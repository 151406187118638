import React, { Component, useState } from 'react';
import 'pages/Reports/PaymentTransaction/PaymentTransaction.scss';
import { Card, Button, Tabs, Modal, Select, DatePicker, Empty } from 'antd';
import { connect } from 'react-redux';
import moment, { duration } from 'moment';
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { SavePaymentReport, GetpaymentReportDetail, UpdatePaymentReport } from 'services/paymentReportsService';

class SaveReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responseData: this.props.responseInfo,
            type: '',
            LoadingIs: false,
        };
    }
    setModalVisible(modalVisible) {
        this.setState({ modalVisible });
    }
    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        const { responseInfo } = this.props;
        this.setState({responseData : ''})
        if (nextProps.handleOpen) {
            if (responseInfo.type == 'create') {
                this.setState({ responseData: this.props.responseInfo, type: responseInfo.type });
            } else {
                this.setState({ type: responseInfo.type });
                this.getActiveReports(responseInfo.data);
            }
        }

        // if(nextProps.handleOpen)
        //     this.setState ( {  responseData : this.props.responseInfo })
    };

    getActiveReports(id) {
        this.setState({ LoadingIs: true });
        GetpaymentReportDetail(id, (data) => {
            this.setState({ LoadingIs: false });
            let respData = data;
            respData.data.paymentsByCategory = respData.data.paymentData;
            respData.periodDuration = respData.data.periodDuration;
            respData.endDate = respData.data.endDate;
            respData.startDate = respData.data.startDate;
            respData.paymentData = respData.data.paymentsByCategory;
            respData.type = this.state.type;
            this.setState({ responseData: respData });
        });
    }

    formartData(date) {
        if (date) {
            var newdate = moment(date, "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'");
            return moment(newdate).format('MM/DD/YY');
        } else {
            return '';
        }
    }

    formartData1(date) {
        var newdate = moment(date, 'DD-MM-YYYY');
        return moment(newdate).format('MM/DD/YY');
    }

    formartData2(date) {
        if (date) {
            var newdate = moment(date, "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'");
            return moment(newdate).format('MM/DD');
        } else {
            return ' ';
        }
    }

    GetChilrenName(data) {
        let Childs = data.childrens.map((child) => child.name);
        return Childs.map((item) => item).join(',');
    }

    ConvertFormat(number) {
        if (!isNaN(number)) {
            return parseFloat(number).toFixed(2).toLocaleString();
        } else {
            return '';
        }
    }

    ShowData() {
        const { responseData } = this.state;
        let paymentData = responseData.data.paymentsByCategory;
        let user1 = responseData.data.user;

        if (!paymentData) {
            return;
        }

        let categories = Object.keys(paymentData);

        return categories.map((row, i) => {
            return <div key={i}>{this.CategoryComponent(paymentData[row], user1)}</div>;
        });
    }

    CategoryComponent = (data, user) => {
        if (data.isSpecial) {
            return (
                <div>
                    {this.HeaderComponent(data)}
                    {this.PaymentListData(data, user)}
                    {this.BottomComponent(data, user)}
                </div>
            );
        } else {
            return (
                <div>
                    {this.NotSpecialHeaderComponent(data)}
                    {this.NotSpecialPaymentListData(data, user)}
                    {this.BottomComponent(data, user)}
                </div>
            );
        }
    };

    HeaderComponent = (data) => {
        let rightText = '';
        let count = data.payments.length;
        if (count > 1) {
            rightText = count + 'Requests';
        } else {
            rightText = count + 'Request';
        }
        return (
            <div className="payment-head">
                <span>{data.name}</span>
                <span>{rightText}</span>
            </div>
        );
    };

    PaymentListData = (data, user) => {
        let payments = data.payments;
        return payments.map((row, i) => {
            return <div key={i}>{this.RowItem(row, user)}</div>;
        });
    };

    NotSpecialHeaderComponent = (data) => {
        let yourpercentage = '';
        let coparentpercentage = '';

        if (data.isUser) {
            yourpercentage = data.creatorPercentage;
            coparentpercentage = data.coparentPercentage;
        } else {
            yourpercentage = data.coparentPercentage;
            coparentpercentage = data.creatorPercentage;
        }
        return (
            <div className="payment-head">
                <span>{data.name}</span>
                <span>{yourpercentage + '%' + '|' + coparentpercentage + '%'}</span>
                <span>{'Fees'}</span>
            </div>
        );
    };

    NotSpecialPaymentListData = (data, user) => {
        let payments = data.payments;
        let Datalength = Object.keys(data.payments).length;
        return payments.map((row, index) => {
            return <div key={index}>{this.NotSpecialRowItem(row, user, index, Datalength)}</div>;
        });
    };

    NotSpecialRowItem = (data, user, index, length) => {
        let note = data.note;
        let yourAmount = '';
        let coparentAmount = '';
        let colorcode = '#70B99A';
        let addcharacter = '';

        if (note && note.length > 0) {
            note = ' - ' + note.trim();
        } else {
            note = '';
        }

        let desc = '';

        if (data.status == 'paid') {
            desc = ' Paid ';
        } else {
            desc = ' Owes ';
        }

        if (data.isUser) {
            if (data.status == 'paid') {
                addcharacter = '-';
            }

            colorcode = '#F06061';
            yourAmount = this.ConvertFormat(data.payerAmount);
            coparentAmount = this.ConvertFormat(data.senderAmount);

            desc = 'You' + desc + user.coparentName;
        } else {
            if (data.status == 'paid') {
                addcharacter = '+';
            }
            colorcode = '#70B99A';
            yourAmount = this.ConvertFormat(data.senderAmount);
            coparentAmount = this.ConvertFormat(data.payerAmount);
            desc = user.coparentName + desc;
        }


        return (
            <div className="pay-row">
                <div className="item item-top">
                    <span>{'Sent: ' + this.formartData2(data.sentDate)}</span>
                    <span style={{ color: colorcode }} className="name">
                        {desc}
                    </span>
                    <span className="amount ">
                        <span style={{ color: colorcode }}>{addcharacter + '$' + yourAmount}</span>|
                        <span style={{ color: '#58595B80' }}>{'$' + coparentAmount}</span>
                    </span>
                    <span className="">{data.fee ? '$' + this.ConvertFormat(data.fee) : ''}</span>
                    <span></span>
                </div>
                <div className="item item-bottom">
                    <span>{'Paid: ' + this.formartData2(data.paymentDate)}</span>
                    <span>{this.GetChilrenName(data) + note}</span>
                    <span>{'Total $' + this.ConvertFormat(data.fullAmount)}</span>
                    <span> {''}</span>
                </div>
            </div>
        );
    };

    BottomComponent = (data, user) => {
        return (
            <div className="total-row">
                <div className="total-head">
                    <span></span>
                    <span>Payer</span>
                    <span>Amount</span>
                    <span>Paid</span>
                    <span>Total Due</span>
                </div>

                {!data.isSpecial || (data.isSpecial && data.totalAmountCoparent > 0) ? (
                    <div className="total-row">
                        <span></span>
                        <span>{user.coparentName}</span>
                        <span> {'$' + this.ConvertFormat(data.totalAmountCoparent)}</span>
                        <span> {'$' + this.ConvertFormat(data.totalPaidCoparent)}</span>
                        <span style={{ color: '#70B99A' }}>
                            {' '}
                            {'+$' +
                                this.ConvertFormat(
                                    parseFloat(data.totalAmountCoparent) - parseFloat(data.totalPaidCoparent),
                                )}
                        </span>
                    </div>
                ) : null}

                {!data.isSpecial || (data.isSpecial && data.totalAmountYou > 0) ? (
                    <div className="total-row">
                        <span></span>
                        <span>{'You'}</span>
                        <span> {'$' + this.ConvertFormat(data.totalAmountYou)}</span>
                        <span> {'$' + this.ConvertFormat(data.totalPaidYou)}</span>
                        <span style={{ color: '#F06061' }}>
                            {' '}
                            {'-$' + this.ConvertFormat(parseFloat(data.totalAmountYou) - parseFloat(data.totalPaidYou))}
                        </span>
                    </div>
                ) : null}

                <div className="total-row">
                    <span></span>
                    <span>{'*Fees'}</span>
                    <span> {'$' + this.ConvertFormat(data.achFee)}</span>
                    <span>ACH</span>
                    <span>{''}</span>
                </div>
            </div>
        );
    };

    RowItem = (data, user) => {
        let colorcode = '#70B99A';
        let addcharacter = '';
        let astreix = '';

        if (data.isUser) {
            if (data.status == 'paid') {
                addcharacter = '-';
                astreix = '*';
            }

            if (data.coparentPercentage == 100) {
                colorcode = '#70B99A';
            } else {
                colorcode = '#F06061';
            }
        } else {
            if (data.status == 'paid') {
                addcharacter = '+';
            }
            if (data.coparentPercentage == 100) {
                colorcode = '#F06061';
            } else {
                colorcode = '#70B99A';
            }
        }

        return (
            <div className="pay-row">
                <div className="item">
                    <span>{'Sent: ' + this.formartData2(data.sentDate)}</span>
                    <span className="name">{data.isUser ? user.name : user.coparentName}</span>
                    <span className="amount" style={{ color: colorcode }}>
                        {addcharacter + '$' + this.ConvertFormat(data.fullAmount)} {astreix}
                    </span>
                </div>
                <div className="item">
                    <span>{'Due: ' + this.formartData2(data.dueDate)}</span>
                    <span>{this.GetChilrenName(data)}</span>
                    <span>{data.paymentDate ? this.formartData(data.paymentDate) : data.status}</span>
                </div>
            </div>
        );
    };

    SaveReport = async () => {
        const { responseData } = this.state;
        let type = responseData.type;
        this.setState({ LoadingIs: true });
        if (type == 'create') {
            let coparent_id = responseData.data.user ? responseData.data.user.coparent_id : '';

            responseData.data.coparent_id = coparent_id;
            responseData.data.child_ids = [];
            responseData.data.endDate = responseData.endDate;
            responseData.data.startDate = responseData.startDate;
            responseData.data.paymentData = responseData.data.paymentsByCategory;
            let response = await SavePaymentReport(responseData.data);
            this.setState({ LoadingIs: false });
            if (response) {
                this.props.handleAllClose();
            }
        } else {
            let data = {};
            data.id = responseData.data.id;
            data.archived = true;
            let response = await UpdatePaymentReport(data);
            this.setState({ LoadingIs: false });
            if (response) {
                this.props.handleRefreshClose();
            }
        }
    };

    render() {
        const { responseData, LoadingIs, handleOpen } = this.state;
        let keys = [];
        if (responseData && responseData.data && responseData.data.paymentsByCategory) {
            keys = Object.keys(responseData.data.paymentsByCategory);
        }

        return (
            <div>
                <Modal
                    title="Payments"
                    centered
                    className="report-data-modal pay-data"
                    maskClosable={false}
                    visible={this.props.handleOpen}
                    onCancel={this.props.handleClose}
                >
                    <LoadingOverlay
                        active={LoadingIs}
                        className={LoadingIs ? 'd-block pop-loader' : 'd-none'}
                        spinner
                        styles={{
                            spinner: (base) => ({
                                ...base,
                                width: '100px',
                                '& svg circle': {
                                    stroke: 'rgb(56, 160, 235)',
                                },
                            }),
                        }}
                    ></LoadingOverlay>
                    {responseData && (
                        <div>
                            <div className="modal-top">
                                <span>
                                    {' '}
                                    {'' +
                                        this.formartData1(responseData.startDate) +
                                        ' - ' +
                                        this.formartData1(responseData.endDate)}
                                </span>
                                <span>{responseData.periodDuration + ' Days'}</span>
                            </div>
                            <div className="modal-body">
                                <div className="payment-wrap">
                                    {keys.length == 0 ? (
                                        <div>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                    ) : (
                                        <div className="payment-item">{this.ShowData()}</div>
                                    )}
                                   
                                </div>
                            </div>
                            {responseData && responseData.type != 'archive' && keys.length > 0 && (
                                <div className="btn-save-wrap">
                                    <Button
                                        className="fp-button-secondary create-btn"
                                        onClick={() => this.SaveReport()}
                                    >
                                        {responseData && responseData.type == 'create'
                                            ? 'Save Report'
                                            : 'Archive Report'}
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparents, coparent } = serviceReducer;
    return { user, error, children, coparents, coparent };
};
export default connect(mapStateToProps)(SaveReport);
