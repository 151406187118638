import React, { Component } from 'react';
import 'pages/Landing/Calender/Calender.scss';
import { Card, Button } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import DatePicker from 'Components/Datepicker';
import {
    getDots,
    getEventDots,
    getSchedule
} from 'services/calendarService';
import {
    getPermission
} from 'services/memberService';
import { getChildrenUserAction, getCoParentsUserAction } from 'actions/userActions';
import { addLoading, removeLoading } from 'actions/loaderActions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import ScrollArea from 'react-scrollbar';
import { Link } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { getThirdPartyAction } from 'actions/houseHoldAction';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import moment from 'moment';

const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    gradient1: 'Gradient1',
    gradient2: 'Gradient2',
};

class Calender extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            dates: {},
            eventDates: {},
            month: 0,
            year: 0,
            events: [],
            arr: [],
            startDate: new Date(),
            coparent: null,
            flag: false,
            loading: false,
            childrenThirdParty : [],
        };
        this.getCalendarDots = this.getCalendarDots.bind(this);
        this.getCalendarEventDots = this.getCalendarEventDots.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.getDateArray = this.getDateArray.bind(this);
        this.getUserCoParents = this.getUserCoParents.bind(this);
        this.getAllChildren = this.getAllChildren.bind(this);
        this.getThirdPartyPermission = this.getThirdPartyPermission.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        const { startDate } = this.state;
        let endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);
        let ar = this.getDateArray(startDate, endDate);
        this.setState({ arr: ar });
        this.getCalendarDots(this.props.coparent);
        this.getCalendarEventDots();
        this.getEvents(this.props.coparent);
        this.getUserCoParents();
        this.getAllChildren();
        if (localStorage.third_party == 'true') {
            this.getThirdPartyPermission();
            if(localStorage.member_id )
            this.props.dispatch(getThirdPartyAction({ id : localStorage.member_id }));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.coparent !== prevProps.coparent) {
            this.getEvents(this.props.coparent);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.err !== null) {
            this.setState({ loading: false });
        }
        if (nextProps && nextProps.error !== null) {
            this.setState({ loading: false });
        }

        if (nextProps && nextProps.coparent !== null) {
            this.getCalendarDots(nextProps.coparent);
        } else if (nextProps && nextProps.coparent === null) {
            this.getCalendarDots(nextProps.coparent);
        }
        if (localStorage.third_party === 'true' && nextProps && nextProps.thirdPartyData) {
                this.setState({ childrenThirdParty: nextProps.thirdPartyData.kid });
        }
    }

    getThirdPartyPermission = async function(){
        let user_id = await localStorage.getItem('user_id');
        let household_id = await localStorage.getItem('household_id');

        let data = {
            user_id,
            household_id
        };

        getPermission(data)
            .then(resp => {
                if (resp && resp.success && resp.permission) {
                    localStorage.setItem('third_party_permission', JSON.stringify(resp.permission));
                    localStorage.setItem('calendar_permission', resp.permission.calendar);
                    localStorage.setItem('payment_permission', resp.permission.payment);
                    localStorage.setItem('chat_permission', resp.permission.chat);
            }
        })
    }

    formatDate = (date) => {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };

    getCalendarDots = function (coparent) {
        const sParams = {
            coparent_id: (coparent && coparent.id) || null,
        };
         if(localStorage.third_party === 'true') {
            sParams .coparent_id = localStorage.getItem("coparent_id");
            sParams.member_id = localStorage.getItem("member_id");
            sParams.third_party = true;
        }

        getDots(sParams).then((resp) => {
            if (resp && resp.data) {
                this.setState({ dates: resp.data });
            }
        }).catch(err => {})
    };

    getCalendarEventDots() {
         const params = {};
        if (localStorage.third_party === 'true') {
               params.member_id = localStorage.getItem("member_id");
               params.coparent_id = localStorage.getItem("coparent_id");
        }

        getEventDots(params).then((resp) => {
            if (resp && resp.data) {
                this.setState({ eventDates: resp.data });
            }
        }).catch(err => {})
    }

    getEvents = function (coparent) {
        if (this.props.err === null) {
            this.setState({ loading: true });
        }
        const { startDate } = this.state;
        let actual_start_date = startDate;
        let start_date = new Date(actual_start_date.getTime() - (24*60*60*1000));
        let end_date = new Date(start_date.getTime() + 6 * 24 * 60 * 60 * 1000);
    
        let ar = this.getDateArray(actual_start_date, end_date);
        this.setState({ arr: ar, coparent: coparent });
        start_date = this.formatDate(start_date);
        end_date = this.formatDate(end_date);
        const params = { type: 'event', start_date, end_date, coparent_id: (coparent && coparent.id) || null };
        if (localStorage.third_party === 'true') {
               params.member_id = localStorage.getItem("member_id");
               params.coparent_id = localStorage.getItem("coparent_id");
               params.third_party = true;
        }
        getSchedule(params).then((resp) => {
            if (resp) {
                this.setState({ events: resp, flag: true, loading: false });
            } else {
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({ loading: false });
        })
    };

    getDateArray = function (start, end) {
        var arr = [];
        var dt = new Date(start);
        while (dt <= end) {
            arr.push(new Date(dt));
            dt.setDate(dt.getDate() + 1);
        }
        return arr;
    };

    getUserCoParents() {
        this.props.dispatch(getCoParentsUserAction());
    }

    getAllChildren() {
        this.props.dispatch(getChildrenUserAction());
    }

    render() {
        const { dates, startDate, arr, events, eventDates, loading, flag, coparent, month, year ,childrenThirdParty} = this.state;
        const current_date = new Date();
        const { loadings, user, children,thirdPartyData } = this.props;
        let acceptedChild = []
        if (localStorage.third_party == 'true') {
            if(Array.isArray(childrenThirdParty)){
                 childrenThirdParty.map((child, i)=> {
                    acceptedChild.push(child.id)
                 })
            }

        } else {
            if(Array.isArray(children)){
                 children.map((child, i)=> {
                    acceptedChild.push(child.id)
                 })
            }
        }
        function getColor(item){
            if(localStorage.third_party == 'true') {
                return (item.created_by_user === (localStorage.coparent_id))?item.color: item.coparent_color;

            } else {
                return (item.created_by_user === (user && user.id))?item.color: item.coparent_color;
            }
            
        }

        return (
            <div className="loader-wrap">
                <Card bordered={false} className="calender-card">
                    <div className="card-head">
                        <span>
                            {month === 0 && year === 0 ? monthNames[current_date.getMonth()] : monthNames[month]}{' '}
                            {month === 0 && year === 0 ? current_date.getFullYear() : year}
                        </span>
                        <div className="btn-wrap">
                            {localStorage.third_party === 'true' && localStorage.calendar_permission === 'false' ? null : (
                                <Link to="/calendar">
                                    <Button className="fp-button-primary" type="primary" icon={<CalendarOutlined />}>
                                        <span>Go to Calenders</span>
                                    </Button>
                                </Link>
                            )}
                        </div>
                    </div>
                    <DatePicker
                        labelFormat={'MMMM'}
                        color={'#374e8c'}
                        selectDate={startDate}
                        dates={dates}
                        eventDates={eventDates}
                        getSelectedDay={(val) => {
                            this.setState({ startDate: val });
                            setTimeout(() => {
                                if (flag !== false) {
                                    this.getEvents(coparent);
                                }
                            }, 500);
                        }}
                        onScrolled={(val) => {
                            this.setState({ month: new Date(val).getMonth(), year: new Date(val).getFullYear() });
                        }}
                    />
                    <div className="card-body-wrap">
                        <ScrollArea
                        speed={0.8}
                        contentClassName="content-child"
                        horizontal={true}
                        vertical={false}
                        >
                            <div className="name-wrap">
                            {localStorage.third_party === 'true' ? (
                                Array.isArray(childrenThirdParty) &&
                                    childrenThirdParty.map((child, i) => (
                                        <div className="children-wrap" key={i}>
                                            {coparent === null ? (
                                                <div className={`circle ${colors[(child.created_by_user === localStorage.coparent_id)? child.color : child.coparent_color]}`}>
                                                    <span>{child.name[0]}</span>
                                                </div>
                                            ) : (child.coparent_user_id ? ((child.created_by_user === (user && user.id) && child.coparent_user_id === coparent.id) || (child.created_by_user === coparent.id && child.coparent_user_id === (user && user.id))) : ((child.created_by_user === (user && user.id) && child.Coparents[0].receiver_id ? child.Coparents[0].receiver_id === coparent.id : (child.coparent_standalone_id === coparent.id || coparent.invitationsIds.includes(child.coparent_standalone_id)) ))) ? (
                                                <div className={`circle ${colors[(child.created_by_user === localStorage.coparent_id )? child.color : child.coparent_color]}`}>
                                                    <span>{child.name[0]}</span>
                                                </div>
                                            ) : null}
                                        </div>
                                    ))

                            ) : (
                                Array.isArray(children) &&
                                    children.map((child, i) => (
                                        <div className="children-wrap" key={i}>
                                            {coparent === null ? (
                                                <div className={`circle ${colors[(child.created_by_user === (user && user.id))? child.color : child.coparent_color]}`}>
                                                    <span>{child.name[0]}</span>
                                                </div>
                                            ) : (child.coparent_user_id ? ((child.created_by_user === (user && user.id) && child.coparent_user_id === coparent.id) || (child.created_by_user === coparent.id && child.coparent_user_id === (user && user.id))) : ((child.created_by_user === (user && user.id) && child.Coparents[0].receiver_id ? child.Coparents[0].receiver_id === coparent.id : (child.coparent_standalone_id === coparent.id || coparent.invitationsIds.includes(child.coparent_standalone_id)) ))) ? (
                                                <div className={`circle ${colors[(child.created_by_user === (user && user.id))? child.color : child.coparent_color]}`}>
                                                    <span>{child.name[0]}</span>
                                                </div>
                                            ) : null}
                                        </div>
                                    ))
                            )}
                                
                            </div>
                        </ScrollArea>
                        <LoadingOverlay
                            active={loading ? true : false}
                            className="cal-loader"
                            spinner
                            styles={{
                                spinner: (base) => ({
                                    ...base,
                                    width: '100px',
                                    '& svg circle': {
                                        stroke: 'rgb(56, 160, 235)',
                                    },
                                }),
                            }}
                        >
                            <div className="list-wrapper">
                                <div className="list">
                                    {arr &&
                                        Array.isArray(arr) &&
                                        arr.map((date, i) => (
                                            <div
                                                key={i}
                                                className={
                                                    this.formatDate(startDate) === this.formatDate(date)
                                                        ? 'list-item selected'
                                                        : 'list-item'
                                                }
                                            >
                                                <div className="list-day">
                                                    <span>{date.getDate()}</span>
                                                    <span>{days[date.getDay()]}</span>
                                                </div>
                                                <div>
                                                    {events && Array.isArray(events) && events.length !== 0
                                                        ? events.map((event, index) => (
                                                              <div key={index} className="list-details">
                                                                  { moment.parseZone(date).format('YYYY-MM-DD') ===
                                                                    moment.parseZone((event.recurring_flag ? event.recurring_start_date : event.start_date)).format('YYYY-MM-DD') &&
                                                                  event.Members &&
                                                                  event.Members[0] && acceptedChild.indexOf(event.child_ids[0])  > -1 ? (
                                                                      <div className="list-wrap">
                                                                        <Link to="/calendar">  
                                                                            <div className="list-main">
                                                                              <div className="list-timer">
                                                                                  <span>{event.start_time}</span>
                                                                                  <div className="stat">
                                                                                      {event.Members &&
                                                                                          event.Members[0] && (
                                                                                              <span
                                                                                                  style={{
                                                                                                      background:(getColor(event.Members[0])),
                                                                                                  }}
                                                                                                   className={"list-info " + colors[getColor(event.Members[0])]}
                                                                                                  
                                                                                              ></span>
                                                                                          )}
                                                                                  </div>
                                                                              </div>
                                                                              <div className="list-activity">
                                                                                  <span>{event.title}</span>
                                                                              </div>
                                                                            </div>
                                                                        </Link>
                                                                      </div>
                                                                  ) : null}
                                                              </div>
                                                          ))
                                                        : null}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </LoadingOverlay>
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = ({ serviceReducer,  houseHoldReducer }) => {
    const { children, user, loadings, error, coparent } = serviceReducer;
    const { thirdPartyData } = houseHoldReducer;
    return { children, user, loadings, error, coparent,thirdPartyData };
};

export default connect(mapStateToProps)(Calender);
