import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { connect } from 'react-redux';
import { updatePayment } from 'services/paymentService';
import LoadingOverlay from 'react-loading-overlay';
import { addLoading, removeLoading } from 'actions/loaderActions';
import PreviewModal from 'pages/Payment/Payments/PreviewModal';
import DeleteAttachmentModal from 'pages/Payment/Payments/DeleteAttachmentModal';
import { getThirdPartyAction } from 'actions/houseHoldAction';
import { getUserById } from 'services/userService';

const { Option } = Select;
const { TextArea } = Input;

const paymentMethod = {
    'credit_card': 'Credit Card',
    'cash': 'Cash',
    'check': 'Check'
};


class RecentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            file: null,
            files: [],
            fileList: [],
            flag1: false,
            modalVisible: false,
            modal1Visible: false,
            index: '',
            isThirdParty: false,
            coParent: null
        };
        this.getFormattedDate = this.getFormattedDate.bind(this);
        this.getChildrenNames = this.getChildrenNames.bind(this);
        this.handleArchive = this.handleArchive.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.handleImageDelete = this.handleImageDelete.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
    }

    componentDidMount() {
        if (localStorage.third_party && localStorage.third_party === 'true') {
            this.props.dispatch(getThirdPartyAction({ id : localStorage.member_id }));
        }
    }

    UNSAFE_componentWillReceiveProps = async function(nextProps) {
        if (nextProps && nextProps.currentPayment) {
            let files = [];
            if (nextProps.currentPayment.attachment && nextProps.currentPayment.attachment.length !== 0) {
                nextProps.currentPayment.attachment.forEach((item) => {
                    let obj = {};
                    obj.name = item.name.length >= 10 ? item.name.slice(0, 10) + '....' : item.name;
                    obj.url = item.url;
                    files.push(obj);
                });
            }

            let normalUser = localStorage.third_party !== 'true';
            let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';

            if (normalUser) {
                await this.setState({ coParent: null });
            }

            if (thirdPartyUser) {
                getUserById(localStorage.coparent_id)
                    .then(async resp => {
                        if (resp) {
                            await this.setState({ coParent: resp });
                    }
                })    
            }

            this.setState({
                files: files,
                fileList: nextProps.currentPayment.attachment || [],
                notes: nextProps.currentPayment.notes,
                amount: nextProps.currentPayment.payer_amount,
                flag1: false,
                isThirdParty: thirdPartyUser
            });
        }
    }

    getFormattedDate = function (dateObj) {
        let month = dateObj.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = dateObj.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = dateObj.getFullYear();
        let output = month + '/' + day + '/' + parseInt(year.toString());
        return output;
    };

    getChildrenNames = function (child_ids) {
        let names = '';
        let normalUser = localStorage.third_party !== 'true';
        let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';
        const { children, thirdPartyData } = this.props;
        if (normalUser) {
            child_ids.forEach((child) => {
                for (let i = 0; i < children.length; i++) {
                    let c = children[i];
                    if (child == c.id) {
                        names = names + c.name;
                        names = names + ', ';
                    }
                }
            });
        } else {
            if (thirdPartyData) {
                thirdPartyData.kid.forEach(child => {
                    if (child_ids.includes(child.id)) {
                        names += child.name;
                        names += ', ';
                    }
                })
            }
        }
        names = names.trim();
        names = names.slice(0, names.length - 1);
        return names;
    };

    handleArchive = function () {
        this.setState({ loading: true });
        const { currentPayment } = this.props;
        const { fileList } = this.state;
        let options = {};
        if (currentPayment) {
            options = {
                notes: currentPayment.notes,
                reply_note: currentPayment.reply_note,
                full_amount: currentPayment.full_amount,
                confirm_code: currentPayment.confirm_code,
                archive: currentPayment.archive === true ? false : true,
                attachment: fileList,
            };

            updatePayment(currentPayment.id, options).then((resp) => {
                this.setState({ loading: false });
                this.props.handleClose();
                this.props.refresh();
                this.props.dispatch(addLoading());
            });
        }
    };

    setModalVisible = function (visible, file) {
        this.setState({ modalVisible: visible, file: file });
    };

    setModal1Visible = function (modalVisible,index) {
        this.setState({ modal1Visible: modalVisible, index:index});
    };

    handleImageDelete = async function (index) {
        this.setState({index:index})
        const { files, fileList } = this.state;
        let arr = [...files];
        let farr = [...fileList];

        arr.splice(this.state.index, 1);
        farr.splice(this.state.index, 1);

        await this.setState({ files: arr, fileList: farr });
    };

    render() {
        const { user, currentPayment } = this.props;
        const { loading, fileList, flag1, files, isThirdParty, coParent } = this.state;
        let isMePayer = currentPayment && user ? user.id === currentPayment.payer_id : false;
        let Notetitle,ReplyTitle,noteEditable,msgEditable,NoteValue,ReplyValue;
        let notes = currentPayment && currentPayment.notes;
        let replyNote = currentPayment && currentPayment.reply_note;

        let finalDate = '';
        if (currentPayment && user) {
        let date = currentPayment.approved_date;
            if (date) {
                let dateSplit = date.split('T');
                let formattedDate = dateSplit[0].split('-');
                finalDate =
                formattedDate[1] + '/' + formattedDate[2] + '/' + formattedDate[0];
            }
        }

        if (isMePayer == false) {
            Notetitle = 'Reply';
            NoteValue = replyNote
            ReplyValue = notes
            ReplyTitle = 'Note'

        } else if (isMePayer == true) {
            Notetitle = 'Note';
            NoteValue = notes
            ReplyValue = replyNote
            ReplyTitle = 'Reply'
        } 
        
        if (isMePayer == true && currentPayment && currentPayment.asking_reimbursing == false) {
            noteEditable = true
            msgEditable = false
            Notetitle = 'Note';
            ReplyTitle = 'Message'
        } else if (isMePayer == true && currentPayment && currentPayment.asking_reimbursing == true) {
            noteEditable = false
            msgEditable = true
            Notetitle = 'Message';
            ReplyTitle = 'Note'
        } else if (isMePayer == false && currentPayment && currentPayment.asking_reimbursing == true) {
            noteEditable = true
            msgEditable = false
            Notetitle = 'Note';
            ReplyTitle = 'Message'
        } else if (isMePayer == false && currentPayment && currentPayment.asking_reimbursing == false) {
            noteEditable = false
            msgEditable = true
            Notetitle = 'Message';
            ReplyTitle = 'Note'
        }
        
        let normalUser = localStorage.third_party !== 'true';
        let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';
        return (
            <Modal
                title="Payments Request"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                className="payments-popup custom-popup"
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={'loader-wrap pop-loader ' + (loading ? 'd-block' : '')}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <ScrollArea speed={0.8} horizontal={false}>
                    <Form>
                        <div className="form-scroll">
                            <div className="modal-top">
                                <Form.Item label="Co-parent" labelCol={{ span: 24 }}>
                                    <Input
                                        readOnly
                                        value={
                                            normalUser ? (
                                            currentPayment
                                                ? user && user.id === currentPayment.payer_id
                                                    ? currentPayment.receiver_username
                                                    : user && user.id === currentPayment.receiver_id
                                                    ? currentPayment.payer_username
                                                    : null
                                                    : null
                                            ) : (
                                                // currentPayment
                                                // ? coParent && coParent.id === currentPayment.payer_id
                                                //     ? currentPayment.receiver_username
                                                //     : coParent && coParent.id === currentPayment.receiver_id
                                                //     ? currentPayment.payer_username
                                                //     : null
                                                //     : null
                                                localStorage.getItem('coparent_name')    
                                            )
                                        }
                                    />
                                </Form.Item>
                                <Form.Item label="Kids" labelCol={{ span: 24 }}>
                                    <Input
                                        readOnly
                                        value={currentPayment ? this.getChildrenNames(currentPayment.child_ids) : null}
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item label="Category" labelCol={{ span: 24 }}>
                                <Input readOnly value={currentPayment ? currentPayment.PaymentCategory.name : null} />
                            </Form.Item>
                            <div>
                                {currentPayment ? (
                                    currentPayment.status !== 'disputed' && currentPayment.status !== 'declined' ? (
                                        <Form.Item label="Payment Method" labelCol={{ span: 24 }}>
                                            <Input
                                                readOnly
                                                value={
                                                    currentPayment
                                                        ? currentPayment.payment_method === 'Epayment'
                                                            ? 'ACH'
                                                            : paymentMethod[currentPayment.payment_method]
                                                        : null
                                                }
                                            />
                                        </Form.Item>
                                    ) : null
                                ) : null}
                            </div>
                            <Form.Item label={Notetitle} labelCol={{ span: 24 }}>
                                <TextArea readOnly value={currentPayment ? currentPayment.notes : null} />
                            </Form.Item>
                            <Form.Item label={ReplyTitle} labelCol={{ span: 24 }}>
                                <TextArea value={currentPayment ? currentPayment.reply_note : null} readOnly />
                            </Form.Item>

                            {currentPayment && currentPayment.status === 'Cancelled' && fileList.length !== 0 && (
                                <div style={{ marginBottom: flag1 ? '10px' : '10px' }} className="upload-file">
                                    <div className="modal-bottom">
                                        <span>Attachments</span>
                                        <div className="upload-wrapper">
                                            <input
                                                id="img"
                                                type={flag1 === false ? 'file' : 'button'}
                                                multiple={true}
                                                encType="multipart/form-data"
                                                onChange={this.handleUpload}
                                            />
                                        </div>
                                    </div>
                                    {Array.isArray(files) &&
                                        files.map((file, i) => (
                                            <div className="file-track" key={i}>
                                                <a onClick={() => this.setModalVisible(true, file.url)}>View</a>
                                                <span onClick={() =>this.setModal1Visible(true,i)}>Delete</span>
                                            </div>
                                        ))}
                                </div>
                            )}
                            {currentPayment &&
                            currentPayment.status === '?Cancelled' &&
                            Array.isArray(files) &&
                            files.length === 3 ? (
                                <span className="file-err">You can only select 3 attachments</span>
                            ) : null}

                            {currentPayment &&
                                currentPayment.status === 'paid' &&
                                currentPayment.payment_method !== 'paypal' &&
                                fileList.length !== 0 && (
                                    <div style={{ marginBottom: flag1 ? '10px' : '10px' }} className="upload-file">
                                        <div className="modal-bottom">
                                            <span>Attachments</span>
                                            <div className="upload-wrapper">
                                                <input
                                                    id="img"
                                                    type={flag1 === false ? 'file' : 'button'}
                                                    multiple={true}
                                                    encType="multipart/form-data"
                                                    onChange={this.handleUpload}
                                                />
                                            </div>
                                        </div>
                                        {Array.isArray(files) &&
                                            files.map((file, i) => (
                                                <div className="file-track" key={i}>
                                                    <span style={{ color: '#555' }}>{file.name}</span>
                                                    <a onClick={() => this.setModalVisible(true, file.url)}>View</a>
                                                </div>
                                            ))}
                                    </div>
                                )}
                            {/* {currentPayment &&
                            currentPayment.status === 'paid' &&
                            currentPayment.payment_method !== 'paypal' &&
                            Array.isArray(files) &&
                            files.length === 3 ? (
                                <span className="file-err">You can only select 3 attachments</span>
                            ) : null} */}

                            <div>
                            {currentPayment && currentPayment.payment_method == 'Epayment' && currentPayment.status !== 'pending' && (
                                <div className="details">
                                    <span>Sender: </span>
                                    <span>{currentPayment ? currentPayment.payer_username : null}</span>
                                </div>
                            )}     
                            </div>

                            <div>
                            {Boolean(isMePayer) && currentPayment.status != 'disputed' && currentPayment.status != 'declined' && (
                                <div className="transfer-details">
                                    <span>Transfer Amount</span>
                                    <span>{currentPayment ? '$' + currentPayment.payer_amount : null}</span>
                                </div>
                            )}    
                            </div>

                            <div>
                            {Boolean(isMePayer) && currentPayment.status != 'disputed' && currentPayment.status != 'declined' && (
                                <div className="transfer-details">
                                    <span>Transfer Fees</span>
                                    <span>
                                        {currentPayment
                                            ? currentPayment.fee
                                                ? '$' + currentPayment.fee
                                                : '$0.00'
                                            : null}
                                    </span>
                                </div>
                            )}
                            </div>

                            <div>
                                {currentPayment && user ? (
                                    (currentPayment.status === 'paid' || currentPayment.status === 'Cancelled') &&
                                    currentPayment.payment_method === 'Epayment' &&
                                    user.id === currentPayment.payer_id ? (
                                        <div className="line-special"></div>
                                    ) : null
                                ) : null}
                            </div>

                            <div>
                            {currentPayment && currentPayment.payment_method == 'Epayment' && currentPayment.status !== 'pending' && (
                                <div className="details">
                                    <span>Recipient: </span>
                                    <span>{currentPayment ? currentPayment.receiver_username : null}</span>
                                </div>
                            )}     
                            </div>

                           <div>
                           {currentPayment && currentPayment.payment_method == 'Epayment' && currentPayment.status !== 'pending' && (
                                <div className="recipient-date">
                                    <span>Payment Sent</span>
                                    <span>
                                        {finalDate}
                                    </span>
                                </div>
                            )}       
                            </div>

                            <div>
                                {currentPayment ? (
                                    (currentPayment.status === 'paid' || currentPayment.status === 'Cancelled') &&
                                    currentPayment.payment_method === 'Epayment' ? (
                                        <div className="line"></div>
                                    ) : null
                                ) : null}
                            </div>

                            <div>
                                {currentPayment ? (
                                    (currentPayment.status === 'paid' || currentPayment.status === 'Cancelled') &&
                                    currentPayment.payment_method === 'Epayment' ? (
                                        <div className="total-details">
                                            <span className="bold">Total to Recipient: </span>
                                            <span>{currentPayment ? '$' + currentPayment.payer_amount : null}</span>
                                        </div>
                                    ) : null
                                ) : null}
                            </div>

                            <div>
                            {Boolean((isMePayer && currentPayment && currentPayment.status === 'disputed') || !isMePayer || currentPayment && currentPayment.status === 'declined') && currentPayment && currentPayment.payment_method != 'Epayment' && (
                                        <Form.Item label="Amount" labelCol={{ span: 24 }} className="input-sm">
                                            <Input
                                                readOnly
                                                value={currentPayment ? currentPayment.payer_amount : null}
                                            />
                                        </Form.Item>
                            )}
                            </div>

                            {currentPayment && currentPayment.status === 'disputed' && fileList.length !== 0 && (
                                <div style={{ marginBottom: flag1 ? '10px' : '10px' }} className="upload-file">
                                    <div className="modal-bottom">
                                        <span>Attachments</span>
                                        <div className="upload-wrapper">
                                            <input
                                                id="img"
                                                type={flag1 === false ? 'file' : 'button'}
                                                multiple={true}
                                                encType="multipart/form-data"
                                                onChange={this.handleUpload}
                                            />
                                        </div>
                                    </div>
                                    {Array.isArray(files) &&
                                        files.map((file, i) => (
                                            <div className="file-track" key={i}>
                                                <a onClick={() => this.setModalVisible(true, file.url)}>View</a>
                                                <span onClick={() => this.handleImageDelete(i)}>Delete</span>
                                            </div>
                                        ))}
                                </div>
                            )}
                            {currentPayment &&
                            currentPayment.status === 'disputed' &&
                            Array.isArray(files) &&
                            files.length === 3 ? (
                                <span className="file-err">You can only select 3 attachments</span>
                            ) : null}

                            <div>
                            {Boolean(!isMePayer) && currentPayment &&  currentPayment.payment_method != 'Epayment' && ( 
                                <Form.Item className="input-sm input-code">
                                        <Input placeholder="Confirmation Code/Check#" readOnly={isThirdParty ? true : false} />
                                </Form.Item>
                            )} 
                            </div>

                            {currentPayment && currentPayment.status === 'declined' && fileList.length !== 0 && (
                                <div style={{ marginBottom: flag1 ? '10px' : '10px' }} className="upload-file">
                                    <div className="modal-bottom">
                                        <span>Attachments</span>
                                        <div className="upload-wrapper">
                                            <input
                                                id="img"
                                                type={flag1 === false ? 'file' : 'button'}
                                                multiple={true}
                                                encType="multipart/form-data"
                                                onChange={this.handleUpload}
                                            />
                                        </div>
                                    </div>
                                    {Array.isArray(files) &&
                                        files.map((file, i) => (
                                            <div className="file-track" key={i}>
                                                <a onClick={() => this.setModalVisible(true, file.url)}>View</a>
                                                <span onClick={() => this.handleImageDelete(i)}>Delete</span>
                                            </div>
                                        ))}
                                </div>
                            )}
                            {/* {currentPayment &&
                            currentPayment.status === 'declined' &&
                            Array.isArray(files) &&
                            files.length === 3 ? (
                                <span className="file-err">You can only select 3 attachments</span>
                            ) : null} */}

                            {currentPayment &&
                                currentPayment.status === 'paid' &&
                                currentPayment.payment_method === 'paypal' &&
                                fileList.length !== 0 && (
                                    <div style={{ marginBottom: flag1 ? '10px' : '10px' }} className="upload-file">
                                        <div className="modal-bottom">
                                            <span>Attachments</span>
                                            <div className="upload-wrapper">
                                                <input
                                                    id="img"
                                                    type={flag1 === false ? 'file' : 'button'}
                                                    multiple={true}
                                                    encType="multipart/form-data"
                                                    onChange={this.handleUpload}
                                                />
                                            </div>
                                        </div>
                                        {Array.isArray(files) &&
                                            files.map((file, i) => (
                                                <div className="file-track" key={i}>
                                                    <span style={{ color: '#555' }}>{file.name}</span>
                                                    <a onClick={() => this.setModalVisible(true, file.url)}>View</a>
                                                </div>
                                            ))}
                                    </div>
                                )}
                            {/* {currentPayment &&
                            currentPayment.status === 'paid' &&
                            currentPayment.payment_method === 'paypal' &&
                            Array.isArray(files) &&
                            files.length === 3 ? (
                                <span className="file-err">You can only select 3 attachments</span>
                            ) : null} */}

                            <div>
                                {currentPayment && user ? (
                                    currentPayment.status === 'disputed' ? (
                                        <div className="transfer-details">
                                            <span>Dispute reason: </span>
                                            <span className="dispute">
                                                {currentPayment ? currentPayment.dispute_reason : null}
                                            </span>
                                        </div>
                                    ) : null
                                ) : null}
                            </div>

                            <div>
                                {currentPayment ? (
                                    currentPayment.status === 'disputed' || currentPayment.status === 'declined' ? (
                                        <div className="dispute-text">
                                            <span>
                                                {currentPayment.status === 'disputed' ? 'Disputed.' : 'Declined.'}
                                            </span>
                                        </div>
                                    ) : null
                                ) : null}
                            </div>

                            <div>
                                {currentPayment ? (
                                    currentPayment.payment_method !== 'Epayment' &&
                                    (currentPayment.asking_reimbursing === true ||
                                        currentPayment.asking_reimbursing === false) &&
                                    currentPayment.status !== 'disputed' &&
                                    currentPayment.status !== 'declined' ? (
                                        <Form.Item>
                                            <div className="cust-text-area">
                                                <div className="text-inner">
                                                    <span>
                                                        <img src={check} alt="check-icon" />
                                                    </span>
                                                    <span>
                                                        Paid by {currentPayment ? currentPayment.payer_username : null}
                                                    </span>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    ) : null
                                ) : null}
                            </div>
                        </div>
                        {!isThirdParty && (
                            <div className="btn-wrap">
                                <Form.Item className="btn-form">
                                    <Button type="primary" onClick={this.handleArchive} className="btn fp-button-secondary">
                                        {currentPayment ? (currentPayment.archive ? 'UNARCHIVE' : 'ARCHIVE') : null}
                                    </Button>
                                </Form.Item>
                            </div>
                        )}
                    </Form>
                </ScrollArea>
                <PreviewModal
                    file={this.state.file}
                    handleOpen={this.state.modalVisible}
                    handleClose={() => this.setModalVisible(false)}
                />
                 <DeleteAttachmentModal
                    index={this.state.index}
                    openModal={this.state.modal1Visible}
                    closeModal={() => this.setModal1Visible(false)}
                    delete={() => this.handleImageDelete()}
                />
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer, houseHoldReducer }) => {
    const { user, error, children, coparent } = serviceReducer;
    const { thirdPartyData } = houseHoldReducer;
    return { user, error, children, coparent, thirdPartyData };
};

export default connect(mapStateToProps)(RecentModal);
