import React, { Component } from 'react';
import 'pages/CalenderInner/Custody/Custody.scss';
import { Card, Button, Modal, Form, Input, Select, Collapse } from 'antd';
import more from 'Assets/more.svg';
import { CalendarOutlined } from '@ant-design/icons';
import Calendar from 'pages/CalenderInner/Custody/react-calendar/src';
import { connect } from 'react-redux';
import SaveCustodyModal from 'pages/CalenderInner/Custody/SaveCustodyModal';
import DeleteCustodyModal from 'pages/CalenderInner/Custody/DeleteCustodyModal';
import { deleteCustody } from 'services/calendarService';
import LoadingOverlay from 'react-loading-overlay';
import moment, { duration } from 'moment';
import SwapErrorModal from 'pages/CalenderInner/Swap/SwapErrorModal';
import { addLoading, removeLoading } from 'actions/loaderActions';

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const status = {
    'approved' : 'Approved',
    'declined' : 'Declined'
};

class EditCustodyModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            title: '',
            child: '',
            start_date: new Date(),
            dates: [],
            markedDates: [],
            currentCustody: null,
            modalVisible: false,
            modal1Visible: false,
            modal2Visible: false,
            loading: false,
        };
        this.formateDate = this.formateDate.bind(this);
        this.getDates = this.getDates.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteCustody = this.handleDeleteCustody.bind(this);
    }

    componentDidMount() {

    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps && nextProps.handleOpen && nextProps.currentCustody) {
            
           let obj = this.getDates(nextProps.currentCustody.start_date, nextProps.currentCustody.pattern, nextProps.currentCustody.type, nextProps.currentCustody.duration);
           
            this.setState({
                title: nextProps.currentCustody.title,
                child: nextProps.currentCustody.Members[0].name,
                start_date: nextProps.currentCustody.start_date,
                dates: obj ? obj.dates : [],
                markedDates: obj ? obj.markedDates : [],
                currentCustody: nextProps.currentCustody,
            });
        }
    }

    getDates = function (date, pattern, type, duration) {
        let er = moment.parseZone(date).format('YYYY-MM-DD');
        let sd = moment(er).toDate();
        let endDate = new Date(sd.getFullYear() + 1, 12, 0);
        let obj = {};
        let arr = [];
        let darr = [];
        let items = [];

        if(type === 'irr') {
            while(sd <= endDate){
                arr.push(new Date(sd));
                sd.setDate(sd.getDate() + 1);
            }
            obj.dates = arr;
            obj.markedDates = darr;
            return obj;
        } else {
            if(pattern.toString() === '1'){
                while(sd <= endDate){
                    darr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                obj.dates = arr;
                obj.markedDates = darr;
                return obj;
            } else {
                let ar = pattern;
                for(let i = 0, num = 0;i < ar.length;){
                    if(ar[i] === '1'){
                        while(i < ar.length && ar[i] === '1'){
                            num++;
                            i++;
                            if(i >= ar.length)
                            break;
                        }
                        items.push(num+'-On');
                        num = 0;
                    } else if(ar[i] === '0'){
                        while(i < ar.length && ar[i] === '0'){
                            num++;
                            i++;
                            if(i >= ar.length)
                            break;
                        }
                        items.push(num+'-Off');
                        num = 0;
                    }  
                }
                
                while(sd <= endDate){
                    for(let i = 0;i < ar.length;i++){
                        if(ar[i] === '0'){
                            arr.push(new Date(sd));
                            sd.setDate(sd.getDate() + 1);
                        } else if(ar[i] === '1'){
                            darr.push(new Date(sd));
                            sd.setDate(sd.getDate() + 1);
                        }
                    }
                    if(type === 'alt') {
                        let pat = [];
                        for(let i = 0;i < pattern.length;i++){
                            if(pattern[i] === '0'){
                                pat.push('1');
                            }else if(pattern[i] === '1'){
                                pat.push('0');
                            }
                        }

                        for(let i = 0;i < pat.length;i++){
                            if(pat[i] === '0'){
                                arr.push(new Date(sd));
                                sd.setDate(sd.getDate() + 1);
                            } else if(pat[i] === '1'){
                                darr.push(new Date(sd));
                                sd.setDate(sd.getDate() + 1);
                            }
                        }
                    }
                }
                obj.dates = arr;
                obj.markedDates = darr;
                return obj;
            }
        }

    }

    setModalVisible = async function(visible){
        await this.setState({modalVisible: visible});
    }

    setModal1Visible = async function(visible){
        await this.setState({modal1Visible: visible});
    }

    setModal2Visible = async function (visible) {
        await this.setState({ modal2Visible: visible });
    }

    refreshPage = function() {
        this.props.handleClose();
        this.props.refresh();
    }

    formateDate = function(d){
        let date = new Date(d);
        let month = monthNames[date.getMonth()];
        let day = date.getDate();
        let year = date.getFullYear();
        
        return month + ' ' + day + ', ' + year;
    }

    handleDelete = function() {
        const { currentCustody } = this.state;
        this.setState({loading: true});
        deleteCustody(currentCustody.id)
        .then(resp => {
            this.setState({loading: false});
            this.refreshPage();
            this.props.dispatch(addLoading());
        })
    }

    handleDeleteCustody = function () {
        const { currentCustody } = this.state;
        const { user } = this.props;

        const canDelete =
        !!currentCustody &&
        !!user &&
        user.id === currentCustody.created_by_user &&
            moment(currentCustody.created_at).add(1, "day").isAfter();
        
        if (canDelete) {
            this.setModal1Visible(true);
        } else {
            this.setModal2Visible(true);
        }
    }

    render() {
        const { title, child, start_date, dates, loading, markedDates, currentCustody } = this.state;
        
        return (
            <Modal
                title="Custom Schedule Setup"
                className="cal-modal swap-modal sc-modal"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <div className="edit-wrap">
                    <div className="scroll-wrap enhance-height">
                            <div className="cal-wrap cust-cal-wrap">
                                <Calendar
                                markedDates={markedDates} 
                                highlightDates={dates}
                                />
                            </div>
                        <div className="schedule-wrap">
                            {currentCustody && currentCustody.status !== 'pending' && (
                            <div className="schedule-inner">
                                <span>Status:</span>
                                <span style={{color: currentCustody.status === 'approved' ? 'green' : 'red'}}>{status[currentCustody.status]}</span>
                            </div>
                            )}
                            <div className="schedule-inner">
                                <span>Custody:</span>
                                <span>{title}</span>
                            </div>
                            <div className="schedule-inner">
                                <span>Child:</span>
                                <span>{child}</span>
                            </div>
                            <div className="schedule-inner">
                                <span>Start Date:</span>
                                <span>{moment.parseZone(start_date).format('MMM DD, YYYY')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="btn-scroll-wrap">
                     
                        <Form.Item className="btn-top">
                            <Button onClick={this.handleDeleteCustody} className="red-btn full-btn" type="primary">
                                Remove Custody Schedule
                            </Button>
                        </Form.Item>
                       
                        {currentCustody && currentCustody.status !== 'declined' && (
                        <Form.Item className="btn-btm">
                            <Button onClick={() => this.setModalVisible(true)} className="fp-button-secondary full-btn" type="primary">
                                Edit Custody Schedule
                            </Button>
                        </Form.Item>
                        )}
                    </div>
                </div>
                <DeleteCustodyModal
                  delete={() => this.handleDelete()}
                  openModal={this.state.modal1Visible}
                  closeModal={() => this.setModal1Visible(false)}
                />
                <SaveCustodyModal
                  dates={dates}
                  markedDates={markedDates}
                  currentCustody={currentCustody}
                  refresh={() => this.refreshPage()}
                  handleOpen={this.state.modalVisible}
                  handleClose={() => this.setModalVisible(false)}
                />
                <SwapErrorModal
                    openModal={this.state.modal2Visible}
                    closeModal={() => this.setModal2Visible(false)}
                    msg={'You cannot delete this item because it was created more than 24 hrs ago'}
                />
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, loadings, error, coparent } = serviceReducer;
    return { children, user, loadings, error, coparent };
};

export default connect(mapStateToProps)(EditCustodyModal);