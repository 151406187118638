import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';

const { Option } = Select;
const { TextArea } = Input;

class SubscriptionErrorModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            msg: ''
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps && nextProps.openModal && nextProps.msg){
            this.setState({msg: nextProps.msg});
        }
    }

    render() {
        const { msg } = this.state;
        return (
            <Modal
            title={msg}
            centered
            maskClosable={false}
            visible={this.props.openModal}
            className="payments-popup custom-popup ach-error-popup sub-er-pop"
            onOk={this.props.closeModal}
            onCancel={this.props.closeModal}
            >
                <div className="display-btn">
                    <Button type="primary" style={{width: '80%'}} onClick={this.props.closeModal} className="btn fp-button-primary">
                        Ok
                    </Button>
                </div>
            </Modal>
        );
    }
}

export default SubscriptionErrorModal;
