import React, { Component } from 'react';
import 'pages/Chat/ChatContainer/ChatContainer.scss';
import { Card, Avatar, Button, Modal, Select, Input, Empty } from 'antd';
import Icon from '@ant-design/icons';
import FloatLabel from 'Components/FloatLabel/FloatLabel';
import { GetChatList, GetThirdPartyChatList } from 'services/chatService';
import { GetAllDialogs } from 'pages/QuickBlox/qbDialogs';
import { v4 as uuid } from 'uuid';
import NewGroupChat from './NewGroupChat';
import NewTopicChat from 'pages/Chat/ChatContainer/NewTopicChat';
import user from 'Assets/default.jpg';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { UnreadMessagesCount } from 'pages/QuickBlox/qbChat';
import { connect } from 'react-redux';
import { QuickBlockConfig } from 'pages/QuickBlox/QBConfig';
import {
    getQBCredentials,
    updateQBUserData,
    GetChatDetail
} from 'services/chatService';
import { updateQBStatus } from 'actions/userActions';
import {
    CreateUser, 
    LoginUser,
    ConnectChatServer,
    IsConnected,
    GetSession,
    CreateSession,
    CreateAppSession,
    GetUser
} from 'pages/QuickBlox/qbutility';
import * as QB from 'quickblox/quickblox';
import { checkCookie } from 'utils/cookies';
import { getHouseHoldAction, saveHouseHoldAction } from 'actions/houseHoldAction.js';
import {
    ChatHistory
} from 'pages/QuickBlox/qbChat';

const { Option } = Select;

const moreSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="3" height="15" viewBox="0 0 3 15">
        <defs></defs>
        <g transform="translate(-443.764 -22.685)">
            <path className="a" d="M0,1.5A1.5,1.5,0,1,1,1.5,3,1.5,1.5,0,0,1,0,1.5Z" transform="translate(443.764 22.685)" />
            <path className="a" d="M0,1.5A1.5,1.5,0,1,1,1.5,3,1.5,1.5,0,0,1,0,1.5Z" transform="translate(443.764 28.685)" />
            <path className="a" d="M0,1.5A1.5,1.5,0,1,1,1.5,3,1.5,1.5,0,0,1,0,1.5Z" transform="translate(443.764 34.685)" />
        </g>
    </svg>
);
const MoreIcon = () => <Icon component={moreSvg} />;


class ChatContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            modal1Visible: false,
            loading: false,
            topic: '',
            topicList: [],
            groupList: [],
            userid: '',
            offset1: 0,
            offset2: 0,
            limit: 15,
        };
        this.handleChange = this.handleChange.bind(this);
        this.getDialogList = this.getDialogList.bind(this);
        this.parseListData = this.parseListData.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.getUsersName = this.getUsersName.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.getPicture = this.getPicture.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleTopicScroll = this.handleTopicScroll.bind(this);
    }

    componentDidMount = async () => {

        if (checkCookie() !== null) {
            let token = checkCookie();
            this.props.dispatch(getHouseHoldAction());
        }

        if(this.props.value === "1"){
            if(QB.chat && QB.chat.isConnected){
                await this.setState({ loading: true, offset1: 0, offset2: 0, topicList: [], groupList: []});
                this.getDialogList(0, 0, true, 'both');
            } 
        }
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        console.log('Hi');
        if(nextProps && nextProps.refreshPage === false && nextProps.value === "1"){
            if(QB.chat && QB.chat.isConnected){
                this.setState({ loading: true,  offset1: 0, offset2: 0, topicList: [], groupList: []});
                this.getDialogList(0, 0, true, 'both');
            } 
        }
        if (nextProps && nextProps.status && nextProps.value === "1") {
            this.setState({ loading: true, offset1: 0, offset2: 0, topicList: [], groupList: []});
            this.getDialogList(0, 0, true, 'both');
            this.props.dispatch(updateQBStatus(false));
        }
    }


    getDialogList = async function(offset1, offset2, flag, title){
        const { limit, topicList, groupList } = this.state;
        await this.setState({ loading: true });
        let userid = await localStorage.getItem('user_id');
        let cp_id;
        let third_party = false;
        this.setState({ userid: userid });
        let list = await GetAllDialogs();
        if (!list) {
            this.setState({ loading: false });
        }
        if (list && list.items && list.items.length === 0) {
            this.setState({ loading: false });
        }
        if(localStorage.third_party == 'true') {
            cp_id = localStorage.coparent_id;
            third_party = true;
        }
        if (list && Array.isArray(list.items) && list.items.length > 0) {
            
            if(title === 'both') {
                    GetChatList(1, offset1, limit,cp_id,third_party).then(async (resp) => {
                        if (resp && resp.data.length > 0) {
                            await this.setState({ offset1: this.state.offset1 + 15 });

                            let topics = await this.parseListData(resp.data, list.items);

                            if (flag) {
                                let arr = [];
                                arr = [...arr, ...topics];
                                this.setState({ topicList: arr, loading: false});
                            } else {
                                let arr = [];
                                arr = [...topicList, ...topics];
                                this.setState({ topicList: arr, loading: false });
                            }
                        } else {
                            this.setState({ loading: false });
                        }
                    })
                    

                    GetChatList(2, offset2, limit,cp_id,third_party).then(async (resp) => {
                        if (resp && resp.data.length > 0) {

                            await this.setState({ offset2: this.state.offset2 + 15 });

                            let groups = await this.parseListData(resp.data, list.items);
                            if(flag) {
                                let arr = [];
                                arr = [...arr, ...groups];
                                this.setState({ groupList: arr, loading: false});
                            } else {
                                let arr = [];
                                arr = [...groupList, ...groups];
                                this.setState({ groupList: arr, loading: false });
                            }
                        } else {
                            this.setState({ loading: false });
                        }
                    });
            } else if (title === 'group') {
                GetChatList(2, offset2, limit,cp_id,third_party).then(async (resp) => {
                    if (resp && resp.data.length > 0) {
                        await this.setState({ offset2: this.state.offset2 + 15 });

                        let groups = await this.parseListData(resp.data, list.items);

                        if(flag) {
                            let arr = [];
                            arr = [...arr, ...groups];
                            this.setState({ groupList: arr, loading: false});
                        } else {
                            let arr = [];
                            arr = [...groupList, ...groups];
                            this.setState({ groupList: arr, loading: false });
                        }
                    } else {
                        this.setState({ loading: false });
                    }
                }); 
            } else if (title === 'topic') {
                GetChatList(1, offset1, limit,cp_id,third_party).then(async (resp) => {
                    if (resp && resp.data.length > 0) {
                        await this.setState({ offset1: this.state.offset1 + 15 });

                        let topics = await this.parseListData(resp.data, list.items);

                        if (flag) {
                            let arr = [];
                            arr = [...arr, ...topics];
                            this.setState({ topicList: arr, loading: false});
                        } else {
                            let arr = [];
                            arr = [...topicList, ...topics];
                            this.setState({ topicList: arr, loading: false });
                        }
                    } else {
                        this.setState({ loading: false });
                    }
                }) 
            }
        }
        
    };

    parseListData = async (fpList, qbList) => {
        let array = [];
        for (const dialoglist of fpList) {
            for (const data of qbList) {
                if (data._id == dialoglist.qb_dialogue_id) {
                    if (localStorage.third_party !== 'true') {
                        let obj = {};
                        obj.type = dialoglist.Dialogues[0].type;
                        obj.id = data._id;
                        obj.fp_dialogid = dialoglist.dialogue_id;

                        obj.unread_count = data.unread_messages_count;
                        obj.qb_dialogue_id = dialoglist.qb_dialogue_id;

                        let messageObj = {};

                        if (dialoglist.is_archived) {
                            let messageDate = Math.round(new Date(data.updated_at).getTime() / 1000);
                            var archived = dialoglist.archived_at;
                            let archivedTimestamp = Math.round(new Date(archived).getTime() / 1000);
                            if (messageDate > archivedTimestamp) {
                                messageObj.id = uuid();
                                messageObj.author = data.last_message_user_id;
                                messageObj.text = data.last_message;
                                messageObj.created_at = data.updated_at;
                                messageObj.unread = true;
                                obj.last_message = messageObj;
                            }
                        } else {
                            messageObj.id = uuid();
                            messageObj.author = data.last_message_user_id;
                            messageObj.text = data.last_message;
                            messageObj.created_at = data.updated_at;
                            messageObj.unread = true;
                            obj.last_message = messageObj;
                        }

                        obj.title = dialoglist.Dialogues[0].name;
                        obj.is_archived = dialoglist.is_archived;
                        obj.archived_at = dialoglist.archived_at;
                        obj.createdBy = dialoglist.Dialogues[0].create_by;
                        obj.chat_created_at = dialoglist.created_at;
                        let members = dialoglist.Dialogues[0].DialogueMembers;

                        let users = await members.map((mem) => {
                            // let user = this.state.coparents.filter((x) => {
                            //   return x.id == mem.user_id;
                            // });

                            // mem.first_name = user[0] ? user[0].first_name : "";
                            mem.first_name = mem.userInfo.first_name;
                            mem.last_name = '';
                            mem.picture = mem.userInfo.file_url;
                            return mem;
                        });
                        obj.users = users;
                        array.push(obj);

                        break;
                    } else if (localStorage.third_party === 'true') {
                        if (dialoglist.Dialogues[0]) {
                            let obj = {};
                            obj.type = dialoglist.Dialogues[0].type;
                            obj.id = data._id;
                            obj.fp_dialogid = dialoglist.dialogue_id;

                            obj.unread_count = data.unread_messages_count;
                            obj.qb_dialogue_id = dialoglist.qb_dialogue_id;

                            let messageObj = {};

                            if (dialoglist.is_archived) {
                                let messageDate = Math.round(new Date(data.updated_at).getTime() / 1000);
                                var archived = dialoglist.archived_at;
                                let archivedTimestamp = Math.round(new Date(archived).getTime() / 1000);
                                if (messageDate > archivedTimestamp) {
                                    messageObj.id = uuid();
                                    messageObj.author = data.last_message_user_id;
                                    messageObj.text = data.last_message;
                                    messageObj.created_at = data.updated_at;
                                    messageObj.unread = true;
                                    obj.last_message = messageObj;
                                }
                            } else {
                                messageObj.id = uuid();
                                messageObj.author = data.last_message_user_id;
                                messageObj.text = data.last_message;
                                messageObj.created_at = data.updated_at;
                                messageObj.unread = true;
                                obj.last_message = messageObj;
                            }

                            obj.title = dialoglist.Dialogues[0].name;

                            obj.is_archived = dialoglist.is_archived;
                            obj.archived_at = dialoglist.archived_at;
                            obj.createdBy = dialoglist.Dialogues[0].create_by;
                            let members = dialoglist.Dialogues[0].DialogueMembers;

                            let users = await members.map((mem) => {
                                // let user = this.state.coparents.filter((x) => {
                                //   return x.id == mem.user_id;
                                // });

                                // mem.first_name = user[0] ? user[0].first_name : "";
                                mem.first_name = mem.userInfo.first_name;
                                mem.last_name = '';
                                mem.picture = mem.userInfo.file_url;
                                return mem;
                            });
                            obj.users = users;
                            array.push(obj);

                            break;
                        }
                    }
                }
            }
        }

        return array;
    };

    CallChatDetailApi = async (dialogId) => {
        return new Promise(resolve => {
            GetChatDetail(dialogId).then(async (resp) => {
                if (resp && resp.data) {
                    let dialogdata = resp.data.Dialogues;
                    var archivedtime = resp.data.archived_at;
                    let archivedTimestamp = Math.round(new Date(archivedtime).getTime() / 1000);
                    let archived = resp.data.is_archived;
                    let archived_at = archivedTimestamp;
                    resolve(archived_at);
                }

            });
        });
    };

    getUsersName = function (chat) {
        let res = '';
        let arr = [];
        if (Array.isArray(chat.users)) {
            chat.users.forEach((user) => {
                if(chat.createdBy === user.user_id){
                    if(!arr.includes(user.first_name)){
                        arr.unshift(user.first_name);
                    }
                } else {
                    if(!arr.includes(user.first_name)){
                        arr.push(user.first_name);
                    }
                }
            });
        }

        res = arr.join(', ');
        res = res.length >= 20 ? res.slice(0,20) + '...' : res;
        return res;
    };

    refreshPage = async function () {
        await this.setState({ offset1: 0, offset2: 0, topicList: [], groupList: []});
        this.getDialogList(0, 0, true, 'both');
    };

    setModalVisible = async function (visible) {
        await this.setState({ modalVisible: visible });
    };

    setModal1Visible = async function (visible) {
        await this.setState({ modal1Visible: visible });
    };

    handleChange = function (e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    getPicture = function (chat) {
        let res = [];
        let users = chat.users.slice(0, 2);
        users.forEach((user) => {
            res.push(user.picture);
        });

        return res;
    };

    handleClick = function(chat){
        this.props.show(chat.qb_dialogue_id,chat.chat_created_at);
    }

    handleScroll = function(e) {
        const { offset1, offset2 } = this.state;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if(bottom) {
            this.getDialogList(offset1, offset2, false, 'group');
        }
    }

    handleTopicScroll = (e) => {
        const { offset1, offset2 } = this.state;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if(bottom) {
            this.getDialogList(offset1, offset2, false, 'topic');
        }
    }

    render() {
        const { topicList, groupList, offset1, offset2, loading } = this.state;
        const { status } = this.props;
        
        return (
            <LoadingOverlay
                active={loading ? true : false}
                className={'loader-wrap chat-loader-overlay ' + (loading ? 'd-block' : '')}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '100px',
                        '& svg circle': {
                            stroke: 'rgb(56, 160, 235)',
                        },
                    }),
                }}
            >
                <div className="chat-content-wrap">
                    <div className="new-chat-wrapper">
                        <div className="chat-head">
                            <span>New Chat</span>
                           
                                <Button className="fp-button-secondary add-btn" onClick={() => this.setModal1Visible(true)}>
                                    +
                                </Button>
                           
                        </div>
                        <div 
                        className="chat-content"
                        onScroll={this.handleScroll} 
                        >
                            {Array.isArray(groupList) && groupList.length > 0 ? (
                                groupList.map((chat, i) => (
                                    <Card key={i} bordered={false} onClick={() => this.handleClick(chat)} className="chat-content-item">
                                        <div className="card-left">
                                            <div className="avatar-wrap">
                                                <Avatar
                                                    size={30}
                                                    src={this.getPicture(chat)[1] ? this.getPicture(chat)[1] : user}
                                                />
                                                <Avatar
                                                    size={25}
                                                    src={this.getPicture(chat)[0] ? this.getPicture(chat)[0] : user}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-right">
                                            <div className="person-data">
                                                <span>{this.getUsersName(chat)}</span>
                                                <span>{chat.last_message ? moment(new Date(chat.last_message.created_at)).fromNow() : null}</span>
                                            </div>
                                            <div
                                            style={{display: 'flex', justifyContent: 'space-between'}} 
                                            className="msg-body"
                                            >
                                                <span style={{width: '80%'}}>{chat.last_message ? chat.last_message.text: " "}</span>
                                                <span className={chat.unread_count === 0 ? null : "chat-messages-count"}>
                                                    {chat.unread_count === 0 ? null : chat.unread_count > 10 ? '10+' : chat.unread_count}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="card-end">
                                            {/* <MoreIcon /> */}
                                        </div>
                                    </Card>
                                ))
                            ) : (
                                <div className="create-new-chat-text">
                                   
                                        <p>Create new Chat</p>
                                    
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="new-topic-wrapper">
                        <div className="chat-head">
                            <span>New Topic</span>
                            
                                <Button className="fp-button-secondary add-btn" onClick={() =>  this.setModalVisible(true)}>
                                    +
                                </Button>
                           
                        </div>
                        <div 
                        className="chat-content"
                        onScroll={this.handleTopicScroll}
                        >
                            {Array.isArray(topicList) && topicList.length > 0 ? (
                                topicList.map((chat, j) => (
                                    <Card key={j} bordered={false} onClick={() => this.handleClick(chat)} className="chat-content-item">
                                        <div className="card-left">
                                            <div className="avatar-wrap">
                                                <Avatar
                                                    size={30}
                                                    src={this.getPicture(chat)[1] ? this.getPicture(chat)[1] : user}
                                                />
                                                <Avatar
                                                    size={25}
                                                    src={this.getPicture(chat)[0] ? this.getPicture(chat)[0] : user}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-right">
                                            <div className="person-data">
                                                <span>{chat.title}</span>
                                                <span>{chat.last_message ? moment(new Date(chat.last_message.created_at)).fromNow(): null}</span>
                                            </div>
                                            <div style={{display: 'flex', justifyContent: 'space-between'}} className="msg-body">
                                                <span style={{width: '80%'}}>{chat.last_message ? chat.last_message.text: " "}</span>
                                                <span className={chat.unread_count === 0 ? null : "chat-messages-count"}>
                                                    {chat.unread_count === 0 ? null : chat.unread_count > 10 ? '10+' : chat.unread_count}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="card-end">
                                            {/* <MoreIcon /> */}
                                        </div>
                                    </Card>
                                ))
                            ) : (
                                <div className="create-new-chat-text">
                                
                                    <p>Create New Topic</p>
                                
                                </div>
                            )}
                        </div>
                    </div>
                    <NewTopicChat
                        handleOpen={this.state.modalVisible}
                        handleClose={() => this.setModalVisible(false)}
                        refresh={() => this.refreshPage()}
                    />
                    <NewGroupChat
                        handleOpen={this.state.modal1Visible}
                        handleClose={() => this.setModal1Visible(false)}
                        refresh={() => this.refreshPage()}
                    />
                </div>
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, loadings,  status } = serviceReducer;
    return { user, error, children, loadings,  status };
};
export default connect(mapStateToProps)(ChatContent);
