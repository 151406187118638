import React, { Component } from 'react';
import 'pages/Signin/SignupTrial.scss';
import cover from 'Assets/cover.png';
import logo from 'Assets/kincrew-logo.svg';
import arrowWhite from 'Assets/arrow-right.svg';
import flag from 'Assets/flag.png';
import arrowDark from 'Assets/arrow-right-dark.png';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';

class SignupTrial extends Component {
    render() {
        return (
            <div className="signin-wrapper">
                <div className="signin-cover">{/* <img src={cover} alt="kincrew-cover-image" /> */}</div>
                <div className="signin-details">
                    <div className="details-inner">
                        <div className="details-head">
                            <div className="logo-wrap">
                                <img src={logo} alt="kincrew-logo" />
                                <span>kincrew</span>
                            </div>
                            <div className="btn-wrap">
                                <Link to="/signup">
                                    <Button type="primary" className="btn-sign-in">
                                        Login
                                        <span className="arrow-wrap">
                                            <img src={arrowDark} alt="arrow-right" />
                                        </span>
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <div className="data-wrap">
                            <div className="sign-head">
                                <span>Try Kincrew for 14 days on us</span>
                            </div>
                            <div className="signin-form-wrap trial-wrap">
                                <Form>
                                    <Form.Item label="Email" labelCol={{ span: 24 }}>
                                        <Input placeholder="mom.parent@gmail.com" />
                                    </Form.Item>
                                    <Form.Item label="Phone Number" className="phone-form" labelCol={{ span: 24 }}>
                                        <Input placeholder="415-555-1705" />
                                        <div className="code-wrapper">
                                            <span>
                                                <img src={flag} alt="flag" />
                                            </span>
                                            <span className="code">+1</span>
                                        </div>
                                    </Form.Item>
                                    <div className="btn-wrap signup-btn">
                                        <Button type="primary" className="btn-full ">
                                            <span>Start Your Trial</span>
                                            <span>
                                                <img src={arrowWhite} alt="arrow-right" />
                                            </span>
                                        </Button>
                                    </div>
                                </Form>
                                <div className="form-bottom">
                                    <div>
                                        By clicking on Start Your Trial, you agree to our <Link to="#">Terms</Link> and
                                        you acknowledge having read our <Link to="#">Privacy Policy</Link>.
                                    </div>
                                    <div>
                                        *This includes periodic newsletters, emails about offers and app usage tips, and
                                        other communications. You can opt out anytime.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SignupTrial;
