import React, { Component } from 'react';
import * as QB from 'quickblox/quickblox';
import { Button, Modal, Form, Input, Select, Checkbox, TimePicker, DatePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { GetChatDetail } from 'services/chatService';
import { PdfDocument } from "./ChatDocument";
import {
    ChatHistory,
    JoinChatGroup,
    SendToGroup,
    MarkMessageRead,
    UploadAttachment,
    SendAttachment,
} from 'pages/QuickBlox/qbChat';

import { checkCookie } from 'utils/cookies';


const { Option } = Select;
function onChange(time, timeString) {}

const dateDropwnData = [
    { id: 1, name: '1 Day' },
    { id: 2, name: '1 Week' },
    { id: 3, name: '1 Month' },
    { id: 4, name: 'Custom' },
];

const archDateDropwnData = [
    { id: 4, name: 'Custom' },
];

var d = new Date();
var utc = d.getTime();


class ChatExportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingIs: false,
            exportChatVisible : false,
            selectedDate: null,
            startdate: new Date(utc),
            enddate: new Date(utc),
            show: false,
            noData : false,
            archivedSt : false,
            archived_at_date : '',
            archivedTimeStamp : '',
            msg : '',
            errors: {
                selectedDate: '',
                startdate: '',
                enddate: '',
            },
            
        };
     this.refreshData = this.refreshData.bind(this);
    }

    componentDidMount() {
      
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
      // console.log('heree',nextProps)
       if(nextProps.exportChatVisible) {
            let userId = await localStorage.getItem('user_id');
            GetChatDetail(this.props.dialogId).then(async (resp) => {
                if (resp && resp.data) {
                    var archivedtime = resp.data.archived_at;
                    let archivedTimestamp = Math.round(new Date(archivedtime).getTime() / 1000);

                    let archived = resp.data.is_archived;
                    let archived_at = archivedTimestamp;

                    this.setState({archived_at_date : resp.data.archived_at,archivedSt : archived,archivedTimeStamp : archived_at})
                    if(this.props.IsFromArchived) {
                        this.setState({startdate : new Date(resp.data.archived_at), enddate : new Date(resp.data.archived_at)})
                    }

                }
            })
       }
    };
   
    exportChatVisible = (visible) => {
        this.setState({ exportChatVisible: visible });
    };

    handleEndField = async (e) => {
        this.setState({show: false,
                        msg : '',
                        noData : false})
        if (e && e._d) {
            await this.setState({ enddate: this.formatDate(e._d) });
           
        }
    };

    handleStartField = async (e) => {
         this.setState({show: false,
                        msg : '',
                        noData : false})
         // if(this.formatDate(e._d) < this.formatDate(new Date(this.props.chatCreatedDate))){

         // } 

             if (e && e._d) {
                await this.setState({ startdate: this.formatDate(e._d) });
           
                
         }

       
    };

    formatDate = function (dateObj) {
        let date = new Date(dateObj);
        let month = date.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = date.getFullYear();
        let output = parseInt(year.toString()) + '-' + month + '-' + day;
        return output;
    };

    handleDate = async function (value) {
         this.setState({exportChatVisible : false,
                        selectedDate: null,
                        startdate: new Date(utc),
                        enddate: new Date(utc),
                        show: false,
                        msg : '',
                        noData : false,
                        errors: {
                        selectedDate: '',
                        startdate: '',
                        enddate: '',
                    },
                    });
        let start = null;
        let end = null;

        if (value == 1) {
            start = new Date(moment().subtract(1, 'days'));
            end = new Date(new Date());
        } else if (value == 2) {
            start = new Date(moment().subtract(7, 'days'));
            end = new Date(new Date());
        } else if (value == 3) {
            start = new Date(moment().subtract(1, 'month'));
            end = new Date(new Date());
        } else if (value == 4) {
            start = new Date(new Date());
            end = new Date(new Date());
        }
        if(this.props.IsFromArchived) {
            end =  new Date(this.state.archived_at_date);
            start = new Date(this.state.archived_at_date);
        }

        this.setState({
            selectedDate: value,
            startdate: start,
            enddate: end,
        });
    };

    getName = function (val) {
        let res = '';
        dateDropwnData.forEach((item) => {
            if (item.id === val) {
                res = item.name;
            }
        });
        return res;
    };
 
    disabledDate = (current) => {

        const {archivedSt, archived_at_date ,archivedTimeStamp} = this.state;
        let st;
        let chat_dt;
        if(this.props.IsFromArchived) {
            st = moment(archived_at_date).format('YYYY-MM-DD')
            st = st+'T12:00:00.066Z'; //timestamp
            chat_dt =  moment(this.props.chatCreatedDate).format('YYYY-MM-DD');
            chat_dt = chat_dt+'T12:00:00.066Z'; //timestamp
            return (current && current.valueOf() > moment(st).valueOf());
            
        } else {
           // console.log('this.props.chatCreatedDate',this.props.chatCreatedDate)
            st = moment(this.props.chatCreatedDate).format('YYYY-MM-DD')
            st = st+'T12:00:00.066Z'; //timestamp
            return ((current && current.valueOf() > Date.now()) || (current && current.valueOf() < moment(st).subtract(1, 'days').valueOf()));
        }
      
 //   return ((current && current.valueOf() > Date.now()) || (current && current.valueOf() < moment(st).valueOf()));  
        
      
       
    };

    disabledEndDate = (current) => {
        const {archivedSt, archived_at_date ,archivedTimeStamp} = this.state;
        let st;
        let chat_dt;
 
        if(this.props.IsFromArchived) {
            
            st = moment(archived_at_date).format('YYYY-MM-DD');
            st = st+'T12:00:00.066Z'; //timestamp
            chat_dt =  moment(this.props.chatCreatedDate).format('YYYY-MM-DD');
            chat_dt = chat_dt+'T12:00:00.066Z'; //timestamp
            return (current && current.valueOf() > moment(st).valueOf());
            
           
        } else {
            st = moment(this.props.chatCreatedDate).format('YYYY-MM-DD')
            st = st+'T12:00:00.066Z'; //timestamp
            return (current && current.valueOf() > Date.now());
        }
       
    };

    validation = async (value) => {
         const { archived, archived_at,startdate,enddate ,errors,selectedDate} = this.state;
         const {IsFromArchived} = this.props;
        if (selectedDate === null) {
            errors.selectedDate = 'Select any option!';
        } else {
            errors.selectedDate = '';
            if (selectedDate === 4) {
                if (startdate === null) {
                    errors.startdate = 'Select any option!';
                } else {
                    errors.startdate = '';
                }
                if (enddate === null) {
                    errors.enddate = 'Select any option!';
                } else {
                    errors.enddate = '';
                }
                if (startdate !== null && enddate !== null) {
                    if (this.formatDate(enddate) < this.formatDate(startdate)) {
                        errors.enddate = 'End date must be more than start date!';
                    } else {
                        let en = this.formatDate(enddate);
                        let st = this.formatDate(startdate);
                        const oneDay = 1000 * 60 * 60 * 24;
                       var dif = new Date(en).getTime() - new Date(st).getTime();
                       const diffInDays = Math.round(dif / oneDay);
                       if(diffInDays > 29) {
                             errors.enddate = 'End date must be maximum difference of 30 days from start date!';
                       } else {
                            errors.enddate = '';
                       }
                    }
                }
            }
        }
        if (
                errors.selectedDate === '' &&
                errors.startdate === '' &&
                errors.enddate === ''
            ) {
                this.downLoad(true);
            } else {
                await this.setState({ errors: errors });
            }
        await this.setState({ errors: errors });

    }

    downLoad = async (value) => {
        const { archived, archived_at,startdate,enddate,selectedDate,archivedSt,archived_at_date,archivedTimeStamp } = this.state;
        const {IsFromArchived} = this.props;
        let filter1 = {};
        let filter2 = {};
        let arr = [];
        let allmessages = [];
        let start;
        let end
        let newsecond;
        let timeInsecond;
        let archTimeStamp;
        if (selectedDate === 4) {
            //start = new Date(moment(startdate).subtract(1, 'days'));
            start = moment(startdate).format('YYYY-MM-DD') 
            end = moment(enddate).format('YYYY-MM-DD')
            end = end+'T23:00:00.066Z'
        } else {
            start = startdate;
            end = enddate;
        }
        let st = moment(start).format('YYYY-MM-DD')
        let ed = moment(end).format('YYYY-MM-DD')
        st = st+'T12:00:00.066Z'; //timestamp
        ed = ed+'T12:00:00.066Z';  //timestamp

        if(IsFromArchived) {
            console.log('innnn')
            if(moment(enddate).format('YYYY-MM-DD') == moment(archived_at_date).format('YYYY-MM-DD')) {
                archTimeStamp = archived_at_date.split('T');
                console.log('timestamp',archTimeStamp[1]);
                end = moment(enddate).format('YYYY-MM-DD')
                end = end+'T'+archTimeStamp[1];
            }
        }

        let startdateTimestamp = Math.round(new Date(start).getTime() / 1000);
        let enddateTimestamp = Math.round(new Date(end).getTime() / 1000);
        if(archivedSt && !IsFromArchived) {
             if(startdateTimestamp < archivedTimeStamp) {
                startdateTimestamp = archivedTimeStamp;
             }
             
        }

        
            
        arr.push(filter1)
        arr.push(filter2)

        filter1.field = 'date_sent';
        filter1.operator = 'lte'
        filter1.value = '' + enddateTimestamp;
           
        filter2.field = 'date_sent';
        filter2.operator = 'gte'
        filter2.value = '' + startdateTimestamp;

        if(this.validation) {
            this.setState({loadingIs : true})

            let chathistory = await ChatHistory(this.props.dialogId, 0, 0, arr, archivedSt,true);
            if(chathistory.items.length == 0) {
                this.setState({noData : true,loadingIs : false})
            } else {
                for (const row of chathistory.items) {
                let senderid = row.sender_id;

                    let _user = await this.GetUserData(senderid);
                    let messagebody = '';
                    if (row.message && row.message != null) {
                        messagebody = row.message;
                    }
                    if((row.message != null) || (row.attachments && row.attachments[0].type == 'image')) {
                       const message = {
                            id: row._id,
                            text: messagebody,
                            createdAt: moment(row.created_at).toDate(),
                            user: _user,
                            //pending: true,
                            sent: true,
                            received: false,
                            selected: false,
                        }; 

                        if (row.attachments) {
                            const { attachments } = row;
                            const attachment = attachments[0];

                            let url = QB.content.privateUrl(attachment && attachment.id);

                            if (url) {
                                if (attachment && attachment.type == 'image') {
                                    message.image = url;
                                    message.image_id = attachment.id;
                                }
                                // if (attachment && attachment.type == 'audio') {
                                //     message.audio = url;
                                //     message.audio_id = attachment.id;
                                // }
                            }
                        }
                        allmessages.push(message);
                    }
                    
            }

            this.setState({show: value,msg : allmessages,loadingIs : false})
            }
            
        }
        
       
    };

    GetUserData = async (userid) => {
        let user = await this.props.users.filter((row) => {
            return row._id == userid;
        });
        if (user.length > 0) {
            return user[0];
        } else {
            return {};
        }
    };

    refreshData =  function () {
        this.setState({exportChatVisible : false,
                        selectedDate: null,
                        startdate: new Date(utc),
                        enddate: new Date(utc),
                        show: false,
                        msg : '',
                        noData : false
                    });
        this.props.onClose();
    }
    



    render() {
        const {
            startdate,
            enddate,
            selectedDate,
            show,
            msg,
            errors,
            noData,
            loadingIs,
            archivedSt
        } = this.state;
        const {IsFromArchived} = this.props;

        // let d = new Date();
        // let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

        // console.log("getCurrentDate : ", dformat);
        // let fileName = "chat_" + dformat + ".pdf";
   
        return (
             <Modal
                    title="Export Chat"
                    centered
                    className="chat-modal"
                    visible={this.props.exportChatVisible}
                    onOk={this.props.onClose}
                    onCancel={this.refreshData}
                >
                <LoadingOverlay
                active={loadingIs ? true : false}
                className={'loader-wrap chat-loader-overlay ' + (loadingIs ? 'd-block' : '')}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '100px',
                        '& svg circle': {
                            stroke: 'rgb(56, 160, 235)',
                        },
                    }),
                }}
            >

                    <div className="new-topic-wrap">
                        <div className="upload-wrapper">
                            <Select
                                value={selectedDate === null ? 'Select Date' : this.getName(selectedDate)}
                                className="full-select"
                                onChange={(value) => {
                                    this.handleDate(value);
                                }}
                            >
                                {Array.isArray(dateDropwnData) && !IsFromArchived &&
                                    dateDropwnData.map((item, i) => (
                                        <Option
                                            key={i}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Option>
                                    ))}

                                    {Array.isArray(archDateDropwnData) && IsFromArchived &&
                                    archDateDropwnData.map((item, i) => (
                                        <Option
                                            key={i}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                              {errors.selectedDate !== '' ? <div className="form-err">{errors.selectedDate}</div> : null}
                            {selectedDate && selectedDate == 4 && (
                                <div className="chat-export-picker">
                                    <div className="chat-export-inner">
                                        <span className="chat-export-start-label">Start Date</span>
                                        <DatePicker
                                            className="cust-dp"
                                            inputReadOnly={true}
                                            value={Moment(this.state.startdate)}
                                            disabledDate={this.disabledDate}
                                            placeholder="Start Date"
                                            size="large"
                                            onChange={this.handleStartField}
                                        />
                                        {errors.startdate !== '' ? (
                                            <div className="form-err">{errors.startdate}</div>
                                        ) : null}
                                    </div>
                                    <div className="chat-export-inner">
                                        <span className="chat-export-start-label">End Date</span>
                                        <DatePicker
                                            className="cust-dp"
                                            inputReadOnly={true}
                                            disabledDate={this.disabledEndDate}
                                            value={Moment(this.state.enddate)}
                                            placeholder="End Date"
                                            size="large"
                                            onChange={this.handleEndField}
                                        />
                                        {errors.enddate !== '' ? (
                                            <div className="form-err">{errors.enddate}</div>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                            <Button className="fp-button-secondary archive-btn" onClick = {this.validation}>Confirm</Button>
                        </div>
                        {noData && (
                         <span className="l">No chat found!!</span>
                        )}
                        {show && (    
                            <PDFDownloadLink
                                  document={<PdfDocument data={this.state.msg} />}
                                  fileName="chat.pdf"
                                  className = "chat-downLoad-btn"
                                  style={{
                                   
                                  }}
                                >
                                {({ blob, url, loading, error }) =>
                                    loading ? 'Loading document...' : 'Download now!'
                                }
                            </PDFDownloadLink> 
                        )}
                    </div>
                    </LoadingOverlay>
                </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer, houseHoldReducer }) => {
    const { children, user, loadings, error, coparent } = serviceReducer;
    const { thirdPartyData } = houseHoldReducer;
    return { children, user, loadings, error, coparent, thirdPartyData };
};

export default connect(mapStateToProps)(ChatExportModal);
