import { combineReducers } from 'redux';
import register from 'reducers/registerReducer';
import login from 'reducers/loginReducer';
import serviceReducer from 'reducers/serviceReducer';
import houseHoldReducer from 'reducers/houseHoldReducer.js';
const rootReducer = combineReducers({
    register,
    login,
    serviceReducer,
    houseHoldReducer
});

export default rootReducer;
