import React, { Component } from 'react';
import 'pages/CalenderInner/Swap/Swap.scss';
import { Card, Button, Modal, Form, Input, Select, Avatar, Empty } from 'antd';
import right from 'Assets/right-arrow.png';
import TextArea from 'antd/lib/input/TextArea';
import { connect } from 'react-redux';
import user from 'Assets/defaultAvatar.png';
import { CalendarOutlined } from '@ant-design/icons';
import Calendar from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import { setupScheduleException } from 'services/calendarService';
import LoadingOverlay from 'react-loading-overlay';
import DeleteExceptionModal from 'pages/CalenderInner/Swap/DeleteExceptionModal';
import moment from 'moment';
import { 
    getScheduleException,
    updateScheduleException,
    deleteException
 } from 'services/calendarService';

let frequencies =  [
    {
      "label": "Yearly",
      "value": "yearly"
    },
    {
      "label": "Weekly",
      "value": "weekly"
    },
    
    {
      "label": "Monthly",
      "value": "monthly"
    },
    {
      "label": "6 Months",
      "value": "six_months"
    },
    {
      "label": "One Time",
      "value": "onetime"
    }
  ];

   let exceptionTypes = [
    {
      "label": "Alternate custody, I get first custody",
      "value": 1
    },
    {
      "label": "Alternate custody, co-parent gets first custody",
      "value": 2,
    },
    {
      "label": "I will have custody on this date",
      "value": 3
    },
    {
      "label": "I won't have custody on this date",
      "value": 4,
    }
  ];

  const exception_status = {
    pending: 'Pending',
    active: 'Approved',
    declined: 'Declined',
  };

class ExceptionDetailsModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            title: '',
            child_ids: [],
            frequency: '',
            custody: '',
            start_date: new Date(),
            currentDate: new Date(),
            child: null,
            loading: false,
            picture: null,
            type:'special_date',
            coparent_id: '',
            modalVisible: false,
            modal1Visible: false,
            status: '',
            exception: null,
            decline_reason: '',
        };
        this.handleFrequency = this.handleFrequency.bind(this);
        this.handleCustody = this.handleCustody.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.confirmDate = this.confirmDate.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getFrequency = this.getFrequency.bind(this);
        this.getCustody = this.getCustody.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps && nextProps.handleOpen && nextProps.currentException){
            this.setState({loading: true});
            getScheduleException(nextProps.currentException.id)
            .then(resp => {
                this.setState({
                    title: resp.title,
                    child_ids: resp.child_ids || [],
                    frequency: resp.frequency,
                    custody: resp.is_alternate_new,
                    start_date: new Date(resp.start_date),
                    currentDate: new Date(resp.start_date),
                    child: resp.Members && resp.Members[0] || null,
                    picture: resp.Members && resp.Members[0].picture,
                    type: resp.type,
                    coparent_id: resp.coparent_id,
                    loading: false,
                    status: resp.status,
                    exception: resp,
                    decline_reason: resp.decline_reason,
                });
            })
           
        }
    }

    formatDate = function (date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return month + '/' + day + '/' + year;
    };

    getFrequency = function(value){
        let val = '';
        frequencies.forEach(item => {
            if(item.value == value){
                val = item.label;
            }
        })
        return val;
    }

    getCustody = function(value){
        let val = '';
        exceptionTypes.forEach(item => {
            if(item.value == value){
                val = item.label;
            }
        })
        return val;
    }

    handleDate = function (e) {
        this.setState({ currentDate: e });
    };

    confirmDate = async function () {
        const { currentDate } = this.state;
        await this.setState({ start_date: currentDate, modalVisible: false });
    };

    setModalVisible = function(visible){
        this.setState({modalVisible: visible});
    }

    setModal1Visible = function(visible){
        this.setState({modal1Visible: visible});
    }

    handleFrequency = async function(value) {
        await this.setState({frequency: value });
    }

    handleCustody = async function(value) {
        await this.setState({custody: value });
    }

    handleDelete = function() {
        const {exception} = this.state;
        this.setState({loading: true});
        deleteException(exception.id)
        .then(resp => {
            this.props.handleClose();
            this.props.refresh();
        })
    }

    handleSubmit = function(){
        const {title, child_ids, picture, start_date, type, frequency, custody, coparent_id, exception } = this.state;

        this.setState({loading: true});
        let schedule = {
            title: title,
            child_ids: child_ids,
            picture: picture,
            start_date: new Date(start_date).toISOString(),
            type: 'special_date',
            frequency: frequency,
            is_alternate_new: custody,
            child_isd: child_ids,
            coparent_id: coparent_id
        };

        updateScheduleException(exception.id, schedule)
        .then(resp => {
            this.setState({loading: false});
            this.props.handleClose();
            this.props.refresh();
        })
    }

    render(){
        const { Option } = Select;
        
        const {
            title, 
            child_ids, 
            child,
            frequency, 
            status,
            picture, 
            custody, 
            start_date, 
            loading, 
            currentDate,
            decline_reason
        } = this.state;
        return (
            <Modal
              title="Schedule Exception Setup"
              className="cal-modal swap-modal exception-modal"
              centered
              maskClosable={false}
              visible={this.props.handleOpen}
              onOk={this.props.handleClose}
              onCancel={this.props.handleClose}
            >
            <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
             ></LoadingOverlay>
             <Form className="schedule-form">
                <div className="scroll-wrap enhance-height">
                    <div className="invite-top">
                        <div style={{fontSize: '12px', color: '#999999'}}>Enter Exception Title</div>
                        <span>{title}</span>
                    </div>
                    <div className="swap-details">
                       <span style={{color: '#999999', fontSize: '12px'}}>Status:</span>              
                       <span style={{fontSize: '12px', color: exception_status[status] === 'Approved' ? 'green' : 'red'}}>{exception_status[status]}</span>              
                    </div>
                    {status === 'declined' && (
                    <div className="swap-details">
                       <span style={{color: '#999999', fontSize: '12px'}}>Decline reason:</span>              
                       <span style={{fontSize: '12px', color: 'red'}}>{decline_reason}</span>              
                    </div>
                    )}
                    <div className="swap-details">
                       <span style={{color: '#999999', fontSize: '12px'}}>Kid(s):</span>              
                       <span style={{fontSize: '12px'}}>{child && child.name}</span>              
                    </div>
                    <div className="swap-details">
                       <span style={{color: '#999999', fontSize: '12px'}}>Date:</span>              
                       <span style={{fontSize: '12px'}}>{this.formatDate(start_date) + ' ' + moment(start_date).format('hh:mm A')}</span>              
                    </div>
                    <Form.Item className="select-sm"> 
                        <div className="heading-style">What is the frequency of custody exception?</div> 
                        <Input className="exception-input" value={this.getFrequency(frequency)} readOnly />
                    </Form.Item>
                    <Form.Item className="select-sm"> 
                    <div className="heading-style">Who will have custody on exception date?</div>  
                       <Input className="exception-input" value={this.getCustody(custody)} readOnly />
                    </Form.Item> 
                    <div style={{marginBottom: '20px'}}></div>       
                </div>
                <div style={{marginBottom: '80px'}}>
                </div>
            </Form>
          </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparent } = serviceReducer;
    return { user, error, children, coparent };
};

export default connect(mapStateToProps)(ExceptionDetailsModal);