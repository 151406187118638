import React, { Component } from 'react';
import 'pages/Payment/NewTransactions/NewTransactions.scss';
import { Card, Button, Empty } from 'antd';
import ScrollArea from 'react-scrollbar';
import { connect } from 'react-redux';
import { getPaymentTransactions } from 'services/paymentService';
import LoadingOverlay from 'react-loading-overlay';
import Icon from '@ant-design/icons';
import CreatePaymentRequestModal from 'pages/Payment/Payments/CreatePaymentRequestModal';
import { addLoading, removeLoading } from 'actions/loaderActions';
import ACHModal from 'pages/Payment/Payments/ACHModal';
import RecentModal from 'pages/Payment/Payments/RecentModal';
import {removeReloading} from 'actions/loaderActions';
import { getUserById } from 'services/userService';

const frequencies = {
    weekly: 'Weekly',
    'bi-weekly': 'Bi-Weekly',
    monthly: 'Monthly',
    '2-month': '2 Months',
    '3-month': '3 Months',
    '6-month': '6 Months',
    yearly: 'Yearly',
};

const payment_status = {
    inProgress: 'InProgress',
    inQueue: 'In Queue',
    verifying: 'Verifying',
    processing: 'Processing',
    paid: 'Paid',
    disputed: 'Disputed',
    declined: 'Declined',
    Cancelled: 'Cancelled',
    archived: 'Archived',
};

const refreshSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 13.237 17.024">
        <path
            d="M177.543,26.108a6.5,6.5,0,0,1,6.373-7.246V17.115a.125.125,0,0,1,.2-.09l3.594,2.641a.11.11,0,0,1,0,.177l-3.59,2.641a.125.125,0,0,1-.2-.09V20.65a4.7,4.7,0,0,0-4.255,2.883,4.633,4.633,0,0,0-.364,2.058,4.686,4.686,0,0,0,1.026,2.679.9.9,0,1,1-1.41,1.109A6.41,6.41,0,0,1,177.543,26.108Zm10.376-3.355a4.645,4.645,0,0,1,.661,4.737,4.7,4.7,0,0,1-4.255,2.883V28.631a.125.125,0,0,0-.2-.09l-3.594,2.641a.11.11,0,0,0,0,.177L184.121,34a.125.125,0,0,0,.2-.09V32.162a6.5,6.5,0,0,0,6.373-7.246,6.376,6.376,0,0,0-1.369-3.271.9.9,0,1,0-1.407,1.109Z"
            transform="translate(-177.5 -17)"
        />
    </svg>
);
const RefreshIcon = (props) => <Icon component={refreshSvg} {...props} />;

class NewTransactions extends Component {
    constructor(props){
        super(props);
        this.state = {
            transactions: [],
            offset: 0,
            coparent: null,
            loading: false,
            modalVisible: false,
            modal1Visible: false,
            modal2Visible: false,
            currentPayment: null,
            coParent: null
        }
        this.getRecentTransactions = this.getRecentTransactions.bind(this);
        this.getFormattedDate = this.getFormattedDate.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        let offset = 0;
        if (localStorage.third_party && localStorage.third_party === 'true') {
            getUserById(localStorage.coparent_id)
                    .then(resp => {
                        if (resp) {
                            this.setState({ coParent: resp });
                    }
            })
            this.getRecentTransactions(offset, localStorage.coparent_id, false);
        } else {
            this.getRecentTransactions(offset, this.props.coparent, false);
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.coparent !== prevProps.coparent) {
            let offset = 0;
            let arr = [];
            this.setState({ transactions: arr });
            if (localStorage.third_party && localStorage.third_party === 'true') {
                this.getRecentTransactions(offset, localStorage.coparent_id, true);
            } else {
                this.getRecentTransactions(offset, this.props.coparent, true);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps && nextProps.err !== null) {
            this.setState({loading: false});
        }
        if(nextProps && nextProps.error !== null) {
            this.setState({loading: false});
        }
        if(nextProps && nextProps.loadings === true){
            this.props.dispatch(removeLoading());
            let offset = 0;
            let arr = [];
            this.setState({ transactions: arr });
            if (localStorage.third_party && localStorage.third_party === 'true') {
                this.getRecentTransactions(offset, localStorage.coparent_id, true);
            } else {
                this.getRecentTransactions(offset, this.props.coparent, true);
            }
        }
        if(nextProps && nextProps.reload === true){
            this.props.dispatch(removeReloading());
            let offset = 0;
            let arr = [];
            this.setState({ transactions: arr });
            if (localStorage.third_party && localStorage.third_party === 'true') {
                this.getRecentTransactions(offset, localStorage.coparent_id, true);
            } else {
                this.getRecentTransactions(offset, this.props.coparent, true);
            }
        }
    }

    refreshPage = async function() {
        this.props.dispatch(addLoading());
        let offset = 0;
        let arr = [];
        await this.setState({ transactions: arr });
        if (localStorage.third_party && localStorage.third_party === 'true') {
            this.getRecentTransactions(offset, localStorage.coparent_id, true);
        } else {
            this.getRecentTransactions(offset, this.props.coparent, true);
        }
    }

    getRecentTransactions = function(offset, coparent, flag) {
        const { transactions } = this.state;
        if(this.props.err === null) {
           this.setState({ loading: true });
        }
        let sParams = {};

        if (localStorage.third_party && localStorage.third_party === 'true') {
            sParams = {
                offset: offset,
                limit: 15,
                orderBy: '-updated_at',
                coparent_id: coparent,
                thirdParty: true
            };
        } else {
            sParams = {
                offset: offset,
                limit: 15,
                orderBy: '-updated_at',
                coparent_id: (coparent && coparent.id) || null,
            };
        }

        this.setState({ offset: offset, coparent: coparent || null });
        getPaymentTransactions(sParams).then((resp) => {
            if (resp) {
                if (resp.index !== undefined) {
                    this.setState({ offset: resp.index + 1, coparent: { id: resp.coparent } });
                } else {
                    resp.offset += 15;
                    this.setState({ offset: resp.offset, coparent: { id: resp.coparent } });
                }

                if (flag) {
                    let arr = [];
                    arr = [...arr, ...resp.transactions];
                    this.setState({ transactions: arr, loading: false });
                } else {
                    let arr = [];
                    arr = [...transactions, ...resp.transactions];
                    this.setState({ transactions: arr, loading: false });
                }
            } else {
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({loading: false});
        })
    }
    
    getFormattedDate = function(dateObj) {
        let month = dateObj.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : ('0' + month);
        let day = dateObj.getDate().toString();
        let year = dateObj.getFullYear();
        let output = day  + '/' + month  + '/' + parseInt(year.toString().slice(2));
        return output;
    }

    setModalVisible = async function(visible) {
        await this.setState({modalVisible: visible});
    }

    setModal1Visible = async function(payment, visible) {
        await this.setState({currentPayment: payment, modal1Visible: visible});
    }

    setModal2Visible = async function(payment, visible) {
        await this.setState({currentPayment: payment, modal2Visible: visible});
    }

    handleClick = function(payment){
        if(payment.status === 'inProgress') {
           this.setModal1Visible(payment, true);
        } else {
            this.setModal2Visible(payment, true);
        }
    }

    render() {
    const { transactions, offset, coparent, coParent, currentPayment, loading} = this.state;
    const { user } = this.props;
    let normalUser = localStorage.third_party !== 'true';
    let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';    


    return (
        <Card bordered={false} className="nt-card">
            <LoadingOverlay
                    active={loading ? true : false}
                    className={'loader-wrap cust-loader ' + (loading ? 'd-block' : '')}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
            ></LoadingOverlay>
            <div className="nt-card-head">
                <h1>Recent Transactions</h1>
                <Button type="primary" onClick={() => this.setModalVisible(true)} className="fp-button fp-button-primary btn-add-req">
                    Add a new request
                </Button>
                <CreatePaymentRequestModal refresh={() => this.refreshPage()} handleOpen={this.state.modalVisible} handleClose={() => this.setModalVisible(false)} />
            </div>
            {/* <span className="divider"> </span> */}
            <ScrollArea 
                speed={0.8} 
                className="nt-card-body" 
                contentClassName="content"
                onScroll={(value) => {
                    if (value.topPosition === value.realHeight - value.containerHeight && value.topPosition !== 0) {
                        if (localStorage.third_party && localStorage.third_party === 'true') {
                            this.getRecentTransactions(offset, localStorage.coparent_id, false);
                        } else {
                            this.getRecentTransactions(offset, coparent, false);
                        }
                    }
                }}
                horizontal={false}
            >
                {user && transactions && Array.isArray(transactions) && transactions.length !== 0 ? transactions.map((payment, i) => (
                (user.id === payment.PaymentCategory.coparent_user_id || user.id === payment.PaymentCategory.coparent_standlalone_id) && payment.PaymentCategory.category_status === 1 ? null : (
                <Card key={i} onClick={() => this.handleClick(payment)} bordered={false} className="list-card">
                    <div className="req-inner">
                        <div className="req-details">
                            <span>{ normalUser ? (user && user.id === payment.payer_id ? 'Request from' : ( user && user.id === payment.receiver_id) ? 'Request sent to' : null) : (coParent && coParent.id === payment.payer_id ? 'Request from' : ( coParent && coParent.id === payment.receiver_id) ? 'Request sent to' : null)}</span>
                            <span>{ normalUser ? (user && user.id === payment.payer_id ? payment.receiver_username : ( user && user.id === payment.receiver_id) ? payment.payer_username : null) : (coParent && coParent.id === payment.payer_id ? payment.receiver_username : ( coParent && coParent.id === payment.receiver_id) ? payment.payer_username : null)}</span>
                        </div>
                        <div className="req-action">
                        {payment && payment.recurring === true ? (
                            <div className="icon-wrap">
                                <RefreshIcon />
                                <span>
                                    {payment && frequencies[payment.RecurringPaymentSchedule.frequency]}
                                </span>
                            </div>
                            ) : null}
                            <Button
                                type="primary"
                                className={
                                    payment && payment.status === 'pending'
                                        ? user && user.id === payment.payer_id
                                        ? 'btn fp-button-secondary'
                                        : user && user.id === payment.receiver_id
                                        ? 'btn fp-button-primary'
                                        : null
                                        : payment && payment.status === 'inProgress'
                                        ? 'btn fp-button-primary'
                                        : 'btn fp-button-secondary'
                                    }
                                >
                                    <span>
                                        {
                                            payment && payment.status === 'inProgress'
                                            ? (payment.sale_status !== 'paid' ? payment_status[payment.sale_status] : payment_status['processing'])
                                            :payment && (payment_status[payment.status] || payment.status)
                                        }
                                    </span>
                                </Button>
                        </div>
                        <div className="req-amount">
                            <span>{`$${payment.payer_amount}`}</span>
                            <span>{this.getFormattedDate(new Date(payment.payment_date))}</span>
                        </div>
                    </div>
                </Card>
                )
                )): (
                    <div>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                )}
            </ScrollArea>
            <ACHModal
                refresh={() => this.refreshPage()}
                currentPayment={currentPayment}
                handleOpen={this.state.modal1Visible}
                handleClose={() => this.setModal1Visible(false)}
            />
            <RecentModal
                refresh={() => this.refreshPage()}
                currentPayment={currentPayment}
                handleOpen={this.state.modal2Visible}
                handleClose={() => this.setModal2Visible(false)}
            />
        </Card>
    );
    }
};

const mapStateToProps = ({serviceReducer}) => {
    const { user, error, coparent, loadings, reload } = serviceReducer;
    return {user, error, coparent, loadings, reload};
}
export default connect(mapStateToProps)(NewTransactions);
