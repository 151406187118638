import * as QB from "quickblox/quickblox";

const PASSWORD = "12345678";

export const GetSession = () => {
  return new Promise(resolve => {
    QB.getSession(function (error, session) {
      resolve(session);
    });
  });
}

export const CreateAppSession = () => {
  return new Promise(resolve => {
    QB.createSession(function(error, result) {
      // callback function
      resolve(result);
    });
  })
}

export const CreateSession = (login) => {
  var params = { login: login, password: PASSWORD };  
  return new Promise (resolve => {
  QB.createSession(params, function(error, result) {
    // callback function
    resolve(result);

  });

});
}

export const LoginUser = (login) => {
  var params = { login: login, password: PASSWORD };  
  return new Promise(resolve => {
    QB.login(params, function(error, result) {
      // callback function

      resolve(result);
        
    });
});
}

export const ConnectChatServer = (user_id, login) => {

  let userCredentials = {
    userId: user_id,
    login: login,
    password: PASSWORD
  };


  return new Promise(resolve => {
    QB.chat.connect(userCredentials, function(error, contactList) {

      if(QB.chat.isConnected){
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
}

export const DisConnect = async () => {
  try {
    QB.chat.disconnect();
    QB.chat.onDisconnectedListener = onDisconnectedListener;
    
  } catch (error) {
   
  }
};

function onDisconnectedListener() {
}

export const CreateUser = (email, login, fullName) => {

  return new Promise( resolve => {
    var params = {
      login: login,
      email: email,
      password: PASSWORD,
      full_name: fullName
    };
    
    QB.users.create(params, function(error, result) {

      resolve(result);

    });
  });
}

export const IsConnected = () => {
  return QB.chat.isConnected;
}

export const GetUser = () => {
  var searchParams = {email: "rakeshk1195@gmail.com"};
  return new Promise(resolve => {
     
    QB.users.get(searchParams, function(error, user) {
        resolve(user);
    });
  })  
}

export const generateUID = () => {

  // I generate the UID from two parts here

  // to ensure the random number provide enough bits.

  var firstPart = (Math.random() * 46656) | 0;

  var secondPart = (Math.random() * 46656) | 0;

  firstPart = ('000' + firstPart.toString(36)).slice(-3);

  secondPart = ('000' + secondPart.toString(36)).slice(-3);

  return firstPart + secondPart;

};
