import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { updateBundlePaymentStatus } from 'services/paymentService';
import PreviewModal from 'pages/Payment/Payments/PreviewModal';
import { addLoading, removeLoading, addReloading } from 'actions/loaderActions';

const { Option } = Select;
const { TextArea } = Input;

const pay_methods = {
    Epayment: 'ACH',
    credit_card: 'Credit Card',
    cash: 'Cash',
    check: 'Check',
};

class PaymentReceivedModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            received_amount: 0,
            payment_ids: [],
            payments: [],
            paymentMethod: '',
            confirm_code: '',
            file: null,
            error: false,
            modalVisible: false,
        };
        this.setModalVisible = this.setModalVisible.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getFormattedDate = this.getFormattedDate.bind(this);
        this.handleConfirmCode = this.handleConfirmCode.bind(this);
        this.handleReceived = this.handleReceived.bind(this);
        this.handleImageDelete = this.handleImageDelete.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.error !== null) {
            this.setState({ loading: false });
        }
        if (nextProps && nextProps.err !== null) {
            this.setState({ loading: false });
        }
        if (nextProps && nextProps.handleOpen && nextProps.payments) {
            let arr = [];
            nextProps.payments.forEach((payment) => {
                if (nextProps.payment_ids.includes(payment.id)) {
                    arr.push(payment);
                }
            });

            this.setState({
                error: false,
                payments: arr,
                received_amount: nextProps.received_amount.toFixed(2),
                payment_ids: nextProps.payment_ids,
            });
        }
    }

    getFormattedDate = function (dateObj) {
        let month = dateObj.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = dateObj.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = dateObj.getFullYear();
        let output = month + '/' + day + '/' + parseInt(year.toString());
        return output;
    };

    handleChange = async function (value) {
        await this.setState({ paymentMethod: value, error: false });
    };

    handleConfirmCode = function (e) {
        this.setState({ confirm_code: e.target.value });
    };

    handleReceived = function () {
        const { paymentMethod, payment_ids, confirm_code } = this.state;
        const { user } = this.props;
        if (paymentMethod.length === 0) {
            this.setState({ error: true });
        } else {
            this.setState({ loading: true });
            let paymentIds = payment_ids;
            let options = {
                status: 'paid',
                approved_by: user.id,
                approved_date: new Date().toISOString(),
                confirm_code: confirm_code,
                payment_method: paymentMethod,
                paid: false,
            };

            const data = {
                paymentIds: paymentIds,
                options: options,
            };

            updateBundlePaymentStatus(data).then((resp) => {
                this.setState({ loading: false });
                this.props.handleClose();
                this.props.refresh();
                this.props.dispatch(addReloading());
            });
        }
    };

    setModalVisible = function (visible, file) {
        this.setState({ modalVisible: visible, file: file });
    };

    handleImageDelete = function (payment, file, index) {
        const { payments } = this.state;
        let arr = payments;
        payments.forEach(async (item, i) => {
            if (item.id === payment.id) {
                let temp = {};
                payment.attachment.splice(index, 1);
                temp = payment;
                arr.splice(i, 1, temp);
                await this.setState({ payments: arr });
            }
        });
    };

    render() {
        const { received_amount, payments, loading, paymentMethod } = this.state;
        const { user } = this.props;
        return (
            <Modal
                title="Payments Received"
                centered
                visible={this.props.handleOpen}
                maskClosable={false}
                className="payments-popup ach-payment trans-detail"
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>

                <Form>
                    <div className="pay-wrap">
                        <div className="detail-wrap">
                            {payments && Array.isArray(payments) && payments.length !== 0
                                ? payments.map((payment, i) => (
                                      <div className="payment-detail" key={i}>
                                          <div className="detail">
                                              <span>
                                                  {user ? payment.payer_username : null}
                                              </span>
                                              <span>{`$${payment.payer_amount}`}</span>
                                          </div>
                                          <div className="detail">
                                              <span>
                                                  {payment && payment.PaymentCategory && payment.PaymentCategory.name}
                                              </span>
                                              <span>{this.getFormattedDate(new Date(payment.payment_date))}</span>
                                          </div>
                                      </div>
                                  ))
                                : null}
                        </div>
                        <Form.Item>
                            <div className="select-wrap">
                                <Select
                                    value={paymentMethod.length === 0 ? 'Payment Method' : pay_methods[paymentMethod]}
                                    onChange={this.handleChange}
                                >
                                    <Option value="Epayment">ACH</Option>
                                    <Option value="credit_card">Credit Card</Option>
                                    <Option value="cash">Cash</Option>
                                    <Option value="check">Check</Option>
                                </Select>
                                {this.state.error ? <div className="field-err">This field is required</div> : null}
                            </div>
                        </Form.Item>

                        <Form.Item>
                            <Form.Item className="input-sm input-code">
                                <Input onChange={this.handleConfirmCode} placeholder="Confirmation Code/Check#" />
                            </Form.Item>
                        </Form.Item>

                       
                            {Array.isArray(payments) &&
                                payments.map((payment, i) => (
                                    payment.attachment && payment.attachment.length !== 0 && (
                                    <div key={i} className="upload-file">
                                        <div  className="modal-bottom">
                                            {Array.isArray(payment.attachment) && payment.attachment.length !== 0 ? (
                                                <span>Attachments</span>
                                            ) : null}
                                            {Array.isArray(payment.attachment) && payment.attachment.length !== 0 && (
                                                <div className="upload-wrapper">
                                                    {/* <input
                                                        id="img"
                                                        type={payment.attachment.length <= 3 ? 'file' : 'button'}
                                                        multiple={true}
                                                        encType="multipart/form-data"
                                                        onChange={this.handleUpload}
                                                    /> */}
                                                    {/* <Button type="primary" className="add-btn fp-button-primary">
                                                        Add
                                                    </Button> */}
                                                </div>
                                            )}
                                        </div>

                                        {Array.isArray(payment.attachment) &&
                                            payment.attachment.length !== 0 &&
                                            payment.attachment.map((file, i) => (
                                                <div className="file-track" key={i}>
                                                    <a onClick={() => this.setModalVisible(true, file.url || file.uri)}>
                                                        View
                                                    </a>
                                                    <span
                                                        style={{ color: 'red' }}
                                                        onClick={() => this.handleImageDelete(payment, file, i)}
                                                    >
                                                        Delete
                                                    </span>
                                                </div>
                                            ))}
                                    </div>
                                    )
                                ))}
                        
                        <div className="detail-wrap cust-mt">
                            <div className="detail">
                                <span>Amount</span>
                                <span>${received_amount}</span>
                            </div>
                        </div>

                        {paymentMethod === 'Epayment' ? (
                            <div style={{ fontSize: '10px', marginBottom: '10px' }}>
                                Payments made after 8:00PM Central Time will be processed the next business day.
                            </div>
                        ) : null}
                    </div>
                    <Form.Item className="btn-form btn-pad">
                        <Button type="primary" onClick={this.handleReceived} className="btn fp-button-primary">
                            Received ${received_amount}
                        </Button>
                    </Form.Item>
                </Form>

                <PreviewModal
                    file={this.state.file}
                    handleOpen={this.state.modalVisible}
                    handleClose={() => this.setModalVisible(false)}
                />
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparent } = serviceReducer;
    return { user, error, children, coparent };
};

export default connect(mapStateToProps)(PaymentReceivedModal);
