import React, { Component } from 'react';
import { Button, Modal, Form, Select, Input, Col, Checkbox, label } from 'antd';
import { addCoparent, editCoparent } from '../../../services/houseHoldService';
import { getHouseHoldAction } from 'actions/houseHoldAction.js';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
const { Option } = Select;
const validator = require('email-validator');

class ViewCoparent extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: '',
            email: '',
            gender: 'Select Gender',
            LoadingIs: false,
            errorIs: { name: '', email: '', gender: '' },
            resMsg: '',
            checked: true,
            disabled: false,
            chekbox: true,
            userName: '',
            isThirdParty : localStorage.getItem("third_party"),
        };
        this.handleModal = this.handleModal.bind(this);
        this.resetField = this.resetField.bind(this);
        this.handleErrorModal = this.handleErrorModal.bind(this);
    }

    handleModal = (modalVisible) => {
        this.props.handleFunc({
            viewModal: false, viewIs: false
         });
        this.resetField();
    };

    resetField = () => {
        this.setState({
            name: '',
            email: '',
            gender: 'Select Gender',
            errorIs: { name: '', email: '', gender: '' },
        });
    };

    componentDidMount = async function() {
        // alert('saas');
         if(this.state.isThirdParty == 'false') {
              await this.props.dispatch(getHouseHoldAction());
         }
      
        let user = this.props.response.serviceReducer.user;
        await this.setState({ userName: user !== null ? user.first_name : '' });
    }

    componentWillReceiveProps(props) {
        const { viewData, editIs, response, viewIs } = props;
        const user = response.serviceReducer.user;
        var coParentEmail = "";
        if (viewData && viewIs) {
            if( viewData.email.endsWith('_notnotify')) {
                coParentEmail = viewData.email.slice(0, -10);
            }
            else {
                if(viewData) {
                     coParentEmail = viewData.email;
                }
                else {
                    coParentEmail = "";
                }
            }

            if(this.state.isThirdParty == 'true') {
                this.setState({
                    name: viewData.name ? viewData.name : '',
                    email: viewData.email ? coParentEmail : '',
                });

            } else {
                this.setState({
                    name: viewData.name ? viewData.name : '',
                    email: viewData.email ? coParentEmail : '',
                    gender: viewData.gender ? viewData.gender : '',
                    chekbox: true,
                    userName: user && user.first_name,
                });
            }
           
        }
    }

    handleErrorModal = () => {
        this.setState({ resMsg: '' });
        this.props.handleFunc({ viewModal: false, viewIs: false });
        this.props.refresh();
    };

    render() {
        const { name, email, gender, errorIs, LoadingIs, resMsg, chekbox,isThirdParty } = this.state;
        const { modalVisible, addCoparent, editIs, response, viewModal, viewData } = this.props;
        const user1 = response.serviceReducer.user;
        
        const check = (viewData && (!viewData.is_registered || (viewData.is_registered && viewData.sender_id == user1.id))) 

        return (
            <React.Fragment>
                <Modal
                    centered
                    visible={viewModal}
                    backdrop="static"
                    maskClosable={false}
                    className="modal-container req-card add-coparent"
                    onCancel={() => this.handleModal(false)}
                >
                    <LoadingOverlay
                        active={LoadingIs}
                        className="d-block pop-loader"
                        spinner
                        styles={{
                            spinner: (base) => ({
                                ...base,
                                width: '100px',
                                '& svg circle': {
                                    stroke: 'rgb(56, 160, 235)',
                                },
                            }),
                        }}
                    ></LoadingOverlay>
                    <div className="modal-head">
                        <span>{ 'Co-parent'}</span>
                    </div>
                        <Col className="col-wrap ">
                            <div className='input-full view-coparent-d-opacity'>
                                <Input
                                    placeholder="Enter Name"
                                    name="name"
                                    value={name}
                                    autoComplete="nope"
                                    disabled={'disabled'}
                                />
                            </div>
                        </Col>
                        <Col className="col-wrap ">
                            <div className='input-full view-coparent-d-opacity'>
                                <Input
                                    placeholder="Email address"
                                    name="email"
                                    value={email}
                                    autoComplete="nope"
                                    disabled={'disabled'}
                                />
                            </div>
                        </Col>
                        {check && isThirdParty == 'false' &&  (<Col className="col-wrap">
                            <div className="select-wrap input-full view-coparent-d-opacity">
                                <Select
                                    name="gender"
                                    value={gender}
                                    disabled='disabled'
                                >
                                    <Option value="male">Male</Option>
                                    <Option value="female">Female</Option>
                                    <Option value="nonbinary">Non Binary</Option>
                                </Select>
                            </div>
                        </Col>)}
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (response) => ({ response });
export default connect(mapStateToProps)(ViewCoparent);
