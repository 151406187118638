import React, { Component, useState } from 'react';
import 'pages/Reports/TimeRatio/TimeRatio.scss';
import { Card, Button, Tabs, Modal, Select, Empty } from 'antd';
import { GetActiveReport } from 'services/reportsService';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import CreateCustodyRatioModal from 'pages/Reports/TimeRatio/CreateCustodyRatioModal';
import SaveReport from 'pages/Reports/TimeRatio/SaveReport';

const { TabPane } = Tabs;

class TimeRatio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modalVisible: false,
            reports: [],
            flag: false,
            offset: 0,
            archived: false,
            detailsData: null,
            detailReportVisible: false,
            selectedTab: "1"
        };
        this.setModalVisible = this.setModalVisible.bind(this);
        this.getCustodyRatioReports = this.getCustodyRatioReports.bind(this);
        this.getCoparentName = this.getCoparentName.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.GetChildrenName = this.GetChildrenName.bind(this);
        this.handleAllClose = this.handleAllClose.bind(this);
    }

    componentDidMount() {
        this.startPage();
    }

    handleAllClose = async function(modal) {
        await this.setState({ [modal]: false, detailsData: [] , selectedTab: "1" });
        this.startPage();
    }

    startPage() {
        let offset = 0;
        let archived = false;
        this.getCustodyRatioReports(offset, archived, true);
    }

    getCoparentName = function (cp_id) {
        const { coparents } = this.props;
        let cp_name;
        coparents.forEach((cp) => {
            if (cp.id === cp_id) {
                cp_name = cp.name;
            }
        });

        return cp_name;
    };

    formatDate = function (date) {
        var newdate = moment(date, 'DD-MM-YYYY');
        return moment(newdate).format('MM/DD/YY');
    };

    getCustodyRatioReports = async function (offset, archived, flag) {
        const { reports } = this.state;

        await this.setState({ loading: true });
        let options = {
            archived: archived,
            offset: offset,
            limit: 10,
        };

        await this.setState({ archived: archived, offset: offset });

        GetActiveReport(options).then(async (resp) => {
            if (resp && resp.data.length > 0) {
                let newOffset = this.state.offset + 10;
                await this.setState({ reports: resp.data, offset: newOffset });

                if (flag) {
                    let arr = [];
                    arr = [...arr, ...resp.data];
                    await this.setState({ reports: arr, loading: false });
                } else {
                    let arr = [];
                    arr = [...reports, ...resp.data];
                    await this.setState({ reports: arr, loading: false });
                }
            } else {
                await this.setState({ loading: false });
                if(flag) {
                    this.setState({ reports: [] });
                }
            }
        });
    };

    GetChildrenName = function (report) {
        let res = [];
        report.custodyByUser.forEach((item) => {
            res.push(item.name);
        });

        return res.join(', ');
    };

    handleClick = async function (key) {
        let archived;
        if (key === '1') {
            archived = false;
        } else if (key === '2') {
            archived = true;
        }

        let offset = 0;
        let arr = [];
        await this.setState({ reports: arr, offset: 0, selectedTab: key });
        this.getCustodyRatioReports(offset, archived, true);
    };

    handleScroll = function (e) {
        const { archived, offset } = this.state;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this.getCustodyRatioReports(offset, archived, false);
        }
    };

    setModalVisible = async function (visible) {
        await this.setState({ modalVisible: visible });
    };

    setSaveModalVisible(val) {
        this.setState({ detailReportVisible: val });
    }

    async showDetails(data) {
        let info = {
            data: data.id,
            type: this.state.archived ? 'archive' : 'active',
        };
        await this.setState({ detailsData: info });
        this.setState({ detailReportVisible: true });
    }

    render() {
        const { reports, loading, selectedTab } = this.state;
        const { user } = this.props;

        return (
            <div className="report-time-wrap">
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'd-block pop-loader' : 'd-none pop-loader'}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Card bordered={false} className="time-ratio-card">
                    <div className="card-head">
                        <span>Custody Time Ratio</span>
                        <Button onClick={() => this.setModalVisible(true)} className="fp-button-secondary add-btn">
                            +
                        </Button>
                    </div>
                    <div className="card-body">
                        <Tabs activeKey={selectedTab} onChange={this.handleClick}>
                            <TabPane tab="Active Reports" key="1">
                                <div onScroll={this.handleScroll} className="tab-content-wrap">
                                    {Array.isArray(reports) && reports.length > 0 ? (
                                        reports.map((report, i) => (
                                            <Card
                                                key={i}
                                                bordered={false}
                                                className="card-inner"
                                                onClick={() => this.showDetails(report)}
                                            >
                                                <div className="card-top">
                                                    <span>{this.getCoparentName(report.coparent_id)}</span>
                                                    <span>
                                                        {this.formatDate(report.startDate)} -{' '}
                                                        {this.formatDate(report.endDate)}
                                                    </span>
                                                </div>
                                                <div className="card-bottom">
                                                    <span>{this.GetChildrenName(report)}</span>
                                                </div>
                                            </Card>
                                        ))
                                    ) : (
                                        <div>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Archived Reports" key="2">
                                <div onScroll={this.handleScroll} className="tab-content-wrap">
                                    {Array.isArray(reports) && reports.length > 0 ? (
                                        reports.map((report, i) => (
                                            <Card
                                                key={i}
                                                bordered={false}
                                                className="card-inner"
                                                onClick={() => this.showDetails(report)}
                                            >
                                                <div className="card-top">
                                                    <span>{this.getCoparentName(report.coparent_id)}</span>
                                                    <span>
                                                        {this.formatDate(report.startDate)} -{' '}
                                                        {this.formatDate(report.endDate)}
                                                    </span>
                                                </div>
                                                <div className="card-bottom">
                                                    <span>{this.GetChildrenName(report)}</span>
                                                </div>
                                            </Card>
                                        ))
                                    ) : (
                                        <div>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                    <CreateCustodyRatioModal
                        handleOpen={this.state.modalVisible}
                        handleClose={() => this.setModalVisible(false)}
                        handleRefreshClose={() => this.handleAllClose('modalVisible')}
                    />

                    <SaveReport
                        handleOpen={this.state.detailReportVisible}
                        handleClose={() => this.setSaveModalVisible(false)}
                        handleRefreshClose={() => this.handleAllClose('detailReportVisible')}
                        responseInfo={this.state.detailsData}
                    />
                </Card>
            </div>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, loadings, error, coparent, coparents } = serviceReducer;
    return { children, user, loadings, error, coparent, coparents };
};

export default connect(mapStateToProps)(TimeRatio);
