import React, { Component } from 'react';
import 'pages/SubscriptionNew/SubscriptionDetails.scss';
import logo from 'Assets/kincrew-white.png';
import { Link } from 'react-router-dom';
import { Card, Button, Typography, Input, Tabs, Select, Modal } from 'antd';
import exclaim from 'Assets/exclaim.svg';
import { getMeUserAction } from 'actions/userActions';
import { checkCookie } from 'utils/cookies';
import { connect } from 'react-redux';
import { US_states } from 'pages/Household/Household/StateData.js';
import Moment from 'moment';
import {
    createNewSubscription,
    saveSubscription,
    getCouponCodes,
    notifySubscription,
    updateFreeTrial
} from '../../services/subscriptionService';
import { ROUTES } from 'constants/routes';
import history from 'utils/history';
import LoadingOverlay from 'react-loading-overlay';
import { getUserSubscription } from '../../services/subscriptionService';
import SubscriptionErrorModal from 'pages/SubscriptionNew/SubscriptionError';
import ConnectionErrorModal from 'Components/Modal/ConnectionModal';
import {
    stripeCardNumberValidation,
    stripeCardExpirValidation,
    textWithSpacesOnly,
    minLength,
} from 'pages/SubscriptionNew/validations';
const validator = require('email-validator');
const { Option } = Select;
function handleChange(value) {
   
}
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const findDebitCardType = (cardNumber) => {
    const regexPattern = {
        MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
        VISA: /^4[0-9]{2,}$/,
        AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
        DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
        DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
        JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
    };
    for (const card in regexPattern) {
        if (cardNumber.replace(/[^\d]/g, '').match(regexPattern[card])) return card;
    }
    return '';
};

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();
let addressArray = [];
const dropDownArr = [
    { addressFields: ['address', 'city', 'zip', 'state', 'licence'], name: '+1 United States', value: 'US' },
    {
        addressFields: ['street', 'city', 'provinceOrTerritory', 'postalCode', 'licence'],
        name: '+1 Canada',
        value: 'CA',
    },
];
today = yyyy + '-' + mm + '-' + dd;

const years = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039];

class SubscriptionDetails extends Component {
    constructor(props) {
        super(props);
        const year = new Date().getYear();

        this.state = {
            LoadingIs: true,
            currentDate: today,
            plans: props.location.dataProps,
            customer: {},
            user_id: '',
            first_name: '',
            last_name: '',
            display_name: '',
            email: '',
            mobile: '',
            country_code: 'US',
            state: 'AL',
            address: '',
            street: '',
            zip: '',
            provinceOrTerritory: '',
            postalCode: '',
            card: '',
            expiry: '',
            securityCode: '',
            error: '',
            cardType: '',
            exp_month: '',
            exp_year: '',
            card_type: '',
            total: '',
            discountCode: '',
            cardNumber: '',
            cvc: '',
            zip: '',
            expiryDate: '',
            firstNameError: '',
            lastNameError: '',
            emailError: '',
            cardError: '',
            expiryError: '',
            securityCodeError: '',
            expiryMonthError: '',
            expiryYearError: '',
            allCoupons: [],
            discountDeductValue: 0,
            mobileError: '',
            addressError: '',
            cityError: '',
            streetError: '',
            zipError: '',
            provinceOrTerritoryError: '',
            postalCodeError: '',
            expiryMonthError: '',
            starts_at: today,
            LoaderIs: false,
            modal2Visible: false,
            freetrialCode : false,
            contractApplied : false,
        };
        this.getUserMe = this.getUserMe.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(true);
        this.setModal2Visible = this.setModal2Visible.bind(true);
        this.handleCountry = this.handleCountry.bind(this);
        this.handleState = this.handleState.bind(this);
        this.getState = this.getState.bind(this);
        this.handleFreeTrialSave = this.handleFreeTrialSave.bind(this);
        this.checkFreeTrial = this.checkFreeTrial.bind(this);
    }

    componentDidMount() {
        if (localStorage.third_party === 'true') {
            this.props.history.push('/home');
        }
        this.getUserMe();
        getCouponCodes().then((res) => {
            let allCoupons = JSON.parse(JSON.stringify(this.state.allCoupons));
            allCoupons = res.data ? res.data.coupons : [];
            this.setState({ allCoupons });
        });

        let routeState;
        if (this.props.location.dataProps) {
            if (localStorage.getItem('selectPlanData')) {
                localStorage.removeItem('selectPlanData');
            }
            localStorage.setItem('selectPlanData', JSON.stringify(this.props.location.dataProps));
            routeState = this.props.location.dataProps;
        } else {
            routeState = localStorage.getItem('selectPlanData');
            if (routeState) routeState = JSON.parse(routeState);
        }

        if (routeState) {
            this.setState({ plans: routeState });
        }
    }

    componentWillReceiveProps(props) {
        let { response } = props;
        let user = response.serviceReducer.user;

        this.setState({
            display_name:
                user !== null && Object.keys(user).length !== 0 && typeof user.first_name !== null
                    ? `${user.first_name} ${user.last_name}`
                    : '',
            first_name:
                user !== null && Object.keys(user).length !== 0 && typeof user.first_name !== null
                    ? user.first_name
                    : '',
            last_name:
                user !== null && Object.keys(user).length !== 0 && typeof user.last_name !== null ? user.last_name : '',
            email: user !== null && Object.keys(user).length !== 0 && typeof user.email !== null ? user.email : '',
            mobile:
                user !== null && Object.keys(user).length !== 0 && typeof user.phone_number !== null
                    ? user.phone_number
                    : '',
            user_id:
                user !== null && Object.keys(user).length !== 0 && typeof user.id !== null ? user.phone_number : '',
        });
    }

    setModal1Visible = (modal1Visible) => {
        this.setState({ modal1Visible });
    };

    setModal2Visible = (visible) => {
        this.setState({ modal2Visible: visible });
    };

    handleValidations = (type, value) => {
        let errorText;
        let cardTypeText;
        let expvalue;
        let { country_code } = this.state;
        if (type == 'discountCode' && value == '') {
            this.setState({ discountDeductValue: 0 });
        }
        switch (type) {
            case 'first_name':
               // errorText = value === '' ? 'Required Field' : minLength(3)(value);
                errorText = value === '' ? 'Required Field' : value[0] === ' ' ? 'First name should not start with whitespace character' 
                                     : !value.replace(/\s/g, '').length ? 'Invalid name!' : '' ;
                this.setState({ firstNameError: errorText });
                break;
            case 'last_name':
               // errorText = value === '' ? 'Required Field' : minLength(3)(value);
                errorText = value === '' ? 'Required Field' : value[0] === ' ' ? 'Last name should not start with whitespace character' 
                                     : !value.replace(/\s/g, '').length ? 'Invalid name!' : '';
                this.setState({ lastNameError: errorText });
                break;
            case 'email':
                errorText = value === '' ? 'Required Field' : !validator.validate(value) ? 'Invalid email' : '';
                this.setState({ emailError: errorText });
                break;
            case 'card':
                cardTypeText = findDebitCardType(value);
                this.setState({ card_type: cardTypeText });
                errorText = stripeCardNumberValidation(value);
                this.setState({ cardError: errorText });
                break;
            case 'exp_month':
                errorText =
                    value === ''
                        ? 'Required Field'
                        : stripeCardExpirValidation(`${this.state.exp_month}/${this.state.exp_year}`);
                this.setState({ expiryMonthError: errorText });
                //this.setState({expiryYearError:errorText});
                break;
            case 'exp_year':
                errorText =
                    value === ''
                        ? 'Required Field'
                        : stripeCardExpirValidation(`${this.state.exp_month}/${this.state.exp_year}`);
                //this.setState({expiryMonthError:errorText});
                //this.setState({expiryYearError:errorText});
                break;
            case 'securityCode':
                errorText = value === '' ? 'Required Field' : minLength(3)(value);
                this.setState({ securityCodeError: errorText });
                break;
            /*  case 'mobile':
                errorText = value === '' ? 'Required' : '';
                this.setState({ mobileError: errorText });
                break; */
            case 'mobile':
                var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
                var patternphone = new RegExp(phoneno);
                errorText =
                    value === ''
                        ? 'Required Field'
                        : patternphone.test(value) !== true
                        ? 'Enter 10 digit numeric number'
                        : '';
                this.setState({ mobileError: errorText });
                break;
            case 'city':
                if (value === '') {
                    errorText = 'Required Field';
                } else if (value.charAt(0) === ' ') {
                    if (value.length !== 1 && !value.replace(/\s/g, '').length) {
                        errorText = 'Invalid City Name';
                    } else {
                        errorText = 'Invalid City Name';
                    }
                } else if (value.length > 50) {
                    errorText = 'City name length value exceeded! (Max 50 symbols)';
                } else {
                    errorText = '';
                }
                this.setState({ cityError: errorText });
                break;

            case 'address':
                errorText = value === '' ? 'Required Field' : '';
                this.setState({ addressError: errorText });
                break;

            case 'zip':
                var numbers = /^\d{5}$|^\d{5}-$|^\d{5}-\d{4}$/;
                var pattern = new RegExp(numbers);
                errorText = value === '' ? 'Required Field' : pattern.test(value) !== true ? 'Invalid Zip Code' : '';
                this.setState({ zipError: errorText });
                break;

            case 'postalCode':
                var postalRegx = /^[0-9\b]+$/;
                var patternPostalRegx = new RegExp(postalRegx);
                errorText =
                    country_code == 'CA' && value === ''
                        ? 'PostalCode is required!'
                        : country_code == 'CA' && !patternPostalRegx.test(value)
                        ? 'Only Number Characters Allowed!'
                        : country_code == 'CA' && !(value.length > 1)
                        ? 'PostalCode is Too short (Must be at Least 2 characters)'
                        : '';
                this.setState({ postalCodeError: errorText });
                break;

            case 'provinceOrTerritory':
                var provinceRegx = /^[0-9\b]+$/;
                var patternprovinceRegx = new RegExp(provinceRegx);
                errorText =
                    country_code == 'CA' && value === ''
                        ? 'Province/Territory is required!'
                        : country_code == 'CA' && !(value.length > 1)
                        ? 'Province/Territory is Too short (Must be at Least 2 characters)'
                        : '';
                this.setState({ provinceOrTerritoryError: errorText });
                break;

            case 'street':
                errorText = value === '' ? 'Required Field' : '';
                this.setState({ streetError: errorText });
                break;
            default:
                break;
        }
    };

    handleBlur = (e) => {
        this.handleValidations(e.target.name, e.target.value);
    };

    handleChange(e, name) {
        if (name === '') {
            const { name, value } = e.target;
            this.setState({ [name]: value ,freetrialCode : false});
            this.handleValidations(name, value);
        } else {
            this.setState({ [name]: e });
            this.handleValidations(name, e);
        }
        this.state.couponCheck = false;

    }

    handleCountry = function (val) {
        let showAddressFields = [];
        this.setState({
            addressError: '',
            cityError: '',
            stateError: '',
            zipError: '',
            streetError: '',
            provinceOrTerritoryError: '',
            postalCodeError: '',
        });
        addressArray = [];
        dropDownArr.map((item) => {
            if (item.value === val) {
                showAddressFields = item.addressFields;
            }
        });
        addressArray.push(showAddressFields);
        this.setState({ country_code: val });
    };

    handleState = function (val) {
        this.setState({ state: val });
    };

    getState = function (val) {
        let res = '';
        US_states.forEach((item) => {
            if (item.value === val) {
                res = item.label;
            }
        });
        return res;
    };

    validateForm = () => {
        let errorArr = 0;
        let {
            first_name,
            last_name,
            email,
            card,
            expiry,
            securityCode,
            exp_month,
            exp_year,
            mobile,
            city,
            zip,
            street,
            address,
            provinceOrTerritory,
            postalCode,
            country_code,
        } = this.state;
        if (first_name === '') {
            this.setState({ firstNameError: 'Required Field' });
            errorArr = errorArr + 1;
        } else if (first_name[0] === ' ') {
            this.setState({ firstNameError: 'First name should not start with whitespace character' });
            errorArr = errorArr + 1;
        } else if(!first_name.replace(/\s/g, '').length) {
            this.setState({ firstNameError: 'Invalid name!' });
            errorArr = errorArr + 1;
        }

        if (!mobile) {
            this.setState({ mobileError: 'Required Field' });
            errorArr = errorArr + 1;
        }

        if (!city) {
            this.setState({ cityError: 'Required Field' });
            errorArr = errorArr + 1;
        }

        if (country_code == 'US' && !zip) {
            this.setState({ zipError: 'Required Field' });
            errorArr = errorArr + 1;
        }
        if (country_code == 'US' && !address) {
            this.setState({ addressError: 'Required Field' });
            errorArr = errorArr + 1;
        }

        if (country_code == 'CA' && !street) {
            this.setState({ streetError: 'Required Field' });
            errorArr = errorArr + 1;
        }

        if (country_code == 'CA' && !provinceOrTerritory) {
            this.setState({ provinceOrTerritoryError: 'Required Field' });
            errorArr = errorArr + 1;
        }

        if (country_code == 'CA' && !postalCode) {
            this.setState({ postalCodeError: 'Required Field' });
            errorArr = errorArr + 1;
        }

        if (last_name === '') {
            this.setState({ lastNameError: 'Required Field' });
            errorArr = errorArr + 1;
        }
        if (email === '') {
            this.setState({ emailError: 'Required Field' });
            errorArr = errorArr + 1;
        }
        if (card === '') {
            this.setState({ cardError: 'Required Field' });
            errorArr = errorArr + 1;
        }
        if (exp_month === '') {
            this.setState({ expiryMonthError: 'Required Field' });
            errorArr = errorArr + 1;
        }
        if (exp_year === '') {
            this.setState({ expiryYearCodeError: 'Required Field' });
            errorArr = errorArr + 1;
        }
        if (securityCode === '') {
            this.setState({ securityCodeError: 'Required Field' });
            errorArr = errorArr + 1;
        }
        if (exp_month !== '' || exp_year !== '') {
            let errorText = stripeCardExpirValidation(`${this.state.exp_month}/${this.state.exp_year}`);
            this.setState({ expiryMonthError: errorText });
        }

        return errorArr;
    };

    timeOutFunction = () => {
        setTimeout(() => {
            this.setState({ messageModal: false, error: '' });
        }, 5000);
    };

    handleSave = (e) => {
        let errorCount = this.validateForm();
        var postdata1 = {};
        var postdata2 = {};
        let {
            firstNameError,
            lastNameError,
            emailError,
            cardError,
            securityCodeError,
            mobileError,
            cityError,
            streetError,
            zipError,
            expiryMonthError,
            addressError,
            provinceOrTerritoryError,
            postalCodeError,
            contractApplied,
        } = this.state;
        if (
            firstNameError === '' &&
            lastNameError === '' &&
            emailError === '' &&
            cardError === '' &&
            securityCodeError === '' &&
            mobileError === '' &&
            !cityError &&
            !streetError &&
            !zipError &&
            !addressError &&
            !provinceOrTerritoryError &&
            !postalCodeError &&
            !expiryMonthError &&
            errorCount == 0
        ) {
            this.setState({ LoaderIs: true });
            postdata1 = {
                customer: {
                    display_name: this.state.first_name + ' ' + this.state.last_name,
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                    email: this.state.email,
                    company_name: '',
                    phone: this.state.mobile,
                    mobile: this.state.mobile,
                    website: '',
                    billing_address: {
                        street: this.state.country_code == 'US' ? this.state.address : this.state.street,
                        city: this.state.city,
                        state: this.state.country_code == 'US' ? this.state.state : this.state.provinceOrTerritory,
                        zip: this.state.country_code == 'US' ? this.state.zip : this.state.postalCode,
                        country: this.state.country_code,
                        fax: '',
                    },
                    shipping_address: {
                        street: this.state.country_code == 'US' ? this.state.address : this.state.street,
                        city: this.state.city,
                        state: this.state.country_code == 'US' ? this.state.state : this.state.provinceOrTerritory,
                        zip: this.state.country_code == 'US' ? this.state.zip : this.state.postalCode,
                        country: this.state.country_code,
                        fax: '',
                    },
                    currency_code: 'USD',
                    notes: '',
                    //payment_terms: 0,
                    //payment_terms_label: 'Due on Receipt',
                    ach_supported: false,
                },
                plan: this.state.plans,
                card: {
                    card_number: this.state.card,
                    cvv_number: this.state.securityCode,
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                    expiry_month: this.state.exp_month,
                    expiry_year: this.state.exp_year,
                    street: this.state.country_code == 'US' ? this.state.address : this.state.street,
                    city: this.state.city,
                    state: this.state.country_code == 'US' ? this.state.state : this.state.provinceOrTerritory,
                    country: this.state.country_code,
                    zip: this.state.country_code == 'CA' ? this.state.postalCode : this.state.zip,
                    payment_gateway: 'authorize_net',
                },
                coupon_code: contractApplied ? '' : this.state.discountCode,
                starts_at: this.state.starts_at,
                //exchange_rate: 2,
                //payment_terms: 0,
                //payment_terms_label: 'Due on Receipt',
                //salesperson_name: 'Miss.Susan Mackezie',
                //reference_id: '',
                auto_collect: true,
                payment_gateways: [
                    {
                        payment_gateway: 'authorize_net',
                    },
                ],
            };
         
            createNewSubscription(postdata1)
                .then((resp) => {
                    if (resp.data.message) {
                        this.setState({ messageModal: true, error: resp.data.message, LoaderIs: false }, () =>
                            this.timeOutFunction(),
                        );
                    }

                    /* if (resp.data.code == 0) {
                    setTimeout(() => {
                       // this.props.history.push('/home');
                    }, 3000);
                } */

                    this.setState({ loading: false });

                    if (resp.stats === true && resp.data.subscription) {
                        let _data = resp.data;
                            if (resp.data.code != 3062) {
                                postdata2 = {
                                    subscription_id: resp.data.subscription.subscription_id,
                                    logged_in_user_id: this.state.user_id,
                                    status: resp.data.subscription.status,
                                    amount: resp.data.subscription.amount,
                                    product_id: resp.data.subscription.product_id,
                                    product_name: resp.data.subscription.product_name,
                                    plan_code: this.state.plans.plan_code,
                                };
                                // if(contractApplied) {
                                //     postdata2.is_contract = true
                                // }
                                saveSubscription(postdata2)
                                    .then((resp2) => {
                                        this.setState({ loading: false });
                                        if (
                                            resp2.data.status === 'trial' ||
                                            resp2.data.status === 'live' ||
                                            resp2.data.status === 'future'
                                        ) {

                                            let options = {
                                                event_type: 'subscription_created',
                                                data: _data
                                            }

                                            notifySubscription(options)
                                                .then(result => {
                                                    getUserSubscription().then((resp) => {
                                                        localStorage.setItem('resp', JSON.stringify(resp.data));
                                                        this.props.history.push('/home');
                                                    });
                                                });
                                        } else {
                                           
                                        }
                                    })
                                    .catch((err) => {
                                        this.setState({ LoaderIs: false, loading: false });
                                        this.setModal2Visible(true);
                                    });
                            }
                            //   else{

                            //     getUserSubscription().then((resp) => {
                            //         if(resp){
                            //            localStorage.setItem("resp", JSON.stringify(resp.data));
                            //            this.props.history.push('/home');
                            //         }
                            //     });

                            //   }
                    }
                })
                .catch((err) => {
                    this.setState({ LoaderIs: false, loading: false });
                    this.setModal2Visible(true);
                });
        } else {
            
        }
    };

    getUserMe() {
        let access_token = '';
        if (checkCookie() !== null) {
            access_token = checkCookie();
        }
        this.props.dispatch(getMeUserAction({ token: access_token }));
    }

    checkAppliedCode = () => {
        const { plans } = this.state;
        this.setState({contractApplied : false,freetrialCode : false})
        if (this.state.allCoupons) {
            if (
                this.state.allCoupons.some(
                    (coupen) => coupen.status == 'active' && coupen.coupon_code == this.state.discountCode,
                )
            ) {
                let findDiscountObj = this.state.allCoupons.find(
                    (coupen) => coupen.coupon_code == this.state.discountCode,
                );
                if(findDiscountObj && findDiscountObj.hasOwnProperty('free_trial') && findDiscountObj.free_trial){
                    this.setState({freetrialCode : true,discountDeductValue: 0,couponCheck: 'valid'})
                } else if(findDiscountObj && findDiscountObj.hasOwnProperty('free_trial_six') && findDiscountObj.free_trial_six){
                    this.setState({freetrialCode : true,discountDeductValue: 0,couponCheck: 'valid'})
                } else if(findDiscountObj && findDiscountObj.hasOwnProperty('is_contract') && findDiscountObj.is_contract) {
                      this.setState({contractApplied : true,freetrialCode : true,discountDeductValue: 0,couponCheck: 'valid'})
                } else if(findDiscountObj && findDiscountObj.hasOwnProperty('is_contract_invitation') && findDiscountObj.is_contract_invitation) {
                      this.setState({freetrialCode : true,discountDeductValue: 0,couponCheck: 'valid'})
                }  
                 else {
                    let discountDeductValue = findDiscountObj.discount_value;
                    if(findDiscountObj.discount_by == "percentage"){
                        if(typeof plans === 'object'){
                            discountDeductValue = plans.price*(discountDeductValue/100);
                        }
                    }
                    this.setState({ couponCheck: 'valid', discountDeductValue: discountDeductValue });
                }
                
            } else {
                this.setState({ couponCheck: 'invalid', discountDeductValue: 0, discountCode: '' });
            }
        }
    };

    checkFreeTrial = () => {
        const { plans, email,contractApplied } = this.state;
        let flag = {is_6month : false};
        if (this.state.allCoupons) {
            if (
                this.state.allCoupons.some(
                    (coupen) => coupen.status == 'active' && coupen.coupon_code == this.state.discountCode,
                )
            ) {
                let findDiscountObj = this.state.allCoupons.find(
                    (coupen) => coupen.coupon_code == this.state.discountCode,
                );
                if(findDiscountObj && findDiscountObj.hasOwnProperty('free_trial_six') && findDiscountObj.free_trial_six){
                    flag = {is_6month : true};
                } else if(findDiscountObj && findDiscountObj.hasOwnProperty('is_contract_invitation') && findDiscountObj.is_contract_invitation){
                    flag = {is_contract_invitation : true,contract_user_id : findDiscountObj.user_id,email : email};
                } else if(contractApplied){
                     flag = {contractApplied : true};
                }
            }
        }
        return flag;
    }

    handleFreeTrialSave = (e) => {
        this.setState({ LoaderIs: true});
        const input = this.checkFreeTrial();
        updateFreeTrial(input)
                .then((resp) => {
                    if(resp) {
                        if (resp.updated) {
                            this.setState({ LoaderIs: false, loading: false });
                            this.props.history.push('/home');
                        } else {
                            this.setState({ LoaderIs: false, loading: false });
                            this.setState({ messageModal: true, error: "Something went wrong !!!", LoaderIs: false }, () =>
                                this.timeOutFunction(),
                            );
                        } 
                    } else {
                            this.setState({ LoaderIs: false, loading: false });
                            this.setModal2Visible(true);
                        }
                    
                }).catch((err) => {
                        this.setState({ LoaderIs: false, loading: false });
                        this.setModal2Visible(true);
                    });
    
    }

    expiryMonthYearChangeHandler = (event, key) => {
        let { expiryYearCodeError, expiryMonthError } = this.state;
        if (key == 'exp_year') {
            expiryYearCodeError = '';
        } else if (key == 'exp_month') {
            expiryMonthError = '';
        }
        this.setState({ [key]: event, expiryYearCodeError, expiryMonthError }, () => {
            if (this.state.exp_month !== '' && this.state.exp_year !== '') {
                let errorText = stripeCardExpirValidation(`${this.state.exp_month}/${this.state.exp_year}`);
                this.setState({ expiryMonthError: errorText });
            }
        });
    };

    render() {
        const {
            plans,
            exp_year,
            contractApplied
        } = this.state;

        
        addressArray = [];

        let showAddressFields = [];
        dropDownArr.map((item) => {
            if (item.value === this.state.country_code) {
                showAddressFields = item.addressFields;
            }
        });

        addressArray.push(showAddressFields);
        
        function checkLabel(planData) {
            if(planData.interval_unit == 'months') {
                    return 'month'
            } else if(planData.interval_unit == 'years') {
                    return 'year'
                
            }

        }
 
        return (
            <div className="sub-wrapper">
                <div className="logo">
                    <div className="logo-wrap">
                        <Link to="/home">
                            <img src={logo} alt="kincrew-logo" />
                            <span className="logo-item">kincrew</span>
                        </Link>
                    </div>
                </div>
                <LoadingOverlay
                    active={this.state.LoaderIs ? true : false}
                    className={'loader-wrap ' + (this.state.LoaderIs ? 'd-block' : '')}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                >
                    <Card bordered={false} className="sub-card req-card">
                        {(this.state.error || this.state.message) && (
                            <Modal
                                centered
                                visible={true}
                                backdrop="static"
                                maskClosable={false}
                                className="modal-container req-card"
                                // onCancel={() => this.handleErrorModal(false)}
                            >
                                <div className="modal-body">
                                    <h5>{`${this.state.error || this.state.message}`}</h5>
                                </div>
                            </Modal>
                        )}
                        <div className="sub-wrap">
                            <div className="sub-card-head detail-head">
                                <h1>Subscription Order Summary</h1>
                            </div>

                            <Card className="offer-card">
                                <div className="offer-card-head">
                                    <div className="head-wrap">
                                        <span>Selected Subscription</span>
                                        <span>Quantity</span>
                                        <span>Billed Rate</span>
                                    </div>
                                </div>
                                <div className="body">
                                    <div className="item">
                                        <div>{typeof plans === 'object' ? plans.plan_name : ''}</div>
                                        <div>
                                            <span>
                                                <img src={exclaim} alt="exclaim-logo" />
                                            </span>
                                            <span>
                                                Every {typeof plans === 'object' ? plans.interval : ''} {checkLabel(typeof plans === 'object' && plans)}(s).
                                            </span>
                                        </div>
                                        <div>{typeof plans === 'object' ? plans.plan_description : ''}</div>
                                    </div>
                                    <div className="quantity">{typeof plans === 'object' ? plans.quantity : '0'}</div>
                                    <div className="rate">${typeof plans === 'object' ? plans.price : ''}</div>
                                </div>
                                <div className="discount">
                                    <div className="d-label">Discount Code:</div>
                                    <div className="d-input">
                                        <Input
                                            name="discountCode"
                                            onChange={(e) => {
                                                this.handleChange(e, '');
                                            }}
                                        />
                                    </div>
                                    <div className="btn-apply">
                                        <Button
                                            onClick={() => this.checkAppliedCode()}
                                            style={
                                                !this.state.couponCheck
                                                    ? {
                                                          backgroundColor: 'white',
                                                          color: 'black',
                                                          border: '1px solid grey',
                                                      }
                                                    : {}
                                            }
                                            disabled={!this.state.discountCode}
                                        >
                                            {this.state.couponCheck === 'valid' ? 'Applied' : 'Apply'}
                                        </Button>
                                    </div>
                                    {/* {this.state.couponCheck == 'valid' ? (
                                        <div className="coupon-success success-msg">
                                            Successfully Applied. We will start billing your account
                                        </div>
                                    ) : this.state.couponCheck == 'invalid' ? (
                                        <div style={{ color: 'red' }} className="coupon-error success-msg">
                                            Opps! There is a invalid code please try again.
                                        </div>
                                    ) : (
                                        ''
                                    )} */}
                                    {/* <span className="success-msg">
                                Successfully applied {typeof plans ==="object" ?  plans.trial_days : 0}days free trial. We will start billing your account after {typeof plans ==="object" ?  plans.trial_days : 0}
                                days.
                            </span> */}
                                </div>
                                {(this.state.couponCheck == 'valid' && !this.state.freetrialCode && !contractApplied) ? (
                                    <div className="coupon-success success-msg">
                                        Successfully applied. We will start billing your account
                                    </div>
                                ) : this.state.couponCheck == 'invalid' ? (
                                    <div style={{ color: 'red' }} className="coupon-error success-msg">
                                        Oops! This is an invalid code. Please try again.
                                    </div>
                                ) : this.state.freetrialCode && !contractApplied ? (
                                     <div className="coupon-success success-msg">
                                        Successfully applied. You can start your free trial
                                    </div>
                                ) : (this.state.couponCheck == 'valid' && this.state.contractApplied ) ? (
                                    <div className="coupon-success success-msg">
                                        Successfully applied. You can start your contract period.
                                    </div>
                                ):

                                 (
                                    ''
                                )}
                                {!this.state.freetrialCode ? (
                                <div className="card-bottom">
                                    <span>Total</span>
                                    <span>
                                        ${typeof plans === 'object' ? plans.price - this.state.discountDeductValue : ''}
                                    </span>
                                </div>
                                ) : null}
                                {this.state.freetrialCode ? (
                                     <div className="card-bottom">
                                        <span className = "free-trial-btn-wrap"><Button onClick={this.handleFreeTrialSave} className = "fp-button-primary free-trial-home-btn">
                                            Go To Home
                                        </Button></span>
                                </div>
                                ) : null}
                               
                            </Card>
                            {!this.state.freetrialCode ? (
                                 <Card className="form-card">
                                <div className="field-wrap">
                                    <span className="field-head">Account Information</span>
                                    <div className="input-group input-sm ">
                                        <div className="mr-5 input-50">
                                            <Input
                                                placeholder="First Name"
                                                onBlur={this.handleBlur}
                                                onChange={(e) => {
                                                    this.handleChange(e, '');
                                                }}
                                                name="first_name"
                                                value={this.state.first_name}
                                                className="w-100"
                                            />
                                            {this.state.firstNameError ? (
                                                <div className="sub-form-err">{this.state.firstNameError}</div>
                                            ) : null}
                                        </div>
                                        <div className="ml-5 input-50">
                                            <Input
                                                placeholder="Last Name"
                                                onBlur={this.handleBlur}
                                                onChange={(e) => {
                                                    this.handleChange(e, '');
                                                }}
                                                name="last_name"
                                                value={this.state.last_name}
                                                className="w-100"
                                            />
                                            {this.state.lastNameError ? (
                                                <div className="sub-form-err">{this.state.lastNameError}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="input-sm">
                                        <Input
                                            placeholder="Email"
                                            name="email"
                                            onChange={(e) => {
                                                this.handleChange(e, '');
                                            }}
                                            onBlur={this.handleBlur}
                                            value={this.state.email}
                                        />
                                    </div>
                                    {this.state.emailError ? (
                                        <div className="sub-form-err">{this.state.emailError}</div>
                                    ) : null}
                                    <div className="input-sm">
                                        <Input
                                            placeholder="Mobile"
                                            name="mobile"
                                            //pattern="\d{3}[\-]\d{3}[\-]\d{4}"
                                            required
                                            onBlur={this.handleBlur}
                                            onChange={(e) => {
                                                this.handleChange(e, '');
                                            }}
                                            value={this.state.mobile}
                                        />
                                    </div>
                                    {this.state.mobileError ? (
                                        <div className="sub-form-err">{this.state.mobileError}</div>
                                    ) : null}
                                </div>
                                <div className="field-wrap">
                                    <span className="field-head">Billing Address</span>
                                    <div className="input-sm">
                                        <Select
                                            style={{ width: '100%' }}
                                            name="country_code"
                                            defaultValue="United States"
                                            value={this.state.country_code === 'US' ? '+1 United States' : '+1 Canada'}
                                            onChange={this.handleCountry}
                                            className="slt-2-box"
                                        >
                                            {dropDownArr.map((obj, index) => (
                                                <option
                                                    selected={this.state.country_code === obj.value}
                                                    value={obj.value}
                                                    key={index}
                                                    className={
                                                        this.state.country_code === obj.value ? 'text-color' : ''
                                                    }
                                                >
                                                    {obj.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="input-group input-sm">
                                        {addressArray[0].includes('state') && (
                                            <div className="w-50 mr-5">
                                                <Select
                                                    //defaultValue="AL"
                                                    // style={{ width: '50%', }}
                                                    name="state"
                                                    defaultValue="AL"
                                                    value={this.getState(this.state.state)}
                                                    onChange={this.handleState}
                                                    className="slt-2-box w-100"
                                                >
                                                    {US_states.map((obj, index) => (
                                                        <option
                                                            selected={this.state.state === obj.value}
                                                            value={obj.value}
                                                            key={index}
                                                            className={
                                                                this.state.state === obj.value ? 'text-color' : ''
                                                            }
                                                        >
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </div>
                                        )}
                                        {addressArray[0].includes('provinceOrTerritory') && (
                                            <div className="w-50 mr-5">
                                                <Input
                                                    name="provinceOrTerritory"
                                                    className="w-100"
                                                    placeholder="Province/Territory"
                                                    value={this.state.provinceOrTerritory}
                                                    onChange={(e) => {
                                                        this.handleChange(e, '');
                                                    }}
                                                    autoComplete="nope"
                                                />
                                                {this.state.provinceOrTerritoryError ? (
                                                    <div className="sub-form-err">
                                                        {this.state.provinceOrTerritoryError}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                        <div className="w-50 ml-5">
                                            <Input
                                                name="city"
                                                className="w-100"
                                                placeholder="City"
                                                value={this.state.city}
                                                onChange={(e) => {
                                                    this.handleChange(e, '');
                                                }}
                                                autoComplete="nope"
                                            />
                                            {this.state.cityError ? (
                                                <div className="sub-form-err">{this.state.cityError}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="input-group input-sm">
                                        {addressArray[0].includes('street') && (
                                            <div className="mr-5 w-50">
                                                <Input
                                                    placeholder="Street"
                                                    className="w-100"
                                                    name="street"
                                                    onChange={(e) => {
                                                        this.handleChange(e, '');
                                                    }}
                                                />
                                                {this.state.streetError ? (
                                                    <div className="sub-form-err">{this.state.streetError}</div>
                                                ) : null}
                                            </div>
                                        )}
                                        {addressArray[0].includes('zip') && (
                                            <div className="mr-5 w-50">
                                                <Input
                                                    placeholder="Zip"
                                                    name="zip"
                                                    className="w-100"
                                                    onChange={(e) => {
                                                        this.handleChange(e, '');
                                                    }}
                                                />
                                                {this.state.zipError ? (
                                                    <div className="sub-form-err">{this.state.zipError}</div>
                                                ) : null}
                                            </div>
                                        )}
                                        {addressArray[0].includes('postalCode') && (
                                            <div className="ml-5 w-50">
                                                <Input
                                                    placeholder="Postal Code"
                                                    name="postalCode"
                                                    className="w-100"
                                                    onChange={(e) => {
                                                        this.handleChange(e, '');
                                                    }}
                                                />
                                                {this.state.postalCodeError ? (
                                                    <div className="sub-form-err">{this.state.postalCodeError}</div>
                                                ) : null}
                                            </div>
                                        )}
                                        {addressArray[0].includes('address') && (
                                            <div className="ml-5 w-50">
                                                <Input
                                                    placeholder="Address"
                                                    name="address"
                                                    className="w-100"
                                                    onChange={(e) => {
                                                        this.handleChange(e, '');
                                                    }}
                                                />
                                                {this.state.addressError ? (
                                                    <div className="sub-form-err">{this.state.addressError}</div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="field-wrap">
                                    <span className="field-head">Payment Information</span>
                                    <div className="input-sm">
                                        <Input
                                            placeholder="Card Number"
                                            name="card"
                                            required
                                            onChange={(e) => {
                                                this.handleChange(e, '');
                                            }}
                                            onBlur={this.handleBlur}
                                        />
                                        {this.state.card_type}
                                        {this.state.cardError ? (
                                            <div className="sub-form-err">{this.state.cardError}</div>
                                        ) : null}
                                    </div>

                                    <div className="input-group-sm input-sm">
                                        <React.Fragment>
                                            <div className="mr-5 bottom-input">
                                                <Select
                                                    className="slt-2-box"
                                                    placeholder="Expiry Month"
                                                    name="exp_month"
                                                    onChange={(e) => this.expiryMonthYearChangeHandler(e, 'exp_month')}
                                                >
                                                    <option selected> Expiry Month </option>
                                                    <option value="01"> 01 </option>
                                                    <option value="02"> 02 </option>
                                                    <option value="03"> 03 </option>
                                                    <option value="04"> 04 </option>
                                                    <option value="05"> 05 </option>
                                                    <option value="06"> 06 </option>
                                                    <option value="07"> 07 </option>
                                                    <option value="08"> 08 </option>
                                                    <option value="09"> 09 </option>
                                                    <option value="10"> 10 </option>
                                                    <option value="11"> 11 </option>
                                                    <option value="12"> 12 </option>
                                                </Select>
                                                {this.state.expiryMonthError ? (
                                                    <div className="sub-form-err">{this.state.expiryMonthError}</div>
                                                ) : null}
                                            </div>
                                            <div className="mr-5 bottom-input">
                                                <Select
                                                    className="slt-2-box"
                                                    placeholder="Expiry Year"
                                                    name="exp_year"
                                                    onChange={(e) => this.expiryMonthYearChangeHandler(e, 'exp_year')}
                                                >
                                                    {years.map((year, index) => {
                                                        return (
                                                            !(year < new Date().getFullYear()) && (
                                                                <option key={`year${index}`} value={year}>
                                                                {year}
                                                                </option>
                                                            )
                                                        );
                                                        
                                                    })}
                                                </Select>
                                                {this.state.expiryYearCodeError ? (
                                                    <div className="sub-form-err">{this.state.expiryYearCodeError}</div>
                                                ) : null}
                                            </div>
                                        </React.Fragment>
                                        <div className="bottom-input">
                                            <Input
                                                name="securityCode"
                                                type="text"
                                                pattern="\d{3,4}"
                                                placeholder="CVC"
                                                minLength="3"
                                                maxLength="3"
                                                onChange={(e) => {
                                                    this.handleChange(e, '');
                                                }}
                                                onBlur={this.handleBlur}
                                                format={this.formatCVC}
                                            />
                                            {this.state.securityCodeError ? (
                                                <div className="sub-form-err">{this.state.securityCodeError}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-btn">
                                    <Button onClick={this.handleSave} disabled={this.submitting}>
                                        Save
                                    </Button>
                                </div>
                            </Card>
                            ) : ''}
                           
                        </div>
                        <ConnectionErrorModal
                            handleOpen={this.state.modal2Visible}
                            handleClose={() => this.setModal2Visible(false)}
                        />
                    </Card>
                    {/* <SubscriptionErrorModal 
                openModal={this.state.LoaderIs} 
                closeModal={() => this.handleClose()} 
                msg={this.state.error}
            /> */}
                </LoadingOverlay>
                {/* {
                    this.state.messageModal &&
                    <div className="messageRight">
                        <p className={this.state.message ? "bg-success" : "bg-danger"}>{this.state.message ? this.state.message : this.state.error}</p>
                    </div>
                } */}
            </div>
        );
    }
}

const mapStateToProps = (response) => ({ response });
export default connect(mapStateToProps)(SubscriptionDetails);
