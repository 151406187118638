import React, { Component } from 'react';
import 'pages/CalenderInner/Swap/Swap.scss';
import { Card, Button, Modal, Form, Input, Select, Avatar, Empty } from 'antd';
import add from 'Assets/star.svg';
import swap from 'Assets/swap.svg';
import right from 'Assets/right-arrow.png';
import TextArea from 'antd/lib/input/TextArea';
import user from 'Assets/avatar.jpg';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { getAllSwaps } from 'services/calendarService';

import CreateSwap from 'pages/CalenderInner/Swap/CreateSwap';
import SwapInvite from 'pages/CalenderInner/Swap/SwapInvite';

import CreateException from 'pages/CalenderInner/Swap/CreateException';
import EditException from 'pages/CalenderInner/Swap/EditException';
import EditSwap from 'pages/CalenderInner/Swap/EditSwap';
import SwapDetailsModal from 'pages/CalenderInner/Swap/SwapDetailsModal';
import ExceptionDetailsModal from 'pages/CalenderInner/Swap/ExceptionDetailsModal';
import UpdateExceptionModal from 'pages/CalenderInner/Swap/UpdateExceptionModal';

const frequency = {
    yearly: '1 Year',
    weekly: '1 Week',
    monthly: '1 Month',
    six_months: '6 Month',
    onetime: 'Onetime',
};

const classes = {
    pending: 'Pending',
    active: 'Approved',
    declined: 'Rejected',
};

const colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    'gradient1': 'Gradient1',
    'gradient2': 'Gradient2',
};

class Swap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            modal2Visible: false,
            modal3Visible: false,
            modal4Visible: false,
            modal5Visible: false,
            modal6Visible: false,
            modal7Visible: false,
            modal8Visible: false,
            results: [],
            offset: 0,
            coparent: null,
            loading: false,
            swap: null,
            exception: null,
            currentException: null,
            currentSwap: null,
        };
        this.getName = this.getName.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.getDates = this.getDates.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.setModal3Visible = this.setModal3Visible.bind(this);
        this.setModal4Visible = this.setModal4Visible.bind(this);
        this.setModal5Visible = this.setModal5Visible.bind(this);
        this.setModal6Visible = this.setModal6Visible.bind(this);
        this.setModal7Visible = this.setModal7Visible.bind(this);
        this.setModal8Visible = this.setModal8Visible.bind(this);
        this.getCalendarSwaps = this.getCalendarSwaps.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
    }

    componentDidMount() {
        let offset = 0;
        this.getCalendarSwaps(offset, this.props.coparent, true);
    }

    componentDidUpdate(prevProps) {
        if (this.props.coparent !== prevProps.coparent) {
            let offset = 0;
            let arr = [];
            this.setState({ swaps: arr });
            this.getCalendarSwaps(offset, this.props.coparent, true);
        }
    }

    refreshPage = function () {
        let offset = 0;
        let arr = [];
        this.setState({ swaps: arr });
        this.getCalendarSwaps(offset, this.props.coparent, true);
    };

    handleScroll = function (e) {
        const { offset, coparent } = this.state;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this.getCalendarSwaps(offset, coparent, false);
        }
    };

    setModalVisible = function (visible) {
        this.setState({ modalVisible: visible });
    };
    setModal2Visible = function (modal2Visible, swap = null) {
        this.setState({ modal2Visible, currentSwap: swap });
    };

    setModal3Visible = function (modal3Visible, exception = null) {
        this.setState({ modal3Visible, exception });
    };

    setModal4Visible = function (visible, item) {
        this.setState({ modal4Visible: visible, currentException: item });
    };

    setModal5Visible = async function (visible, item) {
        await this.setState({ modal5Visible: visible, currentSwap: item });
    };

    setModal6Visible = async function (visible, item) {
        await this.setState({ modal6Visible: visible, currentSwap: item });
    };

    setModal7Visible = async function (visible, item) {
        await this.setState({ modal7Visible: visible, currentException: item });
    };

    setModal8Visible = async function (visible, item) {
        await this.setState({ modal8Visible: visible, currentException: item });
    };

    formatData(temp) {
        let data = [];
        const { coparents, user } = this.props;
        if (Array.isArray(temp) && temp.length !== 0) {
            for (let i = 0; i < temp.length; i++) {
                let item = temp[i];
                /*let creator = '';
                if(item.created_by_user == user.id){
                    creator = user.first_name;
                }else{
                    let parent = coparents.filter((value)=> value.id == item.created_by_user)
                    if(parent[0]){
                       creator = parent[0].name; 
                    }
                }*/
                let memberInfo = item.Members ? item.Members : [];
                let child = {};
                if (memberInfo && memberInfo[0]) {
                    child = memberInfo[0];
                }
                let entry = {
                    isSwap: item.dates ? true : false,
                    isException: item.type == 'special_date' ? true : false,
                    title: item.title,
                    status: item.status,
                    created_by_user: item.created_by_user,
                    coparent_id: item.coparent_id,
                    start_date: item.start_date,
                    exp_date: item.exp_date,
                    frequency: item.frequency,
                    child_name: child.name ? child.name : '',
                    forfeit_dates: item.dates,
                    trade_dates: item.trade_dates,
                    id: item.id,
                    updated_at: item.updated_at,
                    //creator
                };

                if (item.Members[0] && item.Members[0].Users[0]) {
                    entry.Members = item.Members;
                }
                data.push(entry);
            }
        }
        return data;
    }

    getCalendarSwaps = function (offset, coparent, flag) {
        const { results } = this.state;
        this.setState({ loading: true });
        const params = {
            offset: offset,
            limit: 10,
            coparent_id: (coparent && coparent.id) || null,
        };

        this.setState({ offset: offset, coparent: coparent || null });
        getAllSwaps(params).then((resp) => {
            if (resp) {
                resp.offset += 10;
                this.setState({ offset: resp.offset, coparent: { id: resp.coparent } });

                if (flag) {
                    let arr = [];
                    arr = [...arr, ...this.formatData(resp.result)];
                    this.setState({ results: arr, loading: false });
                } else {
                    let arr = [];
                    arr = [...results, ...this.formatData(resp.result)];
                    this.setState({ results: arr, loading: false });
                }
            }
            this.setState({ loading: false });
        }).catch(err => {
            this.setState({ loading: false });
        })
    };

    getDates = function (swap) {
        const swapPrefix = swap.trade_dates && swap.trade_dates.length ? 'Swap ' : '';
        const swapDates =
            swap.forfeit_dates && swap.forfeit_dates.length
                ? `${swapPrefix}${[]
                    .concat(swap.forfeit_dates)
                    .splice(0, 2)
                    .map((d) => Moment(d, 'YYYY-MM-DD').format('M/D/YY'))
                    .join(', ')}`
                : '';
        const trade_dates =
            swap.trade_dates && swap.trade_dates.length
                ? `for ${[]
                    .concat(swap.trade_dates)
                    .splice(0, 2)
                    .map((d) => Moment(d, 'YYYY-MM-DD').format('M/D/YY'))
                    .join(', ')}`
                : '';

        let res = '';
        if (trade_dates === '') {
            return swapDates;
        } else {
            res += swapDates + ' ' + trade_dates;
            return res;
        }
    };

    getName = function (swap) {
        const { user } = this.props;
        if (swap && swap.Members && swap.Members[0] && swap.Members[0].Users[0]) {
            if (user && user.id === swap.created_by_user) {
                return user.first_name;
            } else {
                if (swap.created_by_user === swap.Members[0].Users[0].id) {
                    return swap.Members[0].Users[0].first_name;
                }
            }
        }
    };

    handleClick = function (item) {
        const { user } = this.props;
        if (item.isSwap) {
            if (user && user.id === item.created_by_user) {
                if (item.status !== 'active' && item.status !== 'declined') {
                    this.setModal5Visible(true, item);
                } else {
                    this.setModal6Visible(true, item);
                }
            } else if (user && user.id === item.coparent_id) {
                if (item.status !== 'active' && item.status !== 'declined') {
                    this.setModal2Visible(true, item);
                } else {
                    this.setModal6Visible(true, item);
                }
            }
        } else if (item.isException) {
            if (user && user.id === item.created_by_user) {
                if (item.status !== 'active' && item.status !== 'declined') {
                    this.setModal4Visible(true, item);
                } else {
                    this.setModal7Visible(true, item);
                }
            } else if (user && user.id === item.coparent_id) {
                if (item.status !== 'active' && item.status !== 'declined') {
                    this.setModal8Visible(true, item);
                } else {
                    this.setModal7Visible(true, item);
                }
            }
        }
    };

    getExpireDuration = (expire) => {
        let expires = '';
        const expireDate = Moment(new Date(expire)).zone(0).format('YYYY-MM-DD');
        const currentDate = Moment(new Date()).format('YYYY-MM-DD');
        if (!expire || Moment(currentDate).isAfter(expireDate)) {
            expires = 'Expired';
        } else {
            expires = Moment.duration(
                Moment(expireDate).valueOf() - Moment(currentDate).valueOf(),
                'milliseconds',
            ).humanize(true);
        }

        return expires;
    };

    render() {
        const { Option } = Select;
        const { user } = this.props;
        const { loading, results, currentSwap } = this.state;

        function getColor(item) {
            return (item.created_by_user === (user && user.id)) ? item.color : item.coparent_color;
        }
        return (
            <Card
                bordered={false}
                // title="Swap/Special Dates"
                //onClick={() => this.setModal2Visible(true)}
                className="swap swap-spcl-dates"
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <div className="custom-card-btn-head">
                    <div>
                        <span className="title">Special Dates</span>
                        <Button type="primary" className="fp-button-secondary swap-create" onClick={() => this.setModal3Visible(true)}>
                            <span>Create</span>
                            <span>
                                <img src={add} alt="add-icon" />
                            </span>
                        </Button>
                    </div>
                    {/* <div className="custom-head-title">
                        <span>Swap/Special Dates</span>
                    </div> */}
                    <div>
                        <span className="title" style={{ textAlign: 'right' }}>Swap</span>
                        <Button type="primary" className="fp-button-secondary swap-produce" onClick={() => this.setModalVisible(true)}>
                            <span>Create</span>
                            <span className="swap-icon">
                                <img src={swap} alt="add-icon" />
                            </span>
                        </Button>
                    </div>
                </div>
                <div onScroll={this.handleScroll} className="swap-card-wrap">
                    {Array.isArray(results) && results.length !== 0 ? (
                        results.map((item, i) => (
                            <Card
                                key={i}
                                bordered={false}
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.handleClick(item)}
                                className={item.isSwap ? 'swap-special' : 'swap-data'}
                            >
                                <div className="swap-special-inner">
                                    <div className="card-left">
                                        <div className="title">
                                            <span>{item.title}</span>
                                        </div>
                                        {item.isSwap && (
                                            <div className="repeat">
                                                <span>Posted: {this.getName(item)}</span>
                                            </div>
                                        )}
                                        {item.isException && (
                                            <div className="date">
                                                <span>{Moment.parseZone(item.start_date).format('MMMM DD,YYYY')}</span>
                                            </div>
                                        )}
                                        {item.isException && (
                                            <div className="repeat">
                                                {item.frequency !== 'onetime' && <span>Repeats Every:</span>}
                                                <span>{frequency[item.frequency]}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="card-right">
                                        {item.isException && (
                                            <div className="name exception-kid-color-card">
                                                <span className={"exception-list-dot " + colors[getColor(item.Members[0] && item.Members[0])]}></span>
                                                <span>{item.child_name}</span>
                                            </div>
                                        )}
                                        {item.isSwap && (
                                            <div className="date">
                                                <div className="title">
                                                    <span className={item.status}>
                                                        {classes[item.status] ? classes[item.status] : ''}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        {item.isSwap && (
                                            <div className="status">
                                                <div className="date-wrap">
                                                    <div className="date">
                                                        <span>{this.getDates(item)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="card-bottom">
                                    {item.isSwap && (
                                        <div className="inner">
                                            {item.status != 'active' && (
                                                <div className="state">
                                                    Expires: {this.getExpireDuration(item.exp_date)}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </Card>
                        ))
                    ) : (
                            <div>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        )}
                </div>

                <CreateSwap
                    refresh={() => this.refreshPage()}
                    handleOpen={this.state.modalVisible}
                    handleClose={() => this.setModalVisible(false)}
                />

                <EditSwap
                    refresh={() => this.refreshPage()}
                    currentSwap={currentSwap}
                    handleOpen={this.state.modal5Visible}
                    handleClose={() => this.setModal5Visible(false)}
                />

                <SwapDetailsModal
                    refresh={() => this.refreshPage()}
                    currentSwap={currentSwap}
                    handleOpen={this.state.modal6Visible}
                    handleClose={() => this.setModal6Visible(false)}
                />

                <SwapInvite
                    refresh={() => this.refreshPage()}
                    handleOpen={this.state.modal2Visible}
                    currentSwap={currentSwap}
                    handleClose={() => this.setModal2Visible(false)}
                />

                <CreateException
                    refresh={() => this.refreshPage()}
                    handleOpen={this.state.modal3Visible}
                    swap={this.state.exception}
                    handleClose={() => this.setModal3Visible(false)}
                />
                <EditException
                    refresh={() => this.refreshPage()}
                    handleOpen={this.state.modal4Visible}
                    currentException={this.state.currentException}
                    handleClose={() => this.setModal4Visible(false)}
                />
                <ExceptionDetailsModal
                    refresh={() => this.refreshPage()}
                    handleOpen={this.state.modal7Visible}
                    currentException={this.state.currentException}
                    handleClose={() => this.setModal7Visible(false)}
                />
                <UpdateExceptionModal
                    refresh={() => this.refreshPage()}
                    handleOpen={this.state.modal8Visible}
                    currentException={this.state.currentException}
                    handleClose={() => this.setModal8Visible(false)}
                />
            </Card>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, loadings, error, coparent, coparents } = serviceReducer;
    return { children, user, loadings, error, coparent, coparents };
};

export default connect(mapStateToProps)(Swap);
