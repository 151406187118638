import React, { Component } from 'react';
import { Card, Avatar, Badge, Empty } from 'antd';
import 'pages/Chat/ChatTab/ChatTab.scss';
import { 
    GetOnlineUsersList,
    RetrieveUserChatHistory 
} from 'pages/QuickBlox/qbChat';
import userImg from 'Assets/default.jpg';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { GetChatDetail } from 'services/chatService';
import {updateQBStatus } from 'actions/userActions';

class ChatTab extends Component {
    constructor(props){
        super(props);
        this.state = {
            dialogId: props.dialogId,
            loading: false,
            usersList: [],
            onlineUsersList: [],
            users: props.users || [],
            messages: [],
        };
        this.CallChatDetailApi = this.CallChatDetailApi.bind(this);
        this.FetchQBData = this.FetchQBData.bind(this);
        this.getUsers = this.getUsers.bind(this);
    }

    componentDidMount() {}

    UNSAFE_componentWillReceiveProps = async (nextProps) => {
        if(nextProps && nextProps.dialogId) {
            this.setState({ loading: true, dialogId: nextProps.dialogId });
        } 
        if(nextProps && nextProps.users) {
            if(nextProps.dialogId === null){
                this.setState({ users: nextProps.users, dialogId: nextProps.dialogId });
            }
        }
        if(nextProps.status === true) {
            if(nextProps.dialogId) {
                let users = await GetOnlineUsersList(nextProps.dialogId);
                await this.setState({ onlineUsersList: users, dialogId: nextProps.dialogId });
                this.CallChatDetailApi();
            }  else {
                if(nextProps.users){
                    this.setState({ users: nextProps.users, loading: false });
                } else {
                    this.setState({ onlineUsersList: [], users: [], loading: false });
                }
            }
        } 
        else {
            this.setState({loading : false})
        }

    }
    

    CallChatDetailApi = async () => {
        let userId = await localStorage.getItem('user_id');
        GetChatDetail(this.state.dialogId).then(async (resp) => {
            if (resp && resp.data) {
                let dialogdata = resp.data.Dialogues;
                var archivedtime = resp.data.archived_at;
                let archivedTimestamp = Math.round(new Date(archivedtime).getTime() / 1000);

                let isTopic = dialogdata.type == 1 ? true : false;
                let fpDialogID = dialogdata.id;
                let members = dialogdata.DialogueMembers;
                let topicTitle = dialogdata.name;
                let IsOwner = dialogdata.create_by == userId;
                let archived = resp.data.is_archived;
                let archived_at = archivedTimestamp;

                let usersList = await members.map((mem) => {
                    mem.first_name = mem.userInfo.first_name;
                    mem.last_name = '';
                    mem.picture = mem.userInfo.file_url;
                    return mem;
                });

                
                this.setState(
                    {
                        usersList,
                        archived,
                        archived_at
                    },
                    () => {
                        this.FetchQBData();
                    },
                );

            } 
            else {
                this.setState({loading : false})
            }
        });
    };

    FetchQBData = async () => {
        let usersList = this.state.usersList;
        if (usersList && usersList.length > 0) {
            let newuser = await usersList.map((data) => {
                data.name = data.first_name;
                data._id = data.qb_user_id;
                return data;
            });

            newuser.forEach(user => {
                user.avatar = user.picture ? user.picture : userImg;
            });

            this.setState({ users: newuser }, async () => {
                await this.getUsers();
                
            });
        } 
        else {
            this.setState({loading : false})
        }
    };

    getUsers = async () => {
        const { onlineUsersList, users, dialogId, archived, archived_at } = this.state;
        let arr = [...users];
        arr.forEach(item => {
            if(onlineUsersList.includes(Number(item.qb_user_id))){
                item.isOnline = true;
            } else {
                item.isOnline = false;
            }
        });
        await this.setState({ users: arr });
        
        let str = [...users];
        let promises = [];
        str.forEach(user => {
            promises.push(
            RetrieveUserChatHistory(dialogId, user.qb_user_id, archived, archived_at)
            .then(messages => {
            if(messages && Array.isArray(messages.items) && messages.items.length > 0){
                for (let i = 0; i < messages.items.length; i++){
                    if (user.qb_user_id == messages.items[i].sender_id) {

                        user.updated_at = messages.items[i].updated_at;
                        user.ChatMember.updated_at = messages.items[i].updated_at;
                        user.message = messages.items[i].message;
                        break;
                    }
                }
            } else {
                user.updated_at = null;
            }
            })
            );
        })

        Promise.all(promises)
        .then(async () => {
            await this.setState({ users: str, loading: false });
        });
        
        
    }


    render() {
        const { usersList, onlineUsersList, users, loading, dialogId } = this.state;
        const { user, status } = this.props;
    
        
        return (
            <LoadingOverlay
            active={loading ? true : false}
            className={'loader-wrap chat-loader-overlay ' + (loading ? 'd-block' : '')}
            spinner
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '100px',
                    '& svg circle': {
                        stroke: 'rgb(56, 160, 235)',
                    },
                }),
            }}
            >
            <Card bordered={false} className="chat-tab">
                <div className="chat-tab-inner">
                {user && Array.isArray(users) && users.length > 0 ? (
                    users.map((item, i) => (
                    (item.user_id === user.id ? null : (
                    <Card key={i} bordered={false} className="chat-tab-item">
                        <div className="card-left">
                            <div>
                                <Badge color={item.isOnline ? "#39C47A": "#CBCFD0"}>
                                    <Avatar size={40} src={ dialogId ? (item.picture ? item.picture : item.file_url ? item.file_url : userImg) : (item.picture ? item.picture.url : item.file_url ? item.file_url : userImg)} />
                                </Badge>
                            </div>
                        </div>
                        <div className="card-right">
                            <div className="person-data">
                                <span>{item.name}</span>
                                <span>{item.ChatMember && item.ChatMember.updated_at ? moment(item.ChatMember.updated_at).fromNow(): null}</span>
                            </div>
                            <div className="msg-body">
                                <span>{item.message || ' '}</span>
                            </div>
                        </div>
                    </Card>
                    ))
                ))
                ) : (
                    <div>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                )}
            </div>
            </Card>
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparents, status } = serviceReducer;
    return { user, error, children, coparents, status };
};
export default connect(mapStateToProps)(ChatTab);
