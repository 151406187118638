import React, { Component } from 'react';
import 'pages/Household/Household/Household.scss';
import LoadingOverlay from 'react-loading-overlay';
import { US_states } from 'pages/Household/Household/StateData.js';
import { addressData } from 'pages/Household/Household/AddressData.js';
import { getHouseHoldAction, saveHouseHoldAction } from 'actions/houseHoldAction.js';
import { getCheckListAction } from 'actions/userActions';
import { Form, Card, Avatar, Row, Col, Input, Button, Modal, Select, DatePicker } from 'antd';
import FloatLabel from 'Components/FloatLabel/FloatLabel';
import user from 'Assets/defaultAvatar.png';
import { Formik } from 'formik';
// import Calendar from 'react-calendar';
import Calendar from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import 'react-calendar/dist/Calendar.css';
import Moment from 'moment';
import ChangePassword from './ChangePassword';
import { connect } from 'react-redux';
import { getUserById } from 'services/userService';
import { checkCookie } from 'utils/cookies';
import { getMeUserAction } from 'actions/userActions';
import { saveHouseHold } from 'services/houseHoldService';

const { Option } = Select;

const validator = require('email-validator');

let addressArray = [];
let countries = [
    {
        addressFields: ['address', 'city', 'zip', 'state', 'licence'],
        label: '+1 United States',
        value: 'usa',
    },
    {
        addressFields: ['street', 'city', 'provinceOrTerritory', 'postalCode', 'licence'],
        label: '+1 Canada',
        value: 'canada',
    },
];
function onChange(date, dateString) {

}

var d = new Date();
var utc = d.getTime();

class Household extends Component {
    constructor(props) {
        super(props);
        // this.validate = this.validate.bind(this);
        this.state = {
            name: '',
            firstName: '',
            lastName: '',
            email: '',
            dob: null,
            phone: '',
            licence: '',
            password: '',
            address: '',
            street: '',
            city: '',
            provinceOrTerritory: '',
            state: '',
            zip: '',
            postalCode: '',
            id: '',
            user_id: '',
            pic: '',
            picture: '',
            picName: '',
            country_code: 'usa',
            showCalendar: false,
            moveIcon: false,
            isLoading: false,
            modalVisible: false,
            modal2Visible: false,
            start_date: new Date(utc),
            currentDate: new Date(utc),
            showAddress: [],
            errorIs: {
                name: '',
                firstName: '',
                lastName: '',
                email: '',
                dob: '',
                phone: '',
                licence: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                street: '',
                provinceOrTerritory: '',
                postalCode: '',
            },
            isThirdParty: localStorage.getItem("third_party"),
            userImg: '',
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        this.getCountryName = this.getCountryName.bind(this);
        this.handleState = this.handleState.bind(this);
        this.getStateName = this.getStateName.bind(this);
        this.handleChangeImage = this.handleChangeImage.bind(this);
    }

    setIndexState = (stateObj) => {
        this.setState(stateObj);
    };

    setModalVisible = (modalVisible) => {
        this.setState({
            modalVisible: modalVisible,
        });
    };

    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible });
    }

    handleDateCalendar = () => {
        const { showCalendar } = this.state;
        this.setState({
            showCalendar: !showCalendar,
        });
    };

    handleDropdownSelct = () => {
        this.setState({
            moveIcon: !this.state.moveIcon,
        });
    };

    DateOnChange = (date) => {
        let dateIs = Moment.parseZone(date).format('YYYY-MM-DD');
        this.setState({
            currentDate: dateIs,
            showCalendar: false,
        });
    };

    handleDate = function (e) {
        this.setState({ currentDate: e });
    };

    confirmDate() {
        let currentDate = this.state.currentDate;
        this.setState({
            dob: currentDate,
            modal2Visible: false,
        });
    }

    handleGetData = () => {
        this.setState({ isLoading: true });
        this.props.dispatch(getHouseHoldAction());
        if (localStorage.third_party !== 'true') {
            this.props.dispatch(getCheckListAction());
        }
    };

    componentDidMount() {
        let isSuccess = false;
        let { response } = this.props;
        if (checkCookie() !== null) {
            let token = checkCookie();
            this.props.dispatch(getMeUserAction({ token: token }));
        }

        if (this.state.isThirdParty == 'false')
            this.handleGetData();
    }

    UNSAFE_componentWillReceiveProps = async function(props) {
        let { response } = props;
        let houseHoldDatanew = response.houseHoldReducer.houseHold;

        await this.setState({ pic: '' , isThirdParty: localStorage.getItem("third_party")});
        let thirdpartyStatus = localStorage.getItem("chat_conf");

        if (typeof houseHoldDatanew === 'object' && Object.keys(houseHoldDatanew).length) {
            let dob1 = houseHoldDatanew.owner.dob ? houseHoldDatanew.owner.dob : null;
            this.setState({
                name: houseHoldDatanew.name ? houseHoldDatanew.name : '',
                firstName: houseHoldDatanew.owner.first_name ? houseHoldDatanew.owner.first_name : '',
                lastName: houseHoldDatanew.owner.last_name ? houseHoldDatanew.owner.last_name : '',
                userImg: houseHoldDatanew.owner.file_url ? houseHoldDatanew.owner.file_url : '',
                email: houseHoldDatanew.owner.email ? houseHoldDatanew.owner.email : '',
                dob: dob1 ? Moment.parseZone(dob1).format('YYYY-MM-DD') : '',
                currentDate: dob1 ? Moment.parseZone(dob1).format('YYYY-MM-DD') : Moment.parseZone().format('YYYY-MM-DD'),
                phone: houseHoldDatanew.owner.phone_number ? houseHoldDatanew.owner.phone_number : '',
                licence: houseHoldDatanew.licence ? houseHoldDatanew.licence : '',
                password: houseHoldDatanew.password ? houseHoldDatanew.password : '',
                address: houseHoldDatanew.address ? houseHoldDatanew.address : '',
                street: houseHoldDatanew.street ? houseHoldDatanew.street : '',
                provinceOrTerritory: houseHoldDatanew.provinceOrTerritory ? houseHoldDatanew.provinceOrTerritory : '',
                city: houseHoldDatanew.city ? houseHoldDatanew.city : '',
                state: houseHoldDatanew.state ? houseHoldDatanew.state : '',
                country_code: houseHoldDatanew.country_code ? houseHoldDatanew.country_code : 'usa',
                zip: houseHoldDatanew.zip ? houseHoldDatanew.zip : '',
                postalCode: houseHoldDatanew.postalCode ? houseHoldDatanew.postalCode : '',
                id: houseHoldDatanew.id ? houseHoldDatanew.id : '',
                user_id: houseHoldDatanew.user_id ? houseHoldDatanew.user_id : '',
                pic: houseHoldDatanew.file_url ? houseHoldDatanew.file_url : null,
                picture: houseHoldDatanew.picture ? houseHoldDatanew.picture : '',
                picName:
                    houseHoldDatanew.hasOwnProperty('picture').hasOwnProperty('name') &&
                        !houseHoldDatanew.picture.name === null
                        ? houseHoldDatanew.picture.name
                        : '',
                isLoading: false,
                isThirdParty: localStorage.getItem("third_party"),
            });




        } else {
            this.setState({ isLoading: false });
        }

        if (this.state.isThirdParty == 'true') {

            const user = response.serviceReducer.user;
         
            this.setState({
                name: user && user.first_name ? user.first_name : '',
                firstName: user && user.first_name ? user.first_name : '',
                lastName: user && user.last_name ? user.last_name : '',
                userImg: user && user.file_url ? user.file_url : '',
                email: user && user.email ? user.email : '',
                password: user && user.password ? user.password : '',
                user_id: user && user.id ? user.id : '',
                pic: user && user.picture ? user.picture : null,
                picture: user && user.picture ? user.picture : null,
                isLoading: false,
            });
        }

    }


    formatDate = (dateObj) => {
        let date = new Date(dateObj);
        let month = date.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = date.getFullYear();
        let output = parseInt(year.toString()) + '-' + month + '-' + day;
        return output + 'T12:00:00';
    };

    validateForm = (name, value) => {
        const { errorIs, country_code } = this.state;
        const phoneRegx = /^[0-9\b]+$/;
        const zipRegx = /^[0-9\b]+$/;
        switch (name) {
            case 'name':
                errorIs.name = !value.length
                    ? 'Name is required!'
                    : !(value.length > 1)
                        ? 'Household Name is Too short (Must be at Least 2 character)'
                        : value.length > 50
                            ? 'Household Name is Too Long! (Max 50 symbols)'
                            : '';
                break;
            case 'firstName':
                errorIs.firstName = !value.length
                    ? 'Firstname is required!'
                    : !value.replace(/\s/g, '').length
                        ? 'Invalid name!'
                        : value[0] === ' '
                            ? 'First name should not start with whitespace character'
                            : !(value.length > 1)
                                ? 'First Name is Too short (Must be at Least 2 character)'
                                : value.length > 50
                                    ? 'First Name is Too Long! (Max 50 symbols)'
                                    : '';
                break;
            case 'lastName':
                errorIs.lastName = !value.length
                    ? 'Last name is required!'
                    : !value.replace(/\s/g, '').length
                        ? 'Invalid name!'
                        : value[0] === ' '
                            ? 'Last name should not start with whitespace character'
                            : !(value.length > 1)
                                ? 'Last name Is Too short (Must be at least 2 characters)'
                                : value.length > 50
                                    ? 'Last name Is Too Long (Must be 50 characters)'
                                    : '';
                break;
            case 'email':
                errorIs.email = !value.length
                    ? 'Email is required!'
                    : !validator.validate(value)
                        ? 'Invalid email'
                        : '';
                break;
            case 'dob':
                errorIs.dob = !value.length ? 'DOB is required!' : '';
                break;
            case 'phone':
                errorIs.phone = !value.length
                    ? 'Phone is required!'
                    : value.length > 0 && !phoneRegx.test(value)
                        ? 'Only Number Characters Allowed!'
                        : value.length > 10 || value.length < 10
                            ? 'Phone number should be exactly 10 characters'
                            : '';
                break;
            case 'licence':
                errorIs.licence =
                    value.length > 0 && !(value.length > 4)
                        ? 'License is Too short (Must be at Least 5 characters)'
                        : value.length > 0 && value.length > 16
                            ? 'License is Too Long! (Max 16 characters)'
                            : '';
                break;
            // case "password":
            //   errorIs.password = !value.length ? "Password is required!" : "";
            // break;
            case 'address':
                errorIs.address =
                    country_code == 'usa' && !value.length
                        ? 'Address is required!'
                        : country_code == 'usa' && value.length > 50
                            ? 'Address is Too Long! (Max 50 symbols)'
                            : '';
                break;
            case 'street':
                errorIs.street =
                    country_code == 'canada' && !value.length
                        ? 'Street is required!'
                        : country_code == 'canada' && !(value.length > 1)
                            ? 'Street is Too short (Must be at Least 2 characters)'
                            : country_code == 'canada' && value.length > 50
                                ? 'Street is Too Long (Must be 50 characters)'
                                : '';
                break;
            case 'provinceOrTerritory':
                errorIs.provinceOrTerritory =
                    country_code == 'canada' && !value.length
                        ? 'Province/Territory is required!'
                        : country_code == 'canada' && !(value.length > 1)
                            ? 'Province/Territory is Too short (Must be at Least 2 characters)'
                            : country_code == 'canada' && value.length > 50
                                ? 'Province/Territory is Too Long (Must be 50 characters)'
                                : '';
                break;
            case 'city':
                errorIs.city = !value.length
                    ? 'City is required!'
                    : !(value.length > 1)
                        ? 'City Is Too short (Must be at Least 2 characters)'
                        : value.length > 25
                            ? 'City is Too Long (Must be 25 characters)'
                            : '';
                break;
            case 'state':
                errorIs.state = country_code == 'usa' && !value.length ? 'State is required!' : '';
                break;
            case 'zip':
                errorIs.zip =
                    country_code == 'usa' && !value.length
                        ? 'Zip is required!'
                        : country_code == 'usa' && !zipRegx.test(value)
                            ? 'Only Number Characters Allowed!'
                            : country_code == 'usa' && !(value.length > 4)
                                ? 'Zip is Too short (Must be 5 characters)'
                                : country_code == 'usa' && !(value.length === 5)
                                    ? 'Zip is Too Long (Must be 5 characters)'
                                    : '';
                break;
            case 'postalCode':
                errorIs.postalCode =
                    country_code == 'canada' && !value.length
                        ? 'PostalCode is required!'
                        : country_code == 'canada' && !zipRegx.test(value)
                            ? 'Only Number Characters Allowed!'
                            : country_code == 'canada' && !(value.length > 1)
                                ? 'PostalCode is Too short (Must be at Least 2 characters)'
                                : '';
                break;
            default:
                break;
        }

        return errorIs;
    };

    handleCountry = async function (value) {
        const { errorIs } = this.state;
        errorIs.address = '';
        errorIs.city = '';
        errorIs.state = '';
        errorIs.zip = '';
        errorIs.street = '';
        errorIs.provinceOrTerritory = '';
        errorIs.postalCode = '';
        await this.setState({ country_code: value });
    };

    getCountryName = function (val) {
        let c = '';
        const { errorIs } = this.state;

        addressArray = [];
        countries.forEach((item) => {
            if (item.value === val) {
                c = item.label;
            }
        });
        if (c == '') {
            c = '+1 United States';
        }
        let showAddressFields = [];
        countries.map((item) => {
            if (item.value === val) {
                showAddressFields = item.addressFields;
            }
        });

        addressArray.push(showAddressFields);
        return c;
    };

    handleState = async function (val) {
        const { errorIs } = this.state;
        errorIs.state = val ? '' : 'State is required!';
        await this.setState({ state: val, errorIs });
    };

    getStateName = function (val) {
        let s = '';
        US_states.forEach((item) => {
            if (item.value === val) {
                s = item.label;
            }
        });
        return s;
    };

    validation() {
        const zipRegx = /^[0-9\b]+$/;
        const {
            errorIs,
            name,
            firstName,
            lastName,
            email,
            dob,
            phone,
            password,
            address,
            city,
            state,
            zip,
            postalCode,
            street,
            provinceOrTerritory,
            country_code,
            licence,
            isThirdParty,
        } = this.state;
        const phoneRegx = /^[0-9\b]+$/;
        errorIs.name = !name.length
            ? 'Name is required!'
            : !(name.length > 1)
                ? 'Household Name is Too short (Must be at Least 2 character)'
                : name.length > 50
                    ? 'Household Name is Too Long! (Max 50 symbols)'
                    : '';
        errorIs.firstName = !firstName.length
            ? 'Firstname is required!'
            : !firstName.replace(/\s/g, '').length
                ? 'Invalid name!'
                : firstName[0] === ' '
                    ? 'First name should not start with whitespace character'
                    : !(firstName.length > 1)
                        ? 'First Name is Too short (Must be at Least 2 character)'
                        : firstName.length > 50
                            ? 'First Name is Too Long! (Max 50 symbols)'
                            : '';
        errorIs.lastName = !lastName.length
            ? 'Last name is required!'
            : !lastName.replace(/\s/g, '').length
                ? 'Invalid name!'
                : lastName[0] === ' '
                    ? 'Last name should not start with whitespace character'
                    : !(lastName.length > 1)
                        ? 'Last name Is Too short (Must be at least 2 characters)'
                        : lastName.length > 50
                            ? 'Last name Is Too Long (Must be 50 characters'
                            : '';
        errorIs.email = !email.length ? 'Email is required!' : !validator.validate(email) ? 'Invalid email' : '';

        if (isThirdParty == 'false') {
            errorIs.dob = !dob ? 'DOB is required!' : '';
            errorIs.phone = !phone.length
                ? 'Phone is required!'
                : !phoneRegx.test(phone)
                    ? 'Only Number Characters Allowed!'
                    : phone.length > 10 || phone.length < 10
                        ? 'Phone number should be exactly 10 characters '
                        : '';
            errorIs.licence =
                licence.length > 0 && !(licence.length > 4)
                    ? 'License is Too short (Must be at Least 5 characters)'
                    : licence.length > 0 && licence.length > 16
                        ? 'License is Too Long! (Max 16 characters)'
                        : '';
            // errorIs.password = !password.length ? "Password is required!" : "";
            errorIs.address =
                country_code == 'usa' && !address.length
                    ? 'Address is required!'
                    : country_code == 'usa' && address.length > 50
                        ? 'Address is Too Long! (Max 50 symbols)'
                        : '';

            if (country_code == 'canada') {
                errorIs.street = !street.length
                    ? 'Sreet is required!'
                    : !(street.length > 1)
                        ? 'Street Is Too short (Must be at Least 2 characters)'
                        : street.length > 50
                            ? 'Street is Too Long (Must be 50 characters)'
                            : '';
            }

            if (country_code == 'canada') {
                errorIs.provinceOrTerritory = !provinceOrTerritory.length
                    ? 'Province/Territory is required!'
                    : !(provinceOrTerritory.length > 1)
                        ? 'Province/Territory Is Too short (Must be at Least 2 characters)'
                        : provinceOrTerritory.length > 50
                            ? 'Province/Territory is Too Long (Must be 50 characters)'
                            : '';
            }

            errorIs.city = !city.length
                ? 'City is required!'
                : !(city.length > 1)
                    ? 'City Is Too short (Must be at Least 2 characters)'
                    : city.length > 25
                        ? 'City is Too Long (Must be 25 characters)'
                        : '';
            errorIs.state = country_code == 'usa' && !state.length ? 'State is required!' : '';
            if (country_code == 'usa') {
                errorIs.zip = !zip.length
                    ? 'Zip is required!'
                    : !zipRegx.test(zip)
                        ? 'Only Number Characters Allowed!'
                        : !(zip.length > 4)
                            ? 'Zip is Too short (Must be 5 characters)'
                            : !(zip.length === 5)
                                ? 'Zip is Too Long (Must be 5 characters)'
                                : '';
            }
            if (country_code == 'canada') {
                errorIs.postalCode =
                    country_code == 'canada' && !postalCode.length
                        ? 'Postal Code is required!'
                        : !zipRegx.test(postalCode)
                            ? 'Only Number Characters Allowed!'
                            : !(postalCode.length > 1)
                                ? 'Postal Code is Too short (Must be at Least 2 characters)'
                                : '';
            }
        }


        this.setState({
            errorIs,
        });

        return (
            errorIs &&
            !errorIs.name &&
            !errorIs.lastName &&
            !errorIs.firstName &&
            !errorIs.zip &&
            !errorIs.licence &&
            !errorIs.phone &&
            !errorIs.address &&
            !errorIs.street &&
            !errorIs.email &&
            !errorIs.provinceOrTerritory &&
            !errorIs.postalCode &&
            !errorIs.dob &&
            !errorIs.licence &&
            !errorIs.city &&
            !errorIs.state
        );
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        this.validateForm(name, value);
    }

    onSubmit = async function(values)  {
        // this.props.dispatch(addLoading());
        const { response } = this.props;
        const user = response.serviceReducer.user;
        values.preventDefault();
        values = this.state;
        if (this.state.isThirdParty == 'true') {
            if (this.validation()) {
                this.setState({ isLoading: true });
                let uploadPic = null;
                if (typeof this.state.picture === 'undefined') {
                    uploadPic = null;
                } else {
                    uploadPic = this.state.picture;
                }

                const data = {
                    name: this.state.name,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email,
                    id: this.state.id,
                    user_id: this.state.user_id,
                    picture: uploadPic,
                    thirdpartyProfile: true,
                    houseHoldId: null,
                };

                
                saveHouseHold(data)
                    .then(resp => {
                        if (resp) {
                            this.handleThirdPartyData();
                    }
                })
            }

        } else {
            if (this.validation()) {
                this.setState({ isLoading: true });
                let uploadPic = null;
                if (typeof this.state.picture === 'undefined') {
                    uploadPic = null;
                } else {
                    uploadPic = this.state.picture;
                }

                let DOB = this.state.dob.replaceAll('-', '/');

                const data = {
                    name: this.state.name,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    address: this.state.address,
                    city: this.state.city,
                    street: this.state.street,
                    provinceOrTerritory: this.state.provinceOrTerritory,
                    country_code: this.state.country_code,
                    dob: Moment(new Date(DOB)).format('YYYY-MM-DDThh:mm:ss'),
                    email: this.state.email,
                    id: this.state.id,
                    houseHoldId: this.state.id,
                    licence: this.state.licence,
                    phone: this.state.phone,
                    picture: uploadPic,
                    state: this.state.state,
                    user_id: this.state.user_id,
                    zip: this.state.zip,
                    postalCode: this.state.postalCode,
                };

                saveHouseHold(data)
                    .then(resp => {
                        if (resp) {
                            this.handleGetData();
                    }
                })

                //  this.props.dispatch(removeLoading());
            }
        }

    };

    handleThirdPartyData = () => {
        this.setState({ isLoading: true });
        if (checkCookie() !== null) {
            let token = checkCookie();
            this.props.dispatch(getMeUserAction({ token: token }));

        }
    };

    handleChangeImage = (evt) => {
        var self = this;
        var reader = new FileReader();
        var file = evt.target.files[0];
        var name = evt.target.files[0].name;
        if (this.state.isThirdParty == 'true') {
            self.setState({
                userImg: URL.createObjectURL(file),
            });
        } else {
            self.setState({
                imgRef: URL.createObjectURL(file),
            });
        }

        reader.onload = function (upload) {
            self.setState({
                picture: { base64: upload.target.result, name: name, uri: '' },
            });
        };
        reader.readAsDataURL(file);
    };

    handleDOB = (e) => {
        if (e && e._d) {
            this.setState({ dob: Moment.parseZone(e._d).format('YYYY-MM-DD'), errorIs: { ...this.state.errorIs, dob: '' } });
        }
    };

    render() {
        const {
            errorIs,
            dob,
            showCalendar,
            isLoading,
            currentDate,
            country_code,
            state,
            start_date,
            showAddress,
            isThirdParty,
        } = this.state;

        addressArray = [];

        let showAddressFields = [];
        countries.map((item) => {
            if (item.value === country_code) {
                showAddressFields = item.addressFields;
            }
        });
        addressArray.push(showAddressFields);

  

        return (
            <Card bordered={false} className="household-card req-card">
                <React.Fragment>
                    {isLoading && (
                        <LoadingOverlay
                            active={true}
                            className="d-block cust-loader"
                            spinner
                            styles={{
                                spinner: (base) => ({
                                    ...base,
                                    width: '100px',
                                    '& svg circle': {
                                        stroke: 'rgb(56, 160, 235)',
                                    },
                                }),
                            }}
                        ></LoadingOverlay>
                    )}
                    <div className="head mt-15">
                        <span>{this.state.name}</span>
                    </div>
                    <div className="head-avatar">
                        {/*   <Avatar src={user} alt="user-avatar" /> */}
                        <div className="img-upload position-r wid-box c-pointer">
                            {isThirdParty == 'true' ? (
                                <Avatar
                                    className="set_wid"
                                    src={this.state.userImg ? this.state.userImg : user}
                                    className={this.state.userImg ? "img-exist" : "no-img"}
                                    alt="user-avatar"
                                />
                            ) : (
                                    <Avatar
                                        className="set_wid"
                                        src={this.state.imgRef ? this.state.imgRef : this.state.pic ? this.state.pic : user}
                                        className={this.state.imgRef ? "img-exist" : this.state.pic ? "img-exist" : "no-img"}
                                        alt="user-avatar"
                                    />
                                )}

                            <input
                                type="file"
                                name="picture"
                                className="position-ab c-pointer"
                                encType="multipart/form-data"
                                onChange={this.handleChangeImage}
                            />
                            <div className="edit-icon">
                                <svg version="1.1" id="Capa_1" viewBox="0 0 512 512">
                                    <polygon points="51.2,353.28 0,512 158.72,460.8" />
                                    <rect
                                        x="89.73"
                                        y="169.097"
                                        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -95.8575 260.3719)"
                                        width="353.277"
                                        height="153.599"
                                    />
                                    <path
                                        d="M504.32,79.36L432.64,7.68c-10.24-10.24-25.6-10.24-35.84,0l-23.04,23.04l107.52,107.52l23.04-23.04
                                C514.56,104.96,514.56,89.6,504.32,79.36z"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="content household-content">
                        <Formik onSubmit={this.onSubmit}>
                            {({ touched, values, handleChange, handleSubmit, handleBlur, errors, isSubmitting }) => (
                                <form onSubmit={this.onSubmit}>
                                    <Input type="hidden" name="id" value={this.state.id} />
                                    <Row gutter={48}>
                                        <Col span={12} className="col-wrap">
                                            <div className={'float-wrap ' + (errorIs.name ? 'err-msg' : '')}>
                                                <FloatLabel label="Name" name="name" value={this.state.name}>
                                                    <Input
                                                        name="name"
                                                        value={this.state.name}
                                                        onChange={(e) => {
                                                            this.handleChange(e);
                                                        }}
                                                        onBlur={handleBlur}
                                                        autoComplete="nope"
                                                    />
                                                </FloatLabel>
                                                {errorIs.name ? <div className="form-err">{errorIs.name}</div> : null}
                                            </div>
                                            <div className={'float-wrap ' + (errorIs.firstName ? 'err-msg' : '')}>
                                                <FloatLabel
                                                    label="First Name"
                                                    name="firstName"
                                                    value={this.state.firstName}
                                                >
                                                    <Input
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={(e) => {
                                                            this.handleChange(e);
                                                        }}
                                                        onBlur={handleBlur}
                                                        autoComplete="nope"
                                                    />
                                                </FloatLabel>
                                                {errorIs.firstName ? (
                                                    <div className="form-err">{errorIs.firstName}</div>
                                                ) : null}
                                            </div>
                                            <div className={'float-wrap ' + (errorIs.lastName ? 'err-msg' : '')}>
                                                <FloatLabel
                                                    label="Last Name"
                                                    name="lastName"
                                                    value={this.state.lastName}
                                                >
                                                    <Input
                                                        name="lastName"
                                                        value={this.state.lastName}
                                                        onChange={(e) => {
                                                            this.handleChange(e);
                                                        }}
                                                        onBlur={handleBlur}
                                                        autoComplete="nope"
                                                    />
                                                </FloatLabel>
                                                {errorIs.lastName ? (
                                                    <div className="form-err">{errorIs.lastName}</div>
                                                ) : null}
                                            </div>
                                            <div className={'float-wrap ' + (errorIs.email ? 'err-msg' : '')}>
                                                <FloatLabel label="Email" name="email" value={this.state.email}>
                                                    <Input
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={(e) => {
                                                            this.handleChange(e);
                                                        }}
                                                        onBlur={handleBlur}
                                                        autoComplete="nope"
                                                    />
                                                </FloatLabel>
                                                {errorIs.email ? <div className="form-err">{errorIs.email}</div> : null}
                                            </div>
                                            {isThirdParty == 'false' && (
                                                <div className={'float-wrap  ' + (errorIs.dob ? 'err-msg' : '')}>
                                                    <FloatLabel
                                                        label="Date of Birth"
                                                        name="dob"
                                                        value={dob === '' ? dob : Moment.parseZone(dob).format('YYYY-MM-DD')}
                                                    >
                                                        {/* <Input
                                                        name="dob"
                                                        value={dob === '' ? dob : this.formatDate(dob)}
                                                        onChange={(e) => {
                                                            this.handleChange(e);
                                                        }}
                                                        readOnly={true}
                                                        onBlur={handleBlur}
                                                        // onClick={this.handleDateCalendar}
                                                        onClick={() => this.setModal2Visible(true)}
                                                        autoComplete="nope"
                                                    /> */}
                                                        <DatePicker
                                                            className="custom-dp"
                                                            inputReadOnly="true"
                                                            value={dob ? Moment(dob) : ''}
                                                            placeholder=""
                                                            size="large"
                                                            onChange={this.handleDOB}
                                                        />
                                                    </FloatLabel>
                                                    {errorIs.dob ? <div className="form-err">{errorIs.dob}</div> : null}
                                                </div>
                                            )}

                                            <Modal
                                                title="Calendar"
                                                centered
                                                className="cal-modal hh-dob-modal"
                                                visible={this.state.modal2Visible}
                                                onOk={() => this.setModal2Visible(false)}
                                                onCancel={() => this.setModal2Visible(false)}
                                            >
                                                <div className="dob-cal-wrap">
                                                    <Calendar
                                                        selected={currentDate}
                                                        onChange={(e) => this.DateOnChange(e)}
                                                    />
                                                </div>
                                                <div className="btn-wrap">
                                                    <Button
                                                        onClick={() => this.confirmDate()}
                                                        className="fp-button-primary full-btn"
                                                        type="primary"
                                                    >
                                                        Confirm
                                                    </Button>
                                                </div>
                                            </Modal>
                                            {isThirdParty == 'false' && (
                                                <div className={'float-wrap ' + (errorIs.phone ? 'err-msg' : '')}>
                                                    <FloatLabel label="Phone Number" name="phone" value={this.state.phone}>
                                                        <Input
                                                            name="phone"
                                                            value={this.state.phone}
                                                            onChange={(e) => {
                                                                this.handleChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                            autoComplete="nope"
                                                        />
                                                    </FloatLabel>
                                                    {errorIs.phone ? <div className="form-err">{errorIs.phone}</div> : null}
                                                </div>
                                            )}
                                            {isThirdParty == 'false' && (
                                                <div className={'float-wrap ' + (errorIs.licence ? 'err-msg' : '')}>
                                                    <FloatLabel
                                                        label="Drivers License"
                                                        name="license"
                                                        value={this.state.licence}
                                                    >
                                                        <Input
                                                            name="licence"
                                                            value={this.state.licence}
                                                            onChange={(e) => {
                                                                this.handleChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                            autoComplete="nope"
                                                        />
                                                    </FloatLabel>
                                                    {errorIs.licence ? (
                                                        <div className="form-err">{errorIs.licence}</div>
                                                    ) : null}
                                                </div>
                                            )}

                                            <div className={'float-wrap '}>
                                                {/* <FloatLabel label="Change Password" name="password" value={this.state.password}>
                                                <Input
                                                    name="password"
                                                    value={this.state.password}
                                                    type="password"
                                                    onChange={(e) => {this.handleChange(e)}}
                                                    onBlur={handleBlur}
                                                    autoComplete="nope"
                                                />
                                            </FloatLabel>
                                            { errorIs.password ? (
                                                <div className="form-err-household">{errorIs.password}</div>
                                            ) : null} */}
                                                <button
                                                    type="button"
                                                    className="ant-btn ant-btn-primary fp-button-secondary ui_btn"
                                                    onClick={() => this.setModalVisible(true)}
                                                >
                                                    <span>Change Password</span>
                                                </button>
                                            </div>
                                            {/*----------------------Change Password Modal ------------------*/}
                                            <ChangePassword
                                                {...this.state}
                                                {...this.props}
                                                handleFunc={this.setIndexState}
                                            />
                                        </Col>
                                        {isThirdParty == 'false' && (
                                            <Col span={12} className="col-wrap">
                                                <Form.Item className="select-sm slct">
                                                    <Select
                                                        value={this.getCountryName(country_code)}
                                                        onChange={this.handleCountry}
                                                    >
                                                        {countries.map((country, i) => (
                                                            <Option
                                                                className={
                                                                    country_code === country.value ? 'text-color' : ''
                                                                }
                                                                value={country.value}
                                                                key={i}
                                                            >
                                                                {country.label}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                {addressArray[0].includes('address') && (
                                                    <div className={'float-wrap ' + (errorIs.address ? 'err-msg' : '')}>
                                                        <FloatLabel
                                                            label="Address"
                                                            name="address"
                                                            value={this.state.address}
                                                        >
                                                            <Input
                                                                name="address"
                                                                value={this.state.address}
                                                                type="address"
                                                                onChange={(e) => {
                                                                    this.handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                autoComplete="nope"
                                                            />
                                                        </FloatLabel>
                                                        {errorIs.address ? (
                                                            <div className="form-err">{errorIs.address}</div>
                                                        ) : null}
                                                    </div>
                                                )}
                                                {addressArray[0].includes('street') && (
                                                    <div className={'float-wrap ' + (errorIs.street ? 'err-msg' : '')}>
                                                        <FloatLabel label="Street" name="street" value={this.state.street}>
                                                            <Input
                                                                name="street"
                                                                value={this.state.street}
                                                                type="street"
                                                                onChange={(e) => {
                                                                    this.handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                autoComplete="nope"
                                                            />
                                                        </FloatLabel>
                                                        {errorIs.street ? (
                                                            <div className="form-err">{errorIs.street}</div>
                                                        ) : null}
                                                    </div>
                                                )}
                                                {addressArray[0].includes('city') && (
                                                    <div className={'float-wrap ' + (errorIs.city ? 'err-msg' : '')}>
                                                        <FloatLabel label="City" name="city" value={this.state.city}>
                                                            <Input
                                                                name="city"
                                                                value={this.state.city}
                                                                onChange={(e) => {
                                                                    this.handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                autoComplete="nope"
                                                            />
                                                        </FloatLabel>
                                                        {errorIs.city ? (
                                                            <div className="form-err">{errorIs.city}</div>
                                                        ) : null}
                                                    </div>
                                                )}
                                                {addressArray[0].includes('provinceOrTerritory') && (
                                                    <div
                                                        className={
                                                            'float-wrap ' + (errorIs.provinceOrTerritory ? 'err-msg' : '')
                                                        }
                                                    >
                                                        <FloatLabel
                                                            label="Province/Territory"
                                                            name="provinceOrTerritory"
                                                            value={this.state.provinceOrTerritory}
                                                        >
                                                            <Input
                                                                name="provinceOrTerritory"
                                                                value={this.state.provinceOrTerritory}
                                                                onChange={(e) => {
                                                                    this.handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                autoComplete="nope"
                                                            />
                                                        </FloatLabel>
                                                        {errorIs.provinceOrTerritory ? (
                                                            <div className="form-err">{errorIs.provinceOrTerritory}</div>
                                                        ) : null}
                                                    </div>
                                                )}
                                                {addressArray[0].includes('state') && (
                                                    <div className={'float-wrap ' + (errorIs.state ? 'err-msg' : '')}>
                                                        {/* <FloatLabel label="State" name="state" value={this.state.state}>
                                                <Input
                                                    name="state"
                                                    value={this.state.state}
                                                    onChange={(e) => {this.handleChange(e)}}
                                                    onBlur={handleBlur}
                                                />
                                            </FloatLabel> */}
                                                        {/* <Select
                                            defaultValue="AL"                                           
                                            style={{ width: '100%' }}
                                            name="state"
                                            onChange={handleChange}
                                        >  
                                         {optionItems}
                                            
                                        </Select> */}

                                                        <Form.Item className="select-sm slct">
                                                            <Select
                                                                value={this.getStateName(state)}
                                                                onChange={this.handleState}
                                                            >
                                                                {US_states.map((item, i) => (
                                                                    <Option
                                                                        className={state === item.value ? 'text-color' : ''}
                                                                        value={item.value}
                                                                        key={i}
                                                                    >
                                                                        {item.label}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                        {errorIs.state ? (
                                                            <div className="form-err">{errorIs.state}</div>
                                                        ) : null}
                                                    </div>
                                                )}
                                                {addressArray[0].includes('zip') && (
                                                    <div className={'float-wrap ' + (errorIs.zip ? 'err-msg' : '')}>
                                                        <FloatLabel label="Zip" name="zip" value={this.state.zip}>
                                                            <Input
                                                                name="zip"
                                                                value={this.state.zip}
                                                                onChange={(e) => {
                                                                    this.handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                autoComplete="nope"
                                                            />
                                                        </FloatLabel>
                                                        {errorIs.zip ? <div className="form-err">{errorIs.zip}</div> : null}
                                                    </div>
                                                )}
                                                {addressArray[0].includes('postalCode') && (
                                                    <div className={'float-wrap ' + (errorIs.postalCode ? 'err-msg' : '')}>
                                                        <FloatLabel
                                                            label="Postal Code"
                                                            name="postalCode"
                                                            value={this.state.postalCode}
                                                        >
                                                            <Input
                                                                name="postalCode"
                                                                value={this.state.postalCode}
                                                                onChange={(e) => {
                                                                    this.handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                autoComplete="nope"
                                                            />
                                                        </FloatLabel>
                                                        {errorIs.postalCode ? (
                                                            <div className="form-err">{errorIs.postalCode}</div>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </Col>
                                        )}
                                    </Row>
                                    <div className="bottom-btn">
                                        <Button type="primary" htmlType="submit" className="btn btn-save">
                                            Save Household
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </React.Fragment>
            </Card>
        );
    }
}

const mapStateToProps = (response) => ({ response });

export default connect(mapStateToProps)(Household);
