import React, { Component } from 'react';
import 'pages/Chat/ChatContainer/ChatContainer.scss';
import { Card, Avatar, Button, Modal, Select, Input } from 'antd';
import Icon from '@ant-design/icons';
import FloatLabel from 'Components/FloatLabel/FloatLabel';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { GetCoParentList, CreateChat, GetCoParentListTP } from 'services/chatService';
import { ConnectChatServer, IsConnected } from 'pages/QuickBlox/qbutility';
import { CreateGroupDialog } from 'pages/QuickBlox/qbDialogs';
import SwapErrorModal from 'pages/CalenderInner/Swap/SwapErrorModal';
import { getUserById } from 'services/userService';

const { Option } = Select;

class NewTopicChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUsers: [],
            users: [],
            topicTitle: '',
            loading: false,
            errors: {
                users: '',
                title: '',
            },
            flag: false,
            flag1: false,
            modalVisible: false,
        };
        this.FetchQBData = this.FetchQBData.bind(this);
        this.handleUsers = this.handleUsers.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.handleTitle = this.handleTitle.bind(this);
    }

    componentDidMount() {}

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.handleOpen) {
            this.FetchQBData();
            this.setState({
                users: [],
                selectedUsers: [],
                topicTitle: '',
                errors: {
                    users: '',
                    title: '',
                },
                flag: false,
                flag1: false,
                modalVisible: false,
            });
        }
    }

    ConnectToChatServer = async () => {
        let userid = await localStorage.getItem('qd_userid');
        let email = await localStorage.getItem('qb_username');
        let isconnected = IsConnected();
        if (!isconnected && userid && email) {
            ConnectChatServer(userid, email);
        }
    };

    checkUser = async (users) => {
        return new Promise(resolve => {
            let promises = [];
            let arr = [];

            users.forEach(el => {
                if (el.name) {
                    arr.push(el);
                } else {
                    promises.push(
                        getUserById(el.id)
                        .then(resp => {
                            if (resp) {
                                let obj = {};
                                obj.file_url = resp.file_url;
                                obj.first_name = resp.first_name;
                                obj.id = resp.id;
                                obj.name = resp.first_name;
                                obj.picture = resp.picture;
                                obj.qd_userid = resp.qd_userid;
                                arr.push(obj);
                        }
                        })
                    )
                }
            })

            Promise.all(promises)
                .then(() => {
                    resolve(arr);
            })
        })
    }

    FetchQBData = async () => {
        const { houseHold } = this.props;
        this.setState({ loading: true });
        this.ConnectToChatServer();
        if(localStorage.third_party !== 'true') {
        GetCoParentList().then(async (resp) => {
            if (resp && resp.data && resp.data.length > 0) {
                let newuser = await resp.data.map((data) => {
                    data.name = data.first_name;
                    return data;
                });
                let members = [];
                let promises = [];
                if (houseHold && houseHold.Members) {
                    houseHold.Members.forEach(el => {
                        if (el.type === 'member') {
                            let obj = {};
                            if (el.user_id) {
                                promises.push(
                                    getUserById(el.user_id)
                                        .then(resp => {
                                            obj.file_url = resp.file_url;
                                            obj.first_name = resp.first_name;
                                            obj.id = resp.id;
                                            obj.name = resp.first_name;
                                            obj.picture = resp.picture;
                                            obj.qd_userid = resp.qd_userid;
                                            members.push(obj);
                                        })
                                );
                            }
                        }
                    })
                }

                Promise.all(promises)
                    .then(async () => {
                        newuser = await this.checkUser(newuser);
                        let arr = [...members,...newuser];
                        this.setState({ users: arr, loading: false });
                    });
            } else {
                this.setState({ loading: false });
                this.setModalVisible(true);
            }
        });
    } else if(localStorage.third_party == 'true') {
         GetCoParentList(localStorage.coparent_id,true).then(async (resp) => {
            if (resp && resp.data && resp.data.length > 0) {
                let newuser = await resp.data.map((data) => {
                    data.name = data.first_name;
                    return data;
                });
                newuser = await this.checkUser(newuser);
                let arr = newuser;
                this.setState({ users: arr, loading: false });
                    
            } else {
                this.setState({ loading: false });
                this.setModalVisible(true);
            }

         })
    }
    };

    handleUsers = async function (users) {
        let err = '';
        if (users.length == 0) {
            err = 'Field is Required!';
        }
        await this.setState({ selectedUsers: users, errors: { ...this.state.errors, users: err } });
        this.state.topicTitle.length >= 2 && this.state.selectedUsers.length !== 0
            ? await this.setState({ flag: true })
            : await this.setState({ flag: false });
    };

    onCreate = async function () {
        const { flag1,users } = this.state;
        let flag = true;
        if(!flag1) {

            if(localStorage.third_party == 'true') {
                let cp_id = localStorage.coparent_id;
                var cp_name = users.filter((x) => x.id == cp_id);
                if (!this.state.selectedUsers.includes(cp_id)) {
                    flag = false;
                    this.setState({ errors: { ...this.state.errors, users: `You must select ${cp_name[0].name} to create any chat on this account.` ,loading: false} });
                } else {
                    flag = true;
                }

            }
            if(flag == true) {
                const { topicTitle } = this.state;
                await this.setState({ loading: true, flag1: true });
                let validForm = true;
                let members = this.state.selectedUsers;
                let title = topicTitle;

                let occ = this.state.users.map((data) => {
                    if (members.includes(data.id)) {
                        return data.qd_userid;
                    }
                });
                var occupants = occ.filter((x) => x !== undefined);

                let group = await CreateGroupDialog(occupants, title);

                if (!group) {
                    await this.setState({ loading: false });
                    return;
                }

                let userid = await localStorage.getItem('user_id');
                members.push(userid);

                let object = {};
                object.name = topicTitle;
                object.type = 1;
                object.qb_dialogue_id = group._id;
                object.members = members;
                if(localStorage.third_party == 'true') {
                    object.is_third_party = true;
                    object.coparent_id = localStorage.coparent_id;
                }

                let response = await CreateChat(object);

                if (response && response.chatInfo) {
                    await this.setState({ loading: false });
                    this.setState({ selectedUsers: [], topicTitle: '' });

                    let users = this.state.users.map((data) => {
                        if (members.includes(data.id)) {
                            data.qb_user_id = data.qd_userid;
                            data.user_id = data.id;
                            return data;
                        }
                    });

                    var filtered = users.filter((x) => x !== undefined);
                    let first_name = await localStorage.getItem('first_name');
                    let qd_userid = await localStorage.getItem('qd_userid');
                    let myself = {};
                    myself.qb_user_id = qd_userid;
                    myself.first_name = first_name;
                    myself.user_id = userid;
                    filtered.push(myself);

                    let newObj = {
                        dialogId: group.id,
                        id: response.chatInfo.id,
                        users: filtered,
                        topicTitle: title,
                        IsOwner: true,
                    };

                    this.props.handleClose();

                    if (this.props.newChat) {
                        this.props.refresh(response.chatInfo.qb_dialogue_id);
                    } else {
                        this.props.refresh();
                    }
                }
            }

        }
    };

    setModalVisible = (visible) => {
        this.setState({ modalVisible: visible });
    }

    handleTitle = async function (e) {
        let title = e.target.value;
        let err = '';
        if (title.length === 0 || title.length == 1) {
            err = 'Topic title is Too Short (Must Be At Least 2 Characters)';
        } else if (title.replace(/\s/g, '').length === 0) {
            err = 'Title should not contain only white spaces';
        }

        await this.setState({ topicTitle: title, errors: { ...this.state.errors, title: err } });
        this.state.errors.title.length === 0 && this.state.selectedUsers.length !== 0
            ? await this.setState({ flag: true })
            : await this.setState({ flag: false });
    };

    goBack = () => {
        if(this.props.newChat){
            this.props.showActive();
        } else {
            this.props.handleClose();
        }
    }


    render() {
        const { coparents } = this.props;
        const { users, selectedUsers, loading, errors, flag } = this.state;
        return (
            <Modal
                title="New Topic"
                centered
                className="chat-modal"
                maskClosable={false}
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={() => this.goBack()}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={'create-modal-loader'}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <div className="new-topic-wrap">
                    <form>
                        {users && users.length > 0 ? (
                            <div className="multi-select">
                                <Select
                                    placeholder="Select user(s)"
                                    className="full-select"
                                    value={selectedUsers}
                                    mode="multiple"
                                    onChange={this.handleUsers}
                                >
                                    {Array.isArray(users) &&
                                        users.map((user, i) => (
                                            <Option
                                                key={i}
                                                className={selectedUsers.includes(user.id) ? 'text-color' : ''}
                                                value={user.id}
                                            >
                                                {user.name}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                        ) : (
                            <Select defaultValue="Select user(s)" className="full-select">
                                <Option value="jack">No Options</Option>
                            </Select>
                        )}
                        {errors.users != '' ? <div className="form-err">{errors.users}</div> : null}
                        <FloatLabel label="Topic title" value={this.state.topicTitle}>
                            <Input
                                name="topic"
                                className="full-input"
                                onChange={(e) => {
                                    this.handleTitle(e);
                                }}
                                autoComplete="nope"
                                value={this.state.topicTitle}
                            />
                        </FloatLabel>
                        {errors.title !== '' ? <div className="form-err">{errors.title}</div> : null}
                        <Button
                            onClick={flag ? this.onCreate : null}
                            className={flag ? 'fp-button-primary create-btn' : 'fp-button-primary create-btn topic-btn'}
                        >
                            Create New Topic
                        </Button>
                    </form>
                </div>
                <SwapErrorModal
                    openModal={this.state.modalVisible}
                    closeModal={() => this.setModalVisible(false)}
                    msg='Chat will be enabled once co-parent accepts your invite'
                />
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer, houseHoldReducer  }) => {
    const { user, error, children, loadings, coparent, coparents } = serviceReducer;
    const { houseHold } = houseHoldReducer;
    return { user, error, children, loadings, coparent, coparents, houseHold };
};
export default connect(mapStateToProps)(NewTopicChat);
