import React, { Component } from 'react';
import 'pages/SubscriptionNew/EditSubscription.scss';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { Card, Button, Typography, Input, Popover, Tabs, Select, Empty } from 'antd';
import { connect } from 'react-redux';
import amex from 'Assets/amex.png';
import visa from 'Assets/visa.png';
import masterCard from 'Assets/masterCard.png';
import more from 'Assets/more.svg';
import creditCard from 'Assets/credit-card.png';
import DeleteCardModal from 'pages/SubscriptionNew/DeleteCardModal';
import {
    getSubscriptionPlan,
    getBillingAuthToken,
    getUserSubscription,
    getActiveCards,
    updateSubscription,
    notifySubscription,
    deleteCard,
    fetchSubscription
} from 'services/subscriptionService';
import LoadingOverlay from 'react-loading-overlay';
import AddCardModal from 'pages/SubscriptionNew/AddCardModal';
import SwapErrorModal from 'pages/CalenderInner/Swap/SwapErrorModal';

class PaymentMethod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            loading: false,
            cards: [],
            currentCard: null,
            modal1Visible: false,
            modal2Visible: false,
            clicked: false,
            key: '',
        };
        this.setModalVisible = this.setModalVisible.bind(this);
        this.getSubscription = this.getSubscription.bind(this);
        this.getBillingDetails = this.getBillingDetails.bind(this);
        this.getCreditCards = this.getCreditCards.bind(this);
        this.checkPrimary = this.checkPrimary.bind(this);
        this.handlePrimary = this.handlePrimary.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.handleClickChange = this.handleClickChange.bind(this);
    }

    componentDidMount() {
        this.getSubscription();
    }

    getSubscription = function () {
        this.setState({ loading: true });
        getUserSubscription()
            .then((resp) => {
                if (resp.data && resp.data.data) {
                    localStorage.setItem('resp', JSON.stringify(resp.data));
                    this.getBillingDetails();
                } else {
                    getBillingAuthToken()
                        .then((res) => {
                            localStorage.setItem('billingAccessToken', res.data.access_token);
                            fetchSubscription()
                                .then(response => {
                                    
                                    let options = {
                                        event_type: 'subscription_created',
                                        data: response.data
                                    };

                                    notifySubscription(options)
                                        .then(result => {
                                            getUserSubscription().then((resp1) => {
                                                localStorage.setItem('resp', JSON.stringify(resp1.data));
                                                this.getBillingDetails();
                                            });
                                    })
                            })
                        });
                }
            })
            .catch((err) => {
                
            });
    }

    getBillingDetails = function () {
        getBillingAuthToken()
            .then((res) => {
                localStorage.setItem('billingAccessToken', res.data.access_token);

                this.getCreditCards();
            });
    }

    getCreditCards = function () {
        getActiveCards()
            .then(resp => {
                this.setState({ loading: false });
                if (resp.data) {
                    this.setState({ cards: resp.data.cards });
                }
        })
    }

    setModalVisible = function (visible) {
        this.setState({ modalVisible: visible });
    }

    setModal1Visible = function (visible) {
        this.setState({ modal1Visible: visible });
    }

    setModal2Visible = function (visible) {
        this.setState({ modal2Visible: visible });
    }

    handleClickChange = (visible) => {
        this.setState({
            clicked: visible
        });
    };

    checkPrimary = function(cardId) {
        let res = false;
        let userInfo = JSON.parse(localStorage.getItem('resp'));
        let data = userInfo.data;
        if (data.subscription.card.card_id === cardId) {
            res = true;
            return res;
        }
        return res;
    }

    handlePrimary = function (card) {
        this.setState({ loading: true, clicked: false });
        let userInfo = JSON.parse(localStorage.getItem('resp'));
        let _data = userInfo.data;
        let _subscription = _data.subscription;

        let _card = {
            plan: _subscription.plan,
            card_id: card.card_id
        };

        updateSubscription(_card)
            .then(resp => {

                let options = {
                    event_type : 'subscription_created',
                    data: resp.data
                };
                    
                notifySubscription(options)
                    .then(data => {
                        this.getSubscription();
                })
        })

    }

    handleDelete = async function (card) {
        this.setState({ clicked: false });
        let res = this.checkPrimary(card.card_id);
        if (res) {
            this.setModal2Visible(true);
        } else {
            await this.setState({ currentCard: card });
            this.setModalVisible(true);
        }
    }

    refresh = () => {
        this.getSubscription();
    }

    setKey = (i, clicked) => {
        this.setState({ key: i, clicked: !clicked });
    }

    render() {
        const {
            loading,
            cards,
            key 
        } = this.state;

        return (
            <div className="payment__method__box">
                <LoadingOverlay
                active={loading ? true : false}
                className={'payment-loader-wrap'}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '100px',
                        '& svg circle': {
                            stroke: 'rgb(56, 160, 235)',
                        },
                    }),
                }}
                >
                <div className="create__payment__wrapper">
                    <h3>Payment Method</h3>
                    <Button onClick={() => this.setModal1Visible(true)} className="create__payment__button">
                        <span className="create__icon">+</span>
                    </Button>
                </div>
                <div className="payment__method__line"></div>

                <div className="card__wrapper">
                {Array.isArray(cards) && cards.length !== 0 ? cards.map((card, i) => (
                    
                    <div key={i} className="card__item">
                        <div className="card__number__box">
                            <img src={creditCard} className="card__image"/>
                            <div className="card__number__item">
                                <span className="card__number">&#x2715;&#x2715;&#x2715;&#x2715; &#x2715;&#x2715;&#x2715;&#x2715; &#x2715;&#x2715;&#x2715;&#x2715; {card.last_four_digits}</span>
                                {this.checkPrimary(card.card_id) ? <span className="primary__text">Primary</span> : null}
                            </div>
                        </div>
                        <div className="menu__box">
                            <Popover
                                placement="bottomRight"
                                trigger="click"
                                visible={key == i && this.state.clicked}
                                onClick={() => this.setKey(i, this.state.clicked)}
                                onVisibleChange={this.handleClickChange}
                                content={<div className="popover__model">
                                    <div
                                        onClick={() => this.handlePrimary(card)}
                                        className="primary__item"
                                    >
                                        Make Primary
                                    </div>
                                    <div
                                        className="delete__item"
                                        onClick={() => this.handleDelete(card)}
                                    >
                                        Delete
                                    </div>
                                </div>}
                                trigger="click"

                            >
                                <img src={more} className="upgrade__option_menu" />
                            </Popover>   
                        </div>
                    </div>
                    )) : (
                        <div>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                    )}
                </div>
                <DeleteCardModal
                    card={this.state.currentCard}        
                    openModal={this.state.modalVisible}
                    closeModal={() => this.setModalVisible(false)}
                    refresh={() => this.refresh()}    
                />
                <AddCardModal
                    openModal={this.state.modal1Visible}
                    closeModal={() => this.setModal1Visible(false)}
                    refresh={() => this.refresh()}     
                    />
                <SwapErrorModal
                    openModal={this.state.modal2Visible}
                    closeModal={() => this.setModal2Visible(false)}
                    msg={"Primary card cannot be deleted."}
                />    
                </LoadingOverlay>    
                </div>
        )
    }
}


export default PaymentMethod;