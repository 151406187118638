import React, { Component } from 'react';
import 'pages/Chat/ChatContainer/ChatContainer.scss';
import { Button, Modal, Input } from 'antd';
import Icon from '@ant-design/icons';
import FloatLabel from 'Components/FloatLabel/FloatLabel';
import LoadingOverlay from 'react-loading-overlay';
import { UpdateChatData } from 'services/chatService';

const { TextArea } = Input;

class UpdateTopicModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            users: [],
            type: 1,
            id: null,
            qb_dialogue_id: null,
            addUsers: [],
            removeUsers: [],
            name: '',
            flag: false,
            errors: {
                title: '',
            },
        };
        this.getUsersName = this.getUsersName.bind(this);
        this.handleTitle = this.handleTitle.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.openModal) {
            this.setState({
                users: nextProps.users,
                type: 1,
                id: nextProps.id,
                qb_dialogue_id: nextProps.qb_dialogue_id,
                addUsers: [],
                removeUsers: [],
                name: nextProps.name,
                flag: false,
                errors: {
                    title: '',
                },
            });
        }
    }

    getUsersName = function (users) {
        let res = '';
        let arr = [];
        if (Array.isArray(users)) {
            users.forEach((user) => {
                arr.push(user.first_name);
            });
        }

        res = arr.join(', ');
        return res;
    };

    handleTitle = async function (e) {
        const { value } = e.target;
        if (value.length === 0) {
            await this.setState({ flag: true, errors: { ...this.state.errors, title: '' } });
        } else if (value.length === 1) {
            await this.setState({
                errors: { ...this.state.errors, title: 'Topic title Is Too Short (Must Be At Least 2 Characters)' },
                flag: false,
            });
        } else if (value.length > 1) {
            await this.setState({ flag: false, errors: { ...this.state.errors, title: '' } });
        }

        await this.setState({ name: value });
    };

    handleUpdate = function () {
        const { id, type, qb_dialogue_id, addUsers, removeUsers, name } = this.state;
        this.setState({ loading: true });
        let item = {
            id: id,
            type: type,
            qb_dialogue_id: qb_dialogue_id,
            addUsers: addUsers,
            removeUsers: removeUsers,
            name: name,
        };

        UpdateChatData(item).then((resp) => {
            this.setState({ loading: false });
            this.props.closeModal();
            this.props.refresh();
        });
    };

    render() {
        const { users, loading, type, id, qb_dialogue_id, name, flag, errors } = this.state;

        return (
                <Modal
                    title="Update Topic"
                    centered
                    className="chat-modal"
                    maskClosable={false}
                    visible={this.props.openModal}
                    onOk={this.props.closeModal}
                    onCancel={this.props.closeModal}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={'create-modal-loader'}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                    <div className="new-topic-wrap">
                        <form>
                            {users && users.length > 0 && (
                                <Input value={this.getUsersName(users)} className="full-input" readOnly />
                            )}
                            <FloatLabel label="Topic title" value={name}>
                                <Input name="topic" className="full-input" onChange={this.handleTitle} value={name} />
                            </FloatLabel>
                            {errors.title != '' ? <div className="form-err">{errors.title}</div> : null}
                            <Button
                                onClick={flag === false ? this.handleUpdate : null}
                                className={
                                    flag === false
                                        ? 'fp-button-primary create-btn'
                                        : 'fp-button-primary create-btn topic-btn'
                                }
                            >
                                Update Topic
                            </Button>
                        </form>
                    </div>
                </Modal>
        );
    }
}

export default UpdateTopicModal;
