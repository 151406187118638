import { put, call } from 'redux-saga/effects';
import { getMe, getSetupProgress } from 'services/userService';
import { getChildren } from 'services/memberService';
import { getCoParents } from 'services/coparentsService';
import { showNotifications } from 'services/notificationService';

import * as types from 'actions';

export function* getMeSaga(action) {
    try {
        const user = yield call(getMe, action);
        if(user && user.message === 'Invalid auth token provided.'){
            let flag= true;
            yield put({ type: types.SET_SESSION_ERROR, flag });
        }else{
           yield put({ type: types.GET_USER_SUCCESS, user }); 
        }
    } catch (error) {
        
        let err = {
            msg: 'Connection error - please try again, or check your internet connection.',
        };

        yield put({ type: types.GET_USER_ERROR, err });
    }
}


export function* getChildrenSaga(action){
    try {
        const children = yield call(getChildren, action);
        yield put({type: types.GET_CHILDREN_SUCCESS, children});

    } catch(error) {
        let err = {
            msg: 'Connection error - please try again, or check your internet connection.',
        };
        yield put({ type: types.GET_USER_ERROR, err });
    }
}

export function* getCheckList(action){
    try {
        const checkListStatus = yield call(getSetupProgress, action);
        yield put({type: types.GET_CHECK_LIST_SUCCESS, checkListStatus});

    } catch(error) {
        let err = {
            msg: 'Connection error - please try again, or check your internet connection.',
        };
        yield put({ type: types.GET_USER_ERROR, err });
    }
}

export function* getCoParentsSaga(action){
    try {
        if (localStorage.third_party !== 'true') {
            const response = yield call(getCoParents, action);
            const coparents = [];
            const coParentsEmails = [];
            const coParentIds = [];
            response.sort(function (a, b) {
                return a.created_at > b.created_at ? 1 : -1;
            });

            for (let i = 0; i < response.length; i++) {
                let coparent = response[i];
                if (coparent.User) {
                    if (coParentsEmails.indexOf(coparent.User.email) === -1 &&
                        coParentIds.indexOf(coparent.User.id) === -1) {
                        coparents.push({
                            id: coparent.User.id,
                            is_registered: true,
                            is_accepted: coparent.is_accepted,
                            gender: coparent.gender || '',
                            name: coparent.User.first_name || coparent.User.last_name,
                            email: coparent.User.email,
                            relation: coparent.relation,
                            invitationsIds: [coparent.id],
                            sender_id: coparent.sender_id,
                            receiver_id: coparent.receiver_id,
                            receiver_email: coparent.receiver_email,
                            notified: coparent.receiver_email.includes('_notnotify') ? false : true,
                        });
                        coParentsEmails.push(coparent.User.email);
                        coParentIds.push(coparent.User.id);
                    } else {
                        coparents.forEach((cp) => {
                            if (cp.email === coparent.User.email) {
                                cp.is_accepted = coparent.is_accepted;
                                cp.invitationsIds.push(coparent.id);
                            }
                        });
                    }
                } else {
                    const emailIndex = coParentsEmails.indexOf(coparent.receiver_email);

                    if (emailIndex === -1) {
                        let id = coparent.id;
                        const idIndex = coParentIds.indexOf(id);
                        if (idIndex === -1) {
                            coparents.push({
                                id: id,
                                email: coparent.receiver_email,
                                is_registered: false,
                                is_accepted: coparent.is_accepted,
                                gender: coparent.gender || '',
                                name: coparent.receiver_name,
                                relation: coparent.relation,
                                invitationsIds: [coparent.id],
                                isUserCreated: true,
                                notified: coparent.receiver_email.includes('_notnotify') ? false : true,
                            });
                            coParentsEmails.push(coparent.receiver_email);
                            coParentIds.push(id);
                        }
                    } else {
                        coparents.map((cp) => {
                            if (cp.email === coparent.receiver_email) {
                                cp.is_accepted = coparent.is_accepted;
                                cp.invitationsIds.push(coparent.id);
                            }
                        });
                    }
                }
            }
            yield put({ type: types.GET_COPARENTS_SUCCESS, coparents });
        } else if (localStorage.third_party === 'true') {
            const coparents = [];
            coparents.push({
                id: 1111,
                name: localStorage.coparent_name
            });
            yield put({ type: types.GET_COPARENTS_SUCCESS, coparents });
        }
    } catch(error) {
        let err = {
            msg: 'Connection error - please try again, or check your internet connection.',
        };
        yield put({ type: types.GET_USER_ERROR, err });
    }
}

export function* getCoParentSaga(action) {
    try {
        let coparent = action.coparent;
        if(action.type === 'GET_COPARENT'){
            yield put({ type: types.GET_COPARENT_SUCCESS, coparent });
        }
    } catch (error) {
        
        let err = {
            msg: 'Connection error - please try again, or check your internet connection.',
        };

        yield put({ type: types.GET_USER_ERROR, err });
    }
}

export function* updateQBStatusSaga(action) {
    let status = action.status;
    if(action.type === 'QB_CONNECTION_STATUS'){
        yield put({ type: types.NEW_QB_STATUS, status });
    }
}

export function* updateSessionStatusSaga(action) {
    let flag = action.flag;
    if(action.type === 'SET_SESSION_STATUS'){
        yield put({ type: types.SET_SESSION_ERROR, flag });
    }
}

export function* updateLoginStatusSaga(action) {
    let flag = action.flag;
    if(action.type === 'SET_LOGIN_STATUS'){
        yield put({ type: types.SET_LOGIN_STATUS_ERROR, flag });
    }
}


export function* getNotificationsSaga(action) {
    try {
        const response = yield call(showNotifications, action);
        if (response.success) {
            let data = response.data;
            yield put({ type: types.GET_NOTIFICATION_SUCCESS, notificationCount: data.count, notifications: data.notifications });
        }
    } catch(error) {
        let err = {
            msg: 'Connection error - please try again, or check your internet connection.',
        };
        yield put({ type: types.GET_USER_ERROR, err });
    }
}