import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';

import 'Components/Modal/ModalStyles.scss';
import 'antd/dist/antd.css';
import { BorderTopOutlined } from '@ant-design/icons';
import {
    getQBCredentials,
    updateQBUserData
} from 'services/chatService';
import { getMeUserAction, getChildrenUserAction, getCoParentsUserAction, updateQBStatus,getCheckListAction, updateSessionFlag, updateLoginFlag } from 'actions/userActions';
import { QuickBlockConfig } from 'pages/QuickBlox/QBConfig';
import {
    CreateUser, 
    LoginUser,
    ConnectChatServer,
    IsConnected,
    GetSession,
    CreateSession,
    GetUser,
    CreateAppSession,
} from 'pages/QuickBlox/qbutility';
import * as QB from "quickblox/quickblox";

export class SessionModal extends Component {
    constructor(props){
        super(props);
    }

     UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps && nextProps.hasOwnProperty('loginStatus') && 
            nextProps.loginStatus !== this.props.loginStatus ) {
            this.configureQBData();
        }

    }


    configureQBData = async () => {
        this.props.dispatch(updateQBStatus(false));
        let configData = await localStorage.getItem("chat_conf");
        if(!configData){
            getQBCredentials().then(resp => {
                if (resp.success) {
                  localStorage.setItem("chat_conf", JSON.stringify(resp["chat_conf"]));
                    let data;
                    // Initialize Quickblox SDK
                    if(typeof resp["chat_conf"] === 'string'){
                      data = JSON.parse(resp["chat_conf"]);
                    } else {
                      data = resp["chat_conf"];
                    }
                    QuickBlockConfig(data);
                    setTimeout(async () => {
                       this.checkChatServerLogin();
                    },2000);
                }
              });
        } else {
            configData = JSON.parse(configData);
            // Initialize Quickblox SDK
            QuickBlockConfig(configData);
            setTimeout(async () => {
                this.checkChatServerLogin();
            },2000);
        }
    };


    updateQBDataToServer = async (email, id, qblogin) => {
        await localStorage.setItem('qb_username', email);
        await localStorage.setItem('qd_userid', id);
        let item = { qb_username: email, qd_userid: id,qb_login: qblogin };
        let response = await updateQBUserData(item);
    };
    
    checkChatServerLogin = async () => {
            let username = await localStorage.getItem('qb_username');
            let email = await localStorage.getItem('qb_username');
            let qdLogin = await localStorage.getItem('qb_login');

            let new_user = await localStorage.getItem('new_user');

            if (new_user === 'true') {
                let first_name = await localStorage.getItem('first_name');
                let last_name = await localStorage.getItem('last_name');
               

                let session = await GetSession();
                if(!session){
                    let sessionCreated = await CreateAppSession();
                }
                // Create user
                if (email) {
                    let info = await CreateUser(email, qdLogin, first_name + ' ' + (last_name ? last_name : ''));
                    if (info) {
                        this.updateQBDataToServer(email, '' + info.id, qdLogin);
                        localStorage.removeItem('new_user');
                    }
                }
            }

            // Create User session
            let session =  await GetSession();
            if(!session){
                let sessionCreated = await CreateSession(qdLogin);
            }
            let notFirst = await localStorage.getItem('notFirst');
            if (notFirst) {
                // if (!session || !username) {
                await this.ChatLogin();
                //}
                await this.ConnectToChatServer();
            } else {
                await localStorage.setItem('notFirst', '1');
                await this.ChatLogin();
            }
       
        
    };

    ConnectToChatServer = async () => {
        let userid = await localStorage.getItem('qd_userid');
        let email = await localStorage.getItem('qb_username');
        let qdLogin = await localStorage.getItem('qb_login');
        let isconnected = IsConnected();
        if (!isconnected && userid && qdLogin) {
            let res = await ConnectChatServer(userid, qdLogin);
            if(res){
                this.props.dispatch(updateQBStatus(true));
            }
        }
    };

    ChatLogin = async () => {
        let username = await localStorage.getItem('qb_username');
        let email = await localStorage.getItem('qb_username');
        let qdLogin = await localStorage.getItem('qb_login');
        
        let userData;
        // Login user
        if (qdLogin) {
            userData = await LoginUser(qdLogin);
        }
        
        if (userData) {
            await localStorage.setItem('qb_userid', '' + userData.id);

            let res = await ConnectChatServer(userData.id, qdLogin);
            if(res){
                this.props.dispatch(updateQBStatus(true));
            }

            if (!username) {
                this.updateQBDataToServer(email, '' + userData.id, qdLogin);
            }


        }
    };

    
    render() {
        const { sessionError,loginStatus } = this.props;
        return(
            <Modal
              title="Session has be expired. You will be logged out."
              visible={sessionError}
              maskClosable={false}
              className="error-popup"
              onOk={this.props.handleClose}
              onCancel={this.props.handleClose}
            >
              <Button className="btn" onClick={this.props.handleClose}>OK</Button>
            </Modal>
        );

    }
}

const mapStateToProps = ({serviceReducer}) => {
    const {error, sessionError,loginStatus} = serviceReducer;
    return {error, sessionError, loginStatus};
}

export default connect(mapStateToProps)(SessionModal);