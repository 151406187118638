import { put, call } from 'redux-saga/effects';
import { getHouseHoldService,saveHouseHoldService ,getThirdPartyData} from 'services/houseHoldService.js';

import * as types from 'actions';


export function* getHouseHoldSaga(action){
    try {
        const houseHold = yield call(getHouseHoldService, action);    
        yield put({type: types.GET_HOUSEHOLD_SUCCESS, houseHold});

    } catch(error) {
        let err = {
            msg: 'Connection error - please try again, or check your internet connection.',
        };
        yield put({ type: types.GET_HOUSEHOLD_ERROR, err });
    }
};

export function* getThirdPartySaga(action){
    try {
        const thirdPartyData = yield call(getThirdPartyData, action);    
        yield put({type: types.GET_THIRD_PARTY_SUCCESS, thirdPartyData});

    } catch(error) {
        let err = {
            msg: 'Connection error - please try again, or check your internet connection.',
        };
        yield put({ type: types.GET_THIRD_PARTY_ERROR, err });
    }
};

export function* saveHouseHoldSaga(action){
    try {
        const saveHouseHold = yield call(saveHouseHoldService, action);    
        yield put({type: types.SAVE_HOUSEHOLD_SUCCESS, saveHouseHold});

    } catch(error) {
        let err = {
            msg: 'Connection error - please try again, or check your internet connection.',
        };
        yield put({ type: types.SAVE_HOUSEHOLD_ERROR, err });
    }
}

