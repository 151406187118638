import React, { Component } from 'react';
import 'pages/Payment/Register/Register.scss';
import { Card, Button, Modal, Select, Input, Checkbox } from 'antd';
import FloatLabel from 'Components/FloatLabel/FloatLabel';
import left from 'Assets/left-arrow.svg';

class TermsOfUse extends Component {
    constructor(props) {
        super(props);
        this.state = {
           // termsAgree: false, 
            modal4Visible: false,
            isChecked: false,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps) {
            this.setState({ isChecked: nextProps.termsStatus });
        }    
    }

    setAgree(val) {
        this.setState({termsAgree : val})
    }
  
    setModal4Visible(modal4Visible) {
        this.setState({ termsVisible : false });
    }

    handleClick = async function(){
        await this.setState({ isChecked: !this.state.isChecked });
        this.props.updateTermsAgree(this.state.isChecked);
    }

    render() {
        const { isChecked } = this.state;

        return (
            
                <Modal
                    centered
                    title="Kincrew Terms of Use"
                    visible={this.props.termsVisible}
                    onOk={() => this.setModal4Visible(false)}
                    onCancel={() => this.props.onClose()}
                    className="privacy-policy-popup"
                >
                <div className="privacy-wrap">
                    <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '15px'}}>
                        <strong>
                            THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION AND WAIVER OF
                            JURY TRIAL. PLEASE READ IT CAREFULLY BEFORE AGREEING.
                        </strong>
                    </div>

                    <div style={{textDecoration: 'none'}} className="privacy-rights-text">Terms of Service</div>

                    <p style={{marginTop: '20px'}}>Effective Date: September 12, 2020</p>

                    <p>
                        X2X, Inc., trading as Kincrew App (hereinafter “X2X,” “we” or “us”) 
                        provides its websites (the “Sites”), web-based and mobile based 
                        applications (the “Apps”), the storage functionality associated with the 
                        Sites and/or Apps, and the data, information, tools, updates and similar 
                        materials delivered or provided by us (collectively, the “Service”),
                        subject to your agreement to and compliance with the conditions set forth
                        in this Terms of Service agreement (the “Agreement”).
                    </p>
                    <p>
                        The Service is designed to facilitate and capture information and data 
                        exchanged between current and former spouses and domestic partners
                        (“Partners”), and may also provide services to other users or deliver 
                        information from other users (Partners and other persons are collectively 
                        referred to as “Users”).
                    </p>
                    <p>
                        This Service works best if both Partners register with the Service and 
                        consent to this Agreement. However, limited functionality is available for 
                        use by one of the Partners. In general, the Service may include: capture of 
                        SMS and other text messages, iMessages and photographs, emails and call
                        logs, calendar events, financial information, personal information, and 
                        other information about dependent children or adults who may be cared for
                        by the Partners. While our Service facilitates arrangements between 
                        Partners,
                        <u style={{color: '#333'}}>
                            <strong>
                                we are not responsible and accept no liability for any Partner’s
                                compliance with any agreement between the Partners or with any
                                court’s order or decree
                            </strong>
                        </u>
                        .
                    </p>
                    <p>
                        This Agreement sets forth the legally binding terms and conditions 
                        governing access to and use of the Service by Partners and other Users. By 
                        accessing or using the Service or otherwise entering into this Agreement,
                        you are creating a binding contract with us, including an agreement to 
                        arbitrate any disputes. If you do not agree to these terms and conditions,
                        you may not access or use the Service.
                    </p>
                    <p>
                        We may revise or update this Agreement by posting an amended version 
                        through the Service and making you aware of the revisions, which may be 
                        through posting to the Service or otherwise. Your continued access to or 
                        use of the Service following an update to this Agreement (or other 
                        acceptance method) is considered acceptance of the updated Agreement.
                        Please refer to the “Effective Date” above to see when this Agreement was 
                        last updated.
                    </p>
                    <ol>
                    <li>
                        
                        <p style={{color: '#333'}}>
                            <strong>License</strong>
                        </p>

                        <p>
                            As long as you are in compliance with the conditions of this Agreement and
                            all incorporated documents, we hereby grant you a limited, revocable,
                            non-assignable, non-transferrable, non-sublicensable, non-exclusive license
                            to access, receive and use the Service. No rights not explicitly listed are
                            granted.
                        </p>
                    </li>
                    <li>
                        <p style={{color: '#333'}}>
                            <strong>Incorporated Terms</strong>
                        </p>

                        <p>
                            The following additional terms are incorporated into this Agreement as if
                            fully set forth herein:
                        </p>
                        <ul>
                            <li>
                                <p style={{color: '#333'}}>
                                    <em><strong>Privacy Policy</strong></em>
                                </p>
                            </li>
                            <li>
                                <p style={{color: '#333'}}>
                                    <em><strong>Complaint Policy</strong></em>
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                    <p style={{color: '#333'}}>
                        <strong>DISCLAIMERS</strong>
                    </p>
                    <p>
                        You agree that we are not responsible for monitoring, reporting or ensuring
                        compliance with any agreement between Partners; with compliance with any
                        court order or decree requiring any Partner to act, refrain from action, or
                        pay any sum of money to the other Partner; or for any release of
                        information provided by one User to any other User.
                    </p>
                    <p>
                        You also agree that we are not responsible for any claims of harassment or
                        for any personal or monetary damages suffered by any User as a result of
                        any person’s use of the Service.
                    </p>
                    <p>
                        The Service may allow Users to post and/or store content and materials,
                        which we do not review, control or endorse. You agree that we are not
                        responsible for any content, advice or other materials appearing on or
                        through the Service. Certain Users of the Service may be legal, medical,
                        financial, and/or other professionals or consultants (“Professionals”). The
                        use of the Services by these Professionals does not constitute or imply an
                        endorsement or recommendation of, or referral of other Users to, them by
                        X2X. We are not responsible for the qualifications, regulation or conduct
                        of the Professionals, or for any advice, materials or information they
                        provide (collectively “Advice”), nor do we guarantee the accuracy of any
                        Advice. X2X makes no warranty, express or implied, regarding the
                        Professionals or their Advice. Users that rely on any such Professionals or
                        the Advice they provide do so at their own risk.
                    </p>
                    <p style={{color: '#333'}}>
                        <strong>
                        The Service does not provide any medical treatment or any legal advice.
                        Financial services, if offered to you, are provided through third party
                        processors. Other services may also be offered through third party
                        providers. The Service itself provides no medical, legal, or financial
                        services, and creates no attorney-client or doctor-patient relationship
                        or privilege of any kind. You understand and agree that we do not serve
                        as your professional or financial advisor, and that we do not owe you
                        any duty of care regarding your health, safety, wellbeing, or legal or
                        financial rights/obligations. Materials appearing on the Service are
                        general in nature and you should not act upon them without first
                        consulting with your own professional advisor, whether medical, legal,
                        or financial, with respect to your specific circumstances or issues.
                        </strong>
                    </p>
                    <p>
                        <u style={{color: '#333'}}>
                            <strong>
                                If you believe that you or your child are having a medical
                                emergency, call 911 immediately.
                            </strong>
                        </u>
                    </p>
                    <p>
                        While we make reasonable efforts to ensure that the Service remains
                        generally available, we do not represent or warrant that access to the
                        Service will be error-free or uninterrupted, or without defect, and we do
                        not guarantee that Users will be able to access or use the Service, or its
                        features, at all times.
                    </p>
                    <p>
                        We reserve the right at any time and from time to time to modify or
                        discontinue, temporarily or permanently, the Service, or any part thereof,
                        with or without notice.<strong> </strong>
                    </p>
                    <p>
                        The Service may contain typographical errors or inaccuracies, and may not
                        be complete or current. We reserve the right to correct any such errors,
                        inaccuracies or omissions and to change or update information at any time
                        without prior notice.
                    </p>
                    <p>
                        Use of the Service may cause you to incur text and/or data charges from
                        your provider.
                    </p>
                    </li>
                    <li>
                        <p style={{ color: '#333'}}>
                            <strong>Eligibility</strong>
                        </p>
                        <p>
                            You must be at least eighteen (18) years old in order to use the Service.
                            By using the Service, you represent that you meet the applicable minimum
                            age requirement.
                        </p>
                        <p>
                            Some parts or all of the Service may not be available to the general
                            public, and we may impose eligibility rules from time to time. We reserve
                            the right to amend or eliminate these eligibility requirements at any time.
                        </p>
                        <p>
                            By requesting to use, registering to use and/or using the Service, you
                            represent and warrant that you have the right, legal authority and capacity
                            to enter into this Agreement and you commit to abide by all of the terms
                            and conditions hereof.
                        </p>
                    </li>
                    <li>
                        <p style={{ color: '#333'}}>
                            <strong>Rules of Conduct</strong>
                        </p>
                        <p>
                            Your access to the Service is conditioned on your compliance with the terms
                            of this Agreement, including but not limited to these rules of conduct.
                        </p>
                        <p>
                            You agree that you will not violate any law, regulation or court order
                            applicable to you in connection with your use of the Service.
                        </p>
                        <p>
                            You must keep your user name, password, and any other information needed to
                            login to the Service, or access your communications, payment information,
                            payment history, and other records, if applicable, confidential and secure.
                            We are not responsible for any unauthorized access to your account or
                            profile by others. You are solely responsible for any actions (including
                            payments and postings) made by persons to whom you give access or
                            permission for activities or postings within our Service.
                        </p>
                        <p>
                            You agree not to distribute, upload, make available or otherwise publish
                            through the Service any data, suggestions, information, ideas, comments,
                            causes, promotions, documents, questions, notes, plans, drawings,
                            proposals, or materials similar thereto (“Submissions”) or graphics, text,
                            information, links, profiles, audio, photos, software, music, sounds,
                            video, comments, messages or tags, or similar materials (“Content”) that:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    are unlawful or encourage another person to engage in anything
                                    unlawful;
                                </p>
                            </li>
                            <li>
                                <p>
                                    contain a virus or any other similar programs or software which may
                                    damage the operation of our or another’s computer;
                                </p>
                            </li>
                            <li>
                                <p>
                                    violate the rights of any party or infringes upon the patent,
                                    trademark, trade secret, copyright, right of privacy or publicity
                                    or other intellectual property right of any party; or
                                </p>
                            </li>
                            <li>
                                <p>
                                    are libelous, defamatory, pornographic, obscene, lewd, indecent,
                                    inappropriate, invasive of privacy or publicity rights, abusing,
                                    harassing, threatening or bullying.
                                </p>
                            </li>
                        </ul>
                        <p>
                            You further agree that you will not do any of the following:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    breach, through the Service, any agreements that you enter into
                                    with your Partner or any third parties;
                                </p>
                            </li>
                            <li>
                                <p>
                                    stalk, harass, injure, or harm another individual through the
                                    Service;
                                </p>
                            </li>
                            <li>
                                <p>
                                    modify, adapt, translate, copy, reverse engineer, decompile or
                                    disassemble any portion of the Service;
                                </p>
                            </li>
                            <li>
                                <p>
                                    interfere with or disrupt the operation of the Service, including
                                    restricting or inhibiting any other person from using the Service
                                    by means of hacking or defacing;
                                </p>
                            </li>
                            <li>
                                <p>
                                    transmit to or make available in connection with the Service any
                                    denial of service attack, virus, worm, Trojan horse or other
                                    harmful code or activity;
                                </p>
                            </li>
                            <li>
                                <p>
                                    attempt to probe, scan or test the vulnerability of a system or
                                    network of the Service or to breach security or authentication
                                    measures without proper authorization;
                                </p>
                            </li>
                            <li>
                                <p>
                                    take any action that imposes, or may impose, in our sole
                                    discretion, an unreasonable or disproportionately large load on our
                                    infrastructure;
                                </p>
                            </li>
                            <li>
                                <p>
                                    harvest or collect the email address, contact information, or any
                                    other personal information of other Users of the Service (excluding
                                    information provided to you by your Partner);
                                </p>
                            </li>
                            <li>
                                <p>
                                    use any means to crawl, scrape or collect content from the Service
                                    via automated or large group means;
                                </p>
                            </li>
                            <li>
                                <p>
                                    submit, post or make available false, incomplete or misleading
                                    information to the Service, or otherwise provide such information
                                    to us;
                                </p>
                            </li>
                            <li>
                                <p>
                                    register for more than one user account; or
                                </p>
                            </li>
                            <li>
                                <p>
                                    impersonate any other person or business, including your Partner.
                                </p>
                            </li>
                        </ul>
                        <p>
                            You are not licensed to access any portion of the Service that is not
                            public, and you may not attempt to override any security measures in place
                            on the Service.
                        </p>
                        <p>
                            We reserve the right, in our sole discretion, to protect our Users from
                            violators and violations of these rules of conduct, including but not
                            limited to restricting your access to the Services, restricting your
                            ability to upload Submissions or Content, immediately terminating your
                            access to the Service, or terminating your access to the Service by
                            blocking certain IP addresses from accessing the Service. Notwithstanding
                            the foregoing, our unlimited right to terminate your access to the Service
                            shall not be limited to violations of these rules of conduct.
                        </p>
                        <p>
                            Please note that the Service may include geolocation tracking for
                            convenience in drop-off and pick-up of children or other dependents or
                            items, as well as monitoring and recordation of such deliveries and
                            pick-ups. If a protective order is issued against you or your Partner at
                            any time, please contact &nbsp;   
                             <u>
                                <a href="mailto:support@kincrew.io">
                                    support@kincrew.io
                                </a>
                             </u>
                            &nbsp; for further instructions on disabling notification of location while the
                            order is in effect.
                        </p>
                    </li>

                    <li>
                            <p style={{color: '#333'}}>
                                <strong>Fees and Payments</strong>
                            </p>
                            <p>
                                Access to or use of certain features of the Services may require a paid
                                subscription for that feature, and may require payment of fees based on
                                either the term of the subscription or per transaction, or both
                                (collectively, “Fees”). We also reserve the right to issue access codes
                                which will enable free use of portions of the Service for a limited time.
                                Subscription and other Fees are billed in advance, and are further
                                described at [link]. Subscription and other Fees are subject to change
                                immediately upon our giving notice to you in the manner you have chosen to
                                receive notices from us, for example, by e-mail or text.
                            </p>
                            <p>
                                We will immediately charge your credit card for the first period’s
                                subscription Fee, if such a Fee is assessed. You will have thirty (30) days
                                from the date of initial purchase to receive a full refund of the Fee; no
                                partial refunds will be granted after the initial thirty (30) days have
                                passed. Upon expiration of your subscription, you will receive two (2)
                                grace logins to allow you to access the Service prior to renewal.
                                Thereafter, a renewal payment is required before further access to the
                                Service will be granted.
                            </p>
                            <p>
                                Renewal or continued subscription access will be automatically billed to
                                your credit card or other designated account until such time as you
                                terminate the Service or any aspect of it. To change or cancel your
                                subscription, access your account here: [link]
                            </p>
                            <p>
                                If you wish to use the Service to engage in financial transactions with
                                your Partner or other Users (each a “Transaction”), you will be asked to
                                supply additional financial and other personal information. If you are
                                receiving funds, the information will not be accessible to your Partner who
                                is to make the payment. We will treat any information provided through the
                                Services in accordance with this Agreement and the Privacy Policy [link].
                                Verification of identity of both the sending and receiving person , as well
                                as other information, may be required prior to the acknowledgment or
                                completion of the first and subsequent Transactions. Additionally, you will
                                be required to accept the terms of service of the third party payment
                                processor.
                            </p>
                            <p>
                                <em>
                                    Transfers through the Service are processed from the sender's linked
                                    account through the payment processor’s network to the recipient's
                                    linked account. The time to complete a transfer will vary depending on
                                    the circumstances. Information about the time lapse between the
                                    sender’s initiation of a transfer, and the actual receipt of the
                                    transfer by the recipient, can be found at
                                </em>
                                <u>
                                    <a href="https://support.appclose.com/">
                                        <em>support.FamilyPlanApp.com</em>
                                    </a>
                                </u>
                                <em>.</em>
                            </p>
                            <p>
                                We may require you or your Partner to submit or verify court orders or
                                decrees (“Court Orders”) that contain, among other things, payment
                                obligations concerning alimony, child support or similar payments due
                                between Partners. You represent and warrant that any such documents you
                                submit will be true and correct in all respects. You consent to our use of
                                the Court Orders in any manner we deem necessary or advisable, subject to
                                the provisions of the Privacy Policy [link].
                            </p>
                            <p>
                                You represent and warrant that you have the legal right to use any Payment
                                Method (as defined below) that you desire to use, or may use, in connection
                                with any Transaction. You warrant and represent that all information
                                supplied by you in connection with any Transaction is accurate and
                                complete. The provisions of Section 15 [Indemnification] apply to any
                                claims that any such information is inaccurate or incomplete.
                            </p>
                            <p>
                                We may refuse to process Transactions if we believe that either the paying
                                or receiving party may be: i) impersonating another person or entity; ii)
                                violating the rights of any person or entity; or iii) committing any
                                fraudulent act; iv) providing any incorrect information; v) legally
                                restricted from making or receiving payments. We may limit Transactions by
                                either or both of number permitted within a given period of time, or by the
                                amount transferred. We may also reject Transactions where and if prohibited
                                by law or applicable regulation, or for any other or no reason, in our sole
                                and absolute discretion.
                            </p>
                            <p>
                                We use a third-party payment processor (the “Payment Processor”) to
                                facilitate Transactions and to charge Fees to you through your registered
                                account for use of the Services.
                                <u>
                                    The processing of payments will be subject to the terms, conditions and
                                    privacy policies of the Payment Processor in addition to this Agreement
                                </u>
                                . We are not responsible for errors made by the Payment Processor. You
                                agree to pay us, through the Payment Processor, all charges and Fees for
                                Transactions requested or made by you, or which you are otherwise have a
                                legal obligation to make, and you authorize us, through the Payment
                                Processor, to charge, or credit, your chosen bank account, credit card,
                                debit card, e-check account or other payment method (“Payment Method”).
                            </p>
                            <p>
                                WE MAY SUBMIT TRANSACTIONAL CHARGES AND FEES TO YOU WITHOUT FURTHER
                                AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE NOTICE (RECEIPT OF WHICH IS
                                CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH TO
                                CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED
                                BEFORE WE COULD REASONABLY ACT.
                            </p>
                            <p>
                                Your account will be considered delinquent if payment in full is not
                                successful when a charge is initiated. Amounts due are exclusive of all
                                applicable taxes, levies, or duties, and you will be responsible for
                                payment of all such amounts. If you believe that any specific charge under
                                this Agreement is incorrect, in order to obtain a credit, you must contact
                                us in writing within thirty (30) days after the Transaction, and set forth
                                the nature and amount of the requested correction. Otherwise, Fees and
                                charges are final.
                            </p>
                            <p>
                                We reserve the right to take any and all actions legally permitted to us in
                                the event any Transactions initiated by a User are false, fraudulent,
                                refused by any bank or processor, of if there are insufficient funds in the
                                account to be charged.
                            </p>
                            <p>
                                Again, if you wish to cancel your account and terminate use of the
                                Services, you may do so at any time through your account. Any Fees or
                                charges incurred prior to cancellation are non-refundable.
                            </p>
                        </li>
                        <li>
                            <p style={{color: '#333'}}>
                                <strong>Scheduling, Monitoring and </strong>
                                <strong>Other</strong>
                                <strong>
                                    Functionality Relating to Children or Dependent Adults
                                    <br />
                                </strong>
                            </p>
                            <p>
                                Scheduling functionality works best when used with a Partner or another
                                User. A record of pick-ups and drop-offs will be kept for your own use if
                                not shared, or may be available to both Partners and other Users if
                                permissions are granted.
                            </p>
                            <p>
                                Please be aware that we are registered with the National Center for
                                Exploited Children (http://www.missingkids.com/home), and as such have a
                                reporting obligation should we discover or suspect wrongdoing such as
                                kidnapping, sexual abuse or physical abuse of a child. We may have other
                                reporting requirements under applicable state or federal law.
                            </p>
                            <p>
                                We also expect to use a program that will scan uploaded photos for a match
                                to known sexually explicit images or videos of children. The program
                                examines and searches the images as such, but does not conduct any facial
                                recognition processing. By uploading photos or videos, you consent to our
                                active search for sexually explicit images and reporting of same. Any
                                account found to have such images will be quarantined and content held in a
                                numerical format for review by law enforcement only.
                            </p>
                        </li>
                        <li>
                            <p style={{color: '#333'}}>
                                <strong>Content Submitted or Made Available to Us</strong>
                            </p>
                        <p>
                            You are under no obligation to submit anything to us, and unless otherwise
                            noted, we will not claim ownership of any Content. However, in order for us
                            to provide the Service, we need your permission to process, display,
                            reproduce and otherwise use Content you make available to us.
                        </p>
                        <p>
                            The Service provides you with the ability to grant or remove permission for
                            other Users (including your Partner) to access certain functionality or
                            information. You are solely responsible for all permissions you grant to
                            any other person, and for all actions they take using those permissions,
                            including any Transactions they authorize when using your account.
                        </p>
                        <p>
                            If you choose to submit any Content to the Service, or otherwise make
                            available any Content through the Service, you hereby grant to us a
                            perpetual, irrevocable, transferrable, sub-licensable, non-exclusive,
                            worldwide, royalty-free, fully-paid license to reproduce, use, modify,
                            display, perform, transmit, distribute, translate and create derivative
                            works from any such Content, including without limitation distributing part
                            or all of the Content in any media format through any media channels, in
                            accordance with your instructions, to comply with applicable law or court
                            order, or as necessary to provide the Service or our own purposes.
                        </p>
                        <p>
                            By submitting any Content or Submissions to us you hereby agree, warrant
                            and represent that: (a) the Content and Submissions do not contain
                            proprietary or confidential information, and the provision of the Content
                            and Submissions is not a violation of any applicable law or any
                            third-party’s rights; (b) all such Submissions and Content are complete,
                            accurate and true, and will be updated without undue delay, (c) we are not
                            under any confidentiality obligation relating to the Content or
                            Submissions; (d) we shall be entitled to use or disclose the Content or
                            Submissions in any way, in accordance with your instructions to comply with
                            applicable law or court order, or as necessary to provide the Service or
                            our own purposes; and (e) you are not entitled to compensation or
                            attribution from us in exchange for the Submissions or Content.
                        </p>
                        <p>
                            You acknowledge that we are under no obligation to maintain the Service, or
                            any information, materials, Submissions, Content or other matter you
                            submit, post or make available to or on the Service. We reserve the right
                            to withhold, remove and or discard any such material at any time.
                        </p>
                        </li>
                        <li>
                            <p style={{color: '#333'}}>
                                <strong>Content Shared</strong>
                                <strong> </strong>
                                <strong>Through</strong>
                                <strong> the Service</strong>
                            </p>
                            <p>
                                The Service may make it possible for you to choose to share certain
                                information with your Partner or other Users. There are a number of ways in
                                which you can control how much information is shared, and we will make
                                efforts to comply with your instructions from the options provided to you.
                                You understand that by designating information to be shared through the
                                Service, you may be revealing information that you choose to share. You
                                understand and acknowledge that you are fully aware and responsible for the
                                impact of sharing such materials, and you agree that we shall not be held
                                responsible, and we shall be released and held harmless by you from any
                                liability or damages arising out of such conduct.
                            </p>
                        </li>
                        <li>
                            <p style={{color: '#333'}}>
                                <strong>Our Intellectual</strong>
                                 <strong>Property</strong>
                            </p>
                            <p>
                                Our graphics, logos, names, designs, page headers, button icons, scripts,
                                and service names are our trademarks, trade names and/or trade dress. The
                                “look” and “feel” of the Service (including color combinations, button
                                shapes, layout, design and all other graphical elements) are protected by
                                U.S. copyright and trademark law. All product names, names of services,
                                trademarks and service marks (“Marks”) are our property or the property of
                                their respective owners, as indicated. You may not use the Marks or
                                copyrights for any purpose whatsoever other than as permitted by this
                                Agreement.
                            </p>
                            <p>
                                You acknowledge that the software used to provide the Service, and all
                                enhancements, updates, upgrades, corrections and modifications to the
                                software, all copyrights, patents, trade secrets, or trademarks or other
                                intellectual property rights protecting or pertaining to any aspect of the
                                software (or any enhancements, corrections or modifications) and any and
                                all documentation therefor, are and shall remain our sole and exclusive
                                property or that of our licensors, as the case may be. This Agreement does
                                not convey title or ownership to you, but instead gives you only the
                                limited rights set forth herein.
                            </p>
                        </li>
                        <li>
                            <p style={{color: '#333'}}>
                                <strong>Data Collection </strong>
                                and<strong> Use</strong>
                            </p>
                            <p>
                                You understand and agree that our<em><strong>Privacy Policy [</strong></em><em><strong>link</strong></em>
                                <em><strong>] </strong></em>shall govern the collection and use of data
                                obtained by us through your access to the Service.
                            </p>
                        </li>
                        <li>
                            <p style={{color: '#333'}}>
                                <strong>Enforcement and Termination</strong>
                            </p>
                            <p>
                                We reserve the right to deny all or some portion of the Service to any or
                                all Users, in our sole discretion, at any time. Without limiting the
                                foregoing or assuming additional legal obligations, we have a policy of
                                terminating repeat violators of the Copyright Act, in accordance with
                                applicable law.
                            </p>
                            <p>
                                All grants of any rights from you to us related to Content, Submissions, or
                                other materials, including but not limited to copyright licenses, shall
                                survive any termination of this Agreement. Further, your representations,
                                defense and indemnification obligations survive any termination of this
                                Agreement.
                            </p>
                        </li>
                        <li>
                            <p style={{color: '#333'}}>
                                <strong>Links and </strong>
                                <strong>Third</strong>
                                <strong>-</strong>
                                <strong>Party</strong>
                                <strong> Content </strong>
                            </p>
                            <p>
                                The Service may contain links to other websites. Such links are provided
                                for informational purposes only, and we do not endorse any website or
                                services through the provision of such a link. Further, each such site may
                                have its own privacy policy, which you should read carefully.
                            </p>
                            <p>
                                The Service may contain articles, text, imagery, video, audio, data,
                                information and other similar materials originating from third-parties. We
                                do not endorse any third party content that may appear on the Service or
                                that may be derived from content that may appear on the Service, even if
                                such content was summarized, collected, reformatted or otherwise edited by
                                us.
                            </p>
                        </li>
                        <li>
                            <p style={{color: '#333'}}>
                                <strong>DISCLAIMERS AND LIMITATION ON LIABILITY</strong>
                            </p>
                            <p>
                                EXCEPT WHERE NOT PERMITTED BY LAW, YOU AGREE AND ACKNOWLEDGE THAT THE
                                SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE”, WITHOUT ANY WARRANTY OR
                                CONDITION, EXPRESS, IMPLIED OR STATUTORY, AND WE, AND OUR PARENTS,
                                SUBSIDIARIES, OFFICERS, DIRECTORS, SHAREHOLDERS, MEMBERS, MANAGERS,
                                EMPLOYEES AND SUPPLIERS, SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF
                                TITLE, ACCURACY, SUITABILITY, APPLICABILITY, MERCHANTABILITY, PERFORMANCE,
                                FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR ANY OTHER WARRANTIES
                                OF ANY KIND IN AND TO THE SERVICE. NO ADVICE OR INFORMATION (ORAL OR
                                WRITTEN) OBTAINED BY YOU FROM US SHALL CREATE ANY WARRANTY.
                            </p>
                            <p style={{color: '#333'}}>
                                <strong>ACCESS TO AND USE OF THE SERVICE IS AT YOUR SOLE RISK. WE 
                                    DO NOT WARRANT THAT YOU WILL BE ABLE TO ACCESS OR USE THE SERVICE AT
                                    THE TIMES OR LOCATIONS OF YOUR CHOOSING; THAT THE SERVICE WILL BE
                                    UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; THAT DATA
                                    TRANSMISSION OR STORAGE IS SECURE, OR THAT THE SERVICE IS FREE OF
                                    INACCURACIES, MISREPRESENTATIONS, VIRUSES OR OTHER HARMFUL INFORMATION
                                    OR COMPONENTS.
                                </strong>
                            </p>
                            <p>
                                TO THE MAXIMUM EXTENT PERMITTED BY LAW, AND EXCEPT AS PROHIBITED BY LAW, IN
                                NO EVENT SHALL WE OR OUR SHAREHOLDERS, OFFICERS, DIRECTORS, EMPLOYEES,
                                AFFILIATES, AGENTS, LICENSORS, SUPPLIERS, AND BUSINESS PARTNERS
                                (COLLECTIVELY, THE “RELATED PARTIES”) BE LIABLE TO YOU BASED ON OR RELATED
                                TO THE SERVICE, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
                                STRICT LIABILITY OR OTHERWISE, AND SHALL NOT BE RESPONSIBLE FOR ANY LOSSES
                                OR DAMAGES, INCLUDING WITHOUT LIMITATION DIRECT, INDIRECT, INCIDENTAL,
                                CONSEQUENTIAL, EXEMPLARY, PUNITIVE OR SPECIAL DAMAGES ARISING OUT OF OR IN
                                ANY WAY CONNECTED WITH ACCESS TO OR USE OF THE SERVICE, WHETHER UNDER ANY
                                CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHER THEORY,
                                INCLUDING DAMAGES, LOSS OF USE OR DATA, LOSS OF OTHER INTANGIBLES, LOSS OF
                                SECURITY OF SUBMISSIONS (INCLUDING UNAUTHORIZED INTERCEPTION BY THIRD
                                PARTIES OF ANY SUBMISSIONS), EVEN IF X2X IS ADVISED IN ADVANCE OF THE
                                POSSIBILITY OF SUCH DAMAGES OR LOSSES.
                            </p>
                            <p>
                                WITHOUT LIMITING THE FOREGOING, X2X WILL NOT BE LIABLE FOR DAMAGES OF ANY
                                KIND RESULTING FROM YOUR USE OF OR INABILITY TO USE THE SERVICES OR FROM
                                ANY PRODUCTS OR THIRD PARTY MATERIALS, INCLUDING FROM ANY VIRUS THAT MAY BE
                                TRANSMITTED IN CONNECTION THEREWITH.
                            </p>
                            <p>
                                ALL LIMITATIONS OF LIABILITY OF ANY KIND (INCLUDING IN THIS SECTION AND
                                ELSEWHERE IN THIS AGREEMENT) ARE MADE FOR THE BENEFIT OF BOTH COMPANY AND
                                THE AFFILIATED ENTITIES, AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS.
                            </p>
                            <p>
                                YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SERVICES IS TO
                                STOP USING THE SERVICES.
                            </p>
                            <p>
                                Notwithstanding the foregoing, in the event that a court shall find that
                                the above disclaimers are not enforceable, then, to the maximum extent
                                permissible by law, you agree that neither we nor any of our subsidiaries,
                                affiliated companies, employees, members, shareholders, officers or
                                directors shall be liable for (1) any aggregate damages in excess of the
                                greater of (a) $500.00 or (b) the amounts paid by you to X2X for
                                subscription access to the Service within the last six months (excluding
                                Transaction Payment Fees), or (2) any indirect, incidental, punitive,
                                special, or consequential damages or loss of use, lost revenue, lost
                                profits or data to you or any third party from your access to or use of the
                                Service or any goods sold or provided by us. This limitation shall apply
                                regardless of the basis of your claim, whether other provisions of this
                                Agreement have been breached, or whether or not the limited remedies
                                provided herein fail of their essential purpose. The foregoing limitation
                                shall not apply to any damage that we cause you intentionally and knowingly
                                in violation of this Agreement or applicable law that cannot be disclaimed
                                in this Agreement.
                            </p>
                            <p>
                                YOU UNDERSTAND THAT YOU MAY BE WAIVING RIGHTS WITH RESPECT TO CLAIMS THAT
                                ARE AT THIS TIME UNKNOWN OR UNSUSPECTED, AND IN ACCORDANCE WITH SUCH
                                WAIVER, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND, AND HEREBY
                                EXPRESSLY WAIVE, THE BENEFITS OF SECTION 1542 OF THE CIVIL CODE OF
                                CALIFORNIA, AND ANY SIMILAR LAW OF ANY STATE, WHICH PROVIDES AS FOLLOWS: “A
                                GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW
                                OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE
                                RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR
                                HER SETTLEMENT WITH THE DEBTOR.”
                            </p>
                            <p>
                                SOME STATES, INCLUDING NEW JERSEY, MAY NOT PERMIT CERTAIN DISCLAIMERS AND
                                LIMITATIONS, AND ANY SUCH DISCLAIMERS OR LIMITATIONS ARE VOID WHERE
                                PROHIBITED.
                            </p>
                        </li>
                        <li>
                            <p style={{color: '#333'}}>
                                <strong>Indemnification</strong>
                            </p>
                            <p>
                                You agree to defend, indemnify and hold us and our suppliers, subsidiaries,
                                licensors, and licensees, and each of their officers, directors,
                                shareholders, members, employees and agents harmless from all allegations,
                                judgments, awards, losses, liabilities, costs and expenses, including but
                                not limited to reasonable attorney’s fees, expert witness fees, and costs
                                of litigation arising out of or based on (a) Submissions or Content you
                                submit or transmit through the Service; (b) your access to or use of the
                                Service; (c) your violation of this Agreement; (d) the acts or omissions of
                                you, your agents, your representatives, heirs or assigns; (e) the acts or
                                omissions of any third party, including without limitation, any other User,
                                who gained access to the Service through use of your login credentials; and
                                (f) any conduct, activity or action which is unlawful or illegal under any
                                state, federal or common law, or is violative of the rights of any
                                individual or entity, engaged in, caused by, or facilitated in any way
                                through the access to or use of the Service.
                            </p>
                        </li>
                        <li>
                            <p style={{ color: '#333'}}>
                                <strong>Governing </strong>
                                <strong> </strong>
                                <strong>Law and Jurisdiction; Arbitration</strong>
                            </p>
                            <p>
                                You agree that any claim or dispute arising out of or relating in any way
                                to the Service will be resolved solely and exclusively by binding
                                arbitration, rather than in court, except that you may assert claims in
                                small claims court if your claims qualify. The Federal Arbitration Act and
                                federal arbitration law apply to this agreement. The laws of the State of
                                New York shall govern this Agreement, and shall be used in any arbitration
                                proceeding.
                            </p>
                            <p>
                                There is no judge or jury in arbitration, and court review of an
                                arbitration award is limited. However, an arbitrator can award on an
                                individual basis the same damages and relief as a court (including
                                injunctive and declaratory relief or statutory damages), and must follow
                                the terms of this Agreement as a court would.
                            </p>
                            <p>
                                To begin an arbitration proceeding, you must send a letter requesting
                                arbitration and describing your claim to the following address:
                                ___________________________________________________. Copies must be sent to
                                support@kincrew.io.
                            </p>
                            <p>
                                Arbitration under this Agreement will be conducted by the American
                                Arbitration Association (AAA) under its Consumer Arbitration Rules then in
                                effect, shall be conducted in English, and shall be conducted in New York,
                                New York. Payment of all filing, administration and arbitrator fees will be
                                governed by the AAA's rules.
                            </p>
                            <p>
                                You and X2X agree that any dispute resolution proceedings will be conducted
                                only on an individual basis and not in a class, consolidated or
                                representative action. If for any reason a claim proceeds in court rather
                                than in arbitration, both you and X2X agree that each have waived any right
                                to a jury trial.
                            </p>
                            <p>
                                Notwithstanding the foregoing, you agree that we may bring suit in court to
                                enjoin infringement or other misuse of intellectual property or other
                                proprietary rights, or for cybersecurity breaches.
                            </p>
                            <p>
                                All aspects of the arbitration proceeding, and any ruling, decision or
                                award by the arbitrator, will be strictly confidential for the benefit of
                                all parties.
                            </p>
                            <p>
                                To the extent arbitration does not apply, you agree that any dispute
                                arising out of or relating to the Service, or to us, may only be brought by
                                you in a state or federal court located in New York, New York. YOU HEREBY
                                WAIVE ANY OBJECTION TO THIS VENUE AS INCONVENIENT OR INAPPROPRIATE, AND
                                AGREE TO EXCLUSIVE JURISDICTION AND VENUE IN NEW YORK.
                            </p>
                        </li>
                        <li>
                        <p style={{color: '#333'}}>
                            <strong>GENERAL</strong>
                        </p>
                        <p>
                            <u>Severability</u>
                            . If any provision of this Agreement is found for any reason to be
                            unlawful, void or unenforceable, then that provision will be given its
                            maximum enforceable effect, or shall be deemed severable from this
                            Agreement and will not affect the validity and enforceability of any
                            remaining provision.
                        </p>
                        <p>
                            <u>Revisions</u>
                            . This Agreement is subject to change on a prospective basis at any time.
                            In the event that we change this Agreement, you may be required to
                            re-affirm the Agreement through use of the Service or otherwise. Your
                            access of use of the Service after the effective date of any changes will
                            constitute your acceptance of such changes.
                        </p>
                        <p>
                            <u>No Partnership</u>
                            . You agree that no joint venture, partnership, employment, or agency
                            relationship exists between you and us as a result of this Agreement or
                            your use of the Service.
                        </p>
                        <p>
                            <u>Assignment</u>
                            . We may assign our rights under this Agreement, in whole or in part, to
                            any person or entity at any time with or without your consent, subject to
                            the terms of our Privacy Policy. You may not assign the Agreement without
                            our prior written consent, and any unauthorized assignment by you shall be
                            null and void.
                        </p>
                        <p>
                            <u>Benefits.</u>
                            This Agreement is binding upon and inures to the benefit of the parties,
                            the Related Parties, and their respective heirs, successors and assigns.
                        </p>
                        <p>
                            <u>No Waiver</u>
                            . Our failure to enforce any provision of this Agreement shall in no way be
                            construed to be a present or future waiver of such provision, nor in any
                            way affect the right of any party to enforce each and every such provision
                            thereafter. The express waiver by us of any provision, condition or
                            requirement of this Agreement shall not constitute a waiver of any future
                            obligation to comply with such provision, condition or requirement.
                        </p>
                        <p>
                            <u>Notices</u>
                            . All notices given by you or required under this Agreement shall be in
                            writing and addressed to:
                            ________________________________________________________________. Copies
                            must be sent to
                            <u>
                                <a href="mailto:support@kincrew.io">
                                    support@kincrew.io
                                </a>
                            </u>
                        </p>
                        <p>
                            <u>Interpretation</u>
                            . Headings in this Agreement are for convenience only and will have no
                            legal meaning or effect. The singular shall include the plural, and vice
                            versa, as required given the context.
                        </p>
                        <p>
                            <u>Equitable Remedies</u>
                            . You hereby agree that we would be irreparably damaged if the terms of
                            this Agreement were not specifically enforced, and therefore you agree that
                            we shall be entitled, without bond, other security, or proof of damages, to
                            appropriate equitable remedies with respect to breaches of this Agreement,
                            in addition to such other remedies as we may otherwise have available to us
                            under applicable laws.
                        </p>
                        <p>
                            <a name="complaint_policy"></a>
                            <u>Entire Agreement</u>
                            . This Agreement, including the documents expressly incorporated by
                            reference, constitutes the entire agreement between you and us with respect
                            to the Service, and supersedes all prior or contemporaneous communications,
                            whether electronic, oral or written.
                        </p>
                        <p style={{ color: '#333'}}>
                            <a name="_GoBack"></a>
                            <strong>COMPLAINT POLICY</strong>
                            <br />
                            If you believe in good faith that any material posted on the Service
                            infringes any of your rights, other than in copyright, or is otherwise
                            unlawful, you must send a notice to support@kincrew.io, containing
                            the following information:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    Your name, physical address, e-mail address and phone number;
                                </p>
                            </li>
                            <li>
                                <p>
                                    A description of the material posted on the Site that you believe
                                    violates your rights or is otherwise unlawful, and which parts of
                                    said materials you believe should be remedied or removed;
                                </p>
                            </li>
                            <li>
                                <p>
                                    Identification of the location of the material on the Site;
                                </p>
                            </li>
                            <li>
                                <p>
                                    If you believe that the material violates your rights, a statement
                                    as to the basis of the rights that you claim are violated;
                                </p>
                            </li>
                            <li>
                                <p>
                                    If you believe that the material is unlawful or violates the rights
                                    of others, a statement as to the basis of this belief;
                                </p>
                            </li>
                            <li>
                                <p>
                                    A statement under penalty of perjury that you have a good faith
                                    belief that use of the material in the manner complained of is not
                                    authorized and that the information you are providing is accurate
                                    to the best of your knowledge and in good faith; and,
                                </p>
                            </li>
                            <li>
                                <p>
                                    Your physical or electronic signature.
                                </p>
                            </li>
                        </ul>
                        <p>
                            If we receive a message that complies with all of these requirements, we
                            will evaluate the submission, and if appropriate, in our sole discretion,
                            we will take action. We may disclose your submission to the poster of the
                            claimed violative material, or any other party.
                        </p>
                    </li>
                    </ol>
                    </div>
                    {!this.props.viewOnly && (
                        <div className="cb-wrap">
                            <Checkbox 
                            onClick={this.handleClick}
                            checked={isChecked}
                            //onChange={() => this.props.updateTermsAgree(!this.props.termsStatus)}
                            >
                                I agree to Terms of Use
                            </Checkbox>
                        </div>
                     )}
                    {this.props.viewOnly && (
                        <div className="cb-wrap">
                            <Button
                                type="primary"
                                className="fp-button-secondary btn-add-req"
                                onClick={() =>this.props.onClose()}
                            >
                                <span>Print</span>
                            </Button>
                        </div>
                    )}
                    

                </Modal>
        );
    }
}

export default TermsOfUse;
