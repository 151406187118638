import React from 'react';
 
const CalenderIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg"  width="20" height="20" viewBox="0 0 20 20">
      <g class="b">
      <g transform="translate(0.549 2.321)">
         <path fill="currentcolor" class="c" d="M17.518,1.49h-.924V.86a.831.831,0,1,0-1.661,0v.63H14.07V.86a.831.831,0,1,0-1.661,0v.63h-.864V.86a.831.831,0,1,0-1.661,0v.63H9.02V.86a.831.831,0,1,0-1.661,0v.63H6.494V.86a.831.831,0,1,0-1.661,0v.63H3.969V.86A.846.846,0,0,0,3.139,0a.846.846,0,0,0-.831.86v.63H1.384A1.317,1.317,0,0,0,.118,2.923v.586c0,.158.006.243.159.243a.247.247,0,0,0,.277-.243V2.923c0-.474.373-1.018.831-1.018h.924v.33a.846.846,0,0,0,.831.86.846.846,0,0,0,.831-.86v-.33h.864v.33a.831.831,0,1,0,1.661,0v-.33h.864v.33a.831.831,0,1,0,1.661,0v-.33h.864v.33a.831.831,0,1,0,1.661,0v-.33h.864v.33a.831.831,0,1,0,1.661,0v-.33h.864v.33a.831.831,0,1,0,1.661,0v-.33h.924c.458,0,.986.235.986.709V13.2h-.783c-.153,0-.277.069-.277.227s.124.172.277.172h.628l.155.325a.938.938,0,0,1-.986,1H1.384a1.108,1.108,0,0,1-1-.975v-.325l16.166-.03c.153,0,.277-.014.277-.172s-.124-.227-.277-.227l-16.166.03V4.761c0-.158.044-.316-.109-.316A.282.282,0,0,0,0,4.731v9.195a1.411,1.411,0,0,0,1.384,1.433H17.518A1.411,1.411,0,0,0,18.9,13.926v-11A1.411,1.411,0,0,0,17.518,1.49ZM3.544,2.5c0,.158-.253.287-.406.287s-.392-.128-.392-.287V.641c0-.158.239-.287.392-.287s.406.128.406.287Zm2.525,0s-.253.287-.406.287-.406-.128-.406-.287V.641c0-.158.254-.287.406-.287s.406.128.406.287Zm2.119.287c-.153,0-.421-.112-.421-.27V.658c0-.158.268-.3.421-.3s.435.128.435.287V2.5C8.624,2.663,8.341,2.791,8.189,2.791Zm2.99-.287c0,.158-.313.287-.465.287s-.435-.128-.435-.287V.641c0-.158.283-.287.435-.287s.465.128.465.287Zm2.481,0c0,.158-.268.287-.421.287S12.8,2.663,12.8,2.5V.641c0-.158.283-.287.435-.287s.421.128.421.287Zm2.1.287c-.153,0-.406-.128-.406-.287V.658c0-.158.253-.287.406-.287S16.2.5,16.2.658V2.5C16.2,2.663,15.917,2.791,15.764,2.791Z"/>
         <g transform="translate(2.696 5.021)">
            <rect class="d" width="1.81" height="1.238" rx="0.619" transform="translate(6.001 2.438)"/>
            <rect class="d" width="1.81" height="1.238" rx="0.619" transform="translate(3)"/>
            <rect class="d" width="1.81" height="1.238" rx="0.619" transform="translate(6.001)"/>
            <rect class="d" width="1.81" height="1.238" rx="0.619" transform="translate(9.001)"/>
            <rect class="d" width="1.81" height="1.238" rx="0.619" transform="translate(12.002)"/>
            <rect class="d" width="1.81" height="1.238" rx="0.619" transform="translate(0 2.438)"/>
            <rect class="d" width="1.81" height="1.238" rx="0.619" transform="translate(3.001 2.438)"/>
            <rect class="d" width="1.81" height="1.238" rx="0.619" transform="translate(9.001 2.438)"/>
            <rect class="d" width="1.81" height="1.238" rx="0.619" transform="translate(0 4.876)"/>
            <rect class="d" width="1.81" height="1.238" rx="0.619" transform="translate(3.001 4.876)"/>
            <rect class="d" width="1.81" height="1.238" rx="0.619" transform="translate(6.001 4.876)"/>
         </g>
      </g>
   </g>
</svg>
);
 
export default CalenderIcon;


