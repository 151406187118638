import React, { Component } from 'react';
import 'pages/SubscriptionNew/EditSubscription.scss';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { Card, Button, Typography, Input, Tabs, Select, Empty } from 'antd';
import { connect } from 'react-redux';
import {
    getSubscriptionPlan,
    getBillingAuthToken,
    getUserSubscription,
    getActiveCards,
    updateSubscription,
    notifySubscription,
    deleteCard,
    getTransactions,
    getPayment,
    getInvoice,
    fetchSubscription
} from 'services/subscriptionService';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { addLoading, removeLoading } from 'actions/loaderActions';

class PaymentHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            transactions: []
        };
        this.getPaymentHistory = this.getPaymentHistory.bind(this);
        this.getSubscription = this.getSubscription.bind(this);
        this.getBillingDetails = this.getBillingDetails.bind(this);
    }

    componentDidMount() {
        this.getSubscription();
    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.loadings === true) {
            this.props.dispatch(removeLoading());
            this.getSubscription();
        }    
    }

    getSubscription = function () {
        this.setState({ loading: true });
        getUserSubscription()
            .then((resp) => {
                if (resp.data && resp.data.data) {
                    localStorage.setItem('resp', JSON.stringify(resp.data));
                    this.getBillingDetails();
                } else {
                    getBillingAuthToken()
                        .then((res) => {
                            localStorage.setItem('billingAccessToken', res.data.access_token);
                            fetchSubscription()
                                .then(response => {
                                    
                                    let options = {
                                        event_type: 'subscription_created',
                                        data: response.data
                                    };

                                    notifySubscription(options)
                                        .then(result => {
                                            getUserSubscription().then((resp1) => {
                                                localStorage.setItem('resp', JSON.stringify(resp1.data));
                                                this.getBillingDetails();
                                            });
                                    })
                            })
                        });
                }
            })
            .catch((err) => {
                
            });
    }

    getBillingDetails = function () {
        getBillingAuthToken()
            .then((res) => {
                localStorage.setItem('billingAccessToken', res.data.access_token);
                this.getPaymentHistory();
            });
    }

    getPaymentHistory = function () {
        getTransactions()
            .then(resp => {
                let transactions = resp.data && resp.data.transactions;
                this.setState({ transactions: transactions, loading: false });             
        })
    }

    render() {
        const {
            transactions,
            loading,
        } = this.state;

        return (
            <div className="payment__history__box">
                <LoadingOverlay
                active={loading ? true : false}
                className={'history-loader-wrap'}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '100px',
                        '& svg circle': {
                            stroke: 'rgb(56, 160, 235)',
                        },
                    }),
                }}
                >
                <div className="payment__history__wrapper">
                    <h3>Payment History</h3>
                </div>

                <div className="payment__history__line"></div>

                <div className="transactions__wrapper">
                {Array.isArray(transactions) && transactions.length !== 0 ? transactions.map((item, i) => (
                    <div key={i} className="history__item">
                        <div className="history__date">
                            <span className="history__month">{moment(item.date).format('MMMM YYYY')}</span>
                        </div>
                        <div className="history__amount">
                            <span className="history__price">&#36;{item.amount}</span>
                            <span className="history__price__date">{moment(item.date).format('MM/DD/YYYY')}</span>
                        </div>
                    </div>
                )) : (
                    <div>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                )}
                    </div>
                </LoadingOverlay>       
            </div> 
        )
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, loadings } = serviceReducer;
    return { user, loadings };
};

export default connect(mapStateToProps)(PaymentHistory);