import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'pages/Payment/Register/Register.scss';
import { Card, Button, Modal, Select, Input, Checkbox } from 'antd';
import FloatLabel from 'Components/FloatLabel/FloatLabel';
import LoadingOverlay from 'react-loading-overlay';
import left from 'Assets/left-arrow.svg';
import moment, { duration } from 'moment';
import TermsOfUse from 'pages/Payment/Register/TermsOfUse';
import { checkCustomer, verifyUser, createJHCustomer, createJHAccount} from '../../../services/paymentService';

const selectAccountType = [
  { name: "Checking", id: "Checking", required: true },
];

class AccountSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nameOnAccount: '',
            achVisible : props.achVisible,
            stepOneStatus : props.stepOneStatusProps,
            rtn: '',
            accountNo: '',
            acctype:'Account Type',
            termsAgree: false,
            termsUseVisible: false,
            resMsgModal: false,
            LoadingIs: false,
            customerVerification : false,
            achVerification : false,
            success : false,
            resMsg : '',
             errorIs: {
                nameOnAccount: '',
                rtn: '',
                accountNo: '',
                acctype: '',
                termsAgree: '',
            },
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleAccType = this.handleAccType.bind(this);
        this.updateTermsState = this.updateTermsState.bind(this);
        this.setTermsUseVisible = this.setTermsUseVisible.bind(this);
        const { Option } = Select;
    }

    handleChange = function(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        this.validateForm(name, value);
    }
    
    updateTermsState = async function (val) {
        await this.setState({ termsAgree: val, termsUseVisible : false });
    }

    handleAccType = async function (val) {
        await this.setState({acctype: val });
    };

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        this.setState({achVisible : nextProps.achVisible})
         var cust_exist = await checkCustomer();
            if(typeof cust_exist != undefined  ){
                this.handleCustomerCheck(cust_exist)
            }
    };

    setTermsUseVisible = function(val) {
        this.setState({ termsUseVisible : val })
    }

    handleSetupForm () {
      this.setState({achVisible : false}, () => {
        this.props.handleAccSetupForm(true , false)
      })
      
    }
    handleMsgClose() {
      this.handleErrorModal(false);
      if(this.state.customerVerification == false) {
         this.props.achclose();
         this.handleSetupForm();
      } else  if(this.state.success == true  ){
         this.props.achclose();
      }
     
    }
     handleCustomerCheck(resp) {
        if (resp.isExist) {
          this.setState({ isExist: resp.isExist });
        }
      }
    handleErrorModal (val) {
       this.setState({ resMsg: '',resMsgModal: false });

    }
    onConfirm() {
      if (this.validation()) {
        if (this.state.isExist) {
          this.setState({customerVerification : true,LoadingIs : true})
          this.sendProfileForm();
        } else {
          this.setState({LoadingIs : true})
          this.verifyUserDetails();
        }
      } else {
        this.setState({sendingForm : false})
      }

    }
      validateForm = (name, value) => {
        const { errorIs } = this.state;
        switch (name) {
            case 'nameOnAccount':
                errorIs.nameOnAccount = !value.length
                    ? 'Name is required!'
                    : !(value.length > 1)
                    ? ' Name is Too short (Must be at Least 2 character)'
                    : value.length > 50
                    ? ' Name is Too Long! (Max 50 symbols)'
                    : '';
                break;
            case 'rtn':
                errorIs.rtn = !value.length
                    ? 'Routing Number is required!'
                    : !(value.length > 8)
                    ? 'Routing Number is Too short (Must be at Least 9 character)'
                    : value.length > 9
                    ? 'Routing Number is Too Long! (Max 9 symbols)'
                    : '';
                break;
            case 'accountNo':
                errorIs.accountNo = !value.length
                    ? 'Account Number is required!'
                    : value.length > 50
                    ? 'Account Number Is Too Long (Must be 50 characters)'
                    : '';
                break;
            case 'acctype':
                errorIs.acctype = !value.length || value == 'Account Type'
                    ? 'Account Type is required!'
                    : '';
                break;
           
            default:
                break;
        }
        return errorIs;
    };

       validation() {
        const { errorIs, nameOnAccount, rtn, accountNo, acctype, termsAgree} = this.state;
        errorIs.nameOnAccount = !nameOnAccount.length
            ? 'Name is required!'
            : !(nameOnAccount.length > 1)
            ? 'Name is Too short (Must be at Least 2 character)'
            : nameOnAccount.length > 50
            ? 'Name is Too Long! (Max 50 symbols)'
            : '';
        errorIs.rtn = !rtn.length
            ? 'Routing Number is required!'
            : !(rtn.length > 8)
            ? 'Routing Number Is Too short (Must be at Least 9 characters)'
            : rtn.length > 9
            ? 'Routing Number Is Too Long (Must be 9 characters'
            : '';
        errorIs.accountNo = !accountNo.length
            ? 'Account Number is required!'
            : accountNo.length > 50
            ? 'Account Number Is Too Long (Must be 50 characters)'
            : '';
        errorIs.acctype = !acctype.length || acctype == 'Account Type' ? 'Account Type is required!'  : '';
        errorIs.termsAgree = termsAgree == false ? 'Please Accept Terms of Use' : '';
        this.setState({
            errorIs,
        });
        return (
            errorIs &&
            !errorIs.nameOnAccount &&
            !errorIs.rtn &&
            !errorIs.accountNo &&
            !errorIs.acctype &&
            !errorIs.termsAgree
        );
    }

    getCustomerData() {
        const {
          country,
          firstName,
          lastName,
          licence,
          email,
          phoneNumber,
          address,
          city,
          state,
          zip,
          ssn,
          dob,
        } = this.props.formValues;

        let data = {
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          Phone: phoneNumber,
          Address1: address,
          Address2: "",
          City: city,
          StateRegion: state,
          PostalCode: zip,
          Country: country,
          licence: licence,
          ssn: ssn,
          dob: dob + 'T12:00:00',
        };

    return data;
  }

    verifyUserDetails() {
        const {
          country,
          firstName,
          lastName,
          licence,
          email,
          phoneNumber,
          address,
          city,
          state,
          zip,
          ssn,
          dob,
        } = this.props.formValues;

        let params = {
          FirstName: firstName,
          LastName: lastName,
          EmailAddress: email,
          PhoneNumber: phoneNumber,
          DateOfBirth: moment(dob).format("YYYY-MM-DD"),
          AddressLine1: address,
          City: city,
          State: state,
          ZipCode: zip,
          Country: country == "usa" ? "US" : country,
          DlNumber: licence,
          DlState: state,
          TaxId: ssn,
          NameOnAccount: this.state.nameOnAccount,
          AccountNumber: this.state.accountNo,
          RoutingNumber: this.state.rtn,
          AccountType: 0,
      };
      


        verifyUser(params).then((response) => {
          if (response && response.flag) {
            this.createCustomer();
            this.setState({customerVerification : true, success : true})
          } else {
            this.setState({ sendingForm: false, step: 1, LoadingIs: false });
            let msg =
              "Customer verification failed!!. Please check your information & submit again.";

            // if (response.data && response.data.email && !response.data.email.flag) {
            //   msg = response.data.email.label;
            // }

            if (
              response.data &&
              response.data.customer &&
              !response.data.customer.flag
            ) {
              msg = response.data.customer.label;
            }
            this.setState({resMsg : msg, resMsgModal: true, customerVerification :false, success : false,LoadingIs : false})
          }
        });
  }

    createCustomer() {
    
      let customer = this.getCustomerData();
      createJHCustomer(customer)
        .then((resp) => {
          if (resp && resp.customer_number) {
            this.setState({ achVerification: true, success: true });
            this.sendProfileForm();
          } else {
            let message = resp.error
              ? resp.error
              : "ACH account verification failed!";
            this.setState({resMsg : message, resMsgModal: true ,LoadingIs : false, achVerification :false, success : false})
          }
        })
        .catch((err) => {
          this.setState({resMsg : '', resMsgModal: false ,LoadingIs : false, success: false, achVerification: false})
          //this.props.achclose();
          // handleCommonError("message", err);
        });
      }

    sendProfileForm = async (household) => {
    const { formValues } = this.props;
    let account = {
      NameOnAccount: this.state.nameOnAccount,
      AccountName: formValues.firstName,
      AccountNumber: this.state.accountNo,
      RoutingNumber: this.state.rtn,
    };
    createJHAccount(account)
      .then((resp) => {
         this.setState({LoadingIs : false})
        if (resp && resp.account_reference_id) {
           this.setState({resMsg : 'ACH account setup is completed!!', resMsgModal: true, success : true, achVerification : true})
          this.setState({ sendingForm: false }, () => {
           // this.props.achclose();
          });
        } else {
          this.setState({ sendingForm: false });
          let message = resp.error
            ? resp.error
            : "ACH account creation failed!";
      
           this.setState({resMsg : message, resMsgModal: true,success : false, achVerification: false})
           
        }
      })
      .catch((err) => {
        this.setState({ sendingForm: false, LoadingIs : false, success : false, achVerification: false });
        this.props.achclose();
      });
    };


    render() {
       const { errorIs, resMsg, resMsgModal, LoadingIs, achVisible} = this.state;
        return (
           <React.Fragment>
            { resMsg  && (
                    <Modal
                        centered
                        visible={resMsgModal}
                        backdrop="static"
                        height={200}
                        className="modal-container req-card"
                        maskClosable={false}
                        onCancel={() => this.handleMsgClose()}
                    >
                        <div className="modal-head">
                            <div style={{textAlign: 'center', width: '80%', margin: 'auto', fontWeight: '700', marginBottom: '15px'}}>
                                {resMsg}
                            </div>
                            <Button type="primary" style={{width: '80%', marginTop: '10px', border: 'none', borderRadius: '4px' }} onClick={() =>  this.handleMsgClose()} className="btn fp-button-primary">
                               Ok
                            </Button>
                        </div>
                    </Modal>
            )}

            <Modal
                title="Account Setup"
                centered
                visible={achVisible}
                onCancel={() => this.props.achclose()}
                className="account-modal pay-setup-modal"
            >

            <LoadingOverlay
                active={LoadingIs}
                className="d-block pop-loader"
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '100px',
                        '& svg circle': {
                            stroke: 'rgb(56, 160, 235)',
                        },
                    }),
                }}
            >
            </LoadingOverlay>
                <form>
                    <div className="content-wrap">
                        <div className="float-wrap">
                            <FloatLabel label="Name on Account" value={this.state.nameOnAccount}>
                                <Input
                                    name="nameOnAccount"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.nameOnAccount}
                                />
                            </FloatLabel>
                           {errorIs.nameOnAccount ? (
                                 <div className="err-msg">{errorIs.nameOnAccount}</div>
                            ) : null}
                        </div>
                        <div className="float-wrap">
                            <FloatLabel label="Routing Number" value={this.state.rtn}>
                                <Input
                                    name="rtn"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.rtn}
                                />
                            </FloatLabel>
                          {errorIs.rtn ? <div className="err-msg">{errorIs.rtn}</div> : null}
                        </div>
                        <div className="float-wrap">
                            <FloatLabel label="Account Number" value={this.state.accountNo}>
                                <Input
                                    name="accountNo"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.accountNo}
                                />
                            </FloatLabel>
                           {errorIs.accountNo ? <div className="err-msg">{errorIs.accountNo}</div> : null}
                        </div>
                        <div className="float-wrap">
                            <Select  name="acctype" value={this.state.acctype}
                                onChange={this.handleAccType} className="cust-selct">
                                 {selectAccountType.map((type, i) => (
                                    <Option value={type.id} key={i}>
                                        {type.name}
                                    </Option>
                                ))}
                            </Select>
                           {errorIs.acctype ? <div className="err-msg">{errorIs.acctype}</div> : null}
                        </div>
                        <div className="cb-wrap">
                            <Checkbox checked={this.state.termsAgree} onChange={() => this.setTermsUseVisible(true)}  >
                                I agree to Terms of Use
                            </Checkbox>
                            {errorIs.termsAgree ? <div className="err-msg">{errorIs.termsAgree}</div> : null}
                        </div>
                    </div>
                 
                    
                   <div className="btn-wrap btn-flex">
                         <Button
                            type="primary"
                            className="fp-button-secondary btn-back"
                            onClick={() => this.handleSetupForm()}
                        >
                            <span className="img-wrap">
                                <img src={left} alt="left-arrow" />
                            </span>
                        </Button>
                        <Button
                            type="primary"
                            className="fp-button-secondary btn-add-req"
                             onClick={() => {
                                if (!this.state.sendingForm) {
                                  this.setState({ sendingForm: true }, () => {
                                    this.onConfirm();
                                  });
                                }
                            }}
                        >
                            <span>Register ACH Account</span>
                        </Button>
                    </div>
                    <div className="bottom-info">
                        <span>Receive and Send Funds Electronically.</span>
                        <span>STEP 2 0f 2</span>
                    </div>
                </form>
                <TermsOfUse 
                    termsVisible={this.state.termsUseVisible}
                    termsStatus = {this.state.termsAgree}
                    viewOnly = {Boolean(false)}
                    updateTermsAgree={(val) => this.updateTermsState(val)}
                    onClose={() => this.setTermsUseVisible(false)}
               /> 
            </Modal>

             </React.Fragment>
              
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparents, coparent } = serviceReducer;
    return { user, error, children, coparents, coparent };
};

export default connect(mapStateToProps)(AccountSetup);
