import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import PrivateRoute from 'container/privateRoute';

import MainLayout from 'pages/MainLayout';

import Signin from 'pages/Signin/Signin';
import LandingPage from 'pages/Landing/Landing';
import Household from 'pages/Household/Household';
import Payment from 'pages/Payment/Payment';
import Subscription from 'pages/SubscriptionNew/Subscription';
import CalenderInner from 'pages/CalenderInner/CalenderInner';
import SubscriptionDetails from 'pages/SubscriptionNew/SubscriptionDetails';
import Signup from 'pages/Signin/Signup';
import ForgotPassword from 'pages/Signin/ForgotPassword';
import ResetPassword from 'pages/Signin/ResetPassword';
import SignupTrial from 'pages/Signin/SignupTrial';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import Chat from 'pages/Chat/Chat';
import Reports from 'pages/Reports/Reports';
import Notifications from 'pages/Notifications/Notifications'

/*import ProfilePage from "pages/ProfilePage";
import HomePage from "pages/HomePage";

import DiListPage from "pages/DiListPage";
import DiAddPage from "pages/AddInventoryPage";

import ManagePoliciesPage from "pages/ManagePoliciesPage";
import NewPolicyPage from "pages/NewPolicyPage";

import ManageLoansPage from "pages/ManageLoansPage";
import NewLoanPage from "pages/NewLoanPage";

import ManageUserAccountPage from "pages/ManageUserAccountPage";
import LandingPage from "pages/LandingPage";

import InstantQuotePage from "pages/InstantQuotePage";*/

const ROUTES_OPTIONS = [
    {
        path: ROUTES.LANDING_PAGE,
        component: Signin,
        exact: true,
        private: true,
    },
    {
        path: ROUTES.HOME,
        component: LandingPage,
        exact: false,
        private: true,
    },
    {
        path: ROUTES.HOUSEHOLD,
        component: Household,
        exact: false,
        private: true,
    },
    {
        path: ROUTES.PAYMENT,
        component: Payment,
        exact: false,
        private: true,
    },
    {
        path: ROUTES.SUBSCRIPTION,
        component: Subscription,
        exact: false,
        private: true,
    },
    {
        path: ROUTES.CALENDERINNER,
        component: CalenderInner,
        exact: false,
        private: true,
    },
    {
        path: ROUTES.SUBSCRIPTIONDETAILS,
        component: SubscriptionDetails,
        exact: false,
        private: true,
    },
    {
        path: ROUTES.FORGOTPASSWORD,
        component: ForgotPassword,
        exact: false,
        private: false,
    },
    {
        path: ROUTES.SIGNUP,
        component: Signup,
        exact: false,
        private: false,
    },
    {
        path: ROUTES.RESETPASSWORD,
        component: ResetPassword,
        exact: false,
        private: false,
    },
    {
        path: ROUTES.SIGNUPTRIAL,
        component: SignupTrial,
        exact: false,
        private: false,
    },
    {
        path: ROUTES.ERRORPAGE,
        component: ErrorPage,
        exact: false,
        private: false,
    },
    {
        path: ROUTES.CHAT,
        component: Chat,
        exact: false,
        private: false,
    },
    {
        path: ROUTES.CHAT_ID,
        component: Chat,
        exact: false,
        private: false,
    },
    {
        path: ROUTES.CHAT_NEW,
        component: Chat,
        exact: false,
        private: false,
    },
    {
        path: ROUTES.CHAT_TOPIC,
        component: Chat,
        exact: false,
        private: false,
    },
    {
        path: ROUTES.CHAT_ARCHIVED,
        component: Chat,
        exact: false,
        private: false,
    },
    {
        path: ROUTES.CHAT_ARCHIVED_ID,
        component: Chat,
        exact: false,
        private: false,
    },
    {
        path: ROUTES.REPORTS,
        component: Reports,
        exact: false,
        private: false,
    },
    {
        path: ROUTES.NOTIFICATIONS,
        component: Notifications,
        exact: false,
        private: false,
    },
];

const Routes = () => (
    <MainLayout>
        <Switch>
            {ROUTES_OPTIONS.map(({ path, component: Component, private: priv, exact, ...rest }, i) => {
                const render = (props) => <Component {...Object.assign({}, props, rest)} />;

                const componentProps: any = Object.keys(rest).length
                    ? {
                        exact,
                        render,
                        path,
                        key: path,
                    }
                    : { exact, component: Component, path, key: path };

                return priv ? <PrivateRoute {...componentProps} key={i} /> : <Route {...componentProps} key={i} />;
            })}
        </Switch>
    </MainLayout>
);

export default Routes;
