import React, { Component } from 'react';
import 'pages/CalenderInner/CalenderInner.scss';
import { Row, Col } from 'antd';
import Events from 'pages/CalenderInner/Events/Events';
import Custody from 'pages/CalenderInner/Custody/Custody';
import Swap from 'pages/CalenderInner/Swap/Swap';
import CalenderList from 'pages/CalenderInner/CalenderList/CalenderList';
import { connect } from 'react-redux';
import Modal from 'Components/Modal/ConnectionModal';
import {
    getPermission
} from 'services/memberService';
import { WindowsFilled } from '@ant-design/icons';
import { addPermission, removePermission} from 'actions/loaderActions';

class CalenderInner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible : true,
            isThirdParty : localStorage.getItem("third_party")
        };
        this.setModalVisible = this.setModalVisible.bind(this);
    }
    
    componentDidMount() {
        if (localStorage.third_party === 'true') {
            let data = {
                user_id: localStorage.user_id,
                household_id: localStorage.household_id
            };
            let promises = [];
            promises.push(
                getPermission(data)
                    .then(resp => {
                        if (resp && resp.success && resp.permission) {
                            if (resp.permission.calendar === false) {
                                window.location.href = '/home';
                            }
                            localStorage.setItem('third_party_permission', JSON.stringify(resp.permission));
                            localStorage.setItem('calendar_permission', resp.permission.calendar);
                            localStorage.setItem('payment_permission', resp.permission.payment);
                            localStorage.setItem('chat_permission', resp.permission.chat);
                        }
                    })
            );

            Promise.all(promises)
                .then(() => {
                    this.props.dispatch(addPermission());
            })
        }
    }

    setModalVisible = async function(visible){
        await this.setState({modalVisible: visible});
    }

    render() {
        const {error} = this.props;
        return (
            <div>
            {error ? <Modal handleOpen={this.state.modalVisible} maskClosable={false} handleClose={() => this.setModalVisible(false)}></Modal> : null}
            <Row className="row-container" gutter={'10'}>
                <Col xs={24} lg={this.state.isThirdParty == 'true' ? 12 : 10} className="calender-wrap">
                    <CalenderList />
                </Col>
                <Col xs={24} lg={this.state.isThirdParty == 'true' ? 12 : 7} className="todo-wrap">
                    <Events />
                </Col>
                {this.state.isThirdParty == 'false' && (
                <Col xs={24} lg={7} className="col-wrap">
                    <Swap />
                    <Custody />
                </Col>
                )}
            </Row>
            </div>
        );
    }
}

const mapStateToProps = ({serviceReducer}) => {
    const {error} = serviceReducer;
    return {error};
}

export default connect(mapStateToProps)(CalenderInner);
