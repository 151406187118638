import React, { Component } from 'react';
import 'pages/Landing/SetUpCheckList/SetupCheckList.scss';
import { Card, Empty, Button } from 'antd';
//import TransactionCard from 'pages/Landing/SetUpCheckList/RecentTransactionsCard';
import ScrollArea from 'react-scrollbar';
import { getSetupProgress } from 'services/userService';
import { getCheckListAction} from 'actions/userActions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import SetupCheckListModal from 'pages/Landing/SetUpCheckList/SetupCheckListModal';
import {removeLoading} from 'actions/loaderActions';
import cancel from 'Assets/cancel.svg';

class SetupCheckList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coparent: null,
            loading: false,
            currentPayment: null,
            modal1Visible: false,
            modal2Visible: false,
            setUpModalVisible: false,
            setUpCheckListCount : 0,
            setupProgress : [],
            baseListStatus : false,
        };
        this.setModal2Visible = this.setModal2Visible.bind(this);
    }


    componentDidMount() {
        if (localStorage.third_party !== 'true') {
            this.props.dispatch(getCheckListAction());
            this.getSetupProgress();
        }
     
    }

    componentDidUpdate(prevProps) {
      this.props.dispatch(getCheckListAction());
    }

    getSetupProgress = function () {
        this.setState({loading : true})
        getSetupProgress().then(async (resp) => {
            if (resp) {
                var count = 0
                var baseListStatus = false
                await this.setState({setupProgress : resp})  
                if( typeof resp === 'object'){
                     const calendarEnabled =
                            !!resp["profile"] &&
                            !!resp["coParent"] &&
                            !!resp["children"];
                        const paymentEnabled =
                            
                            !!resp["profile"] &&
                            !!resp["coParent"] &&
                            !!resp["children"];
                        const chatEnabled =
                            
                            !!resp["profile"] &&
                            !!resp["coParent"] &&
                            !!resp["children"];
                        const reportsEnabled =
                            
                            !!resp["profile"] &&
                            !!resp["coParent"] &&
                            !!resp["children"];
                         baseListStatus = calendarEnabled && paymentEnabled && chatEnabled && reportsEnabled
                    await  Object.keys(resp).map((item, i) => {
                        if(resp[item] == 1) {
                            count++
                        }
                    })
                }
                this.setState({setUpCheckListCount : count,baseListStatus,loading:false})
            } else {
             this.setState({loading:false})
            }
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps && nextProps.error !== null) {
           this.setState({loading: false});
        }
        if(nextProps && nextProps.err !== null) {
            this.setState({loading: false});
        }
        
    }
    
    setModal2Visible = async function(modalVisible ) {
        await this.setState({setUpModalVisible: modalVisible});
    }


    render() {
        const { user } = this.props;
        const { coparent, currentPayment, loading, setUpCheckListCount } = this.state;
        return (
            <Card bordered={false} className="trans-card card-rec-trans checklist-card">
                <LoadingOverlay
                    active={loading ? true : false}
                    className="d-block cust-loader"
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '30px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <div className="card-head ">
                    <span>Welcome to kincrew ! Click the setup checklist to get started</span>
                     <div className="checklist-div">
                         <Button className="fp-button-primary checklist-btn-wrap" type="primary" 
                             onClick={() =>this.setModal2Visible(true)}>
                                        <span>Setup Checklist {setUpCheckListCount}/7</span>
                         </Button>
                        {/*<span>
                            <img src={cancel} alt="cancel-icon"/>
                         </span>*/}
                    </div>
                </div>
               <SetupCheckListModal 
               handleOpen={this.state.setUpModalVisible}
               handleClose = {() =>this.setModal2Visible(false)}
               checkList = {this.state.setupProgress}
               baseListStatus = {this.state.baseListStatus}/>
            </Card>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, coparent, loadings, error } = serviceReducer;
    return { user, coparent, loadings, error };
};
export default connect(mapStateToProps)(SetupCheckList);
