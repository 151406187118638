import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getDate, getDayStart, getDayEnd } from '@wojtekmaj/date-utils';

import Tile from '../Tile';

import { isWeekend } from '../shared/dates';
import { formatLongDate as defaultFormatLongDate } from '../shared/dateFormatter';
import { tileProps } from '../shared/propTypes';


export default function Day({
  formatLongDate = defaultFormatLongDate,
  calendarType,
  classes,
  markedDates,
  highlightDates,
  currentMonthIndex,
  ...otherProps
}) {

  const formatDate = (date) => {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');  
  }

  let arr = [];
  if(Array.isArray(markedDates)){
    for(let i = 0;i < markedDates.length;i++){
      arr.push(formatDate(markedDates[i]));
    }
  }
  
  let darr = [];
  if(highlightDates){
    if(Array.isArray(highlightDates)){
      for(let i = 0;i < highlightDates.length;i++){
        darr.push(formatDate(highlightDates[i]));
      }
    }
  }

  let className = 'react-calendar__month-view__days__day ';
  const { date } = otherProps;
  
  if(darr.includes(formatDate(date))){
    className += 'highlight-date';
  }

  if(arr.includes(formatDate(date))){
    className += 'selected';
  }
  
  return (
    <Tile
      {...otherProps}
      classes={[].concat(
        classes,
        className,
        isWeekend(date, calendarType) ? `${className}--weekend` : null,
        date.getMonth() !== currentMonthIndex ? `${className}--neighboringMonth` : null,
      )}
      formatAbbr={formatLongDate}
      maxDateTransform={getDayEnd}
      minDateTransform={getDayStart}
      view="month"
    >
      {getDate(date)}
    </Tile>
  );
}

Day.propTypes = {
  ...tileProps,
  currentMonthIndex: PropTypes.number.isRequired,
  formatLongDate: PropTypes.func,
};
