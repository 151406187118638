import React, { Component } from 'react';
import 'pages/Payment/Payment.scss';
import { Row, Col } from 'antd';
import NewTransactions from 'pages/Payment/NewTransactions/NewTransactions';
import Categories from 'pages/Payment/Categories/Categories';
import RecurringPayments from 'pages/Payment/RecurringPayments/RecurringPayments';
import SetupCard from 'pages/Payment/Register/SetupCard';
import Payments from 'pages/Payment/Payments/Payments';
import { connect } from 'react-redux';
import Modal from 'Components/Modal/ConnectionModal';
import {
    getPermission
} from 'services/memberService';
import { addPermission, removePermission } from 'actions/loaderActions';

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: true,
        };
        this.setModalVisible = this.setModalVisible.bind(this);
    }

    componentDidMount() {
        if (localStorage.third_party === 'true') {
            let data = {
                user_id: localStorage.user_id,
                household_id: localStorage.household_id
            };
            let promises = [];
            promises.push(
                getPermission(data)
                    .then(resp => {
                        if (resp && resp.success && resp.permission) {
                            if (resp.permission.payment === false) {
                                window.location.href = '/home';
                            }
                            localStorage.setItem('third_party_permission', JSON.stringify(resp.permission));
                            localStorage.setItem('calendar_permission', resp.permission.calendar);
                            localStorage.setItem('payment_permission', resp.permission.payment);
                            localStorage.setItem('chat_permission', resp.permission.chat);
                        }
                    })
            );

            Promise.all(promises)
                .then(() => {
                    this.props.dispatch(addPermission());
            })
        }
    }
    
    setModalVisible = async function (visible) {
        await this.setState({ modalVisible: visible });
    };

    render() {
        const { error } = this.props;
        let normalUser = localStorage.third_party !== 'true';
        let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';

        return (
            <div>
                {error ? (
                    <Modal
                        handleOpen={this.state.modalVisible}
                        maskClosable={false}
                        handleClose={() => this.setModalVisible(false)}
                    ></Modal>
                ) : null}
                <Row className="row-container" gutter={'10'}>
                    <Col xs={24} lg={normalUser ? 10: 12} className={normalUser ? "calender-wrap" : "calender-wrap card-height"}>
                        {normalUser ? <SetupCard /> : null}
                        <NewTransactions err={error} />
                    </Col>
                    <Col xs={24} lg={normalUser ? 7: 0} className="todo-wrap">
                       {normalUser ? <Categories err={error} /> : null}
                    </Col>
                    <Col xs={24} lg={normalUser ? 7 : 12} className="col-wrap">
                        <RecurringPayments err={error} />
                        <Payments err={error} />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { error } = serviceReducer;
    return { error };
};

export default connect(mapStateToProps)(Landing);
