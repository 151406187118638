import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import {
    updateBundlePaymentStatus,
    makeDebitBundlePayment,
    getUserCustomerDetails,
    getCoparentCustomerDetails,
} from 'services/paymentService';
import PreviewModal from 'pages/Payment/Payments/PreviewModal';
import ACHErrorModal from 'pages/Payment/Payments/ACHErrorModal';
import ACHReceiverErrorModal from 'pages/Payment/Payments/ACHReceiverErrorModal';
import EpaymentErrorModal from 'pages/Payment/Payments/EpaymentErrorModal';
import {addReloading} from 'actions/loaderActions';

import { PaymentConst } from 'pages/Payment/paymentConstants';

const { Option } = Select;
const { TextArea } = Input;

const pay_methods = {
    Epayment: 'ACH',
    credit_card: 'Credit Card',
    cash: 'Cash',
    check: 'Check',
};

class PaymentReviewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pay_amount: 0,
            payment_ids: [],
            payments: [],
            new_payments: [],
            paymentMethod: '',
            confirm_code: '',
            file: null,
            error: false,
            fee: 0,
            modalVisible: false,
            modal1Visible: false,
            modal2Visible: false,
            modal3Visible: false,
            coParentId: [],
            new_attachments: [],
            total: 0,
        };
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.setModal3Visible = this.setModal3Visible.bind(this);
        this.handleConfirmCode = this.handleConfirmCode.bind(this);
        this.getFormattedDate = this.getFormattedDate.bind(this);
        this.handleImageDelete = this.handleImageDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePay = this.handlePay.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.getBase64 = this.getBase64.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.error !== null) {
            this.setState({ loading: false });
        }
        if (nextProps && nextProps.err !== null) {
            this.setState({ loading: false });
        }
        if (nextProps && nextProps.handleOpen && nextProps.payments && nextProps.user) {
            let user = nextProps.user;
            let arr = [];
            let farr = [];
            nextProps.payments.forEach((payment) => {
                if (nextProps.payment_ids.includes(payment.id)) {
                    arr.push(payment);
                    if (payment.payer_id === user.id) {
                        farr.push(payment.receiver_id);
                    } else if (payment.receiver_id === user.id) {
                        farr.push(payment.payer_id);
                    }
                }
            });

            let ar = [];
            farr.forEach((item) => {
                if (ar.includes(item)) {
                } else {
                    ar.push(item);
                }
            });

            let amt = parseFloat(nextProps.pay_amount) + parseFloat(ar.length * PaymentConst.transfer_fee);
            amt = parseFloat(amt).toFixed(2);

            this.setState({
                total: amt,
                paymentMethod: '',
                new_attachments: [],
                error: false,
                payments: arr,
                fee: parseFloat(ar.length * PaymentConst.transfer_fee).toFixed(2),
                coParentId: farr,
                pay_amount: nextProps.pay_amount.toFixed(2),
                payment_ids: nextProps.payment_ids,
            });
        }
    }

    getFormattedDate = function (dateObj) {
        let month = dateObj.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = dateObj.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = dateObj.getFullYear();
        let output = month + '/' + day + '/' + parseInt(year.toString());
        return output;
    };

    handleChange = async function (value) {
        await this.setState({ paymentMethod: value, error: false });
    };

    handleConfirmCode = function (e) {
        this.setState({ confirm_code: e.target.value });
    };

    setModalVisible = function (visible, file) {
        this.setState({ modalVisible: visible, file: file });
    };

    setModal1Visible = async function (modalVisible) {
        await this.setState({ modal1Visible: modalVisible });
    };

    setModal2Visible = async function (modalVisible) {
        await this.setState({ modal2Visible: modalVisible });
    };

    setModal3Visible = async function (modalVisible) {
        await this.setState({ modal3Visible: modalVisible });
    };

    handleImageDelete = function (payment, file, index) {
        const { payments } = this.state;
        let arr = payments;
        payments.forEach(async (item, i) => {
            if (item.id === payment.id) {
                let temp = {};
                payment.attachment.splice(index, 1);
                temp = payment;
                arr.splice(i, 1, temp);
                await this.setState({ payments: arr });
            }
        });
    };

    getBase64 = function (file) {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (upload) {
                resolve(upload.target.result);
            };
            reader.onerror = function (error) {
                
            };
        });
    };

    handleUpload = async function (event, payment) {
        const { payments } = this.state;
        let arr = payments;
        var reader = new FileReader();
        var file = event.target.files[0];
        var name = event.target.files[0].name;
        let url = URL.createObjectURL(file);
        let base64;

        base64 = await this.getBase64(file);

        let upload = {
            uri: url,
            name: name,
            base64: base64,
        };

        let index;
        payments.forEach((item, i) => {
            if (item.id === payment.id) {
                index = i;
            }
        });

        let updatedPayment = payments[index];
        updatedPayment.attachment.push(upload);

        arr.splice(index, 1, updatedPayment);

        await this.setState({ payments: arr, new_attachments: [...this.state.new_attachments, upload] });
    };

    handlePay = function () {
        const {
            paymentMethod,
            coParentId,
            total,
            payments,
            new_attachments,
            pay_amount,
            fee,
            payment_ids,
            confirm_code,
        } = this.state;
        const { user } = this.props;
        let accountReference;
        let flag = false;
        if (paymentMethod.length === 0) {
            this.setState({ error: true });
        } else {
            if (paymentMethod === 'Epayment') {
                if (pay_amount <= 998.75) {
                    getUserCustomerDetails().then((resp) => {
                        if (resp) {
                            if (resp.account && resp.account.id && resp.account.account_status == 1) {
                                accountReference = resp.account.account_reference_id;
                                getCoparentCustomerDetails().then((resp) => {
                                    if (resp && resp.all_data) {
                                        resp.all_data.forEach((coparent) => {
                                            if (coparent && coparent.coparent_id) {
                                                if (coParentId.includes(coparent.coparent_id)) {
                                                    if (coparent.coparent_account !== false) {
                                                        flag = true;
                                                    } else {
                                                        flag = false;
                                                    }
                                                }
                                            }
                                        });

                                        if (flag) {
                                            this.setState({ loading: true });
                                            let data = {
                                                coParentId: coParentId,
                                                Amount: total,
                                                paymentId: payment_ids,
                                                fee: parseInt(fee),
                                                accountReferenceId: accountReference,
                                                payments: payments,
                                            };

                                            makeDebitBundlePayment(data).then((resp) => {
                                                this.setState({ loading: false });
                                                this.props.handleClose();
                                                this.props.refresh();
                                                this.props.dispatch(addReloading());
                                            });
                                        } else {
                                            this.setModal2Visible(true);
                                        }
                                    }
                                });
                            } else {
                                this.setModal1Visible(true);
                            }
                        }
                    });
                } else {
                    this.setModal3Visible(true);
                }
            } else {
                this.setState({ loading: true });
                let options = {
                    status: 'paid',
                    approved_by: user.id,
                    approved_date: new Date().toISOString(),
                    confirm_code: confirm_code,
                    payment_method: paymentMethod,
                    paid: true,
                };

                if (new_attachments.length !== 0) {
                    options.attachment = new_attachments;
                }

                let data = {
                    paymentIds: payment_ids,
                    options: options,
                };

                updateBundlePaymentStatus(data).then((resp) => {
                    this.setState({ loading: false });
                    this.props.handleClose();
                    this.props.refresh();
                    this.props.dispatch(addReloading());
                });
            }
        }
    };

    render() {
        const { pay_amount, payments, loading, fee, total, paymentMethod } = this.state;
        const { user } = this.props;
        return (
            <Modal
                title="Payments Review"
                centered
                visible={this.props.handleOpen}
                maskClosable={false}
                className="payments-popup ach-payment trans-detail"
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>

                <Form>
                    <div className="pay-wrap">
                        <div className="detail-wrap">
                            {payments && Array.isArray(payments) && payments.length !== 0
                                ? payments.map((payment, i) => (
                                      <div className="payment-detail" key={i}>
                                          <div className="detail">
                                              <span>
                                                {user ? payment.payer_username : null}
                                              </span>
                                              <span>{`$${payment.payer_amount}`}</span>
                                          </div>
                                          <div className="detail">
                                              <span>
                                                  {payment && payment.PaymentCategory && payment.PaymentCategory.name}
                                              </span>
                                              <span>{this.getFormattedDate(new Date(payment.payment_date))}</span>
                                          </div>
                                      </div>
                                  ))
                                : null}
                        </div>
                        <Form.Item>
                            <div className="select-wrap">
                                <Select
                                    value={paymentMethod.length === 0 ? 'Payment Method' : pay_methods[paymentMethod]}
                                    onChange={this.handleChange}
                                >
                                    <Option value="Epayment">ACH</Option>
                                    <Option value="credit_card">Credit Card</Option>
                                    <Option value="cash">Cash</Option>
                                    <Option value="check">Check</Option>
                                </Select>
                                {this.state.error ? <div className="field-err">This field is required</div> : null}
                            </div>
                        </Form.Item>

                        <Form.Item>
                            <Form.Item className="input-sm input-code">
                                <Input onChange={this.handleConfirmCode} placeholder="Confirmation Code/Check#" />
                            </Form.Item>
                        </Form.Item>

                            {Array.isArray(payments) &&
                                payments.map((payment, i) => (
                                    payment.attachment && payment.attachment.length !== 0 && (
                                    <div key={i} className="upload-file">
                                        <div  className="modal-bottom">
                                            {Array.isArray(payment.attachment) && payment.attachment.length !== 0 ? (
                                                <span>Attachments</span>
                                            ) : null}
                                            {Array.isArray(payment.attachment) && payment.attachment.length !== 0 && (
                                                <div className="upload-wrapper">
                                                    <input
                                                        id="img"
                                                        type={payment.attachment.length <= 3 ? 'file' : 'button'}
                                                        multiple={true}
                                                        encType="multipart/form-data"
                                                        onChange={(e) => this.handleUpload(e, payment)}
                                                    />
                                                    <Button type="primary" className="add-btn fp-button-primary">
                                                        Add
                                                    </Button>
                                                </div>
                                            )}
                                        </div>

                                        {Array.isArray(payment.attachment) &&
                                            payment.attachment.length !== 0 &&
                                            payment.attachment.map((file, i) => (
                                                <div className="file-track" key={i}>
                                                    <a onClick={() => this.setModalVisible(true, file.url || file.uri)}>
                                                        View
                                                    </a>
                                                    <span
                                                        style={{ color: 'red' }}
                                                        onClick={() => this.handleImageDelete(payment, file, i)}
                                                    >
                                                        Delete
                                                    </span>
                                                </div>
                                            ))}
                                    </div>
                                    )
                        ))}
                        <div className="detail-wrap cust-mt">
                            <div className="detail">
                                <span>Amount</span>
                                <span>${pay_amount}</span>
                            </div>
                            <div className="detail">
                                <span>Transfer Fee</span>
                                <span>${paymentMethod === 'Epayment' ? fee : '0.00'}</span>
                            </div>
                        </div>

                        {paymentMethod === 'Epayment' ? (
                            <div style={{ fontSize: '10px', marginBottom: '10px' }}>
                                Payments made after 8:00PM Central Time will be processed the next business day.
                            </div>
                        ) : null}
                    </div>
                    <Form.Item className="btn-form btn-pad">
                        <Button type="primary" onClick={this.handlePay} className="btn fp-button-secondary">
                            Pay ${paymentMethod === 'Epayment' ? total : pay_amount}
                        </Button>
                    </Form.Item>
                </Form>

                <PreviewModal
                    file={this.state.file}
                    handleOpen={this.state.modalVisible}
                    handleClose={() => this.setModalVisible(false)}
                />
                <ACHErrorModal openModal={this.state.modal1Visible} closeModal={() => this.setModal1Visible(false)} />
                <ACHReceiverErrorModal
                    openModal={this.state.modal2Visible}
                    closeModal={() => this.setModal2Visible(false)}
                />
                <EpaymentErrorModal
                    openModal={this.state.modal3Visible}
                    closeModal={() => this.setModal3Visible(false)}
                />
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparent } = serviceReducer;
    return { user, error, children, coparent };
};

export default connect(mapStateToProps)(PaymentReviewModal);
