import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import 'pages/Household/ThirdParty/ThirdParty.scss';
import { connect } from 'react-redux';
import { Card, Button, Modal, Form, Radio, Select, Input, Empty } from 'antd';
import ScrollArea from 'react-scrollbar';
import AddThirdParty from './AddThirdParty';

class ThirdParty extends Component {
    state = {
        LoadingIs: true,
        modalVisible: false,
        data: '',
        editIs: false,
        deleteIs: false,
        addIs : false,
        flag4: false,
    };


    setIndexState = (stateObj) => {
        this.setState(stateObj);
    };

    setModalVisible(modalVisible) {
        this.setState({ modalVisible, deleteIs: false });
    }

    handleEdit = (obj) => {
        this.setState({
            deleteIs: false,
            editIs: true,
            modalVisible: true,
            data: obj ? obj : '',
        });
    };

    handleDelete = (obj) => {
        this.setState({
            deleteIs: true,
            modalVisible: true,
            data: obj ? obj : '',
        });
    };

    refreshPage = () => {
        this.setState({flag4: true, LoadingIs: true});
    }

    componentWillReceiveProps(props) {
        if(props.response && props.response.houseHoldReducer && props.response.houseHoldReducer.houseHold){
            if(this.state.flag4){
                this.setState({flag4: false, LoadingIs: false}); 
            }
            if(this.state.LoadingIs === true){
                this.setState({LoadingIs: false});
            }
        }

        if (props) {
            setTimeout(() => {
                this.setState({
                    LoadingIs: false,
                });
            }, 1000);
        }
    }

    render() {
        const { response } = this.props;
        let data = response.houseHoldReducer.houseHold;
        let coparentData = response.serviceReducer.coparent;
        let flag = 0;
         function getRelation(item){
           if(item == "0" || item == 0) {
                return "Extended Family"
           } else if(item == "1" || item == 1) {
                return "Child Care"
           } else if(item == "2" || item == 2) {
                return "Other"
           } else {
                return ""
           }
        }
        return (
            <Card bordered={false} className="household-card party-card req-card">
             {
              (this.state.LoadingIs) &&
              <LoadingOverlay
                    active={true}
                    className="d-block cust-loader"
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                >

              </LoadingOverlay>
            }         
                <React.Fragment>
                    <div className="head cust-head">
                        <span>3rd Party</span>
                        <Button
                            type="primary"
                            className="add-item"
                            shape="circle"
                            size="small"
                            onClick={() => this.setModalVisible(true)}
                        >
                            +
                        </Button>
                        {/*-----------------------Add Third Party Modal --------------------------*/}

                        <AddThirdParty 
                        {...this.state} 
                        {...this.props} 
                        handleFunc={this.setIndexState}
                        refresh={() => this.refreshPage()} 
                        />

                        {/*-----------------------Add Third Party Modal --------------------------*/}
                    </div>
                    <div className="party-lis card-scroll-wrap">
                        <React.Fragment>
                            {typeof data === 'object' && Object.keys(data).length
                                ? data.Members.map((obj, index) => {
                                      if (obj.type !== 'child') {
                                          return (
                                              <Card bordered={false} className="party-inner" key={index}>
                                                  <div className="relation">
                                                  <div hidden>{obj.id ? flag = 1 : ''} </div>
                                                      <span>{obj.name}</span>
                                                       <span className="tp-relation">{getRelation(obj.relation)}</span>
                                                  </div>
                                                  <div className="name">
                                                     
                                                      <span onClick={() => this.handleEdit(obj)}>
                                                          <svg
                                                              className="icon-_size edit"
                                                              version="1.1"
                                                              id="Capa_1"
                                                              viewBox="0 0 512.001 512.001"
                                                              style={{ enableBackground: 'new 0 0 512.001 512.001' }}
                                                              xmlSpace="preserve"
                                                          >
                                                              <path
                                                                  d="M496.063,62.299l-46.396-46.4c-21.199-21.199-55.689-21.198-76.888,0C352.82,35.86,47.964,340.739,27.591,361.113
                                        c-2.17,2.17-3.624,5.054-4.142,7.875L0.251,494.268c-0.899,4.857,0.649,9.846,4.142,13.339c3.497,3.497,8.487,5.042,13.338,4.143
                                        L143,488.549c2.895-0.54,5.741-2.008,7.875-4.143l345.188-345.214C517.311,117.944,517.314,83.55,496.063,62.299z M33.721,478.276
                                        l14.033-75.784l61.746,61.75L33.721,478.276z M140.269,452.585L59.41,371.721L354.62,76.488l80.859,80.865L140.269,452.585z
                                        M474.85,117.979l-18.159,18.161l-80.859-80.865l18.159-18.161c9.501-9.502,24.96-9.503,34.463,0l46.396,46.4
                                        C484.375,93.039,484.375,108.453,474.85,117.979z"
                                                              />
                                                          </svg>
                                                      </span>
                                                      <span
                                                          style={{ marginLeft: '4px' }}
                                                          onClick={() => this.handleDelete(obj)}
                                                      >
                                                          <svg
                                                              className="icon-_size delete"
                                                              viewBox="-40 0 427 427.00131"
                                                          >
                                                              <path d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                              <path d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                              <path d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0" />
                                                              <path d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                          </svg>
                                                      </span>
                                                  </div>
                                              </Card>
                                          );
                                      }
                                  })
                                : ''}
                            {flag == 0 && (
                                <div className="empty-data ">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                            )}
                        </React.Fragment>
                    </div>
                </React.Fragment>
            </Card>
        );
    }
}

const mapStateToProps = (response) => ({ response });

export default connect(mapStateToProps)(ThirdParty);
