import React, { Component } from 'react';
import 'pages/CalenderInner/Swap/Swap.scss';
import { Card, Button, Modal, Form, Input, Select, Avatar, Empty } from 'antd';
import right from 'Assets/right-arrow.png';
import TextArea from 'antd/lib/input/TextArea';
import { connect } from 'react-redux';
import user from 'Assets/defaultAvatar.png';
import { CalendarOutlined } from '@ant-design/icons';
import Calendar from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import { setupScheduleException } from 'services/calendarService';
import LoadingOverlay from 'react-loading-overlay';
import DeleteExceptionModal from 'pages/CalenderInner/Swap/DeleteExceptionModal';
import { getScheduleException, updateScheduleException, deleteException } from 'services/calendarService';
import moment from 'moment';
import { addLoading, removeLoading } from 'actions/loaderActions';

let frequencies = [
    {
        label: 'Yearly',
        value: 'yearly',
    },
    {
        label: 'Weekly',
        value: 'weekly',
    },

    {
        label: 'Monthly',
        value: 'monthly',
    },
    {
        label: '6 Months',
        value: 'six_months',
    },
    {
        label: 'One Time',
        value: 'onetime',
    },
];

let exceptionTypes = [
    {
        label: 'Alternate custody, I get first custody',
        value: 1,
    },
    {
        label: 'Alternate custody, co-parent gets first custody',
        value: 2,
    },
    {
        label: 'I will have custody on this date',
        value: 3,
    },
    {
        label: "I won't have custody on this date",
        value: 4,
    },
];

let specialTypes = [
    {
        label: 'I will have custody on this date',
        value: 3,
    },
    {
        label: "I won't have custody on this date",
        value: 4,
    },
];

const colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    'gradient1': 'Gradient1',
    'gradient2': 'Gradient2',
};

const exception_status = {
    pending: 'Pending',
    active: 'Approved',
    declined: 'Declined',
};

class EditException extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            child_ids: [],
            frequency: '',
            custody: '',
            start_date: new Date(),
            currentDate: new Date(),
            child: null,
            loading: false,
            picture: null,
            type: 'special_date',
            coparent_id: '',
            modalVisible: false,
            modal1Visible: false,
            status: '',
            exception: null,
        };
        this.handleFrequency = this.handleFrequency.bind(this);
        this.handleCustody = this.handleCustody.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.confirmDate = this.confirmDate.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.handleOpen && nextProps.currentException) {
            this.setState({ loading: true });
            getScheduleException(nextProps.currentException.id).then((resp) => {
                this.setState({
                    title: resp.title,
                    child_ids: resp.child_ids || [],
                    frequency: resp.frequency,
                    custody: resp.is_alternate_new,
                    start_date: moment.parseZone(resp.start_date),
                    currentDate: moment(moment.parseZone(resp.start_date).format('YYYY-MM-DD')).toDate(),
                    child: (resp.Members && resp.Members[0]) || null,
                    picture: resp.Members && resp.Members[0].picture,
                    type: resp.type,
                    coparent_id: resp.coparent_id,
                    loading: false,
                    status: resp.status,
                    exception: resp,
                });
            });
        }
    }

    formatDate = function (date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return month + '/' + day + '/' + year;
    };

    handleDate = function (e) {
        this.setState({ currentDate: e });
    };

    confirmDate = async function () {
        const { currentDate } = this.state;
        await this.setState({ start_date: currentDate, modalVisible: false });
    };

    setModalVisible = function (visible) {
        this.setState({ modalVisible: visible });
    };

    setModal1Visible = function (visible) {
        this.setState({ modal1Visible: visible });
    };

    handleFrequency = async function (value) {
        if(value === 'onetime' && (this.state.custody !== 3 && this.state.custody !== 4)){
            await this.setState({ custody: ''});
        }
        await this.setState({ frequency: value });
    };

    handleCustody = async function (value) {
        await this.setState({ custody: value });
    };

    handleDelete = function () {
        const { exception } = this.state;
        this.setState({ loading: true });
        deleteException(exception.id).then((resp) => {
            this.props.handleClose();
            this.props.refresh();
            this.props.dispatch(addLoading());
        });
    };

    handleSubmit = function () {
        const { 
            title, 
            child_ids, 
            picture, 
            start_date, 
            type, 
            frequency, 
            custody, 
            coparent_id, 
            exception,
            child, 
        } = this.state;
        this.setState({ loading: true });
        let m = new Date(this.formatDate(start_date));
        let n = moment( m.setTime(moment(m).set({ 'h': 12, 'm': 0, 's': 0 }).valueOf()).valueOf());
        
        let schedule = {
            title: title,
            child_ids: [child && child.id],
            picture: picture,
            start_date: moment.parseZone(n._d).format('YYYY-MM-DD'),
            type: 'special_date',
            frequency: frequency,
            is_alternate_new: custody,
            child_isd: [child && child.id],
            coparent_id: coparent_id,
        };
        
        updateScheduleException(exception.id, schedule).then((resp) => {
            this.setState({ loading: false });
            this.props.handleClose();
            this.props.refresh();
            this.props.dispatch(addLoading());
        });
    };

    render() {
        const { Option } = Select;

        const {
            title,
            child_ids,
            child,
            frequency,
            status,
            picture,
            custody,
            start_date,
            loading,
            currentDate,
        } = this.state;

        let today = new Date();

        let correctDate = moment(this.formatDate(start_date)) >= moment(this.formatDate(today));

        return (
            <Modal
                title="Schedule Exception Setup"
                className="cal-modal swap-modal exception-modal"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Form className="schedule-form">
                    <div className="scroll-wrap enhance-height">
                        <div className="invite-top">
                            <span>{title}</span>
                            <div className="avatar-wrap">
                                <Avatar size={60} src={picture ? picture.url : user} alt="avatar-image" />
                            </div>
                        </div>
                        <Form.Item className="child-input">
                            <Input className="cinput" value={child && child.name} readOnly />
                        </Form.Item>
                        <Form.Item
                            className="input-sm icon-input"
                            label="Select Exception Date"
                            labelCol={{ span: 24 }}
                        >
                            <Input
                                value={moment.parseZone(start_date).format('MM/DD/YYYY')}
                                placeholder="Select Exception Date"
                                suffix={<CalendarOutlined onClick={() => this.setModalVisible(true)} />}
                            />
                        </Form.Item>
                        <Form.Item className="select-sm">
                            <div className="heading-style">What is the frequency of custody exception?</div>
                            <Select
                                value={frequency === '' ? 'Select Frequency' : frequency}
                                onChange={this.handleFrequency}
                            >
                                {Array.isArray(frequencies) &&
                                    frequencies.map((item, i) => (
                                        <Option
                                            key={i}
                                            style={{ color: frequency === item.value ? '#479ED7' : '#333333' }}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <Form.Item className="select-sm">
                            <div className="heading-style">Who will have custody on exception date?</div>
                            <Select value={custody === '' ? 'Select Custody' : custody} onChange={this.handleCustody}>
                                {frequency !== 'onetime' && Array.isArray(exceptionTypes) &&
                                    exceptionTypes.map((item, i) => (
                                        <Option
                                            key={i}
                                            style={{ color: custody === item.value ? '#479ED7' : '#333333' }}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Option>
                                ))}
                                {frequency === 'onetime' && Array.isArray(specialTypes) &&
                                    specialTypes.map((item, i) => (
                                        <Option
                                            key={i}
                                            style={{ color: custody === item.value ? '#479ED7' : '#333333' }}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="btn-scroll-wrap">
                        <Form.Item>
                            <Button
                                onClick={() => correctDate ? this.handleSubmit() : null}
                                className={correctDate ? "save-btn" : "grey-btn"}
                                type="primary"
                            >
                                Save
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                onClick={() => correctDate ? this.setModal1Visible(true) : null}
                                className={correctDate ? "red-btn" : "grey-btn"}
                                type="primary"
                            >
                                Remove
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
                <Modal
                    title="Calendar"
                    className="cal-modal swap-modal sc-modal"
                    centered
                    visible={this.state.modalVisible}
                    onOk={() => this.setModalVisible(false)}
                    onCancel={() => this.setModalVisible(false)}
                >
                    <div className="edit-wrap cal-custom">
                        <div className="scroll-wrap">
                            <div className="cal-wrap">
                                <Calendar selected={currentDate} onChange={(e) => this.handleDate(e)} />
                            </div>
                        </div>
                        <div className="btn-scroll-wrap">
                            <Form.Item className="btn-top">
                                <Button onClick={this.confirmDate} className="fp-button-primary" type="primary">
                                    Confirm
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Modal>
                <DeleteExceptionModal
                    delete={() => this.handleDelete()}
                    openModal={this.state.modal1Visible}
                    closeModal={() => this.setModal1Visible(false)}
                />
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparent } = serviceReducer;
    return { user, error, children, coparent };
};

export default connect(mapStateToProps)(EditException);
