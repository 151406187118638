import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, InputNumber, Radio, DatePicker, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { getPaymentRequests } from 'services/paymentService';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import SentModal from 'pages/Payment/Payments/SentModal';
import ReviewModal from 'pages/Payment/Payments/ReviewModal';
import ACHModal from 'pages/Payment/Payments/ACHModal';
import RecentModal from 'pages/Payment/Payments/RecentModal';
import BundlePaymentModal from 'pages/Payment/Payments/BundlePaymentModal';
import CreatePaymentRequestModal from 'pages/Payment/Payments/CreatePaymentRequestModal';
import { addLoading, removeLoading } from 'actions/loaderActions';
import { getUserById } from 'services/userService';

const frequencies = {
    weekly: 'Weekly',
    'bi-weekly': 'Bi-Weekly',
    monthly: 'Monthly',
    '2-month': '2 Months',
    '3-month': '3 Months',
    '6-month': '6 Months',
    yearly: 'Yearly',
};

const payment_status = {
    inProgress: 'InProgress',
    inQueue: 'In Queue',
    verifying: 'Verifying',
    processing: 'Processing',
    paid: 'Paid',
    disputed: 'Disputed',
    declined: 'Declined',
    Cancelled: 'Cancelled',
    archived: 'Archived',
};

const refreshSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 13.237 17.024">
        <path
            d="M177.543,26.108a6.5,6.5,0,0,1,6.373-7.246V17.115a.125.125,0,0,1,.2-.09l3.594,2.641a.11.11,0,0,1,0,.177l-3.59,2.641a.125.125,0,0,1-.2-.09V20.65a4.7,4.7,0,0,0-4.255,2.883,4.633,4.633,0,0,0-.364,2.058,4.686,4.686,0,0,0,1.026,2.679.9.9,0,1,1-1.41,1.109A6.41,6.41,0,0,1,177.543,26.108Zm10.376-3.355a4.645,4.645,0,0,1,.661,4.737,4.7,4.7,0,0,1-4.255,2.883V28.631a.125.125,0,0,0-.2-.09l-3.594,2.641a.11.11,0,0,0,0,.177L184.121,34a.125.125,0,0,0,.2-.09V32.162a6.5,6.5,0,0,0,6.373-7.246,6.376,6.376,0,0,0-1.369-3.271.9.9,0,1,0-1.407,1.109Z"
            transform="translate(-177.5 -17)"
        />
    </svg>
);
const RefreshIcon = (props) => <Icon component={refreshSvg} {...props} />;
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

function callback(key) {
    
}

function handleChange(value) {
    
}

class Payments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payments: [],
            modalVisible: false,
            modal2Visible: false,
            modal3Visible: false,
            modal4Visible: false,
            modal5Visible: false,
            modal7Visible: false,
            offset: 0,
            status: ['pending'],
            coparent: null,
            loading: false,
            currentPayment: null,
            coParent: null
        };
        this.getRecentPayments = this.getRecentPayments.bind(this);
        this.getFormattedDate = this.getFormattedDate.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.getChildrenNames = this.getChildrenNames.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.setModal3Visible = this.setModal3Visible.bind(this);
        this.setModal4Visible = this.setModal4Visible.bind(this);
        this.setModal5Visible = this.setModal5Visible.bind(this);
        this.setModal6Visible = this.setModal6Visible.bind(this);
        this.setModal7Visible = this.setModal7Visible.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
    }

    componentDidMount() {
        let offset = 0;
        if (localStorage.third_party && localStorage.third_party === 'true') {
            getUserById(localStorage.coparent_id)
                    .then(resp => {
                        if (resp) {
                            this.setState({ coParent: resp });
                    }
            })
            this.getRecentPayments(offset, localStorage.coparent_id, false);
        } else {
            this.getRecentPayments(offset, this.props.coparent, false);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.coparent !== prevProps.coparent) {
            let offset = 0;
            let arr = [];
            this.setState({ payments: arr });
            if (localStorage.third_party && localStorage.third_party === 'true') {
                this.getRecentPayments(offset, localStorage.coparent_id, true);
            } else {
                this.getRecentPayments(offset, this.props.coparent, true);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.err !== null) {
            this.setState({ loading: false });
        }
        if (nextProps && nextProps.error !== null) {
            this.setState({ loading: false });
        }

        if(nextProps && nextProps.loadings === true) {
            this.props.dispatch(removeLoading());
            let offset = 0;
            let arr = [];
            this.setState({ payments: arr });
            if (localStorage.third_party && localStorage.third_party === 'true') {
                this.getRecentPayments(offset, localStorage.coparent_id, true);
            } else {
                this.getRecentPayments(offset, this.props.coparent, true);
            }
        }
    }

    getRecentPayments = function (offset, coparent, flag) {
        const { payments, status } = this.state;
        if (this.props.err === null) {
            this.setState({ loading: true });
        }
        let sParams = {};

        if (localStorage.third_party && localStorage.third_party === 'true') {
            sParams = {
                offset: offset,
                limit: 15,
                status: status,
                orderBy: '-updated_at',
                coparent_id: coparent,
                thirdParty: true
            }; 
        } else {
            sParams = {
                offset: offset,
                limit: 15,
                status: status,
                orderBy: '-updated_at',
                coparent_id: (coparent && coparent.id) || null,
            };
        }

        this.setState({ offset: offset, status: status, coparent: coparent || null });
        getPaymentRequests(sParams).then((resp) => {
            if (resp) {
                if (resp.index !== undefined) {
                    this.setState({ offset: resp.index + 1, coparent: { id: resp.coparent } });
                } else {
                    resp.offset += 15;
                    this.setState({ offset: resp.offset, coparent: { id: resp.coparent } });
                }

                if (flag) {
                    let arr = [];
                    arr = [...arr, ...resp.payments];
                    this.setState({ payments: arr, loading: false });
                } else {
                    let arr = [];
                    arr = [...payments, ...resp.payments];
                    this.setState({ payments: arr, loading: false });
                }
            } else {
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({loading: false});
        })
    };

    getFormattedDate = function (dateObj) {
        let month = dateObj.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = dateObj.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = dateObj.getFullYear();
        let output = month + '/' + day + '/' + parseInt(year.toString());
        return output;
    };

    getChildrenNames = function (child_ids) {
        let names = '';
        const { children } = this.props;
        child_ids.forEach((child) => {
            for (let i = 0; i < children.length; i++) {
                let c = children[i];
                if (child == c.id) {
                    names = names + c.name;
                    names = names + ', ';
                }
            }
        });
        names = names.trim();
        names = names.slice(0, names.length - 1);
        return names;
    };

    refreshPage = function () {
        let offset = 0;
        if (localStorage.third_party && localStorage.third_party === 'true') {
            this.getRecentPayments(offset, localStorage.coparent_id, true);
        } else {
            this.getRecentPayments(offset, this.props.coparent, true);
        }
    };

    handleInput = async function (_status) {
        const { coparent } = this.state;
        if (_status === 'pending') {
            let arr = [];
            await this.setState({ payments: arr, offset: 0, status: ['pending'] });
            if (localStorage.third_party && localStorage.third_party === 'true') {
                this.getRecentPayments(this.state.offset, localStorage.coparent_id, true);
            } else {
                this.getRecentPayments(this.state.offset, coparent, true);
            }
        } else if (_status === 'non-pending') {
            let arr = [];
            await this.setState({
                payments: arr,
                offset: 0,
                status: ['paid', 'declined', 'disputed', 'on-hold', 'Cancelled', 'inProgress'],
            });
            if (localStorage.third_party && localStorage.third_party === 'true') {
                this.getRecentPayments(this.state.offset, localStorage.coparent_id, true);
            } else {
                this.getRecentPayments(this.state.offset, coparent, true);
            }
        } else if (_status === 'archive') {
            let arr = [];
            await this.setState({ payments: arr, offset: 0, status: ['archived'] });
            if (localStorage.third_party && localStorage.third_party === 'true') {
                this.getRecentPayments(this.state.offset, localStorage.coparent_id, true);
            } else {
                this.getRecentPayments(this.state.offset, coparent, true);
            }
        }
    };

    handleClick = function (payment) {
        const { status, coParent } = this.state;
        const { user } = this.props;
        let normalUser = localStorage.third_party !== 'true';
        let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';

        if (normalUser) {
            if (payment.status === 'on-hold') {
                if (user && user.id === payment.created_by) {
                    if (user && user.id === payment.receiver_id) {
                        this.setModal2Visible(true, payment);
                    } else if (user && user.id === payment.payer_id) {
                        this.setModal3Visible(true, payment);
                    }
                }
            } else {
                if (status.includes('archived')) {
                    this.setModal4Visible(true, payment);
                } else if (status.includes('pending')) {
                    if (user && user.id === payment.receiver_id) {
                        this.setModal2Visible(true, payment);
                    } else if (user && user.id === payment.payer_id) {
                        this.setModal3Visible(true, payment);
                    }
                } else if (status.includes('paid')) {
                    if (payment && payment.status === 'inProgress') {
                        this.setModal5Visible(true, payment);
                    } else {
                        this.setModal4Visible(true, payment);
                    }
                }
            }
        } else if (thirdPartyUser) {
            if (payment.status === 'on-hold') {
                if (coParent && coParent.id === payment.receiver_id) {
                    this.setModal2Visible(true, payment);
                } else if (coParent && coParent.id === payment.payer_id) {
                    this.setModal3Visible(true, payment);
                }
            }else {
                if (status.includes('archived')) {
                    this.setModal4Visible(true, payment);
                } else if (status.includes('pending')) {
                    if (coParent && coParent.id === payment.receiver_id) {
                        this.setModal2Visible(true, payment);
                    } else if (coParent && coParent.id === payment.payer_id) {
                        this.setModal3Visible(true, payment);
                    }
                } else if (status.includes('paid')) {
                    if (payment && payment.status === 'inProgress') {
                        this.setModal5Visible(true, payment);
                    } else {
                        this.setModal4Visible(true, payment);
                    }
                }
            }
        }
    };

    setModalVisible = async function (modalVisible) {
        await this.setState({ modalVisible });
    };
    setModal2Visible = async function (modal2Visible, payment) {
        await this.setState({ modal2Visible: modal2Visible, currentPayment: payment });
    };
    setModal3Visible = async function (modal3Visible, payment) {
        await this.setState({ modal3Visible: modal3Visible, currentPayment: payment });
    };
    setModal4Visible = async function (modal4Visible, payment) {
        await this.setState({ modal4Visible: modal4Visible, currentPayment: payment });
    };
    setModal5Visible = async function (modal5Visible, payment) {
        await this.setState({ modal5Visible: modal5Visible, currentPayment: payment });
    };
    setModal6Visible = function (modal6Visible) {
        this.setState({ modal6Visible });
    };
    setModal7Visible = async function (modalVisible) {
        await this.setState({ modal7Visible: modalVisible });
    };
    setModal8Visible = function (modal8Visible) {
        this.setState({ modal8Visible });
    };

    render() {
        function onChange(e) {
            
        }
        const { payments, offset, coparent, coParent, status, currentPayment, loading } = this.state;
        const { user } = this.props;
        
        let normalUser = localStorage.third_party !== 'true';
        let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';


        return (
            <Card bordered={false} className="payments">
                <LoadingOverlay
                    active={loading ? true : false}
                    className={'loader-wrap cust-loader ' + (loading ? 'd-block' : '')}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <div className="payments-head">
                    <h1 style={{ fontSize: '14px' }}>Payments</h1>
                </div>
                <div className={normalUser ? "payments-modal" : "payments-create-btn"}>
                    {normalUser ? (
                        <Button type="primary" onClick={() => this.setModalVisible(true)} className="fp-button-secondary">
                            Bundle Payments
                        </Button>
                    ) : null}
                    <Button type="primary" onClick={() => this.setModal7Visible(true)} className="fp-button-secondary">
                        Create Payment Request
                    </Button>
                </div>
                <div className="payments-btn">
                    <Button
                        type="primary"
                        onClick={() => this.handleInput('pending')}
                        value="pending"
                        className={status.includes('pending') ? 'active' : 'fp-'}
                    >
                        Pending Requests
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => this.handleInput('non-pending')}
                        value="non-pending"
                        className={status.includes('paid') ? 'active' : 'fp-'}
                    >
                        Recent Activity
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => this.handleInput('archive')}
                        value="archive"
                        className={status.includes('archived') ? 'active' : 'fp-'}
                    >
                        Archive
                    </Button>
                </div>

                <ScrollArea
                    speed={0.8}
                    className="area"
                    onScroll={(value) => {
                        if (value.topPosition === value.realHeight - value.containerHeight && value.topPosition !== 0) {
                            if (localStorage.third_party && localStorage.third_party === 'true') {
                                this.getRecentPayments(offset, localStorage.coparent_id, false);
                            } else {
                                this.getRecentPayments(offset, coparent, false);
                            }
                        }
                    }}
                    contentClassName="content"
                    horizontal={false}
                >
                    {user && payments && Array.isArray(payments) && payments.length !== 0 ? (
                        payments.map((payment, i) => (
                           (user.id === payment.PaymentCategory.coparent_user_id || user.id === payment.PaymentCategory.coparent_standlalone_id) && payment.PaymentCategory.category_status === 1 ? null : (
                            <Card
                                key={i}
                                onClick={() => this.handleClick(payment)}
                                bordered={false}
                                className="list-card"
                            >
                                <div className="req-inner">
                                    <div className="req-details">
                                        <span>
                                            {normalUser && user ? payment.payer_username : null}

                                            {thirdPartyUser && coParent ? localStorage.getItem('coparent_name') : null}
                                        </span>
                                        <span>
                                            {payment && payment.PaymentCategory && payment.PaymentCategory.name}
                                        </span>
                                    </div>
                                    <div className="req-action icon-action">
                                        {payment && payment.recurring === true ? (
                                            <div className="icon-wrap">
                                                <RefreshIcon />
                                                <span>
                                                    {payment && frequencies[payment.RecurringPaymentSchedule.frequency]}
                                                </span>
                                            </div>
                                        ) : null}
                                        <Button
                                            type="primary"
                                            className={normalUser ?(
                                                payment && payment.status === 'pending'
                                                    ? user && user.id === payment.payer_id
                                                        ? 'btn fp-button-secondary'
                                                        : user && user.id === payment.receiver_id
                                                        ? 'btn fp-button-primary'
                                                        : null
                                                    : payment && payment.status === 'inProgress'
                                                    ? 'btn fp-button-primary'
                                                        : 'btn fp-button-secondary'
                                            ) : (
                                                payment && payment.status === 'pending'
                                                    ? coParent && coParent.id === payment.payer_id
                                                        ? 'btn fp-button-secondary'
                                                        : coParent && coParent.id === payment.receiver_id
                                                        ? 'btn fp-button-primary'
                                                        : null
                                                    : payment && payment.status === 'inProgress'
                                                    ? 'btn fp-button-primary'
                                                        : 'btn fp-button-secondary'   
                                            )
                                            }
                                        >
                                            <span>
                                                { normalUser ? (payment && payment.status === 'pending'
                                                    ? user && user.id === payment.payer_id
                                                        ? 'Review'
                                                        : user && user.id === payment.receiver_id
                                                        ? 'Sent'
                                                        : null
                                                    : payment && payment.archive === true && status.includes('archived')
                                                    ? 'Archived'
                                                    : payment && payment.status === 'inProgress'
                                                    ? (payment.sale_status !== 'paid' ? payment_status[payment.sale_status] : payment_status['processing'])
                                                                : payment && (payment_status[payment.status] || payment.status)
                                                    ) : (
                                                        (payment && payment.status === 'pending'
                                                        ? coParent && coParent.id === payment.payer_id
                                                            ? 'Review'
                                                            : coParent && coParent.id === payment.receiver_id
                                                            ? 'Sent'
                                                            : null
                                                        : payment && payment.archive === true && status.includes('archived')
                                                        ? 'Archived'
                                                        : payment && payment.status === 'inProgress'
                                                        ? (payment.sale_status !== 'paid' ? payment_status[payment.sale_status] : payment_status['processing'])
                                                                    : payment && (payment_status[payment.status] || payment.status)      
                                                ))
                                                }
                                            </span>
                                        </Button>
                                    </div>
                                    <div className="req-amount">
                                        <span>{`$${payment.payer_amount}`}</span>
                                        <span>{this.getFormattedDate(new Date(payment.payment_date))}</span>
                                    </div>
                                </div>
                            </Card>
                            )
                        ))
                    ) : (
                        <div>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                    )}
                </ScrollArea>

                <Modal
                    title="Payments Requests"
                    centered
                    maskClosable={false}
                    visible={this.state.modal6Visible}
                    className="payments-popup ach-payment"
                    onOk={() => this.setModal6Visible(false)}
                    onCancel={() => this.setModal6Visible(false)}
                >
                    <div>
                        <div className="add-btn">
                            <Button type="primary" className="btn fp-button-primary">
                                <span>Add New Payment Request</span>
                            </Button>
                        </div>
                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Pending Requests" key="1">
                                <Card bordered={false} className="list-card icon-card">
                                    <div className="req-inner">
                                        <div className="req-details">
                                            <span>Vikram</span>
                                            <span>Test Category</span>
                                        </div>
                                        <div className="req-action">
                                            <div className="icon-wrap">
                                                <RefreshIcon />
                                                <span>weekly</span>
                                            </div>
                                            <Button type="primary" className="btn fp-button-primary">
                                                <span>InProgress</span>
                                            </Button>
                                        </div>
                                        <div className="req-amount">
                                            <span>$65.00</span>
                                            <span>8/01/2018</span>
                                        </div>
                                    </div>
                                </Card>
                                <Card bordered={false} className="list-card">
                                    <div className="req-inner">
                                        <div className="req-details">
                                            <span>Vikram</span>
                                            <span>Test Category</span>
                                        </div>
                                        <div className="req-action">
                                            <Button type="primary" className="btn fp-button-secondary">
                                                <span>Paid</span>
                                            </Button>
                                        </div>
                                        <div className="req-amount">
                                            <span>$40.00</span>
                                            <span>09/24/2020</span>
                                        </div>
                                    </div>
                                </Card>
                                <Card bordered={false} className="list-card">
                                    <div className="req-inner">
                                        <div className="req-details">
                                            <span>Vikram</span>
                                            <span>second test category</span>
                                        </div>
                                        <div className="req-action">
                                            <Button type="primary" className="btn fp-button-secondary">
                                                <span>Paid</span>
                                            </Button>
                                        </div>
                                        <div className="req-amount">
                                            <span>$50.00</span>
                                            <span>09/25/2020</span>
                                        </div>
                                    </div>
                                </Card>
                                <Card bordered={false} className="list-card icon-card">
                                    <div className="req-inner">
                                        <div className="req-details">
                                            <span>Vikram</span>
                                            <span>Test Category</span>
                                        </div>
                                        <div className="req-action">
                                            <div className="icon-wrap">
                                                <RefreshIcon />
                                                <span>weekly</span>
                                            </div>
                                            <Button type="primary" className="btn fp-button-secondary">
                                                <span>Paid</span>
                                            </Button>
                                        </div>
                                        <div className="req-amount">
                                            <span>$65.00</span>
                                            <span>8/01/2018</span>
                                        </div>
                                    </div>
                                </Card>
                                <Card bordered={false} className="list-card">
                                    <div className="req-inner">
                                        <div className="req-details">
                                            <span>Vikram</span>
                                            <span>Test Category</span>
                                        </div>
                                        <div className="req-action">
                                            <Button type="primary" className="btn fp-button-secondary">
                                                <span>Paid</span>
                                            </Button>
                                        </div>
                                        <div className="req-amount">
                                            <span>$65.00</span>
                                            <span>8/01/2018</span>
                                        </div>
                                    </div>
                                </Card>
                            </TabPane>
                            <TabPane tab="Archive" key="2">
                                Content of Tab Pane 2
                            </TabPane>
                            <TabPane tab="Archive" key="3">
                                Content of Tab Pane 3
                            </TabPane>
                        </Tabs>
                    </div>
                </Modal>

                <SentModal
                    refresh={() => this.refreshPage()}
                    input={true}
                    currentPayment={currentPayment}
                    handleOpen={this.state.modal2Visible}
                    handleClose={() => this.setModal2Visible(false)}
                />
                <ReviewModal
                    refresh={() => this.refreshPage()}
                    currentPayment={currentPayment}
                    handleOpen={this.state.modal3Visible}
                    handleClose={() => this.setModal3Visible(false)}
                />
                <ACHModal
                    refresh={() => this.refreshPage()}
                    currentPayment={currentPayment}
                    handleOpen={this.state.modal5Visible}
                    handleClose={() => this.setModal5Visible(false)}
                />
                <RecentModal
                    refresh={() => this.refreshPage()}
                    currentPayment={currentPayment}
                    handleOpen={this.state.modal4Visible}
                    handleClose={() => this.setModal4Visible(false)}
                />
                <BundlePaymentModal
                    refresh={() => this.refreshPage()}
                    handleOpen={this.state.modalVisible}
                    handleClose={() => this.setModalVisible(false)}
                />
                <CreatePaymentRequestModal
                    refresh={() => this.refreshPage()}
                    handleOpen={this.state.modal7Visible}
                    handleClose={() => this.setModal7Visible(false)}
                />
            </Card>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, loadings, coparent } = serviceReducer;
    return { user, error, children, loadings, coparent };
};
export default connect(mapStateToProps)(Payments);
