import React, { Component } from 'react';
import 'pages/Household/Children/Children.scss';
import { Button, Modal, Form, Input, Avatar, Col, DatePicker, Select } from 'antd';
//import Calendar from 'react-calendar';
import Moment from 'moment';
import { getHouseHoldAction } from 'actions/houseHoldAction.js';
import userImg from 'Assets/defaultAvatar.png';
import add from 'Assets/add.svg';
import { getMeUserAction, getChildrenUserAction ,getCheckListAction} from 'actions/userActions';
import { addChildren, editChildren, deleteChildren } from '../../../services/houseHoldService';
import { connect } from 'react-redux';
import { checkCookie } from 'utils/cookies';
import LoadingOverlay from 'react-loading-overlay';
import Calendar from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import 'react-calendar/dist/Calendar.css';
import { CalendarOutlined } from '@ant-design/icons';
import AddCoparent from './CoparentModal';
import { getCoParentsUserAction } from 'actions/userActions';
import { getSetupProgress } from 'services/userService';

class DeleteChildModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            LoadingIs: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.handleOpen) {
            this.state = {
                LoadingIs: false
            };
        }
    }

    handleDeleteChild = () => {
        const { dataIs } = this.props;
        let _id = dataIs.id ? dataIs.id : '';
        this.setState({ LoadingIs: true });
        deleteChildren(_id).then((res) => {
            if (res) {
                this.setState({ LoadingIs: false });
                this.props.dispatch(getHouseHoldAction());
                this.props.handleDelete({ modal1Visible: false, editIs: false, deleteIs: false });
                if (localStorage.third_party !== 'true') {
                    this.props.dispatch(getCheckListAction());
                }
                // update checklist status
            }
        });
    };


    render() {

        return (
            <Modal
                centered
                visible={this.props.handleOpen}
                backdrop="static"
                maskClosable={false}
                className={'add-child-popup del-pop'}
                onCancel={this.props.handleClose}
            >
                {this.state.LoadingIs && (
                        <LoadingOverlay
                            active={true}
                            className="d-block pop-loader"
                            spinner
                            styles={{
                                spinner: (base) => ({
                                    ...base,
                                    width: '100px',
                                    '& svg circle': {
                                        stroke: 'rgb(56, 160, 235)',
                                    },
                                }),
                            }}
                        ></LoadingOverlay>
                )}
                <div className="modal-head">
                    <h5 className="del-head">{'Do you want to delete this kid ? '}</h5>
                </div>
                <Form>
                    <div className="modal-bottom">
                        <Button type="warning" className="ant-btn" onClick={() => this.props.handleClose()}>
                            Cancel
                        </Button>
                        <Button type="primary" className="btn-add" onClick={() => this.handleDeleteChild()}>
                            OK
                        </Button>
                    </div>
                </Form>
                </Modal>
        )
    }

}

const mapStateToProps = (response) => ({ response });
export default connect(mapStateToProps)(DeleteChildModal);