import * as QB from "quickblox/quickblox";

export const QuickBlockConfig = async (configData) => {

    var APPLICATION_ID = configData && configData.appId;
    var AUTH_KEY = configData && configData.authKey;
    var AUTH_SECRET = configData && configData.authSecret;
    var ACCOUNT_KEY = configData && configData.accountKey;
    var CONFIG = {
    endpoints: {
        api: configData && configData.apiEndpoint, // set custom API endpoint
        chat: configData && configData.chatEndpoint, // set custom Chat endpoint
    },
    chatProtocol: {
        active: 2, // set 1 to use BOSH, set 2 to use WebSockets (default)
    },
    /**
     * set { mode: 1 } or true to output to console,
     * set { mode: 2, file: 'log.txt' } to output to file,
     * set "false" to not output
     */
    debug: { mode: 1 },
    };


    QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG)

};
