import React, { Component } from 'react';
import 'pages/CalenderInner/Custody/Custody.scss';
import { Card, Button, Modal, Form, Input, Select, Collapse } from 'antd';
import more from 'Assets/more.svg';
import { CalendarOutlined } from '@ant-design/icons';
import Calendar from 'pages/CalenderInner/Custody/react-calendar/src';
import { connect } from 'react-redux';

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

let patternTemplates = [
    {
      label: "Weekly Alternate",
      pattern: ["1","1","1","1","1","1","1"],
      type: 'alt',
    },
    {
      label: "2on-3off-2on Alternate",
      pattern: ["1","1","0","0","0","1","1"],
      type: 'alt',
    },
    {
      label: "5on-2off Alternate",
      pattern: ["1","1","1","1","1","0","0"],
      type: 'rpt',
    },
    {
      label: "4on Alternate",
      pattern: ["1","1","1","1"],
      type: 'alt', 
    },
    {
      label: "3on-4off-4on-3off Repeat",
      pattern: ["1","1","1","0","0","0","0","1","1","1","1","0","0","0"],
      type: 'rpt',  
    },
    {
      label: "Full Time",
      pattern: ["1"],
      type: 'rpt',
    }
];


class CustodyTemplateModal extends Component {
    constructor(props){
        super(props);
        this.state ={
           pattern: '',
           patternType: '',
           startDate: new Date(),
           patternName: '',
           dates: [],
           markedDates: [],
           highlightDates: [],
           template: '',
           duration: '',
           flag: false,
           obj1: {},
           obj2: {},
           obj3: {},
           obj4: {},
           obj5: {},
           obj6: {},
        };
        this.handleChange = this.handleChange.bind(this);
        this.getDateArray = this.getDateArray.bind(this);
        this.handleMarkedDates = this.handleMarkedDates.bind(this);
        this.handleDates = this.handleDates.bind(this);
        this.handleApply = this.handleApply.bind(this);
    }

    UNSAFE_componentWillReceiveProps = async function(nextProps) {
        if(nextProps && nextProps.openModal && nextProps.start_date) {
            let startDate = new Date(nextProps.start_date);
            let endDate = new Date(startDate.getFullYear() + 1, 12, 0);
            let dates = this.getDateArray(startDate, endDate);
            await this.setState({ flag: !this.state.flag,dates: dates, startDate: new Date(nextProps.start_date)});

            let obj1 = {};
            obj1.markedDates = this.handleMarkedDates("Weekly Alternate");
            obj1.highlightDates = this.handleDates("Weekly Alternate");
            obj1.duration = 7;
            obj1.pattern = ['1','1','1','1','1','1','1'];
            obj1.template = '["1","1","1","1","1","1","1"];alt';
            obj1.patternName = 'Weekly Alternate';
            obj1.patternType = 'alt';

            let obj2 = {};
            obj2.markedDates = this.handleMarkedDates("2on-3off-2on Alternate");
            obj2.highlightDates = this.handleDates("2on-3off-2on Alternate");
            obj2.duration = 7;
            obj2.pattern = ['1','1','0','0','0','1','1'];
            obj2.template = '["1","1","0","0","0","1","1"];alt'; 
            obj2.patternName = '2on-3off-2on Alternate';
            obj2.patternType = 'alt';

            let obj3 = {};
            obj3.markedDates = this.handleMarkedDates("5on-2off Alternate");
            obj3.highlightDates = this.handleDates("5on-2off Alternate");
            obj3.duration = 7;
            obj3.pattern = ['1','1','1','1','1','0','0'];
            obj3.template = '["1","1","1","1","1","0","0"];alt'; 
            obj3.patternName = '5on-2off Alternate';
            obj3.patternType = 'alt';

            let obj4 = {};
            obj4.markedDates = this.handleMarkedDates("4on Alternate");
            obj4.highlightDates = this.handleDates("4on Alternate");
            obj4.duration = 4;
            obj4.pattern = ['1','1','1','1'];
            obj4.template = '["1","1","1","1"];alt'; 
            obj4.patternName = '4on Alternate';
            obj4.patternType = 'alt';

            let obj5 = {};
            obj5.markedDates = this.handleMarkedDates("3on-4off-4on-3off Repeat");
            obj5.highlightDates = this.handleDates("3on-4off-4on-3off Repeat");
            obj5.duration = 14;
            obj5.pattern = ['1','1','1','0','0','0','0','1','1','1','1','0','0','0'];
            obj5.template = '["1","1","1","0","0","0","0","1","1","1","1","0","0","0"];rpt'; 
            obj5.patternName = '3on-4off-4on-3off Repeat';
            obj5.patternType = 'rpt';

            let obj6 = {};
            obj6.markedDates = this.handleMarkedDates("Full Time");
            obj6.highlightDates = this.handleDates("Full Time");
            obj6.duration = 1;
            obj6.pattern = ['1'];
            obj6.template = '["1"];rpt'; 
            obj6.patternName = 'Full Time';
            obj6.patternType = 'rpt';

            await this.setState({
                obj1: obj1,
                obj2: obj2,
                obj3: obj3,
                obj4: obj4,
                obj5: obj5,
                obj6: obj6
            });
        }
    }

    componentDidMount() {}

    getDateArray = function(start, end) {
        var arr = [];
        var dt = new Date(start);
        while (dt <= end) {
            arr.push(new Date(dt));
            dt.setDate(dt.getDate() + 1);
        }
        return arr;
    }

    handleDates = function(patternName) {
        const {startDate} = this.state;
        let arr = [];
        let sd = new Date(startDate);
        let md = new Date(sd);
        let endDate = new Date(md.setMonth(md.getMonth() + 6));
        if(patternName === 'Weekly Alternate') {
            while ( sd <= endDate) {
                for(let i = 0;i < 7; i++){
                   arr.push(new Date(sd));
                   sd.setDate(sd.getDate() + 1);
                } 
                sd.setDate(sd.getDate() + 7);
            }

            return arr;

        } else if(patternName === '2on-3off-2on Alternate') {
            while(sd <= endDate) {
                for(let i = 0;i < 2;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                sd.setDate(sd.getDate() + 3);
                for(let i = 0;i < 2;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                sd.setDate(sd.getDate() + 2);
                for(let i = 0;i < 3;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                sd.setDate(sd.getDate() + 2);
            }
            return arr;
        } else if(patternName === '5on-2off Alternate') {
            while(sd <= endDate) {
                for(let i = 0;i < 5;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                sd.setDate(sd.getDate() + 2);
                sd.setDate(sd.getDate() + 5);
                for(let i = 0;i < 2;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
            }
            return arr;
        } else if(patternName === '4on Alternate'){
            while(sd <= endDate) {
                for(let i = 0;i < 4;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                sd.setDate(sd.getDate() + 4);
            }
            return arr;
        } else if(patternName === '3on-4off-4on-3off Repeat'){
            while(sd <= endDate) {
                for(let i = 0;i < 3;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                sd.setDate(sd.getDate() + 4);
                for(let i = 0;i < 4;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                sd.setDate(sd.getDate() + 3);
            }
            return arr;
        } else if(patternName === 'Full Time'){
            while(sd <= endDate) {
                arr.push(new Date(sd));
                sd.setDate(sd.getDate() + 1);
            }
            return arr;
        }
    }

    handleMarkedDates = function(patternName) {
        const { dates, startDate } = this.state;
        let arr = [];
        let sd = new Date(startDate);
        let md = new Date(sd);
        let endDate = new Date(md.setMonth(md.getMonth() + 13));
        if(patternName === 'Weekly Alternate') {
            while ( sd <= endDate) {
                for(let i = 0;i < 7; i++){
                   arr.push(new Date(sd));
                   sd.setDate(sd.getDate() + 1);
                } 
                sd.setDate(sd.getDate() + 7);
            }

            return arr;

        } else if(patternName === '2on-3off-2on Alternate') {
            while(sd <= endDate) {
                for(let i = 0;i < 2;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                sd.setDate(sd.getDate() + 3);
                for(let i = 0;i < 2;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                sd.setDate(sd.getDate() + 2);
                for(let i = 0;i < 3;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                sd.setDate(sd.getDate() + 2);
            }
            return arr;
        } else if(patternName === '5on-2off Alternate') {
            while(sd <= endDate) {
                for(let i = 0;i < 5;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                sd.setDate(sd.getDate() + 2);
                sd.setDate(sd.getDate() + 5);
                for(let i = 0;i < 2;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
            }
            return arr;
        } else if(patternName === '4on Alternate'){
            while(sd <= endDate) {
                for(let i = 0;i < 4;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                sd.setDate(sd.getDate() + 4);
            }
            return arr;
        } else if(patternName === '3on-4off-4on-3off Repeat'){
            while(sd <= endDate) {
                for(let i = 0;i < 3;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                sd.setDate(sd.getDate() + 4);
                for(let i = 0;i < 4;i++){
                    arr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                sd.setDate(sd.getDate() + 3);
            }
            return arr;
        } else if(patternName === 'Full Time'){
            while(sd <= endDate) {
                arr.push(new Date(sd));
                sd.setDate(sd.getDate() + 1);
            }
            return arr;
        }
    }

    handleChange = async function(e){
        const { startDate } = this.state;
        if(e == undefined) {
            await this.setState({markedDates: [], highlightDates: []});
        } else if(e == '1'){

            let obj1 = {};
            obj1.markedDates = this.handleMarkedDates("Weekly Alternate");
            obj1.highlightDates = this.handleDates("Weekly Alternate");
            obj1.duration = 7;
            obj1.pattern = ['1','1','1','1','1','1','1'];
            obj1.template = '["1","1","1","1","1","1","1"];alt';
            obj1.patternName = 'Weekly Alternate';
            obj1.patternType = 'alt';
            await this.setState({obj1: obj1});

        } else if(e == '2'){

            let obj2 = {};
            obj2.markedDates = this.handleMarkedDates("2on-3off-2on Alternate");
            obj2.highlightDates = this.handleDates("2on-3off-2on Alternate");
            obj2.duration = 7;
            obj2.pattern = ['1','1','0','0','0','1','1'];
            obj2.template = '["1","1","0","0","0","1","1"];alt'; 
            obj2.patternName = '2on-3off-2on Alternate';
            obj2.patternType = 'alt';
            await this.setState({ obj2: obj2 });

        } else if(e == '3'){ 

            let obj3 = {};
            obj3.markedDates = this.handleMarkedDates("5on-2off Alternate");
            obj3.highlightDates = this.handleDates("5on-2off Alternate");
            obj3.duration = 7;
            obj3.pattern = ['1','1','1','1','1','0','0'];
            obj3.template = '["1","1","1","1","1","0","0"];alt'; 
            obj3.patternName = '5on-2off Alternate';
            obj3.patternType = 'alt';
            await this.setState({obj3: obj3});

        } else if(e == '4') {
            let obj4 = {};
            obj4.markedDates = this.handleMarkedDates("4on Alternate");
            obj4.highlightDates = this.handleDates("4on Alternate");
            obj4.duration = 4;
            obj4.pattern = ['1','1','1','1'];
            obj4.template = '["1","1","1","1"];alt'; 
            obj4.patternName = '4on Alternate';
            obj4.patternType = 'alt';
            await this.setState({ obj4: obj4 });

        } else if(e == '5') {
            
            let obj5 = {};
            obj5.markedDates = this.handleMarkedDates("3on-4off-4on-3off Repeat");
            obj5.highlightDates = this.handleDates("3on-4off-4on-3off Repeat");
            obj5.duration = 14;
            obj5.pattern = ['1','1','1','0','0','0','0','1','1','1','1','0','0','0'];
            obj5.template = '["1","1","1","0","0","0","0","1","1","1","1","0","0","0"];rpt'; 
            obj5.patternName = '3on-4off-4on-3off Repeat';
            obj5.patternType = 'rpt';
            await this.setState({ obj5: obj5 });
             
        } else if(e == '6') {
            
            let obj6 = {};
            obj6.markedDates = this.handleMarkedDates("Full Time");
            obj6.highlightDates = this.handleDates("Full Time");
            obj6.duration = 1;
            obj6.pattern = ['1'];
            obj6.template = '["1"];rpt'; 
            obj6.patternName = 'Full Time';
            obj6.patternType = 'rpt';
            await this.setState({ obj6: obj6 });

        }
    }

    handleApply = function(object) {
        const {
            pattern, 
            patternName,
            patternType, 
            template, 
            duration, 
            markedDates
        } = object;

        let obj = {
            pattern,
            patternName,
            patternType,
            template,
            duration,
            markedDates
        };
        this.props.handleUseTemplate(obj);
        this.props.closeModal();
    }

    render() {
        const { 
            dates, 
            markedDates, 
            highlightDates,
            obj1,
            obj2,
            obj3,
            obj4,
            obj5,
            obj6 
        } = this.state;
        return (
            <Modal
                title="Custody Schedule Setup"
                className="cal-modal swap-modal sc-modal"
                centered
                maskClosable={false}
                visible={this.props.openModal}
                onOk={this.props.closeModal}
                onCancel={this.props.closeModal}
            >
                <div className="custom-schedule">
                    <Form>
                        <div className="scroll-wrap modal-height">
                            <Collapse  onChange={(e) => this.handleChange(e)}>
    
                                <Panel  header="Weekly Alternate" key="1">
                                   <div>
                                       <p className="special-text">Custody will be alternative</p>
                                   </div>

                                    <div className="cal-wrap">
                                        <Calendar markedDates={obj1.highlightDates} />
                                        <Form.Item className="btn-top">
                                            <Button onClick={() => this.handleApply(obj1)} className="fp-button-primary full-btn" type="primary">
                                                Apply
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Panel>
                               
                                <Panel header="2on-3off-2on Alternate" key="2">
                                    <div>
                                       <p className="special-text">Custody will be split every 7 days</p>
                                    </div>
                                    <div className="cal-wrap">
                                        <Calendar markedDates={obj2.highlightDates} />
                                        <Form.Item className="btn-top">
                                            <Button onClick={() => this.handleApply(obj2)} className="fp-button-primary full-btn" type="primary">
                                                Apply
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Panel>
                                <Panel header="5on-2off Alternate" key="3">
                                    <div>
                                       <p className="special-text">Custody will be split every 7 days</p>
                                    </div>
                                    <div className="cal-wrap">
                                        <Calendar markedDates={obj3.highlightDates} />
                                        <Form.Item className="btn-top">
                                            <Button onClick={() => this.handleApply(obj3)} className="fp-button-primary full-btn" type="primary">
                                                Apply
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Panel>
                                <Panel header="4on Alternate" key="4">
                                    <div>
                                       <p className="special-text">Custody will be split every 4 days</p>
                                    </div>
                                    <div className="cal-wrap">
                                        <Calendar  markedDates={obj4.highlightDates} />
                                        <Form.Item className="btn-top">
                                            <Button onClick={() => this.handleApply(obj4)} className="fp-button-primary full-btn" type="primary">
                                                Apply
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Panel>
                                <Panel header="3on-4off-4on-3off Repeat" key="5">
                                    <div>
                                       <p className="special-text">Custody will be split every 14 days</p>
                                    </div>
                                    <div className="cal-wrap">
                                        <Calendar markedDates={obj5.highlightDates} />
                                        <Form.Item className="btn-top">
                                            <Button onClick={() => this.handleApply(obj5)} className="fp-button-primary full-btn" type="primary">
                                                Apply
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Panel>
                                <Panel header="Full Time" key="6">
                                    <div>
                                       <p className="special-text">Custody will be full time</p>
                                    </div>
                                    <div className="cal-wrap">
                                        <Calendar markedDates={obj6.highlightDates} />
                                        <Form.Item className="btn-top">
                                            <Button onClick={() => this.handleApply(obj6)} className="fp-button-primary full-btn" type="primary">
                                                Apply
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    </Form>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, loadings, error, coparent } = serviceReducer;
    return { children, user, loadings, error, coparent };
};

export default connect(mapStateToProps)(CustodyTemplateModal);