import React, { Component } from "react";
import "pages/Notifications/Notifications.scss";
import {
  getNotifications,
  updateNotificationRead,
} from "services/notificationService";
import { getNotificationAction } from "actions/userActions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import { Card, Empty } from "antd";
 
class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationDataVisible: false,
      notificationData: null,
      refreshing: false,
      notificationList: [],
      limit: 10,
      offset: 0,
      LoadingIs: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.getNotificationsList = this.getNotificationsList.bind(this);
  }
  componentDidMount() {
    if (localStorage.third_party === 'true') {
           window.location.href = '/home';
        } else {
          this.startPage();
        }
    
  }

  startPage() {
    let offset = 0;
    this.setState({ offset, showMore: true }, () => {
      this.getNotificationsList(true);
    });
  }

  getNotificationsList = async () => {
    const { showMore, offset, limit } = this.state;
    if (showMore) {
      this.setState({ LoadingIs: true });
      getNotifications(offset, limit, (data) => {
        console.log("getNotificationsList response", data);
        this.setState({ LoadingIs: false });

        if (data && data.results.length > 0) {
          let data1 = this.state.notificationList;
          let newData = data.results;
          console.log("newData", newData);
          if (offset == 0) {
            // data1 = [...data1, ...newData];
            this.setState({ notificationList: newData });
          } else {
            data1 = [...data1, ...newData];
            this.setState({ notificationList: data1 });
          }

          if (newData.length < limit) {
            this.setState({ showMore: false });
          } else {
            let newoffset = offset + limit;
            this.setState({ showMore: true, offset: newoffset });
          }
        } else {
          this.setState({ showMore: false });
          if (offset == 0) {
            this.setState({ notificationList: [] });
          }
        }
      });
    }
  };

  handleNavigation = async function (item) {
    await this.setState({ is_clicked: !this.state.is_clicked });
    if (!item.is_read) {
      updateNotificationRead(item.id).then((resp) => {
        if (resp.updated) {
          this.props.dispatch(getNotificationAction());
        }
      });
    }
  };

  getRoute = (item) => {
    let res = "/home";
    if (item.action == 1 || item.action == 2) {
      res = "/household";
    } else if (item.action == 3 || item.action == 4 || item.action == 5) {
      res = "/calendar";
    } else if (item.action == 6 || item.action == 7 || item.action == 8) {
      res = "/payment";
    } else if (item.action == 9) {
      res = "/chat";
    }

    return res;
  };

  handleScroll = function (e) {
    //const { offset } = this.state;
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    console.log("bottom", bottom);
    if (bottom) {
      this.getNotificationsList(true);
    }
  };

  render() {
    const { notificationList, LoadingIs } = this.state;
    if (localStorage.third_party === 'true') {
            return null;
    } else{
      return (
      <div className="notification-listing-page">
        <Card
          title="All Notifications"
          bordered={false}
          className="notification-listing-page-card"
        >
          <LoadingOverlay
          active={LoadingIs}
          className={LoadingIs ? "d-block pop-loader" : "d-none pop-loader"}
          spinner
          styles={{
            spinner: (base) => ({
              ...base,
              width: "100px",
              "& svg circle": {
                stroke: "rgb(56, 160, 235)",
              },
            }),
          }}
        ></LoadingOverlay>
          <div className="card-wrap" onScroll={this.handleScroll}>
            {notificationList &&
            Array.isArray(notificationList) &&
            notificationList.length !== 0 ? (
              notificationList.map((item, i) => {
                return (
                  <Link key={i} to={this.getRoute(item)}>
                    <Card
                      bordered={false}
                      className={
                        item.is_read
                          ? "notification-item"
                          : "notification-item pink-list"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => this.handleNavigation(item)}
                    >
                      <div className="list-top">
                        <span>{item.title}</span>
                        <span>{moment(item.created_at).from(moment())}</span>
                      </div>
                      <div className="list-bottom">{item.details}</div>
                    </Card>
                  </Link>
                );
              })
            ) : (
              <div>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </div>
        </Card>
      </div>
    );
    }
    
  }
}
const mapStateToProps = ({ serviceReducer }) => {
  const { notificationCount, notifications } = serviceReducer;
  return {
    notificationCount,
    notifications,
  };
};
export default connect(mapStateToProps)(Notifications);
//export default Notifications;
