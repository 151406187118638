import { put, call } from 'redux-saga/effects';
import { registerUserService, loginUserService } from 'services/authenticationService';

import * as types from 'actions';

export function* registerSaga(payload) {
    try {
        const response = yield call(registerUserService, payload);
        /*yield [
      put({ type: types.REGISTER_USER_SUCCESS, response })
    ];*/
        yield put({ type: types.REGISTER_USER_SUCCESS, response });
    } catch (err) {
        let error = {
            msg: 'Connection error - please try again, or check your internet connection.',
        };
        yield put({ type: types.REGISTER_USER_ERROR, error });
    }
}

export function* loginSaga(payload) {
    try {
        const response = yield call(loginUserService, payload);
        /*yield [
       put({ type: types.LOGIN_USER_SUCCESS, response })
    ];*/
        yield put({ type: types.LOGIN_USER_SUCCESS, response });
    } catch (err) {
        let error = {
            msg: 'Connection error - please try again, or check your internet connection.',
        };
        yield put({ type: types.LOGIN_USER_ERROR, error });
    }
}
