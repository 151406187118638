import React, { Component } from 'react';
import 'pages/ErrorPage/ErrorPage.scss';
import errLogo from 'Assets/error-404.svg';

class ErrorPage extends Component {
    render() {
        return (
            <div className="page-wrap">
                <div className="detail-wrap">
                    <img src={errLogo} alt="error-icon" />
                    <p>Page Not Found</p>
                </div>
            </div>
        );
    }
}

export default ErrorPage;
