import React, { Component } from 'react';
import 'pages/CalenderInner/Custody/Custody.scss';
import { Card, Button, Modal, Form, Input, Select, Collapse } from 'antd';
import more from 'Assets/more.svg';
import { CalendarOutlined } from '@ant-design/icons';
import CalendarDate from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import Calendar from 'pages/CalenderInner/Custody/react-calendar/src';
import { connect } from 'react-redux';
import CustodyTemplateModal from 'pages/CalenderInner/Custody/CustodyTemplateModal';
import { TimePicker } from 'antd';
import moment, { duration } from 'moment';
import { setupCustody } from 'services/calendarService';
import LoadingOverlay from 'react-loading-overlay';
import { checkCookie } from 'utils/cookies';
import { getCoParentsUserAction, getChildrenUserAction } from 'actions/userActions';
import { addLoading, removeLoading } from 'actions/loaderActions';

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;
function onChange(time, timeString) {
}
const colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    'gradient1': 'Gradient1',
    'gradient2': 'Gradient2',
};

let durationsTemplates = [
    {
        label: '1 Week (7 day rotations)',
        pattern: ['0', '0', '0', '0', '0', '0', '0'],
        value: 7,
    },
    {
        label: '2 Weeks (14 day rotations)',
        pattern: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
        value: 14,
    },
    {
        label: '3 Weeks (21 day rotation)',
        pattern: [
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
        ],
        value: 21,
    },
    {
        label: '4 Weeks (28 day rotation)',
        pattern: [
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
            '0',
        ],
        value: 28,
    },
    {
        label: '3 Days',
        pattern: ['0', '0', '0'],
        value: 3,
    },
    {
        label: '4 Days',
        pattern: ['0', '0', '0', '0'],
        value: 4,
    },
    {
        label: '5 Days',
        pattern: ['0', '0', '0', '0', '0'],
        value: 5,
    },
    {
        label: '6 Days',
        pattern: ['0', '0', '0', '0', '0', '0'],
        value: 6,
    },
    {
        label: '7 Days',
        pattern: ['0', '0', '0', '0', '0', '0', '0'],
        value: 7,
    },
    {
        label: '8 Days',
        pattern: ['0', '0', '0', '0', '0', '0', '0', '0'],
        value: 8,
    },
    {
        label: '9 Days',
        pattern: ['0', '0', '0', '0', '0', '0', '0', '0', '0'],
        value: 9,
    },
    {
        label: '10 Days',
        pattern: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
        value: 10,
    },
    {
        label: '11 Days',
        pattern: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
        value: 11,
    },
    {
        label: '12 Days',
        pattern: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
        value: 12,
    },
    {
        label: '13 Days',
        pattern: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
        value: 13,
    },
    {
        label: '14 Days',
        pattern: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
        value: 14,
    },
    {
        label: '15 Days',
        pattern: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
        value: 15,
    },
    {
        label: '16 Days',
        pattern: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
        value: 16,
    },
];

let patternTypes = [
    {
        label: 'Repeat The Rotation',
        value: 'rpt',
    },
    {
        label: 'Alternating Will Switch The Next Rotation',
        value: 'alt',
    },
    {
        label: 'Irregular (For Irregular Schedules)',
        value: 'irr',
    },
];

class CreateCustodyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            modal2Visible: false,
            currentDate: new Date(),
            start_date: moment().valueOf(),
            child_ids: [],
            tab: 0,
            title: '',
            template: '',
            patternType: '',
            patternName: '',
            duration: '',
            pattern: [],
            patternList: [],
            irregularDates: [],
            dates: [],
            highlightDates: [],
            markedDates: [],
            irrPattern: [],
            startTime: moment().set({ h: 12, m: 0, s: 0 }).valueOf(),
            endTime: moment().set({ h: 12, m: 0, s: 0 }).valueOf(),
            errors: {
                title: '',
                kids: '',
            },
            items: [],
            editMode: false,
            loading: false,
        };
        this.handleTitle = this.handleTitle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.setModal3Visible = this.setModal3Visible.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.confirmDate = this.confirmDate.bind(this);
        this.handleChildren = this.handleChildren.bind(this);
        this.getChildrenNames = this.getChildrenNames.bind(this);
        this.handleUseTemplate = this.handleUseTemplate.bind(this);
        this.handleDuration = this.handleDuration.bind(this);
        this.handleTemplateButton = this.handleTemplateButton.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCustomButton = this.handleCustomButton.bind(this);
        this.handlePatternType = this.handlePatternType.bind(this);
        this.handleItems = this.handleItems.bind(this);
        this.getDateArray = this.getDateArray.bind(this);
        this.handlePattern = this.handlePattern.bind(this);
        this.dateFormate = this.dateFormate.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleChilds = this.handleChilds.bind(this);
        this.getDates = this.getDates.bind(this);
        this.handleCustomDates = this.handleCustomDates.bind(this);
        this.handleChilds = this.handleChilds.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.handleOpen) {
            let errors = {
                title: '',
                kids: '',
            };

            this.setState({
                modalVisible: false,
                modal2Visible: false,
                currentDate: new Date(),
                start_date: moment().valueOf(),
                child_ids: [],
                tab: 0,
                title: '',
                template: '',
                patternType: '',
                patternName: '',
                duration: '',
                pattern: [],
                patternList: [],
                irregularDates: [],
                dates: [],
                highlightDates: [],
                markedDates: [],
                irrPattern: [],
                startTime: moment().set({ h: 12, m: 0, s: 0 }).valueOf(),
                endTime: moment().set({ h: 12, m: 0, s: 0 }).valueOf(),
                errors: errors,
                editMode: false,
            });
        }
    }

    componentDidMount() {
        if (checkCookie() !== null) {
            let token = checkCookie();
            this.props.dispatch(getChildrenUserAction());
        }
    }

    getDateArray = function (start, end) {
        var arr = [];
        var dt = new Date(start);
        while (dt <= end) {
            arr.push(new Date(dt));
            dt.setDate(dt.getDate() + 1);
        }
        return arr;
    };

    formatDate = function (date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return month + '/' + day + '/' + year;
    };

    dateFormate = function (date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return year + '-' + month + '-' + day;
    };

    getDates = function (pattern, type, duration) {
        const { start_date } = this.state;
        let date = new Date(start_date);
        let sd = new Date(date);
        let md = new Date(sd);
        let endDate = new Date(md.setMonth(md.getMonth() + 13));
        let obj = {};
        let arr = [];
        let darr = [];
        let items = [];

        if (type === 'irr') {
            while (sd <= endDate) {
                arr.push(new Date(sd));
                sd.setDate(sd.getDate() + 1);
            }
            obj.dates = arr;
            obj.markedDates = darr;
            return obj;
        } else {
            if (pattern.toString() === '1') {
                while (sd <= endDate) {
                    darr.push(new Date(sd));
                    sd.setDate(sd.getDate() + 1);
                }
                obj.dates = arr;
                obj.markedDates = darr;
                return obj;
            } else {
                let ar = pattern;
                for (let i = 0, num = 0; i < ar.length;) {
                    if (ar[i] === '1') {
                        while (i < ar.length && ar[i] === '1') {
                            num++;
                            i++;
                            if (i >= ar.length)
                                break;
                        }
                        items.push(num + '-On');
                        num = 0;
                    } else if (ar[i] === '0') {
                        while (i < ar.length && ar[i] === '0') {
                            num++;
                            i++;
                            if (i >= ar.length)
                                break;
                        }
                        items.push(num + '-Off');
                        num = 0;
                    }
                }

                while (sd <= endDate) {
                    for (let i = 0; i < ar.length; i++) {
                        if (ar[i] === '0') {
                            arr.push(new Date(sd));
                            sd.setDate(sd.getDate() + 1);
                        } else if (ar[i] === '1') {
                            darr.push(new Date(sd));
                            sd.setDate(sd.getDate() + 1);
                        }
                    }
                    if (type === 'alt') {
                        let pat = [];
                        for (let i = 0; i < pattern.length; i++) {
                            if (pattern[i] === '0') {
                                pat.push('1');
                            } else if (pattern[i] === '1') {
                                pat.push('0');
                            }
                        }

                        for (let i = 0; i < pat.length; i++) {
                            if (pat[i] === '0') {
                                arr.push(new Date(sd));
                                sd.setDate(sd.getDate() + 1);
                            } else if (pat[i] === '1') {
                                darr.push(new Date(sd));
                                sd.setDate(sd.getDate() + 1);
                            }
                        }
                    }
                }
                obj.dates = arr;
                obj.markedDates = darr;
                return obj;
            }
        }

    }

    handleCustomDates = async function () {

    }


    handleChange = function () { };

    setModalVisible = function (visible) {
        this.setState({ modalVisible: visible });
    };
    setModal2Visible = function (visible) {
        this.setState({ modal2Visible: visible });
    };
    setModal3Visible = function (visible) {
        this.setState({ modal3Visible: visible });
    };

    handleDate = function (e) {
        this.setState({ currentDate: e });
    };

    handleChilds = function (value) {

    }

    getChildrenNames = function (child_ids) {
        let names = '';
        const { children } = this.props;
        child_ids.forEach((child) => {
            for (let i = 0; i < children.length; i++) {
                let c = children[i];
                if (child == c.id) {
                    names = names + c.name;
                    names = names + ', ';
                }
            }
        });
        names = names.trim();
        names = names.slice(0, names.length - 1);
        return names;
    };

    handleTitle = function (e) {
        if (e.target.value === '') {
            this.setState({ title: e.target.value, errors: { ...this.state.errors, title: 'Field is required!' } });
        } else {
            this.setState({ title: e.target.value, errors: { ...this.state.errors, title: '' } });
        }
    };

    handleDuration = async function (value) {
        const { patternType, start_date } = this.state;
        let obj = durationsTemplates[value];
        let arr = [];
        let startDate = new Date(start_date);
        let endDate = new Date(new Date(start_date).setDate(new Date(start_date).getDate() + obj.value - 1));
        arr = this.getDateArray(startDate, endDate);
        let flag = patternType !== '' ? true : false;
        if (obj.value == 7) {
            obj.label = '1 Week (7 day rotations)';
        } else if (obj.value == 14) {
            obj.label = '2 Weeks (14 day rotations)';
        }

        await this.setState({
            duration: obj.value,
            patternName: obj.label,
            pattern: obj.pattern,
            dates: arr,
            markedDates: [],
            highlightDates: arr,
            editMode: flag,
            items: [obj.value + '-Off'],
        });
    };

    handleChilds = async function (value) {
        if (value.length === 0) {
            await this.setState({ child_ids: value, errors: { ...this.state.errors, kids: 'You have to select at least one item' } });
        } else {
            await this.setState({ child_ids: value, errors: { ...this.state.errors, kids: '' } });
        }
    }

    handleChildren = async function (value) {
        const { child_ids } = this.state;
        let arr = [...child_ids];
        if (arr.includes(value)) {
            let index = arr.indexOf(value);
            arr.splice(index, 1);
            if (arr.length === 0) {
                await this.setState({
                    child_ids: arr,
                    errors: { ...this.state.errors, kids: 'You have to select at least one item' },
                });
            } else {
                await this.setState({ child_ids: arr, errors: { ...this.state.errors, kids: '' } });
            }
        } else {
            await this.setState({
                child_ids: [...this.state.child_ids, value],
                errors: { ...this.state.errors, kids: '' },
            });
        }
    };

    handlePatternType = async function (value) {
        const { duration, highlightDates, pattern, start_date } = this.state;
        if (value === 'irr') {
            let startDate = new Date(start_date);
            let endDate = new Date(startDate.getFullYear() + 1, 12, 0);
            let dates = this.getDateArray(startDate, endDate);
            let arr = [];
            for (let i = 0; i < dates.length; i++) {
                arr.push('0');
            }
            await this.setState({
                patternType: value,
                dates: dates,
                highlightDates: dates,
                markedDates: [],
                irrPattern: arr,
                editMode: true,
                items: [],
            });
        } else {
            let flag = duration !== '' ? true : false;
            let objPattern = pattern.map(el => '0');
            await this.setState({
                patternType: value,
                editMode: flag,
                pattern: objPattern,
                dates: highlightDates,
                highlightDates: highlightDates,
                markedDates: []
            });
        }
    };

    handleItems = function () { };

    handleReset = async function () {
        await this.setState({
            tab: 0,
            pattern: '',
            template: '',
            patternType: '',
            patternName: '',
            duration: '',
            irregularDates: [],
            markedDates: [],
            items: [],
            dates: [],
            highlightDates: [],
            editMode: false,
        });
    };

    handlePattern = async function (value) {
        const { pattern, dates, highlightDates, markedDates, irrPattern, patternType } = this.state;
        let darr = [];
        let arr = pattern;
        let str = [];
        let hd = highlightDates;
        let mr = irrPattern;

        let zr = [];
        hd.forEach((date, i) => {
            zr.push(this.formatDate(date));
        });

        if (patternType !== 'irr') {
            if (zr.includes(this.formatDate(value))) {
                let index = zr.indexOf(this.formatDate(value));
                if (arr[index] == '0') {
                    arr.splice(index, 1, '1');
                } else if (arr[index] == '1') {
                    arr.splice(index, 1, '0');
                }
            }

            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == '0') {
                    darr.push(hd[i]);
                } else if (arr[i] == '1') {
                    str.push(hd[i]);
                }
            }

            let abr = [];
            for (let i = 0, num = 0; i < arr.length;) {
                if (arr[i] === '1') {
                    while (i < arr.length && arr[i] === '1') {
                        num++;
                        i++;
                        if (i >= arr.length) break;
                    }
                    abr.push(num + '-On');
                    num = 0;
                } else if (arr[i] === '0') {
                    while (i < arr.length && arr[i] === '0') {
                        num++;
                        i++;
                        if (i >= arr.length) break;
                    }
                    abr.push(num + '-Off');
                    num = 0;
                }
            }
            await this.setState({ pattern: arr, dates: darr, markedDates: str, items: abr });
        } else {
            if (zr.includes(this.formatDate(value))) {
                let index = zr.indexOf(this.formatDate(value));
                if (mr[index] == '0') {
                    mr.splice(index, 1, '1');
                } else if (arr[index] == '1') {
                    mr.splice(index, 1, '0');
                }
            }

            for (let i = 0; i < mr.length; i++) {
                if (mr[i] == '0') {
                    darr.push(hd[i]);
                } else if (mr[i] == '1') {
                    str.push(hd[i]);
                }
            }

            await this.setState({ pattern: mr, dates: darr, markedDates: str, irregularDates: str });
        }
    };

    handleUseTemplate = async function (obj) {
        const { pattern, patternName, patternType, template, duration, markedDates } = obj;
        let arr = [];
        if (patternName === 'Full Time') {
            arr = ['Full Time Custody'];
        } else {
            for (let i = 0, num = 0; i < pattern.length;) {
                if (pattern[i] === '1') {
                    while (i < pattern.length && pattern[i] === '1') {
                        num++;
                        i++;
                        if (i >= pattern.length) break;
                    }
                    arr.push(num + '-On');
                    num = 0;
                } else if (pattern[i] === '0') {
                    while (i < pattern.length && pattern[i] === '0') {
                        num++;
                        i++;
                        if (i >= pattern.length) break;
                    }
                    arr.push(num + '-Off');
                    num = 0;
                }
            }
        }

        let object = this.getDates(pattern, patternType, duration);

        await this.setState({
            pattern: pattern,
            patternName: patternName,
            patternType: patternType,
            template: template,
            duration: duration,
            markedDates: object.markedDates,
            dates: object.dates,
            items: arr,
        });
    };

    handleTemplateButton = function () {
        const {
            pattern,
            patternName,
            patternType,
            duration,
            template,
            markedDates,
            items,
            dates,
            highlightDates,
            editMode
        } = this.state;
        this.setModalVisible(true);
        this.setState({
            tab: 2,
            pattern: template !== '' ? pattern : '',
            template: template !== '' ? template : '',
            patternType: template !== '' ? patternType : '',
            patternName: template !== '' ? patternName : '',
            duration: template !== '' ? duration : '',
            irregularDates: [],
            markedDates: template !== '' ? markedDates : [],
            items: template !== '' ? items : [],
            dates: template !== '' ? dates : [],
            highlightDates: template !== '' ? highlightDates : [],
            editMode: template !== '' ? editMode : false,
        });
    };

    handleCustomButton = function () {
        const { tab } = this.state;
        if (tab !== 1) {
            this.setState({
                tab: 1,
                pattern: '',
                template: '',
                patternType: '',
                patternName: '',
                duration: '',
                irregularDates: [],
                markedDates: [],
                items: [],
                dates: [],
                highlightDates: [],
                editMode: false,
            });
        }
    };

    handleSubmit = async function () {
        const {
            title,
            child_ids,
            start_date,
            startTime,
            endTime,
            patternType,
            duration,
            template,
            pattern,
            tab,
            irregularDates,
        } = this.state;
        if (title === '' || child_ids.length === 0) {
            if (title === '') {
                await this.setState({ errors: { ...this.state.errors, title: 'Field is required!' } });
            }
            if (child_ids.length === 0) {
                await this.setState({ errors: { ...this.state.errors, kids: 'You have to select at least one item' } });
            }
        } else {
            this.setState({ loading: true });
            let arr = [];
            if (irregularDates.length !== 0) {
                for (let i = 0; i < irregularDates.length; i++) {
                    arr.push(this.dateFormate(irregularDates[i]));
                }
            }

            let m = new Date(this.formatDate(start_date));
            let n = moment(m.setTime(moment(m).set({ 'h': 12, 'm': 0, 's': 0 }).valueOf()).valueOf());


            const newCustody = {
                title: title,
                child_ids: child_ids,
                start_date: moment.parseZone(n._d).format('YYYY-MM-DD'),
                start_time: moment(startTime).format('hh:mm A'),
                end_time: moment(endTime).format('hh:mm A'),
                type: patternType,
                duration:
                    patternType === 'irr' ? (irregularDates.length === 0 ? null : irregularDates.length) : duration,
                template: template,
                pattern: patternType === 'irr' ? ['0'] : pattern,
                tab: tab,
                irregular_dates: arr,
            };

            setupCustody(newCustody).then((resp) => {
                this.setState({ loading: false });
                this.props.handleClose();
                this.props.refresh();
                this.props.dispatch(addLoading());
            });
        }
    };

    confirmDate = async function () {
        const {
            currentDate,
            markedDates,
            dates,
            pattern,
            patternType,
            duration,
            tab,
            editMode,
        } = this.state;

        await this.setState({ start_date: currentDate, modal2Visible: false });

        if (tab === 2 && (markedDates.length !== 0 || dates.length !== 0)) {
            let obj = this.getDates(pattern, patternType, duration);
            await this.setState({ markedDates: obj.markedDates, dates: obj.dates });
        }

        // if(tab === 1 && editMode === true && (markedDates && markedDates.length !== 0 || dates && dates.length !== 0)){

        // }
    };

    render() {
        const {
            patternName,
            patternType,
            currentDate,
            duration,
            start_date,
            child_ids,
            markedDates,
            title,
            tab,
            errors,
            pattern,
            items,
            dates,
            editMode,
            startTime,
            endTime,
            loading,
        } = this.state;
        const { children, user } = this.props;

        function getColor(item) {
            return (item.created_by_user === (user && user.id)) ? item.color : item.coparent_color;
        }


        return (
            <Modal
                title="Normal Schedule Setup"
                className="cal-modal swap-modal sc-modal"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Form className="schedule-form">
                    <div className="scroll-wrap enhance-height">
                        <Form.Item className="input-sm tp-input"  >
                            <Input placeholder="Schedule Name Title" value={title} onChange={this.handleTitle} />
                            {errors.title !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.title}</div>
                            ) : null}
                        </Form.Item>
                        <Form.Item className="select-sm multi-select">
                            <Select
                                placeholder="Choose Kid(s)"
                                value={child_ids}
                                mode="multiple"
                                onChange={this.handleChilds}
                            >
                                {Array.isArray(children) &&
                                    children.map((kid, i) => (
                                        <Option
                                            key={i}
                                            className={child_ids.includes(kid.id) ? 'text-color' : ''}
                                            value={kid.id}
                                        >
                                            <span className={"dot " + colors[getColor(kid)]}></span>
                                            {kid.name}
                                        </Option>
                                    ))}
                            </Select>
                            {errors.kids !== '' ? (
                                <div style={{ color: 'red', fontSize: '12px' }}>{errors.kids}</div>
                            ) : null}
                        </Form.Item>
                        <Form.Item
                            label="When does the schedule starts?"
                            className="input-sm icon-input"
                            labelCol={{ span: 24 }}
                        >
                            <Input
                                onClick={() => this.setModal2Visible(true)}
                                value={this.formatDate(start_date)}
                                placeholder="When does the schedule starts?"
                                suffix={<CalendarOutlined onClick={() => this.setModal2Visible(true)} />}
                            />
                        </Form.Item>
                        <div className="item-wrap btn-row">
                            <Form.Item>
                                <Button
                                    onClick={this.handleTemplateButton}
                                    className={tab === 2 ? 'fp-button-primary full-btn' : 'full-btn btn-white'}
                                    type="primary"
                                >
                                    Use a Template
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    onClick={this.handleCustomButton}
                                    className={tab === 1 ? 'fp-button-primary full-btn' : 'full-btn btn-white'}
                                    type="primary"
                                >
                                    Use Custom
                                </Button>
                            </Form.Item>
                        </div>

                        {tab !== 1 && <div style={{ marginBottom: '20px' }}></div>}

                        {tab === 1 && patternType !== 'irr' && (
                            <Form.Item className="select-sm">
                                <Select
                                    value={patternName !== '' ? patternName : 'Select the total days in the rotation'}
                                    onChange={this.handleDuration}
                                    placeholder="Select the total days in the rotation"
                                >
                                    {Array.isArray(durationsTemplates) &&
                                        durationsTemplates.map((template, i) => (
                                            <Option
                                                key={i}
                                                className={duration === template.value ? 'text-special-color' : ''}
                                                value={i}
                                            >
                                                {template.label}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        )}
                        {tab === 1 && (
                            <Form.Item className="select-sm">
                                <Select
                                    value={
                                        patternType === ''
                                            ? 'Select interval of the rotation'
                                            : patternType === 'rpt'
                                                ? 'Repeat The Rotation'
                                                : patternType === 'alt'
                                                    ? 'Alternating Will Switch The Next Rotation'
                                                    : patternType === 'irr'
                                                        ? 'Irregular (For Irregular Schedules)'
                                                        : 'Select interval of the rotation'
                                    }
                                    onChange={this.handlePatternType}
                                    placeholder="Select interval of the rotation"
                                >
                                    {Array.isArray(patternTypes) &&
                                        patternTypes.map((pattern, i) => (
                                            <Option
                                                key={i}
                                                className={patternType === pattern.value ? 'text-special-color' : ''}
                                                value={pattern.value}
                                            >
                                                {pattern.label}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        )}

                        {/* <div className="detail-wrap">
                            <span>20n - 30ff - 20n Alternate</span>
                        </div> */}
                        {tab == 1 && !(duration && patternType) ? <div style={{ marginBottom: '10px', fontSize: '12px', textAlign: 'center', color: '#58595B' }}>Select the days you have custody in this rotation: </div> : null}
                        <div className="edit-wrap">
                            <div className="cal-wrap">
                                <Calendar
                                    onChange={(e) => {
                                        if (editMode) {
                                            this.handlePattern(e);
                                        }
                                    }}
                                    markedDates={markedDates}
                                    highlightDates={dates}
                                />
                            </div>
                        </div>
                        <div className="line-break"></div>
                        <div className="item-tabs">
                            {Array.isArray(items) &&
                                items.map((item, i) => (
                                    <div key={i} className="item">
                                        {item}
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="btn-scroll-wrap">
                        <Form.Item className="btn-reset">
                            <Button onClick={this.handleReset} className="fp-button-secondary full-btn" type="primary">
                                Reset
                            </Button>
                        </Form.Item>
                        {(pattern.length > 0 && patternType !== '') || patternType == 'irr' ? (
                            <Form.Item>
                                <Button
                                    onClick={this.handleSubmit}
                                    className="fp-button-primary full-btn"
                                    type="primary"
                                >
                                    Apply Custody Schedule
                                </Button>
                            </Form.Item>
                        ) : null}
                    </div>
                </Form>
                <CustodyTemplateModal
                    start_date={start_date}
                    handleUseTemplate={(obj) => this.handleUseTemplate(obj)}
                    openModal={this.state.modalVisible}
                    closeModal={() => this.setModalVisible(false)}
                />
                <Modal
                    title="Calendar"
                    className="cal-modal swap-modal sc-modal"
                    centered
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                >
                    <div className="edit-wrap cal-custom">
                        <div className="scroll-wrap">
                            <div className="cal-wrap">
                                <CalendarDate selected={currentDate} onChange={(e) => this.handleDate(e)} />
                            </div>
                        </div>
                        <div className="btn-scroll-wrap">
                            <Form.Item className="btn-top">
                                <Button onClick={this.confirmDate} className="fp-button-primary" type="primary">
                                    Confirm
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title="Calendar"
                    className="cal-modal swap-modal sc-modal"
                    centered
                    visible={this.state.modal3Visible}
                    onOk={() => this.setModal3Visible(false)}
                    onCancel={() => this.setModal3Visible(false)}
                >
                    <TimePicker />
                    {/* <div className="edit-wrap cal-custom">
                        <div className="scroll-wrap">
                            <div className="cal-wrap">
                          
                            </div>
                        </div>
                        <div className="btn-scroll-wrap">
                            <Form.Item className="btn-top">
                                <Button className="fp-button-primary" type="primary">
                                    Apply
                                </Button>
                            </Form.Item>
                        </div>
                    </div> */}
                </Modal>
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { children, user, loadings, error, coparent } = serviceReducer;
    return { children, user, loadings, error, coparent };
};

export default connect(mapStateToProps)(CreateCustodyModal);
