import React, { Component } from 'react';
import 'pages/Chat/Chat.scss';
import { Row, Col } from 'antd';
import ChatContainer from 'pages/Chat/ChatContainer/ChatContainer';
import ChatTab from 'pages/Chat/ChatTab/ChatTab';
import history from 'utils/history';
import queryString from 'query-string';
import { GetCoParentList,GetCoParentListTP } from 'services/chatService';
import {
    getPermission
} from 'services/memberService';
import { addPermission, removePermission} from 'actions/loaderActions';
import { connect } from 'react-redux';
import { store } from '../../App';
import { getUserById } from 'services/userService';

class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogId: null,
            flag: false,
            users: [],
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (localStorage.third_party === 'true') {
            let data = {
            user_id: localStorage.user_id,
            household_id: localStorage.household_id
            };
            let promises = [];
            promises.push(
                getPermission(data)
                    .then(resp => {
                        if (resp && resp.success && resp.permission) {
                            if (resp.permission.chat === false) {
                                window.location.href = '/home';
                            }
                            localStorage.setItem('third_party_permission', JSON.stringify(resp.permission));
                            localStorage.setItem('calendar_permission', resp.permission.calendar);
                            localStorage.setItem('payment_permission', resp.permission.payment);
                            localStorage.setItem('chat_permission', resp.permission.chat);
                        }
                    })
            );

            Promise.all(promises)
                .then(() => {
                    store.dispatch(addPermission());
                    if (localStorage.chat_permission === 'true') {
                        history.push('/chat');
                        this.setState({ dialogId: null, flag: false });
                        let newuser = [];
                        let arr = [];
                        if(localStorage.third_party !== 'true') {
                            arr.push(
                                GetCoParentList().then(async (resp) => {
                                    if (resp && resp.data && resp.data.length > 0) {
                                        newuser = await resp.data.map((data) => {
                                            data.name = data.first_name;
                                            return data;
                                        });
                                    }
                                })
                            );
                        } else if(localStorage.third_party == 'true') {
                            arr.push(
                                GetCoParentList(localStorage.coparent_id,true).then(async (resp) => {
                                    if (resp && resp.data && resp.data.length > 0) {
                                        newuser = await resp.data.map((data) => {
                                            data.name = data.first_name;
                                            return data;
                                        });
                                    }
                                })
                            );
                        }

                        Promise.all(arr)
                            .then(async () => {
                                newuser = await this.checkUser(newuser);
                                this.setState({
                                    dialogId: null,
                                    flag: false,
                                    users: newuser
                                });
                            })
                    }  
            })
        }
        if (localStorage.third_party !== 'true') {
            history.push('/chat');
            this.setState({ dialogId: null, flag: false });
            let newuser = [];
            let promises = [];

            promises.push(
                GetCoParentList().then(async (resp) => {
                    if (resp && resp.data && resp.data.length > 0) {
                        newuser = await resp.data.map((data) => {
                            data.name = data.first_name;
                            return data;
                        });
                    }
                })
            );

            Promise.all(promises)
                .then(async () => {
                    newuser = await this.checkUser(newuser);
                    this.setState({
                        dialogId: null,
                        flag: false,
                        users: newuser
                    });
                })
        }
    }

    checkUser = async (users) => {
        return new Promise(resolve => {
            let promises = [];
            let arr = [];

            users.forEach(el => {
                if (el.name) {
                    arr.push(el);
                } else {
                    promises.push(
                        getUserById(el.id)
                        .then(resp => {
                            if (resp) {
                                let obj = {};
                                obj.file_url = resp.file_url;
                                obj.first_name = resp.first_name;
                                obj.id = resp.id;
                                obj.name = resp.first_name;
                                obj.picture = resp.picture;
                                obj.qd_userid = resp.qd_userid;
                                arr.push(obj);
                        }
                        })
                    )
                }
            })

            Promise.all(promises)
                .then(() => {
                    resolve(arr);
            })
        })
    }

    handleChange = async function(dialogId) {
        if (dialogId) {
            await this.setState({ dialogId: dialogId, flag: true });
        } else {
            let newuser = [];
            if(localStorage.third_party !== 'true') {
            GetCoParentList().then(async (resp) => {
                if (resp && resp.data && resp.data.length > 0) {
                    newuser = await resp.data.map((data) => {
                        data.name = data.first_name;
                        return data;
                    });
                }
                newuser = await this.checkUser(newuser);
                await this.setState({ dialogId: null, flag: false, users: newuser });
            });
            } else {
                GetCoParentList(localStorage.coparent_id,true).then(async (resp) => {
                    console.log('hhhhhh')
                if (resp && resp.data && resp.data.length > 0) {
                    newuser = await resp.data.map((data) => {
                        data.name = data.first_name;
                        return data;
                    });
                }
                newuser = await this.checkUser(newuser);
                await this.setState({ dialogId: null, flag: false, users: newuser });
            });
            }
        }
    };

    render() {
        if (localStorage.third_party === 'true') {
            if (localStorage.chat_permission === 'false') {
                return null;
            }
        }

        const { users } = this.state;
        let tab = '1';
        let dialogId = this.state.dialogId;
        let flag = this.state.flag;
        

        if (history.location.pathname === '/chat/new') {
            tab = '2';
        } else if (history.location.pathname === '/chat/topic') {
            tab = '3';
        } else if (history.location.pathname === '/chat/archived') {
            tab = '4';
        } else {
            if (history.location.pathname.includes('archived')) {
                tab = '4';
                dialogId = history.location.pathname.slice(15);
                flag = true;
            } else {
                tab = '1';
                if (history.location.pathname === '/chat') {
                    dialogId = null;
                } else {
                    dialogId = history.location.pathname.slice(6);
                    flag = true;
                }
            }
        }

        return (
            <div>
                <Row className="row-container chat-row-container" gutter={'10'}>
                    <Col xs={24} lg={18} className="calender-wrap">
                        <ChatContainer
                            selectedTab={tab}
                            dialogId={dialogId}
                            handleChange={(dialogId) => this.handleChange(dialogId)}
                        />
                    </Col>
                    <Col xs={24} lg={6} className="col-wrap">
                        <ChatTab dialogId={flag ? dialogId : null} users={this.state.users} />
                    </Col>
                </Row>
            </div>
        );
    }
}


export default Chat;
