import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/check-bold.svg';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import PaymentReviewModal from 'pages/Payment/Payments/PaymentReviewModal';
import PaymentReceivedModal from 'pages/Payment/Payments/PaymentReceivedModal';
import SelectPaymentModal from 'pages/Payment/Payments/SelectPaymentModal';
import { getPaymentRequests } from 'services/paymentService';
import { addLoading, removeLoading } from 'actions/loaderActions';

const { Option } = Select;
const { TextArea } = Input;

const refreshSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 13.237 17.024">
        <path
            d="M177.543,26.108a6.5,6.5,0,0,1,6.373-7.246V17.115a.125.125,0,0,1,.2-.09l3.594,2.641a.11.11,0,0,1,0,.177l-3.59,2.641a.125.125,0,0,1-.2-.09V20.65a4.7,4.7,0,0,0-4.255,2.883,4.633,4.633,0,0,0-.364,2.058,4.686,4.686,0,0,0,1.026,2.679.9.9,0,1,1-1.41,1.109A6.41,6.41,0,0,1,177.543,26.108Zm10.376-3.355a4.645,4.645,0,0,1,.661,4.737,4.7,4.7,0,0,1-4.255,2.883V28.631a.125.125,0,0,0-.2-.09l-3.594,2.641a.11.11,0,0,0,0,.177L184.121,34a.125.125,0,0,0,.2-.09V32.162a6.5,6.5,0,0,0,6.373-7.246,6.376,6.376,0,0,0-1.369-3.271.9.9,0,1,0-1.407,1.109Z"
            transform="translate(-177.5 -17)"
        />
    </svg>
);
const RefreshIcon = (props) => <Icon component={refreshSvg} {...props} />;

const frequencies = {
    weekly: 'Weekly',
    'bi-weekly': 'Bi-Weekly',
    monthly: 'Monthly',
    '2-month': '2 Months',
    '3-month': '3 Months',
    '6-month': '6 Months',
    yearly: 'Yearly',
};

class BundlePaymentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modalVisible: false,
            modal1Visible: false,
            modal2Visible: false,
            payments: [],
            offset: 0,
            coparent: null,
            owe: 0,
            owed: 0,
            status: ['pending'],
            payment_ids: [],
            sent_ids: [],
            review_ids: [],
            received_amount: 0,
            pay_amount: 0,
        };
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.getRecentPayments = this.getRecentPayments.bind(this);
        this.getFormattedDate = this.getFormattedDate.bind(this);
        this.handleSelected = this.handleSelected.bind(this);
        this.handleReceived = this.handleReceived.bind(this);
        this.handlePay = this.handlePay.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
    }

    componentDidMount() {
        let offset = 0;
        this.getRecentPayments(offset, this.props.coparent, false);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps && nextProps.error !== null) {
           this.setState({loading: false});
        }
        if(nextProps && nextProps.err !== null) {
            this.setState({loading: false});
        }
        if(nextProps && nextProps.handleOpen){
            let offset = 0;
            let arr = [];
            this.setState({ payments: arr, payment_ids: [], sent_ids: [], review_ids: [], owe: 0, owed: 0, received_amount: 0, pay_amount: 0 });
            this.getRecentPayments(offset, this.props.coparent, true);
        }
        if(nextProps && nextProps.loadings === true) {
            this.props.dispatch(removeLoading());
            let offset = 0;
            let arr = [];
            this.setState({ payments: arr });
            this.getRecentPayments(offset, this.props.coparent, true);
        }
    }

    refreshPage = function(){
        this.props.handleClose();
        this.props.refresh();
    }

    getRecentPayments = function (offset, coparent, flag) {
        const { payments, status } = this.state;
        const {user} = this.props;
        this.setState({ loading: true });
        const sParams = {
            offset: offset,
            limit: 15,
            status: status,
            orderBy: '-updated_at',
            coparent_id: (coparent && coparent.id) || null,
            isBundle: true
        };
        
        this.setState({ offset: offset, coparent: coparent || null });
        getPaymentRequests(sParams).then((resp) => {
            if (resp) {
                if (resp.index !== undefined) {
                    this.setState({ offset: resp.index + 1, coparent: { id: resp.coparent } });
                } else {
                    resp.offset += 15;
                    this.setState({ offset: resp.offset, coparent: { id: resp.coparent } });
                }

                if (flag) {
                    let arr = [];
                    let owe_amount = 0;
                    let owed_amount = 0;
                    arr = [...arr, ...resp.payments];
                    if(Array.isArray(resp.payments) && user) {
                        resp.payments.forEach(item => {
                            if(item.payer_id === user.id) {
                               owe_amount += parseFloat(item.payer_amount);
                            } else if (item.receiver_id === user.id){
                                owed_amount += parseFloat(item.payer_amount);
                            }
                        })
                    }
                    this.setState({ payments: arr, loading: false, owe : this.state.owe + owe_amount, owed: this.state.owed + owed_amount });
                } else {
                    let arr = [];
                    let owe_amount = 0;
                    let owed_amount = 0;
                    arr = [...payments, ...resp.payments];
                    if(Array.isArray(resp.payments) && user) {
                        resp.payments.forEach(item => {
                            if(item.payer_id === user.id) {
                               owe_amount += parseFloat(item.payer_amount);
                            } else if (item.receiver_id === user.id){
                                owed_amount += parseFloat(item.payer_amount);
                            }
                        })
                    }
                    this.setState({ payments: arr, loading: false,  owe : this.state.owe + owe_amount, owed: this.state.owed + owed_amount });
                }
            } else {
                this.setState({ loading: false });
            }
        });
        
    };

    getFormattedDate = function (dateObj) {
        let month = dateObj.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = dateObj.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = dateObj.getFullYear();
        let output = month + '/' + day + '/' + parseInt(year.toString());
        return output;
    };

    setModalVisible = function(visible, arr){
        this.setState({modalVisible: visible, review_ids: arr});
    }

    setModal1Visible = function(visible, arr){
        this.setState({modal1Visible: visible, sent_ids: arr});
    }

    setModal2Visible = function(visible){
        this.setState({modal2Visible: visible});
    }

    handleScroll = function(e) {
        const { offset, coparent } = this.state;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if(bottom) {
            this.getRecentPayments(offset, coparent, false);
        }
    }

    handleSelected = async function(value){
        const {payment_ids, received_amount, pay_amount} = this.state;
        const {user} = this.props;
        let arr = [...payment_ids];
        let r_amount = received_amount;
        let p_amount = pay_amount;
        if(arr.includes(value.id)){
            let index = arr.indexOf(value.id);
            arr.splice(index, 1);
            if(user){
                if(user.id === value.payer_id) {
                    p_amount -= parseFloat(value.payer_amount);
                    p_amount = Math.abs(p_amount);
                } else if(user.id === value.receiver_id) {
                    r_amount -= parseFloat(value.payer_amount);
                    r_amount = Math.abs(r_amount);
                }
            }
            await this.setState({payment_ids: arr, received_amount: r_amount, pay_amount: p_amount });
        } else {
            arr.push(value.id); 
            if(user){
                if(user.id === value.payer_id) {
                    p_amount += parseFloat(value.payer_amount);
                } else if(user.id === value.receiver_id) {
                    r_amount += parseFloat(value.payer_amount);
                }
            }
            await this.setState({payment_ids: arr, received_amount: r_amount, pay_amount: p_amount });
        }
    }

    handleReceived = function(){
        const {payment_ids, payments} = this.state;
        const { user } = this.props;
        let arr = [];
        let darr = [];
        payments.forEach(payment => {
            if(payment_ids.includes(payment.id)){
                if(user.id === payment.receiver_id){
                    arr.push(payment);
                    darr.push(payment.id);
                }
            }
        })

        if(arr.length === 0){
           this.setModal2Visible(true);
        } else{
            this.setModal1Visible(true, darr);
        }
    }

    handlePay = function(){
        const {payment_ids, payments} = this.state;
        const { user } = this.props;
        let arr = [];
        let darr = [];
        payments.forEach(payment => {
            if(payment_ids.includes(payment.id)){
                if(user.id === payment.payer_id){
                    arr.push(payment);
                    darr.push(payment.id);
                }
            }
        })

        if(arr.length === 0){
           this.setModal2Visible(true);
        }else {
            this.setModalVisible(true, darr);
        }
    }

    render() {
        const { user, currentPayment} = this.props;
        const { loading, payments, sent_ids, review_ids, offset, coparent, payment_ids, owe, owed, pay_amount, received_amount } = this.state;
        
        return (
            <div>
                <Modal
                title="Bundle Payments"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                className="payments-popup bp-modal"
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <div className="modal-top">
                    <div className="detail-wrap">
                        <span>You Owe: </span>
                        <span>${owe.toFixed(2)}</span>
                    </div>
                    <div className="detail-wrap">
                        <span>You are owed: </span>
                        <span>${owed.toFixed(2)}</span>
                    </div>
                </div>

                {/* Scroller */}
                <div
                onScroll={this.handleScroll} 
                className="scroll-wrap"
                >
                    {payments && Array.isArray(payments) && payments.length !== 0 ? (
                    payments.map((payment, i) => (
                    <Card 
                    key={i}
                    onClick={() => this.handleSelected(payment)} 
                    bordered={false} 
                    className="list-card icon-card"
                    >
                        <div className="req-inner">
                            <div className="cb-wrap">
                                {payment_ids.includes(payment.id) ? 
                                <div className="img-wrap">
                                    <img src={check} alt="check-icon" />
                                </div>
                                : null}
                                <div className="req-details">
                                    <span>
                                    {user ? payment.payer_username : null}
                                    </span>
                                    <span>
                                    {payment && payment.PaymentCategory && payment.PaymentCategory.name}
                                    </span>
                                </div>
                            </div>
                            <div className="req-action">
                                {payment && payment.recurring === true ? (
                                    <div className="icon-wrap">
                                        <RefreshIcon />
                                        <span>
                                            {payment && frequencies[payment.RecurringPaymentSchedule.frequency]}
                                        </span>
                                    </div>
                                ) : null}
                                <Button 
                                type="primary" 
                                className={
                                    payment && user && 
                                    user.id === payment.payer_id
                                    ? 'btn fp-button-secondary'
                                    : user && user.id === payment.receiver_id
                                    ? 'btn fp-button-primary'
                                    : null
                                }
                                >
                                    <span>
                                        { payment && user && user.id === payment.payer_id
                                            ? 'Review'
                                            : user && user.id === payment.receiver_id
                                            ? 'Sent'
                                            : null
                                        }
                                    </span>
                                </Button>
                            </div>
                            <div className="req-amount">
                                <span>{`$${payment.payer_amount}`}</span>
                                <span>{this.getFormattedDate(new Date(payment.payment_date))}</span>
                            </div>
                        </div>
                    </Card>
                    ))
                    ) : (
                        <div>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                    )}
                </div>

                <div className="bottom-btn">
                    <Button 
                    type="primary"
                    onClick={this.handleReceived} 
                    className="btn fp-button-primary"
                    >
                        <span>
                            <img src={lock} alt="lock-icon" />
                        </span>
                        <span>Received ${received_amount.toFixed(2)}</span>
                    </Button>
                    <Button 
                    type="primary"
                    onClick={this.handlePay} 
                    className="btn fp-button-secondary"
                    >
                        <span>
                            <img src={lock} alt="lock-icon" />
                        </span>
                        <span>Pay ${pay_amount.toFixed(2)}</span>
                    </Button>
                </div>
            </Modal>
                <PaymentReceivedModal 
                payment_ids={sent_ids}
                received_amount={received_amount}
                payments={payments}
                refresh={() => this.refreshPage()}
                handleOpen={this.state.modal1Visible}
                handleClose={() => this.setModal1Visible(false)}
                />
                <PaymentReviewModal 
                payment_ids={review_ids}
                pay_amount={pay_amount}
                payments={payments}
                refresh={() => this.refreshPage()}
                handleOpen={this.state.modalVisible}
                handleClose={() => this.setModalVisible(false)}
                />
                <SelectPaymentModal 
                handleOpen={this.state.modal2Visible}
                handleClose={() => this.setModal2Visible(false)}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparent, loadings } = serviceReducer;
    return { user, error, children, coparent, loadings };
};

export default connect(mapStateToProps)(BundlePaymentModal);
