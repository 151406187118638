import * as types from './index';

export const getMeUserAction = (token) => {
    return {
        type: types.GET_USER,
        token,
    };
};

export const getCheckListAction = () => {
    return {
        type: types.GET_CHECK_LIST,
    };
};

export const getChildrenUserAction = () => {
    return {
        type: types.GET_CHILDREN,
    }
};


export const getCoParentsUserAction = () => {
    return {
        type: types.GET_COPARENTS,
    }
};

export const getCoParentUserAction = (coparent) => {
    return {
        type: types.GET_COPARENT,
        coparent,
    };
};

export const updateQBStatus = (status) => {
    return {
        type: types.QB_CONNECTION_STATUS,
        status: status
    };
};

export const updateSessionFlag = (flag) => {
    return {
        type: types.SET_SESSION_STATUS,
        flag
    };
}

export const updateLoginFlag = (flag) => {
    return {
        type: types.SET_LOGIN_STATUS,
        flag
    };
}

export const getNotificationAction = () => {
    return {
        type: types.GET_NOTIFICATION,
    };
};

