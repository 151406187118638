import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { connect } from 'react-redux';
import {
    updatePayment,
    deletePayment,
    getUserCustomerDetails,
    getCoparentCustomerDetails,
    makeDebitPayment,
    updatePaymentStatus
} from 'services/paymentService';
import DeleteModal from 'pages/Payment/Payments/DeletePaymentModal';
import ACHErrorModal from 'pages/Payment/Payments/ACHErrorModal';
import ACHReceiverErrorModal from 'pages/Payment/Payments/ACHReceiverErrorModal';
import EpaymentErrorModal from 'pages/Payment/Payments/EpaymentErrorModal';
import LoadingOverlay from 'react-loading-overlay';
import PreviewModal from 'pages/Payment/Payments/PreviewModal';
import DeleteAttachmentModal from 'pages/Payment/Payments/DeleteAttachmentModal';
import moment from 'moment';
import { addLoading, removeLoading } from 'actions/loaderActions';
import { getThirdPartyAction } from 'actions/houseHoldAction';
import { getUserById } from 'services/userService';
import { PaymentConst } from 'pages/Payment/paymentConstants';

const { Option } = Select;
const { TextArea } = Input;

const pay_methods = {
    Epayment: 'ACH',
    credit_card: 'Credit Card',
    cash: 'Cash',
    check: 'Check',
};

class ReviewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disputeReason: '',
            flag: false,
            modalVisible: false,
            modal1Visible: false,
            modal2Visible: false,
            modal3Visible: false,
            modal4Visible: false,
            modal5Visible: false,
            paymentMethod: '',
            error: false,
            notes: '',
            amount: 0.0,
            loading: false,
            fileList: [],
            files: [],
            file: null,
            flag1: false,
            reply_note: '',
            index: '',
            isSpecial: false,
            isThirdParty: false,
            coParent: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.getFormattedDate = this.getFormattedDate.bind(this);
        this.getChildrenNames = this.getChildrenNames.bind(this);
        this.handleDispute = this.handleDispute.bind(this);
        this.handleText = this.handleText.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.setModal3Visible = this.setModal3Visible.bind(this);
        this.setModal4Visible = this.setModal4Visible.bind(this);
        this.setModal5Visible = this.setModal5Visible.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handlePay = this.handlePay.bind(this);
        this.handleNotes = this.handleNotes.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.getBase64 = this.getBase64.bind(this);
        this.handleImageDelete = this.handleImageDelete.bind(this);
        this.handleAccept = this.handleAccept.bind(this);
        this.handleReject = this.handleReject.bind(this);
    }

    componentDidMount() {
        if (localStorage.third_party && localStorage.third_party === 'true') {
            this.props.dispatch(getThirdPartyAction({ id : localStorage.member_id }));
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.handleOpen !== prevProps.handleOpen) {
            this.setState({ disputeReason: '', flag: false, paymentMethod: '', error: false });
        }
    }

    UNSAFE_componentWillReceiveProps = async function(nextProps) {
        if (nextProps && nextProps.currentPayment && nextProps.user) {
            let files = [];
            if (nextProps.currentPayment.attachment && nextProps.currentPayment.attachment.length !== 0) {
                nextProps.currentPayment.attachment.forEach((item) => {
                    files.push(item.url);
                });
            }

            let normalUser = localStorage.third_party !== 'true';
            let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';

            let isSpecial = false;
            
            if (normalUser) {
                if (nextProps.currentPayment.status === 'on-hold') {
                    if (nextProps.user.id === nextProps.currentPayment.created_by) {
                        isSpecial = true;
                    }
                }
                await this.setState({ coParent: null });
            }
            
            if (thirdPartyUser) {
                getUserById(localStorage.coparent_id)
                    .then(async resp => {
                        if (resp) {
                            await this.setState({ coParent: resp });
                    }
                })    
            }

            this.setState({
                modalVisible: false,
                modal1Visible: false,
                modal2Visible: false,
                modal3Visible: false,
                modal4Visible: false,
                modal5Visible: false,
                files: files,
                fileList: nextProps.currentPayment.attachment || [],
                notes: nextProps.currentPayment.recurring
                    ? nextProps.currentPayment.RecurringPaymentSchedule.notes
                    : nextProps.currentPayment.notes,
                amount: nextProps.currentPayment.payer_amount,
                flag1:  nextProps.currentPayment.attachment && nextProps.currentPayment.attachment.length === 3 ? true : false,
                reply_note: '',
                isSpecial,
                isThirdParty: thirdPartyUser
            });
        }
    }

    getFormattedDate = function (dateObj) {
        let month = dateObj.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = dateObj.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = dateObj.getFullYear();
        let output = month + '/' + day + '/' + parseInt(year.toString());
        return output;
    };

    getChildrenNames = function (child_ids) {
        let names = '';
        let normalUser = localStorage.third_party !== 'true';
        let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';
        const { children, thirdPartyData } = this.props;
        if (normalUser) {
            child_ids.forEach((child) => {
                for (let i = 0; i < children.length; i++) {
                    let c = children[i];
                    if (child == c.id) {
                        names = names + c.name;
                        names = names + ', ';
                    }
                }
            });
        } else {
            if (thirdPartyData) {
                thirdPartyData.kid.forEach(child => {
                    if (child_ids.includes(child.id)) {
                        names += child.name;
                        names += ', ';
                    }
                })
            }
        }
        names = names.trim();
        names = names.slice(0, names.length - 1);
        return names;
    };

    handleChange = async function (value) {
        await this.setState({ paymentMethod: value, error: false });
    };

    handleNotes = async function (e) {
        await this.setState({ reply_note: e.target.value });
    };

    setModalVisible = async function (modalVisible) {
        await this.setState({ modalVisible: modalVisible });
    };

    setModal1Visible = async function (modalVisible) {
        await this.setState({ modal1Visible: modalVisible });
    };

    setModal2Visible = async function (modalVisible) {
        await this.setState({ modal2Visible: modalVisible });
    };

    setModal3Visible = async function (modalVisible) {
        await this.setState({ modal3Visible: modalVisible });
    };

    setModal4Visible = function (modalVisible, file) {
        this.setState({ modal4Visible: modalVisible, file: file });
    };

    setModal5Visible = function (modalVisible,index) {
        this.setState({ modal5Visible: modalVisible, index:index});
    };

    handleText = async function (e) {
        await this.setState({ disputeReason: e.target.value });
    };

    handlePay = async function () {
        const { paymentMethod, notes, amount, reply_note, fileList } = this.state;
        const { currentPayment, user } = this.props;
        let accountReference;
        if (paymentMethod.length === 0) {
            this.setState({ error: true });
        } else {
            if (paymentMethod === 'Epayment') {
                if (parseFloat(amount) <= 998.75) {
                    getUserCustomerDetails().then((resp) => {
                        if (resp) {
                            if (resp.account && resp.account.id && resp.account.account_status === '1') {
                                accountReference = resp.account.account_reference_id;
                                getCoparentCustomerDetails().then((resp) => {
                                    if (resp && resp.all_data) {
                                        resp.all_data.forEach((coparent) => {
                                            if (
                                                coparent &&
                                                coparent.coparent_id === (currentPayment && currentPayment.receiver_id)
                                            ) {
                                                if (coparent.coparent_account !== false) {
                                                    this.setState({ loading: true });
                                                    let options = {};
                                                    if (currentPayment) {
                                                        options = {
                                                            coParentId: currentPayment.receiver_id,
                                                            Amount: parseFloat(amount).toFixed(2),
                                                            paymentId: currentPayment.id,
                                                            fee: PaymentConst.transfer_fee,
                                                            accountReferenceId: accountReference,
                                                            recurring: currentPayment.recurring,
                                                        };

                                                        makeDebitPayment(options).then((resp) => {
                                                            if (resp) {
                                                                let data = {
                                                                    notes: notes,
                                                                    reply_note: reply_note,
                                                                    full_amount: currentPayment.full_amount,
                                                                    confirm_code: currentPayment.confirm_code,
                                                                    status: 'inProgress',
                                                                    payment_method: 'Epayment',
                                                                    approved_by: user.id,
                                                                    approved_date: new Date(),
                                                                    attachment: fileList,
                                                                };

                                                                updatePayment(currentPayment.id, data).then((res) => {
                                                                    this.setState({ loading: false });
                                                                    this.props.dispatch(addLoading());
                                                                    this.props.handleClose();
                                                                    this.props.refresh();
                                                                });
                                                            }
                                                        });
                                                    }
                                                } else {
                                                    this.setModal2Visible(true);
                                                }
                                            }
                                        });
                                    }
                                });
                            } else {
                                this.setModal1Visible(true);
                            }
                        }
                    });
                } else {
                    this.setModal3Visible(true);
                }
            } else {
                this.setState({ loading: true });
                let options = {};
                if (currentPayment) {
                    options = {
                        notes: notes,
                        reply_note: reply_note,
                        full_amount: currentPayment.full_amount,
                        confirm_code: currentPayment.confirm_code,
                        status: 'paid',
                        payment_method: paymentMethod,
                        approved_by: currentPayment.approved_by || user.id,
                        approved_date: currentPayment.approved_date || new Date(),
                        attachment: fileList,
                    };

                    updatePayment(currentPayment.id, options).then((resp) => {
                        this.setState({ loading: false });
                        this.props.dispatch(addLoading());
                        this.props.handleClose();
                        this.props.refresh();
                    });
                }
            }
        }
    };

    handleDispute = async function () {
        const { disputeReason, notes, fileList, reply_note } = this.state;
        const { currentPayment } = this.props;
        if (disputeReason.length === 0) {
            await this.setState({ flag: true });
        } else {
            this.setState({ loading: true });
            let options = {};
            if (currentPayment) {
                options = {
                    status: 'disputed',
                    dispute_reason: disputeReason,
                    payment_method: currentPayment.payment_method,
                    notes: notes,
                    reply_note: reply_note,
                    attachment: fileList,
                };
            }

            updatePayment(currentPayment.id, options).then((resp) => {
                this.setState({ loading: false });
                this.props.dispatch(addLoading());
                this.props.handleClose();
                this.props.refresh();
            });
        }
    };

    handleDelete = function () {
        this.setState({ loading: true });
        const { currentPayment } = this.props;
        if (currentPayment) {
            deletePayment(currentPayment.id).then((resp) => {
                this.setState({ modalVisible: false, loading: false });
                this.props.dispatch(addLoading());
                this.props.handleClose();
                this.props.refresh();
            });
        }
    };

    handleImageDelete = async function (index) {
        this.setState({index:index})
        const { files, fileList } = this.state;
        let arr = [...files];
        let farr = [...fileList];

        arr.splice(this.state.index, 1);
        farr.splice(this.state.index, 1);


        await this.setState({ files: arr, fileList: farr, flag1: false });
    };

    getBase64 = function (file) {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (upload) {
                resolve(upload.target.result);
            };
            reader.onerror = function (error) {
                
            };
        });
    };

    handleUpload = async function (event) {
        let flag = false;
        if (this.state.files.length === 2) {
            flag = true;
        }
        this.setState({
            files: [...this.state.files, URL.createObjectURL(event.target.files[0])],
            flag1: flag,
        });

        var reader = new FileReader();
        var file = event.target.files[0];
        var name = event.target.files[0].name;
        let url = URL.createObjectURL(file);
        let base64;

        base64 = await this.getBase64(file);

        let upload = {
            uri: url,
            name: name,
            base64: base64,
        };

        this.setState({ fileList: [...this.state.fileList, upload] });
    };

    handleAccept = function(){
        const { user, currentPayment } = this.props;
        this.setState({ loading: true });
        let data = {
            id: currentPayment.id,
            status: 'pending'
        };

        updatePaymentStatus(data)
            .then(resp => {
                this.setState({ loading: false });
                if (resp.success) {
                    this.props.handleClose();
                    this.props.refresh();
                }
        })
    }

    handleReject = () => {
        const { user, currentPayment } = this.props;
        const { _payment } = this.state;
        this.setState({ loading: true });
        let data = {
            id: currentPayment.id,
            status: 'Failed'
        };

        updatePaymentStatus(data)
            .then(resp => {
                this.setState({ loading: false });
                if (resp.success) {
                    this.props.handleClose();
                    this.props.refresh();
                }
        })
    }

    render() {
        const { user, currentPayment, thirdPartyData } = this.props;
        const {
            flag,
            disputeReason,
            fileList,
            reply_note,
            files,
            paymentMethod,
            notes,
            loading,
            amount,
            flag1,
            isSpecial,
            isThirdParty,
            coParent
        } = this.state;
        const isPending = currentPayment ? currentPayment.status === 'pending' : true;
        let isMePayer = currentPayment && user ? user.id === currentPayment.payer_id : false;
        const userPay = currentPayment && user ? user.id === currentPayment.created_by : false;
        const created_at = currentPayment && currentPayment.created_at;
        let hours = moment().diff(moment(created_at), 'hours');
        let days = moment().diff(moment(created_at), 'days');
        let oneDayAgo = hours >= 24 ? true : false;
        return (
            <Modal
                title="Payments Request"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                className="payments-popup custom-popup rb-popup"
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <ScrollArea speed={0.8} horizontal={false}>
                    <Form>
                        <div className="form-scroll">
                            <div className="modal-top">
                                <Form.Item label="Co-parent" labelCol={{ span: 24 }}>
                                    <Input
                                        readOnly
                                        value={
                                            !isThirdParty? (
                                            currentPayment
                                                ? user && user.id === currentPayment.payer_id
                                                    ? currentPayment.receiver_username
                                                    : user && user.id === currentPayment.receiver_id
                                                    ? currentPayment.payer_username
                                                    : null
                                                    : null
                                            ) : (
                                                // currentPayment
                                                // ? coParent && coParent.id === currentPayment.payer_id
                                                //     ? currentPayment.receiver_username
                                                //     : coParent && coParent.id === currentPayment.receiver_id
                                                //     ? currentPayment.payer_username
                                                //     : null
                                                //     : null 
                                                localStorage.getItem('coparent_name')      
                                            )
                                        }
                                    />
                                </Form.Item>
                                <Form.Item label="Kids" labelCol={{ span: 24 }}>
                                    <Input
                                        readOnly
                                        value={currentPayment ? this.getChildrenNames(currentPayment.child_ids) : null}
                                    />
                                </Form.Item>
                            </div>

                            <Form.Item label="Category" labelCol={{ span: 24 }}>
                                <Input readOnly value={currentPayment ? currentPayment.PaymentCategory.name : null} />
                            </Form.Item>
                            <Form.Item label="Message" labelCol={{ span: 24 }}>
                                <TextArea value={notes} readOnly />
                            </Form.Item>
                            <Form.Item label="Note" labelCol={{ span: 24 }}>
                                <TextArea onChange={this.handleNotes} value={reply_note} readOnly={isThirdParty ? true : false} />
                            </Form.Item>
                            {isThirdParty ? null : (
                                <Form.Item className="input-select">
                                    <Select
                                        value={paymentMethod.length === 0 ? 'Payment Method' : pay_methods[paymentMethod]}
                                        onChange={this.handleChange}
                                    >
                                        <Option value="Epayment">ACH</Option>
                                        <Option value="credit_card">Credit Card</Option>
                                        <Option value="cash">Cash</Option>
                                        <Option value="check">Check</Option>
                                    </Select>
                                    {this.state.error ? <div className="field-err">This field is required</div> : null}
                                </Form.Item>
                            )}
                            {fileList.length !== 0 && (
                                <div style={{ marginBottom: flag1 ? '0px' : '20px' }} className="upload-file">
                                    <div className="modal-bottom">
                                        <span>Attachments</span>
                                        <div className="upload-wrapper">
                                            <input
                                                id="img"
                                                type={flag1 === false ? 'file' : 'button'}
                                                multiple={true}
                                                encType="multipart/form-data"
                                                onChange={this.handleUpload}
                                            />
                                            <Button type="primary" className="add-btn fp-button-primary">
                                                Add
                                            </Button>
                                        </div>
                                    </div>
                                    {Array.isArray(files) &&
                                        files.map((file, i) => (
                                            <div className="file-track" key={i}>
                                                <a onClick={() => this.setModal4Visible(true, file)}>View</a>
                                                <span onClick={() =>this.setModal5Visible(true,i)}>Delete</span>
                                            </div>
                                        ))}
                                </div>
                            )}
                            {Array.isArray(files) && files.length === 3 ? (
                                <span className="file-err">You can only select 3 attachments</span>
                            ) : null}

                            <div className="transfer-details">
                                <span>Transfer Amount</span>
                                <span>{currentPayment ? '$' + currentPayment.payer_amount : null}</span>
                            </div>
                            <div className="transfer-details">
                                <span>Transfer Fees</span>
                                <span>
                                    {currentPayment ? (paymentMethod === 'Epayment' ? '$'+PaymentConst.transfer_fee : '$0.00') : null}
                                </span>
                            </div>
                            {paymentMethod === 'Epayment' ? (
                                <div style={{ fontSize: '10px', marginBottom: '10px' }}>
                                    Payments made after 8:00PM Central Time will be processed the next business day.
                                </div>
                            ) : null}

                        </div>
                        <div className="btn-wrap">
                            <div>
                                {flag === false && !isSpecial && !isThirdParty ? (
                                    <Form.Item className="btn-form">
                                        <Button
                                            type="primary"
                                            onClick={this.handlePay}
                                            className="btn fp-button-secondary"
                                        >
                                            {currentPayment
                                                ? paymentMethod === 'Epayment'
                                                    ? 'Pay $' + (parseFloat(amount) + PaymentConst.transfer_fee).toFixed(2)
                                                    : 'Pay $' + amount
                                                : null}
                                        </Button>
                                    </Form.Item>
                                ) : null}
                                {isSpecial && !isThirdParty && (
                                     <Form.Item className="btn-form">
                                     <Button
                                         type="primary"
                                         onClick={this.handleAccept}
                                         className="btn fp-button-secondary"
                                     >
                                        Accept
                                     </Button>
                                 </Form.Item>
                                )}
                            </div>

                            <div>
                                {flag === true && !isSpecial && !isThirdParty ? (
                                    <Form.Item className="dispute-txt" label="Dispute Reason: " labelCol={{ span: 24 }}>
                                        <TextArea onChange={this.handleText} />
                                    </Form.Item>
                                ) : null}
                            </div>

                            {flag === true && !isSpecial && !isThirdParty ? (
                                <div className="dispute-err">You need to provide a dispute reason!</div>
                            ) : null}
                            {!isSpecial && !isThirdParty && (
                                <Form.Item className="btn-form">
                                    <Button type="primary" onClick={this.handleDispute} className="btn fp-button-red">
                                        Dispute
                                    </Button>
                                </Form.Item>
                            )}
                            {isSpecial && !isThirdParty && (
                                <Form.Item className="btn-form">
                                <Button type="primary" onClick={this.handleReject} className="btn fp-button-red">
                                    Reject
                                </Button>
                            </Form.Item>
                            )}
                            {Boolean(isPending && userPay && flag === false && !oneDayAgo) && !isSpecial && !isThirdParty && (
                                <Form.Item className="btn-form">
                                    <Button
                                        type="primary"
                                        onClick={() => this.setModalVisible(true)}
                                        className="btn fp-button-red"
                                    >
                                        Cancel Request
                                    </Button>
                                </Form.Item>
                            )}
                        </div>
                    </Form>
                </ScrollArea>

                <DeleteModal
                    openModal={this.state.modalVisible}
                    closeModal={() => this.setModalVisible(false)}
                    delete={() => this.handleDelete()}
                />
                <ACHErrorModal openModal={this.state.modal1Visible} closeModal={() => this.setModal1Visible(false)} />
                <ACHReceiverErrorModal
                    openModal={this.state.modal2Visible}
                    closeModal={() => this.setModal2Visible(false)}
                />
                <EpaymentErrorModal
                    openModal={this.state.modal3Visible}
                    closeModal={() => this.setModal3Visible(false)}
                />
                <PreviewModal
                    file={this.state.file}
                    handleOpen={this.state.modal4Visible}
                    handleClose={() => this.setModal4Visible(false)}
                />
                    <DeleteAttachmentModal
                    index={this.state.index}
                    openModal={this.state.modal5Visible}
                    closeModal={() => this.setModal5Visible(false)}
                    delete={() => this.handleImageDelete()}
                />
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer, houseHoldReducer  }) => {
    const { user, error, children, coparent } = serviceReducer;
    const { thirdPartyData } = houseHoldReducer;
    return { user, error, children, coparent, thirdPartyData };
};

export default connect(mapStateToProps)(ReviewModal);
