import * as types from 'actions';

const INITIAL_STATE = {
    user: null,
    error: null,
    loadings: false,
    reload: false,
    permission: false,
    children: [],
    coparents: [],
    coparent: null,
    status: false,
    checkListStatus : [],
    sessionError: false,
    loginStatus: false,
    notificationCount: 0,
    notifications: []
};

export default function (state = INITIAL_STATE, action) {
    let {
        user,
        error,
        children,
        coparents,
        coparent,
        checkListStatus,
        notificationCount,
        notifications
    } = state;
    if(action.user){
       user = action.user;
    }else if(action.children){
        children = action.children;
    }else if(action.coparents){
        coparents = action.coparents;
    }else if(action.coparent){
        coparent = action.coparent;
    }else if(action.err){
        error = action.err;
    }else if(action.coparent == null){
        coparent = action.coparent;
    }else if(action.checkListStatus){
        checkListStatus = action.checkListStatus;
    }
    
    
    if (action.type == types.GET_NOTIFICATION_SUCCESS) {
        notificationCount = action.notificationCount;
        notifications = action.notifications;
    }


    switch (action.type) {
        case types.GET_USER_SUCCESS:
            return { ...state, user };
        case types.GET_USER_ERROR:
            return { ...state, error };
        case types.ADD_LOADING_SUCCESS:
            return {...state, loadings: true};
        case types.REMOVE_LOADING_SUCCESS:
            return {...state, loadings: false};
        case types.ADD_RELOADING_SUCCESS:
            return {...state, reload: true};
        case types.REMOVE_RELOADING_SUCCESS:
            return { ...state, reload: false };
        case types.ADD_PERMISSION_SUCCESS:
            return {...state, permission: true};
        case types.REMOVE_PERMISSION_SUCCESS:
            return { ...state, permission: false };
        case types.GET_CHILDREN_SUCCESS:
            return {...state, children};
        case types.GET_COPARENTS_SUCCESS:
            return {...state, coparents};
        case types.GET_COPARENT_SUCCESS:
            return {...state, coparent};
        case types.NEW_QB_STATUS:
            return {...state, status: action.status};
        case types.GET_CHECK_LIST_SUCCESS:
            return {...state, checkListStatus: action.checkListStatus};
        case types.SET_SESSION_ERROR:
            return {...state, sessionError: action.flag};
        case types.SET_LOGIN_STATUS:
            return { ...state, loginStatus: action.flag };
        case types.GET_NOTIFICATION_SUCCESS:
            return { ...state, notificationCount, notifications };
        default:
            return state;
    }
}
