import React, { Component } from 'react';
import { Card, Button } from 'antd';

class RequestCard extends Component {
    
    render() {
    const {user, status, payer_id, coParent, receiver_id, payer_username, receiver_username, amount, date} = this.props;
    let normalUser = localStorage.third_party !== 'true';
    let thirdPartyUser = localStorage.third_party && localStorage.third_party === 'true';
    return (
        <Card onClick={this.props.handleInput} bordered={false} className="list-card">
            <div className="req-inner">
                <div className="req-details"> 
                    <span>{ normalUser && user && user.id === payer_id ? 'Request from' : ( user && user.id === receiver_id) ? 'Request sent to' : null}</span>
                    <span>{normalUser && user && user.id === payer_id ? receiver_username : (user && user.id === receiver_id) ? payer_username : null}</span>
                    
                    <span>{ thirdPartyUser && coParent && coParent && coParent.id === payer_id ? 'Request from' : ( coParent && coParent.id === receiver_id) ? 'Request sent to' : null}</span>
                    <span>{ thirdPartyUser && coParent && coParent && coParent.id === payer_id ? receiver_username : ( coParent && coParent.id === receiver_id) ? payer_username : null}</span>
                </div>
                <div className="req-action">
                    <Button type="primary" className={normalUser  ? (status === 'on-hold' ? 'btn fp-button-secondary' : user && user.id === payer_id ? 'btn fp-button-secondary' : (user && user.id === receiver_id) ? 'btn fp-button-primary' : null) : (coParent ? ( status === 'on-hold' ? 'btn fp-button-secondary' : coParent.id === payer_id ? 'btn fp-button-secondary' : (coParent && coParent.id === receiver_id) ? 'btn fp-button-primary' : null): null)}>
                        <span>{normalUser && user ? (status === 'on-hold' ? 'on-hold' : user.id === payer_id ? 'Review' : (user && user.id === receiver_id) ? 'Sent' : null) : null}</span>

                        <span>{thirdPartyUser && coParent ? (status === 'on-hold' ? 'on-hold' : coParent.id === payer_id ? 'Review' : (coParent && coParent.id === receiver_id) ? 'Sent' : null) : null}</span>
                    </Button>
                </div>
                <div className="req-amount">
                    <span>{`$${amount}`}</span>
                    <span>{date}</span>
               </div>
            </div>
        </Card>
    );
    }
};

export default RequestCard;