import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'pages/Payment/Register/Register.scss';
import { Card, Button, Modal, Select, Input, Checkbox } from 'antd';
import FloatLabel from 'Components/FloatLabel/FloatLabel';
import left from 'Assets/left-arrow.svg';
import { getUserCustomerDetails } from '../../../services/paymentService';
import PaymentSetup from 'pages/Payment/Register/PaymentSetup';
import LoadingOverlay from 'react-loading-overlay';

class SetupCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            modal2Visible: false,
            paymentSetupVisible: false,
            modal4Visible: false,
            LoadingIs: false,
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            address: '',
            city: '',
            zip: '',
            license: '',
            nameOnAccount: '',
            recuringNumber: '',
            accountNumber: '',
            userAccDetails: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.getAllData = this.getAllData.bind(this);
        const { Option } = Select;
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        this.getAllData();
    };
    async getAllData() {
        this.setState({ LoadingIs: true });
        await getUserCustomerDetails()
            .then((resp) => {
                this.setState({ userAccDetails: resp, LoadingIs: false });
            })
            .catch((err) => {
                this.setState({ LoadingIs: false });
            });
    }
    setModalVisible(modalVisible) {
        this.setState({ [modalVisible]: false });
        this.getAllData();
    }
    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible });
    }
    setPaymentSetupVisible(paymentSetupVisible) {
        this.setState({ paymentSetupVisible });
    }
    setModal4Visible(modal4Visible) {
        this.setState({ modal4Visible });
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        const { userAccDetails, LoadingIs } = this.state;
        return (
            <Card bordered={false} className="register-card">
                <LoadingOverlay
                    active={LoadingIs}
                    className="d-block pop-loader"
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '50px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <div className="card-inner">
                    <div className="req-data">{'Configure your ACH Account.'}</div>
                    <div>
                        <Button
                            type="primary"
                            className="fp-button-secondary btn-add-req"
                            onClick={() => this.setPaymentSetupVisible(true)}
                        >
                            <span>
                                {userAccDetails && userAccDetails.account != null ? 'Edit Payment' : 'Setup Payment'}
                            </span>
                        </Button>
                    </div>
                </div>

                <PaymentSetup
                    paymentSetupVisible={this.state.paymentSetupVisible}
                    handleClose={() => this.setModalVisible('paymentSetupVisible')}
                    refreshData={this.getAllData}
                />
            </Card>
        );
    }
}
const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparents, coparent } = serviceReducer;
    return { user, error, children, coparents, coparent };
};

export default connect(mapStateToProps)(SetupCard);
