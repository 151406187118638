import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';

const { Option } = Select;
const { TextArea } = Input;

class PreviewModal extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <Modal
            title="Image Preview"
            centered
            maskClosable={false}
            visible={this.props.handleOpen}
            className="payments-popup custom-popup"
            onOk={this.props.handleClose}
            onCancel={this.props.handleClose}
            >
              
              <img className="img-preview" src={this.props.file} width="300" height="250" alt="Image" />
              
            </Modal>
        );
    }
}

export default PreviewModal;