import React, { Component } from 'react';
import 'pages/SubscriptionNew/EditSubscription.scss';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { Card, Button, Typography, Input, Tabs, Select, Popover } from 'antd';
import { connect } from 'react-redux';
import more from 'Assets/more.svg';
import {
    getSubscriptionPlan,
    getBillingAuthToken,
    cancelSubscription,
    getUserSubscription,
    activateSubscription,
    getSubscriptionCheck,
    updateSubscription,
    notifySubscription
} from '../../services/subscriptionService';
import LoadingOverlay from 'react-loading-overlay';
import ChoosePlanModal from 'pages/SubscriptionNew/ChoosePlanModal';
import SubscriptionErrorModal from 'pages/SubscriptionNew/SubscriptionError';
import ConnectionErrorModal from 'Components/Modal/ConnectionModal';
import { addLoading, removeLoading } from 'actions/loaderActions';

class UpgradeSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            plans: [],
            isSubscribed: false,
            plan_code: '',
            prev_plan_code: '',
            modalVisible: false,
            modal1Visible: false,
            modal2Visible: false,
            plan_price: '',
            msg: '',
            status: '',
            clicked: false,
        };
        this.getSubscription = this.getSubscription.bind(this);
        this.getBillingDetails = this.getBillingDetails.bind(this);
        this.handleUpgrade = this.handleUpgrade.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleActivate = this.handleActivate.bind(this);
        this.handleClickChange = this.handleClickChange.bind(this);
    }

    componentDidMount() {
        this.getSubscription();
    }

    getSubscription = function () {
        this.setState({ loading: true });
        getUserSubscription()
            .then((resp) => {
                localStorage.setItem('resp', JSON.stringify(resp.data));
                this.getBillingDetails();
            })
            .catch((err) => {
                
            });
    }

    getBillingDetails = async function() {
        if (localStorage.resp) {
            let userInfo = JSON.parse(localStorage.resp);
            
            if (userInfo.status && userInfo.status != 'cancelled' && userInfo.subscription_id) {
                await this.setState({ status: userInfo.status, isSubscribed: true, plan_code: userInfo.plan_code, prev_plan_code: '' });
            } else if (userInfo.status) {
                if (userInfo.status == 'cancelled') {
                    await this.setState({ isSubscribed: false, plan_code: userInfo.plan_code });
                }
                
                await this.setState({ status: userInfo.status, prev_plan_code: userInfo.plan_code });
            }
        }
        getBillingAuthToken()
            .then((res) => {
                localStorage.setItem('billingAccessToken', res.data.access_token);
                getSubscriptionPlan()
                    .then((res) => {
                        if (res.data.plans !== undefined) {
                            let defaultIndex = 1;
                            let plans = JSON.parse(JSON.stringify(this.state.plans));
                            plans = res.data.plans;

                            let price;

                            plans.forEach(plan => {
                                if (plan.plan_code == this.state.plan_code) {
                                    price = '$' + plan.recurring_price;
                                    if (this.state.plan_code == '007') {
                                        price += '/month';
                                    } else if (this.state.plan_code == '0013') {
                                        price += '/3 months';
                                    } else if (this.state.plan_code == '0014') {
                                        price += '/6 months';
                                    } else if (this.state.plan_code == '0015') {
                                        price += '/9 months';
                                    } else if (this.state.plan_code == '0011') {
                                        price += '/year';
                                    }
                                }
                            })

                            this.setState({ plans, loading:false, plan_price: price });
                        }
                    })
                    .catch((err) => {
                      
                    });
                
            })
            .catch((err) => {
               
            });
    };

    handleClickChange = (visible) => {
        this.setState({
            clicked: visible
        });
    };


    handleUpgrade = function (_plan) {
        const {
            plans,
            plan_code
        } = this.state;

        this.setState({ loading: true });

        
        let userInfo = JSON.parse(localStorage.getItem('resp'));
        let _data = userInfo.data;
        let _subscription = _data.subscription;

        let planDetails = plans.find(el => el.plan_code == _plan.plan_code);

        let plan = {};
        if (planDetails) {
            plan = {
                plan_code: planDetails.plan_code,
                name: planDetails.name,
                price: planDetails.recurring_price,
                quantity: '1',
            }
        } else {
            plan = _plan;
        }

        let card = {
            plan,
            card_id: _subscription.card.card_id
        };

        updateSubscription(card).then(resp => {
            
            let options = {
                event_type : 'subscription_created',
                data: resp.data
            };
                
            notifySubscription(options)
                .then(data => {
                    this.props.dispatch(addLoading());
                    this.getSubscription();
            })

        })        
    }

    setModalVisible = function (visible) {
        this.setState({ modalVisible: visible });
    }

    setModal1Visible = function (visible) {
        this.setState({ modal1Visible: visible });
    }

    setModal2Visible = function (visible) {
        this.setState({ modal2Visible: visible });
    }

    handleCancel = () => {
        this.setState({ loading: true, clicked: false });
        cancelSubscription()
            .then((res) => {
                const msg = res.data ? res.data.message : 'Unble to cancel the subscription';
                this.setState({ loading: false, msg });
                this.getSubscription();
                this.setModal1Visible(true);
            })
            .catch((err) => {
                this.setState({ loading: false });
                this.setModal2Visible(true);
            });
    };

    handleActivate = () => {
        this.setState({ loading: true, clicked: false });
        activateSubscription()
            .then((res) => {
                const msg = res.data ? res.data.message : 'Unble to reactivate the subscription';
                this.setState({ loading: false, msg });
                this.setModal1Visible(true);
                this.getSubscription();
            })
            .catch((err) => {
                this.setState({ loading: false });
                this.setModal2Visible(true);
            });
    };

    setKey = (clicked) => {
        this.setState({ clicked: !clicked });
    }

    render() {
        const {
            isSubscribed,
            plans,
            plan_code,
            loading,
            plan_price,
            msg,
            status,
            key 
        } = this.state;

        return (
            <LoadingOverlay
                active={loading ? true : false}
                className={'loader-wrap ' + (loading ? 'd-block' : '')}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '100px',
                        '& svg circle': {
                            stroke: 'rgb(56, 160, 235)',
                        },
                    }),
                }}
            >
            <div className="upgrade__box">
                <div className="upgrade__wrapper">
                    <div className="subscription__plan">
                        <span className="plan__text">Subscriptions Plan</span>
                        <span className="plan__price">{plan_price}</span>
                    </div>
                    <div className="subscription__button_box">
                            {(plan_code == '0011' || status == 'cancelled') ? null : (
                                <Button
                                    type="primary"
                                    className="upgrade__button"
                                    onClick={() => this.setModalVisible(true)}
                                >
                                    Upgrade
                                </Button>
                            )}
                        <span className="menu__box">
                            <Popover
                                placement="bottom"
                                trigger="click"
                                visible={this.state.clicked}
                                onClick={() => this.setKey(this.state.clicked)}
                                onVisibleChange={this.handleClickChange}
                                overlayClassName="upgrade__popover"
                                content={<div className="upgrade__popover__model">
                                    {!isSubscribed ? (
                                        <div
                                            onClick={this.handleActivate}
                                            className="primary__item"
                                        >
                                            Reactivate Subscription
                                        </div>
                                    ) : (
                                        <div
                                            onClick={this.handleCancel}
                                            className="delete__item"
                                        >
                                            Cancel Subscription
                                        </div>
                                    )}
                                </div>}
                                trigger="click"

                            >
                                <img src={more} className="upgrade__option_menu" />
                            </Popover> 
                        </span>
                    </div>   
                </div>
                </div>
                <ChoosePlanModal
                    plan_code={plan_code}
                    openModal={this.state.modalVisible}
                    closeModal={() => this.setModalVisible(false)}
                    confirm={(plan) => this.handleUpgrade(plan)}
                />
                <SubscriptionErrorModal
                    openModal={this.state.modal1Visible}
                    closeModal={() => this.setModal1Visible(false)}
                    msg={msg}
                />
                <ConnectionErrorModal
                    handleOpen={this.state.modal2Visible}
                    handleClose={() => this.setModal2Visible(false)}
                />
            </LoadingOverlay>
        )
    }
}


const mapStateToProps = ({ serviceReducer }) => {
    const { user, loadings } = serviceReducer;
    return { user, loadings };
};

export default connect(mapStateToProps)(UpgradeSubscription);