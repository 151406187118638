import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, InputNumber, Radio, DatePicker, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import {getUpdatedActionDispute, setupPaymentAddDispute} from 'services/categoryService';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;


class EditCategoryModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            status: '',
            coParent: null,
            you: 50,
            _coparent: 50,
            updated_at: '',
        };
        this.getStatus = this.getStatus.bind(this);
        this.handleAccept = this.handleAccept.bind(this);
        this.handleDispute = this.handleDispute.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps && nextProps.handleOpen && nextProps.currentCategory && nextProps.coparents) {
            this.getStatus(nextProps.currentCategory.categoryId);
            for(let i = 0;i < nextProps.coparents.length; i++) {
                let cpr = nextProps.coparents[i];
                if(nextProps.currentCategory.created_by_user === cpr.id) {
                    this.setState({coParent: cpr});
                }
            }
            let x = nextProps.currentCategory.You.slice(0, nextProps.currentCategory.You.length-1);
            let y = nextProps.currentCategory.coParent.slice(0, nextProps.currentCategory.coParent.length-1);

            this.setState({you: x, _coparent: y});
            
        }
    }

    getStatus = function(categoryId){
        const {user} = this.props;
        getUpdatedActionDispute(user.id, categoryId)
        .then( async resp => {
        
            if(resp[0][0]){
                const data = resp[0][0];
                if(data.status === 1){
                    await this.setState({status: 'Accepted', updated_at: resp[0][0].updated_at});
                } else if (data.status === 2) {
                    await this.setState({status: 'Disputed', updated_at: resp[0][0].updated_at});
                }
            }
        }).catch(err => {});
    }

    handleAccept = function() {
        this.setState({loading: true});
        const {currentCategory, user} = this.props;
        if(currentCategory){
            let options = {
                id: currentCategory.categoryId,
                default_id: '',
                status: 1,
                creator_percent: currentCategory.creator_amount_percent,
                coparent_percent: currentCategory.coparent_amount_percent,
            };

            setupPaymentAddDispute(options, user.id)
            .then(resp => {

                this.setState({loading: false});
                this.props.handleClose();
                this.props.refresh();
            }).catch(err => {
                this.setState({loading: false});
            })
        }
    }

    handleDispute = function(){
        this.setState({ loading: true });
        const {currentCategory, user} = this.props;
        if(currentCategory){
            let options = {
                id: currentCategory.categoryId,
                default_id: '',
                status: 2,
                creator_percent: currentCategory.creator_amount_percent,
                coparent_percent: currentCategory.coparent_amount_percent,
            };

            setupPaymentAddDispute(options, user.id)
            .then(resp => {
               
                this.setState({ loading: false });
                this.props.handleClose();
                this.props.refresh();
            }).catch(err => {
                this.setState({loading: false});
            })
        }
    }

    render() {
        const {loading, coParent, status, you, _coparent, updated_at} = this.state;
        const  {currentCategory} = this.props;
        return (
            <Modal
            title="Edit Category"
            centered
            maskClosable={false}
            visible={this.props.handleOpen}
            className="payments-popup edit-payment"
            onOk={this.props.handleClose}
            onCancel={this.props.handleClose}
           >
            <LoadingOverlay
                    active={loading ? true : false}
                    className={'loader-wrap pop-loader ' + (loading ? 'd-block' : '')}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
            ></LoadingOverlay>
            <Form>
                <div className="edit-type">
                    <span>Co-parent</span>
                </div>
                <Form.Item className="cust-input">
                    <Input readOnly value={coParent && 'Shared with '+ coParent.name +' \'s household'} />
                </Form.Item>
                <Form.Item label="Category Name" className="input-sm" labelCol={{ span: 24 }}>
                    <Input readOnly value={currentCategory && currentCategory.Category} />
                </Form.Item>
                <div className="input-number">
                    <Form.Item label="You" labelCol={{ span: 24 }}>
                        <InputNumber readOnly value={you} />
                    </Form.Item>
                    <Form.Item label="Co-parent" labelCol={{ span: 24 }}>
                        <InputNumber readOnly value={_coparent} />
                    </Form.Item>
                </div>
                <Form.Item className="cb-form">
                    <Checkbox checked>
                        <span className="cb-text"> I verified % Agreed upon with Co-parent</span>
                    </Checkbox>
                </Form.Item>
                
                <div>
                {currentCategory && currentCategory.current_status === 1 ? (
                    <Form.Item className="btn-full">
                        <Button onClick={this.handleAccept} className="fp-button-primary" type="primary">
                            <span>Accept</span>
                        </Button>
                    </Form.Item>
                ): null}
                </div>
                <div>
                {currentCategory && currentCategory.current_status === 1 ? (
                    <Form.Item className="btn-full">
                        <Button onClick={this.handleDispute} className="fp-button-secondary" type="primary">
                            <span>Dispute</span>
                        </Button>
                    </Form.Item>
                ): null}
                </div>

                <div>
                 {currentCategory && currentCategory.current_status === 2 ? (   
                <table>
                  <thead>  
                    <tr>
                        <th>Date</th>
                        <th>You</th>
                        <th>Co-parent</th>
                        <th></th>
                    </tr>
                   </thead> 
                   <tbody>
                    <tr>
                        <td>{currentCategory &&  moment(updated_at, 'YYYY-MM-DD').format('DD/MM/YY')}</td>
                        <td>{currentCategory && currentCategory.You}</td>
                        <td>{currentCategory && currentCategory.coParent}</td>
                        <td><div style={{color: status ==='Accepted' ? 'green' : 'red'}}>{status}</div></td>
                    </tr>
                    </tbody>
                </table>
                 ): null}
                </div>
            </Form>
         </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparent, coparents } = serviceReducer;
    return { user, error, children, coparent, coparents };
};
export default connect(mapStateToProps)(EditCategoryModal);