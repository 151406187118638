import React, { Component } from 'react';
import 'pages/Payment/Categories/Categories.scss';
import { Card, Button, Table, List, Empty, Divider } from 'antd';
import info from 'Assets/info.png';
import { connect } from 'react-redux';
import { getPaymentCategories } from 'services/paymentService';
import LoadingOverlay from 'react-loading-overlay';
import { PlusOutlined } from '@ant-design/icons';
import AddCategoryModal from 'pages/Payment/Categories/AddCategoryModal';
import EditCategoryModal from 'pages/Payment/Categories/EditCategoryModal';
import SaveCategoryModal from 'pages/Payment/Categories/SaveCategoryModal';
import YouModal from 'pages/Payment/Categories/YouModal';
import CoparentModal from 'pages/Payment/Categories/CoparentModal';
import UpdateModal from 'pages/Payment/Categories/UpdateModal';
import {setupSpecialCategory} from 'services/categoryService';
import {removeReloading} from 'actions/loaderActions';
import SelectCoparentModal from 'pages/Payment/Categories/SelectCoparentModal';
import { getUserById } from 'services/userService';

import moment from 'moment';
import ColumnGroup from 'antd/lib/table/ColumnGroup';

const columns = [
    {
        title: 'Category',
        dataIndex: 'Category',
        key: 'Category',
    },
    {
        title: 'You',
        dataIndex: 'You',
        key: 'You',
    },
    {
        title: 'Co-parent',
        dataIndex: 'coParent',
        key: 'coParent',
    },
    {
        title: '',
        dataIndex: 'status',
        key: 'status',
    },
];

class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            loading: false,
            modalVisible: false,
            modal1Visible: false,
            modal2Visible: false,
            modal3Visible: false,
            modal4Visible: false,
            modal5Visible: false,
            modal6Visible: false,
            currentCategory: null,
            categoryName: '',
        };
        this.getCategories = this.getCategories.bind(this);
        this.getData = this.getData.bind(this);
        this.getOtherCategories = this.getOtherCategories.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.setModal3Visible = this.setModal3Visible.bind(this);
        this.setModal4Visible = this.setModal4Visible.bind(this);
        this.setModal5Visible = this.setModal5Visible.bind(this);
        this.setModal6Visible = this.setModal6Visible.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleYou = this.handleYou.bind(this);
        this.handleCoparent = this.handleCoparent.bind(this);
        this.handleNA = this.handleNA.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    componentDidMount() {
        if (localStorage.third_party && localStorage.third_party === 'true') {
            getUserById(localStorage.coparent_id)
                .then(resp => {
                    if (resp) {
                        this.getCategories(resp);
                    }
                })
        } else {
            this.getCategories(this.props.coparent);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.coparent !== prevProps.coparent) {
            this.getCategories(this.props.coparent);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.err !== null) {
            this.setState({ loading: false });
        }
        if (nextProps && nextProps.error !== null) {
            this.setState({ loading: false });
        }
        if(nextProps && nextProps.reload === true){
            this.props.dispatch(removeReloading());
            this.getCategories(this.props.coparent);
        }
    }

    refreshPage = function () {
        this.getCategories(this.props.coparent);
    };

    getCategories = function (coparent) {
        if (this.props.err === null) {
            this.setState({ loading: true });
        }

        let sParams = {
            orderBy: '-updated_at',
            coparent_id: (coparent && coparent.id) || null,
        };

        if (localStorage.third_party && localStorage.third_party === 'true') {
            sParams.third_party = true;
        }

        getPaymentCategories(sParams).then((resp) => {
            if (resp) {
                this.setState({ categories: resp, loading: false });
            } else {
                this.setState({ loading: false });
            }
        }).catch(err => {
            this.setState({ loading: false });
        })
    };


    modifyCategoryName = (category) => {
        const { user, coparents } = this.props;
        let res = '';
        
        if (user && coparents) {
            if (category.created_by_user === user.id) {
                if (category.coparent_user_id) {
                    coparents.forEach(el => {
                        if (el.id === category.coparent_user_id) {
                            res = category.name + ' with ' + el.name;
                        }
                    })
                } else if (category.coparent_standalone_id) {
                    coparents.forEach(el => {
                        if (el.id !== 222) {
                            if (
                                el.id === category.coparent_standalone_id ||
                                el.invitationsIds.includes(category.coparent_standalone_id)
                            ) {
                                res = category.name + ' with ' + el.name;
                            }
                        }
                    })
                }
            } else {
                coparents.forEach(el => {
                    if (el.id === category.created_by_user) {
                        res = category.name + ' with ' + el.name;
                    }
                })
            }
        }

        return res;
    }

    
    getData = function () {
        let data = [];
        const { categories } = this.state;
        const { user, coparent } = this.props;
        let carr = [];

        categories.forEach(el => {
            carr.push(el.name);
        });

        if (user) {
            if (Array.isArray(categories)) {
                categories.forEach((category, i) => {
                    if (category && category.name !== 'Child Support' && category.name !== 'Spousal Support') {
                        let cg = {};
                        cg.key = i;
                        cg.Category = category.name;
                        cg.You =
                            user && user.id === category.created_by_user
                                ? category.creator_amount_percent + '%'
                                : category.coparent_amount_percent + '%';
                        cg.coParent =
                            user && user.id === category.created_by_user
                                ? category.coparent_amount_percent + '%'
                                : category.creator_amount_percent + '%';
                        cg.status =
                            user && user.id === category.coparent_user_id && category.category_status === 1 ? (
                                <img src={info} alt="info-icon" />
                            ) : null;
                        
                        cg.created_by_user = category.created_by_user;
                        cg.coparent_user_id = category.coparent_user_id;
                        cg.coparent_standalone_id = category.coparent_standalone_id;
                        cg.current_status = category.category_status;
                        cg.categoryId = category.id;
                        cg.updated_at =  category.updated_at;
                        cg.creator_amount_percent = category.creator_amount_percent;
                        cg.coparent_amount_percent = category.coparent_amount_percent;
                        cg.default_cat_id = category.default_cat_id;
                        cg.isDefault = false;

                        if (coparent === null) {
                            if (carr.filter(el => el === cg.Category).length > 1) {
                                cg.duplicate = true;
                                cg.CategoryName = this.modifyCategoryName(category);
                            }
                        }

                        data.push(cg);
                    }
                });

                if(this.props.coparent){
                    let ar = [
                        {
                            Category: 'Expenses',
                            You : 50,
                            coParent: 50,
                            isDefault: true,
                            status: null,
                            default_cat_id: 'df01'
                        },
                        {
                            Category: 'Education',
                            You: 50,
                            coParent: 50,
                            isDefault: true,
                            status: null,
                            default_cat_id: 'df02'
                        },
                        {
                            Category: 'Extracurricular',
                            You: 50,
                            coParent: 50,
                            isDefault: true,
                            status: null,
                            default_cat_id: 'df03'
                        },
                        {
                            Category: 'Healthcare',
                            You: 50,
                            coParent: 50,
                            isDefault: true,
                            status: null,
                            default_cat_id: 'df04'
                        },
                        {
                            Category: 'Vehicle Expenses',
                            You: 50,
                            coParent: 50,
                            isDefault: true,
                            status: null,
                            default_cat_id: 'df05'
                        }
                    ];

                    ar.forEach(item => {
                        let flag = false;
                        data.forEach(cg => {
                            if(cg.default_cat_id === item.default_cat_id){
                                flag = true;
                            }
                        })
                        if(!flag){
                            data.push(item);
                        }
                    });
                }
            }
        }
        return data;
    };

    getOtherCategories = function (categoryName) {
        let cg = null;
        const { categories } = this.state;
        const { user } = this.props;
        if (user) {
            if (Array.isArray(categories)) {
                categories.forEach((category) => {
                    if (category && category.name === categoryName) {
                        cg = category;
                    }
                });
            }
        }

        return cg;
    };

    setModalVisible = async function (visible, category) {
        await this.setState({ modalVisible: visible, currentCategory: category });
    };

    setModal1Visible = async function (category, visible) {
        await this.setState({currentCategory: category, modal1Visible: visible });
    };

    setModal2Visible = async function (category, visible) {
        await this.setState({currentCategory: category, modal2Visible: visible });
    };

    setModal3Visible = async function (category, name, visible) {
        await this.setState({currentCategory: category, categoryName: name, modal3Visible: visible });
    };

    setModal4Visible = async function (category, name, visible) {
        await this.setState({currentCategory: category, categoryName: name, modal4Visible: visible });
    };

    setModal5Visible = async function (category, name, visible) {
        await this.setState({currentCategory: category, categoryName: name, modal5Visible: visible });
    };

    setModal6Visible = async function (visible) {
        await this.setState({ modal6Visible: visible });
    };

    handleClick = function(category){
        const {user} = this.props;
        if(category.isDefault){
            this.setModalVisible(true,category);
        } else {
            if(user.id === category.coparent_user_id || user.id ===  category.coparent_standalone_id) {
                this.setModal1Visible(category, true);
            } else if(user.id === category.created_by_user) {
                this.setModal2Visible(category, true);
            }
        }
    }

    handleUpdate = function(category, name) {
        const {user, coparent} = this.props;
        if(category && coparent){
            if((user.id === category.coparent_user_id || user.id === category.coparent_standalone_id) && category.category_status === 1){
                this.setModal5Visible(category, name, true);
            }
        }
    }

    handleYou = function(category, name) {
        const {user, coparent} = this.props;
        if(coparent){
            if(category === null){
            this.setModal3Visible(category, name, true);
            } else if (user.id === category.created_by_user && category.creator_amount_percent === 0 ) {
            this.setModal3Visible(category, name, true);
            } else if( (user.id === category.coparent_user_id || user.id === category.coparent_standalone_id) && category.category_status === 1) {
            this.setModal5Visible(category, name, true);
            }
        } else {
            this.setModal6Visible(true);
        }
    }

    handleCoparent = function(category, name) {
        const {user, coparent} = this.props;
        if(coparent) {
            if(category === null){
            this.setModal4Visible(category, name, true);
            } else if (user.id === category.created_by_user && category.creator_amount_percent === 100 || (category.creator_amount_percent === 0 && category.coparent_amount_percent === 0) ) {
                this.setModal4Visible(category, name, true);
            }  else if( (user.id === category.coparent_user_id || user.id === category.coparent_standalone_id) && category.category_status === 1) {
                this.setModal5Visible(category, name, true);
            }
        } else {
            this.setModal6Visible(true);
        }
    }

    handleNA = function(category, name) {
        const {coparent, user} = this.props;
        let options = {};
        if(coparent){
            if(category === null){
                options = {
                    name: name,
                    creator_amount_percent: 'n/a',
                    percent_verified: true,
                    default_cat_id: '',
                    is_na: 1,
                    category_status: 3,
                    catIds: 0,
                    coparent_user_id: coparent.id
                };

                setupSpecialCategory(options)
                .then(resp => {
                this.refreshPage();
                });

            } else if(user.id === category.created_by_user && category.category_status !== 3){
                options = {
                    name: name,
                    creator_amount_percent: 'n/a',
                    percent_verified: true,
                    default_cat_id: category.default_cat_id,
                    is_na: 1,
                    category_status: 3,
                    catIds: category.id,
                    coparent_user_id: coparent.id
                };

                setupSpecialCategory(options)
                .then(resp => {
                this.refreshPage();
                });
            }
        } else {
            this.setModal6Visible(true);
        }
    }

    render() {
        const { loading, currentCategory, categoryName, is_na } = this.state;
        const { user, coparent, coparents } = this.props;
        const childSupport = this.getOtherCategories('Child Support');
        const spousalSupport = this.getOtherCategories('Spousal Support');
        const data = this.getData();

        return (
            <Card bordered={false} className="ct-card">
                <LoadingOverlay
                    active={loading ? true : false}
                    className={'loader-wrap cust-loader ' + (loading ? 'd-block' : '')}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <div className="ct-card-head">
                    <h1>Categories</h1>
                    <Button
                        type="primary"
                        onClick={() => this.setModalVisible(true, null)}
                        className="add-item"
                        shape="circle"
                        size="small"
                    >
                        <PlusOutlined />
                    </Button>
                    <AddCategoryModal
                        refresh={() => this.refreshPage()}
                        coparent={coparent}
                        currentCategory={currentCategory}
                        handleOpen={this.state.modalVisible}
                        handleClose={() => this.setModalVisible(false)}
                    />
                </div>
                <div className="ct-card-main">
                    <div className="ct-card-top">
                        <Card>
                            <div onClick={() => this.handleUpdate(childSupport, 'Child Support')} className="card-row">
                                <h2>Child Support</h2>
                                <div className="card-inner">
                                    <span>Who is payer?</span>
                                    <span>
                                        <Button
                                            onClick={() => this.handleYou(childSupport, 'Child Support')}
                                            type="primary"
                                            className={
                                                coparent === null 
                                                    ? 'btn btn-orange fp-button-primary'
                                                    : coparent &&
                                                      user &&
                                                      childSupport &&
                                                      ((childSupport.category_status === 3) || (childSupport.creator_amount_percent === 0 && childSupport.coparent_amount_percent === 0) || 
                                                       (user.id === childSupport.created_by_user &&
                                                          childSupport.creator_amount_percent === 0) ||
                                                          (user.id === childSupport.coparent_user_id &&
                                                              childSupport.coparent_amount_percent === 0))
                                                    ? 'btn btn-grey'
                                                    : 'btn btn-orange fp-button-primary'
                                            }
                                        >
                                            You
                                        </Button>
                                    </span>
                                    <span>
                                        <Button
                                            onClick={() => this.handleCoparent(childSupport, 'Child Support')}
                                            type="primary"
                                            className={
                                                coparent === null
                                                    ? 'btn fp-button-secondary'
                                                    : coparent &&
                                                      user &&
                                                      childSupport &&
                                                      ((childSupport.category_status === 3) || (childSupport.creator_amount_percent === 0 && childSupport.coparent_amount_percent === 0) 
                                                         || (user.id === childSupport.created_by_user &&
                                                          childSupport.creator_amount_percent === 100) ||
                                                          (user.id === childSupport.coparent_user_id &&
                                                              childSupport.coparent_amount_percent === 100))
                                                    ? 'btn btn-grey'
                                                    : 'btn fp-button-secondary'
                                            }
                                        >
                                            Co-parent
                                        </Button>
                                    </span>
                                    <span>
                                        <Button onClick={() => this.handleNA(childSupport,'Child Support')} type="primary" className="btn btn-grey">
                                            N/A
                                        </Button>
                                    </span>
                                    <span className="img-info">
                                        {coparent === null ? null : user &&
                                          childSupport &&
                                          user.id === childSupport.coparent_user_id &&
                                          childSupport.category_status === 1 ? (
                                            <img src={info} alt="info-icon" />
                                        ) : null}
                                    </span>
                                </div>
                            </div>
                            <div onClick={() => this.handleUpdate(spousalSupport, 'Spousal Support')} className="card-row">
                                <h2>Spousal Support</h2>
                                <div className="card-inner">
                                    <span>Who is payer?</span>
                                    <span>
                                        <Button
                                            onClick={() => this.handleYou(spousalSupport, 'Spousal Support')}
                                            type="primary"
                                            className={
                                                coparent === null
                                                    ? 'btn btn-orange fp-button-primary'
                                                    : coparent &&
                                                      user &&
                                                      spousalSupport &&
                                                      ((spousalSupport.category_status === 3) || (spousalSupport.creator_amount_percent === 0 && spousalSupport.coparent_amount_percent === 0) 
                                                         || (user.id === spousalSupport.created_by_user &&
                                                          spousalSupport.creator_amount_percent === 0) ||
                                                          (user.id === spousalSupport.coparent_user_id &&
                                                              spousalSupport.coparent_amount_percent === 0))
                                                    ? 'btn btn-grey'
                                                    : 'btn btn-orange fp-button-primary'
                                            }
                                        >
                                            You
                                        </Button>
                                    </span>
                                    <span>
                                        <Button
                                            onClick={() => this.handleCoparent(spousalSupport, 'Spousal Support')}
                                            type="primary"
                                            className={
                                                coparent === null
                                                    ? 'btn fp-button-secondary'
                                                    : coparent &&
                                                      user &&
                                                      spousalSupport &&
                                                      ((spousalSupport.category_status === 3) || (spousalSupport.creator_amount_percent === 0 && spousalSupport.coparent_amount_percent === 0) 
                                                         || (user.id === spousalSupport.created_by_user &&
                                                          spousalSupport.creator_amount_percent === 100) ||
                                                          (user.id === spousalSupport.coparent_user_id &&
                                                              spousalSupport.coparent_amount_percent === 100))
                                                    ? 'btn btn-grey'
                                                    : 'btn fp-button-secondary'
                                            }
                                        >
                                            Co-parent
                                        </Button>
                                    </span>
                                    <span>
                                        <Button onClick={() => this.handleNA(spousalSupport,'Spousal Support')}type="primary" className="btn btn-grey">
                                            N/A
                                        </Button>
                                    </span>
                                    <span className="img-info">
                                        {coparent === null ? null : user &&
                                          spousalSupport &&
                                          user.id === spousalSupport.coparent_user_id &&
                                          spousalSupport.category_status === 1 ? (
                                            <img src={info} alt="info-icon" />
                                        ) : null}
                                    </span>
                                </div>
                            </div>
                        </Card>
                    </div>

                    <div className="ct-card-bottom">
                        <List
                            header={
                                <div className="thead">
                                    <span className="th">Category</span>
                                    <span className="th">You</span>
                                    <span className="th"> Co-parent</span>
                                    <span className="th"></span>
                                </div>
                            }
                        >
                            {Array.isArray(data) && data.length !== 0 ? (
                            data.map((category, i) => ( 
                            <List.Item key={i}>
                                <Card onClick={() => this.handleClick(category)} className="tbody" bordered={false}>
                                    <div className="td">
                                        <span>{category.duplicate ? category.CategoryName : category.Category}</span>
                                        <span>{category.isDefault ? '--%' : category.You}</span>
                                        <span>{category.isDefault ? '--%' : category.coParent}</span>
                                        {category.status !== null ? (
                                        <span>
                                            <img src={info} alt="alert" />
                                        </span>
                                        ): (
                                        <span></span>
                                        )}
                                    </div>
                                </Card>
                            </List.Item>
                            
                            ))): (
                                <div>
                                   <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                            )}
                        </List>
                    </div>
                    <EditCategoryModal
                        refresh={() => this.refreshPage()}
                        currentCategory={currentCategory}
                        handleOpen={this.state.modal1Visible}
                        handleClose={() => this.setModal1Visible(false)}
                    />
                    <SaveCategoryModal
                        refresh={() => this.refreshPage()}
                        currentCategory={currentCategory}
                        handleOpen={this.state.modal2Visible}
                        handleClose={() => this.setModal2Visible(false)}
                    />
                    <YouModal
                        refresh={() => this.refreshPage()}
                        currentCategory={currentCategory}
                        categoryName={categoryName}
                        handleOpen={this.state.modal3Visible}
                        handleClose={() => this.setModal3Visible(currentCategory, categoryName, false)}
                    />
                    <CoparentModal
                        refresh={() => this.refreshPage()}
                        currentCategory={currentCategory}
                        categoryName={categoryName}
                        handleOpen={this.state.modal4Visible}
                        handleClose={() => this.setModal4Visible(currentCategory, categoryName, false)}
                    />
                    <UpdateModal
                        refresh={() => this.refreshPage()}
                        currentCategory={currentCategory}
                        categoryName={categoryName}
                        handleOpen={this.state.modal5Visible}
                        handleClose={() => this.setModal5Visible(currentCategory, categoryName, false)}
                    />
                    <SelectCoparentModal
                        openModal={this.state.modal6Visible}
                        closeModal={() => this.setModal6Visible(false)}
                    />
                </div>
            </Card>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, coparent, reload, coparents } = serviceReducer;
    return { user, error, coparent, reload, coparents };
};

export default connect(mapStateToProps)(Categories);
