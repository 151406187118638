import React, { useRef, useState, Component} from 'react';
import IdleTimer from 'react-idle-timer';


class IdleTimerContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTimedOut: false
        }
        this.idleTimer = React.createRef();
    }

    _onActive = (e) => {

    }

    handleOnAction = (e) => {
        
    }
      
    onIdle = (e) => {
        this.idleTimer.current.reset();
        this.props.handleClose();
    }
      
      
    render() {
        return (
            <div>
                <IdleTimer
                    ref={this.idleTimer}
                    timeout={ 20 * 60000}
                    onIdle={this.onIdle}
                    onActive={this._onActive}
                    onAction={this.handleOnAction}
                >

                </IdleTimer>
            </div>
        );
    }
}



export default IdleTimerContainer;