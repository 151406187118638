import * as types from 'actions';

const INITIAL_STATE = {
    houseHold: {},
    error: null,
    thirdPartyData: null
};

export default function (state = INITIAL_STATE, action) {   
    let houseHold = action.houseHold; 
    let thirdPartyData = action.thirdPartyData;
    let error = null;
    if(action.err){
        error = action.err;
    }   
    switch (action.type) {      
        case types.GET_HOUSEHOLD_SUCCESS:
            return { ...state, houseHold };
        case types.GET_HOUSEHOLD_ERROR:
            return { ...state, error };
        case types.GET_THIRD_PARTY_SUCCESS:
            return { ...state, thirdPartyData };
        case types.GET_THIRD_PARTY_ERROR:
            return { ...state, error };            
        default:
            return state;
    }
};

