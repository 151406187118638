import { makeRequest } from "./authenticationService";

export const getNotifications = (offset, limit, callback) => {
  let url = `/api/notification?offset=${offset}&limit=${limit}`;
  console.log("getNotifications request");
  //   return makeRequest("GET", url)
  //     .then((resp) => {
  //       return resp;
  //     })
  //     .then((json) => {
  //       console.log("err", json);
  //       return json;
  //     });
  return makeRequest("GET", url).then((resp) => {
    if (callback) {
      callback(resp);
    }
  }, true);
};

export const showNotifications = () => {
  let url = `/api/notification/getNotifications`;

  return makeRequest("GET", url)
    .then((resp) => {
      return resp;
    })
    .then((json) => {
      return json;
    });
};

export const updateNotificationRead = (notificationId) => {
  let url = `/api/notification/update`;

  let data = { is_read: true, id: notificationId };

  return makeRequest("PUT", url, data)
    .then((resp) => {
      return resp;
    })
    .then((json) => {
      return json;
    });
};
