import React, { Component } from 'react';
import { Tabs } from 'antd';
import 'pages/Chat/ChatContainer/ChatContainer.scss';
import Icon from '@ant-design/icons';
import ChatContent from 'pages/Chat/ChatContainer/ChatContent';
import ChatScreen from 'pages/Chat/ChatContainer/ChatScreen';
import ChatArchives from 'pages/Chat/ChatContainer/ChatArchives';
import NewChat from 'pages/Chat/ChatContainer/NewChat';
import NewTopic from 'pages/Chat/ChatContainer/NewTopic';
import ChatRoom from 'pages/Chat/ChatContainer/ChatRoom';
import history from 'utils/history';
import * as QB from 'quickblox/quickblox';


const { TabPane } = Tabs;
const activeSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22.611" viewBox="0 0 24 22.611">
        <defs></defs>
        <path
            className="a"
            d="M.824,22.611a.317.317,0,0,1-.274-.176.372.372,0,0,1,0-.378,12.7,12.7,0,0,0,1.421-5.261.3.3,0,0,0-.057-.2A9.935,9.935,0,0,1,0,10.759,10.161,10.161,0,0,1,3.515,3.151,12.656,12.656,0,0,1,12,0a12.654,12.654,0,0,1,8.485,3.151A10.161,10.161,0,0,1,24,10.759a10.16,10.16,0,0,1-3.515,7.607A12.653,12.653,0,0,1,12,21.517a13.242,13.242,0,0,1-3.524-.476.277.277,0,0,1-.205-.228.3.3,0,0,1,.132-.3c1.791-1.177,1.79-1.249,1.786-1.486a.421.421,0,0,0-.2-.351.414.414,0,0,0-.526.084.394.394,0,0,1-.055.056c-.27.209-1.294.9-2.491,1.679l-.006,0-.007,0,0,0A19.89,19.89,0,0,1,.882,22.6.264.264,0,0,1,.824,22.611ZM18,9.305a2,2,0,1,0,2,2A2,2,0,0,0,18,9.305Zm-6,0a2,2,0,1,0,2,2A2,2,0,0,0,12,9.305Zm-6,0a2,2,0,1,0,2,2A2,2,0,0,0,6,9.305Z"
        />
    </svg>
);

const chatSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <defs>
            <clipPath id="a">
                <rect className="a" width="24" height="24" />
            </clipPath>
        </defs>
        <g className="b">
            <g transform="translate(0 2.485)">
                <path
                    className="a"
                    d="M9.75,4.01C4.365,4.01,0,7.367,0,11.51a6.544,6.544,0,0,0,2.273,4.814A1.238,1.238,0,0,1,2.7,17.373c-.025.2-.056.41-.094.617-.2,1.073.691,2.428,1.673,1.951a12.731,12.731,0,0,0,1.709-1.017,1.341,1.341,0,0,1,1.059-.207,12.493,12.493,0,0,0,2.706.293c5.385,0,9.75-3.355,9.75-7.5S15.135,4.01,9.75,4.01Z"
                    transform="translate(0 -1.01)"
                />
                <path
                    className="a"
                    d="M22.7,12.624a1.265,1.265,0,0,1,.227-.7A6.119,6.119,0,0,0,24,8.51c0-4.143-4.365-7.5-9.75-7.5a12.238,12.238,0,0,0-3.987.654c-.5.173-.295.846.237.846C16.3,2.51,21,6.2,21,10.76a6.839,6.839,0,0,1-1.154,3.768,1.276,1.276,0,0,0,.274,1.8,11.8,11.8,0,0,0,1.111.619C22.211,17.42,23.1,16.07,22.9,15A13.292,13.292,0,0,1,22.7,12.624Z"
                    transform="translate(0 -1.01)"
                />
            </g>
        </g>
    </svg>
);
const topicSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <defs>
            <clipPath id="a">
                <rect className="a" width="24" height="24" />
            </clipPath>
        </defs>
        <g className="b">
            <g className="c">
                <path
                    className="a"
                    d="M6.476,11.789a6.009,6.009,0,0,1,2.516-.607h9.427a1.2,1.2,0,0,0,1.2-1.2V9.139a2.879,2.879,0,0,0-2.861-2.861h-6.54a.409.409,0,0,1-.409-.409,2.753,2.753,0,0,0-.843-2.018,2.754,2.754,0,0,0-2.018-.843H2.861a2.754,2.754,0,0,0-2.018.843A2.753,2.753,0,0,0,0,5.869V18.131q0,.051.006.16c0,.022,0,.042,0,.06a.038.038,0,0,0,.067.023l4.3-5.058A5.958,5.958,0,0,1,6.476,11.789Z"
                />
                <path
                    className="a"
                    d="M23.662,12.983a1.729,1.729,0,0,0-.773-.166H8.992a4.432,4.432,0,0,0-1.833.454A4.33,4.33,0,0,0,5.62,14.376L1.329,19.434a1.314,1.314,0,0,0-.4.843.568.568,0,0,0,.338.549,1.73,1.73,0,0,0,.773.166h13.9a4.436,4.436,0,0,0,1.833-.453,4.327,4.327,0,0,0,1.539-1.105L23.6,14.376a1.314,1.314,0,0,0,.4-.843A.567.567,0,0,0,23.662,12.983Z"
                />
            </g>
        </g>
    </svg>
);
const archiveSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <defs>
            <clipPath id="a">
                <rect className="a" width="24" height="24" />
            </clipPath>
        </defs>
        <g className="b">
            <g transform="translate(0.999 1.733)">
                <path
                    className="a"
                    d="M21.117,1.2H.884A.885.885,0,0,0,0,2.084V6.334H22V2.084A.885.885,0,0,0,21.117,1.2Z"
                    transform="translate(0 -1.2)"
                />
                <path
                    className="c"
                    d="M2.817,22.334a1.521,1.521,0,0,1-1.617-1.4V8.4H21V20.938a1.521,1.521,0,0,1-1.617,1.4ZM14.4,11.072H7.8v1.6a1.208,1.208,0,0,0,1.285,1.11h4.03a1.208,1.208,0,0,0,1.285-1.11Z"
                    transform="translate(-0.1 -1.8)"
                />
            </g>
        </g>
    </svg>
);
const ActiveIcon = () => <Icon component={activeSvg} />;
const ChatIcon = () => <Icon component={chatSvg} />;
const TopicIcon = () => <Icon component={topicSvg} />;
const ArchiveIcon = () => <Icon component={archiveSvg} />;

class ChatContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: props.selectedTab || "1",
            dialogId: props.dialogId || null,
            refreshPage: true,
            chatCreated: '',
        };
        this.handleTab = this.handleTab.bind(this);
        this.showChatDetails = this.showChatDetails.bind(this);
        this.handleArchive = this.handleArchive.bind(this);
        this.archiveChat = this.archiveChat.bind(this);
    }

    

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps) {
            this.setState({
                selectedTab: nextProps.selectedTab || "1",
                dialogId: nextProps.dialogId || null,
                refreshPage: this.state.refreshPage,
            });
        }
    }

    handleTab = async function (key) {
        let url = '/chat';
        if (key === '1') {
            url = '/chat';
        } else if (key === '2') {
            url = '/chat/new';
        } else if (key === '3') {
            url = '/chat/topic';
        } else if (key === '4') {
            url = '/chat/archived';
        }

        
            history.push(url);
            this.props.handleChange(null);
            await this.setState({ selectedTab: key, dialogId: null, refreshPage: false });
        
    };

    showChatDetails = function (dialogId,chatCreatedAt) {
        let url = `/chat/${dialogId}`;
        history.push(url);
        this.props.handleChange(dialogId);
        this.setState({ selectedTab: "1", dialogId: dialogId, refreshPage: false ,chatCreated :chatCreatedAt});
    };

    handleArchive = function(dialogId) {
        let url = `/chat/archived/${dialogId}`;
        history.push(url);
        this.props.handleChange(dialogId);
        this.setState({ selectedTab: "4", dialogId: dialogId, refreshPage: false });
    }
    
    archiveChat = async function() {
       let url = '/chat';
       history.push(url);
       this.props.handleChange(null);
       await this.setState({ selectedTab: "1", dialogId: null, refreshPage: true  });
    }

    showActive = async () => {
        let url = '/chat';
        history.push(url);
        this.props.handleChange(null);
        await this.setState({ selectedTab: "1", dialogId: null, refreshPage: true  });
    }

    render() {
        const { selectedTab, dialogId } = this.state;
        
        return (
            <div className="chat-wrapper-container">
                <Tabs activeKey={selectedTab} onChange={this.handleTab} tabPosition="left" className="chat-tabs">
                    <TabPane
                        tab={
                            <span className="tab-icon-wrap">
                                <ActiveIcon />
                                <span className="tab-data">Active</span>
                            </span>
                        }
                        key="1"
                    >
                        {dialogId === null && (
                        <ChatContent 
                           show={(dialogId,date) => this.showChatDetails(dialogId,date)}
                           refreshPage={this.state.refreshPage}
                           value={selectedTab}
                        />
                        )}

                        {dialogId !== null && (
                        <ChatRoom
                            show={(dialogId,date) => this.showChatDetails(dialogId,date)}
                            dialogId={this.state.dialogId}
                            chatCreated={this.state.chatCreated}
                            value={selectedTab}
                            refreshPage={this.state.refreshPage}
                            archiveMsg={() => this.archiveChat()}
                        />
                        )}

                        {/* <ChatScreen /> */}
                    </TabPane>
                    
                        <TabPane
                            tab={
                                <span className="tab-icon-wrap">
                                    <ChatIcon />
                                    <span className="tab-data">New Chat</span>
                                </span>
                            }
                            key="2"
                        >
                            <NewChat
                                value={selectedTab}
                                refreshPage={this.state.refreshPage}
                                archiveMsg={() => this.archiveChat()}
                                displayActive={() => this.showActive()}
                                show={(dialogId) => this.showChatDetails(dialogId)}
                            />
                        </TabPane>
                   
                    
                        <TabPane
                            tab={
                                <span className="tab-icon-wrap">
                                    <TopicIcon />
                                    <span className="tab-data">New Topic</span>
                                </span>
                            }
                            key="3"
                        >
                            <NewTopic
                                value={selectedTab}
                                refreshPage={this.state.refreshPage}
                                archiveMsg={() => this.archiveChat()}
                                displayActive={() => this.showActive()}
                                show={(dialogId) => this.showChatDetails(dialogId)}
                            />
                        </TabPane>
                    
                    <TabPane
                        tab={
                            <span className="tab-icon-wrap">
                                <ArchiveIcon />
                                <span className="tab-data">Archives</span>
                            </span>
                        }
                        key="4"
                    >
                        {dialogId === null && (
                            <ChatArchives 
                              archive={(dialogId) => this.handleArchive(dialogId)}
                              refreshPage={this.state.refreshPage}
                              value={selectedTab}
                            />
                        )}
 
                        {dialogId !== null && (
                        <ChatRoom
                            dialogId={this.state.dialogId}
                            value={selectedTab}
                            IsFromArchived={true}
                            refreshPage={this.state.refreshPage}
                        />
                        )}

                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default ChatContainer;
