import React, { Component } from 'react';
import 'pages/Chat/ChatContainer/ChatContainer.scss';
import { GiftedChat, Bubble, Send } from 'react-web-gifted-chat';
import Icon from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import * as QB from 'quickblox/quickblox';
import { Button, Modal, Input, Checkbox } from 'antd';
import moment from 'moment';
import { ConnectChatServer, IsConnected } from 'pages/QuickBlox/qbutility';
import userImg from 'Assets/default.jpg';
import {
    ChatHistory,
    JoinChatGroup,
    SendToGroup,
    MarkMessageRead,
    UploadAttachment,
    SendAttachment,
} from 'pages/QuickBlox/qbChat';
import { GetChatDetail } from 'services/chatService';
import ArchiveModal from 'pages/Chat/ChatContainer/ArchiveModal';
import NewTopicChat from 'pages/Chat/ChatContainer/NewTopicChat';
import LoadingOverlay from 'react-loading-overlay';
import UpdateTopicModal from 'pages/Chat/ChatContainer/UpdateTopicModal';
import SwapErrorModal from 'pages/CalenderInner/Swap/SwapErrorModal';
import ReactAudioPlayer from 'react-audio-player';
import SelectTopicModal from 'pages/Chat/ChatContainer/SelectTopicModal';
import { checkCookie } from 'utils/cookies';
import { getHouseHoldAction, saveHouseHoldAction } from 'actions/houseHoldAction.js';
import { connect } from 'react-redux';

const archiveSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <defs>
            <clipPath id="a">
                <rect className="a" width="24" height="24" />
            </clipPath>
        </defs>
        <g className="b">
            <g transform="translate(0.999 1.733)">
                <path
                    className="a"
                    d="M21.117,1.2H.884A.885.885,0,0,0,0,2.084V6.334H22V2.084A.885.885,0,0,0,21.117,1.2Z"
                    transform="translate(0 -1.2)"
                />
                <path
                    className="c"
                    d="M2.817,22.334a1.521,1.521,0,0,1-1.617-1.4V8.4H21V20.938a1.521,1.521,0,0,1-1.617,1.4ZM14.4,11.072H7.8v1.6a1.208,1.208,0,0,0,1.285,1.11h4.03a1.208,1.208,0,0,0,1.285-1.11Z"
                    transform="translate(-0.1 -1.8)"
                />
            </g>
        </g>
    </svg>
);

const ArchiveIcon = () => <Icon component={archiveSvg} />;

const editSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <defs>
            <clipPath id="a">
                <rect className="a" width="24" height="24" transform="translate(968 114)" />
            </clipPath>
        </defs>
        <g className="b" transform="translate(-968 -114)">
            <g transform="translate(970.275 116.275)">
                <path className="c" d="M0,16.2v4.052H4.052L16.01,8.3,11.958,4.244Z" transform="translate(0 -0.805)" />
                <path
                    className="c"
                    d="M22.2,2.839,19.676.316a1.083,1.083,0,0,0-1.529,0L16.169,2.294l4.052,4.052L22.2,4.369A1.083,1.083,0,0,0,22.2,2.839Z"
                    transform="translate(-3.066)"
                />
            </g>
        </g>
    </svg>
);
const EditIcon = () => <Icon component={editSvg} />;

const { TextArea } = Input;

class NewTopic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            modalVisible: false,
            modal1Visible: false,
            modal2Visible: false,
            modal3Visible: false,
            modal4Visible: false,
            modal5Visible: false,
            newMessageText: '',
            dialogId: '',
            user: {},
            users: [],
            isTopic: false,
            editMode: false,
            showPickerTypeModal: false,
            copyMode: false,
            showloadEarlier: false,
            currentPlayingAudio: null,
            userlist: [],
            IsOwner: false,
            IsFromArchived: props.IsFromArchived || false,
            fpDialogID: '',
            topicTitle: '',
            skip: 0,
            limit: 20,
            keyboardShown: false,
            showUploadModal: false,
            archived: false,
            loading: false,
            copyMessages: [],
        };
        this.onSend = this.onSend.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.ConnectToChatServer = this.ConnectToChatServer.bind(this);
        this.CallChatDetailApi = this.CallChatDetailApi.bind(this);
        this.FetchQBData = this.FetchQBData.bind(this);
        this.CallChatHistory = this.CallChatHistory.bind(this);
        this.ParseChatHistory = this.ParseChatHistory.bind(this);
        this.LoginedUser = this.LoginedUser.bind(this);
        this.GetUserData = this.GetUserData.bind(this);
        this.getUsersName = this.getUsersName.bind(this);
        this.getBase64 = this.getBase64.bind(this);
        this.PopulateChat = this.PopulateChat.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.onSendAttachment = this.onSendAttachment.bind(this);
        this.onSendMessage = this.onSendMessage.bind(this);
        this.UpdateChat = this.UpdateChat.bind(this);
        this.handleText = this.handleText.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.setModal3Visible = this.setModal3Visible.bind(this);
        this.setModal4Visible = this.setModal4Visible.bind(this);
        this.setModal5Visible = this.setModal5Visible.bind(this);
        this.onLongPress = this.onLongPress.bind(this);
        this.handleCopy = this.handleCopy.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    componentDidMount() {
        if (checkCookie() !== null) {
            let token = checkCookie();
            this.props.dispatch(getHouseHoldAction());
        }
        
        this.setState({ loading: true });
        if (QB.chat && QB.chat.isConnected) {
            this.setState({ modalVisible: true, loading: false });
        } else {
            this.ConnectToChatServer();
        }
    }

    UNSAFE_componentWillReceiveProps = async function(nextProps) {
        if (nextProps && nextProps.value === '3') {
                await this.setState({
                    messages: [],
                    modalVisible: true,
                    modal1Visible: false,
                    modal2Visible: false,
                    modal3Visible: false,
                    modal4Visible: false,
                    modal5Visible: false,
                    newMessageText: '',
                    dialogId: '',
                    user: {},
                    users: [],
                    isTopic: false,
                    editMode: false,
                    showPickerTypeModal: false,
                    copyMode: false,
                    showloadEarlier: false,
                    currentPlayingAudio: null,
                    userlist: [],
                    IsOwner: false,
                    IsFromArchived: this.props.IsFromArchived || false,
                    fpDialogID: '',
                    topicTitle: '',
                    skip: 0,
                    limit: 20,
                    keyboardShown: false,
                    showUploadModal: false,
                    archived: false,
                    loading: true,
                    copyMessages: [],
                });
            
            
                if (QB.chat && QB.chat.isConnected) {
                    await this.setState({ modalVisible: true, loading: false });
                } else {
                    this.ConnectToChatServer();
                }
            }
    }

    ConnectToChatServer = async () => {
        let userid = await localStorage.getItem('qd_userid');
        let email = await localStorage.getItem('qb_username');
        let isconnected = IsConnected();
        if (!isconnected && userid && email) {
            let res = await ConnectChatServer(userid, email);
            if (res) {
                this.setState({ modalVisible: true, loading: false });
            }
        }
    };

    setModalVisible = function (visible) {
        this.setState({ modalVisible: visible });
    };

    setModal2Visible = (visible) => {
        this.setState({ modal2Visible: visible });
    };

    setModal3Visible = (visible) => {
        this.setState({ modal3Visible: visible });
    };

    setModal4Visible = (visible) => {
        this.setState({ modal4Visible: visible });
    };

    setModal5Visible = async function (visible) {
        if (visible) {
            await this.setState({ modal5Visible: visible });
        } else {
            await this.setState({ modal5Visible: visible, copyMessages: [] });
        }
    }

    refreshPage = async (_dialogId) => {
        await this.setState({ dialogId: _dialogId, loading: true });
        let result = await JoinChatGroup(this.state.dialogId);
        this.CallChatDetailApi();
        QB.chat.onMessageListener = this.onMessage;
    };

    onMessage = (userId, message) => {
        let user_id = localStorage.getItem('qd_userid');
        if (!(userId == user_id)) {
            message.sender_id = userId;
            this.PopulateChat(message);
        }
    };

    CallChatDetailApi = async () => {
        let userId = await localStorage.getItem('user_id');
        GetChatDetail(this.state.dialogId).then(async (resp) => {
            if (resp && resp.data) {
                let dialogdata = resp.data.Dialogues;
                var archivedtime = resp.data.archived_at;
                let archivedTimestamp = Math.round(new Date(archivedtime).getTime() / 1000);

                let isTopic = dialogdata.type == 1 ? true : false;
                let fpDialogID = dialogdata.id;
                let members = dialogdata.DialogueMembers;
                let topicTitle = dialogdata.name;
                let IsOwner = dialogdata.create_by == userId;
                let archived = resp.data.is_archived;
                let archived_at = archivedTimestamp;

                let userlist = await members.map((mem) => {
                    mem.first_name = mem.userInfo.first_name;
                    mem.last_name = '';
                    mem.picture = mem.userInfo.file_url;
                    return mem;
                });

                this.setState(
                    {
                        IsOwner,
                        fpDialogID,
                        topicTitle,
                        userlist,
                        isTopic,
                        archived,
                        archived_at,
                    },
                    () => {
                        this.FetchQBData();
                    },
                );

            }
        });
    };

    FetchQBData = async () => {
        let userlist = this.state.userlist;
        if (userlist && userlist.length > 0) {
            let newuser = await userlist.map((data) => {
                data.name = data.first_name;
                data._id = data.qb_user_id;
                return data;
            });

            newuser.forEach((user) => {
                user.avatar = user.picture ? user.picture : userImg;
            });

            this.setState({ users: newuser, loading: false }, async () => {
                await this.LoginedUser();
                await this.CallChatHistory();
            });
        }
    };

    CallChatHistory = async () => {
        const { archived, archived_at, IsFromArchived } = this.state;
        let filter = {};
        if (archived) {
            if (IsFromArchived) {
                filter = {
                    field: QB.chat.MESSAGES_FILTER.FIELD.DATE_SENT,
                    operator: QB.chat.MESSAGES_FILTER.OPERATOR.LTE,
                    value: '' + archived_at,
                };
            } else {
                filter = {
                    field: QB.chat.MESSAGES_FILTER.FIELD.DATE_SENT,
                    operator: QB.chat.MESSAGES_FILTER.OPERATOR.GTE,
                    value: '' + archived_at,
                };
            }
        }
        let chathistory = await ChatHistory(this.state.dialogId, this.state.limit, this.state.skip, filter, archived);

        await this.ParseChatHistory(chathistory);
    };

    ParseChatHistory = async (chatHistory) => {
        if (!chatHistory || !(Array.isArray(chatHistory.items) && chatHistory.items.length > 0)) {
            this.setState({ showloadEarlier: false });
            this.AddCopyMessages();
            return;
        }

        let allmessages = this.state.messages;

        for (const row of chatHistory.items) {
            let senderid = row.sender_id;

            let _user = await this.GetUserData(senderid);
            let messagebody = '';
            if (row.message && row.message != 'null') {
                messagebody = row.message;
            }
            const message = {
                id: row._id,
                text: messagebody,
                createdAt: row.created_at,
                user: _user,
                //pending: true,
                sent: true,
                received: false,
                selected: false,
            };

            if (row.attachments) {
                const { attachments } = row;
                const attachment = attachments[0];

                let url = QB.content.privateUrl(attachment && attachment.id);

                if (url) {
                    if (attachment && attachment.type == 'image') {
                        message.image = url;
                    }
                    if (attachment && attachment.type == "audio") {
                        message.audio = url;
                    }
                }
            }

            allmessages.push(message);
        }

        let count = this.state.skip + this.state.limit;


        this.setState({ messages: allmessages, loading: false, skip: count }, () => {
            //this.AddCopyMessages();
        });

        if (this.state.limit > chatHistory.items.length) {
            this.setState({ showloadEarlier: false });
        } else {
            this.setState({ showloadEarlier: true });
        }
    };

    AddCopyMessages = async () => {
        const { user, selectedMessages } = this.state;

        const updates = [];
        if (selectedMessages && selectedMessages.length > 0) {
            for (let i in selectedMessages) {
                const originalMessage = {
                    ...selectedMessages[i],
                };

                const newMessage = {
                    ...originalMessage,
                    // _idOriginal: originalMessage._id,
                    _id: uuid(),
                    // createdAtOriginal: originalMessage.createdAt,
                    createdAt: moment(),
                    // userOriginal: originalMessage.user,
                    user: { ...user },
                    sent: false,
                    received: false,
                    isCopy: true,
                    selected: false,
                };

                updates.push(newMessage);
            }

            await this.setState((previousState) => ({
                messages: GiftedChat.append(previousState.messages, updates),
                selectedMessages: [],
            }));
        }
    };

    GetUserData = async (userid) => {
        let user = await this.state.users.filter((row) => {
            return row._id == userid;
        });
        if (user.length > 0) {
            return user[0];
        } else {
            return {};
        }
    };

    LoginedUser = async () => {
        let userId = await localStorage.getItem('qb_userid');
        let user = await this.GetUserData(userId);
        user.avatar = user.picture ? user.picture : userImg;
        this.setState({ user: user });
    };

    getUsersName = function (users) {
        let res = '';
        let arr = [];
        if (Array.isArray(users)) {
            users.forEach((user) => {
                if (!arr.includes(user.first_name)) {
                    arr.push(user.first_name);
                }
            });
        }

        res = arr.join(', ');
        return res;
    };

    PopulateChat = async (receivedmessage) => {
        if (receivedmessage.body || receivedmessage.extension.attachments) {
            let senderid = receivedmessage.sender_id;

            let _user = { ...this.state.user };
            if (senderid == _user._id) {
                return;
            }

            MarkMessageRead(receivedmessage);

            _user = await this.GetUserData(senderid);

            let messagebody = '';
            if (receivedmessage.body && receivedmessage.body != 'null') {
                messagebody = receivedmessage.body;
            }

            const message = {
                id: receivedmessage.id,
                text: messagebody,
                createdAt: moment().toDate(),
                user: _user,
                //pending: true,
                sent: true,
                received: false,
                selected: false,
            };

            if (receivedmessage.extension.attachments && receivedmessage.extension.attachments.length > 0) {
                const { attachments } = receivedmessage.extension;
                const attachment = attachments[0];

                let url = QB.content.privateUrl(attachment && attachment.id);

                if (url) {
                    if (attachment && attachment.type == 'image') {
                        message.image = url;
                    }
                    if (attachment && attachment.type == "audio") {
                        message.audio = url;
                    }
                }
            }

            let oldmessages = this.state.messages;
            oldmessages.unshift(message);

            await this.setState((previousState) => ({
                messages: GiftedChat.append([], oldmessages),
            }));
        }
    };

    getBase64 = async function (file) {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (upload) {
                resolve(upload.target.result);
            };
            reader.onerror = function (error) {
            };
        });
    };

    handleUpload = async (event) => {
        let file = event.target.files[0];
        if (file.type.includes('image')) {
            this.setModal3Visible(false);
            file.uri = URL.createObjectURL(file);
            file.base64 = await this.getBase64(file);
            this.onSendAttachment(file);
        } else {
            this.setModal4Visible(true);
        }
    };

    onSendAttachment = async (image) => {
        const { newMessageText: text, user } = this.state;

        let _user = { ...user };
        let messageid = uuid();

        const message = {
            id: messageid,
            text: text,
            createdAt: moment().toDate(),
            user: _user,
            sent: false,
            received: false,
            selected: false,
        };

        const UID = await UploadAttachment(image);

        if (UID) {
            message.image = image.uri;
            message.percent = 0;
            await this.setState((previousState) => ({
                messages: GiftedChat.append(previousState.messages, message),
                newMessageText: '',
            }));

            let response = SendAttachment(this.state.dialogId, UID);
            if (response) {
                this.UpdateChat(messageid);
            }
        }
    };

    renderBubble = (props) => {
        if(props.currentMessage.audio) {
            return (
                <ReactAudioPlayer
                    src={props.currentMessage.audio}
                    controls
                />
            );
        } else {
        return (
            <Bubble
                {...props}
                wrapperStyle={{
                    left: {
                        backgroundColor: '#f0f0f0',
                    },
                }}
            />

           );
        }
    };

    handleText = (e) => {
        this.setState({ newMessageText: e.target.value });
    };

    checkSpace = () => {
        const {newMessageText} = this.state;
        let res = false;
        if(!newMessageText.replace(/\s/g,'').length){
            res = true;
        }
        return res;
    }

    handleCopy = async function (message) {
        const { copyMessages, messages } = this.state;
        let res = false;
        let arr = [];
        let index = 0;
        let str = [];

        copyMessages.forEach(item => {
            arr.push(item);
        });

        for (let i = 0; i < arr.length; i++) {
            let msg = arr[i];
            if (msg.id === message.id) {
                res = true;
                index = i;
                break;
            }
        }

        if (res) {
            arr.splice(index, 1);
        } else {
            arr.push(message);
        }

        await this.setState({ copyMessages: arr });

    }

    renderToolbar = () => {
        if (this.state.copyMessages.length === 0) {
            return (
                <div className="toolbar-wrap">
                    <TextArea
                        rows={2}
                        placeholder="Type a Message here..."
                        value={this.state.newMessageText}
                        onChange={this.handleText}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                this.onSendMessage();
                            }
                        }}
                    />
                    <span onClick={() => this.setModal3Visible(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18.002" viewBox="0 0 24 18.002">
                            <defs></defs>
                            <path
                                className="a"
                                d="M22.454,18H1.547A1.557,1.557,0,0,1,0,16.438V5.086A1.556,1.556,0,0,1,1.547,3.524h3.68a.868.868,0,0,0,.716-.379L7.654.618A1.779,1.779,0,0,1,8.9,0h6.2a1.774,1.774,0,0,1,1.245.618l1.7,2.512a.9.9,0,0,0,.738.393h3.665A1.556,1.556,0,0,1,24,5.086V16.438A1.557,1.557,0,0,1,22.454,18ZM12,5.088a5.479,5.479,0,1,0,5.419,5.478A5.455,5.455,0,0,0,12,5.088ZM18.582,4.7a.783.783,0,1,0,.774.783A.78.78,0,0,0,18.582,4.7ZM12,14.871a4.3,4.3,0,1,1,4.258-4.3A4.286,4.286,0,0,1,12,14.871ZM4.645,2.348h-3.1V1.566A.78.78,0,0,1,2.322.783h1.55a.779.779,0,0,1,.773.783v.782Z"
                                transform="translate(0 0)"
                            />
                        </svg>
                    </span>
                    {this.state.newMessageText !== '' && this.checkSpace() === false ? (
                        <Button type="primary" onClick={this.onSendMessage} className="fp-button-primary send-btn">
                            Send
                        </Button>
                    ) : null}
                </div>
            );
        } else {
            return (
                <span className="topic-copy-btn">
                    <Button
                        type="primary"
                        onClick={() => this.setModal5Visible(true)}
                        className="fp-button-primary send-btn"
                    >
                        Copy To Topic
                </Button>
                </span>
            );
        }
    };

    handleAudio = async (message) => {
        if (this.state.copyMessages.length === 0) {
            let arr = [];
            arr.push(message);
            await this.setState({ copyMessages: arr });
        }
    }

    verifyChatMessage = (message) => {
        const { copyMessages } = this.state;
        let res = false;

        copyMessages.forEach(item => {
            if (item.id === message.id) {
                res = true;
                return res;
            }
        })

        return res;
    }

    renderMessage = (props) => {
        if (props.currentMessage.audio) {
            return (
                <div className="select-action-chat">
                    {
                        this.state.copyMessages.length > 0
                            ? <Checkbox
                                onChange={() => this.handleCopy(props.currentMessage)}
                                checked={this.verifyChatMessage(props.currentMessage)}
                            >
                            </Checkbox>
                        : null
                    }
                    
                    {this.state.copyMessages.length === 0 && (  
                        <div
                            onClick={() => this.handleAudio(props.currentMessage)}
                            className="audio-box"
                        >
                        </div>        
                    )}
                     {this.state.copyMessages.length === 0 && (  
                        <div
                            onClick={() => this.handleAudio(props.currentMessage)}
                            className="audio-box2"
                        >
                        </div>        
                    )}
                    <ReactAudioPlayer
                        className="audio-player"
                        src={props.currentMessage.audio}
                        controls
                    />

                </div>
            );
        } else {
            return (
                <div className="select-action-chat">
                    {
                        this.state.copyMessages.length > 0
                            ? <Checkbox
                                onChange={() => this.handleCopy(props.currentMessage)}
                                checked={this.verifyChatMessage(props.currentMessage)}
                            >
                            </Checkbox>
                        : null
                    }
                    <Bubble
                        {...props}
                        wrapperStyle={{
                            left: {
                                backgroundColor: '#f0f0f0',
                            },
                        }}
                    />
                </div>
            );
        }
    };

    onSendMessage = async () => {
        const { newMessageText: text, user } = this.state;

        if (!!text) {
            if (!text.replace(/\s/g, '').length === false) {
                let _user = { ...user };
                let sent = false;

                let messageid = uuid();
                const message = {
                    id: messageid,
                    text: text,
                    createdAt: moment().toDate(),
                    user: _user,
                    sent: false,
                    received: false,
                    selected: false,
                };

                if (!sent) {
                    await this.setState((previousState) => ({
                        messages: GiftedChat.append(previousState.messages, message),
                        newMessageText: '',
                    }));

                    let response = await SendToGroup(this.state.dialogId, text);
                    if (response) {
                        this.UpdateChat(messageid);
                    }
                }
            }
        }
    };

    UpdateChat = async (messageid) => {
        let updateMessage = await this.state.messages.map((data) => {
            if (data.id == messageid) {
                data.sent = true;
            }
            return data;
        });

        await this.setState(() => ({
            messages: [],
        }));
        await this.setState((previousState) => ({
            messages: GiftedChat.append(previousState.messages, updateMessage),
        }));
    };

    LoadEarlier = () => {
        this.CallChatHistory();
    };

    onSend = function (messages = []) {
        this.setState((previousState) => ({
            messages: GiftedChat.append(previousState.messages, messages),
        }));
    };

    setModal1Visible = (visible) => {
        this.setState({ modal1Visible: visible });
    };

    refreshChat = () => {
        this.props.archiveMsg();
    };

    goBack = () => {
        this.setModalVisible(false);
        this.props.displayActive();
    }

    onLongPress = async function (context, message) {
        if (this.state.copyMessages.length === 0) {
            let arr = [];
            arr.push(message);
            await this.setState({ copyMessages: arr });
        }
    }

    changePage = function(qb_dialogue_id) {
        this.props.show(qb_dialogue_id);
    }

    render() {
        const {
            messages,
            loading,
            fpDialogID,
            user,
            users,
            topicTitle,
            dialogId,
            copyMessages
        } = this.state;
        

        return (
            <LoadingOverlay
                active={loading ? true : false}
                className={'loader-wrap chat-loader-overlay ' + (loading ? 'd-block' : '')}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '100px',
                        '& svg circle': {
                            stroke: 'rgb(56, 160, 235)',
                        },
                    }),
                }}
            >
                <div className="chat-screen-wrapper">
                    <div className="chat-screen-head">
                        <span>{topicTitle}</span>
                        <span className="icon-wrap archived">
                            <div
                                onClick={() => this.setModal2Visible(true)}
                                style={{ display: 'inline-block', marginRight: '20px' }}
                            >
                                {topicTitle !== '' && topicTitle !== 'Chat Group' ? <EditIcon /> : null}
                            </div>
                            {dialogId ? (
                                <div onClick={() => this.setModal1Visible(true)} style={{ display: 'inline-block' }}>
                                    <ArchiveIcon />
                                </div>
                            ) : null}
                        </span>
                    </div>
                    <div className="chat-wrap">
                        {dialogId ? (
                            <GiftedChat
                                className="custom-chat"
                                messages={this.state.messages}
                                //onSend={(messages) => this.onSend(messages)}
                                showUserAvatar={true}
                                showAvatarForEveryMessage={true}
                                loadEarlier={this.state.showloadEarlier}
                                alwaysShowSend={true}
                                onLongPress={this.onLongPress}
                                //send={true}
                                user={{ ...user }}
                                renderMessage={this.renderMessage}
                                renderInputToolbar={this.renderToolbar}
                                onLoadEarlier={() => this.LoadEarlier()}
                                renderUsernameOnMessage={true}
                            />
                        ) : null}
                    </div>
                    <NewTopicChat
                        handleOpen={this.state.modalVisible}
                        handleClose={() => this.setModalVisible(false)}
                        refresh={(dialogId) => this.refreshPage(dialogId)}
                        newChat={true}
                        showActive={() => this.goBack()}
                    />
                    <ArchiveModal
                        handleOpen={this.state.modal1Visible}
                        handleClose={() => this.setModal1Visible(false)}
                        dialogId={this.state.dialogId}
                        fpDialogID={this.state.fpDialogID}
                        userId={user && user.user_id}
                        refresh={() => this.refreshChat()}
                    />
                    <UpdateTopicModal
                        openModal={this.state.modal2Visible}
                        closeModal={() => this.setModal2Visible(false)}
                        qb_dialogue_id={dialogId}
                        users={users}
                        type={1}
                        id={fpDialogID}
                        name={topicTitle}
                    />
                    <Modal
                        title="Select file"
                        centered
                        className="chat-modal"
                        visible={this.state.modal3Visible}
                        onOk={() => this.setModal3Visible(false)}
                        onCancel={() => this.setModal3Visible(false)}
                    >
                        <div className="new-topic-wrap">
                            
                            <div className="upload-wrapper">
                                <input
                                    id="img"
                                    type="file"
                                    multiple={true}
                                    encType="multipart/form-data"
                                    onChange={this.handleUpload}
                                />
                                <Button className="fp-button-secondary archive-btn">Open Document</Button>
                            </div>
                        </div>
                    </Modal>
                    <SwapErrorModal
                        openModal={this.state.modal4Visible}
                        closeModal={() => this.setModal4Visible(false)}
                        msg={'Please select an image file.'}
                    />
                    <SelectTopicModal
                        copyMessages={copyMessages}
                        messages={messages}
                        changePage={(qb_dialogue_id) => this.changePage(qb_dialogue_id)}
                        handleOpen={this.state.modal5Visible}
                        handleClose={() => this.setModal5Visible(false)}
                    />
                </div>
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = (response) => ({ response });
export default connect(mapStateToProps)(NewTopic);
