import { makeRequest } from './authenticationService';

export const getPaymentRequests = (sParams) => {
    
    const sortParams = sParams || {};
    const orderBy = sortParams.orderBy || '-updated_at';
    const offset = sortParams.offset || 0;
    const limit = sortParams.limit || 10;
    const status = sortParams.status;
    const coparent_id = sortParams.coparent_id || null;
    let url = `/api/payment/recentRequests/fetch?orderby=${orderBy}&offset=${offset}&limit=${limit}&status=${status}&coparent_id=${coparent_id}`;

    if (sParams.thirdParty) {
        url += '&thirdParty=true';
    }

    if (sParams.isBundle) {
        url += '&isBundle=true';
    }

    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const getPaymentTransactions = (sParams) => {

    const sortParams = sParams || {};
    const orderBy = sortParams.orderBy || '-updated_at';
    const offset = sortParams.offset || 0;
    const limit = sortParams.limit || 10;
    const coparent_id = sortParams.coparent_id || null;
    let url = `/api/payment/recentTransactions/fetch?orderby=${orderBy}&offset=${offset}&limit=${limit}&coparent_id=${coparent_id}`;

    if (sParams.thirdParty) {
        url += '&thirdParty=true';
    }
    
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const getPaymentCategories = (sParams) => {
    
    const sortParams = sParams || {};
    const orderBy = sortParams.orderBy || '-updated_at';
    const coparent_id = sortParams.coparent_id || null;
    
    let url = `/api/payment/category/getCategories?orderby=${orderBy}&coparent_id=${coparent_id}`;

    if (sParams.third_party) {
        url += '&thirdParty=true';
    }

    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const getRecurringPayments = (sParams) => {
    
    const sortParams = sParams || {};
    const orderBy = sortParams.orderBy || '-updated_at';
    const offset = sortParams.offset || 0;
    const limit = sortParams.limit || 10;
    const coparent_id = sortParams.coparent_id || null;
    let url = `/api/payment/recurring/getRecurringPayments?orderby=${orderBy}&offset=${offset}&limit=${limit}&coparent_id=${coparent_id}`;

    if (sParams.thirdParty) {
        url += '&thirdParty=true'    
    }

    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const getPaymentById = (id) => {

    let url = '/api/payment/' + id;

    return makeRequest('GET', url)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
}


export const updatePayment = (id, options) => {
    
    let url = '/api/payment/manual/' + id;
    
    
    return makeRequest('PUT', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const deletePayment = (id) => {
    
    let url = '/api/payment/delete/' + id;
    
    
    return makeRequest('DELETE', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const getTransactionStatus = (data) => {

    let url = '/api/jackHenry/getStatus';

    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
};

export const getUserCustomerDetails = () => {
    
    let url = '/api/jackHenry/getUserInfo';
    
    
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const getCoparentCustomerDetails = () => {
    
    let url = '/api/jackHenry/getCoParentInfo';
    
    
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const makeDebitPayment = (options) => {
    
    let url = '/api/jackHenry/debit';
    
    
    return makeRequest('POST', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}


export const cancelTransaction = (options) => {
    
    let url = '/api/jackHenry/cancelTransaction';
    
    
    return makeRequest('POST', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const setupPayment = (options) => {
    
    let url = '/api/payment/manual';
    
    
    return makeRequest('POST', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const setupRecurringPayment = (options) => {
    
    let url = '/api/payment/recurring';
    
    
    return makeRequest('POST', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const updateRecurringPayment = (id, options) => {
    
    let url = '/api/payment/recurring/' + id;
    
    
    return makeRequest('PUT', url, options)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const updateBundlePaymentStatus = (data) => {
    
    let url = '/api/payment/bundlePayment/';
    
    
    return makeRequest('PUT', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const makeDebitBundlePayment = (data) => {
    
    let url = '/api/jackHenry/bundlePayment/debit';
    
    
    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}

export const checkCustomer = (callback) => {

    let url = '/api/jackHenry/getCustomerInfo';
    
    
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
};

export const verifyUser = (data) => {
  return makeRequest("POST", "/api/identity/verify", data);
};

export const createJHCustomer = (data) => {
  return setupData("/api/jackHenry/customer", data);
};

export const createJHAccount = (data) => {
  return setupData("/api/jackHenry/account", data);
};

export const updateJHAccount = (data) => {
  return setupData("/api/jackHenry/updateAccount", data);
};

export const getCoParentsCustomerDetails = () => {

    let url = '/api/jackHenry/getCoParentInfo';
    return makeRequest('GET', url)
        .then((resp) => {
            return resp.all_data;
        })
        .then((json) => {
            return json;
        });
};

export const updateJHCustomer = (data) => {
  return setupData("/api/jackHenry/updateCustomer", data);
};

export const updateAccountStatus = (data) => {
  return setupData("/api/jackHenry/updateAccountStatus", data);
};

function setupData(url, dataObj) {
  if (dataObj.id) {
    return makeRequest("PUT", url + "/" + dataObj.id, dataObj);
  } else {
    return makeRequest("POST", url, dataObj);
  }
};

export const updatePaymentStatus = (data) => {

    let url = '/api/payment/manual/update';

    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const modifyRecurring = (data) => {

    let url = '/api/payment/recurring/update';

    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const deleteRecurringPayment = (id) => {
    
    let url = '/api/payment/recurring/delete/' + id;  
    
    return makeRequest('DELETE', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });

}