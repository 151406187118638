import React, { Component, useState } from 'react';
import 'pages/Reports/PaymentTransaction/PaymentTransaction.scss';
import CreateReport from 'pages/Reports/PaymentTransaction/CreateReport';
import { Card, Button, Tabs, Modal, Select, Empty } from 'antd';
import moment, { duration } from 'moment';
import { GetActivePaymentReport } from 'services/recurringReportsService';
import ScrollArea from 'react-scrollbar';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import SaveReport from 'pages/Reports/PaymentTransaction/SaveReport';

const { TabPane } = Tabs;
const { Option } = Select;

function callback(key) {
    
}
class PaymentTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createReportVisible: false,
            detailReportVisible: false,
            detailsData: null,
            refreshing: false,
            coParnetData: [],
            coParentId: null,
            validForm: false,
            selectedTab: props.selectedTab || 0,
            reportsList: [],
            limit: 10,
            offset: 0,
            archived: false,
            selectedDate: null,
            showMore: true,
            statusSel: [],
            activeTab: 1,
            LoadingIs: false,
            selectedTab: "1"
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.getActiveReports = this.getActiveReports.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.getChilrenName = this.getChilrenName.bind(this);
        this.handleAllClose = this.handleAllClose.bind(this);
    }
    setModalVisible = async function (visible) {
        await this.setState({ createReportVisible: visible });
    };
    componentDidMount() {
        this.startPage();
    }

    startPage() {
        let offset = 0;
        let archived = false;
        this.setState({ offset, archived, showMore: true }, () =>{
            this.getActiveReports(true);
        });
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        //  this.startPage();
    };

    setSaveModalVisible(val) {
        this.setState({ detailReportVisible: val });
    }

    handleAllClose = async function(modal) {
        await this.setState({ [modal]: false, detailsData: [], selectedTab: "1" });
        this.startPage();
    }

    getCategories = function(data) {
        let pymentData = data.paymentData;
        let namesList = Object.keys(pymentData).map((row) => {
            return pymentData[row].name;
        });

        let categoriesName = namesList.map((item) => item).join(',');
        return categoriesName;
    }

    getChilrenName = function(data) {
        let pymentData = data.paymentData;
        let list = [];
        let paymentListkeys = Object.keys(pymentData);
        for (const keys of paymentListkeys) {
            let paymentData = pymentData[keys].payments;
            for (const pData of paymentData) {
                list = list.concat(pData.childrens);
            }
        }

        let child = list
            .map((row) => row.name)
            .filter(function (value, index, array) {
                return array.indexOf(value) == index;
            });

        return child.map((item) => item).join(',');
    }

    getActiveReports = async () => {
        const { showMore, offset, limit, archived } = this.state;

        // let activeTab = await this.state.activeTab;
        // let archived = activeTab == 1 ? false : true;
        if (showMore) {
            this.setState({ LoadingIs: true });
            GetActivePaymentReport(archived, offset, limit, (data) => {
                this.setState({ LoadingIs: false });
            
                if (data && data.data.length > 0) {
                    let data1 = this.state.reportsList;
                    let newData = data.data;

                    if (offset == 0) {
                        // data1 = [...data1, ...newData];
                        this.setState({ reportsList: newData });
                    } else {
                        data1 = [...data1, ...newData];
                        this.setState({ reportsList: data1 });
                    }

                    if (newData.length < limit) {
                        this.setState({ showMore: false });
                    } else {
                        let newoffset = offset + limit;
                        this.setState({ showMore: true, offset: newoffset });
                    }
                } else {
                    this.setState({ showMore: false });
                    if (offset == 0) {
                       this.setState({ reportsList: [] }); 
                    }
                }
            });
        }
    };

    handleScroll = function (e) {
        const { archived, offset } = this.state;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this.getActiveReports();
        }
    };

    formartData(date) {
        var newdate = moment(date, 'DD-MM-YYYY');
        return moment(newdate).format('MM/DD/YY');
    }

    changeTab = async (activeKey) => {
        let archived;
        if (activeKey === '1') {
            archived = false;
        } else if (activeKey === '2') {
            archived = true;
        }

        this.setState(
            {
                reportsList: [],
                offset: 0,
                showMore: true,
                archived,
                selectedTab: activeKey
            },
            () => this.getActiveReports(true),
        );
    };
    
    async showDetails(data) {
        let info = {
            data: data.id,
            type: this.state.archived ? 'archive' : 'active',
        };
        await this.setState({ detailsData: info });
        this.setState({ detailReportVisible: true });
    }

    render() {
        const { reportsList, LoadingIs, archived, selectedTab } = this.state;
        return (
            <div className="report-page-wrap">
                <LoadingOverlay
                    active={LoadingIs}
                    className={LoadingIs ? 'd-block pop-loader' : 'd-none pop-loader'}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Card bordered={false} className="p-t-card">
                    <div className="card-head">
                        <span>Payments Incoming/Outgoing</span>
                        <Button className="fp-button-secondary add-btn" onClick={() => this.setModalVisible(true)}>
                            +
                        </Button>
                    </div>
                    <div className="card-body">
                        <Tabs activeKey={selectedTab} onChange={this.changeTab}>
                            <TabPane tab="Active Reports" key="1">
                                <div className="tab-content-wrap" onScroll={this.handleScroll}>
                                    {reportsList && Array.isArray(reportsList) && reportsList.length !== 0 ? (
                                        reportsList.map((report, i) => {
                                            let categoriesName = this.getCategories(report);
                                            let childrens = this.getChilrenName(report);
                                            return (
                                                <Card
                                                    bordered={false}
                                                    className="card-inner"
                                                    key={i}
                                                    onClick={() => this.showDetails(report)}
                                                >
                                                    <div className="card-top">
                                                        <span>{report.user ? report.user.coparentName : ''}</span>
                                                        <span>
                                                            {'' +
                                                                this.formartData(report.startDate) +
                                                                ' - ' +
                                                                this.formartData(report.endDate)}
                                                        </span>
                                                    </div>
                                                    <div className="card-bottom">
                                                        <span>{childrens}</span>
                                                        <span>{categoriesName}</span>
                                                    </div>
                                                </Card>
                                            );
                                        })
                                    ) : (
                                        <div>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                    )}
                                </div>
                            </TabPane>

                            <TabPane tab="Archived Reports" key="2">
                                <div className="tab-content-wrap" onScroll={this.handleScroll}>
                                    {reportsList && Array.isArray(reportsList) && reportsList.length !== 0 ? (
                                        reportsList.map((report, i) => {
                                            let categoriesName = this.getCategories(report);
                                            let childrens = this.getChilrenName(report);
                                            return (
                                                <Card
                                                    bordered={false}
                                                    className="card-inner"
                                                    key={i}
                                                    onClick={() => this.showDetails(report)}
                                                >
                                                    <div className="card-top">
                                                        <span>{report.user ? report.user.coparentName : ''}</span>
                                                        <span>
                                                            {'' +
                                                                this.formartData(report.startDate) +
                                                                ' - ' +
                                                                this.formartData(report.endDate)}
                                                        </span>
                                                    </div>
                                                    <div className="card-bottom">
                                                        <span>{childrens}</span>
                                                        <span>{categoriesName}</span>
                                                    </div>
                                                </Card>
                                            );
                                        })
                                    ) : (
                                        <div>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>

                        {/*  <Tabs defaultActiveKey="1" onChange={this.changeTab}>
                        <TabPane tab="Active Reports" key="1">
                            <div className="tab-content-wrap">
                                <Card bordered={false} className="card-inner">
                                    <div className="card-top">
                                        <span>Sarah Joseph</span>
                                        <span>1/12/19 - 3/12/19</span>
                                    </div>
                                    <div className="card-bottom">
                                        <span>Jack, Jill</span>
                                        <span>Child Support</span>
                                    </div>
                                </Card>
                                <Card bordered={false} className="card-inner">
                                    <div className="card-top">
                                        <span>Martha Clarke</span>
                                        <span>1/12/19 - 3/12/19</span>
                                    </div>
                                    <div className="card-bottom">
                                        <span>Jack, Jill, Sophie</span>
                                        <span>Child Support</span>
                                    </div>
                                </Card>
                            </div>
                        </TabPane>
                        <TabPane tab="Archived Reports" key="2">
                            <div className="tab-content-wrap">
                                <Card bordered={false} className="card-inner">
                                    <div className="card-top">
                                        <span>Sarah Joseph</span>
                                        <span>1/12/19 - 3/12/19</span>
                                    </div>
                                    <div className="card-bottom">
                                        <span>Jack, Jill</span>
                                        <span>Child Support</span>
                                    </div>
                                </Card>
                                <Card bordered={false} className="card-inner">
                                    <div className="card-top">
                                        <span>Martha Clarke</span>
                                        <span>1/12/19 - 3/12/19</span>
                                    </div>
                                    <div className="card-bottom">
                                        <span>Jack, Jill, Sophie</span>
                                        <span>Child Support</span>
                                    </div>
                                </Card>
                            </div>
                        </TabPane>
                    </Tabs>*/}
                    </div>
                    <CreateReport
                        handleOpen={this.state.createReportVisible}
                        handleClose={() => this.setModalVisible(false)}
                        handleRefreshClose={() => this.handleAllClose('createReportVisible')}
                    />

                    <SaveReport
                        handleOpen={this.state.detailReportVisible}
                        handleClose={() => this.setSaveModalVisible(false)}
                        handleRefreshClose={() => this.handleAllClose('detailReportVisible')}
                        responseInfo={this.state.detailsData}
                    />
                </Card>
            </div>
        );
    }
}

export default PaymentTransaction;
