export const REGISTER_USER             = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS     = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR       = 'REGISTER_USER_ERROR';

export const LOGIN_USER                = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS        = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR          = 'LOGIN_USER_ERROR';

export const GET_USER                  = 'GET_USER';
export const GET_USER_SUCCESS          = 'GET_USER_SUCCESS';
export const GET_USER_ERROR            = 'GET_USER_ERROR';

export const GET_CHECK_LIST            = 'GET_CHECK_LIST';
export const GET_CHECK_LIST_SUCCESS = 'GET_CHECK_LIST_SUCCESS';

export const ADD_LOADING               = 'ADD_LOADING';
export const ADD_LOADING_SUCCESS       = 'ADD_LOADING_SUCCESS';
export const REMOVE_LOADING            = 'REMOVE_LOADING';
export const REMOVE_LOADING_SUCCESS    = 'REMOVE_LOADING_SUCCESS';

export const GET_COPARENTS             = 'GET_COPARENTS';
export const GET_COPARENTS_SUCCESS     = 'GET_COPARENTS_SUCCESS';

export const GET_CHILDREN = 'GET_CHILDREN';
export const GET_CHILDREN_SUCCESS = 'GET_CHILDREN_SUCCESS';

export const GET_COPARENT = 'GET_COPARENT';
export const GET_COPARENT_SUCCESS = 'GET_COPARENT_SUCCESS';

export const GET_HOUSEHOLD             = 'GET_HOUSEHOLD';
export const GET_HOUSEHOLD_SUCCESS     = 'GET_HOUSEHOLD_SUCCESS';
export const GET_HOUSEHOLD_ERROR       = 'GET_HOUSEHOLD_ERROR';

export const GET_THIRD_PARTY          = 'GET_THIRD_PARTY';
export const GET_THIRD_PARTY_SUCCESS  = 'GET_THIRD_PARTY_SUCCESS';
export const GET_THIRD_PARTY_ERROR    = 'GET_THIRD_PARTY_ERROR';

export const SAVE_HOUSEHOLD            = 'SAVE_HOUSEHOLD';
export const SAVE_HOUSEHOLD_SUCCESS    = 'SAVE_HOUSEHOLD_SUCCESS';
export const SAVE_HOUSEHOLD_ERROR      = 'SAVE_HOUSEHOLD_ERROR';

export const ADD_RELOADING             = 'ADD_RELOADING';
export const ADD_RELOADING_SUCCESS     = 'ADD_RELOADING_SUCCESS';
export const REMOVE_RELOADING          = 'REMOVE_RELOADING';
export const REMOVE_RELOADING_SUCCESS  = 'REMOVE_RELOADING_SUCCESS';

export const QB_CONNECTION_STATUS = 'QB_CONNECTION_STATUS';
export const NEW_QB_STATUS = 'NEW_QB_STATUS';

export const ADD_PERMISSION = 'ADD_PERMISSION';
export const ADD_PERMISSION_SUCCESS = 'ADD_PERMISSION_SUCCESS';
export const REMOVE_PERMISSION = 'REMOVE_PERMISSION';
export const REMOVE_PERMISSION_SUCCESS = 'REMOVE_PERMISSION_SUCCESS';

export const SET_SESSION_ERROR = 'SET_SESSION_ERROR';
export const SET_SESSION_STATUS = 'SET_SESSION_STATUS';

export const SET_LOGIN_STATUS_ERROR = 'SET_LOGIN_STATUS_ERROR';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';

export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';