import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import 'pages/Household/CoParents/CoParents.scss';
import { Card, Button, Modal, Form, Empty, Col, Checkbox, Select } from 'antd';
import ScrollArea from 'react-scrollbar';
import { connect } from 'react-redux';
import { getMeUserAction } from 'actions/userActions';
import { getHouseHoldAction,getThirdPartyAction } from 'actions/houseHoldAction.js';
import { 
    resendInvite,
    resendInvitation,
    getMembers
} from '../../../services/houseHoldService';
import { checkCookie } from 'utils/cookies';
import { Baseurl } from '../../../services/authenticationService';
import AddCoparent from './AddCoparent';
import ViewCoparent from './ViewCoparent';
import { retry } from 'redux-saga/effects';
import { getCoParentsUserAction, getChildrenUserAction } from 'actions/userActions';
import { acceptKid, declineKid } from '../../../services/coparentsService';
import { get } from 'react-scroll/modules/mixins/scroller';

let colors = {
    '#c910e8': 'Pink',
    '#2196f3': 'Blue',
    '#673ab7': 'Purple',
    '#ff0000': 'Red',
    '#4caf50': 'Green',
    '#ffc107': 'Orange',
    '#00bcd4': 'Cyan',
    '#795548': 'Brown',
    '#ffffff': 'White',
    '#999999': 'Grey',
    '#333333': 'Charcoal',
    '#000000': 'Black',
    gradient1: 'Gradient1',
    gradient2: 'Gradient2',
};


class CoParents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            modalVisible2: false,
            viewModal: false,
            statusIs: '',
            LoadingIs: true,
            addCoparent: false,
            editIs: false,
            viewIs: false,
            coparentList: [],
            data: '',
            viewData: '',
            clickId: 0,
            resMsg: '',
            showMsg: false,
            flag4: false,
            isThirdParty : localStorage.getItem("third_party"),
            mem_id : localStorage.getItem("member_id"),
            coparent_color: '',
            errorColor: false,
            membersList: []
        };
        //this.props.dispatch(getCoParentUserAction(null));
        this.isValidCoparent = this.isValidCoparent.bind(this);
    }

    componentDidMount(){
        if(checkCookie() !== null){
            let token = checkCookie();
            this.props.dispatch(getMeUserAction({token: token})); 
        }
        if(localStorage.getItem("third_party") && localStorage.getItem("member_id")){
         this.props.dispatch(getThirdPartyAction({ id : localStorage.getItem("member_id") }));
            this.fetchMembers();
        }
    }


    fetchMembers = () => {
        let data = {
            isThirdParty: true,
            coparent_id: localStorage.coparent_id
        };

        getMembers(data)
            .then(resp => {
                if (resp.success) {
                    this.setState({ membersList: resp.data.members });
                }
        })
    }

    setIndexState = (stateObj) => {
        this.setState(stateObj);
    };

    addCoparentsModal = (modalVisible) => {
        this.setState({
            modalVisible: modalVisible,
            addCoparent: true,
        });
    };

    handleEdit = (obj) => {
        this.setState({
            editIs: true,
            modalVisible: true,
            addCoparent: true,
            data: obj,
        });
    };

    handleView = (obj) => {
        this.setState({
            viewIs: true,
            viewModal: true,
            addCoparent: false,
            viewData: obj,
        });
    };

    setModalVisible = () => {
        this.setState({
            modalVisible: false,
        });
    };

    handleGetConfirmation = (status, modalVisible, clickId) => {
        this.setState({
            modalVisible2: modalVisible,
            statusIs: status,
            clickId: clickId,
        });
    };

    refreshPage = () => {
        this.setState({flag4: true, LoadingIs: true, coparent_color: '', errorColor: false});
        this.props.dispatch(getCoParentsUserAction());
    }
    
    handleCoParentStatus = (id, householdId, children) => {
        const { statusIs, coparent_color } = this.state;
        var access_token = '';
        if (checkCookie() !== null) {
            access_token = checkCookie();
        }

        if (statusIs === 'accept') {
            let status = 'accept';
            let url = '/api/households/'+householdId + `/` + status + `/` + id;
            let body = { children: children, color: coparent_color };

            this.setState({ LoadingIs: true });

            acceptKid(url, body).then((res) => {
                let message = res.msg? res.msg: 'Please retry!'
                if(res.colorError){
                    message = res.error;
                }else if(res){
                    this.setState({
                        modalVisible2: false,
                    });

                    this.props.dispatch(getMeUserAction({ token: access_token }));
                    this.props.dispatch(getHouseHoldAction());
                    this.props.dispatch(getChildrenUserAction());
                    //window.location.reload();
                    this.refreshPage();
                }
                this.setState({ LoadingIs: false, showMsg: true,resMsg: message});
            });
        } else if (statusIs === 'decline') {
            let url = `/api/coparents/${id}`;
            declineKid(url).then((res)=>{
                if(res){
                    this.props.dispatch(getMeUserAction({ token: access_token }));
                    this.props.dispatch(getChildrenUserAction());
                    this.setState({
                        modalVisible2: false,
                    });
                    //window.location.reload();
                    this.refreshPage(); 
                }
            });
        }
    };

    componentWillReceiveProps(props) {

        if(props && props.response && props.response.serviceReducer && props.response.serviceReducer.coparents){
            if(this.state.flag4){
                this.setState({flag4: false, LoadingIs: false}); 
            }
        }

        const houseHoldData = props.response.houseHoldReducer.houseHold;
        if (typeof houseHoldData === 'object') {
            this.setState({
                coparentList:
                props.response.serviceReducer.coparents && props.response.serviceReducer.coparents.length > 0 ? props.response.serviceReducer.coparents.filter(function(datalist) {
                    return datalist.name !=='Household';
                  }) : '',
            });
        }
        if (props) {
            setTimeout(() => {
                this.setState({
                    LoadingIs: false,
                });
            }, 1000);
        }
    }

    handleColor = (value) => {
        this.setState({ coparent_color: value, errorColor: value === '' });
    };

    handleValidation = (id, householdId, children) =>{
        const { coparent_color, statusIs } = this.state;
        if(statusIs === 'decline'){
          this.handleCoParentStatus(id, householdId, children);
        }else{
          if(!coparent_color){
            this.setState({errorColor : true})
          }else{
              this.handleCoParentStatus(id, householdId, children);
          }
        }
    }


    renderModal(id, householdId, children, invitation) {
        const { statusIs, coparent_color, errorColor } = this.state;
        
        const { sender_name, Members } = invitation;
        let kidName ='';
        if(Members && Members[0]){
            kidName = Members[0].name;
        }

        const colorArr = [
            { colorName: 'Pink', colorCodeIs: '#c910e8' },
            { colorName: 'Blue', colorCodeIs: '#2196f3' },
            { colorName: 'Green', colorCodeIs: '#4caf50' },
            { colorName: 'Red', colorCodeIs: '#ff0000' },
            { colorName: 'Purple', colorCodeIs: '#673ab7' },
            { colorName: 'Orange', colorCodeIs: '#ffc107' },
            { colorName: 'Cyan', colorCodeIs: '#00bcd4' },
            { colorName: 'Black', colorCodeIs: '#000000' },
            { colorName: 'Brown', colorCodeIs: '#795548' },
            { colorName: 'White', colorCodeIs: '#ffffff' },
            { colorName: 'Grey', colorCodeIs: '#999999' },
            { colorName: 'Charcoal', colorCodeIs: '#333333' },
            { colorName: 'Gradient 1', colorCodeIs: 'gradient1' },
            { colorName: 'Gradient 2', colorCodeIs: 'gradient2' },
        ];

        return (
            <React.Fragment>
                {this.state.modalVisible2 && !this.state.addCoparent && id === this.state.clickId && (
                    <Modal
                        centered
                        visible={this.state.modalVisible2}
                        backdrop="static"
                        maskClosable={false}
                        className="del-pop modal-container invite-coparent"
                        onCancel={() => this.setState({ modalVisible2: false,showMsg:false, 
                            coparent_color: '', errorColor: false })}
                    >
                        <div className="modal-head">
                            <span>{statusIs==='accept'?'Accept Kid': 'Decline Kid'}</span>
                        </div>
                        <Form>
                            <div className="check-text">{sender_name} has shared a child invite</div>
                            <div className="select-wrap input-full input-full-invite">
                                <Col span={12} className="col-wrap cust-selct">
                                    <div className={`box-slt-box pos-relative`}>
                                        <Checkbox
                                            style={{ marginTop: '5px' }}
                                            checked={true}
                                            name="chekbox"
                                            className="cust-cb"
                                        >
                                            {kidName}
                                        </Checkbox>
                                    </div>
                                </Col>
                                {statusIs==='accept' && (<Col span={12} className="col-wrap cust-selct ">
                                    <div className="box-slt-box pos-relative select-dot-kid-invite">
                                        <Select
                                            name={coparent_color}
                                            value={coparent_color}
                                            onChange={this.handleColor}
                                            className={`slt-2-box`}
                                        >
                                            <option value="">Dot Color</option>
                                            {colorArr.map((obj, index) => (
                                                <option
                                                    style={{
                                                        display: 'inline-block',
                                                        color: obj.colorCodeIs === coparent_color ? '#2dadcc' : '#333',
                                                    }}
                                                    selected={coparent_color === obj.colorCodeIs}
                                                    value={obj.colorCodeIs}
                                                    key={index}
                                                >
                                                    <span className={'dot ' + colors[obj.colorCodeIs]}></span>
                                                    {obj.colorName}
                                                </option>
                                            ))}
                                        </Select>
                                        {errorColor ? <div className="form-err-coparent">{'Color is required!'}</div> : null}
                                    </div>
                                </Col>)}
                            </div>
                            <div className="check-text">
                                {statusIs==='accept'?'Accept co-parent invite and add child to household': 'Decline co-parent invite so child will not be added'}
                            </div>
                            {/*<div className="modal-bottom">
                                <Button type="warning" className="ant-btn" onClick={() => this.setModalVisible()}>
                                    CANCEL
                                </Button>
                                <Button
                                    type="primary"
                                    className="btn-add"
                                    onClick={() => this.handleCoParentStatus(id, householdId, children)}
                                >
                                    OK
                                </Button>
                            </div>*/}
                            <div className="btn-scroll-wrap">
                                <Form.Item className="btn-reset">
                                    <Button
                                        onClick={() => this.handleValidation(id, householdId, children)}
                                        className={statusIs==='accept'? "fp-button-primary full-btn" : "full-btn red-btn"}
                                        type="primary"
                                    >
                                        {statusIs==='accept'? "Add to household" : "Do not add to household"}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </Modal>
                )}

            </React.Fragment>
        );
    }

     setModalVisible = function (visible) {
        this.setState({ modalVisible: false,showMsg:false });
    };

    resendInvitation = async (value) => {
        const { response } = this.props;
        const user = response.serviceReducer.user;
         let name = '';
         const {coparentList} = this.state;
         coparentList.forEach(cp => {
            if(cp.id == value.id) {
                name = cp.name;
            }
         });
   
        this.setState({ LoadingIs: true });
         let data = {
            sender_id: user.id,
            invitationsIds: value.invitationsIds,
            userName: user.first_name
         };

         resendInvitation(data).then((res) => {
            if (res) {
                this.setState({ LoadingIs: false, showMsg: true,resMsg: 'Invitation sent to ' + name + '!' });
                this.props.dispatch(getCoParentsUserAction());

            }
                 
        });
    }

    isValidCoparent = (cp) => {
        const { response } = this.props;
        const user = response.serviceReducer.user;
        const children = response.serviceReducer.children;
        let res = false;
        
        for(let i = 0;i < children.length; i++) {
            let child = children[i];
            if(user){
                if((cp.id === child.created_by_user && (user.id === child.coparent_user_id || user.id === child.coparent_standalone_id))
                || ((cp.id === child.coparent_user_id || cp.id === child.coparent_standalone_id) && user.id === child.created_by_user) 
                ){
                    res = true;
                }
            }
        }

        return res;
    }

    isCoparentLinked = (cp) => {
        const { response } = this.props;
        const children = response.serviceReducer.children;
        let res = false;

        for(let i = 0;i < children.length; i++){
            let child = children[i];
            if(cp.id === child.created_by_user || cp.id === child.coparent_user_id || cp.id === child.coparent_standalone_id){
                if(child.Coparents && child.Coparents.length > 0){
                    child.Coparents.forEach(item => {
                        if(item.is_accepted){
                            res = true;
                            return res;
                        }
                    })
                }
            }
        }
        
        return res;
    }

    checkLinked = (sender_id, receiver_id) => {
        const { response } = this.props;
        const coparents = response.serviceReducer.coparents;

        let linked = false;

        coparents.forEach(cp => {
            if(((cp.sender_id === sender_id && cp.receiver_id === receiver_id) || 
               (cp.receiver_id === sender_id && cp.sender_id === receiver_id))
               && this.isCoparentLinked(cp)
            ) {
                linked = true;
            }
        })

        return linked;
    }

    render() {
        const { response } = this.props;
        const { isThirdParty, membersList } = this.state;
        const user = response.serviceReducer.user;
        const coparents = response.serviceReducer.coparents;
        const coparent = response.serviceReducer.coparent;
        const children = response.serviceReducer.children;
        const houseHoldData = response.houseHoldReducer.houseHold;
        const temArray = [];
        const results = [];
        const ids = []

        
        let coparentTp ;
        if(isThirdParty == 'true') {
           coparentTp = response.houseHoldReducer && response.houseHoldReducer.thirdPartyData&& response.houseHoldReducer.thirdPartyData.coparent[0]
        }
        if(houseHoldData && houseHoldData.Coparents) {
            houseHoldData.Members.map((memdata) => {
                memdata.Users.map((copdata) => {
                    ids.push(copdata.id)  
                })
            })
            houseHoldData.Coparents.map((data) => {
                data.Users.map((userInfo) => {
                   ids.push(userInfo.id)
                })
           })
        }
         
        coparents.forEach(cp => {
            if(cp.is_registered === false){
                cp.isInvite = false;
                results.push(cp);
            } else {
                if(user && user.id === cp.sender_id){
                    cp.isInvite = false;
                    results.push(cp);
                } else if ( user && user.id === cp.receiver_id) {
                    let validCoparent = this.isValidCoparent(cp);
                    if(validCoparent){
                        cp.isInvite = false;
                        results.push(cp);
                    }
                }
            }
        });
        
        const invites = [];
        let filterCoparent = '';
        var flag = 0;
        if (typeof coparent === 'object' && coparent != null) {
            filterCoparent = coparent != null ? coparent.id : '';
        }

        if (user !== null && user.hasOwnProperty('invitations')) {
           const senders = user.senders;
            var objdata ={};
            user.invitations.map((invite) => {
                if (invite.id && !invite.is_accepted && invite.children && invite.children.length) {
                    if(senders && invite.sender_id){
                        objdata = senders.find(o => o.id === invite.sender_id)
                        if(objdata){
                            invite.sender_name= objdata.first_name;       
                        }
                        
                        if(invite.Members && invite.Members[0]){
                            if(invite.Members[0].coparent_user_id === null){
                                let present = invites.find(item => item.Members[0].id === invite.Members[0].id);
                                if(present){}
                                else {
                                   invites.push(invite);
                                }
                            } else if(invite.Members[0].coparent_user_id === user.id){
                                invites.push(invite);
                            }
                        }
                        
                    }
                    
                }
            });
        }

        
        invites.forEach(item => {
            item.isInvite = true;
            user.invitations.forEach(inv => {
                if(this.checkLinked(item.sender_id, item.receiver_id)){
                    item.showInvite = true;
                } else {
                    if(item.sender_id === inv.sender_id && item.receiver_id === inv.receiver_id && inv.email_text !== null) {
                        item.email_text = inv.email_text;
                        item.showInvite = true;
                    }
                }
            })
            results.push(item);
        })

        return (
            <Card bordered={false} className="household-card coparent-card req-card">
            {this.state.showMsg  && (
                    <Modal
                        centered
                        visible={this.state.showMsg}
                        backdrop="static"
                        maskClosable={false}
                        className="modal-container req-card"
                        onCancel={() => this.setModalVisible()}
                    >
                        <div className="modal-head">
                            <span>{this.state.resMsg}</span>
                        </div>
                    </Modal>
                )}
                {this.state.LoadingIs && (
                    <LoadingOverlay
                        active={true}
                        className="d-block cust-loader"
                        spinner
                        styles={{
                            spinner: (base) => ({
                                ...base,
                                width: '100px',
                                '& svg circle': {
                                    stroke: 'rgb(56, 160, 235)',
                                },
                            }),
                        }}
                    ></LoadingOverlay>
                )}

                <div className="head cust-head">
                    <span>Co-parents</span>
                    {isThirdParty == 'false' && (
                        <Button
                            type="primary"
                            className="add-item"
                            shape="circle"
                            size="small"
                            onClick={() => this.addCoparentsModal(true)}
                        >
                        +
                        </Button>
                    )}
                    
                    {/*---------------------Add Coparent ---------------------*/}

                    <AddCoparent {...this.state} {...this.props} handleFunc={this.setIndexState} refresh={() => this.refreshPage()} />

                    <ViewCoparent {...this.state} {...this.props} handleFunc={this.setIndexState} refresh={() => this.refreshPage()}/>

                    {/*---------------------Add Coparent ---------------------*/}
                </div>
                <div className="card-scroll-wrap" >

                    {isThirdParty == 'true' && (
                        Array.isArray(membersList) && membersList.map((item, i) => (
                            <Card key={i} bordered={false} className="coparent-inner">
                            <div className="wrap-name" onClick={() => this.handleView(item)}>
                                <div className="name small-font">
                                     <span>{item.name}</span>  
                                </div>
                            </div>

                            </Card>
                        ))
                    )}
                   

                    {isThirdParty == 'false' && typeof houseHoldData === 'object' && Array.isArray(results) && results.length !== 0 ? results.map((value, key) => (
                        (value.name !== 'Household' && value.isInvite === true  && value.showInvite === true ? (
                            value.Members && value.Members.length > 0 && (
                                <Card
                                    bordered={false}
                                    key={key}
                                    className="coparent-inner"
                                >
                                    {Array.isArray(value.Members) &&
                                        value.Members.map((obj, index) => (
                                            <React.Fragment key={index}>
                                              <div hidden>{(flag = value.id ? 1 : '')} </div>
                                              <div className="wrap-name">
                                                    <div className="name small-font">
                                                         <span>{value.sender_name}</span>  
                                                    </div>
                                                    <div className="name small-font w-break" >
                                                        <span>{obj.name}</span>
                                                    </div>
                                                </div>
                                                <div className="actions">
                                                    <Button
                                                        type="primary"
                                                        className="btn btn-accept"
                                                        onClick={() =>
                                                            this.handleGetConfirmation(
                                                                'accept',
                                                                true,
                                                                value.id,
                                                                houseHoldData.id,
                                                                value.children[0].id,
                                                            )
                                                        }
                                                    >
                                                        Accept
                                                    </Button>
                                                    {this.renderModal(
                                                        value.id,
                                                        houseHoldData.id,
                                                        value.children,
                                                        value
                                                    )}
                                                    <Button
                                                        type="primary"
                                                        className="btn btn-decline"
                                                        onClick={() =>
                                                            this.handleGetConfirmation(
                                                                'decline',
                                                                true,
                                                                value.id,
                                                                houseHoldData.id,
                                                                value.children,
                                                            )
                                                        }
                                                    >
                                                        Decline
                                                    </Button>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                </Card>
                            )
                              
                        ) : (
                            value.name !== 'Household' && value.isInvite === false && (
                            
                              <span key={key}>
                                    <div className="flx_blok header-h">
                                       <div onClick={() => this.handleView(value)} style={{cursor: 'pointer'}}> 
                                            <div className="relation">
                                                <div hidden>{(flag = value.id ? 1 : '')} </div>
                                                <div className = "inside-info">
                                                <span> {value.id ? value.name : ''}</span>
                                                    <span> {this.isCoparentLinked(value) ? ' (Linked)' : ''}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="name resend-inv">
                                        {!value.is_registered && (
                                            <span onClick={() => this.handleEdit(value)}>
                                                <svg
                                                    className="icon-_size"
                                                    version="1.1"
                                                    id="Capa_1"
                                                    viewBox="0 0 512.001 512.001"
                                                    style={{ enableBackground: 'new 0 0 512.001 512.001' }}
                                                    xmlSpace="preserve"
                                                >
                                                    <path
                                                        d="M496.063,62.299l-46.396-46.4c-21.199-21.199-55.689-21.198-76.888,0C352.82,35.86,47.964,340.739,27.591,361.113
                                                        c-2.17,2.17-3.624,5.054-4.142,7.875L0.251,494.268c-0.899,4.857,0.649,9.846,4.142,13.339c3.497,3.497,8.487,5.042,13.338,4.143
                                                        L143,488.549c2.895-0.54,5.741-2.008,7.875-4.143l345.188-345.214C517.311,117.944,517.314,83.55,496.063,62.299z M33.721,478.276
                                                        l14.033-75.784l61.746,61.75L33.721,478.276z M140.269,452.585L59.41,371.721L354.62,76.488l80.859,80.865L140.269,452.585z
                                                        M474.85,117.979l-18.159,18.161l-80.859-80.865l18.159-18.161c9.501-9.502,24.96-9.503,34.463,0l46.396,46.4
                                                        C484.375,93.039,484.375,108.453,474.85,117.979z"
                                                    />
                                                </svg>
                                            </span>
                                            )}
                                    
                                            {(!value.is_registered || value.notified === false) && (
                                            <Button type="primary"
                                                onClick={() =>this.resendInvitation(value)}
                                                className="fp-button-secondary btn-resend">
                                                    Resend Invite
                                            </Button>
                                            )}
                                            
                                        </div>
                                    </div>
                                </span>
                            
                            )
                        ))
                    )): ''}

                    {flag == 0 && isThirdParty == 'false' && (
                        <div>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                    )}

                </div>
            </Card>
        );
    }
}
const mapStateToProps = (response) => ({ response });
export default connect(mapStateToProps)(CoParents);
