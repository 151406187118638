import { makeRequest } from './authenticationService';

export const getMe = (request) => {

    let access_token = request.token.token;
    let url = '/api/users/me?access_token=' + access_token;
    
    return makeRequest('GET', url, false, true)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });   

    
}

export const getMeUser = (token) => {

    let access_token = token;
    let url = '/api/users/me?access_token=' + access_token;
    
    return makeRequest('GET', url, false, true)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });     
}

export const getSetupProgress = (request) => {

    let url = '/api/status/';
    
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });   
    
}

export const getUserById = (id) => {

    let url = '/api/users/' + id;
    
    return makeRequest('GET', url)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });   
    
}

export const setupThirdParty = (data) => {
    
    let url = '/api/users?isThirdParty=true';

    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
    
}


export const updateChecklistResp = (request) => {
    let url = '/api/status/';
    
    return makeRequest('GET', url)
        .then((resp) => {
            
             if( typeof resp === 'object'){
                const calendarEnabled =
                            !!resp["profile"] &&
                            !!resp["coParent"] &&
                            !!resp["children"];
                const paymentEnabled =
                            
                            !!resp["profile"] &&
                            !!resp["coParent"] &&
                            !!resp["children"];
                const chatEnabled =
                            
                            !!resp["profile"] &&
                            !!resp["coParent"] &&
                            !!resp["children"];
                const reportsEnabled =
                            
                            !!resp["profile"] &&
                            !!resp["coParent"] &&
                            !!resp["children"];
                const baseListEnabled = 
                            !!resp["profile"] &&
                            !!resp["coParent"] &&
                            !!resp["children"];
                localStorage.setItem('checkListResp', JSON.stringify(resp));
                localStorage.setItem('baseListEnabled', baseListEnabled);
                localStorage.setItem('calendarEnabled', JSON.parse(calendarEnabled));
                localStorage.setItem('paymentEnabled', paymentEnabled);
                localStorage.setItem('chatEnabled', chatEnabled);
                localStorage.setItem('reportsEnabled', reportsEnabled);
             }
            return resp;
        })
        .then((json) => {
            return json;
        });  
}

