import React, { Component } from 'react';
import 'pages/Household/Children/Children.scss';
import { Card, Button, Empty, Avatar } from 'antd';
import AddChildren from './AddChildren';
import ViewChildren from './ViewChildren';
import { getMeUserAction } from 'actions/userActions';
import { getThirdPartyAction } from 'actions/houseHoldAction';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { checkCookie } from 'utils/cookies';
import userAvatar from 'Assets/defaultAvatar.png';
import DeleteChildModal from 'pages/Household/Children/DeleteChildModal';

class Children extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members: [],
            loadingIs: true,
            modalVisible: false,
            modal1Visible: false,
            dataIs: '',
            editIs: false,
            deleteIs: false,
            isThirdParty : localStorage.getItem("third_party"),
            coparentId : localStorage.getItem("coparent_id"),
            viewChildrenVisible: false,
            thirdpartyChildren : ''
        };
        this.setIndexState = this.setIndexState.bind(this);
        this.setDeleteState = this.setDeleteState.bind(this);
    }

    setIndexState = async function(stateObj){
        const { modalVisible, editIs, deleteIs } = stateObj;
        await this.setState({ modalVisible });
        await this.setState({ editIs });
        await this.setState({ deleteIs });
    };

    setDeleteState = async function (stateObj) {
        const { modal1Visible, editIs, deleteIs } = stateObj;
        await this.setState({ modal1Visible });
        await this.setState({ editIs });
        await this.setState({ deleteIs });
    }


    setModalVisible = (modalVisible) => {
        this.setState({ modalVisible });
    }

    setModal1Visible = (visible) => {
        this.setState({ modal1Visible: visible });
    }

    componentDidMount = async function() {
      if(localStorage.getItem("third_party") && localStorage.getItem("member_id")){
         this.props.dispatch(getThirdPartyAction({ id : localStorage.getItem("member_id") }));
      }
    }

    componentWillReceiveProps(props) {
        // this.setState({
        //   loadingIs: true,
        // });
        if (props) {
            setTimeout(() => {
                this.setState({
                    loadingIs: false,
                });
            }, 1000);
        }
    }

    handleEditChildren = (value) => {
        this.setState({
            dataIs: value,
            modalVisible: true,
            editIs: true,
        });
    };

    handleDeleteChildren = (value) => {
        this.setState({
            dataIs: value,
            modal1Visible: true,
            deleteIs: true,
        });
    };

    handleViewChild = (value,data) => {
      this.setState({
        viewChildrenVisible: value,
      })
      if(data != null) {
        this.setState({
        thirdpartyChildren : data
      })
      }
    }

    closeModal = () => {
        this.setState({ modalVisible: false, editIs: false, deleteIs: false });
    }

    closeDelete = () => {
        this.setState({ modal1Visible: false, editIs: false, deleteIs: false });
    }

    render() {
        const { response } = this.props;
        const {isThirdParty,coparentId} = this.state;
        const houseHoldData = response.houseHoldReducer.houseHold;
        let tempcolor = [];
        const coparent = response.serviceReducer.coparent;
        const user = response.serviceReducer.user;
        let filterCoparent = '';
        let householdChildren = [];
        filterCoparent = coparent != null ? coparent.id : '';
        if (houseHoldData && houseHoldData.Members) {
            householdChildren = houseHoldData.Members.filter((item) => {
                return item.Coparents != '';
            });
        }
        let filterCop = null;
        if(filterCoparent && response.serviceReducer.coparents){
          filterCop = response.serviceReducer.coparents.filter((item) => {
                return (item.invitationsIds &&item.invitationsIds.includes(filterCoparent));
          });
        }

        let childTp = [];
        if(isThirdParty == 'true') {
           childTp = response.houseHoldReducer && response.houseHoldReducer.thirdPartyData&& response.houseHoldReducer.thirdPartyData.kid
        }

        return (
            <React.Fragment>
                <Card className="household-card req-card" bordered={false}>
                    {this.state.loadingIs && (
                        <LoadingOverlay
                            active={true}
                            className="d-block cust-loader"
                            spinner
                            styles={{
                                spinner: (base) => ({
                                    ...base,
                                    width: '100px',
                                    '& svg circle': {
                                        stroke: 'rgb(56, 160, 235)',
                                    },
                                }),
                            }}
                        ></LoadingOverlay>
                    )}
                    <React.Fragment>
                        <div className="head mt-15">
                            <span>Children</span>

                            <div className="child-content-wrap">
                              <div className="child-content">
                                {isThirdParty == 'true' && (
                                   Array.isArray(childTp) && childTp.map((c,index)=> {

                                      const creator = coparentId == c.created_by_user;
                                          const image_url = creator
                                              ? c.picture
                                                  ? c.picture.url
                                                      ? c.picture.url
                                                      : null
                                                  : null
                                              : c.coparent_picture
                                              ? c.coparent_picture.url
                                                  ? c.coparent_picture.url
                                                  : null
                                              : null;
                                              
                                            c.file_url = image_url;
                                       
                                      return (
                                        <div className="child-element">
                                            <div className="Letter">
                                            </div>{' '}
                                            ​​​​​​​
                                            <Avatar
                                                className="children-avatar"
                                                src={image_url ? image_url : userAvatar}
                                                alt="user-avatar"
                                                onClick={() => this.handleViewChild(true,c)}
                                            />
                                            <p>{c.name}</p>
                                        </div>
                                      )
                                  })                                        
                                )}
                              </div>
                            </div>

                              <ViewChildren 
                               viewVisible = {this.state.viewChildrenVisible}
                               data = {this.state.thirdpartyChildren}
                               hadleClose = {()=>this.handleViewChild()}
                              /> 

                          {isThirdParty != 'true' && (
                            <Button
                                type="primary"
                                className="add-item"
                                shape="circle"
                                size="small"
                                onClick={() => this.setModalVisible(true)}
                            >
                                +
                            </Button>
                          )}
                            {/*-------------------------add Children Modal ---------------------------*/}

                            <AddChildren
                                {...this.state}
                                {...this.props}
                                handleFunc={this.setIndexState}
                                handleClose={() => this.closeModal()}
                            />
                            {/*-------------------------add Children Modal ---------------------------*/}
                        </div>
              
                    {isThirdParty != 'true' && (
                      
                        <div className="child-content-wrap">
                            <div className="child-content">
                                {typeof houseHoldData === 'object' && houseHoldData && houseHoldData.Members && user
                                    ? houseHoldData.Members.map((value, key) => {
                                          const creator = user.id == value.created_by_user;
                                          const image_url = creator
                                              ? value.picture
                                                  ? value.picture.url
                                                      ? value.picture.url
                                                      : null
                                                  : null
                                              : value.coparent_picture
                                              ? value.coparent_picture.url
                                                  ? value.coparent_picture.url
                                                  : null
                                              : null;
                                              
                                            value.file_url = image_url;

                                          if (
                                                (value.created_by_user === houseHoldData.owner.id
                                                ||
                                                value.coparent_user_id === houseHoldData.owner.id)
                                                &&
                                                (value.created_by_user === filterCoparent ||
                                                    value.coparent_user_id === filterCoparent ||
                                                    (value.coparent_standalone_id !== null &&
                                                        (value.coparent_standalone_id === filterCoparent) ||
                                                        (filterCop && filterCop[0] && filterCop[0].invitationsIds.includes(value.coparent_standalone_id))
                                                    )
                                                )
                                                ||
                                                filterCoparent == ''
                                          ) {
                                              const color = (value.created_by_user === (user && user.id))?value.color: value.coparent_color;
                                              if (
                                                  value.type === 'child' &&
                                                  tempcolor.includes(color) === false &&
                                                  value.Coparents != ''
                                              ) {
                                                  tempcolor.push(color);
                                                  var colorcode = color === '#ffffff' ? '#000000' : color;
                                                  var grad_class =
                                                      color == 'gradient1'
                                                          ? 'g1'
                                                          : color == 'gradient2'
                                                          ? 'g2'
                                                          : '';

                                                  return (
                                                      <div className="child-element" key={key}>
                                                          <div className="Letter">
                                                              <span
                                                                  className="delete-icon"
                                                                  onClick={() => this.handleDeleteChildren(value)}
                                                              >
                                                                  <svg
                                                                      className="icon-_size del"
                                                                      viewBox="-40 0 427 427.00131"
                                                                  >
                                                                      <path d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                                      <path d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                                      <path d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0" />
                                                                      <path d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                                                  </svg>
                                                              </span>
                                                          </div>{' '}
                                                          ​​​​​​​
                                                          <Avatar
                                                              className="children-avatar"
                                                              src={image_url ? image_url : userAvatar}
                                                              alt="user-avatar"
                                                              onClick={() => this.handleEditChildren(value)}
                                                          />
                                                          <p>{value.name}</p>
                                                      </div>
                                                  );
                                              }
                                          }
                                      })
                                    : ''}
                                {householdChildren.length == 0 && (
                                    <div className="empty-data ">
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </div>
                                )}
                            </div>
                        </div>
                        )}

                    </React.Fragment>
                </Card>
                <DeleteChildModal
                    {...this.state}
                    {...this.props}
                    handleDelete={this.setDeleteState}
                    handleOpen={this.state.modal1Visible}
                    handleClose={() => this.closeDelete()}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (response) => ({ response });
export default connect(mapStateToProps)(Children);
