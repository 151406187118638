import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import configureStore from 'store/configureStore';

import Container from 'container/App';
import { addLocationQuery } from 'helpers/routeParams';
import { BrowserRouter } from 'react-router-dom';

const history = createBrowserHistory();

addLocationQuery(history);

history.listen((location) => {
    addLocationQuery(history);
});


export const store = configureStore();

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Container history={history} />
            </BrowserRouter>
        </Provider>
    );
}

export default App;
