import React, { Component } from 'react';
import 'pages/Landing/Landing.scss';
import { Row, Col } from 'antd';
import Calender from 'pages/Landing/Calender/Calender';
import ToDoList from 'pages/Landing/ToDoList/ToDoList';
import RecentRequest from 'pages/Landing/RecentRequest/RecentRequest';
import RecentTransactions from 'pages/Landing/RecentTransactions/RecentTransactions';
import SetupCheckList from 'pages/Landing/SetUpCheckList/SetupCheckList';
import {connect} from 'react-redux';
import Modal from 'Components/Modal/ConnectionModal';

import { QuickBlockConfig } from 'pages/QuickBlox/QBConfig';
import {
    getQBCredentials,
    updateQBUserData
} from 'services/chatService';
import history from 'utils/history';
import {
    CreateUser, 
    LoginUser,
    ConnectChatServer,
    IsConnected,
    GetSession,
    CreateSession,
    CreateAppSession,
    GetUser
} from 'pages/QuickBlox/qbutility';

import {
    updateQBStatus
} from 'actions/userActions';


class Landing extends Component {
    constructor(props){
        super(props);
        this.state = {
            modalVisible: true,
        };
        this.setModalVisible = this.setModalVisible.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    setModalVisible = async function(visible) {
        await this.setState({modalVisible: visible});
    }

    render(){
    const {error} = this.props;
    return (
        <div>
        {error ? <Modal handleOpen={this.state.modalVisible} maskClosable={false} handleClose={() => this.setModalVisible(false)} ></Modal> : null}
            {localStorage.third_party && localStorage.third_party === 'true' ? null : (
                <Row>
                    <Col span={24}>
                        <SetupCheckList err={error} className="checklist-wrap" />
                    </Col>
                </Row>
            )}
        <Row className="row-container" gutter={'10'}>
            <Col xs={24} lg={10} className="calender-wrap">
                <Calender err={error} />
            </Col>
            <Col xs={24} lg={7} className="todo-wrap">
                <RecentRequest err={error} />
            </Col>
            <Col xs={24} lg={7} className="col-wrap">
                <RecentTransactions err={error} />
            </Col>
        </Row>
        </div>
    );
    }
};

const mapStateToProps = ({serviceReducer}) => {
    const { error } = serviceReducer;
    return { error };
}
export default connect(mapStateToProps)(Landing);

