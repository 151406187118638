import React, { Component } from 'react';
import 'pages/Payment/Payments/Payments.scss';
import { Card, Button, Modal, Checkbox, Form, Input, Select, Empty, Tabs } from 'antd';
import ScrollArea from 'react-scrollbar';
import Icon from '@ant-design/icons';
import lock from 'Assets/lock.png';
import check from 'Assets/simple-check.png';

const { Option } = Select;
const { TextArea } = Input;

class SelectPaymentModal extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <Modal
            title="Please select proper payments"
            centered
            maskClosable={false}
            visible={this.props.handleOpen}
            className="payments-popup custom-popup ach-error-popup"
            onOk={this.props.handleClose}
            onCancel={this.props.handleClose}
            >
                <Button onClick={() => this.props.handleClose()} style={{width: '80%', marginLeft: '35px'}} type="primary"  className="btn fp-button-primary">
                    Ok
                </Button>
              
            </Modal>
        );
    }
}

export default SelectPaymentModal;
