import {
    makeRequest
} from './authenticationService';

export const sendOTPToEmail = (data) => {

    let url = '/api/users/sendotp';

    if (data.isThirdParty) {
        url += '?isThirdParty=true';
        delete data.isThirdParty;
    }
    
    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}

export const verifyOTP = (data) => {
    let url = '/api/users/verifycode';
    return makeRequest('POST', url, data)
        .then((resp) => {
            return resp;
        })
        .then((json) => {
            return json;
        });
}