import React, { Component } from 'react';
import 'pages/Signin/ForgotPassword.scss';
import cover from 'Assets/cover.png';
import logo from 'Assets/kincrew-logo.svg';
import arrowWhite from 'Assets/arrow-right.svg';
import { Form, Input, Button, Checkbox, Modal } from 'antd';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import history from 'utils/history';
import { ResetWebUrl, ResetAndroidUrl, ResetIosUrl, sendPasswordOnEmail } from '../../services/forgotPasswordService';
import ConnectionErrorModal from 'Components/Modal/ConnectionModal';

function onChange(e) {
    
}

class ForgotPassword extends Component {
    state = {
        email: '',
        resPonseMsg: '',
        modalVisible: false,
        LoadingIs: false,
        modal1Visible: false,
    };

    handleResponseModal = (modalVisible) => {
        this.setState({ modalVisible: modalVisible });
    };

    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        this.setState({ errorEmailMessage: re.test(String(email).toLowerCase()) ? '' : 'Invalid Email Address' });
    };

    onChangeEmailHandler = (event) => {
        this.setState({ email: event.target.value }, () => this.validateEmail(this.state.email));
    };

    submitEmailHandler = () => {
        if (this.state.email.length && !this.state.errorEmailMessage) {
            this.setState({ LoadingIs: true });
            sendPasswordOnEmail({ email: this.state.email })
                .then((res) => {
                    // localStorage.setItem("forgotPasswordToken", res.token)
                    this.setState({
                        resPonseMsg: res.msg
                            ? 'The reset password link has been sent on your email address. Please, check it and try to change your password within 1 hour'
                            : 'Email address not registered, please try again',
                        modalVisible: true,
                        LoadingIs: false,
                    });
                    setTimeout(() => {
                        this.setState({ resPonseMsg: '' });
                        // this.state.mobileIsDetect ? this.props.history.push("/forgotpassword") :  this.props.history.push(`${ResetWebUrl}`)
                    }, 3000);
                })
                .catch((err) => {
                    this.setModal1Visible(true);
                });
        } else if (this.state.email.length) {
        } else {
            this.setState({ errorEmailMessage: 'Email is Required' });
        }
    };

    setModal1Visible = (visible) => {
        this.setState({ modal1Visible: visible });
    };

    render() {
        const { modalVisible, resPonseMsg, LoadingIs } = this.state;

        return (
            <div className="signin-wrapper">
                {resPonseMsg && (
                    <Modal
                        centered
                        visible={modalVisible}
                        backdrop="static"
                        className="modal-container req-card alert-pop"
                        onCancel={() => this.handleResponseModal(false)}
                    >
                        <div className="modal-body">
                            <h5>{`${resPonseMsg}`}</h5>
                        </div>
                    </Modal>
                )}
                <Link to="/">
                    <div className="back-arrow">
                        <span>
                            <img src={arrowWhite} />
                        </span>
                    </div>
                </Link>
                <ConnectionErrorModal
                    handleOpen={this.state.modal1Visible}
                    handleClose={() => this.setModal1Visible(false)}
                />
                <div className="signin-cover"></div>
                <div className="signin-details">
                    <div className="details-inner">
                        <div className="details-head pswrd-head">
                            <div className="logo-wrap">
                                <img src={logo} alt="kincrew-logo" />
                                <span>kincrew</span>
                            </div>
                        </div>
                        <div className="data-wrap pwd-wrap">
                            <div className="sign-head">
                                <span>Forgot Password?</span>
                            </div>
                            <div className="signin-form-wrap pwd-form">
                                <Form.Item label="Email" labelCol={{ span: 24 }}>
                                    <Input
                                        placeholder="mom.parent@gmail.com"
                                        onChange={(e) => this.onChangeEmailHandler(e)}
                                    />
                                    {this.state.errorEmailMessage && (
                                        <div className="form-err forgotErrorMessage">
                                            {this.state.errorEmailMessage}
                                        </div>
                                    )}
                                </Form.Item>
                                <div className="btn-wrap">
                                    <Button type="primary" onClick={this.submitEmailHandler} className="btn-full">
                                        <span>Next</span>
                                        <span>
                                            <img src={arrowWhite} alt="arrow-right" />
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;
