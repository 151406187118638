import { makeRequest } from './authenticationService';

export const GetActiveSwapReport = (options) => {
    const offset = options.offset || 0;
    const limit = options.limit || 10;

    let url = `/api/reports/swap/list?archived=${options.archived}&offset=${offset}&limit=${limit}`;

    return makeRequest('GET', url)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
};


//Swap
export const CreateSwapReport = (data) => {

    let url = '/api/reports/swap';

    return makeRequest('POST', url, data)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
};
  

export const SaveSwapReport = (data) => {

    let url = '/api/reports/swap/save';

    return makeRequest('POST', url, data)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
};

  
// Custody
export const GetActiveReport = (options) => {
    const offset = options.offset || 0;
    const limit = options.limit || 10;
    let archived = options.archived;

    let url = `/api/reports/custody/list?archived=${archived}&offset=${offset}&limit=${limit}`;

    return makeRequest('GET', url)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
};

export const CreateCustodyReport = (data) => {

    let url = '/api/reports/custody';

    return makeRequest('POST', url, data)
    .then((resp) => {
        return resp;
    })
    .then((json) => {
        return json;
    });
  };

export const UpdateCustodyReport = (data) => {
  return makeRequest("PUT", `/api/reports/custody/update`, data);
};

export const SaveCustodyReport = (data) => {
  return makeRequest("POST", `/api/reports/custody/save`, data);
};

export const GetCustodyDetail = (custody_report_id, callback) => {
  let url = `/api/reports/custody/${custody_report_id}`;
  return makeRequest('GET', url)
        .then((resp) => {
           if (callback) {
            callback(resp);
           }
        },
       true
    )
};

export const GetSwapDetail = (custody_report_id, callback) => {
  let url = `/api/reports/swap/${custody_report_id}`;
  return makeRequest('GET', url)
        .then((resp) => {
           if (callback) {
            callback(resp);
           }
        },
       true
    )
};

export const UpdateSwapReport = (data) => {
  return makeRequest("PUT", `/api/reports/swap/update`, data);
};
