import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import 'pages/Payment/Register/Register.scss';
import { Card, Button, Modal, Select, Input, Checkbox, DatePicker } from 'antd';
import FloatLabel from 'Components/FloatLabel/FloatLabel';
import left from 'Assets/left-arrow.svg';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import moment, { duration } from 'moment';
import { US_states } from 'pages/Household/Household/StateData.js';
import { checkCustomer, getUserCustomerDetails, verifyUser, updateJHCustomer, updateJHAccount} from '../../../services/paymentService';

const validator = require('email-validator');
let addressArray = [];
let countries = [
    {
        addressFields: ['address', 'city', 'zip', 'state', 'licence'],
        label: '+1 United States',
        value: 'usa',
    },
    {
        addressFields: ['street', 'city', 'provinceOrTerritory', 'postalCode', 'licence'],
        label: '+1 Canada',
        value: 'canada',
    },
];
const selectAccountType = [
  { name: "Checking", id: "Checking", required: true },
];
class UpdateSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
        resMsg : '', 
        resMsgModal: false,
        coParentsCustomerInfo: [],
        LoadingIs: false,
        flag: false,
        sendingForm: false,
        isAcountChanged: true,
        UpdateSetupVisible : props.UpdateSetupVisible,
        updateStatus: false,
        //flag2: props.status,
        firstName: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.firstName
            ? this.props.userCustomerInfo.JHCustomer.firstName
            : ""
          : ""
        : "",
            lastName: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.lastName
            ? this.props.userCustomerInfo.JHCustomer.lastName
            : ""
          : ""
        : "",
            phoneNumber: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.phone
            ? this.props.userCustomerInfo.JHCustomer.phone
            : ""
          : ""
        : "",
            email: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.email
            ? this.props.userCustomerInfo.JHCustomer.email
            : ""
          : ""
        : "",
            dob: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.dob
            ? this.props.userCustomerInfo.JHCustomer.dob
            : ""
          : ""
        : "",
        address: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.address1
          : ""
        : "",
        city:  this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.city
          : ""
        : "",
        zip: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.zip
          : ""
        : "",
        state : this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.state
          : ""
        : "",
        country: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.country_code
            ? this.props.userCustomerInfo.JHCustomer.country_code.toLowerCase()
            : ""
          : ""
        : "",
        street : this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.address1
            ? this.props.userCustomerInfo.JHCustomer.address1
            : ""
          : ""
        : "",
            postalCode: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.zip
            ? this.props.userCustomerInfo.JHCustomer.zip
            : ""
          : ""
        : "",
            provinceOrTerritory: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.state
            ? this.props.userCustomerInfo.JHCustomer.state
            : ""
          : ""
        : "",
        licence: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.licence
            ? this.props.userCustomerInfo.JHCustomer.licence
            : ""
          : ""
        : "",

        postcode: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.zip
            ? this.props.userCustomerInfo.JHCustomer.zip
            : ""
          : ""
        : "",
        ssn: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer.ssn
            ? this.props.userCustomerInfo.JHCustomer.ssn
            : ""
          : ""
        : "",
        nameOnAccount: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.accountName
        : "",
        rtn: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.routingNumber
        : "",
        accountNo: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.accountNumber
        : "",
        accountName: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.accountName
        : "",
        acctype: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.account_type
        : "",
        customerDetails: this.props.userCustomerInfo
        ? this.props.userCustomerInfo.JHCustomer
          ? this.props.userCustomerInfo.JHCustomer
          : ""
        : "",
            errorIs: {
                name: '',
                firstName: '',
                lastName: '',
                email: '',
                dob: '',
                phoneNumber: '',
                licence: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                street: '',
                provinceOrTerritory: '',
                postalCode: '',
                ssn: '',
                nameOnAccount: '',
                rtn: '',
                accountNo: '',
                acctype: '',
            },
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleState = this.handleState.bind(this);
        this.getStateName = this.getStateName.bind(this);
        this.getCountryName = this.getCountryName.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        const { Option } = Select;
    }

    focusNode(field) {
        let node = ReactDOM.findDOMNode(this.refs[field]);
        node.scrollIntoView({block: 'start', behavior: 'smooth'});
       
    }
  
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        if(name == 'nameOnAccount' || name == 'accountNo' || name == 'rtn' || name == 'acctype' ) {
            this.setState({isAcountChanged: true})
        } 
        this.validateForm(name, value);
    }

    UNSAFE_componentWillReceiveProps = async function (nextProps) {
        if(nextProps.UpdateSetupVisible)
         this.setState({UpdateSetupVisible : nextProps.UpdateSetupVisible})
           
    };


    formatDate = (dateObj) => {
        let date = new Date(dateObj);
        let month = date.getMonth();
        month = month + 1;
        month = month.toString().length > 1 ? month : '0' + month;
        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        let year = date.getFullYear();
        let output = parseInt(year.toString()) + '-' + month + '-' + day;
        return output;
    };

    handleDOB = (e) => {
        if (e && e._d) {
            this.setState({ dob: this.formatDate(e._d) });
        }
    };

    getStateName = function (val) {
        let s = '';
        US_states.forEach((item) => {
            if (item.value === val) {
                s = item.label;
            }
        });
        return s;
    };

    handleState = async function (val) {
        await this.setState({state: val });
    };

    handleCountry = async function (value) {
        const { errorIs } = this.state;
        errorIs.address = '';
        errorIs.city = '';
        errorIs.state = '';
        errorIs.zip = '';
        errorIs.street = '';
        errorIs.provinceOrTerritory = '';
        errorIs.postalCode = '';
        await this.setState({ country_code: value, country: value});
    };

    getCountryName = function (val) {
        let c = '';
        const { errorIs } = this.state;

        addressArray = [];
        countries.forEach((item) => {
            if (item.value === val) {
                c = item.label;
            }
        });
        if (c == '') {
            c = '+1 United States';
        }
        let showAddressFields = [];
        countries.map((item) => {
            if (item.value === val) {
                showAddressFields = item.addressFields;
            }
        });

        addressArray.push(showAddressFields);
        return c;
    };
    handleClose () {
        this.setState({ UpdateSetupVisible :false} , () =>
            this.props.handleClose(false)
        );
       
    }

    handleMsgClose (val) {
       this.setState({ resMsg: '',resMsgModal: false , UpdateSetupVisible :false});
       this.props.handleClose(false);

    }


    validation() {
        const zipRegx = /^[0-9\b]+$/;
        const { errorIs, name, firstName, lastName, email, dob, phoneNumber, address,
                city, state, zip, postalCode, street, provinceOrTerritory, country_code,
                licence, ssn, nameOnAccount, rtn, accountNo, acctype } = this.state;
        const phoneRegx = /^[0-9\b]+$/;
        errorIs.firstName = !firstName.length
            ? 'Firstname is required!'
            : !(firstName.length > 1)
            ? 'First Name is Too short (Must be at Least 2 character)'
            : firstName.length > 50
            ? 'First Name is Too Long! (Max 50 symbols)'
            : '';
        errorIs.lastName = !lastName.length
            ? 'Lastname is required!'
            : !(lastName.length > 1)
            ? 'Last Name Is Too short (Must be at Least 2 characters)'
            : lastName.length > 50
            ? 'Last Name Is Too Long (Must be 50 characters'
            : '';
        errorIs.ssn = !ssn.length
            ? 'SSN  is required!'
            : !(ssn.length > 8)
            ? 'SSN Is Too short (Must be at Least 9 characters)'
            : ssn.length > 9
            ? 'SSN Name Is Too Long (Must be 9 characters)'
            : '';
        if(ssn.length > 0)
            var isnum = /^\d+$/.test(this.state.ssn);
        if (isnum) {
            errorIs.ssn = '';
        } else {
            errorIs.ssn =  "Only Number Allowed!";
        }
        errorIs.email = !email.length ? 'Email is required!' : !validator.validate(email) ? 'Invalid email' : '';
        errorIs.dob = !dob ? 'DOB is required!' : '';
        errorIs.phoneNumber = !phoneNumber.length
            ? 'Phone is required!'
            : !phoneRegx.test(phoneNumber)
            ? 'Only Number Characters Allowed!'
            : phoneNumber.length > 10 || phoneNumber.length < 10
            ? 'Phone number should be exactly 10 characters '
            : '';
        errorIs.licence =
            licence.length > 0 && !(licence.length > 4)
                ? 'License is Too short (Must be at Least 5 characters)'
                : licence.length > 0 && licence.length > 16
                ? 'License is Too Long! (Max 16 characters)'
                : '';
        errorIs.address =
            country_code == 'usa' && !address.length
                ? 'Address is required!'
                : country_code == 'usa' && address.length > 50
                ? 'Address is Too Long! (Max 50 symbols)'
                : '';

        if (country_code == 'canada') {
            errorIs.street = !street.length
                ? 'Sreet is required!'
                : !(street.length > 1)
                ? 'Street Is Too short (Must be at Least 2 characters)'
                : street.length > 50
                ? 'Street is Too Long (Must be 50 characters)'
                : '';
        }

        if (country_code == 'canada') {
            errorIs.provinceOrTerritory = !provinceOrTerritory.length
                ? 'Province/Territory is required!'
                : !(provinceOrTerritory.length > 1)
                ? 'Province/Territory Is Too short (Must be at Least 2 characters)'
                : provinceOrTerritory.length > 50
                ? 'Province/Territory is Too Long (Must be 50 characters)'
                : '';
        }

        errorIs.city = !city.length
            ? 'City is required!'
            : !(city.length > 1)
            ? 'City Is Too short (Must be at Least 2 characters)'
            : city.length > 25
            ? 'City is Too Long (Must be 25 characters)'
            : '';
        errorIs.state = country_code == 'usa' && !state.length ? 'State is required!' : '';
        if (country_code == 'usa') {
            errorIs.zip = !zip.length
                ? 'Zip is required!'
                : !zipRegx.test(zip)
                ? 'Only Number Characters Allowed!'
                : !(zip.length > 4)
                ? 'Zip is Too short (Must be 5 characters)'
                : !(zip.length === 5)
                ? 'Zip is Too Long (Must be 5 characters)'
                : '';
        }
        if (country_code == 'canada') {
            errorIs.postalCode =
                country_code == 'canada' && !postalCode.length
                    ? 'Postal Code is required!'
                    : !zipRegx.test(postalCode)
                    ? 'Only Number Characters Allowed!'
                    : !(postalCode.length > 1)
                    ? 'Postal Code is Too short (Must be at Least 2 characters)'
                    : '';
        }
         errorIs.nameOnAccount = !nameOnAccount.length
            ? 'Name is required!'
            : !(nameOnAccount.length > 1)
            ? 'Name is Too short (Must be at Least 2 character)'
            : nameOnAccount.length > 50
            ? 'Name is Too Long! (Max 50 symbols)'
            : '';
        errorIs.rtn = !rtn.length
            ? 'Routing Number is required!'
            : !(rtn.length > 8)
            ? 'Routing Number Is Too short (Must be at Least 9 characters)'
            : rtn.length > 9
            ? 'Routing Number Is Too Long (Must be 9 characters'
            : '';
        errorIs.accountNo = !accountNo.length
            ? 'Account Number is required!'
            : accountNo.length > 50
            ? 'Account Number Is Too Long (Must be 50 characters)'
            : '';
        if(accountNo.length > 0)
            var isnumAcc = /^\d+$/.test(this.state.accountNo);
        if (isnumAcc) {
            errorIs.accountNo = '';
        } else {
            errorIs.accountNo =  "Only Number Allowed!";
        }
        errorIs.acctype = !acctype.length || acctype == 'Account Type' ? 'Account Type is required!'  : '';

        this.setState({
            errorIs,
        });
  
        return (
            errorIs &&
            !errorIs.lastName &&
            !errorIs.firstName &&
            !errorIs.zip &&
            !errorIs.licence &&
            !errorIs.phone &&
            !errorIs.address &&
            !errorIs.street &&
            !errorIs.email &&
            !errorIs.ssn &&
            !errorIs.provinceOrTerritory &&
            !errorIs.postalCode &&
            !errorIs.dob &&
            !errorIs.licence &&
            !errorIs.city &&
            !errorIs.state &&
            !errorIs.nameOnAccount &&
            !errorIs.rtn &&
            !errorIs.accountNo &&
            !errorIs.acctype
        );
    }

    validateForm = (name, value) => {
        const { errorIs, country } = this.state;
        const phoneRegx = /^[0-9\b]+$/;
        const zipRegx = /^[0-9\b]+$/;
      
        switch (name) {
            case 'name':
                errorIs.name = !value.length
                    ? 'Name is required!'
                    : !(value.length > 1)
                    ? 'Household Name is Too short (Must be at Least 2 character)'
                    : value.length > 50
                    ? 'Household Name is Too Long! (Max 50 symbols)'
                    : '';
                break;
            case 'firstName':
                errorIs.firstName = !value.length
                    ? 'Firstname is required!'
                    : !(value.length > 1)
                    ? 'First Name is Too short (Must be at Least 2 character)'
                    : value.length > 50
                    ? 'First Name is Too Long! (Max 50 symbols)'
                    : '';
                break;
            case 'lastName':
                errorIs.lastName = !value.length
                    ? 'Lastname is required!'
                    : !(value.length > 1)
                    ? 'Last Name Is Too short (Must be at Least 2 characters)'
                    : value.length > 50
                    ? 'Last Name Is Too Long (Must be 50 characters)'
                    : '';
                break;
            case 'email':
                errorIs.email = !value.length
                    ? 'Email is required!'
                    : !validator.validate(value)
                    ? 'Invalid email'
                    : '';
                break;
            case 'ssn':
                errorIs.ssn = !value.length
                    ? 'SSN is required!'
                    : !(value.length > 8)
                    ? 'SSN Is Too short (Must be at Least 9 characters)'
                    : value.length > 9
                    ? 'SSN Is Too Long (Must be 9 characters)'
                    : '';
                break;
            case 'dob':
                errorIs.dob = !value.length ? 'DOB is required!' : '';
                break;
            case 'phoneNumber':
                errorIs.phoneNumber = !value.length
                    ? 'Phone is required!'
                    : value.length > 0 && !phoneRegx.test(value)
                    ? 'Only Number Characters Allowed!'
                    : value.length > 10 || value.length < 10
                    ? 'Phone number should be exactly 10 characters'
                    : '';
                break;
            case 'licence':
                errorIs.licence =
                    value.length > 0 && !(value.length > 4)
                        ? 'License is Too short (Must be at Least 5 characters)'
                        : value.length > 0 && value.length > 16
                        ? 'License is Too Long! (Max 16 characters)'
                        : '';
                break;
            // case "password":
            //   errorIs.password = !value.length ? "Password is required!" : "";
            // break;
            case 'address':
                errorIs.address =
                    country == 'usa' && !value.length
                        ? 'Address is required!'
                        : country == 'usa' && value.length > 50
                        ? 'Address is Too Long! (Max 50 symbols)'
                        : '';
                break;
            case 'street':
                errorIs.street =
                    country == 'canada' && !value.length
                        ? 'Street is required!'
                        : country == 'canada' && !(value.length > 1)
                        ? 'Street is Too short (Must be at Least 2 characters)'
                        : country == 'canada' && value.length > 50
                        ? 'Street is Too Long (Must be 50 characters)'
                        : '';
                break;
            case 'provinceOrTerritory':
                errorIs.provinceOrTerritory =
                    country == 'canada' && !value.length
                        ? 'Province/Territory is required!'
                        : country == 'canada' && !(value.length > 1)
                        ? 'Province/Territory is Too short (Must be at Least 2 characters)'
                        : country == 'canada' && value.length > 50
                        ? 'Province/Territory is Too Long (Must be 50 characters)'
                        : '';
                break;
            case 'city':
                errorIs.city = !value.length
                    ? 'City is required!'
                    : !(value.length > 1)
                    ? 'City Is Too short (Must be at Least 2 characters)'
                    : value.length > 25
                    ? 'City is Too Long (Must be 25 characters)'
                    : '';
                break;
            case 'state':
                errorIs.state = country == 'usa' && !value.length ? 'State is required!' : '';
                break;
            case 'zip':
                errorIs.zip =
                    country == 'usa' && !value.length
                        ? 'Zip is required!'
                        : country == 'usa' && !zipRegx.test(value)
                        ? 'Only Number Characters Allowed!'
                        : country == 'usa' && !(value.length > 4)
                        ? 'Zip is Too short (Must be 5 characters)'
                        : country == 'usa' && !(value.length === 5)
                        ? 'Zip is Too Long (Must be 5 characters)'
                        : '';
                break;
            case 'postalCode':
                errorIs.postalCode =
                    country == 'canada' && !value.length
                        ? 'PostalCode is required!'
                        : country == 'canada' && !zipRegx.test(value)
                        ? 'Only Number Characters Allowed!'
                        : country == 'canada' && !(value.length > 1)
                        ? 'PostalCode is Too short (Must be at Least 2 characters)'
                        : '';
                break;
             case 'nameOnAccount':
                errorIs.nameOnAccount = !value.length
                    ? 'Name is required!'
                    : !(value.length > 1)
                    ? ' Name is Too short (Must be at Least 2 character)'
                    : value.length > 50
                    ? ' Name is Too Long! (Max 50 symbols)'
                    : '';
                break;
            case 'rtn':
                errorIs.rtn = !value.length
                    ? 'Routing Number is required!'
                    : !(value.length > 8)
                    ? 'Routing Number is Too short (Must be at Least 9 character)'
                    : value.length > 9
                    ? 'Routing Number is Too Long! (Max 9 symbols)'
                    : '';
                break;
            case 'accountNo':
                errorIs.accountNo = !value.length
                    ? 'Account Number is required!'
                    : value.length > 50
                    ? 'Account Number Is Too Long (Must be 50 characters)'
                    : '';
                break;
            case 'acctype':
                errorIs.acctype = !value.length || value == 'Account Type'
                    ? 'Account Type is required!'
                    : '';
                break;
            default:
                break;
        }
    
        return errorIs;
    };

    onConfirm() {
        const { errorIs } = this.state;
        if(this.validation()) {
            this.verifyUserDetails();
        } else {
           Object.entries(errorIs).map((type , key) => {
               if(type && type.length > 1 && type[1] != '') {
                 this.focusNode(type[0])
               }
            })
        }
        
    }

    verifyUserDetails() {
        let customer = this.getCustomerData(this.state.country);
        let params = {
          FirstName: this.state.firstName,
          LastName: this.state.lastName,
          EmailAddress: this.state.email,
          PhoneNumber: this.state.phoneNumber,
          DateOfBirth: moment(this.state.dob).format("YYYY-MM-DD"),
          AddressLine1: customer.Address1,
          City: customer.City,
          State: customer.StateRegion,
          ZipCode: customer.PostalCode,
          Country: customer.Country == "USA" ? "US" : customer.Country,
          DlNumber: customer.licence,
          DlState: customer.StateRegion,
          TaxId: this.state.ssn,
          NameOnAccount: this.state.nameOnAccount,
          AccountNumber: this.state.accountNo,
          RoutingNumber: this.state.rtn,
          AccountType: 0,
        };
   
        this.setState({ LoadingIs: true })
        

        verifyUser(params).then((response) => {
            
             if (response && response.flag) {
                this.updateACH();
                  this.setState({updateStatus : true})
             }else {
                 this.setState({LoadingIs : false})
                  let msg =
                        "Customer verification failed!!. Please check your information & submit again.";
                // if (response.data && response.data.email && !response.data.email.flag) {
                //    msg = response.data.email.label;
                // }
                if (
                  response.data &&
                  response.data.customer &&
                  !response.data.customer.flag
                ) {
                    msg = response.data.customer.label;
                }
                this.setState({resMsg : msg, resMsgModal: true, updateStatus : false})
             }
        })
    }

    getCustomerData(country) {
        let data = {
          FirstName: this.state.firstName,
          LastName: this.state.lastName,
          Email: this.state.email,
          Phone: this.state.phoneNumber,
          ssn: this.state.ssn,
          Address1: "",
          Address2: "",
          City: "",
          StateRegion: "",
          PostalCode: "",
          Country: "",
          licence: this.state.licence,
        };
        switch (country) {
          case "uk":
            data.Address1 = this.state.addressOrThoroughsfare;
            data.City = this.state.locality;
            data.StateRegion = this.state.postTown;
            data.PostalCode = this.state.postcode;
            data.Country = "UK";
            break;
          case "usa":
            data.Address1 = this.state.address;
            data.City = this.state.city;
            data.StateRegion = this.state.state;
            data.PostalCode = this.state.zip;
            data.Country = "USA";
            break;
          case "canada":
            data.Address1 = this.state.street;
            data.City = this.state.canadaCity;
            data.StateRegion = this.state.provinceOrTerritory;
            data.PostalCode = this.state.postalCode;
            data.Country = "CANADA";
            break;
        }
        return data;
    }

    updateACH() {
    this.updateCustomer();
    }

    updateCustomer() {
        const { customerDetails, country } = this.state;
        let customer = this.getCustomerData(country);
        customer.CustomerNumber = customerDetails.customer_number;
        customer.dob = moment(this.state.dob).format("YYYY-MM-DD") + 'T12:00:00';

        this.setState({ flag: true });
    
         updateJHCustomer(customer)
          .then((resp) => {
            if (resp && resp.updated) {
                 if (this.state.isAcountChanged) {
                    this.updateAccount();
                    this.setState({updateStatus : true})
                } else {
                     this.setState({ LoadingIs: false });
                }
            }else {
                   this.setState({ LoadingIs: false,sendingForm: false, flag: false });
                   let msg = resp.error ? resp.error : "ACH details updation failed!!";
                   this.setState({resMsg : msg, resMsgModal: true, updateStatus : false})
            }
          })
          .catch((err) => {
                this.setState({ LoadingIs: false, sendingForm: false });
          });
    }

    updateAccount() {
        let { userCustomerInfo } = this.props;
        let account = {
          NameOnAccount: this.state.nameOnAccount,
          AccountName: this.state.nameOnAccount,
          AccountNumber: this.state.accountNo,
          RoutingNumber: this.state.rtn,
          AccountReferenceID: userCustomerInfo
            ? userCustomerInfo.account_reference_id
            : "",
       };

       updateJHAccount(account)
        .then((resp) => {
    
        if (resp && resp.updated) {
          //this.props.removeLoading();
          this.setState(
            {
              sendingForm: false,
              flag: false,
              isAcountChanged: true,
              LoadingIs: false,
            },
            () => {
             let msg = resp.error ? resp.error : "ACH account updated!!";
              this.setState({resMsg : msg, resMsgModal: true, updateStatus : true})
            }
          );
        } else {
          this.setState({
            sendingForm: false,
            flag: false,
            LoadingIs: false,
            isAcountChanged: true,
          });
          let msg = resp.error ? resp.error : "ACH account updation failed!!";
          this.setState({resMsg : msg, resMsgModal: true, updateStatus : false})
        }
      })
      .catch((err) => {
        this.setState({ sendingForm: false,LoadingIs: false , updateStatus : false});
       // handleCommonError("message", err);
      });
    }



    render() {
        const { errorIs, country, LoadingIs, resMsg, resMsgModal, UpdateSetupVisible} = this.state;
        addressArray = [];

       
        let showAddressFields = [];
        countries.map((item) => {
            if (item.value === country) {
                showAddressFields = item.addressFields;
            }
        });


        addressArray.push(showAddressFields);
        return (
        <React.Fragment>
            { resMsg  && (
                <Modal
                    centered
                    visible={resMsgModal}
                    backdrop="static"
                    height={200}
                    className="modal-container req-card"
                    maskClosable={false}
                    onCancel={() => this.handleMsgClose()}
                >
                    <div className="modal-head">
                        <div style={{textAlign: 'center', width: '80%', margin: 'auto', fontWeight: '700', marginBottom: '15px'}}>
                            {resMsg}
                        </div>
                        <Button type="primary" style={{width: '80%', marginTop: '10px', border: 'none', borderRadius: '4px' }} onClick={() =>  this.handleMsgClose()} className="btn fp-button-primary">
                           Ok
                        </Button>
                    </div>
                </Modal>
            )}
                
            <Modal
                title="Update Account"
                centered
                visible={UpdateSetupVisible}
                onOk={() => this.setModalVisible(false)}
                onCancel={() => this.handleClose(false)}
                className="account-modal"
            >
            <LoadingOverlay
                active={LoadingIs}
                className="d-block pop-loader"
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '100px',
                        '& svg circle': {
                            stroke: 'rgb(56, 160, 235)',
                        },
                    }),
                }}
            >
            </LoadingOverlay>
                    {/* <Formik> */}
                <form>
                    <div className="content-wrap">
                        <div className="float-wrap" ref="firstName">
                            <FloatLabel label="First Name" value={this.state.firstName}>
                                <Input
                                    name="firstName"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.firstName}
                                />
                            </FloatLabel>
                             {errorIs.firstName ? (
                                     <div className="err-msg">{errorIs.firstName}</div>
                                ) : null}
                            {/*<div className="err-msg">this is a error message</div> */}
                        </div>
                        <div className="float-wrap" ref="lastName">
                            <FloatLabel label="Last Name" value={this.state.lastName}>
                                <Input
                                    name="lastName"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.lastName}
                                />
                            </FloatLabel>
                            {errorIs.lastName ? (
                                 <div className="err-msg">{errorIs.lastName}</div>
                            ) : null}
                        </div>
                        <div className="float-wrap" ref="phoneNumber">
                            <FloatLabel label="Phone Number" value={this.state.phoneNumber}>
                                <Input
                                    name="phoneNumber"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.phoneNumber}
                                />
                            </FloatLabel>
                        {errorIs.phoneNumber ? <div className="err-msg">{errorIs.phoneNumber}</div> : null}
                        </div>
                         <div className="float-wrap" ref="ssn">
                            <FloatLabel label="SSN" value={this.state.ssn}>
                                <Input
                                    name="ssn"
                                    type="password"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.ssn}
                                />
                            </FloatLabel>
                            {errorIs.ssn ? <div className="err-msg">{errorIs.ssn}</div> : null}
                        </div>
                        <div className="float-wrap" ref="email">
                            <FloatLabel label="Email" value={this.state.email}>
                                <Input
                                    name="email"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.email}
                                />
                            </FloatLabel>
                             {errorIs.email ? <div className="err-msg">{errorIs.email}</div> : null}
                        </div>
                        <div className="float-wrap cust-mb" ref="dob">
                            <FloatLabel label="Date of Birth" value={this.state.dob === '' ? this.state.dob : this.formatDate(this.state.dob)}>
                                <DatePicker
                                    className="cust-dp"
                                    inputReadOnly="true"
                                    value={Moment(this.state.dob)}
                                    placeholder=""
                                    size="large"
                                    onChange={this.handleDOB}
                                />
                            </FloatLabel>
                            {errorIs.dob ? <div className="err-msg">{errorIs.dob}</div> : null}
                        </div>
                         <div className="float-wrap" ref="country">
                            <Select value={this.getCountryName(this.state.country)}
                                onChange={this.handleCountry} className="cust-selct">
                               {countries.map((country, i) => (
                                    <Option
                                        className={
                                            this.state.country === country.value ? 'text-color' : ''
                                        }
                                        value={country.value}
                                        key={i}
                                    >
                                        {country.label}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        {addressArray[0].includes('address') && (
                            <div className="float-wrap" ref="address">
                                <FloatLabel label="Address" value={this.state.address}>
                                    <Input
                                        name="address"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                        }}
                                        autoComplete="nope"
                                        value={this.state.address}
                                    />
                                </FloatLabel>
                            {errorIs.address ? (
                                <div className="err-msg">{errorIs.address}</div>
                            ) : null}
                            </div>
                        )}
                        {addressArray[0].includes('street') && (
                            <div className="float-wrap" ref="street">
                                <FloatLabel label="Street" value={this.state.street}>
                                    <Input
                                        name="street"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                        }}
                                        autoComplete="nope"
                                        value={this.state.street}
                                    />
                                </FloatLabel>
                            {errorIs.street ? (
                                <div className="err-msg">{errorIs.street}</div>
                            ) : null}
                            </div>
                        )}
                        {addressArray[0].includes('city') && (
                            <div className="float-wrap" ref="city">
                                <FloatLabel label="City" value={this.state.city}>
                                    <Input
                                        name="city"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                        }}
                                        autoComplete="nope"
                                        value={this.state.city}
                                    />
                                </FloatLabel>
                            {errorIs.city ? (
                                <div className="err-msg">{errorIs.city}</div>
                            ) : null}
                            </div>
                        )}
                        {addressArray[0].includes('provinceOrTerritory') && (
                            <div className="float-wrap" ref="provinceOrTerritory">
                                <FloatLabel label="Province/Territory" value={this.state.provinceOrTerritory}>
                                    <Input
                                        name="provinceOrTerritory"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                        }}
                                        autoComplete="nope"
                                        value={this.state.provinceOrTerritory}
                                    />
                                </FloatLabel>
                            {errorIs.provinceOrTerritory ? (
                                <div className="err-msg">{errorIs.provinceOrTerritory}</div>
                            ) : null}
                            </div>
                        )}
                        {addressArray[0].includes('zip') && (
                            <div className="float-wrap" ref="zip">
                                <FloatLabel label="Zip" value={this.state.zip}>
                                    <Input
                                        name="zip"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                        }}
                                        autoComplete="nope"
                                        value={this.state.zip}
                                    />
                                </FloatLabel>
                            {errorIs.zip ? <div className="err-msg">{errorIs.zip}</div> : null}
                            </div>
                         )}
                        {addressArray[0].includes('postalCode') && (
                            <div className="float-wrap" ref="postalCode">
                                <FloatLabel label="PostalCode" value={this.state.postalCode}>
                                    <Input
                                        name="postalCode"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                        }}
                                        autoComplete="nope"
                                        value={this.state.postalCode}
                                    />
                                </FloatLabel>
                            {errorIs.postalCode ? <div className="err-msg">{errorIs.postalCode}</div> : null}
                            </div>
                         )}
                        {addressArray[0].includes('state') && (
                            <div className="float-wrap" ref="state">
                                <Select value={this.getStateName(this.state.state)}
                                    onChange={this.handleState} className="cust-selct">
                                   {US_states.map((item, i) => (
                                        <Option
                                            className={this.state.state === item.value ? 'text-color' : ''}
                                            value={item.value}
                                            key={i}
                                        >
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            {errorIs.state ? (
                                <div className="err-msg">{errorIs.state}</div>
                            ) : null}
                            </div>
                        )}
                        <div className="float-wrap" ref="licence">
                            <FloatLabel label="Drivers licence" value={this.state.licence}>
                                <Input
                                    name="licence"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.licence}
                                />
                            </FloatLabel>
                        {errorIs.licence ? (
                             <div className="err-msg">{errorIs.licence}</div>
                        ) : null}
                        </div>

                        <div className="float-wrap" ref="nameOnAccount">
                            <FloatLabel label="Name on Account" value={this.state.nameOnAccount}>
                                <Input
                                    name="nameOnAccount"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.nameOnAccount}
                                />
                            </FloatLabel>
                           {errorIs.nameOnAccount ? (
                                 <div className="err-msg">{errorIs.nameOnAccount}</div>
                            ) : null}
                        </div>

                        <div className="float-wrap" ref="rtn">
                            <FloatLabel label="Routing Number" value={this.state.rtn}>
                                <Input
                                    name="rtn"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.rtn}
                                />
                            </FloatLabel>
                          {errorIs.rtn ? <div className="err-msg">{errorIs.rtn}</div> : null}
                        </div>
                        <div className="float-wrap" ref="accountNo">
                            <FloatLabel label="Account Number" value={this.state.accountNo}>
                                <Input
                                    name="accountNo"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    autoComplete="nope"
                                    value={this.state.accountNo}
                                />
                            </FloatLabel>
                           {errorIs.accountNo ? <div className="err-msg">{errorIs.accountNo}</div> : null}
                        </div>
                        <div className="float-wrap" ref="acctype">
                            <Select  name="acctype" value={this.state.acctype}
                                onChange={this.handleAccType} className="cust-selct">
                                 {selectAccountType.map((type, i) => (
                                    <Option value={type.id} key={i}>
                                        {type.name}
                                    </Option>
                                ))}
                            </Select>
                           {errorIs.acctype ? <div className="err-msg">{errorIs.acctype}</div> : null}
                        </div>
                    </div>

                    <div className="btn-wrap">
                        <Button
                            type="primary"
                            className="fp-button-secondary btn-add-req"
                            onClick={() => this.onConfirm()}
                        >
                            <span>Update ACH Account</span>
                        </Button>
                    </div>
            
                </form>
                    {/* </Formik> */}
                </Modal>
            </React.Fragment>
            );   
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparents, coparent } = serviceReducer;
    return { user, error, children, coparents, coparent };
};

export default connect(mapStateToProps)(UpdateSetup);

