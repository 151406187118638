import * as types from 'actions';


export default function (state = [], action) {
    const response = action.response;
    let error = action.error;
    switch (action.type) {
        case types.LOGIN_USER_SUCCESS:
            return { ...state, response };
        case types.LOGIN_USER_ERROR:
            return { ...state, error };
        default:
            return state;
    }
}
