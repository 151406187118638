import React, { Component } from 'react';
import 'pages/CalenderInner/Swap/Swap.scss';
import { Card, Button, Modal, Form, Input, Select, Avatar, Empty } from 'antd';
import right from 'Assets/right-arrow.png';
import TextArea from 'antd/lib/input/TextArea';
import { connect } from 'react-redux';
import user from 'Assets/defaultAvatar.png';
import { CalendarOutlined } from '@ant-design/icons';
import Calendar from 'pages/CalenderInner/Custody/date-picker/react-calendar/src';
import { setupScheduleException } from 'services/calendarService';
import LoadingOverlay from 'react-loading-overlay';
import DeleteExceptionModal from 'pages/CalenderInner/Swap/DeleteExceptionModal';
import moment from 'moment';
import { getScheduleException, updateScheduleException } from 'services/calendarService';
import { addLoading, removeLoading } from 'actions/loaderActions';

let frequencies = [
    {
        label: 'Yearly',
        value: 'yearly',
    },
    {
        label: 'Weekly',
        value: 'weekly',
    },

    {
        label: 'Monthly',
        value: 'monthly',
    },
    {
        label: '6 Months',
        value: 'six_months',
    },
    {
        label: 'One Time',
        value: 'onetime',
    },
];

let exceptionTypes = [
    {
        label: 'Alternate custody, I get first custody',
        value: 1,
    },
    {
        label: 'Alternate custody, co-parent gets first custody',
        value: 2,
    },
    {
        label: 'I will have custody on this date',
        value: 3,
    },
    {
        label: "I won't have custody on this date",
        value: 4,
    },
];

const exception_status = {
    pending: 'Pending',
    active: 'Approved',
    declined: 'Declined',
};

class UpdateExceptionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            child_ids: [],
            frequency: '',
            custody: '',
            start_date: new Date(),
            currentDate: new Date(),
            child: null,
            loading: false,
            picture: null,
            type: 'special_date',
            coparent_id: '',
            modalVisible: false,
            modal1Visible: false,
            status: '',
            exception: null,
            decline_reason: '',
            error: '',
            flag: false,
        };
        this.handleFrequency = this.handleFrequency.bind(this);
        this.handleCustody = this.handleCustody.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.confirmDate = this.confirmDate.bind(this);
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal1Visible = this.setModal1Visible.bind(this);
        this.getFrequency = this.getFrequency.bind(this);
        this.getCustody = this.getCustody.bind(this);
        this.handleAccept = this.handleAccept.bind(this);
        this.handleDecline = this.handleDecline.bind(this);
        this.handleText = this.handleText.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.handleOpen && nextProps.currentException) {
            this.setState({ loading: true });
            getScheduleException(nextProps.currentException.id).then((resp) => {
                this.setState({
                    title: resp.title,
                    child_ids: resp.child_ids || [],
                    frequency: resp.frequency,
                    custody: resp.is_alternate_new,
                    start_date: new Date(resp.start_date),
                    currentDate: new Date(resp.start_date),
                    child: (resp.Members && resp.Members[0]) || null,
                    picture: resp.Members && resp.Members[0].picture,
                    type: resp.type,
                    coparent_id: resp.coparent_id,
                    loading: false,
                    status: resp.status,
                    exception: resp,
                    decline_reason: '',
                    error: '',
                    flag: false,
                });
            });
        }
    }

    formatDate = function (date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return month + '/' + day + '/' + year;
    };

    getFrequency = function (value) {
        let val = '';
        frequencies.forEach((item) => {
            if (item.value == value) {
                val = item.label;
            }
        });
        return val;
    };

    getCustody = function (value) {
        let val = '';
        exceptionTypes.forEach((item) => {
            if (item.value == value) {
                val = item.label;
            }
        });
        return val;
    };

    handleText = async function (e) {
        if (e.target.value === '') {
            await this.setState({ decline_reason: e.target.value, error: 'You have to provide a decline reason!' });
        } else {
            await this.setState({ decline_reason: e.target.value, error: '' });
        }
    };

    handleDate = function (e) {
        this.setState({ currentDate: e });
    };

    confirmDate = async function () {
        const { currentDate } = this.state;
        await this.setState({ start_date: currentDate, modalVisible: false });
    };

    setModalVisible = function (visible) {
        this.setState({ modalVisible: visible });
    };

    setModal1Visible = function (visible) {
        this.setState({ modal1Visible: visible });
    };

    handleFrequency = async function (value) {
        await this.setState({ frequency: value });
    };

    handleCustody = async function (value) {
        await this.setState({ custody: value });
    };

    handleAccept = function () {
        const { exception, child, response_memo } = this.state;
        this.setState({ loading: true });
        let options = {
            status: 'active',
            kid: child.id,
            response_memo: response_memo,
        };

        updateScheduleException(exception.id, options).then((resp) => {
            this.setState({ loading: false });
            this.props.handleClose();
            this.props.refresh();
            this.props.dispatch(addLoading());
        });
    };

    handleDecline = function () {
        const { decline_reason, exception, child, response_memo } = this.state;
        if (decline_reason.length === 0) {
            this.setState({ flag: true, error: 'You have to provide a decline reason!' });
        } else {
            this.setState({ loading: true });
            let options = {
                status: 'declined',
                kid: child.id,
                response_memo: response_memo,
                decline_reason: decline_reason,
            };

            updateScheduleException(exception.id, options).then((resp) => {
                this.setState({ loading: false });
                this.props.handleClose();
                this.props.refresh();
                this.props.dispatch(addLoading());
            });
        }
    };

    render() {
        const { Option } = Select;

        const {
            title,
            child_ids,
            child,
            frequency,
            status,
            picture,
            custody,
            start_date,
            loading,
            currentDate,
            decline_reason,
            flag,
            error,
        } = this.state;
        return (
            <Modal
                title="Schedule Exception Setup"
                className="cal-modal swap-modal exception-modal"
                centered
                maskClosable={false}
                visible={this.props.handleOpen}
                onOk={this.props.handleClose}
                onCancel={this.props.handleClose}
            >
                <LoadingOverlay
                    active={loading ? true : false}
                    className={loading ? 'loader-wrap pop-loader' : ''}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgb(56, 160, 235)',
                            },
                        }),
                    }}
                ></LoadingOverlay>
                <Form className="schedule-form">
                    <div className="scroll-wrap enhance-height">
                        <div className="invite-top">
                            <span>{title}</span>
                            <div className="avatar-wrap">
                                <Avatar size={60} src={picture ? picture.url : user} alt="avatar-image" />
                            </div>
                        </div>
                        <div className="swap-details">
                            <span style={{ color: '#999999', fontSize: '12px' }}>Kid(s):</span>
                            <span style={{ fontSize: '14px' }}>{child && child.name}</span>
                        </div>
                        <div className="swap-details">
                            <span style={{ color: '#999999', fontSize: '12px' }}>Date:</span>
                            <span style={{ fontSize: '14px' }}>
                                {this.formatDate(start_date) + ' ' + moment(start_date).format('hh:mm A')}
                            </span>
                        </div>
                        <Form.Item className="select-sm">
                            <div className="heading-style">What is the frequency of custody exception?</div>
                            <Input style={{fontSize: '12px'}} className="exception-input" value={this.getFrequency(frequency)} readOnly />
                        </Form.Item>
                        <Form.Item className="select-sm">
                            <div className="heading-style">Who will have custody on exception date?</div>
                            <Input style={{fontSize: '12px'}} className="exception-input" value={this.getCustody(custody)} readOnly />
                        </Form.Item>
                        {flag === true && (
                            <Form.Item className="sm-textarea" label="Decline Reason" labelCol={{ span: 24 }}>
                                <TextArea value={decline_reason} onChange={this.handleText} />
                                {flag === true && error !== '' ? (
                                    <div style={{ color: 'red', fontSize: '12px' }}>{error}</div>
                                ) : null}
                            </Form.Item>
                        )}
                        <div style={{ marginBottom: '20px' }}></div>
                    </div>
                    <div className="btn-scroll-wrap">
                        {flag === false && (
                            <Form.Item>
                                <Button onClick={this.handleAccept} className="save-btn" type="primary">
                                    Accept
                                </Button>
                            </Form.Item>
                        )}
                        <Form.Item>
                            <Button onClick={this.handleDecline} className="red-btn" type="primary">
                                Decline
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { user, error, children, coparent } = serviceReducer;
    return { user, error, children, coparent };
};

export default connect(mapStateToProps)(UpdateExceptionModal);
