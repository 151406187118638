import { Layout } from 'antd';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
    getMeUserAction,
    getChildrenUserAction,
    getCoParentsUserAction,
    updateQBStatus,
    getCheckListAction,
    updateSessionFlag,
    updateLoginFlag,
    getNotificationAction
} from 'actions/userActions';
import { checkCookie } from 'utils/cookies';
import Header from 'Components/Header/Header';
import { QuickBlockConfig } from 'pages/QuickBlox/QBConfig';
import {
    getQBCredentials,
    updateQBUserData
} from 'services/chatService';
import { getSetupProgress } from 'services/userService';
import {
    CreateUser, 
    LoginUser,
    ConnectChatServer,
    IsConnected,
    GetSession,
    CreateSession,
    GetUser,
    CreateAppSession,
} from 'pages/QuickBlox/qbutility';
import * as QB from "quickblox/quickblox";
import SessionModal from 'Components/Modal/SessionModal';
import { ROUTES } from 'constants/routes';
import IdleTimerContainer from 'Components/IdleTimerContainer/IdleTimerContainer';
import SwapErrorModal from 'pages/CalenderInner/Swap/SwapErrorModal';
import LogoutModal from 'Components/Modal/LogoutModal';

const { Content } = Layout;


export class MainLayout extends Component {
    constructor(props){
        super(props);
        this.state = {
            msg: '',
            modal2Visible: false
        };
        this.setModalVisible = this.setModalVisible.bind(this);
        this.setModal2Visible = this.setModal2Visible.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    componentDidMount(){
        let { login } = this.props;
        if(checkCookie() !== null) {
            let token = checkCookie();
            this.props.dispatch(getMeUserAction({token: token})); 
            this.props.dispatch(getChildrenUserAction());
            this.props.dispatch(getCoParentsUserAction());
            this.props.dispatch(getNotificationAction());
            if (localStorage.third_party !== 'true') {
                this.props.dispatch(getCheckListAction());
            }
            this.configureQBData();
        }     
    }


    configureQBData = async () => {
        this.props.dispatch(updateQBStatus(false));
        let configData = await localStorage.getItem("chat_conf");
        if(!configData){
            getQBCredentials().then(resp => {
                if (resp.success) {
                  localStorage.setItem("chat_conf", JSON.stringify(resp["chat_conf"]));
                    let data;
                    // Initialize Quickblox SDK
                    if(typeof resp["chat_conf"] === 'string'){
                      data = JSON.parse(resp["chat_conf"]);
                    } else {
                      data = resp["chat_conf"];
                    }
                    QuickBlockConfig(data);
                    setTimeout(async () => {
                       this.checkChatServerLogin();
                    },2000);
                }
              });
        } else {
            configData = JSON.parse(configData);
            // Initialize Quickblox SDK
            QuickBlockConfig(configData);
            setTimeout(async () => {
                this.checkChatServerLogin();
            },2000);
        }
    };

    updateQBDataToServer = async (email, id, qblogin) => {
        await localStorage.setItem('qb_username', email);
        await localStorage.setItem('qd_userid', id);
        let item = { qb_username: email, qd_userid: id,qb_login: qblogin };
        let response = await updateQBUserData(item);
    };
    
    checkChatServerLogin = async () => {
        
            let username = await localStorage.getItem('qb_username');
            let email = await localStorage.getItem('qb_username');
            let qdLogin = await localStorage.getItem('qb_login');

            let new_user = await localStorage.getItem('new_user');

            if (new_user === 'true') {
                let first_name = await localStorage.getItem('first_name');
                let last_name = await localStorage.getItem('last_name');
               

                let session = await GetSession();
                if(!session){
                    let sessionCreated = await CreateAppSession();
                }
                // Create user
                if (email) {
                    let info = await CreateUser(email, qdLogin, first_name + ' ' + (last_name ? last_name : ''));
                    if (info) {
                        this.updateQBDataToServer(email, '' + info.id, qdLogin);
                        localStorage.removeItem('new_user');
                    }
                }
            }

            // Create User session
            let session =  await GetSession();
            if(!session){
                let sessionCreated = await CreateSession(qdLogin);
            }
            let notFirst = await localStorage.getItem('notFirst');
            if (notFirst) {
                // if (!session || !username) {
                await this.ChatLogin();
                //}
                await this.ConnectToChatServer();
            } else {
                await localStorage.setItem('notFirst', '1');
                await this.ChatLogin();
            }
       
        
    };

    ConnectToChatServer = async () => {
        let userid = await localStorage.getItem('qd_userid');
        let email = await localStorage.getItem('qb_username');
        let qdLogin = await localStorage.getItem('qb_login');
        let isconnected = IsConnected();
        if (!isconnected && userid && qdLogin) {
            let res = await ConnectChatServer(userid, qdLogin);
            if(res){
                this.props.dispatch(updateQBStatus(true));
            }
        }
    };

    ChatLogin = async () => {
        let username = await localStorage.getItem('qb_username');
        let email = await localStorage.getItem('qb_username');
        let qdLogin = await localStorage.getItem('qb_login');
        
        let userData;
        // Login user
        if (qdLogin) {
            userData = await LoginUser(qdLogin);
        }
        
        if (userData) {
            await localStorage.setItem('qb_userid', '' + userData.id);

            let res = await ConnectChatServer(userData.id, qdLogin);
            if(res){
                this.props.dispatch(updateQBStatus(true));
            }

            if (!username) {
                this.updateQBDataToServer(email, '' + userData.id, qdLogin);
            }


        }
    };

    setModalVisible = async function (visible) {
        this.props.dispatch(updateSessionFlag(false))
        this.handleLogout();
    };

    handleLogout = function () {
        document.cookie = 'token= ; path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT';
        window.location.replace(ROUTES.LANDING_PAGE);

        let arr = [
            'socialLogin',
            'third_party',
            'household_id',
            'coparent_id',
            'third_party_permission',
            'calendar_permission',
            'payment_permission',
            'chat_permission',
            'member_id',
            'qd_userid',
            'qb_username',
            'qb_login',
            'coparent_name',
            'new_user'
        ];

        arr.forEach(item => {
            localStorage.removeItem(item);
        })
        
    };

    setModal2Visible = function (visible, msg) {
        this.setState({ modal2Visible: visible, msg });
    }

    handleSessionLogout = () => {
        this.setModal2Visible(true, 'Your session has expired. You will be logged out.');
    }

    closeApp = () => {
        this.setModal2Visible(false, '');
        this.handleLogout();
    }

    render() {
        const { children ,loginStatus ,sessionError} = this.props;
        return (
            <div>
                <IdleTimerContainer handleClose={() => this.handleSessionLogout()}></IdleTimerContainer>
                {!this.props.loading && (
                    <Layout>
                        <div>
                            <Header />
                        </div>
                        <Content>{children}</Content>
                        <SessionModal 
                        maskClosable={false}
                        handleClose={() => this.setModalVisible(false)}/>
                    </Layout>
                )}
                <LogoutModal
                    openModal={this.state.modal2Visible}
                    closeModal={() => this.closeApp()}
                    msg={this.state.msg}
                />
            </div>
        );

    }
}

const mapStateToProps = ({ serviceReducer }) => {
    const { loginStatus ,sessionError } = serviceReducer;
    return { loginStatus, sessionError };
};

export default connect(mapStateToProps)(MainLayout);

